import Config from '@apricityhealth/web-common-lib/Config';

import React, { Component } from 'react';
import { AppBar, 
    Toolbar, 
    Typography, 
    CircularProgress,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions, 
    DialogTitle, 
    DialogContentText, 
    DialogContent 
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import Axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

class SubscriptionView extends Component {
    componentWillMount() {
        console.log("componentWillMount:", this);
        if (this.props.sub)
            this.loadSubscription(this.props.sub);
    }
    componentDidUpdate(oldProps){
        if (this.props.sub !== oldProps.sub)
            this.loadSubscription(this.props.sub);
    }

    closeView() {
        console.log("closeView()", this);
        this.props.onClose();
    }

    loadSubscription(sub) {
        console.log("loadSubscription:", sub);
        if (! sub.criteria )
            sub.criteria = "";

        this.setState({
            sub: sub,
            progress: null,
            dialog: null
        });
    }
    saveSubscription() {
        console.log("saveSubscription()", this);

        const self = this;
        const { sub } = self.state;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress /> });

        const saveSubscription = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/subscription`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: sub
        };

        console.log("saveSubscription:", saveSubscription);
        Axios(saveSubscription).then(function (response) {
            console.log("saveSubscription response:", response);
            self.setState({ sub: response.data, progress: null });
        }).catch(function (error) {
            console.log("saveSubscription error:", error);
            self.setState({ progress: error.message });
        });
    }
    deleteSubscription() {
        console.log("deleteSubscription()", this);
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Subscription: {`${self.state.sub.subId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDelete() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }
    cancelDelete() {
        this.setState({ dialog: null });
    }
    confirmDelete() {
        const self = this;
        const sub = this.state.sub;

        self.setState({ dialog: null });
        if (sub.subId) {
            const deleteSubscription = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/subscription/${sub.subId}`,
                method: 'DELETE',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteSubscription:", deleteSubscription);
            Axios(deleteSubscription).then( (response) => {
                console.log("deleteSubscription response:", response);
                self.setState({ progress: null });
                self.closeView();
            }).catch( (error) => {
                console.log("deleteSubscription error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }

    render() {
        const self = this;
        let { sub, progress, dialog } = self.state;

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Subscription</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>

                            <tr>
                                <td style={styles.td} valign="top">
                                    <IconButton onClick={() => { self.saveSubscription(); }}><SaveIcon /></IconButton>
                                    <IconButton onClick={() => { self.deleteSubscription(); }}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Subscription ID: {sub.subId}</p>
                                    <p>Resource ID: {sub.resourceId}</p>
                                    <p>Status: {sub.status}</p>
                                    <TextField style={styles.category} label="Criteria" value={sub.criteria}
                                        onChange={(e) => { sub.criteria = e.target.value; self.setState({ sub }); }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    category: {
        margin: 5,
        width: 300
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 100
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default SubscriptionView;
