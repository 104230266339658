import React, { Component } from 'react';
import Select from 'react-select';
import {
    Typography,
    FormControl
} from '@material-ui/core/';

import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

export function getUser(appContext, userId) {
    return new Promise((resolve, reject) => {
        let url = Config.baseUrl + `${Config.pathPrefix}authentication/users/${userId}`;
        const getUsers = {
            url: url,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        }
        console.log(`getUser request: `, getUsers)
        Axios(getUsers).then((response) => {
            console.log(`getUser response: `, response)
            resolve(response.data.user);
        }).catch(() => {
            resolve()
            //reject(error);
        });
    });
}

export function findUsers(appContext, search, group, offset = 0, limit = 100) {
    return new Promise((resolve, reject) => {
        let args = [
            `limit=${limit}`,
            `offset=${offset}`
        ];
        if (group)
            args.push(`group=${group}`);
        if (search) {
            args.push(`email=${encodeURIComponent(search)}`);
            args.push(`phone=${encodeURIComponent(search)}`);
        }

        const getUsers = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users?${args.join('&')}`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        }
        console.log(`findUsers request: `, getUsers)
        Axios(getUsers).then((response) => {
            console.log(`findUsers response: `, response)
            resolve(response.data.users);
        }).catch(function (error) {
            reject(error);
        });

    });
}

export class SelectUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.userId,
            items: [],
            user: null,
            search: '',
            selection: { label: '', value: '' }
        };
    }

    componentDidMount() {
        this.getUser();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.state.userId !== this.props.userId) {
            this.setState({ userId: this.props.userId, selection: { label: '', value: '' } }, this.getUser.bind(this))
        }
    }

    getUser() {
        const { appContext } = this.props;
        let { userId, items } = this.state;

        if ( userId ) {
            getUser(appContext, userId).then((user) => {
                if (user) {
                    let selection = items.find((e) => e.value === user.username);
                    if (!selection && user) {
                        selection = { label: user.email, user, value: user.username };
                        items.push(selection);
                    }
                    this.setState({ user, items, selection }, this.findUsers.bind(this));
                } else {
                    let selection = { label: `User not found!`, value: userId };
                    items.push(selection);

                    this.setState({ user, items, selection }, this.findUsers.bind(this));
                }
            }).catch((err) => {
                console.error("getUser error:", err);
            })
        }
        else {
            this.findUsers();
        }
    }

    findUsers() {
        const { appContext, groups } = this.props;
        const { search } = this.state;

        findUsers(appContext, search, groups).then((users) => {
            if (search === this.state.search) {       // don't apply old search results
                let { user, userId, selection } = this.state;
                let items = [];

                let userFound = false;
                if (Array.isArray(users)) {
                    for (let i = 0; i < users.length; ++i) {
                        let user = users[i];
                        items.push({ label: user.email || user.phone || user.username, user, value: user.username });
                        if (user.username === userId) {
                            selection = { label: user.email || user.phone || user.username, user, value: user.username };
                            userFound = true;
                        }
                    }
                }

                if (!userFound && user && user.userId === userId) {
                    selection = { label: user.email || user.phone || user.username, user, value: user.username };
                    items.push(selection);
                }

                items.sort((a, b) => {
                    let aLabel = a.label ? a.label.toLowerCase() : '';
                    let bLabel = b.label ? b.label.toLowerCase() : '';
                    return aLabel.localeCompare( bLabel );
                })

                if (this.props.enableAll) {
                    items.unshift({ label: 'All', value: '' });
                    if (!userId)
                        selection = { label: 'All', value: '' };
                }
                if (this.props.enableNone) {
                    items.unshift({ label: 'None', value: '' });
                    if (!userId)
                        selection = { label: 'None', value: '' };
                }

                //console.log("findUsers selection:", selection, items, user, userId, userFound );
                this.setState({ items, selection })
            }
        });
    }

    handleChange(selection) {
        console.log("handleChange:", selection);
        this.setState({ selection, userId: selection.value }, () => {
            if (this.props.onChange) this.props.onChange(selection.value)
            if (this.props.onChangeUser) this.props.onChangeUser(selection.user)
        });
    }

    onChangeSearch(e) {
        console.log("onChangeSearch:", e);
        if (e !== this.state.search) {
            if (e) {
                this.setState({ search: e }, this.findUsers.bind(this));
            } else {
                this.setState({ search: '' }, this.findUsers.bind(this));
            }
        }
    }

    render() {
        let { items, selection } = this.state;
        //console.log("render:", selection, items );
        return <FormControl style={this.props.style || { width: 300, margin: 5 }}>
            <Typography component="span" variant={'caption'} children={this.props.label || "Select User"} />
            <Select options={items} isSearchable={true} defaultOptions={false} disabled={this.props.disabled}
                onInputChange={this.onChangeSearch.bind(this)} value={selection}
                onChange={this.handleChange.bind(this)} />
        </FormControl>;
    }
}

export default SelectUser;
