import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment-timezone";

import { Provider } from "@apricityhealth/web-common-lib/components/Provider";
import Org from "@apricityhealth/web-common-lib/components/Org";
import { Patient } from "@apricityhealth/web-common-lib/components/Patient";

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import {
    getAppointments,
} from '@apricityhealth/web-common-lib/utils/Services';
import JSONView from '../JSONView'

function testFilter(filter, type) {
    if (filter === "" || filter === "*") return true;
    if (type && filter) {
        filter = filter.toLowerCase();
        if (type.note && type.note.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (type.category && type.category.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientAppointmentsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter: '',
            filtered: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;

        let { appContext, patientId } = this.props;
        if (patientId) {
            let args = [`patientId=${patientId}`,`getNotes=true`, 'all=true']
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            getAppointments(appContext, "*", args).then((content) => {
                let appointments = content.appointments;
                self.setState({ appointments, progress: null }, self.applyFilter.bind(self));
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    applyFilter() {
        let { appointments, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < appointments.length; ++i) {
            if (!testFilter(filter, appointments[i])) {
                continue;
            }
            filtered.push(appointments[i]);
        }
        this.setState({ filtered }, this.updateTable.bind(this));
    }

    updateTable() {
        const self = this;
        let { filtered } = this.state;
        let { appContext } = this.props;
        const columnData = [
            {
                id: 'orgId', numeric: false, width: 200, disabledPadding: false, label: 'Org', formatValue: (v) => {
                    return <Org appContext={appContext} orgId={v} />
                }
            },
            {
                id: 'patientId', numeric: false, width: 200, disabledPadding: false, label: 'Patient', formatValue: (v) => {
                    return <Patient appContext={appContext} patientId={v} />
                }
            },
            {
                id: 'providerId', numeric: false, width: 200, disabledPadding: false, label: 'Provider', formatValue: (v) => {
                    return <Provider appContext={appContext} userId={v} />
                }
            },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'category', numeric: false, disabledPadding: false, label: 'Category' },
            {
                id: 'start', numeric: false, disabledPadding: false, label: 'Start', formatValue: (v) => {
                    return moment(v).format("lll");
                }
            },
            {
                id: 'end', numeric: false, disabledPadding: false, label: 'End', formatValue: (v) => {
                    return moment(v).format("lll");
                }
            },
            {
                id: 'userId', numeric: false, width: 200, disabledPadding: false, label: 'Creator', formatValue: (v) => {
                    return <Provider appContext={appContext} userId={v} />
                }
            },


        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='createdAt'
            columnData={columnData}
            data={filtered}
            rowsPerPage={25}
            title='Patient Appointments' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { filtered } = self.state;
        const data = filtered[s[0]];
        console.log(`selected `, data.dataId);
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <JSONView data={data} onClose={() => self.closeDialog()} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress, error } = this.state;
        const self = this
        let { filter } = this.state;
        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
        </div>;

        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                {table}
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientAppointmentsView;
