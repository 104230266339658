import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
    AppBar,
    Toolbar,
    Button,
    Typography,
    Tooltip,
    Paper,
    DialogContent,
    Dialog,
    DialogActions
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';
import NavigationClose from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from "@apricityhealth/web-common-lib/components/User";
import Provider from "@apricityhealth/web-common-lib/components/Provider";
import {
    isArrayValid,
    loadNotes,
    deleteNote,
    getBlob
} from '@apricityhealth/web-common-lib/utils/Services';
import XMLViewer from 'react-xml-viewer'
import ReactJson from 'react-json-view';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function testFilter(filter, type) {
    if (filter === "" || filter === "*") return true;
    if (type && filter) {
        filter = filter.toLowerCase();
        if (type.note && type.note.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (type.category && type.category.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (Array.isArray(type.source)) {
            let resourceId = (type.source.find((e) => e.resourceId) || { resourceId: '' }).resourceId;
            if (resourceId && resourceId.toLowerCase().indexOf(filter) >= 0)
                return true;
        }
    }
    return false;
}

export class PatientNotesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter: '',
            filtered: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;

        let { appContext, patientId } = this.props;
        if (patientId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            loadNotes(appContext, patientId).then((content) => {
                self.setState({ content: content.notes, progress: null }, self.applyFilter.bind(self));
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered }, this.updateTable.bind(this));
    }

    updateTable() {
        const self = this;
        let { appContext } = this.props;
        let { filtered } = this.state;
        const dataColumns = [
            { id: 'createdAt', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Create Time' },
            { id: 'category', numeric: false, disabledPadding: false, label: 'Category' },
            {
                id: 'userId', numeric: false, disabledPadding: false, label: 'Author',
                formatValue: (v, r) => {
                    if (v) return <User
                        appContext={appContext}
                        userId={v}
                        userIdHidden={true}
                    />;
                    if (r.providerId) return <Provider appContext={appContext} providerId={r.providerId} />
                }
            },
            { id: 'note', numeric: false, disabledPadding: false, label: 'Note' },

        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='createdAt'
            columnData={dataColumns}
            data={filtered}
            rowsPerPage={25}
            title='Patient Notes' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext } = self.props;
        let { filtered } = self.state;
        const data = filtered[s[0]];
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <NoteView appContext={appContext} note={data} onClose={() => self.closeDialog()} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        self.setState({ dialog });
    }

    closeDialog() {
        this.loadContent();
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress, error } = this.state;
        const self = this
        let { filter } = this.state;
        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
        </div>;

        return <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td>
                        {filterInput}
                    </td>
                    <td align='right'>
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {table}
                        {dialog}
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}

class NoteView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: props.note,
            dialog: null,
            progress: null
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.note?.noteId !== prevProps.note?.noteId)) {
            this.setState({ note: this.props.note })
        }
    }

    deleteNote() {
        let self = this;
        let { appContext } = this.props;
        let { note } = this.state;
        this.setState({ progress: <CircularProgress /> });
        if (note && note.patientId && note.noteId) {
            deleteNote(appContext, note.patientId, note.noteId).then(() => {
                self.props.onClose()
            }).catch(function (error) {
                console.log("Notes save error:", getErrorMessage(error));
                self.setState({ progress: getErrorMessage(error) });
            });
        } else {
            this.setState({ progress: "Note is missing information." });
        }
    }

    async loadAttachment(attachment) {
        if (attachment && attachment.blobId) {
            const { appContext } = this.props;
            try {
                let blob = await getBlob(appContext, attachment.blobId);
                if (blob) {
                    this.showAttachment({ ...attachment, blob });
                }
            } catch (error) {
                console.log("Notes save error:", getErrorMessage(error));
                this.setState({ progress: getErrorMessage(error) });
            }
        }
    }

    showAttachment(attachment) {
        let self = this;
        this.setState({
            dialog:
                <Dialog
                    maxWidth={'md'}
                    fullWidth={true}
                    align="left"
                    model="false"
                    open={true}
                >
                    <DialogContent>
                        <Paper key="2" style={{ width: '100%' }}>
                            {attachment && (attachment.mimeType === "pdf" || attachment.mimeType === "application/pdf") && <iframe key={attachment._id} style={{ height: 800, width: 800 }} title="attachment" src={"data:application/pdf;base64," + attachment.blob.data} />}
                            {attachment && (attachment.mimeType === "xml" || attachment.mimeType === "application/xml") && <div key={attachment._id} style={{ width: 1000 }}><XMLViewer xml={Buffer.from(attachment.blob.data, "base64").toString("utf8")} /></div>}
                            {/* <iframe key={attachment._id} style={styles.report} title="attachment" src={`data:${attachment.blob?.mimeType};base64,` + attachment.blob?.data} /> */}
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => self.setState({ dialog: null })}>Ok</Button>
                    </DialogActions>
                </Dialog>
        });
    }


    render() {
        let { note, progress, dialog } = this.state;
        let { appContext } = this.props;

        return (<>
            <AppBar style={styles.appBar} position="static" >
                <Toolbar>
                    <IconButton onClick={() => this.props.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Note details</Typography>
                </Toolbar>
            </AppBar>
            {progress}
            <div style={styles.right}><Tooltip title="Delete Note"><IconButton onClick={() => { this.deleteNote(); }}><DeleteIcon /></IconButton></Tooltip></div>

            <Paper style={styles.jsonPaper}>
                {note.userId ? <><strong>Author: </strong><User appContext={appContext} userId={note.userId} userIdHidden={true} /> </> : ""}
                {note.providerId ? <><strong>Author: </strong><Provider appContext={appContext} providerId={note.providerId} /> </> : ""}

                {isArrayValid(note.attachments) &&
                    <table className="simple_table" style={{ width: "100%" }}>
                        <thead>
                            <tr><td style={{ textAlign: "center" }} colSpan={3}>Attachments</td></tr>
                            <tr><th>Author</th><th>Title</th><th>Mime</th></tr>
                        </thead>
                        <tbody>
                            {note.attachments.map((attachment) => {
                                return <tr><td>{attachment.author}</td><td>{attachment.title}</td><td>{attachment.mimeType}<Tooltip title="Show Attachment"><IconButton onClick={() => { this.loadAttachment(attachment); }}><VisibilityIcon /></IconButton></Tooltip></td></tr>
                            })

                            }
                        </tbody>
                    </table>
                }
                <ReactJson
                    src={{ note }}
                    collapsed={false}
                    name={"Note"}
                    collapseStringsAfterLength={64}
                    displayDataTypes={false}
                />
                {dialog}
            </Paper>
        </>)
    }

}


const styles = {
    appBar: {
        width: '700px',
        maxWidth: '700px'
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        maxWidth: 700,
        borderRadius: 6,
        wordWrap: "normal"
    },
    delete: {
        margin: 5,
        "textAlign": "right"
    },
    report: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: '70vh',
        alignContent: 'center',
        alignItems: 'center'
    },
    right: {
        justifyContent: "flex-end",
        width: '100%',
        display: "flex",
    },
}


export default PatientNotesView;
