import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Config from '@apricityhealth/web-common-lib/Config';
import ChangePassword from '@apricityhealth/web-common-lib/components/ChangePassword';
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import React, { Component } from 'react';

import {
    AppBar, Toolbar, Typography, Select, MenuItem, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Dialog, FormControl, InputLabel, CircularProgress, IconButton, TextField, Button, Paper, Tooltip, Tabs, Tab,
    FormControlLabel, Checkbox, Menu
} from '@material-ui/core/';
import {
    getAccount,
    unlockUser,
    getPatients,
    getProviders,
    getTerms
} from '@apricityhealth/web-common-lib/utils/Services';

import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import UnlockIcon from '@material-ui/icons/LockOpen';
import ChangeIcon from '@material-ui/icons/Lock';
import ResetIcon from '@material-ui/icons/Restore';
import LinkIcon from '@material-ui/icons/Link';
import OptInIcon from '@material-ui/icons/AlternateEmail';
import ResendInviteIcon from '@material-ui/icons/Email';
import NavigationClose from '@material-ui/icons/Close';
import LogsIcon from '@material-ui/icons/DataUsage';

import ReactJson from 'react-json-view';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import JSONView from './JSONView';
import { isArrayValid } from "../utils/Utils";

class UserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            error: null,
            currentTab: '0',
            account: {
                groups: [],
                email: '',
                phone: ''
            },
            terms: []
        }
    }
    componentDidMount() {
        console.log("componentDidMount:", this);
        this.getAccount();
    }

    componentDidUpdate(oldProps) {
        if (this.props.username !== oldProps.username) {
            this.getAccount();
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    closeUser() {
        console.log("closeAction()", this);
        this.props.onClose();
    }

    unlockUser() {
        const self = this;
        let { account } = this.state;
        if (account && account.email) {
            // note, setting the answers state here since we don't want a new answer to disappear while we are saving
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            unlockUser(self.props.appContext, account.email).then(() => {
                self.setState({ progress: null, error: <div>{account.email} Account unlocked</div> });
            }).catch((err) => {
                self.setState({ progress: null, error: getErrorMessage(err) });
            });
        }

    }

    getAccount() {
        const { appContext,username } = this.props;
        if (username) {
            // note, setting the answers state here since we don't want a new answer to disappear while we are saving
            this.setState({ account: null, progress: <CircularProgress size={20} />, error: null });
            Promise.all([
                getAccount(appContext, username),
                getTerms(appContext, { userId: username } ),
                getPatients(appContext, { userId: username }),
                getProviders(appContext, { userId: username })
            ]).then(([account, { terms }, patients, providers ]) => {
                console.log(`getAccount result:`, account, terms, patients, providers )
                this.setState({ account, terms, patients, providers, progress: null });
                window.history.replaceState(null, null, `/users/${username}`);
            }).catch((err) => {
                console.log("getAccount error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else {
            this.setState({ account: { groups: [], email: '', phone: '' } })
        }
    }

    resetPassword() {
        console.log("saveType()", this);

        const self = this;
        const { account } = self.state;

        if (account.username) {
            // note, setting the answers state here since we don't want a new answer to disappear while we are saving
            self.setState({ progress: <CircularProgress size={20} />, error: null });

            const resetPassword = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/resetPassword`,
                method: 'POST',
                headers: { "Authorization": self.props.appContext.state.idToken },
                data: { Username: account.username }
            };

            console.log("resetPassword:", resetPassword);
            Axios(resetPassword).then(function (response) {
                console.log("resetPassword response:", response);
                self.setState({ progress: null });
            }).catch(function (error) {
                console.log("resetPassword error:", error);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    resendInvite() {
        console.log("optIn()", this);

        const self = this;
        const { account } = self.state;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        const resendInvite = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/resendInvite`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: { username: account.username }
        };

        console.log("resendInvite:", resendInvite);
        Axios(resendInvite).then((response) => {
            console.log("resendInvite response:", response);
            self.setState({ progress: null });
        }).catch((error) => {
            console.log("resendInvite error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    optIn() {
        console.log("optIn()", this);

        const self = this;
        const { account } = self.state;
        account.optIn = true;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        const saveUser = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: account
        };

        console.log("saveUser:", saveUser);
        Axios(saveUser).then(function (response) {
            console.log("saveUser response:", response);
            self.setState({ progress: null, account: response.data.user });
        }).catch(function (error) {
            console.log("saveUser error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    saveUser() {
        console.log("saveType()", this);

        const self = this;
        const { account } = self.state;

        if (!account.username) {
            if (account.email)
                account.username = account.email;
            else if (account.phone)
                account.username = account.phone;
        }

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress size={20} />, error: null });

        const saveUser = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: account
        };

        console.log("saveUser:", saveUser);
        Axios(saveUser).then(function (response) {
            console.log("saveUser response:", response);
            self.setState({ progress: null, account: response.data.user });
        }).catch(function (error) {
            console.log("saveUser error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deleteUser() {
        console.log("deleteUser()", this);
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete User: {`${self.state.account.username}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteUser() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    confirmDeleteUser() {
        const self = this;
        const account = self.state.account;

        self.setState({ dialog: null });
        if (account.username) {
            const deleteUser = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/users/${account.username}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress size={20} />, error: null });
            console.log("deleteUser:", deleteUser);
            Axios(deleteUser).then(function (response) {
                console.log("deleteUser response:", response);
                self.setState({ progress: null });
                self.closeUser();
            }).catch(function (error) {
                console.log("deleteUser error:", error.response);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onGroupsChanged(data) {
        console.log("onGroupsChanged():", this, data);
        var { account } = this.state;

        account.groups = [];
        for (let i = 0; i < data.length; ++i)
            account.groups.push(data[i].group);
        this.setState({ account });
    }

    showCode() {
        const { account } = this.state;
        let dialog = <Dialog open={true} maxWidth='md' fullWidth={true} onClose={() => this.setState({ dialog: null })}>
            <DialogContent>
                <ReactJson src={account}
                    collapsed={3} name="account" collapseStringsAfterLength={64} displayDataTypes={false} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => this.setState({ dialog: null })}>OK</Button>
            </DialogActions>
        </Dialog>;
        this.setState({ dialog });
    }

    render() {
        const self = this;
        const { account, terms, patients, providers, progress, dialog, error, currentTab, redirect } = this.state;
        const { appContext } = this.props;

        if ( redirect ) {
            return redirect;
        }
        if (!account) {
            return null;
        }

        const groupColumns = [
            {
                id: 'group', numeric: false, disabledPadding: false, label: 'Group', editControl: (table, value, row, index, id) => {
                    return <FormControl style={styles.select}>
                        <InputLabel>Group</InputLabel>
                        <Select value={value}
                            onClick={(e) => {
                                table.handleClickEdit(e);
                            }}
                            onChange={(e) => {
                                table.handleOnChange(id, row._index, 'group')(e);
                            }} >
                            <MenuItem value={'administrators'}>Administrators</MenuItem>
                            <MenuItem value={'support'}>Support</MenuItem>
                            <MenuItem value={'employer'}>Employer</MenuItem>
                            <MenuItem value={'auditors'}>Auditors</MenuItem>
                            <MenuItem value={'nophi'}>No PHI</MenuItem>
                            <MenuItem value={'providers'}>Providers</MenuItem>
                            <MenuItem value={'patients'}>Patients</MenuItem>
                            <MenuItem value={'experts'}>Experts</MenuItem>
                            <MenuItem value={'debug'}>Debug</MenuItem>
                        </Select></FormControl>;
                }
            },
        ];
        const termsColumns = [
            { id: 'termId', label: 'Term Id' },
            { id: 'planId', label: 'Plan', formatValue: (v) => <Plan appContext={appContext} planId={v} /> },
            { id: 'accepted', label: 'accepted' },
            { id: 'acceptedVersion', label: 'Accepted Version' },
            { id: 'acceptedDate', label: 'Accepted Date' },
            { id: 'clientIp', label: 'Client IP'}
        ]

        let appBar = this.props.onClose ?
            <AppBar  position="static">
                <Toolbar>
                    <IconButton onClick={this.closeUser.bind(this)}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">User Details</Typography>
                </Toolbar>
            </AppBar> : null;

        let tabs = [];
        let groups = [];
        for (let i = 0; i < account.groups.length; ++i)
            groups.push({ group: account.groups[i] });

        let baseEdits = [];
        baseEdits.push(<div><TextField key={'email'} style={styles.question} label="Email" value={account.email}
            onChange={(e) => { account.email = e.target.value; self.setState({ account }); }} /></div>);

        baseEdits.push(<div><TextField key={'phone'} style={styles.question} label="Phone" value={account.phone}
            onChange={(e) => { account.phone = e.target.value; self.setState({ account }); }} /></div >);

        let headerItems = <table style={{ width: '100%' }}><tbody>
            <tr><td>
            </td><td align='right' valign='top'>
                    {error}
                    <Tooltip title="Save User"><IconButton disabled={progress !== null} onClick={this.saveUser.bind(this)}>{progress ? progress : <SaveIcon />}</IconButton></Tooltip>
                    <Tooltip title='Open Logs'>
                                <IconButton id='logs' onClick={() => this.setState({ redirect: <Navigate to={`/logs?userId=${account.username}`} />})}><LogsIcon /></IconButton>
                    </Tooltip>
                    {isArrayValid(patients) && <Tooltip title='Open Patient'><IconButton onClick={(e) => {
                        if ( patients.length > 1) {
                            const menu = <Menu anchorEl={e.currentTarget} open={true} onClose={this.onCloseDialog.bind(this)}>
                                {patients.map((p) => <MenuItem><Link to={`/patients/${p.patientId}`}>{`${p.firstName} ${p.lastName}`}</Link></MenuItem>)} 
                            </Menu>;
                            this.setState({dialog: menu});
                        } else {
                            this.setState({redirect: <Navigate to={`/patients/${patients[0].patientId}`} />});
                        }
                    }}><LinkIcon /></IconButton></Tooltip>}
                    {isArrayValid(providers) && <Tooltip title='Open Provider'><IconButton onClick={(e) => {
                        if (providers.length > 1) {
                            const menu = <Menu anchorEl={e.currentTarget} open={true} onClose={this.onCloseDialog.bind(this)}>
                                {providers.map((p) => <MenuItem><Link to={`/providers/${p.providerId}`}>{`${p.firstName} ${p.lastName}`}</Link></MenuItem>)} 
                            </Menu>;
                            this.setState({dialog: menu});
                        } else {
                            this.setState({redirect: <Navigate to={`/providers/${providers[0].providerId}`} />});
                        }
                    }}><LinkIcon /></IconButton></Tooltip>}
                    <Tooltip title="Change Password"><IconButton onClick={(e) => {
                        self.setState({
                            dialog:
                                <ChangePassword appContext={appContext} username={appContext.state.username}
                                    onDone={() => { self.setState({ dialog: null }); }} />
                        });
                    }}><ChangeIcon /></IconButton></Tooltip>
                    <Tooltip title='OptIn Users for SMS'><IconButton disabled={!account.phone} onClick={this.optIn.bind(this)}><OptInIcon /></IconButton></Tooltip>
                    <Tooltip title='Unlock Account'><IconButton onClick={this.unlockUser.bind(this)}><UnlockIcon /></IconButton></Tooltip>
                    <Tooltip title='Reset Password'><IconButton onClick={this.resetPassword.bind(this)}><ResetIcon /></IconButton></Tooltip>
                    <Tooltip title='Resend Invite'><IconButton onClick={this.resendInvite.bind(this)}><ResendInviteIcon /></IconButton></Tooltip>
                    <Tooltip title="Show JSON"><IconButton onClick={this.showCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                    <Tooltip title="Delete User"><IconButton onClick={this.deleteUser.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                </td></tr>
        </tbody></table>;

        tabs.push(<div key='Groups' style={styles.sectionRow}>
            <Paper style={styles.sectionPaperContainer}>
                <EnhancedTable
                    onDataChanged={this.onGroupsChanged.bind(this)}
                    disableMultiSelect={false}
                    orderBy='group'
                    columnData={groupColumns}
                    data={groups}
                    rowsPerPage={5}
                    title='Groups' />
            </Paper>
        </div>);

        tabs.push(<div key='Accepted Terms' style={styles.sectionRow}>
            <Paper style={styles.sectionPaperContainer}>
                <EnhancedTable
                    disableSelect={true}
                    orderBy='acceptedDate'
                    columnData={termsColumns}
                    data={terms}
                    rowsPerPage={5}
                    title='Accepted Terms' />
            </Paper>
        </div>)

        tabs.push(<div key='User Info' style={styles.sectionRow}>
            <Paper style={styles.sectionPaperContainer}>
                <Typography style={styles.sectionHeader} variant="h6">User Info</Typography>
                <div style={styles.userRow}>
                    {isArrayValid(patients) ? <JSONView name='patients' data={patients} /> : null}
                    {isArrayValid(providers) ? <JSONView name='providers' data={providers} /> : null}
                </div>
            </Paper>
        </div>);

        let editUser =
            <div style={styles.table}>
                <div style={styles.sectionRow}>
                    <Paper style={styles.sectionPaperContainer}>
                        <Typography style={styles.sectionHeader} variant="h6">User Details</Typography>
                        <div style={styles.userRow}>
                            <table style={{ margin: 5, width: '100%' }}><tbody>
                                <tr><td>User ID:</td><td>{account.username}</td></tr>
                                <tr><td>Create Date:</td><td>{account.createDate}</td></tr>
                                <tr><td>Modified Date:</td><td>{account.modifiedDate}</td></tr>
                                <tr><td>User Status:</td><td>{account.userStatus}</td></tr>
                            </tbody></table>
                            <br />
                            {baseEdits}
                        </div>
                        <div>
                            <FormControlLabel style={{ margin: 5, width: '50%' }} control={<Checkbox checked={account.mfaEnabled} onChange={(e, v) => {
                                account.mfaEnabled = v; self.setState({ account });
                            }} />} label='Enable MFA ' />
                        </div>
                    </Paper>
                </div>
            </div>;

        return (
            <div styles={styles.main}>
                {appBar}
                {headerItems}
                {editUser}
                <div style={styles.sectionRow}>
                    <Tabs style={styles.tab} variant="scrollable" value={currentTab}
                        onChange={(e, t) => {
                            self.setState({ currentTab: `${t}` });
                        }} >
                        {tabs.map((e, i) => <Tab key={e.key} label={e.key} style={{ color: 'white' }} value={`${i}`} />)}
                    </Tabs>
                    {tabs[currentTab]}
                    <br />
                </div>
                {dialog}
            </div>
        );
    }
}

const styles = {
    main: {
        textAlign: 'left',
        width: '70vw'
    },
    userHeader: {
        marginLeft: 12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    userRow: {
        marginLeft: 15,
        paddingBottom: 15
    },
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    question: {
        margin: 10,
        width: 350
    },
    select: {
        margin: 10,
        width: 200
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "#FF9800"
    },
    table: {
        width: "50vw"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    sectionPaperContainer: {
        margin: 10
    },
    sectionRow: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15,
        marginBottom: 15,
    },
    sectionHeader: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '64px',
        paddingLeft: '24px'
    },
}

export default UserView;
