import React from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@material-ui/core/';

import { addPatientProvider } from "@apricityhealth/web-common-lib/utils/Services";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import SelectProvider from "@apricityhealth/web-common-lib/components/SelectProvider";

class EditPatientProviderDialog extends React.Component {
    constructor(props) {
        super(props);
        if (! props.appContext ) throw new Error("appContext required property!")
        if (! props.patientId ) throw new Error("patientId required property!");

        this.state = {
            providerId: props.providerId || '',
            progress: null,
            error: null
        };
    }

    onDone() {
        const self = this;
        const { providerId } = this.state;
        const { appContext, patientId } = this.props;

        this.setState({progress: <CircularProgress />, error: null});
        addPatientProvider( appContext, patientId, { providerId } ).then(() => {
            this.setState({progress: null}, this.props.onDone);
        }).catch((err) => {
            self.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    render() {
        const { providerId, progress, error } = this.state;

        return <Dialog open={true} PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogTitle>Add Provider</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }}>
                <SelectProvider appContext={this.props.appContext} providerId={providerId} onChange={(providerId) => {
                    this.setState({ providerId });
                }} />
                <br />
                <span style={{color: 'red'}}>{error}</span>
                {progress}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>Add</Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    button: {
        margin: 5
    }
}

export default EditPatientProviderDialog;