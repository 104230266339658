import '../styles/devices.css'
import React, { Component } from 'react';
import {
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    TextField,
    CircularProgress,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Input,
    FormControlLabel,
    Checkbox,
    Tooltip,
    FormLabel
} from '@material-ui/core/';
import {
    ClearAllOutlined,
    Send,
    Save,
    Code,
    Delete,
    Close,
} from '@material-ui/icons';
import {
    isArrayValid,
    saveReportSchedule,
    deleteReportSchedule,
    sendReportSchedule,
    getAvailableReports
} from '@apricityhealth/web-common-lib/utils/Services';

import JSONDataDialog from '../dialogs/JSONDataDialog';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Error from "@apricityhealth/web-common-lib/components/Error";

import User from "@apricityhealth/web-common-lib/components/User";
import SelectUser from "@apricityhealth/web-common-lib/components/SelectUser";

import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import ReportParameters from './ReportParameters';

const DATE_FORMAT = 'LLLL';
class SendToDialog extends Component {
    constructor(props) {
        super(props);

        if (! props.onCancel ) throw new Error("SendToDialog missing onCancel property!");
        if (! props.onDone ) throw new Error("SendToDialog missing onDone property!");

        this.state = {
            sendTo: JSON.parse(JSON.stringify(props.sendTo))
        };
    }

    render() {
        const { sendTo } = this.state;
        const { appContext } = this.props;
        return <Dialog open={true} PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }}>
                <FormControl style={{ width: 300, margin: 5}} >
                    <InputLabel>Role</InputLabel>
                    <Select style={styles.entry} label='Role'
                        value={sendTo.roleType}
                        input={<Input name="age" id="role" />}
                        onChange={(e) => {
                            sendTo.roleType = e.target.value; this.setState({ sendTo });
                        }}>
                        <MenuItem value='provider'>Provider</MenuItem>
                        <MenuItem value='patient'>Patient</MenuItem>
                    </Select>
                </FormControl>
                <SelectUser appContext={appContext}
                    group={sendTo.roleType}
                    userId={sendTo.userId}
                    onChange={(userId) => {
                        sendTo.userId = userId;
                        this.setState({ sendTo })
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
                <Button variant="contained" style={styles.button} onClick={() => this.props.onDone(sendTo)}>Ok</Button>
            </DialogActions>
        </Dialog>;
    }

}

class ReportScheduleView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportSchedule: { ...props.reportSchedule },
            dialog: null,
            progress: null,
            validated: true,
            reports: [],
            remove: []
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.reportSchedule !== oldProps.reportSchedule)
            this.setState({ reportSchedule: { ...this.props.reportSchedule } });
    }

    componentWillMount() {
        this.loadContent();
    }


    loadContent() {
        const self = this;
        const { appContext } = this.props;
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        getAvailableReports(appContext, "*").then((reports) => {
            self.setState({ reports, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    onClose() {
        this.props.onClose();
    }

    onSave() {
        const self = this;
        let { reportSchedule } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        saveReportSchedule(appContext, reportSchedule).then((response) => {
            self.setState({ progress: null });
        }).catch(function (error) {
            console.error("save reportSchedule error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    onDelete() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Report Schedule</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Cancel</Button>
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onCancel() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        let { reportSchedule } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress size={20} /> });
        deleteReportSchedule(appContext, reportSchedule.scheduleId).then((response) => {
            self.onClose();
        }).catch((error) => {
            console.error("delete reportSchedule error:", error);
            self.setState({ progress: error.message });
        });
    }

    onShowCode() {
        this.setState({
            dialog: <JSONDataDialog
                appContext={this.props.appContext}
                dataType={this.state.reportSchedule}
                onDone={this.onCloseDialog.bind(this)} />
        });
    }

    onRemoveSendTo(item) {
        const { remove } = this.state;
        remove.push(item);
        this.setState({ remove, dialog: <Dialog open={true}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Please confirm you would like to remove {remove.length} users?</DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                    <Button onClick={() => {
                        const { reportSchedule } = this.state;
                        for(let i=0;i<remove.length;++i) {
                            let userId = remove[i].userId;
                            let j = reportSchedule.sendTo.findIndex((e) => e.userId === userId);
                            if ( j >= 0 ) {
                                reportSchedule.sendTo.splice(j, 1);
                            }
                        }
                        this.setState({ remove: [], dialog: null, reportSchedule });
                    }}>Confirm</Button>
                </DialogActions>
        </Dialog>});

    }

    onAddSendTo(add) {
        const { appContext } = this.props;
        const { reportSchedule } = this.state;
        if (!reportSchedule.sendTo) reportSchedule.sendTo = [];
        add.roleType = "provider";

        const dialog = <SendToDialog appContext={appContext} 
            sendTo={add}
            onDone={(add) => {
                reportSchedule.sendTo.push(add);
                this.setState({ dialog: null, reportSchedule })
            }}
            onCancel={this.onCloseDialog.bind(this)} />
        this.setState({ dialog });
    }

    onSendSelected(t) {
        const { appContext } = this.props;
        const { selected, data } = t.state;

        if (selected.length > 0) {
            const { reportSchedule } = this.state;

            let schedule = { ...reportSchedule, sendTo: [] };
            for(let i=0;i<selected.length;++i) {
                schedule.sendTo.push(data[selected[i]]);
            }

            t.setState({selected: [] });
            this.setState({dialog: <Dialog open={true}>
                    <DialogTitle>Confirm Send</DialogTitle>
                    <DialogContent>Please confirm you would like to send this report to {schedule.sendTo.length} users?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                        <Button onClick={() => {
                            this.setState({progress: <CircularProgress size={20} />, error: null, dialog: null});
                            sendReportSchedule(appContext, { schedule }).then((result) => {
                                console.log("sendReportSchedule result:", result );
                                this.setState({progress: null})
                            }).catch((err) => {
                                this.setState({progress: null, error: getErrorMessage(err)});
                            })
                        }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            });
        }
    }

    onReportSelected(reportName) {
        let { reports, reportSchedule } = this.state;
        let report = reports && reportName ? reports.find((report) => report.reportName === reportName) : null;
        if (report) {
            reportSchedule.reportName = report.reportName;
            if (!reportSchedule.args) reportSchedule.args = {};
            if (isArrayValid(report.args)) {
                report.args.forEach(arg => {
                    if (arg.defaultValue !== undefined && reportSchedule.args[arg.name] === undefined) //if not set then set to the default
                        reportSchedule.args[arg.name] = arg.defaultValue;
                });
            }
        }
        this.setState({ reportSchedule })
    }

    updateArgs(args) {
        //note.. these args have values.. we only want to set the name and value on the schedule
        let { reportSchedule } = this.state;
        if (!reportSchedule.args) reportSchedule.args = {};
        if (isArrayValid(args)) {
            args.forEach(arg => {
                reportSchedule.args[arg.name] = arg.value;
            });
        }
        this.setState({ reportSchedule })
    }

    render() {
        let { reportSchedule, dialog, progress, error, reports } = this.state;
        let { appContext } = this.props;

        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => this.onClose()}>
                        <Close />
                    </IconButton>
                    <div>Report Schedule Details</div>
                </Toolbar>
            </AppBar> : null;

        const columnData = [
            {
                id: 'userId', numeric: false, disabledPadding: false, label: 'User', editControl: (table, v, sendTo) => {
                    return <User appContext={appContext} userIdHidden={true} userId={v} />;
                }
            },
            { id: 'roleType', numeric: false, disabledPadding: false, label: 'Role' },
        ];

        if (reportSchedule.batch === undefined) reportSchedule.batch = false;
        if (!reportSchedule.sendTo) reportSchedule.sendTo = [];
        if (reportSchedule.intervalType === undefined) reportSchedule.intervalType = 'day';
        if (!reportSchedule.recipe) reportSchedule.recipe = 'html';
        if (reportSchedule.reportName === undefined) reportSchedule.reportName = '';

        if (reportSchedule.contactMethod === undefined) reportSchedule.contactMethod = 'userPreference';

        const sendToTable = <EnhancedTable
            onActions={(t, numSelected, actions) => {
                if ( numSelected > 0 ) {
                    actions.push(<Tooltip key={actions.length} title='Send Report Now'><IconButton onClick={this.onSendSelected.bind(this, t)}><Send /></IconButton></Tooltip>)
                }
            }}
            onTable={table => this._types = table}
            onAdd={this.onAddSendTo.bind(this)}
            onDelete={this.onRemoveSendTo.bind(this)}
            orderBy='userId'
            columnData={columnData}
            rowsPerPage={10}
            data={JSON.parse(JSON.stringify(reportSchedule.sendTo))}
            title='Send To Users' />;

        let report = reports && reportSchedule.reportName ? reports.find((report) => report.reportName === reportSchedule.reportName) : null;
        let recipes = report && report.recipes && report.recipes.split(",");
        if (report && isArrayValid(report.args)) {
            if (!reportSchedule.args) reportSchedule.args = {};
            report.args.forEach(arg => {
                if (reportSchedule.args[arg.name] !== undefined) {
                    arg.value = reportSchedule.args[arg.name];
                }
            });
        }
        let selectedReport = reports.find((r) => r.reportName === reportSchedule.reportName);
        let selectedReportDescription = selectedReport ? selectedReport.description : ""
        return (
            <div>
                {appBar}
                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ marginLeft: "6px" }} >Last Sent: {reportSchedule.lastSent && Moment(reportSchedule.lastSent).format(DATE_FORMAT)}</div>
                                    <div style={{ color: 'red', dislay: "flex", whiteSpace: 'pre-wrap', maxWidth: '10rem' }}>{error}</div>
                                    {reportSchedule && reportSchedule.error && <div style={{ color: 'red', dislay: "flex", whiteSpace: 'pre-wrap', maxWidth: '30rem' }}><Error>{reportSchedule.error}</Error></div>}
                                </td>
                                <td valign="top" align="right">
                                    {reportSchedule && reportSchedule.error &&
                                        <Tooltip title='Clear Error'>
                                            <IconButton onClick={() => {
                                                reportSchedule.error = null;
                                                this.setState({ reportSchedule });
                                            }}><ClearAllOutlined />
                                            </IconButton>
                                        </Tooltip>}
                                    <Tooltip title='Save'><IconButton disabled={progress !== null} onClick={this.onSave.bind(this, null)}>{progress || <Save />}</IconButton></Tooltip>
                                    <Tooltip title='Show Code'><IconButton onClick={this.onShowCode.bind(this)}><Code /></IconButton></Tooltip>
                                    <Tooltip title='Delete'><IconButton onClick={this.onDelete.bind(this)}><Delete /></IconButton></Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <FormControlLabel control={<Checkbox checked={reportSchedule.enabled} onChange={(e, v) => {
                                        reportSchedule.enabled = v;
                                        this.setState({ reportSchedule });
                                    }} />} label={"Enabled"} />

                                    <FormControlLabel control={<Checkbox checked={reportSchedule.disableSkipAhead} onChange={(e, v) => {
                                        reportSchedule.disableSkipAhead = v;
                                        this.setState({ reportSchedule });
                                    }} />} label={"Disable Skip Ahead"} />

                                    <FormControlLabel control={<Checkbox checked={reportSchedule.batch} onChange={(e, v) => {
                                        reportSchedule.batch = v;
                                        if (!v && reportSchedule.sendLink)
                                            reportSchedule.sendLink = false;        // only valild for batch 
                                        this.setState({ reportSchedule });
                                    }} />} label={"Batch"} />

                                    <FormControlLabel disabled={reportSchedule.batch !== true} control={<Checkbox checked={reportSchedule.sendLink} onChange={(e, v) => {
                                        reportSchedule.sendLink = v;
                                        this.setState({ reportSchedule });
                                    }} />} label={"Send Link"} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <TextField style={styles.name} label="Name" value={reportSchedule.name}
                                        helperText="Used for the e-mail subject"
                                        onChange={(e) => { reportSchedule.name = e.target.value; this.setState({ reportSchedule }); }} />
                                    <br />
                                    <TextField
                                        multiline
                                        minRows={3}
                                        maxRows={3}
                                        style={styles.description}
                                        label="Description"
                                        value={reportSchedule.description}
                                        onChange={(e) => { reportSchedule.description = e.target.value; this.setState({ reportSchedule }); }} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <FormControl style={{ width: 600, margin: 5 }}>
                                        <InputLabel>Select Report</InputLabel>
                                        <Select value={reportSchedule.reportName}
                                            onChange={(e) => {
                                                this.onReportSelected(e.target.value)
                                            }}>
                                            {reports && reports.map((report) => {
                                                return <MenuItem key={report.reportName} value={report.reportName} >{report.reportName}</MenuItem>;
                                            })}
                                        </Select>
                                        <FormLabel style={{margin: 5}}>{selectedReportDescription}</FormLabel>
                                    </FormControl>
                                    <FormControl style={{ width: 150, margin: 5 }}>
                                        <InputLabel>Select Recipe</InputLabel>
                                        <Select value={reportSchedule.recipe}
                                            onChange={(e) => {
                                                reportSchedule.recipe = e.target.value;
                                                reportSchedule.isSeries = reportSchedule.recipe === 'json';
                                                this.setState({ reportSchedule })
                                            }}>

                                            {recipes && recipes.map((recipe) => {
                                                return <MenuItem key={recipe} value={recipe} > {recipe}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {report && <div style={styles.args}>
                                        <ReportParameters
                                            args={report.args}
                                            appContext={appContext}
                                            onChange={(args) => {
                                                this.updateArgs(args);
                                            }}
                                        /></div>}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            margin="normal"
                                            style={{ width: 200, margin: 5 }}
                                            ampm={true}
                                            label="Time of Report"
                                            value={reportSchedule.nextSend}
                                            onChange={(time) => { reportSchedule.nextSend = time; this.setState({ reportSchedule }) }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <FormControl style={{ width: 150, margin: 5 }}>
                                        <InputLabel htmlFor="freq">Frequency</InputLabel>
                                        <Select value={reportSchedule.intervalType}
                                            onChange={(e) => {
                                                reportSchedule.intervalType = e.target.value; this.setState({ reportSchedule });
                                            }}>
                                            <MenuItem value='year'>Annually</MenuItem>
                                            <MenuItem value='month'>Monthly</MenuItem>
                                            <MenuItem value='week'>Weekly</MenuItem>
                                            <MenuItem value='day'>Daily</MenuItem>
                                            <MenuItem value='hour'>Hourly</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField style={{ width: 100, margin: 5 }} label='Interval' value={reportSchedule.interval} type='number'
                                        onChange={(e) => { reportSchedule.interval = e.target.value; this.setState({ reportSchedule }) }} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2'>
                                    <FormControl style={{ width: 400, margin: 5 }}>
                                        <InputLabel htmlFor="contact">Contact Method</InputLabel>
                                        <Select style={{ width: 300 }}
                                            value={reportSchedule.contactMethod}
                                            input={<Input name="input" id="contactMethod" />}
                                            onChange={(e) => {
                                                reportSchedule.contactMethod = e.target.value; this.setState({ reportSchedule });
                                            }}>
                                            <MenuItem value='direct'>Direct Email</MenuItem>
                                            <MenuItem value='email'>Email</MenuItem>
                                            <MenuItem value='phone'>Phone</MenuItem>
                                            <MenuItem value='userPreference'>User Preference</MenuItem>
                                        </Select>
                                        <div style={{ fontSize: '12px', color: "grey", margin: '5' }}>Override the default preference on the sendto user</div>
                                    </FormControl>
                                    <br />
                                    {sendToTable}
                                    <br />
                                    {reportSchedule.batch ? <div><SelectUser
                                        appContext={appContext}
                                        style={{ margin: 5, width: 350 }}
                                        userId={reportSchedule.userId}
                                        onChange={(userId) => {
                                            reportSchedule.userId = userId;
                                            this.setState({ reportSchedule })
                                        }} />
                                        <div style={{ marginLeft: "6px", color: "gray", fontSize: '12px' }} >Select the user the report will be generated under for a batch job when not sending to any users.</div></div> : null}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </div >
        );
    }
}

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 5
    },
    description: {
        margin: 5,
        width: '95%'
    },
    div: {
        margin: 10
    },
    name: {
        margin: 5,
        width: '350px'
    },
    args: {
        width: '900px'
    },
    entry: {
        width: '140px'
    },
    table: {
        "width": "100%"
    },
}

export default ReportScheduleView;
