import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Paper, IconButton } from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';

import ReactJson from 'react-json-view';

class LogView extends Component {
    constructor(props){
        super(props)
        this.state = {
            log: {
                log:{}
            }
        }
    }
    componentDidMount() {
        console.log("componentDidMount:", this);
        if (this.props.log)
            this.loadLog(this.props.log);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.log && oldProps.log._id && this.props.log._id){
            if (this.props.log._id !== oldProps.log._id){
                this.loadLog(this.props.log);
            }
        }
    }

    closeLog() {
        console.log("closeAction()", this);
        this.props.onClose();
    }

    loadLog(log) {
        console.log("loadLog:", log);
        this.setState({
            log: log,
            progress: null,
            dialog: null
        });
    }
    render() {
        const self = this;
        const { log, progress, dialog } = self.state;

        let lines = [];
        for (let i = 0; i < log.log.length; ++i) {
            let v = log.log[i];
            try {
                if ( typeof v === 'string') {
                    let parsed = JSON.parse(v);
                    if ( parsed == null || typeof parsed !== 'object' )
                        throw new Error();
                    v = parsed;
                }
                if ( typeof v !== 'object' || v === null )
                    throw new Error();      // not a JSON object, display it normally..

                lines.push(<tr key={i}><td><ReactJson style={{width: '95%'}} name={null} src={v}
                    collapsed={3} collapseStringsAfterLength={64} displayDataTypes={false} /></td></tr>);
            }
            catch (err) {
                lines.push(<tr key={i}><td style={{ whiteSpace: 'pre-line' }}>{v}</td></tr>);
            }
        }

        let appBar = this.props.onClose ? <AppBar style={{ backgroundColor: "#FF9800", width: '100%' }} position="static">
            <Toolbar>
                <IconButton onClick={() => self.closeLog()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Log Details</Typography>
            </Toolbar>
        </AppBar> : null;

        if (log){
        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <h4>Details:</h4>
                    <table style={{ width: '100%' }}><tbody>
                        <tr><td>Log Time:</td><td>{log.createDate}</td></tr>
                        <tr><td>Tag:</td><td>{log.tag}</td></tr>
                        <tr><td>User ID:</td><td>{log.userId}</td></tr>
                        <tr><td>Request ID:</td><td>{log.requestId}</td></tr>
                        <tr><td>Elaspsed Time:</td><td>{log.elapsed}ms</td></tr>
                        <tr><td>Log Time:</td><td>{log.createDate}</td></tr>
                    </tbody></table>
                    <h4>Logs:</h4>
                    <Paper style={{ margin: 0, width: 700, padding: 5 }}>
                        <table style={{width: '100%'}} border="0"><tbody>
                            {lines}
                        </tbody></table>
                    </Paper>
                </div>
                {dialog}
                {progress}
            </div>
        );
        }else{
            return null
        }
    }
}

export default LogView;
