import { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import {
    useTheme,
    withStyles,
    Drawer,
    IconButton,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    Paper
} from '@material-ui/core/';
import {
    Refresh,
    Code
} from '@material-ui/icons';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import ReactJson from 'react-json-view';
import Error from '@apricityhealth/web-common-lib/components/Error';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import useLogging from '@apricityhealth/web-common-lib/hooks/useLogging';
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import styles from './RecentActivityPage.styles'
import Metric from './Metric';




/**
 * Page that displays raw metrics.
 */
function PatientReportPage({ classes }) {
    const theme = useTheme();
    const [showMetric, setShowMetric] = useState(false);
    const [currentMetric, setCurrentMetric] = useState({});
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'updatedAt', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));
    const [selectionModel, setSelectionModel] = useState([]);

    const [metrics, setMetrics] = useState([])
    const [showCode, setShowCode] = useState(false);

    let [{ data, loading, fetchMetrics, error }] = useLogging(true, []);


    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])

    function internalFetchData() {
        let args = [];
        args.push("EventName=ReportLinkAccess");
        try {
            fetchMetrics(null, args, { offset: page * pageSize, limit: pageSize })
        } catch (error) {
            console.error(`Error fetching metrics data `, error)
        }
    }

    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize])


    useEffect(() => {
        if (data) {
            let metricData = data?.metrics;
            if (metricData)
                metricData.forEach(element => {
                    element.id = element._id
                });
            setMetrics(metricData);
        }
    }, [data])

    function metricSelected(metric) {
        if (metric) {
            setCurrentMetric(metric);
            setSelectionModel([metric.id])
            setShowMetric(true);
        }
    }

    let table = useRef(null);

    function onClose() {
        if (table && table.current)
            table.current.setState({ selected: [] });        // unselect the question
        setShowMetric(false)
        setSelectionModel([]);
    }

    const columns = useMemo(() => {
        return [
            {
                field: 'updatedAt', headerName: 'Updated Date',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'metrics1',
                headerName: 'Provider',
                width: 250,
                renderCell: (params) => {
                    let v = params.row;
                    let metrics = v.metrics;
                    if (isArrayValid(metrics)) {
                        for (let i = 0; i < metrics.length; ++i) {
                            if (metrics[i].UserId !== undefined) {
                                let userId = metrics[i].UserId;
                                if (metrics)
                                    return <Provider userId={userId} />;
                            }
                        }
                    }
                    return '';
                },
            },
            {
                field: 'metrics2',
                headerName: 'Patient',
                width: 250,
                renderCell: (params) => {
                    let v = params.row;
                    let metrics = v.metrics;
                    for (let i = 0; i < metrics.length; ++i) {
                        if (metrics[i].Args !== undefined) {
                            let args = metrics[i].Args;
                            if (args && args.patientId)
                                return <Patient patientId={args.patientId} />;
                        }
                    }

                    return '';
                },
            },
            {
                field: 'metrics3',
                headerName: 'Report',
                width: 250,
                renderCell: (params) => {
                    let v = params.row;
                    let metrics = v.metrics;
                    if (isArrayValid(metrics)) {
                        for (let i = 0; i < metrics.length; ++i) {
                            if (metrics[i].ReportName !== undefined) {
                                let reportName = metrics[i].ReportName;
                                if (reportName)
                                    return reportName;
                                // }

                            }


                        }
                    }
                    return '';
                },
            },

        ];
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search} />
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {metrics && <DataGrid
                        className={classes.root}
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}

                        rowCount={500} //should be the total count from the query if there is one
                        rows={metrics}
                        onRowClick={({ row }) => metricSelected(row)}
                    />}
                </div>

                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Metrics</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={metrics}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Metrics"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showMetric}>
                    <Metric metric={currentMetric} onClose={() => onClose()} />
                </Drawer>
            </div>
        </div >
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(PatientReportPage)
