import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import TestsIcon from '@material-ui/icons/Grade';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ReportSchedulesIcon from '@material-ui/icons/CalendarViewDay';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ProviderIcon from '@material-ui/icons/AccountCircle'
import PersonOutline from '@material-ui/icons/PersonOutline';
import PeopleOutlined from '@material-ui/icons/PeopleOutlined';
import DirectMessagesIcon from '@material-ui/icons/MoveToInbox';
import OrgsIcon from '@material-ui/icons/Group';
import UsersIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import TocIcon from '@material-ui/icons/Toc';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LogsIcon from '@material-ui/icons/DataUsage';
import MetricsIcon from '@material-ui/icons/BarChart';
import ImagesIcon from '@material-ui/icons/Image';
import DevicesIcon from '@material-ui/icons/BluetoothConnected';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import RightsIcon from '@material-ui/icons/VpnKey';
import PromoteIcon from '@material-ui/icons/Input';
import ApricityLogo from './apricityTextLogo.png';
import CurrentOpsIcon from '@material-ui/icons/Storage';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import anthropic from './anthropic.png';

import {
    withStyles,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Drawer,
    List,
    ListSubheader
} from '@material-ui/core/';

import T from 'i18n-react';
import './App.css';
import { DRAWER_WIDTH } from './App';

class MenuView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: null,
            models: [],
            plan: null,
            selectedId: null
        }
    }

    isSelected(path) {
        let pathname = window.location.pathname.slice(1).split('/');
        pathname = pathname[0]
        return (path === pathname)
    }

    createReportsMenu() {
        let menuItems = [];
        let subList = <ListSubheader key={'generalcontent'} disableSticky>Reports</ListSubheader>
        menuItems.push(subList);

        menuItems.push(<MenuItem key='reports' component={Link} to="/reports" selected={this.isSelected('reports')}>
            <ListItemIcon ><InsertChartIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="reports" />} />
        </MenuItem>);
        menuItems.push(<MenuItem key='report_jobs' component={Link} to="/report_jobs" selected={this.isSelected('report_jobs')}>
            <ListItemIcon ><InsertChartIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="report_jobs" />} />
        </MenuItem>);
        menuItems.push(<MenuItem key='report_schedules' component={Link} to="/report_schedules" selected={this.isSelected('report_schedules')}>
            <ListItemIcon ><ReportSchedulesIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="report_schedules" />} />
        </MenuItem>);
        return menuItems;
    }


    createDeveloperMenu() {
        let menuItems = [];
        menuItems.push(<ListSubheader key='ListSubHeader' disableSticky>Developer</ListSubheader>)
        menuItems.push(<MenuItem id="logs" key='logs' component={Link} to="/logs" selected={this.isSelected('logs')}>
            <ListItemIcon ><LogsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="logs" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="notifications" key='notifications' component={Link} to="/notifications" selected={this.isSelected('notifications')}>
            <ListItemIcon ><LogsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="notifications" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="metrics" key='metrics' component={Link} to="/metrics" selected={this.isSelected('metrics')}>
            <ListItemIcon ><MetricsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="metrics" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="maintenancewindows" key='maintenancewindows' component={Link} to="/maintenancewindows" selected={this.isSelected('maintenancewindows')}>
            <ListItemIcon ><BuildIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="maintenancewindows" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="promote" key='promote' component={Link} to="/promote" selected={this.isSelected('promote')}>
            <ListItemIcon ><PromoteIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="promote" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="tests" key='tests' component={Link} to="/tests" selected={this.isSelected('tests')}>
            <ListItemIcon><TestsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="tests" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="patientLabData" key='patientLabData' component={Link} to="/patient_labs" selected={this.isSelected('patient_labs')}>
            <ListItemIcon ><TocIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="patient_labs" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="patientModelsView" key='patientModelsView' component={Link} to="/patient_models" selected={this.isSelected('patient_models')}>
            <ListItemIcon ><TocIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="patient_models" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="rights" key='rights' component={Link} to="/rights" selected={this.isSelected('rights')}>
            <ListItemIcon ><RightsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="rights" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="feedbacks" key='feedback' component={Link} to="/feedback" selected={this.isSelected('feedback')}>
            <ListItemIcon ><FeedbackIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="feedback" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="currentOps" key='currentOps' component={Link} to="/currentOps" selected={this.isSelected('currentOps')}>
            <ListItemIcon ><CurrentOpsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="currentOps" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="anthropic" key='anthropic' component={Link} to="/anthropic" selected={this.isSelected('anthropic')}>
            <ListItemIcon > <img src={anthropic} width={25} height={25} alt={"anthropic"} /></ListItemIcon>
            <ListItemText primary={<T.span text="anthropic" />} />
        </MenuItem>);
        return menuItems;
    }

    createAdminMenu() {
        let menuItems = [];

        menuItems.push(<ListSubheader key={'reports'} disableSticky>Administration</ListSubheader>);
        menuItems.push(<MenuItem id="orgs" key='orgs' component={Link} to="/organizations" selected={this.isSelected('organizations')}>
            <ListItemIcon ><OrgsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="orgs" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="providers" key='providers' component={Link} to="/providers" selected={this.isSelected('providers')}>
            <ListItemIcon ><ProviderIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="providers" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="patients" key='patients' component={Link} to="/patients" selected={this.isSelected('patients')}>
            <ListItemIcon ><PersonOutline /></ListItemIcon>
            <ListItemText primary={<T.span text="patients" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="users" key='users' component={Link} to="/users" selected={this.isSelected('users')}>
            <ListItemIcon ><UsersIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="users" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="appointments" key='appointments' component={Link} to="/appointments" selected={this.isSelected('appointments')}>
            <ListItemIcon ><CalendarIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="appointments" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="employees" key='employees' component={Link} to="/employees" selected={this.isSelected('employees')}>
            <ListItemIcon ><PeopleOutlined /></ListItemIcon>
            <ListItemText primary={<T.span text="employees" />} />
        </MenuItem>);
        // menuItems.push(<MenuItem id="trials" key='trials' component={Link} to="/trials" selected={this.isSelected('trials')}>
        //     <ListItemIcon ><AssignmentIcon /></ListItemIcon>
        //     <ListItemText primary={<T.span text="trials" />} />
        // </MenuItem>);
        menuItems.push(<MenuItem id="devices" key='devices' component={Link} to="/devices" selected={this.isSelected('devices')}>
            <ListItemIcon ><DevicesIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="devices" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="subscriptions" key='subscriptions' component={Link} to="/subscriptions" selected={this.isSelected('subscriptions')}>
            <ListItemIcon ><SubscriptionsIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="subscriptions" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="directMessages" key='directMessages' component={Link} to="/directMessages" selected={this.isSelected('directMessages')}>
            <ListItemIcon ><DirectMessagesIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="directMessages" />} />
        </MenuItem>);
        menuItems.push(<MenuItem style={{ marginLeft: 25 }} id="directMessageAttachments" key='directMessageAttachments' component={Link} to="/directMessageAttachments" selected={this.isSelected('directMessageAttachments')}>
            <ListItemIcon ><AttachmentIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="directMessageAttachments" />} />
        </MenuItem>);

        menuItems.push(<MenuItem id="recentActivity" key='recentActivity' component={Link} to="/recentActivity" selected={this.isSelected('recentActivity')}>
            <ListItemIcon ><LocalActivityIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="recentActivity" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="activities" key='activities' component={Link} to="/activities" selected={this.isSelected('activities')}>
            <ListItemIcon ><CalendarIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="activities" />} />
        </MenuItem>);
        menuItems.push(<MenuItem id="blobs" key='blobs' component={Link} to="/blobs" selected={this.isSelected('blobs')}>
            <ListItemIcon ><ImagesIcon /></ListItemIcon>
            <ListItemText primary={<T.span text="blobs" />} />
        </MenuItem>);

        return menuItems;
    }

    render() {
        const self = this;
        const { open, classes, language } = this.props;

        let adminMenu = self.createAdminMenu();
        let reportsMenu = self.createReportsMenu()
        let developerMenus = self.createDeveloperMenu();

        T.setTexts(require(`./local/${language}.json`));
        return (
            <div align='left' className={classes.root}>

                <Drawer
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    variant="persistent"
                    open={open}

                >
                    <List component="nav"
                        onClick={(e) => this.setState({ selectedId: e.target.value })}
                        className={classes.menuList}>
                        <div className={classes.sidebarHeader}>
                            <img className={classes.logo} src={ApricityLogo} alt="apricity logo" />
                        </div>
                        <Divider />
                        {reportsMenu}
                        <Divider />
                        {adminMenu}
                        <Divider />
                        {developerMenus}
                    </List>
                </Drawer>
                {self.state.dialog}

            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    logo: {
        height: '90px',
        marginBottom: '10px',
    },
    sidebarHeader: {
        display: 'flex',
        padding: '10px 10px 0 10px',
        justifyContent: 'center'
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) /*+ 1*/,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) /*+ 1*/,
        },
    },
});

MenuView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuView);

