import Config from '@apricityhealth/web-common-lib/Config';
import Axios from 'axios';
import React from 'react';

import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Tab, Tabs,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    Select,
    FormControl,
    InputLabel
} from '@material-ui/core/';

import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import ExportIcon from '@material-ui/icons/CloudDownload';
import ImportIcon from '@material-ui/icons/CloudUpload';
import MergeIcon from '@material-ui/icons/MergeTypeOutlined';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ViewIcon from '@material-ui/icons/Visibility';

import Logger from '@apricityhealth/web-common-lib/utils/Logger';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import UploadImage from "@apricityhealth/web-common-lib/components/UploadImage";
import SelectOrg from '@apricityhealth/web-common-lib/components/SelectOrg';
import User from '@apricityhealth/web-common-lib/components/User';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import OwnerInfo from '@apricityhealth/web-common-lib/components/OwnerInfo';

import {
    //eslint-disable-next-line
    Organization as Org,
    isArrayValid, 
    isTypeArrayValid, 
    saveOrg
} from '@apricityhealth/web-common-lib/utils/Services';

import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils'
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';

import AddressView from './AddressView';
import ContactsView from './ContactsView';
import DirectMessageRuleView from './DirectMessageRuleView';
import JSONDataDialog from '../../dialogs/JSONDataDialog';
import OfficeHoursView from './OfficeHoursView';
import OrgTrialsView from './OrgTrialsView';
import RolesView from './RolesView';
import RosterView from './RosterView';
import CodeView from './CodeView';

import '../../styles/org.css';
import ProvidersView from './ProvidersView';
import SelectSubscriptionPlans from '../../components/SelectSubscriptionPlan';
import { AdditionalTerms } from './AdditionalTerms';
import { getTimePicker } from './EditOfficeHourDialog';

const LANGUAGES = require('@apricityhealth/web-common-lib/config/languages.json');

/**@type {Intl.DateTimeFormat|String='UTZ'} Browser's Current Timezone `UTZ-NameAbbr.`*/
const TIMEZONE = (Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTZ');
/**Accepted `org.timeZone`(s): ___House-Keeping Metric___*/
const TIMEZONES = Object.freeze(
[
    <MenuItem button key='UTZ'                 value='UTZ'>UTZ</MenuItem>,
    <MenuItem button key='America/Los_Angeles' value='America/Los_Angeles'>America/Los_Angeles</MenuItem>,
    <MenuItem button key='America/Denver'      value='America/Denver'>America/Denver</MenuItem>,
    <MenuItem button key='America/Chicago'     value='America/Chicago'>America/Chicago</MenuItem>,
    <MenuItem button key='America/New_York'    value='America/New_York'>America/New_York</MenuItem>
]);
/**Accepted `org.orgType`'(s): ___DirectMessage-ACL is directly correlated___*/
const ORG_TYPES = Object.freeze(
[
    <MenuItem button key='agency'   value='agency'>Agency - Regulational</MenuItem>,
    <MenuItem button key='employer' value='employer'>Employer</MenuItem>,
    <MenuItem button key='research' value='research'>Research - Clinical</MenuItem>,
    <MenuItem button key='practice' value='practice'>Practice - Clinical</MenuItem>,
    <MenuItem button key='unknown'  value='unknown'>Unknown - General OP</MenuItem>
]);

const ALERT_POLICY = Object.freeze([
    'permitted',
    'notPermitted'
]);

const ORG_CONFIG_KEYS = Object.freeze(
    [
        { key: 'disableRegister', type: 'boolean', desc: 'Disable user registration.' },
        { key: 'prospectivePatientCount', type: 'string', desc: 'The number of prospective patients for this org. Used for reporting'},
        { key: 'disableRegisterRace', type: 'boolean', desc: 'Disable asking for race and ethnicity on registration.'},
        { key: 'disableRegisterGender', type: 'boolean', desc: 'Disable asking for gender on registration.'},
        { key: 'disableDailyCheck', type: 'boolean', desc: 'Disable daily check-in prompt.'},
        { key: 'disableBaselineCheck', type: 'boolean', desc: 'Disable baseline prompt.'},
        { key: 'disableCheckInReminder', type: 'boolean', desc: 'Disable check-in SMS reminder snackbar message.'},
        { key: 'forcedCheckInReminder', type: 'boolean', desc: 'If true, then check-in SMS reminder will turn on automatically for new patients.'},
        //{ key: 'disableReminderPrompt', type: 'boolean', desc: 'Disable check-in SMS reminder prompt on first login.'},
        { key: 'enableEmLife', type: 'boolean', desc: 'Enable EmLife.'},
        { key: 'videoChat', type: 'boolean', desc: 'Enable VideoChat button.'},
        { key: 'appointments', type: 'boolean', desc: 'Enable Appointment button.' },
        { key: 'messages', type: 'boolean', desc: 'Enable Messaging button.' },
        { key: 'trials', type: 'boolean', desc: 'Enable Trials button.' },
        { key: 'companyPolicyPage_{{language}}', type: 'string', desc: 'Company policy page (HTML supported).' },
        { key: 'lowEODMessage_{{language}}', type: 'string', desc: 'Message displayed to the user on a low alert level' },
        { key: 'mediumEODMessage_{{language}}', type: 'string', desc: 'Message displayed to the user on a medium alert level.'},
        { key: 'highEODMessage_{{language}}', type: 'string', desc: 'Message displayed to the user on a high alert level.'},
        { key: 'confirmedEODMessage_{{language}}', type: 'string', desc: 'Message displayed to the user on a confirmed alert level.'},
        { key: 'quarantineEODMessage_{{language}}', type: 'string', desc: 'Message displayed to the user on a quarantine alert level.'},
        { key: 'low_covidAlert_clearance', type: 'boolean', desc: 'Require clearance for low covidAlert.' },
        { key: 'low_exposureAlert_clearance', type: 'boolean', desc: 'Require clearance for low exposureAlert.' },
        { key: 'medium_covidAlert_clearance', type: 'boolean', desc: 'Require clearance for medium covidAlert.' },
        { key: 'medium_exposureAlert_clearance', type: 'boolean', desc: 'Require clearance for medium exposureAlert.' },
        { key: 'high_covidAlert_clearance', type: 'boolean', desc: 'Require clearance for high covidAlert.' },
        { key: 'high_exposureAlert_clearance', type: 'boolean', desc: 'Require clearance for high exposureAlert.' },
        { key: 'confirmed_covidAlert_clearance', type: 'boolean', desc: 'Require clearance for confirmed covidAlert.' },
        { key: 'low_covidAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for low covidAlert.' },
        { key: 'low_exposureAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for low exposureAlert.' },
        { key: 'medium_covidAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for medium covidAlert.' },
        { key: 'medium_exposureAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for medium exposureAlert.' },
        { key: 'high_covidAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for high alerts.' },
        { key: 'high_exposureAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for high alerts.' },
        { key: 'confirmed_covidAlert_policy', type: 'selector', options: ALERT_POLICY, desc: 'Default policy for confirmed covidAlert.' },
        { key: 'CustomMessage_AdminCreateUser_Patient_emailMessage_{{language}}', type: 'string', desc: 'Patient welcome email body.'},
        { key: 'CustomMessage_AdminCreateUser_Patient_emailSubject_{{language}}', type: 'string', desc: 'Patient welcome email subject.'},
        { key: 'CustomMessage_AdminCreateUser_Patient_smsMessage_{{language}}', type: 'string', desc: 'Patient welcome SMS message.'},
        { key: 'enableSurvey1', type: 'boolean', desc: 'Enable Survey #1.'},
        { key: 'enableSurvey2', type: 'boolean', desc: 'Enable Survey #2.'},
        { key: 'enableSurvey3', type: 'boolean', desc: 'Enable Survey #3.'},
        { key: 'enableSurvey4', type: 'boolean', desc: 'Enable Survey #4.'},
        { key: 'enableSurvey5', type: 'boolean', desc: 'Enable Survey #5.'},
        { key: 'enableSurvey6', type: 'boolean', desc: 'Enable Survey #6.'},
        { key: 'enableSurvey7', type: 'boolean', desc: 'Enable Survey #7.'},
        { key: 'enableSurvey8', type: 'boolean', desc: 'Enable Survey #8.'},
        { key: 'enableSurvey9', type: 'boolean', desc: 'Enable Survey #9.'},
        { key: 'termsOfService_{{language}}', type: 'string', desc: 'Terms of service text override.'},
        { key: 'privacyNotice_{{language}}', type: 'string', desc: 'Privacy Policy text override.'},
        { key: 'noticeOfPrivacyPractice_{{language}}', type: 'string', desc: 'Private Practice text override.'},
        { key: 'baselineCheckDelay', type: 'number', desc: 'How many days after accepting terms to ask for a baseline. (default 0)'},
        { key: 'addPatientTerms', type: 'string', desc: 'Additional terms to ask for in the patient client. (JSON format)'}
        // { key: 'CustomMessage_AdminCreateUser_Provider_emailMessage_{{language}}', type: string, desc: 'Provider welcome email body.'},
        // { key: 'CustomMessage_AdminCreateUser_Provider_emailSubject_{{language}}', type: string, desc: 'Provider welcome email subject.'},
        // { key: 'CustomMessage_AdminCreateUser_Provider_smsMessage_{{language}}', type: string, desc: 'Provider welcome SMS message.'},
    ]
)
const log = new Logger();

function isValidDate(d) {
    let ts = Date.parse(d);
    if (isNaN(ts))
        return false;
    else
        return true;
}

/**
 * @extends React.Component
 * Apricity Tenant-Organization Viewer
 * @memberof module:Admin
 * @alias OrgView
 */
class OrgView extends React.Component {
//#region Component-Lifecycle Methods
    constructor (props) {
        super(props);
        this.state = {
            configArray: [],
            dialog: null,
            export_progress: null,
            currentTab: '0',
            tabs: [],
/**@type {Org}*/org: null,
            patients: [],
            providers: [],
            users: [],
            mergeOrgId: ''
        };

        if (typeof this.props.onClose !== 'function')
            log.warning('`OrgView.onClose()` event not found');
    }


    componentDidMount() {
        this.loadOrg(this.props.orgId);
    }

    componentDidUpdate(oldProps) {
        const { props: { orgId } } = this,
            { orgId: oldOrgId } = oldProps;

        if (orgId && typeof orgId !== 'string') throw new TypeError('`props.orgId` must be a string');
        if (orgId !== oldOrgId) this.setState({ orgId }, this.loadOrg.bind(this, orgId));
    }


    closeOrg() {
        this.props.onClose();
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onMergeOrg() {
        const { org } = this.state;
        const { appContext } = this.props;

        this.setState({
            dialog: <Dialog open={true}>
                <DialogTitle>Merge Org:</DialogTitle>
                <DialogContent>
                    Please select an org to merge this org into?<br />
                    PLEASE NOTE: This is not reversable, SO MAKE SURE YOU WANT TO MERGE!
                    <SelectOrg appContext={appContext} onChange={(orgId) => {
                        console.log("selected org:", orgId );
                        this.setState({mergeOrgId: orgId });
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Cancel</Button>,
                    <Button variant='contained' onClick={() => {
                        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
                        const request = {
                            url: `${Config.baseUrl + Config.pathPrefix}orgs/merge`,
                            method: 'POST',
                            headers: { "Authorization": appContext.state.idToken },
                            data: { sourceOrgId: org.orgId, targetOrgId: this.state.mergeOrgId }
                        };

                        log.debug('mergeOrg request:', request);
                        Axios(request).then((response) => {
                            log.debug('mergeOrg response:', response);
                            const org = response.data.sourceOrg;
                            this.transformOrg(org);
                            this.setState({ org, progress: null }, this.createTabs.bind(this));
                        }).catch((err) => {
                            log.error('mergeOrg error:', err);
                            this.setState({ progress: null, error: getErrorMessage(err) }, this.createTabs.bind(this));
                        });
                    }}>Confirm</Button>
            </DialogActions>
            </Dialog>
        })
    }

    onDeleteOrg() {
        const { state: { org } } = this;
        this.setState({
            dialog: <Dialog open model='false'>
                <DialogTitle>Delete Org: {org.orgId}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Cancel</Button>,
                    <Button variant='contained' onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                </DialogActions>
            </Dialog>
        });
    }

    /**@param {String} orgId Apricity Tenant-Organization Identifier*/
    loadOrg(orgId) {
        log.debug(`loadOrg ${orgId}`);
        const { props: { appContext } } = this;

        if (orgId && typeof orgId === 'string') {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            const request = {
                url: `${Config.baseUrl + Config.pathPrefix}orgs/${orgId}`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };
            log.debug('getOrg request:', request);
            Axios(request).then((response) => {
                log.debug('getOrg response:', response);
                const { data: { org } } = response;
                this.transformOrg(org);
                this.setState({ org, progress: null }, this.createTabs.bind(this));
            }).catch((err) => {
                log.error('getOrg error:', err);
                this.setState({ progress: null, error: getErrorMessage(err) }, this.createTabs.bind(this));
            });

        } else {
            this.setState({
                org: {
                    orgId: '',
                    name: '',
                    officeHours: [],
                    contacts: [],
                    address: [],
                    trialIds: [],
                    config: {}
                }
            }, this.createTabs.bind(this));
        }
    }

    /**Save the currently-selected Org*/
    saveOrg() {
        const { props: { appContext }, state: { org } } = this;

        org.NPIs = org.NPIs.filter((e) => e);           // remove any blank NPI's

        this.untransformOrg(org);
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        saveOrg(appContext, org).then( uOrg => {
            if (! org || ! uOrg || typeof uOrg.orgId !== 'string') return void (
                this.transformOrg(org),
                this.setState({
                    org,
                    progress: null,
                    error: `Failed to update Apricity Tenant-Organization<${uOrg.orgId}>`
                })
            );
            else return void (
                this.transformOrg(uOrg),
                this.setState({ org: uOrg, progress: null, error: null })
            );
        }).catch( error => void (
            this.transformOrg(org),
            this.setState({ org, progress: null, error: getErrorMessage(error) })
        ));
    }

    saveUser(account) {
        const { props: { appContext } } = this;
        return new Promise((resolve, reject) => {
            const saveUser = {
                url: `${Config.baseUrl + Config.pathPrefix}authentication/users`,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken },
                data: account
            };
            // log.debug('saveUser:', saveUser);
            Axios(saveUser).then(function (response) {
                // log.debug('saveUser response:', response);
                resolve(response.data.user);
            }).catch(function (error) {
                log.error('saveUser error:', error);
                reject(null);
            });
        });
    }

    /**@param {React.ChangeEvent} e `org.orgType` event trigger*/
    onOrgType(e) {
        const { state: { org } } = this,
            { target: { value: orgType } } = e;
        if (typeof orgType !== 'string') return void this.setState({
            error: 'onOrgType: `e.target.value: orgType` must be a string',
            progress: null
        });
        else if (org) return void (
            org.orgType = (orgType || 'unknown'),
            this.setState({ org, progress: null })
        );
        else return void this.setState({
            error: 'no Org selected',
            progress: null
        });
    }
    /**Display Apricity Tenant-Organization's ___rawJSON___*/
    onShowCode() {
        this.setState({
            dialog: <JSONDataDialog
                appContext={this.props.appContext}
                dataType={this.state.org}
                onDone={this.onCloseDialog.bind(this)} />
        });
    }
    /**@param {React.ChangeEvent} e `org.timeZone` event trigger*/
    onTimeZone(e) {
        const { state: { org } } = this,
            { target: { value: timeZone } } = e;
        if (typeof timeZone !== 'string') return void this.setState({
            error: 'onTimeZone: `e.target.value: timeZone` must be a string',
            progress: null
        });
        else if (! org) return void this.setState({
            error: 'no Org selected',
            progress: null
        });
        else return void (
            org.timeZone = (timeZone || TIMEZONE),
            this.setState({ org, progess: null })
        );
    }
//#endregion


    /**
     * Modify/Update Org values
     * @param {Org} org Target Apricity Tenant-Organization
     */
    transformOrg(org) {
        const config = [];
        if (! org.config) org.config = {};
        for (const key in org.config) {
            config.push({ key, value: org.config[key] });
        }
        org.configArray = config;
        this.setState({ configArray: config, org }, this.createTabs.bind(this));
    }

    /**
     * Un-Modify/Update Org values
     * @param {Org} org Target Apricity Tenant-Organization
     */
    untransformOrg(org, deleteConfigArray = true ) {
        const { configArray } = this.state;
        const config = {};
        for (let i = 0; i < configArray.length; ++i) {
            config[configArray[i].key] = configArray[i].value;
        }
        org.config = config;
        if ( deleteConfigArray ) {
            delete org.configArray;// keep this here to remove the configArray from org's we accidently added this data onto
            this.setState({ org }, this.createTabs.bind(this));
        } else {
            this.setState({ org }, this.createTabs.bind(this));
        }
    }

//#region Import|Export-Org|Patient Data
    /**Push `DELETE` for the currently selected Apricity Tenant-Organization*/
    confirmDelete() {
        const {
            props: { appContext },
            state: { org }
        } = this;
        this.setState({ dialog: null, error: null, progress: <CircularProgress size={20} /> });
        if (org.orgId) {
            const request = {
                url: `${Config.baseUrl + Config.pathPrefix}orgs/${org.orgId}`,
                method: 'DELETE',
                headers: { "Authorization": appContext.state.idToken }
            };
            log.debug('deleteOrg request:', request);
            Axios(request).then((/**@this {OrgView}*/function (response) {
                log.debug('deleteOrg response:', response);
                this.setState({ progress: null }, this.closeOrg.bind(this));
            }).bind(this)
            ).catch((/**@this {OrgView}*/function (error) {
                log.error('deleteOrg error:', error.response);
                this.setState({ progress: null, error: getErrorMessage(error) });
            }).bind(this));
        }
    }

    confirmImportPatients() {
        const {
            props: { appContext },
            state: { org: { orgId = '' } = {} }
        } = this;
        log.info(`org id: ${orgId}`);
        if (orgId === '') this.setState({
            export_progress: null, error: null,
            dialog: <Dialog open aria-labelledby="form-dialog-title">
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <div>The org must be created and have a valid org id before patients can be imported.</div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Ok</Button>,
                </DialogActions>
            </Dialog>
        });
        else this.setState({
            dialog: <PatientsImport appContext={appContext} cancel={this.onCloseDialog.bind(this)}
                ok={this.importPatients.bind(this)} />
        });
    }

    importPatients(data) {
        const {
            props: { appContext },
            state: { org: { orgId } }
        } = this,
            { bucket, firstName, lastName, newPatientId, providerId, userId } = data;
        const args = [];

        this.setState({ dialog: null, error: null, export_progress: <CircularProgress size={20} /> });

        if (firstName) args.push(`firstName=${firstName}`);
        if (lastName) args.push(`lastName=${lastName}`);
        if (bucket) args.push(`fileId=${bucket}`);
        if (orgId) args.push(`orgId=${orgId}`);
        if (providerId) args.push(`providerId=${providerId}`);
        if (userId) args.push(`userId=${userId}`);
        if (String(newPatientId) === 'true') args.push(`newPatientId=true`);

        const request = {
            url: `${Config.baseUrl + Config.pathPrefix}apricity-cleanse/patients/?${args.join('&')}`,
            method: 'post',
            headers: { "Authorization": appContext.state.idToken }
        };
        log.debug('importPatients request', request);
        Axios(request).then((response) => {
            log.debug('importPatients response:', response);
            this.setState({ export_progress: null, error: null });
        }).catch((error) => {
            log.error('importPatients request', error);
            this.setState({ export_progress: null, error: getErrorMessage(error) });
        });
    }

    exportPatients() {
        this.setState({
            dialog: <Dialog open aria-labelledby="form-dialog-title">
                <DialogTitle>Exporting Org Patients</DialogTitle>
                <DialogContent>
                    <div>This may take longer than 30 seconds.</div>
                    <div> Once you select ok, you will see a dialog with the link and file id to use for the import.</div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={this.performOrgExport.bind(this)}>Ok</Button>
                    <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        });
    }

    performOrgExport() {
        const {
            props: { appContext },
            state: { org }
        } = this,
            orgId = org.orgId;
        const s3URL = `https://s3.console.aws.amazon.com/s3/buckets/patientexport.develop.apricity-health?region=us-east-1&tab=objects`;

        this.setState({ export_progress: <CircularProgress size={20} />, error: null });

        const request = {
            url: `${Config.baseUrl + Config.pathPrefix}apricity-cleanse/patients/?orgId=${orgId}`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        };
        log.debug('exportPatient request', request);
        Axios(request).then((response) => {
            log.debug('exportPatient response:', response);
            const data = response.data;
            const s3DirectURL = `https://s3.console.aws.amazon.com/s3/buckets/patientexport.develop.apricity-health?region=us-east-1&prefix=${data}/&showversions=false`;

            this.setState({
                export_progress: null, error: null,
                dialog: <Dialog open aria-labelledby="form-dialog-title">
                    <DialogTitle>Exporting Org Patients</DialogTitle>
                    <DialogContent>
                        <div>The org is exporting, the file id is: {data}.</div>
                        <br />
                        <div>The exported orgs are here: <a href={s3URL}>Export Buckets</a>.</div>
                        <br />
                        <div>Direct link here: <a href={s3DirectURL}>{data}</a>. Note: this link won't exist until the export is complete. Refresh until the link exists.</div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Ok</Button>,
                    </DialogActions>
                </Dialog>
            });
        }).catch((error) => {
            this.setState({ export_progress: null, error: getErrorMessage(error) });
        });
    }
//#endregion

//#region Organizational-Data Fetching
    exportActivationCodes(limit) {
        log.debug(`exportActivationCodes limit: ${limit}`);
        this.setState({ progress: <CircularProgress /> });
        return new Promise((resolve, reject) => {
            let report = [];
            this.getNextActivationCodes(limit).then( codes => {
                if (isArrayValid(codes)) {
                    for (let i = 0; i < codes.length; i++) report.push({
                        index: i, activationCode: (codes[i] && codes[i].activationCode)
                    });
                }

                this.setState({ progress: null });
                resolve(report);
            }).catch( error => {
                log.error('exportActivationCodes error:', error);
                this.setState({ progress: error });
                reject(error);
            });
        });
    }

    getNextActivationCodes(count = 10) {
        const { props: { appContext, orgId } } = this;
        return new Promise((resolve, reject) => {
            if (typeof count !== 'number' || count === 0 || isNaN(count)) count = 10;
            if (orgId) {
                const request = {
                    url: `${Config.baseUrl + Config.pathPrefix}orgs/${orgId}/codes/${count}`,
                    method: 'GET',
                    headers: { "Authorization": appContext.state.idToken }
                };
                console.log('getNextActivationCodes request', request);
                Axios(request).then((response) => {
                    console.log('getNextActivationCodes response:', response.data);
                    resolve(response.data);
                }).catch((err) => {
                    console.error('getNextActivationCodes error:', err.response.data.error);
                    reject(err.response.data.error);
                });
            } else {
                reject(new Error('No org id defined'));
            }
        });
    }


    getAccount(appContext, userId) {
        return new Promise((resolve, reject) => {
            const request = {
                url: `${Config.baseUrl + Config.pathPrefix}authentication/users/${userId}`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };
            log.debug('getAccount request:', request);
            Axios(request).then(function (response) {
                log.debug('getAccount response:', response);
                resolve(response.data.user);
            }).catch(function (error) {
                log.error('getAccount error:', getErrorMessage(error));
                reject(error);
            });
        });
    }
//#endregion


    createTabs() {
        const {
            props: { appContext },
            state: { configArray, org }
        } = this;

        if (! org) return void this.setState({ error: 'no Org selected', progress: null, tabs: [] });
        if (! org.directMessageDomain ) org.directMessageDomain = '';
        if (! org.overnightHours ) org.overnightHours = { start: 1380, end: 420 };
         
        if (org.orgType === 'research')
            if (! Array.isArray(org.directMessageDomainWhitelist))
                org.directMessageDomainWhitelist = [];
            else for (const rule of org.directMessageDomainWhitelist) {
                if (rule.directMessageAddress.includes('@'))
                    rule.directMessageAddress = rule.directMessageAddress.substring(0, rule.directMessageAddress.indexOf('@'));
                if (! rule.directMessageAddress.includes(org.directMessageDomain))
                    rule.directMessageAddress = `${rule.directMessageAddress}@${org.directMessageDomain}`;
                rule.planIds = isTypeArrayValid(rule.planIds, 'string') ? rule.planIds : [];
                rule.trialIds = isTypeArrayValid(rule.trialIds, 'string') ? rule.trialIds : [];
            }
        if (! Array.isArray(org.planIds)) org.planIds = [];
        if (! Array.isArray(org.trialIds)) org.trialIds = [];

        const tabs = [];
        const locationColumns = [
            { id: 'locationId', label: 'Location Id'                                 },
            { id: 'name',       label: 'Location Name', editType: 'text', width: 250 },
            { id: 'default',    label: 'Default',    editControl: (table, value, row, index, id) =>
                <FormControlLabel label='Default' onClick={table.preventClickThrough.bind(table)}
                    control={<Checkbox checked={(String(value) === 'true')} onChange={(e, v) => {
                        table.setDataField(index, id, v);
                        for (let i = 0; i < table.state.data.length; ++i)
                            if (i !== index && table.state.data[i][id]) table.setDataField(i, id, false);
                    }} />}
                />
            }
        ],
        planColumns = [
            { id: 'planId',  label: 'Plan', numeric: false, disabledPadding: false,
                editControl: (table, value, row, index, id) => <SelectPlan appContext={appContext} enableNone={true}
                    style={{ margin: 5, width: 300 }} planId={value} onChange={(plan) => {
                        if (plan && plan.planId !== value) table.setDataField(index, id, plan.planId);
                    }}
                />
            },
            { id: 'default', label: 'Default', editControl: (table, value, row, index, id) =>
                <FormControlLabel label='Default' onClick={table.preventClickThrough.bind(table)}
                    control={<Checkbox checked={String(value) === 'true'} onChange={(e, v) => {
                        //table.preventClickThrough(e);
                        table.setDataField(index, id, v);
                        for (let i = 0; i < table.state.data.length; ++i)
                            if (i !== index && table.state.data[i][id]) table.setDataField(i, id, false);
                    }} />}
                />
            }
        ],
        configColumns = [
            { id: 'key', label: 'Key', editControl: (table, value, row, index, id) => {
                return <Select value={value} onChange={(e) => table.setDataField(index, id, e.target.value)} style={{width: 500}}>
                    {[...ORG_CONFIG_KEYS].sort((a,b) => a.key.localeCompare(b.key)).map((i) => {
                        if ( i.key.indexOf('{{language}}') >= 0) {
                            let keys = [];
                            for(let k in LANGUAGES) {
                                let key = i.key.replace('{{language}}', k );
                                keys.push(<MenuItem key={key} id={key} value={key}>{key} ({i.desc})</MenuItem>);
                            }
                            return keys;
                        }
                        else {
                            return <MenuItem key={i.key} id={i.key} value={i.key}>{i.key} ({i.desc})</MenuItem>;
                        }
                    })}
                </Select>;
            } },  
            { id: 'value', label: 'Value', editControl: (table, value, row, index, id) => {
                let config = ORG_CONFIG_KEYS.find((c) => {
                    if ( c.key.indexOf('{{language}}') >= 0 ) {
                        for(let k in LANGUAGES) {
                            let key = c.key.replace('{{language}}', k);
                            if ( key === row.key ) return true;
                        }
                        return false;
                    }
                    return c.key === row.key;
                })

                if ( config ) {
                    switch(config.type) {
                        case 'boolean':
                            return <Select value={value} onChange={(e) => table.setDataField(index, id, e.target.value)} style={{width: 200}}>
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                            </Select>; 
                        case 'selector':
                            return <Select value={value} onChange={(e) => table.setDataField(index, id, e.target.value)} style={{width: 500}}>
                                {config.options.map((k) => <MenuItem key={k} value={k}>{k}</MenuItem>)}
                            </Select>;
                        case 'number':
                            return <TextField style={{width: 200}} key={row.key} value={value} type='number'
                                onChange={(e) => table.setDataField(index, id, e.target.value)} />;
                        default:
                            break;
                    }    
                }

                return <TextField style={{width: 500}} key={row.key} value={value} 
                    onChange={(e) => table.setDataField(index, id, e.target.value)} />;
            } }
        ],
        orgFieldsColumns = [
            { id: 'id', label: 'Field ID', editType: 'text' },
            { id: 'description', label: 'Description', editType: 'text', width: 400 },
            { id: 'required', label: 'Required', editType: 'checkbox' },
            { id: 'minLength', label: 'Min Length', editType: 'number' },
            { id: 'maxLength', label: 'Max Length', editType: 'number' }
        ],
        dmRuleColumns = [
            { id: 'directMessageAddress', label: 'Direct Address'                    },
            { id: 'planIds',              label: 'Override Org-Default-PlanId'  },
            { id: 'trialIds',             label: 'Override Org-Default-TrialId' }
        ],
        alertFilterColumns = [
            { id: 'modelId', label: 'Model ID', editType: 'text' },
            { id: 'category', label: 'Category', editType: 'text' },
            { id: 'patientId', label: 'Patient ID', editType: 'text' }
        ];

        tabs.push(<Paper key='Providers'>
            <ProvidersView appContext={appContext} org={org} />
        </Paper>);
        tabs.push(<Paper key='Locations'>
            <EnhancedTable
                title='Locations'
                rowsPerPage={5}
                orderBy='planId'
                columnData={locationColumns}
                data={org.locations}
                onDataChanged={(data, table) => {
                    //make sure falses are false, prevent server errors
                    data.forEach( row => {
                        row.default = toBoolean(row.default);
                    });
                    org.locations = data;
                    this.setState({ org });
                }}
            />
        </Paper>);
        tabs.push(<OfficeHoursView key='Office Hours' orgId={org.orgId} officeHours={org.officeHours} timeZone={org.timeZone} appContext={appContext} />);
        if ( org.enableAppointments ) {
            tabs.push(<OfficeHoursView key='Appt Hours' orgId={org.orgId} officeHours={org.appointmentHours} timeZone={org.timeZone} appointments={true} appContext={appContext} />)
        }
        tabs.push(<AddressView key='Addresses' address={org.address} appContext={appContext} />);
        tabs.push(<ContactsView key='Contacts' org={org} appContext={appContext} />);
        tabs.push(<OrgTrialsView key='Trials' trialIds={org.trialIds} appContext={appContext} />);
        tabs.push(<Paper key='Plans'>
            <EnhancedTable
                title='Plans'
                rowsPerPage={5}
                orderBy='planId'
                columnData={planColumns}
                data={org.planIds}
                onDataChanged={(data, table) => { // make sure falses are false, prevent server errors
                    if (isArrayValid(data)) { 
                        for (const row of data) {
                            row.default = toBoolean(row.default);
                        }
                    }
                    org.planIds = data;
                    this.setState({ org });
                }}
            />
        </Paper>);// ONLY `research` Organization(s) have an Access Control List Tab

        tabs.push(<Paper key='Alert Filters'>
            <EnhancedTable
                title='Alert Filters'
                rowsPerPage={5}
                orderBy='_id'
                columnData={alertFilterColumns}
                data={org.alertFilters}
                onDataChanged={(data) => {
                    org.alertFilters = data;
                    this.setState({ org });
                }}
            />
        </Paper>);

        if (org.orgType === 'research') {
            tabs.push(<Paper key='Direct Message ACL'>
                <EnhancedTable
                    title='Direct Message Access Rules'
                    rowsPerPage={5}
                    orderBy='directMessageAddress'
                    columnData={dmRuleColumns}
                    data={org.directMessageDomainWhitelist}
                    onDataChanged={(data, table) => {
                        for (const rule of data) {
                            if (rule.planIds && !isTypeArrayValid(rule.planIds, 'string'))
                                rule.planIds = [];
                            if (rule.trialIds && !isTypeArrayValid(rule.trialIds, 'string'))
                                rule.trialIds = [];
                        }
                        org.directMessageDomainWhitelist = data;
                        this.setState({ org });
                    }}
                    onAdd={() => this.setState({
                        dialog: <DirectMessageRuleView domain={org.directMessageDomain}
                            onDone={this.onCloseDialog.bind(this)} onSave={(dmRule) => {
                                if (!org.directMessageDomainWhitelist.some(r => r.directMessageAddress === dmRule.directMessageAddress))
                                    org.directMessageDomainWhitelist.push(dmRule);
                                for (const rule of org.directMessageDomainWhitelist) {
                                    if (typeof rule.directMessageAddress !== 'string')
                                        throw new TypeError('OrgAccessRule must have a `.directMessageAddress` string');
                                    if (rule.planIds && !isTypeArrayValid(rule.planIds, 'string'))
                                        rule.planIds = [];
                                    if (rule.trialIds && !isTypeArrayValid(rule.trialIds, 'string'))
                                        rule.trialIds = [];
                                }
                                this.setState({ org }, () => { this.onCloseDialog(); this.saveOrg() });  // not re-rendering on save unless we saveOrg()
                            }}
                        />
                    })}
                />
            </Paper>);
        }

        tabs.push(<Paper key='Config'>
            <EnhancedTable
                title='Config'
                rowsPerPage={5}
                orderBy='key'
                columnData={configColumns}
                data={configArray}
                onDataChanged={(data, table) => {
                    this.setState({ configArray: data }, this.untransformOrg.bind(this, org, false));
                }}
            />
        </Paper>);

        tabs.push(<Paper key='Additional Terms'>
            <AdditionalTerms
                appContext={appContext}
                org={org}
                onChange={(org) => this.setState({ org }, this.transformOrg.bind(this,org) )}
            />
        </Paper>);

        tabs.push(<Paper key='Org Fields'>
            <EnhancedTable
                title='Org Fields'
                rowsPerPage={5}
                orderBy='key'
                columnData={orgFieldsColumns}
                data={org.orgFields}
                onDataChanged={(data, table) => {
                    org.orgFields = data;
                    this.setState({ org });
                }}
            />
        </Paper>);

        tabs.push(<Paper key='Roles'>
            <RolesView appContext={appContext} org={org} />
        </Paper>);// ONLY `enabled` Organizational-Rosters display Roster Tab

        if (org.enableRoster) {
             tabs.push(<Paper key='Roster'>
                <RosterView appContext={appContext} org={org} />
            </Paper>);
        }
        tabs.push(<Paper key='Registration Codes'>
            <CodeView appContext={appContext} org={org} />
        </Paper>);

        this.setState({ tabs });
    }


    render() {
        const {
            props: { appContext },
            state: { dialog, error, export_progress, org, progress, tabs }
        } = this,
            currentTab = (this.state.currentTab < 0 || this.state.currentTab >= tabs.length) ?
                '0' : this.state.currentTab;// SET tab index = '0'

        const { userId } = appContext.state;
        const appBar = (typeof this.props.onClose === 'function') ?
            <AppBar position='static' className='appbar'><Toolbar>
                <IconButton onClick={this.closeOrg.bind(this)}><NavigationClose /></IconButton>
                <Typography color='inherit'>Organization Details</Typography>
            </Toolbar></AppBar>
        : null;
        let editOrg = null;


        if (org) {
            if (org.shortName === undefined) org.shortName = '';
            if (org.billingType === undefined) org.billingType = 'free';
            if (org.square === undefined) org.square = { accessToken: '', locationId: '', signatureKey: '' };
            if (org.square.accessToken === undefined) org.square.accessToken = '';
            if (org.square.locationId === undefined) org.square.locationId = '';
            if (org.square.signatureKey === undefined) org.square.signatureKey = '';
            if (!Array.isArray(org.NPIs)) org.NPIs = [];

            const displayTabs = tabs.map((t,i) => <Tab key={i} label={t.key} value={`${i}`} />),
                importButton = <ImportExport asText={true} collectQuantity={true} ignoreImport={true}
                    toolTip='Activation Codes'
                    name='Activation Codes'
                    note='Specify the number of activation codes to export'
                    exportStart={(limit) => {
                        return this.exportActivationCodes(limit)
                    }}
                    exportRow={() => Promise.resolve()}
                    importStart={() => Promise.resolve()}
                    importRows={(rows) => this.importRoster(rows)}
                    importRow={() => Promise.resolve()}
                    importDone={() => Promise.resolve()}
                />;

            editOrg = <div className='drawer'>Org ID: {org.orgId}
                <span className='error'>{error}</span>
                <span className='icons'>
                    <IconButton disabled={progress != null} onClick={this.saveOrg.bind(this)}>
                        {progress ? progress : <SaveIcon />}
                    </IconButton>
                    <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                    <IconButton onClick={this.onDeleteOrg.bind(this)}><DeleteIcon /></IconButton>
                    <Tooltip title='Merge Org'>
                        <IconButton onClick={this.onMergeOrg.bind(this)}><MergeIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title='Export Patients'>
                        <IconButton onClick={this.exportPatients.bind(this)}><ExportIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title='Import Patients'>
                        <IconButton onClick={this.confirmImportPatients.bind(this)}><ImportIcon /></IconButton>
                    </Tooltip>
                </span>
                <div>
                    <TextField required component='form' name='orgName' label='Name' style={{ margin: 5, width: 400 }}
                        value={org.name} onChange={(e) => { org.name = e.target.value; this.setState({ org }); }} />
                    <TextField required component='form' name='orgShortName' label='Short Name' style={{ margin: 5, width: 150 }}
                        value={org.shortName} onChange={(e) => { org.shortName = e.target.value; this.setState({ org }); }} />
                    <TextField required select multiline component='form' name='orgType' label='Type' placeholder='Operational Type' style={{margin: 5, width: 200}}
                        value={org.orgType || 'unknown'} onChange={this.onOrgType.bind(this)}
                            children={ORG_TYPES} />
                    <TextField required select component='form' name='timezone' label='Timezone' style={{margin: 5, width: 200}}
                        value={org.timeZone || 'UTZ'} onChange={this.onTimeZone.bind(this)}
                            children={TIMEZONES} />
                    <TextField component='form' name='customURL' label='Logo URL' style={{ margin: 5, width: 400 }}
                        value={org.customLogo} onChange={(e) => void (
                            org.customLogo = String(e.target.value || ''),
                            this.setState({ org })
                        )}
                    />
                    <Tooltip title='View Image'><IconButton onClick={() => {
                        this.setState({// DISPLAY Popup Dialog<Image>
                            dialog: <Dialog open maxWidth='lg' onClose={this.onCloseDialog.bind(this)}>
                                <DialogContent><div align='center'>
                                    <img src={org.customLogo} alt='logo' width='100%' />
                                </div></DialogContent>
                                <DialogActions>
                                    <Button onClick={this.onCloseDialog.bind(this)}>Close</Button>
                                </DialogActions>
                            </Dialog>
                        });
                    }}><ViewIcon /></IconButton></Tooltip>
                    <UploadImage folderPath='logos/' appContext={appContext} onChange={(e) => {
                        org.customLogo = e; this.setState({ org }) }} />
                    <br />
                    <TextField component='form' name='idPrefix' label='ID Prefix' value={org.idPrefix} style={{ margin: 5, width: '10%' }}
                        onChange={(e) => void (
                            org.idPrefix = String(e.target.value || '').toUpperCase(),
                            this.setState({ org })
                        )}
                    />
                    <TextField component='form' name='patientDNS' label='Patient DNS' style={{ margin: 5, width: '20%' }}
                        value={org.patientDNS} onChange={(e) => void (
                            org.patientDNS = String(e.target.value || '').toLowerCase(),
                            this.setState({ org })
                        )}
                    />
                    <TextField component='form' name='providerDNS' label='Provider DNS' style={{ margin: 5, width: '20%' }}
                        value={org.providerDNS} onChange={(e) => void (
                            org.providerDNS = String(e.target.value || '').toLowerCase(),
                            this.setState({ org })
                        )}
                    />
                    <TextField component='form' name='directMessageDomain' label='DirectEMR Domain' style={{ margin: 5, width: '30%' }}
                        value={org.directMessageDomain} onChange={(e) => void (
                            org.directMessageDomain = String(e.target.value || '').toLowerCase(),
                            this.setState({ org })
                        )}
                    />
                    <br />
                    <FormControl style={{margin: 5, width: 250}}>
                        <InputLabel>Billing Type</InputLabel>
                        <Select value={org.billingType} onChange={(e) => {
                            org.billingType = e.target.value;
                            this.setState({org});
                        }}>
                            <MenuItem value='free'>Free</MenuItem>
                            <MenuItem value='invoiced'>Invoiced</MenuItem>
                            <MenuItem value='square'>Square</MenuItem>
                        </Select>
                    </FormControl>
                    {org.billingType === 'square' ? 
                        <SelectSubscriptionPlans appContext={appContext} orgId={org.orgId} value={org.subscriptionPlanIds} onChange={(e) => {
                            console.log("onChange subscription plans:", e );
                            org.subscriptionPlanIds = e;
                            this.setState({ org })
                        }} /> : null
                    }
                    {org.billingType === 'square' ?
                        <TextField label='Square Token' style={{ margin: 5, width: 300 }} value={org.square.accessToken} onChange={(e) => {
                            org.square.accessToken = e.target.value;
                            this.setState({ org })
                        }} /> : null
                    }
                    {org.billingType === 'square' ?
                        <TextField label='Square Location ID' style={{ margin: 5, width: 300 }} value={org.square.locationId} onChange={(e) => {
                            org.square.locationId = e.target.value;
                            this.setState({ org })
                        }} /> : null
                    }
                    {org.billingType === 'square' ?
                        <TextField label='Square Webhook Signature' style={{ margin: 5, width: 300 }} value={org.square.signatureKey} onChange={(e) => {
                            org.square.signatureKey = e.target.value;
                            this.setState({ org })
                        }} /> : null
                    }
                    <br />
                    <div style={styles.divRow}>
                        <SelectProvider enableNone label='Default Provider' style={{margin: 5, width: 300}}
                            appContext={appContext} providerId={org.defaultProviderId} orgId={org.orgId}
                            onSelect={(id) => { org.defaultProviderId = id; this.setState({ org })} }
                        />
                        <SelectProvider enableNone label='Register Notify Provider' style={{margin: 5, width: 300}}
                            appContext={appContext} providerId={org.registerProviderId} orgId={org.orgId}
                            onSelect={(id) => { org.registerProviderId = id; this.setState({ org })} }
                        />
                        <Tooltip title='Organization NPIs comma seperated'><TextField label="NPIs" style={{margin: 5, width: 300}} value={org.NPIs.join(',')} onChange={(e) => {
                            org.NPIs = e.target.value.split(',');
                            this.setState({org})
                        }} /></Tooltip>
                        <SelectLanguage enableNone label='Default Language' style={{margin: 5, width: 300}}
                            language={org.defaultLanguage} onChange={(lang) => { org.defaultLanguage = lang; this.setState({org}) }}
                        />
                    </div>
                    <br />
                    <FormControlLabel label='Inactive' control={
                        <Checkbox checked={org.inactive} onChange={(e,v) => { 
                            org.inactive = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable Appointments' control={
                        <Checkbox checked={org.enableAppointments} onChange={(e,v) => {
                            org.enableAppointments = v; this.setState({ org }, this.createTabs.bind(this)) }} />
                    } />
                    <FormControlLabel label='Enable Roster' control={
                        <Checkbox checked={org.enableRoster} onChange={(e,v) => {
                            org.enableRoster = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable Activation Code' control={
                        <Checkbox checked={org.enableActivationCode} onChange={(e,v) => {
                            org.enableActivationCode = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable Registration Code' control={
                        <Checkbox checked={org.enableRegistrationCode} onChange={(e,v) => {
                            org.enableRegistrationCode = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable Registration Notification' control={
                        <Checkbox checked={org.enableRegisterNotification} onChange={(e,v) => {
                            org.enableRegisterNotification = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Require Registration Codes' control={
                        <Checkbox checked={org.requireRegistrationCode} onChange={(e,v) => {
                            org.requireRegistrationCode = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable Provider Search' control={
                        <Checkbox checked={org.enableProviderSearch} onChange={(e,v) => {
                            org.enableProviderSearch = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Disable EMR' control={
                        <Checkbox checked={org.disableEMR} onChange={(e,v) => {
                            org.disableEMR = v; this.setState({ org }) }} />
                    } />
                    <FormControlLabel label='Enable MFA' control={
                        <Checkbox checked={org.enableMFA} onChange={(e,v) => {
                            org.enableMFA = v; this.setState({ org }) }} />
                    } />
                    <br />
                    <FormControlLabel control={<Checkbox checked={isValidDate(org.validationDate)} onChange={(e, v) => {
                        org.validationDate = v ? new Date().toISOString() : null;
                        org.validatedBy = userId;
                        this.setState({ org });
                    }} />} label={org.validationDate ? `Validated on ${org.validationDate}` : "Validate"} />
                    {org.validationDate ? <span><i> validated by <User appContext={appContext} userId={org.validatedBy} userIdHidden={true} /></i></span> : null}
                    {org.enableActivationCode === true && importButton}
                    <br />
                    <FormControlLabel label='Enable Overnight Calls' control={
                        <Checkbox checked={org.enableOvernightCalls} onChange={(e,v) => {
                            org.enableOvernightCalls = v; this.setState({ org }) }} />
                    } />
                    {org.enableOvernightCalls && getTimePicker("Start Time", org.overnightHours.start, org.timeZone, (start) => {
                        org.overnightHours.start = start;
                        this.setState({ org })
                    })}
                    {org.enableOvernightCalls && getTimePicker("End Time", org.overnightHours.end, org.timeZone, (end) => {
                        org.overnightHours.end = end;
                        this.setState({ org })
                    })}
                </div>
                {displayTabs.length > 0 && [
                    <Tabs key='tab_bar' className='contact_table' variant='scrollable'
                        value={currentTab} onChange={(e,t) => { this.setState({ currentTab: `${t}` }); }}
                        children={displayTabs} />,
                    tabs[currentTab],
                    <br key='remove_key_for_a_dumb_warning'/>
                ]}
                <OwnerInfo appContext={appContext} model={org} />
            </div>;
        }


        return <div className='view'>
            {appBar}
            {export_progress}
            {editOrg}
            {dialog}
        </div>;
    }
};


/**
 * @extends React.Component
 * Organization Patient-Data Importer
 * @memberof module:Admin
 * @alias PatientsImporter
 */
class PatientsImport extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            bucket: null,
            firstName: null,
            lastName: null,
            newPatientId: false,
            providerId: null,
            userId: null
        };

        if (typeof this.props.cancel !== 'function')
            log.warning('`PatientsImport.onCancel()` event not found');
    }


    ok() {
        if (typeof this.props.ok === 'function') {
            const { state: { bucket, firstName, lastName, newPatientId, providerId, userId } } = this;
            this.props.ok({ bucket, firstName, lastName, newPatientId, providerId, userId });
        }
    }

    cancel() {
        if (typeof this.props.cancel === 'function') this.props.cancel();
    }


    render() {
        const {
            props: { appContext },
            state: { bucket, firstName, lastName, newPatientId, providerId, userId }
        } = this;

        return <Dialog open maxWidth='lg' aria-labelledby="customized-dialog-title">
            <DialogTitle id='customized-dialog-title'>Organization's patients import</DialogTitle>
            <DialogContent style={{ height: '400px' }}>
                <Typography variant='title' style={{ color: 'blue' }}>Please take care importing patients into the same env. Patient IDs are preserved and it will modify the existing patient.</Typography>
                <br />
                <Typography>Patients exported from an org will be imported into this org. If the org was a roster org the roster will be imported accordingly.</Typography>
                <table><tbody>
                    <tr>
                        <td align='left'>
                            <TextField label='First Name' value={firstName} style={styles.text}
                                onChange={(e) => { this.setState({ firstName: e.target.value }); }}
                            />
                            <TextField label='Last Name' value={lastName} style={styles.text}
                                onChange={(e) => { this.setState({ lastName: e.target.value }); }}
                            />
                            <Typography component='span' variant='caption'>Note: If you leave the first and last name empty it will use the preset naming of: First -Exported Last - [patientId].  If you provide a last name it will incremented: LastName1, LastName2, etc.</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td align='left'>
                            <TextField label='File id from the export:' style={styles.text} value={bucket}
                                onChange={(e) => { this.setState({ bucket: e.target.value }); }}
                            />
                            <FormControlLabel label='Generate Patient Id' control={
                                <Checkbox name='Generate Patient Id' checked={newPatientId} style={{ margin: '5px' }}
                                    onChange={(e,v) => { this.setState({ newPatientId: v }); }}
                                />} />
                            <Typography component='span' variant='caption'>Note:  If you check the box 'Generate Patient Id' then it will NOT use the existing patientId but generate one randomly.</Typography>

                        </td>
                    </tr>
                    <tr>
                        <td align='left'>
                            <SelectProvider label='Provider' value={providerId} style={styles.select}
                                appContext={appContext} onChange={this.setState} />
                            <SelectUser label='User Account' groups='patients' userId={userId}
                                style={styles.select} appContext={appContext} onChange={this.setState} />
                            <Typography component='span' variant='caption'>Note:  If you provide a 'user' account this will allow you to use the specified account to login to ALL of the exported patients for this org with that account. When you login it will give you a selection to scroll through the list.  If you provide a 'Provider' account it will set that provider as the primary care provider for ALL the exported patients.</Typography>
                        </td>
                    </tr>
                </tbody></table>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={this.cancel.bind(this)}>Cancel</Button>,
                <Button variant='contained' onClick={this.ok.bind(this)}>Confirm</Button>
            </DialogActions>
        </Dialog>;
    }
};


const styles = {
    idPrefix: { width: 100 },
    select: { margin: 5, overflow: 'visible', width: 400 },
    text: { margin: 5, width: 400 },
    divRow: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    }    
};


export default OrgView;