import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import {
    getForecasts
} from '@apricityhealth/web-common-lib/utils/Services';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import DataTypeInstanceView from '../DataTypeInstanceView';

function testFilter(filter, data) {
    if (filter === "" || filter === "*") return true;
    if (data && filter) {
        filter = filter.toLowerCase();

        if (data.dataId && data.dataId.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientForecastView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter:'',
            filtered:''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;
        let { appContext, patientId, args } = this.props;
        if (patientId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            getForecasts(appContext, patientId, args).then((content) => {
                self.setState({ content, progress: null}, self.applyFilter.bind(self))
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }
    
    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered }, this.updateTab.bind(this) );
    }

    updateTab() {
        const self = this;
        let { filtered } = this.state;
        const dataColumns = [
            { id: 'eventTime', numeric: false, disabledPadding: false, label: 'Event Time' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
            { id: 'p10', numeric: true, disabledPadding: false, label: 'p10' },
            { id: 'p50', numeric: true, disabledPadding: false, label: 'p50' },
            { id: 'p90', numeric: true, disabledPadding: false, label: 'p90' },
            { id: 'actual', numeric: true, label: 'Actual', formatValue: (v, r) => {
                return v ? v.data[r.tupleIndex] : ''
            }},
            { id: 'mpe', numeric: false, disabledPadding: false, label: 'Accuracy', formatValue: (v, r) => { return v ? `${(100 - Number(v)).toFixed(3)}%` : '' } }
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='eventTime'
            columnData={dataColumns}
            data={filtered}
            rowsPerPage={25}
            title='Forecast Data' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let { appContext, patientId, planId } = this.props;
        let { filtered } = this.state;

        let data = filtered[s[0]];
        console.log(`selected `, data.dataId);
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} patientId={patientId} instance={data} planId={planId} dataId={data && data.dataId} 
                onClose={this.closeDialog.bind(this)} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        this.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const self = this;
        const { table, dialog, progress, error } = this.state;
        let { filter } = this.state;

        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
            </div>;

        return <table style={{width: '100%'}}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                {error}
                <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
            </td></tr>
            <tr><td colSpan={2}>
            {table}
            {dialog}
        </td></tr>
        </tbody></table>;
    }
}

export default PatientForecastView;
