import React, { useState, useEffect, useMemo } from 'react';
import {
    AppBar,
    useTheme,
    withStyles,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    Toolbar,
    Typography,
} from '@material-ui/core/';
import {
    Refresh,
    Code
} from '@material-ui/icons';
import NavigationClose from '@material-ui/icons/Close';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';

import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import _ from 'lodash';

import Error from '@apricityhealth/web-common-lib/components/Error';
import Patient from '@apricityhealth/web-common-lib/components/Patient'
import Provider from '@apricityhealth/web-common-lib/components/Provider'
import styles from './RecentActivityPage.styles'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function getAllMessages(appContext, { patientId, offset, limit }) {
    let url = Config.baseUrl + `${Config.pathPrefix}messaging/message`;
    url += `/patient/${patientId}?offset=${offset}&limit=${limit}`
    return new Promise((resolve, reject) => {
        const request = {
            url,
            method: 'GET',
            headers: { "Authorization": _.get(appContext, "state.idToken") }
        };
        console.debug("get messages request", request);
        AxiosRequest(request).then(function (response) {
            console.debug("get messsages response:", response);
            resolve(response.data);
        }).catch(function (error) {
            console.error("send message error:", getErrorMessage(error));
            reject(error);
        });
    });
}

function MessagesPage({ classes, patientId, appContext }) {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'createDate', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));

    const [showPatient, setShowMessage] = useState(false);
    const [selectedNote, setSelectedMessage] = useState({});
    const [messages, setMessages] = useState([])
    const [totalNotes, setTotalMessages] = useState(500)
    const [showCode, setShowCode] = useState(false);


    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])


    async function internalFetchData() {
        setLoading(true);
        try {
            let args = { offset: page * pageSize, limit: pageSize, patientId };
            let data = await getAllMessages(appContext, args);
            let messages = data.messages;
            let totalMessages = data.totalMessages;
            if (Array.isArray(messages)) {
                messages.forEach(element => {
                    element.id = element._id;
                });
                setMessages(messages);
                setTotalMessages(totalMessages);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(getErrorMessage(error))
            console.error(`Error fetching message `, getErrorMessage(error))
        }
    }

    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize, patientId])



    const columns = useMemo(() => {
        return [
            {
                field: 'createDate', headerName: 'Created At',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'patientId', headerName: 'Patient', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return <Patient patientId={v} />
                },
            },
            {
                field: 'providerId', headerName: 'Provider', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return v ? <Provider userId={v} /> : "n/a"
                },
            },
            {
                field: 'subject', headerName: 'Subject', width: 250
            },

            {
                field: 'message', headerName: 'Note', width: 400, renderCell: (params) => (
                    params.value
                )
            },
        ]
    }, []);

    function messageSelected(message) {
        if (alert) {
            setSelectedMessage(message);
            setSelectionModel([message.id])
            setShowMessage(true);
        }
    }

    function onClose() {
        setShowMessage(false)
        setSelectionModel([]);
    }


    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {messages && <DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}

                        rowCount={totalNotes} //should be the total count from the query if there is one
                        rows={messages}
                        onRowClick={({ row }) => messageSelected(row)}
                    />}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Notes</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={messages}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Messages"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showPatient}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={() => onClose()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" >Details</Typography>
                        </Toolbar>
                    </AppBar>

                    <Paper key="2" className={classes.drawerPaper}>
                        {selectedNote && selectedNote.note && <div className={classes.note}><pre className={classes.pre}>{selectedNote.note}</pre></div>}
                        <ReactJson
                            className={classes.jsonPaper}
                            theme={theme.overrides?.ReactJsonView}
                            src={selectedNote}
                            collapsed={3}
                            name="Message"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />

                    </Paper>
                </Drawer>

            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(MessagesPage)