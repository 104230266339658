import React, { Component } from "react";
import PropTypes from 'prop-types';

import moment from "moment-timezone";
import '../styles/calendar.css'
import {
    withStyles,
    CircularProgress,
    IconButton,
    Drawer,
    Paper,
    Tabs,
    Tab,
    TextField
} from '@material-ui/core/';
import { SelectOrg } from "@apricityhealth/web-common-lib/components/SelectOrg";
import { Provider } from "@apricityhealth/web-common-lib/components/Provider";
import { Patient } from "@apricityhealth/web-common-lib/components/Patient";
import { User } from '@apricityhealth/web-common-lib/components/User';
import Org from "@apricityhealth/web-common-lib/components/Org";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import RefreshIcon from '@material-ui/icons/Refresh'
import {
    getOrgs,
    getAppointments,
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import AppointmentView from "./AppointmentView";
import Calendar from './calendar';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

const Promise = require('bluebird');



function filterAppointment(filter, item) {
    filter = filter.toLowerCase();
    if (item.appointmentId && item.appointmentId.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.patientId && item.patientId.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.providerId && item.providerId.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.userId && item.userId.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.status && item.status.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.originator && item.originator.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.category && item.category.toLowerCase().indexOf(filter) >= 0)
        return true;
    if (item.reminderAlertType && item.reminderAlertType.toLowerCase().indexOf(filter) >= 0)
        return true;

    return false;
}


class AppointmentsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            orgId: "*",
            appointments: [],
            tab: 0,
            startTime: moment().subtract(1, 'month'),
            timeZone: null,
            filter: ""
        }
    }

    componentDidMount() {
        this.getContent();
        this.orgChanged("*")
    }

    getContent() {
        let self = this;
        const { appContext } = this.props;
        self.setState({
            progress: <CircularProgress size={20} />
        });
        let promises = [];

        promises.push(getOrgs(appContext));
        Promise.all(promises).then((results) => {
            let orgs = results[0];
            self.setState({ orgs, progress: null });
        }).catch((error) => {
            console.log(error)
            self.setState({ progress: error.message });
        })

    }

    orgChanged(orgId) {
        let self = this;
        const { appContext } = this.props;
        const { orgs } = this.state;
        let selectedOrg = isArrayValid(orgs) ? (orgs.find((org) => org.orgId === orgId)) : null;
        let timeZone = selectedOrg && selectedOrg.timeZone;
        timeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        self.setState({ orgId, selectedOrg, timeZone, progress: <CircularProgress size={20} /> });
        let args = [`endAfter=${this.state.startTime.toISOString()}`, `addNotes=true`, 'status=requested,confirmed,rejected', 'all=true']
        getAppointments(appContext, orgId, args).then((data) => {
            let appointments = data.appointments;
            self.setState({ appointments, progress: null });
        }).catch((error) => {
            console.log(error)
            self.setState({ progress: error.message });
        })
    }

    createAppointment() {
        const { appContext } = this.props;
        const { orgId, timeZone } = this.state;
        if (orgId !== '*') {
            const dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <AppointmentView timezone={timeZone} appContext={appContext} orgId={orgId} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        } else {
            alert("Please select an organization first!");
        }
    }

    appointmentSelected(s, t) {
        if (s.length > 0) {
            const { appContext } = this.props;
            const { filtered, appointments, timeZone } = this.state;
            const appointment = filtered ? filtered[s[0]] : appointments[s[0]];
            const dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <AppointmentView appContext={appContext} appointment={appointment} timezone={timeZone} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        } else {
            this.setState({ progress: null, dialog: null });
        }

    }
    handleStartDayChange(date) {
        let { startTime, orgId } = this.state;
        startTime = moment(date);
        this.setState({ startTime }, () => this.orgChanged(orgId));
    };

    onCloseDialog() {
        this.setState({ dialog: null });
        let { orgId } = this.state;
        if (this._types) this._types.setState({ selected: [] });
        this.orgChanged(orgId);
    }

    handleTabChange = (event, tab) => {
        this.setState({ tab });
    };

    render() {
        let self = this;
        let { appContext } = this.props;
        let { tab, orgId, appointments, dialog, progress, startTime, selectedOrg, timeZone, filter } = this.state;


        const columnData = [
            {
                id: 'orgId', numeric: false, width: 200, disabledPadding: false, label: 'Org', formatValue: (v) => {
                    return <Org appContext={appContext} orgId={v} />
                }
            },
            {
                id: 'patientId', numeric: false, width: 200, disabledPadding: false, label: 'Patient', formatValue: (v) => {
                    return <Patient appContext={appContext} patientId={v} />
                }
            },
            {
                id: 'providerId', numeric: false, width: 200, disabledPadding: false, label: 'Provider', formatValue: (v) => {
                    return <Provider appContext={appContext} userId={v} />
                }
            },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'category', numeric: false, disabledPadding: false, label: 'Category' },
            {
                id: 'notes', numeric: false, disabledPadding: false, label: 'Notes?', formatValue: (v) => {
                    return isArrayValid(v) ? "true" : "false";
                }
            },
            {
                id: 'start', numeric: false, disabledPadding: false, label: 'Start', formatValue: (v) => {
                    return moment(v).tz(timeZone).format("YYYY/MM/DD HH:MM A");
                }
            },
            {
                id: 'end', numeric: false, disabledPadding: false, label: 'End', formatValue: (v) => {
                    return moment(v).tz(timeZone).format("YYYY/MM/DD HH:MM A");
                }
            },
            {
                id: 'userId', numeric: false, width: 200, disabledPadding: false, label: 'Creator', formatValue: (v) => {
                    return <User appContext={appContext} userId={v} userIdHidden={true} />
                }
            },


        ];


        let filtered = appointments.filter((a) => filterAppointment(filter, a));

        return (<>
            <div style={{ display: "flex" }} >
                <SelectOrg
                    style={styles.orgSelect}
                    appContext={this.props.appContext}
                    enableAll={true}
                    orgId={orgId}
                    onChange={(orgId) => { self.orgChanged(orgId) }}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        style={styles.picker}
                        ampm={false}
                        format={"MM/dd/yyyy"}
                        label="Appointments After"
                        value={startTime}
                        onChange={(day) => { self.handleStartDayChange(day) }}
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    style={styles.filter}
                    value={filter}
                    label="Filter"
                    onChange={(event) => {
                        self.setState({ filter: event.target.value })
                    }}
                />
                <div style={{ margin: 5, width: "100%", textAlign: "right" }}>
                    <IconButton
                        disabled={progress !== null}
                        onClick={() => { self.orgChanged(orgId) }}>
                        {progress || <RefreshIcon />}
                    </IconButton>
                </div>
            </div>
            <div>
                <Paper square >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        value={tab}
                        onChange={this.handleTabChange}>
                        <Tab label="Appointments" />
                        <Tab label="Calendar" />
                    </Tabs>
                    {tab === 0 && <EnhancedTable disableMultiSelect={true}
                        onTable={table => self._types = table}
                        onSelected={(s, t) => this.appointmentSelected(s, t)}
                        onAdd={() => this.createAppointment()}
                        orderBy='start'
                        order='desc'
                        rowsPerPage={25}
                        columnData={columnData}
                        data={filtered}
                        title='Appointments' />
                    }
                    {tab === 1 && <div className="calendar"><Calendar
                        appContext={appContext}
                        events={filtered}
                        org={selectedOrg}
                        timeZone={timeZone}
                    /></div>}
                    {dialog}
                </Paper>
            </div>
        </>
        )
    }
}


const styles = {
    div: {
        margin: 10,
        textAlign: 'left'
    },
    orgSelect: {
        margin: 5,
        width: 600
    },

    filter: {
        margin: 5,
        width: 1000
    },
    picker: {
        margin: 5,
        width: 600
    },
    refresh: {
        marginLeft: "auto",
        backgroundColor: "orange",
    }
};

AppointmentsView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(AppointmentsView);

