
const styles = ({ spacing, shadows, palette, topography, mixins }) => ({
    root: {
        backgroundColor: palette?.background?.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginRight: '20px',
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        "&.MuiDataGrid-root": {
            color: palette.text.primary,
            border: '0px solid ',
        },
    },
    actions: {
        backgroundColor: palette?.background?.paper,
    },
    box: {
        display: 'flex',
        gap: '1rem',
        overflowX: 'hidden',
        boxShadow: shadows[2],
        border: '1px solid',
        borderColor: palette.divider,
        padding: '1rem',
        minHeight: '80vh',
        backgroundColor: palette?.background?.paper,
        borderRadius: mixins?.borderRadius,
        flex: 1,
        width: '100%',
        justifyContent: "start",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        width: '100%',
    },
    search: {
        width: '100%',
    },
    refresh: {
        textAlign: "right",
    },
    jsonPaper: {
        theme: 'pop',
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    },
    limit: {
        margin: '0.5rem',
        width: '6rem'
    },
    tag: {
        margin: '0.5rem',
        width: '10rem'
    },
    text: {
        margin: '0.5rem',
        width: '20rem'
    },
    json: {
        color: palette?.text?.primary,
    },

})

export default styles
