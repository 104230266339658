import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import {
    getPatientData,
} from '@apricityhealth/web-common-lib/utils/Services';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import SelectDataTypeCategory from '@apricityhealth/web-common-lib/components/SelectDataTypeCategory';
import DataTypeInstanceView from '../DataTypeInstanceView';


function testFilter(filter, data) {
    if (filter === "" || filter === "*") return true;
    if (data && filter) {
        filter = filter.toLowerCase();

        if (data.dataId && data.dataId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.groupId && data.groupId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.sessionId && data.sessionId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.data && data.data.join(',').toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}


export class PatientDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter:'',
            filtered:'',
            category: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;
        const { appContext, patientId, args } = this.props;
        if (patientId) {
            const { category } = this.state;

            self.setState({ progress: <CircularProgress size={20} />, error: null });

            // recursive function to get all the data within the start and end times, we had some patients in prod with loads of data
            // and we wern't getting all of it.
            function getData(offset = 0, limit = 1000) {
                const opts = [ ...args, `offset=${offset}`, `limit=${limit}`];
                if ( category ) opts.push(`category=${category}`);
                console.log("opts:", opts, category );
                return getPatientData(appContext, patientId, opts).then((content) => {
                    if ( content.data.length === limit ) {
                        return getData(offset + limit).then((more) => {
                            content.data.push( ...more.data );
                            return content;
                        })
                    } else {
                        return content;
                    }
                })
            }

            getData().then((content) => {
                this.setState({ content: content.data, progress: null}, this.applyFilter.bind(self))
            }).catch((error) => {
                this.setState({ progress: null, error: error.message });
            });
        }
    }
    
    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered }, this.updateTab.bind(this) );
    }

    updateTab() {
        const self = this;
        let { filtered } = this.state;
        const dataColumns = [
            { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Event Time' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
            { id: 'groupId', numeric: false, disabledPadding: false, label: 'Group ID' },
            { id: 'sessionId', numeric: false, disabledPadding: false, label: 'Session ID' },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v, r) => { return v ? v.join(',') : '' } }
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='eventTime'
            columnData={dataColumns}
            data={filtered}
            rowsPerPage={25}
            title='Patient Data' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext, patientId, planId } = self.props;

        let { filtered } = self.state;
        let data = filtered[s[0]];
        console.log(`selected `, data.dataId);
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} patientId={patientId} instance={data} planId={planId} dataId={data && data.dataId} onClose={() => { self.closeDialog(); self.loadContent() } } />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const self = this;
        const { table, dialog, progress, error, category } = this.state;
        let { filter } = this.state;

        let filterInput = <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />

        return <table style={{width: '100%'}}><tbody>
            <tr><td>
                {filterInput}
                <SelectDataTypeCategory value={category} onChange={(e) => {
                    console.log("SelectDataTypeCategory:", e.target.value );
                    this.setState({ category: e.target.value}, this.loadContent.bind(this))
                }} />
            </td><td align='right'>
                {error}
                <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
            </td></tr>
            <tr><td colSpan={2}>
            {table}
            {dialog}
        </td></tr>
        </tbody></table>;
    }
}

export default PatientDataView;
