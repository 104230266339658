import React from 'react';
import {
    Tabs,
    Tab,
    Paper
} from '@material-ui/core/';

import {
    isArrayValid,
} from '../../utils/Utils';

import ContactView from './ContactView'
import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';

class ContactsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            org: props.org,
            contactTabs: [],
            currentContactTab: '0',
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (this.props.org !== oldProps.org) {
            this.setState({ org: this.props.org }, this.loadContent());
        }
    }

    closeOrg() {
        this.props.onClose();
    }

    loadContent() {
        const self = this;
        const { org } = this.state;

        let contactTabs = [];
        if (org && isArrayValid(org.contacts)) {
            let contacts = org.contacts;
            for (let i = 0; i < contacts.length; ++i) {
                let contact = contacts[i]
                contactTabs.push(<ContactView org={org} contact={contact}
                    contactId={contact.contactId} onDelete={(contact) => self.deleteContact(contact)} />);
            }
        }

        this.setState({ contactTabs });
    }

    deleteContact(contact) {
        let { org } = this.state;
        let i = org.contacts.findIndex((e) => e.contactId === contact.contactId);
        if (i >= 0) {
            org.contacts.splice(i, 1);
            this.setState({ org }, this.loadContent.bind(this));
        }
    }

    selectContactTab(tab) {
        if (tab === 'create')
            this.createContact();
        else
            this.setState({ currentContactTab: `${tab}`, dialog: null, progress: null });
    }

    createContact() {
        let { org } = this.state;
        if (!org.contacts)
            org.contacts = [];

        org.contacts.push({ contactId: 'New Contact', phoneNumbers: [], emails: [] });
        this.setState({ org }, this.loadContent.bind(this));
    }

    closeDialog() {
        this.setState({ dialog: null });
    }

    render() {
        const self = this;
        let { dialog, contactTabs, currentContactTab } = self.state;

        let displayTabs = [];
        for (let i = 0; i < contactTabs.length; ++i) {
            const tab = contactTabs[i];
            displayTabs.push(<Tab key={i} label={tab.props.contactId} style={{ color: 'white' }} value={`${i}`} />);
        }
        if (currentContactTab < 0 || currentContactTab >= (displayTabs.length)) {
            currentContactTab = '0';
        }

        return <Paper>
            <EnhancedTableToolbar title='Contacts' onAdd={this.createContact.bind(this)} onDelete={() => {}} numSelected={0} />
            {contactTabs.length > 0 ? <div>
                <Tabs style={styles.tab} variant="scrollable" value={currentContactTab}
                    onChange={(e, t) => self.selectContactTab(t)} >
                    {displayTabs}
                </Tabs>
                {contactTabs[currentContactTab]}
            </div> : null}
            {dialog}
        </Paper>;
    }
}

const styles = {
    tab: {
        "backgroundColor": "#FF9800"
    }
}

export default ContactsView;
