import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import {
    getPatientData,
} from '@apricityhealth/web-common-lib/utils/Services';

import DataTypeInstanceView from '../DataTypeInstanceView';

function testFilter(filter, type) {
    if (filter === "" || filter === "*") return true;
    if (type && filter) {
        filter = filter.toLowerCase();
        if (type.dataId && type.dataId.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter: '',
            filtered: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        console.log(`PatientDataView componentDidUpdate`)
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered }, this.updateTable.bind(this));
    }

    loadContent() {
        const self = this;
        let { appContext, patientId, args } = this.props;
        if (patientId) {
            if (!args) args = [];
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            getPatientData(appContext, patientId, args.concat(["category=labTest"])).then((content) => {
                self.setState({ content:content.data, progress: null }, self.applyFilter.bind(self));
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    updateTable() {
        const self = this;
        let { filtered } = this.state;
        const dataColumns = [
            { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Lab Date' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'dataId' },
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='eventTime'
            columnData={dataColumns}
            data={filtered}
            rowsPerPage={25}
            title='Patient Labs' />
        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext, patientId, planId } = self.props;
        let { filtered } = self.state;
        const data = filtered[s[0]];
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} patientId={patientId} instance={data} planId={planId} dataId={data.dataId} onClose={() => self.closeDialog()} />
        </Drawer>;
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress, error } = this.state;
        const self = this
        let { filter } = this.state;
        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
        </div>;

        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                {table}
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientDataView;
