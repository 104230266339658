import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core/';


import EditPatientPhoneDialog from './EditPatientPhoneDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';

class PatientPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumbers: [],
            dialog: null
        }
    }

    componentDidMount(){
        this.setState({
            phoneNumbers: this.props.phoneNumbers || []
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.phoneNumbers !== this.props.phoneNumbers){
            this.setState({phoneNumbers: this.props.phoneNumbers})
        }
    }


    onAddPhone() {
        let { phoneNumbers } = this.state;
        if (!Array.isArray(phoneNumbers)) phoneNumbers = [];

        phoneNumbers.push({ number: '', numberType: 'mobile', note: '' });
        let index = phoneNumbers.length - 1;

        this.setState({
            phoneNumbers: phoneNumbers,
            dialog: <EditPatientPhoneDialog
                phone={phoneNumbers[index]}
                onCancel={() => {
                    phoneNumbers.splice(index, 1);
                    this.setState({ phoneNumbers, dialog: null })
                }}
                onDone={(v) => {
                    phoneNumbers[index] = v;
                    this.setState({ phoneNumbers, dialog: null });
                }}
            />
        })
    }

    onEditPhone(index) {
        const { phoneNumbers } = this.state;
        this.setState({
            dialog: <EditPatientPhoneDialog
                phone={phoneNumbers[index]}
                onCancel={() => {
                    this.setState({dialog: null});
                }}
                onDone={(v) => {
                    phoneNumbers[index] = v;
                    this.setState({ phoneNumbers, dialog: null});
                }}
            />
        });

        this.setState({create: false, editIndex: index, modalOpen:true})
    }

    onDeletePhone(phoneIndex, evt) {
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        let { phoneNumbers } = this.state;
        phoneNumbers.splice( phoneIndex, 1);
        this.setState({phoneNumbers});
    }

    render(){
        const { phoneNumbers, dialog } = this.state;

        let phoneRows = () => {
            var tableRows = []
            if (Array.isArray(phoneNumbers)){
                phoneNumbers.forEach( (phoneItem, index)=> {
                    var row = (
                        <TableRow
                            hover
                            onClick={this.onEditPhone.bind(this, index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                {phoneItem.number}
                            </TableCell>
                            <TableCell>
                                {phoneItem.numberType}
                            </TableCell>
                            <TableCell>
                                {phoneItem.note}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete" s 
                                    onClick={this.onDeletePhone.bind(this,index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                })
            }
            return tableRows 
        }

        return (
            <div>
                {dialog}
                <Paper>
                <EnhancedTableToolbar
                    title='Phones'
                    onAdd={this.onAddPhone.bind(this)}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>PhoneNumber</TableCell>
                            <TableCell>Phone Type</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {phoneRows()}
                    </TableBody>
                </Table>
                </Paper>
            </div>
        )
    }
}

export default PatientPhone;