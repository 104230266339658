import React, { Component } from 'react';
import {
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button
} from '@material-ui/core/';

import Moment from 'moment';
import Sequential from 'promise-sequential';
import ReactFileReader from 'react-file-reader';

import {
    getPatients,
    runPatientDetect,
    getPatientDataGroups,
    getPatientJournals
} from '@apricityhealth/web-common-lib/utils/Services';

import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const CSV = require('neat-csv');

export class DetectAllDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            message: "Select plan and numbers of days from today to run detect.",
            error: null,
            running: false,
            cancel: false,
            planId: '',
            patientIds: [],
            days: 14
        }
    }

    onClose() {
        this.setState({ cancel: true }, this.props.onClose);
    }

    onCancel() {
        this.setState({ cancel: true });
    }

    runDetectForJournal(group, journal) {
        if (this.state.cancel)
            return Promise.reject(new Error("Detect cancelled."));
        //console.log("runDetectForJournal:", group, journal );

        const { appContext } = this.props;
        const { groupId, patientId } = group;
        const { sessionId, createDate } = journal;
        this.setState({ message: `Running detect on ${patientId}, group ${groupId}, session ${sessionId} @ ${createDate}...` });
        return runPatientDetect(appContext, patientId, { groupId, sessionId, eventTime: createDate, recalculateModels: true });
    }

    runDetectOnGroup(group) {
        const { appContext } = this.props;
        const { groupId, patientId } = group;

        return new Promise((resolve, reject) => {
            getPatientJournals(appContext, patientId, [`groupId=${groupId}`]).then((journals) => {
                // need to execute journals oldest to newest, so sort by createDate
                journals.sort((a, b) => {
                    return Moment(a.createDate) - Moment(b.createDate);
                })

                let promises = [];
                for (let i = 0; i < journals.length; ++i) {
                    let journal = journals[i];
                    let eod = journal.journal.find((e) => e.eod === true && e.questionId !== 'bail');
                    if (!eod) continue;       // if no eod flag as true, then it's an incompleted journal
                    promises.push(() => this.runDetectForJournal(group, journal));
                }

                return Sequential(promises);
            }).then(() => {
                resolve();
            }).catch((err) => {
                console.error("runDetect error:", err);
                reject(err);
            })
        })
    }

    runDetectOnPatient(patients, i) {
        const { appContext } = this.props;
        const { days } = this.state;
        const patientId = patients[i];

        return new Promise((resolve, reject) => {
            if (this.state.cancel)
                return reject(new Error("Detect cancelled."));

            this.setState({ progress: (i * 100) / patients.length });
            getPatientDataGroups(appContext, patientId, { days } ).then((groups) => {
                groups.sort((a, b) => {
                    return Moment(a.eventTime) - Moment(b.eventTime);
                });
                console.log("groups:", groups);

                let promises = [];
                for (let i = 0; i < groups.length; ++i) {
                    promises.push(() => this.runDetectOnGroup(groups[i]));
                }

                return Sequential(promises);
            }).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        })
    }

    onRun() {
        const { patientIds } = this.state;

        this.setState({ cancel: false, running: true, error: null });

        let promises = [];
        for (let i = 0; i < patientIds.length; ++i) {
            promises.push(this.runDetectOnPatient.bind(this, patientIds, i));
        }

        Sequential(promises).then(() => {
            this.setState({ running: false, progress: 100, message: `Done processing ${patientIds.length} patients.` });
        }).catch((err) => {
            this.setState({ running: false, error: getErrorMessage(err) });
        })
    }

    loadPatients() {
        const { appContext } = this.props;
        const { planId } = this.state;
        if (planId) {
            getPatients(appContext, { planId } ).then((result) => {
                let patientIds = result.map((e) => e.patientId);
                this.setState({ patientIds });
            }).catch((err) => {
                console.err("loadPatients error:", err);
            })
        }
    }

    componentDidMount() {
        this.loadPatients();
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    importPatientIds(files) {
        console.log("importPatientIds:", files);

        this.readFile(files[0]).then((result) => {
            return CSV(result);
        }).then((rows) => {
            let patientIds = [];
            for (let i = 0; i < rows.length; ++i) {
                if (rows[i].patientId !== undefined)
                    patientIds.push(rows[i].patientId);
            }

            let error = patientIds.length === 0 ? 'No patientIds column found in the CSV' : '';
            this.setState({ patientIds, error })
        })
    }

    render() {
        const { appContext } = this.props;
        const { running, planId, days, progress, message, error, patientIds } = this.state;

        return <Dialog open={true} maxWidth='sm' fullWidth={true}>
            <DialogTitle>{`Run Detect on ${patientIds.length} patients in a plan`}</DialogTitle>
            <DialogContent>
                <SelectPlan disabled={running} appContext={appContext} planId={planId} onChange={(plan) => {
                    this.setState({ planId: plan ? plan.planId : '' }, this.loadPatients.bind(this));
                }} />
                <br />
                <TextField disabled={running} label='Days' value={days} onChange={(e) => {
                    this.setState({ days: e.target.value })
                }} />
                <br />
                <br />
                <ReactFileReader fileTypes={[".csv"]} multipleFiles={false} handleFiles={this.importPatientIds.bind(this)} >
                    <Button variant='contained'>Upload CSV</Button></ReactFileReader>
                <br />
                <div><br />
                    <LinearProgress variant="determinate" value={progress} />
                    <br />
                    {message}
                    <br />
                    <span style={{ color: 'red' }}>{error}</span>
                </div>
            </DialogContent>
            <DialogActions>
                {running ? <Button variant='contained' onClick={this.onCancel.bind(this)}>Cancel</Button> :
                    <Button disabled={patientIds.length === 0} variant='contained' onClick={this.onRun.bind(this)}>Run</Button>}
                <Button disabled={running} variant='contained' onClick={this.onClose.bind(this)}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
};

export default DetectAllDialog;
