import React, { useState, useEffect, useMemo } from 'react';
import {
    AppBar,
    useTheme,
    withStyles,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    Toolbar,
    Typography,
} from '@material-ui/core/';
import {
    Refresh,
    Code
} from '@material-ui/icons';
import NavigationClose from '@material-ui/icons/Close';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';

import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import useOrgs from '@apricityhealth/web-common-lib/hooks/useOrgs';
import Patient from '@apricityhealth/web-common-lib/components/Patient'
import Org from '@apricityhealth/web-common-lib/components/Org'
import Provider from '@apricityhealth/web-common-lib/components/Provider'
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services'

import styles from './RecentActivityPage.styles'


function AppointmentsPage({ classes, patientId }) {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'createdAt', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));

    const [showAppointment, setShowAppointment] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [appointments, setAppointments] = useState([])
    const [totalAppointments, setTotalAppointments] = useState(500)
    const [showCode, setShowCode] = useState(false);

    let [{ data, loading, fetchAppointments, error }] = useOrgs(null, []);

    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])


    function internalFetchData() {
        try {
            let args = []
            if (patientId && patientId !== "*") {
                args.push(`patientId=${patientId}`)
            }
            args.push(`addNotes=true`)
            args.push(`sort=createdAt`)
            args.push(`sortOrder=-1`)
            fetchAppointments("*", args, { offset: page * pageSize, limit: pageSize });
        } catch (error) {
            console.error(`Error fetching alert data `, error)
        }
    }

    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize, patientId])


    useEffect(() => {
        if (data) {
            let appointments = data.appointments;
            if (Array.isArray(appointments)) {
                appointments.forEach(element => {
                    element.id = element._id;
                });

                setAppointments(appointments);
                setTotalAppointments(data.total);
            }
        }
    }, [data])

    const columns = useMemo(() => {
        return [
            {
                field: 'createdAt', headerName: 'Created At',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'orgId', headerName: 'Org', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return <Org orgId={v} />
                },
            },
            {
                field: 'patientId', headerName: 'Patient', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return <Patient patientId={v} />
                },
            },
            {
                field: 'providerId', headerName: 'Provider', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return <Provider userId={v} />
                },
            },
            {
                field: 'category', headerName: 'Category', width: 150
            },

            {
                field: 'notes', headerName: 'Note', width: 400, renderCell: (params) => (
                    isArrayValid(params.value) ? params.value.map(n => n.note).join(",") : params.value
                )
            },
        ]
    }, []);

    function appointmentSelected(journal) {
        if (alert) {
            setSelectedAppointment(journal);
            setSelectionModel([journal.id])
            setShowAppointment(true);
        }
    }

    function onClose() {
        setShowAppointment(false)
        setSelectionModel([]);
    }




    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {appointments && <DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}

                        rowCount={totalAppointments} //should be the total count from the query if there is one
                        rows={appointments}
                        onRowClick={({ row }) => appointmentSelected(row)}
                    />}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Notes</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={appointments}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Notes"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showAppointment}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={() => onClose()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" >Details</Typography>
                        </Toolbar>
                    </AppBar>

                    <Paper key="2" className={classes.jsonPaper}>
                        <div style={{ flexGrow: 1, width: "100%", fontSize: '18px', margin: '10px' }}>
                            {'Appointment created by ' + selectedAppointment.originator + ' for ' + moment(selectedAppointment.start).format("llll")}
                        </div>
                        {selectedAppointment && selectedAppointment.note && <div className={classes.note}><pre className={classes.pre}>{selectedAppointment.note}</pre></div>}
                        <ReactJson
                            theme={theme.overrides?.ReactJsonView}
                            src={selectedAppointment}
                            className={classes.json}
                            collapsed={3}
                            name="Appointment"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />

                    </Paper>
                </Drawer>

            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(AppointmentsPage)