
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
    TextField,
    withStyles,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core/';
import {
    Refresh
} from '@material-ui/icons';
import {
    getReportSeries,
    isArrayValid,
    getAccount,
    getMetrics
} from '@apricityhealth/web-common-lib/utils/Services';
import Chart from 'react-apexcharts';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';


let DATA_ID_OPTIONS = [
    { value: 'iraeOverallAlert', label: 'IRAE Overall' },
]

function PatientChart({ classes, appContext, patient }) {
    const [loading, setLoading] = useState(false);
    const [days, setDays] = useState(365);
    const [patientTotals, setPatientTotals] = useState(null);
    const [loginSuccess, setLoginSuccess] = useState(null);
    const [loginFailure, setLoginFailure] = useState(null);

    const fetchData = async () => {
        try {
            if (patient?.patientId) {
                setLoading(true)
                let [report, user] = await Promise.all([
                    getReportSeries(appContext, 'PatientMetrics', { patientId: patient.patientId, dataId: DATA_ID_OPTIONS.map(item => item.value).join(","), startTime: moment().subtract(days, 'day').toISOString() }),
                    getAccount(appContext, patient.userId)
                ]);

                setPatientTotals(report.patientTotals)
                if (user) {
                    let loginSuccessArgs = [];
                    loginSuccessArgs.push("EventName=LoginSuccess");
                    loginSuccessArgs.push(`UserName=${encodeURIComponent(user.email)}`);
                    loginSuccessArgs.push("limit=1");

                    let loginFailArgs = [];
                    loginFailArgs.push("EventName=LoginFailure");
                    loginFailArgs.push(`UserName=${encodeURIComponent(user.email)}`);
                    loginFailArgs.push("limit=1");
                    let [loginSuccess, loginFailure] = await Promise.all([
                        getMetrics(appContext, loginSuccessArgs),
                        getMetrics(appContext, loginFailArgs)
                    ]);
                    if (loginSuccess && isArrayValid(loginSuccess.metrics))
                        setLoginSuccess(loginSuccess.metrics[0]);
                    if (loginFailure && isArrayValid(loginFailure.metrics))
                        setLoginFailure(loginFailure.metrics[0]);

                }

                setLoading(false);
            } else {
                console.error(`no patient to load metrics for`)
            }
        } catch (error) {
            console.error(getErrorMessage(error))
        }
    }

    useEffect(() => {
        return () => {
            setPatientTotals({})
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [patient])

    let totalsChart = useMemo(() => {
        let tiles = [];
        if (patientTotals?.dataTotals) {
            let dataTotals = patientTotals?.dataTotals;
            //we want irae to be first so just use the id list
            if (dataTotals) {
                DATA_ID_OPTIONS.forEach(option => {
                    let dataChart = JSON.parse(JSON.stringify(chartData))
                    let categories = [];
                    let series = [];
                    let dataId = option.value;
                    let name = option.label;
                    dataChart.options.title.text = name;
                    let alertData = dataTotals[dataId];
                    if (alertData) {
                        let data = []
                        let colors = [];
                        console.debug(`keys`, Object.keys(alertData))
                        Object.keys(alertData).forEach(alert => {
                            categories.push(alertData[alert].name);
                            data.push(alertData[alert].count);
                            colors.push(alertData[alert].color)
                        });
                        series.push({ data });
                        dataChart.options.colors = colors;
                        dataChart.options.xaxis.categories = categories;
                        tiles.push(<Chart
                            key={dataId}
                            options={dataChart.options}
                            series={series}
                            type="bar"
                            height={200}
                            width={200} />)
                    }
                })
            }
        }

        return tiles
    }, [patientTotals]);

    const patientTable = useMemo(() => {
        function getRow(text, value) {
            return <TableRow key={text}>
                <TableCell>{text}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow >
        }
        let rows = []
        if (patientTotals) {
            rows.push(getRow(`# Completed Check-Ins (${days} days)`, patientTotals["completeCheckIn"]));
            rows.push(getRow(`# In-Complete Check-Ins (${days} days)`, patientTotals["inCompleteCheckIn"]));
            rows.push(getRow(`# Baselines (${days} days)`, patientTotals["baselineCheckIn"]));
            rows.push(getRow(`# Bailed (${days} days)`, patientTotals["bailedCheckIn"]));
            patientTotals?.mostRecentAlertData && Object.keys(patientTotals.mostRecentAlertData).forEach((alert) => {
                let alertName = DATA_ID_OPTIONS.find(item => item.value === alert).label;
                let data = patientTotals.mostRecentAlertData[alert][0]
                rows.push(getRow(`Most recent ${alertName}`, <> {moment(data.eventTime).local().format("LLL")}; <span style={{color:data.data[0]}}>{data.data[0]}</span></>));
            });
            rows.push(getRow("Recent Successful Login", loginSuccess ? moment(loginSuccess["createDate"]).local().format("LLL") : "N/A"));
            rows.push(getRow("Recent Failed Login", loginFailure ? moment(loginFailure["createDate"]).local().format("LLL") : "N/A"));
        }
        return <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>;

    }, [patientTotals, loginSuccess, loginFailure])

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.search}>
                    <TextField className={classes.text} label="Days History" value={days}
                        onKeyDown={(e) => { if (e.key === "Enter") fetchData() }}
                        onChange={(e) => {
                            if (e.target.value === "" || !Number.isNaN(Number(e.target.value))) {
                                setDays(e.target.value)
                            }
                        }} />
                </div>
                <div className={classes.refresh}>
                    <IconButton
                        color="primary"
                        style={{ marginRight: '2rem' }}
                        onClick={() => fetchData()}
                    >
                        {loading ? <CircularProgress size={25} /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            {isArrayValid(totalsChart) ? totalsChart : "No Alert Data for time range"}
            {patientTable}
        </div>

    )
}


const styles = ({ palette, mixins, shadows }) => ({
    root: {
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginRight: '20px',
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    row: {
        display: "flex",
        width: '100%',
    },
    search: {
        width: '100%',
    },
    refresh: {
        textAlign: "right",
    },
})

export default withStyles(styles)(PatientChart);


const chartData = {
    options: {
        chart: {
            id: 'bar-chart',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                padding: '0rem',
                distributed: true
            }
        },
        xaxis: {
            type: 'category',
            // categories: ['high', 'medium', 'low'],
        },
        title: {
            text: 'IRAE Overall Alerts',
            floating: true,
            align: 'center',
            style: {
                color: 'red'
            }
        },
        fill: {
            type: 'solid'
        },
        dataLabels: {
            enabled: true,
            offsetY: 20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        grid: {
            show: false
        },
        colors: ['#546E7A', '#304758']
    },
};