
const styles = ({ spacing, palette, mixins }) => ({
    root: {
        backgroundColor: palette?.background?.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        // flexGrow: 1,
        padding: spacing(),
        marginRight: spacing(2),
        borderRadius: mixins.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'relative',
        overflow: 'hidden',
        // "& .MuiTableRow-root": {
        //     '&:nth-of-type(odd)': {
        //         backgroundColor: grey[100],
        //     },
        // },
        // "& .MuiTableHead-root": {
        //     "& .MuiTableCell-root": {
        //         backgroundColor: palette?.tableHeader?.background,
        //         color: "#b64925",
        //     }
        // }

    },
    cleanRow: {
        "& .MuiTableRow-root": {
            '&:nth-of-type(odd)': {
                backgroundColor: "#f9f9f9",
            },
        },
    },
    info: {
        display: 'flex',
        justifyContent: "top",
        flexDirection: "column",
        width: '100%',
        valign: "top",
        alignItems: "top",
        marginTop: spacing(1),
    },
    table: {
        borderRadius: mixins?.borderRadius,
        border: '10px blue',
        padding: '10px',
        backgroundColor: palette?.background?.page,
        width: '100%',
        height: "100%",
        valign: "top",
        alignItems: "top",
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    },
    heading: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: '0.5rem'
    },
    left: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: '1rem'
    },
    right: {
        alignItems: "center",
        display: "flex"
    },
    search: {
        display: "flex",
        width: '100%'
    },
    icon: {
        width: '1.5rem',
        height: '1.5rem',
    },

    noBulletList: {
        listStyleType: "square",
        padding: 0, /* Remove padding */
        margin: 0,/* Remove margins */
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
})

export default styles
