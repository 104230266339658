import React, { Component, useState } from 'react';
import {
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    InputLabel,
    Button
} from '@material-ui/core/';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Medication from '@apricityhealth/web-common-lib/components/Medication';
import SelectMedication from './SelectMedication';
import _ from 'lodash';

export class PatientMedicationsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medications: props.medications || [],
            filtered: [],
            dialog: null,
            progress: null,
            filter: ''
        }

    }

    componentDidMount() {
        this.applyFilter();
    }

    componentDidUpdate(prevProps) {
        if (this.props.medications !== prevProps.medications)
            this.applyFilter();
    }

    applyFilter() {
        let { filter, medications } = this.state;
        let filtered = [];
        for (let i = 0; i < medications.length; ++i) {
            const medication = medications[i];
            if (filter) {
                if (medication.medicationId.indexOf(filter.toLocaleLowerCase()) >= 0) {
                    filtered.push(medication);
                } else if (medication.label && medication.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                    filtered.push(medication);
                }
            } else {
                filtered.push(medication);
            }
        }
        this.setState({ filtered });
    }

    addNewMedication() {
        this.setState({
            dialog: <PatientMedication
                appContext={this.props.appContext}
                planId={this.props.planId}
                medication={{}}
                save={(updatedMed) => {
                    if (typeof this.props.save == 'function') {
                        let { medications } = this.state;
                        let { appContext } = this.props;
                        updatedMed.source = [{
                            updatedBy: _.get(appContext, "state.account.username"),
                            date: new Date(),
                            status: "new"
                        }]
                        medications.push(updatedMed)
                        medications.forEach(m => {
                            delete m.ref
                        });
                        this.props.save(medications);
                    }
                    this.setState({ dialog: null })
                }}
                cancel={() => {
                    this.setState({ dialog: null })
                }}
                remove={() => {
                    this.setState({ dialog: null })
                }}
            />
        })
    }

    editMedication(s) {

        if (s.length > 0) {
            const medication = this._table.state.data[s[0]]; //filtered ? filtered[s[0]] : roster[s[0]];
            this.setState({
                dialog: <PatientMedication
                    appContext={this.props.appContext}
                    planId={this.props.planId}
                    medication={medication}
                    save={(updatedMed) => {
                        if (typeof this.props.save == 'function') {
                            let { medications } = this.state;
                            let { appContext } = this.props;
                            let dIndex = medications.findIndex((m => m._id === updatedMed._id));
                            if (dIndex >= 0) {
                                updatedMed.source = [
                                    ...(updatedMed.source || []),
                                    {
                                        updatedBy: _.get(appContext, "state.account.username"),
                                        date: new Date(),
                                    }]
                                medications[dIndex] = updatedMed;
                                medications = medications.map((m => { delete m.ref; return m; }))
                                this.props.save(medications);
                            }
                        }
                        this.setState({ dialog: null })
                    }}
                    cancel={() => {
                        this.setState({ dialog: null })
                    }}
                    remove={() => {
                        let { medications } = this.state;
                        let dIndex = medications.findIndex((m => m._id === medication._id));
                        if (dIndex >= 0) {
                            medications.splice(dIndex, 1);
                            this.props.save(medications);
                        }

                        this.setState({ dialog: null })
                    }}
                />
            })
        }
    }

    render() {
        const { planId, appContext } = this.props;
        const { dialog, filter, filtered } = this.state;
        const dataColumns = [
            { id: 'startDate', label: 'Start Date' },
            { id: 'endDate', label: 'End Date' },
            {
                id: 'medicationId', label: 'Name', formatValue: (v, r) => {
                    return <Medication appContext={appContext} planId={planId} medicationId={v} ref={(ref) => ref ? r.label = ref.label : null} />;
                }
            },
            { id: 'text', label: 'Text' }
        ];

        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => this.setState({ filter: e.target.value }, this.applyFilter.bind(this))} />
        </div>;
        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                    {dialog}
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable
                    disableDelete={true}
                    onAdd={() => this.addNewMedication()}
                    disableMultiSelect={true}
                    onTable={table => this._table = table}
                    onSelected={(s, t) => this.editMedication(s, t)}
                    order='desc'
                    orderBy='startDate'
                    columnData={dataColumns}
                    data={filtered}
                    rowsPerPage={25}
                    title='Medications' />
            </td></tr>
        </tbody></table>;
    }
}


function PatientMedication({ medication, planId, save, cancel, remove, appContext }) {

    const [patientMedication, setPatientMedication] = useState(medication);

    return (<Dialog model="true" open={true}>
        <DialogTitle>Edit Medication</DialogTitle>
        <DialogContent>
            <SelectMedication
                appContext={appContext}
                style={{ marginTop: "5px", width: "100%" }}
                label={<span style={{ fontSize: '1em' }}>Medication:</span>}
                planId={planId}
                fullMedicationInfo
                medicationId={patientMedication.medicationId}
                onChange={(med) => setPatientMedication({ ...patientMedication, medicationId: med?.medicationId, name: med?.name })}
            />
            <br />
            <InputLabel>Start Date:</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    fullWidth
                    clearable
                    format="MM/dd/yyyy"
                    autoComplete="off"
                    animateYearScrolling={false}
                    value={patientMedication.startDate || null}
                    onChange={time => setPatientMedication({ ...patientMedication, startDate: new Date(time).toISOString() })}
                />
            </MuiPickersUtilsProvider>
            <br />  <br />
            <InputLabel>End Date:</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    fullWidth
                    clearable
                    format="MM/dd/yyyy"
                    autoComplete="off"
                    invalidDateMessage="Invalid Date"
                    animateYearScrolling={false}
                    value={patientMedication.endDate || null}
                    onChange={time => setPatientMedication({ ...patientMedication, endDate: new Date(time).toISOString() })}
                />
            </MuiPickersUtilsProvider>



        </DialogContent>
        <DialogActions>
            <Button variant="contained"
                onClick={() => {
                    if (typeof remove == 'function') {
                        remove()
                    }
                }}>Delete</Button>
            <Button variant="contained"
                onClick={() => {
                    if (typeof cancel == 'function') {
                        cancel()
                    }
                }}>Cancel</Button>
            <Button variant="contained"
                onClick={() => {
                    if (typeof save == 'function') {
                        save(patientMedication)
                    }
                }
                }>Save</Button>
        </DialogActions>
    </Dialog>)
}

export default PatientMedicationsView;
