import { isArrayValid } from '../utils/Utils';
import React, { Component } from 'react';
import {
    FormLabel,
} from '@material-ui/core/';
import Moment from 'moment';

class UserDetails extends Component {


    addPatientInfo(baseEdits) {
        baseEdits.push(<div style={{ marginTop: '10px' }} />)
        baseEdits.push(<FormLabel style={styles.question}  >First: {this.props.patient.firstName}</FormLabel>);
        baseEdits.push(<FormLabel style={styles.question}  >Last: {this.props.patient.lastName} </FormLabel>);
        if (this.props.patient) {
            baseEdits.push(<FormLabel style={styles.question} >Gender: {this.props.patient.gender} </FormLabel>);

            baseEdits.push(<FormLabel style={styles.question} >DOB: {Moment(this.props.patient.dob).utcOffset(0).format('MM/DD/YYYY')} </FormLabel>);

            baseEdits.push(<div style={{ marginTop: '10px' }} />);
            baseEdits.push(<FormLabel style={styles.question} >Contact Method: {this.props.patient.contactMethod} </FormLabel>);
            if (isArrayValid(this.props.patient.emails)) {
                let emails = this.props.patient.emails;
                for (let index = 0; index < emails.length; index++) {
                    let email = emails[index];
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >{`${email.emailType}: ${email.email}`}</FormLabel>);
                }
            }
            if (isArrayValid(this.props.patient.phoneNumbers)) {
                let phoneNumbers = this.props.patient.phoneNumbers;
                for (let index = 0; index < phoneNumbers.length; index++) {
                    let phone = phoneNumbers[index];
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >{`${phone.numberType}: ${phone.number}`}</FormLabel>);
                }
            }
            if (isArrayValid(this.props.patient.address)) {
                let addresses = this.props.patient.address;
                for (let index = 0; index < addresses.length; index++) {
                    let address = addresses[index];
                    baseEdits.push(<div style={{ marginTop: '20px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Address Type: {address.addressType}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Street: {address.street1}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >City: {address.city}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Postal Code: {address.postalCode}</FormLabel>);

                }
            }
        }
        baseEdits.push(<div style={{ marginTop: '20px' }} />);
        baseEdits.push(<FormLabel style={styles.question}>Record Id: {this.props.patient.recordId}</FormLabel>);
        baseEdits.push(<div style={{ marginTop: '10px' }} />)
        baseEdits.push(<FormLabel style={styles.question} >Patient Id: {this.props.patient.patientId} </FormLabel>);
        if (this.props.patient.createDate) {
            baseEdits.push(<div style={{ marginTop: '10px' }} />)
            baseEdits.push(<FormLabel style={styles.question} >Create Date: {new Date(this.props.patient.createDate).toLocaleDateString()} </FormLabel>);
        }
        if (this.props.patient.updatedAt) {
            baseEdits.push(<div style={{ marginTop: '10px' }} />)
            baseEdits.push(<FormLabel style={styles.question} >Last updated: {new Date(this.props.patient.updatedAt).toLocaleDateString()} </FormLabel>);
        }
        if (this.props.patient.consentGiven) {
            baseEdits.push(<div style={{ marginTop: '10px' }} />)
            baseEdits.push(<FormLabel style={styles.question} >Consent Date: {new Date(this.props.patient.consentGiven).toLocaleDateString()} </FormLabel>);
        }
    }

    addAccountInfo(baseEdits) {
        baseEdits.push(<div style={{ marginTop: '10px' }} />)
        if (this.props.account) {
            baseEdits.push(<FormLabel style={styles.question}  >User Status: {this.props.account.userStatus}</FormLabel>);
            baseEdits.push(<div style={{ marginTop: '10px' }} />);
            if (isArrayValid(this.props.account.groups)) {
                baseEdits.push(<FormLabel style={styles.question}  >Groups: {this.props.account.groups.join(', ')}</FormLabel>);
            }
        }
    }
    addProviderInfo(baseEdits) {
        baseEdits.push(<div style={{ marginTop: '10px' }} />)
        baseEdits.push(<FormLabel style={styles.question}  >First: {this.props.provider.firstName}</FormLabel>);
        baseEdits.push(<FormLabel style={styles.question}  >Last: {this.props.provider.lastName} </FormLabel>);
        if (this.props.provider) {
            baseEdits.push(<div style={{ marginTop: '10px' }} />);
            baseEdits.push(<FormLabel style={styles.question} >Contact Method: {this.props.provider.contactMethod} </FormLabel>);

            if (isArrayValid(this.props.provider.emails)) {
                let emails = this.props.provider.emails;
                for (let index = 0; index < emails.length; index++) {
                    let email = emails[index];
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >{`${email.emailType}: ${email.email}`}</FormLabel>);
                }
            }

            if (isArrayValid(this.props.provider.phoneNumbers)) {
                let phoneNumbers = this.props.provider.phoneNumbers;
                for (let index = 0; index < phoneNumbers.length; index++) {
                    let phone = phoneNumbers[index];
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >{`${phone.numberType}: ${phone.number}`}</FormLabel>);
                }
            }
            if (isArrayValid(this.props.provider.address)) {
                let addresses = this.props.provider.address;
                for (let index = 0; index < addresses.length; index++) {
                    let address = addresses[index];
                    baseEdits.push(<div style={{ marginTop: '20px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Address Type: {address.addressType}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Street: {address.street1}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >City: {address.city}</FormLabel>);
                    baseEdits.push(<div style={{ marginTop: '10px' }} />);
                    baseEdits.push(<FormLabel style={styles.question} >Postal Code: {address.postalCode}</FormLabel>);

                }
            }
        }
        baseEdits.push(<div style={{ marginTop: '20px' }} />);
        baseEdits.push(<FormLabel style={styles.question}>Record Id: {this.props.provider.recordId}</FormLabel>);
        baseEdits.push(<div style={{ marginTop: '10px' }} />)
        baseEdits.push(<FormLabel style={styles.question} >Provider Id: {this.props.provider.providerId} </FormLabel>);
        if (this.props.provider) {
            baseEdits.push(<div style={{ marginTop: '10px' }} />)
            baseEdits.push(<FormLabel style={styles.question} >Create Date: {new Date(this.props.provider.createDate).toLocaleDateString()} </FormLabel>);
            baseEdits.push(<div style={{ marginTop: '10px' }} />)
            baseEdits.push(<FormLabel style={styles.question} >Last updated: {new Date(this.props.provider.updatedAt).toLocaleDateString()} </FormLabel>);
        }
    }

    render() {
        const self = this;
        const { account, patient, provider } = self.props;
        let baseEdits = [];
        if (account) {
            this.addAccountInfo(baseEdits);
            if (patient)
                this.addPatientInfo(baseEdits);
            if (provider)
                this.addProviderInfo(baseEdits);

        }

        return (
            <div align="center" >
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                {baseEdits}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 200
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default UserDetails;
