import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';

import Config from '@apricityhealth/web-common-lib/Config';
import { axios } from '@apricityhealth/web-common-lib';
import {
    getProvider,
    getPatients,
    getPatient,
    getAccount,
    updateFeedback,
    deleteFeedback
} from '@apricityhealth/web-common-lib/utils/Services';
import UserDetails from './UserDetails';

class FeedbackItemView extends Component {

    constructor(props){
        super(props);
        this.state = {
            feedback: {}
        }
    }

    componentDidMount() {
        //console.log("componentWillMount:", this);
        if (this.props.feedback) {
            this.loadFeedback(this.props.feedback);
            this.getAccount();
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.feedback && oldProps.feedback._id &&
        this.props.feedback._id){
            if (oldProps.feedback._id !== this.props.feedback._id){
                this.loadFeedback(this.props.feedback);
                this.getAccount();
            }
        }
    }

    closeFeedback() {
        //console.log("closeAction()", this);
        this.props.onClose();
    }

    loadFeedback(feedback) {

        this.setState({
            feedback: feedback,
            attachment: null,
            progress: null,
        });

        if (feedback.attachment) {
            const getImage = {
                url: Config.baseUrl + `${Config.pathPrefix}images/${feedback.attachment}`,
                method: 'GET',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            this.setState({ progress: <CircularProgress /> });
            axios(getImage).then(response => {
                console.log("getImage response:", response);

                this.setState({
                    attachment: {
                        mimeType: response.headers['content-type'],
                        data: response.data
                    },
                    progress: null
                });
            }).catch(err => {
                console.log("getImage error:", err.message);
                this.setState({
                    attachment: { error: err.message },
                    progress: null
                });
            });
        }
    }
    getAccount() {
        const self = this;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ account: null, progress: <CircularProgress /> });
        getAccount(self.props.appContext, this.props.username).then((account) => {
            if (account) {
                if (account.groups.includes('patients')){
                    self.getPatientItems();
                    }else{
                        self.setState({patient:null})
                    }
                if (account.groups.includes('providers')){
                    self.getProvider();
                }else{
                    self.setState({provider:null})
                }
                self.setState({ account, progress: null });
            }
        }).catch((err) => {
            console.log("getAccount error:", err);
            self.setState({ progress: err.message });
        });

    }

    getPatientItems() {
        this.setState({ progress: <CircularProgress /> });
        getPatients(this.props.appContext, { userId: this.props.username} ).then((patients) => {
            if (patients.length > 0) {
                return getPatient(this.props.appContext, patients[0].patientId).then((patient)=> {
                    this.setState({ patient, progress: null });
                })
            }
        }).catch((err) => {
            console.log("getPatient error:", err);
            this.setState({ progress: err.message });
        });
    }


    getProvider() {
        const self = this;
        self.setState({ progress: <CircularProgress /> });
        getProvider(self.props.appContext, this.props.username).then((provider) => {
            self.setState({ provider, progress: null });
        }).catch((err) => {
            console.log("getProvider error:", err);
            self.setState({ progress: err.message });
        });
    }

    onChange = type => e => {
        let self = this;
        let { feedback } = this.state;
        feedback[type] = e.target.value;
        self.setState({ feedback, progress: <CircularProgress /> });
        updateFeedback(self.props.appContext, feedback).then((feedback) => {
            self.setState({ progress: null });
        }).catch((err) => {
            console.log("getProvider error:", err);
            self.setState({ progress: err.message });
        });

    }

    onDelete() {
        const self = this;
        console.log("onSignOut()", self);

        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    onClose={() => self.cancelSignOut()}
                >
                    <DialogTitle>Feedback Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete the feedback? This operation cannot be undone.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => {  this.setState({ dialog: null }) }} >Cancel</Button>,
                  <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.deleteFeedback(); }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    deleteFeedback() {
        let self = this;
        let { feedback } = this.state;
        self.setState({ progress: <CircularProgress /> });
        deleteFeedback(this.props.appContext, feedback).then((feedback) => {
            self.setState({ progress: null }, self.closeFeedback());
        }).catch((err) => {
            console.log("getProvider error:", err);
            self.setState({ progress: err.message });
        });

    }

    render() {
        const self = this;
        const { account, patient, provider, feedback, progress, dialog, attachment } = self.state;
        const { appContext } = this.props;
        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.closeFeedback()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Feedback Details</Typography>
            </Toolbar>
        </AppBar> : null;

        let image = <p></p>;

        if (attachment && attachment.error) {
            image = <p>{attachment.error}</p>;
        } else if (attachment && attachment.mimeType && attachment.data) {
            image = <img style={styles.imageAttachment} src={"data:" + attachment.mimeType + ";base64," + attachment.data} alt="" />
        }

        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <h4>Details:</h4>

                    <p>Subject: {feedback.subject}</p>
                    <p>Status:
                    <Select value={feedback.status}
                            onChange={self.onChange("status")}
                            style={{ marginLeft: '15px' }}
                        >
                            <MenuItem value={"open"}>Open</MenuItem>
                            <MenuItem value={"in-progress"}>In Progress</MenuItem>
                            <MenuItem value={"closed"}>Closed</MenuItem>

                        </Select>
                        <IconButton style={{ marginLeft: '15px' }} onClick={() => self.onDelete()}>
                            <Delete />
                        </IconButton>
                    </p>
                    <p>Time: {feedback.createDate}</p>
                    <p>User ID: {feedback.userId}</p>
                    <p>Feedback ID: {feedback._id}</p>
                    <p>Jira Link: <a href={feedback.url}>{feedback.url}</a></p>

                    <UserDetails appContext={appContext} account={account} patient={patient} provider={provider} />
                    <h4>Feedback:</h4>
                    <Paper style={{ margin: 5, width: 700 }}>
                        <table style={styles.table} border="1"><tbody>
                            <tr>
                                <td>{feedback.comment}</td>
                            </tr>
                        </tbody></table>
                    </Paper>
                    <h4>Image:</h4>
                    {image}

                </div>
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 200
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    imageAttachment: {
        maxWidth: "600px",
        maxHeight: "600px",
    }
}

export default FeedbackItemView;
