
import { useSelector } from 'react-redux';
const DEFAULT_LANG = "en-us";

function Text({ language, textId }) {
    if (!language) language = DEFAULT_LANG;

    const textContent = useSelector(state => state.app.textContent) //we need to listen to language changes to reload text

    if (typeof textId !== 'string') textId = String(textId);

    let text = textId;
    let start = textId.indexOf("[[");
    if (start >= 0) {
        let end = textId.indexOf("]]", start);
        if (end < 0) {
            throw new Error(`Failed to finding closing ']]' ${textId} `);
        }
        const length = end - start;
        textId = textId.substr(start + 2, length - 2);
    }

    if (textId && textContent && textContent[language]) {
        let textC = textContent[String(language)][String(textId)];
        if (textC) {
            text = textC.text;
        }
    }

    return (
        <span dangerouslySetInnerHTML={{ __html: text }} />
    )

}

export default Text;