import React from 'react';
import {
    IconButton,
    Paper,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
} from '@material-ui/core/';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";

import DeleteIcon from '@material-ui/icons/Delete';

import {
    getPatientOrgs,
    addPatientOrg,
    deletePatientOrg
} from '@apricityhealth/web-common-lib/utils/Services';

class PatientOrgsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.patientId,
            orgs: []
        }
    }

    componentDidMount() {
        if (this.state.patientId) {
            getPatientOrgs(this.props.appContext, this.state.patientId).then((orgs) => {
                this.setState({ orgs });
            }).catch((err) => {
                console.error("getPatientOrgs error:", err);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientId !== this.props.patientId) {
            this.setState({ patientId: this.props.patientId }, this.componentDidMount.bind(this));
        }
    }

    onAdd() {
        const { orgs } = this.state;
        orgs.push({ orgId: '' });
        this.setState({ orgs });
    }

    onDelete(i) {
        const { appContext } = this.props;
        const { patientId, orgs } = this.state;
        let deleteOrgId = orgs[i].orgId;
        console.log("onDelete:", deleteOrgId);

        orgs.splice(i, 1);
        this.setState({ orgs });
        deletePatientOrg(appContext, patientId, deleteOrgId).catch((err) => {
            console.error("onDelete error:", err);
        })
    }

    onChangeOrg(i, orgId) {
        const { appContext } = this.props;
        const { patientId, orgs } = this.state;
        let deleteOrgId = orgs[i].orgId;
        console.log("onChange:", deleteOrgId, "=>", orgId);
        if (deleteOrgId !== orgId) {
            orgs[i].orgId = orgId;
            this.setState({ orgs });

            Promise.all([
                orgId ? addPatientOrg(appContext, patientId, orgId) : Promise.resolve(),
                deleteOrgId ? deletePatientOrg(appContext, patientId, deleteOrgId) : Promise.resolve()
            ]).catch((err) => {
                console.error("onChange error:", err);
            });
        }
    }

   render() {
        const { appContext } = this.props;
        const { dialog, orgs } = this.state;

        let rows = [];
        for (let i = 0; i < orgs.length; ++i) {
            rows.push(
                <TableRow key={i}>
                    <TableCell>
                        <SelectOrg appContext={appContext} orgId={orgs[i].orgId} onChange={this.onChangeOrg.bind(this, i)} />
                    </TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="Delete"
                            onClick={this.onDelete.bind(this, i)} >
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        return (
            <div>
                {dialog}
                <Paper style={styles.sectionPaperContainer}>
                    <EnhancedTableToolbar
                        title='Organizations'
                        onAdd={this.onAdd.bind(this)}
                        onDelete={() => { }}
                        numSelected={0}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Organization</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
};

const styles = {
    sectionPaperContainer: {
        margin: 10
    }
}

export default PatientOrgsView;
