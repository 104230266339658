import React, { Component } from 'react';
import Moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core/';


import EditPatientPlanDialog from './EditPatientPlanDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import Plan from '@apricityhealth/web-common-lib/components/Plan';

class PatientPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            dialog: null
        }
    }

    componentDidMount(){
        this.setState({
            plans: this.props.plans || []
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.plans !== this.props.plans){
            this.setState({plans: this.props.plans})
        }
    }

    handleOnAdd = (e) => {
        const {plans} = this.state;
        const index = plans.length;
        plans.push( { planId: '', startDate: Moment().format('yyyy-MM-DD'), endDate: '' } );
        console.log("handleOnAdd:", e, plans );

        this.setState({ plans, dialog: <EditPatientPlanDialog plan={plans[index]}
            appContext={this.props.appContext}
            onClose={() => {
                plans.splice(index, 1);
                this.setState({plans, dialog: null});
            }}
            onDone={(plan) => {
                plans[index] = plan;
                this.setState({plans, dialog: null })
            }} />
        })
    }

    handleOnEdit = (index) => (e) => {
        const {plans} = this.state;
        if ( plans[index]) {
            this.setState({ dialog: <EditPatientPlanDialog plan={plans[index]}
                appContext={this.props.appContext}
                onClose={() => this.setState({dialog: null})}
                onDone={(plan) => {
                    plans[index] = plan;
                    this.setState({plans, dialog: null })
                }} />
            })
        }
    }

    handleOnDelete = (index) => (e) => {
        if ( e ) {
            e.stopPropagation();
            e.preventDefault();
        }

        const { plans } = this.state;
        plans.splice( index, 1);
        this.setState({ plans });
    }

    render(){
        const { plans, dialog } = this.state;
        const { appContext } = this.props

        let planRows = () => {
            let rows = []
            if (Array.isArray(plans) && plans.length > 0){
                plans.forEach( (planItem, index)=> {
                    let formattedEndDate = planItem.endDate ? Moment(planItem.endDate).format("MM-DD-YYYY") : '' 
                    let formattedStartDate = planItem.startDate ? Moment(planItem.startDate).format("MM-DD-YYYY") : '' 
                    
                    var row = (
                        <TableRow
                            hover
                            onClick={this.handleOnEdit(index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                {planItem.planId}
                            </TableCell>
                            <TableCell>
                                {formattedStartDate} 
                            </TableCell>
                            <TableCell>
                                {formattedEndDate} 
                            </TableCell>
                            <TableCell>
                                <Plan appContext={appContext} planId={planItem.planId} />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete" s 
                                    onClick={this.handleOnDelete(index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    rows.push(row)
                })
            }
            return rows 
        }
        
        return (
            <div>
                <Paper>
                <EnhancedTableToolbar
                    title='Plans'
                    onAdd={this.handleOnAdd.bind(this)}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>PlanId</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Plan Name</TableCell>
                            <TableCell>Plan Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planRows()}
                    </TableBody>
                </Table>
                {dialog}
                </Paper>
            </div>
        )
    }
}

export default PatientPlans;