import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { getMessages } from '@apricityhealth/web-common-lib/utils/Services';
import JSONView from '../JSONView';

export class PatientMessagesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
            typeHash: {},
            dialog: null,
            progress: null,
            filter: '',
            filtered: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.providerId !== prevProps.providerId)
            this.loadContent();
    }

    loadContent() {
        const self = this;
        let { appContext, providerId } = this.props;
        if (providerId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            getMessages(appContext, { providerId }).then((data) => {
                let messages = data.messages;
                self.setState({ messages, progress: null });
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext } = self.props;
        let { messages } = this.state;
        let data = messages[s[0]];
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <JSONView appContext={appContext} data={data} onClose={() => self.closeDialog()} />
        </Drawer>;
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        let self = this;
        const { dialog, progress, error, messages } = this.state;
        const dataColumns = [
            { id: 'createDate', numeric: false, editType: 'dateLabel', width: 200, disabledPadding: false, label: 'Date' },
            { id: 'message', numeric: false, disabledPadding: false, label: 'Message' },
        ];
        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {error}
            </td><td align='right'>
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable
                    onTable={(table) => { self._types = table }}
                    onSelected={(s, t) => self.contentSelected(s, t)}
                    disableMultiSelect={true}
                    disableActions={true}
                    order='desc'
                    orderBy='startDate'
                    columnData={dataColumns}
                    data={messages}
                    rowsPerPage={25}
                    title='Messages' />
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientMessagesView;
