
import React from 'react'
import { useEffect } from 'react';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';

import useTypes from './useTypes';

let alertLevels = {};

export function getAlert(alertId) {
    return alertLevels[alertId];
}

function AlertLevel({ planId, alertId }) {
    let [{ error, data, getAlertLevels }] = useTypes();

    useEffect(() => {
        const alertLevel = alertLevels[alertId];
        if (!alertLevel) {
            getAlertLevels("", planId, [alertId])
        }
    }, [alertId])

    useEffect(() => {
        if (data && isArrayValid(data)) { //cache the org so we don't keep getting it
            alertLevels[alertId] = data[0];
        }
    }, [data])

    useEffect(() => {
        if (error) { //error on org so we stop trying.. they not be found
            alertLevels[alertId] = {};
        }
    }, [error])

    const alertLevel = alertLevels[alertId];

    return (
        alertLevel ? <span style={{ color: alertLevel.color }} >{alertLevel.name}</span> : alertId
    )

}

export default AlertLevel;