import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import {
    findByField,
    loadRecommendations,
    getPatientRecommendations,
} from '@apricityhealth/web-common-lib/utils/Services';

import RecommendationView from '../RecommendationView';

function testFilter(filter, recommendation) {
    if (filter === "" || filter === "*") return true;
    if (recommendation && filter) {
        filter = filter.toLowerCase();

        //type, category, name
        if (recommendation.type && recommendation.type.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (recommendation.groupId && recommendation.groupId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (recommendation.sessionId && recommendation.sessionId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (recommendation.category && recommendation.category.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (recommendation.name && recommendation.name.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientRecommendationsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            types: [],
            dialog: null,
            progress: null,
            filter: '',
            filtered: ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.planId !== prevProps.planId) || (this.props.patientId !== prevProps.patientId))
            this.loadContent();
        if (this.props.content !== prevProps.content)
            this.applyFilter();
    }

    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered }, this.updateTab.bind(this));
    }

    loadContent() {
        const self = this;
        let { appContext, patientId, planId } = this.props;
        if (patientId && planId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            let promises = [];
            promises.push(getPatientRecommendations(appContext, patientId));
            promises.push(loadRecommendations(appContext, { planId, recommendId: "*", dependencies: true }));
            Promise.all(promises).then((results) => {
                let content = results[0];
                let types = results[1];
                self.setState({ content, types, progress: null }, self.applyFilter.bind(self))
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    updateTab() {
        const self = this;
        let { types, filtered } = this.state;
        const dataColumns = [
            { id: 'createDate', numeric: false, width: 150, editType: 'dateLabel', disabledPadding: false, label: 'Date' },
            { id: 'recommendId', numeric: false, width: 100, disabledPadding: false, label: 'Id' },
            { id: 'type', numeric: false, width: 100, disabledPadding: false, label: 'Type' },
            { id: 'category', numeric: false, width: 100, disabledPadding: false, label: 'Category' },
            { id: 'groupId', numeric: false, disabledPadding: false, label: 'Group ID' },
            { id: 'sessionId', numeric: false, disabledPadding: false, label: 'Session ID' },
            {
                id: 'name', numeric: false, width: 200, disabledPadding: false, label: 'Name', formatValue: (v, r) => {

                    let recommendation = findByField(types, 'recommendId', r.recommendId);
                    if (recommendation) {
                        return recommendation.name.substring(0, 150);
                    } else {
                        return v
                    }
                }
            },
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='createDate'
            columnData={dataColumns}
            data={filtered}
            rowsPerPage={25}
            title='Recommendations' />
        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext } = self.props;
        let { filtered, types } = self.state;
        const data = filtered[s[0]];
        let recommendationType = findByField(types, 'recommendId', data.recommendId);
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <RecommendationView appContext={appContext} recommendation={data} recommendationType={recommendationType} onClose={() => self.closeDialog()} />
        </Drawer>;
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const self = this
        const { table, dialog, progress, error } = this.state;
        let { filter } = this.state;
        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
        </div>;

        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                {table}
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientRecommendationsView;
