import React from 'react';
import Axios from 'axios';

import { 
    Drawer, 
    CircularProgress, 
    TextField,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import RightView from './RightView';
import Config from '@apricityhealth/web-common-lib/Config';

class RightsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rights: [],
            filter: ''
        }
    }

    componentWillMount() {
        this.loadContent();
    }

    loadContent(){
        const self = this;
        const getRights = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/rights`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getRights:", getRights);
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Axios(getRights).then((response) => {
            console.log("getRights response:", response);
            self.setState({ rights: response.data, progress: null }, self.updateFilter.bind(self));
        }).catch((error) => {
            console.log("getRights error:", error);
            self.setState({ progress: null, error: error.message });
        });
    }

    updateFilter() {
        function testFilter(filter, right) {
            if (right) {
                filter = filter.toLowerCase();
                if (right.rightId && right.rightId.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (right.description && right.description.toLowerCase().indexOf(filter) >= 0)
                    return true;
            }
            return false;
        }
        
        let { rights, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i <  rights.length; ++i) {
                if (testFilter(filter, rights[i]))
                filtered.push(rights[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._rights)
            this._rights.setState({ selected: [] });  
    }

    createRight() {
        const { appContext } = this.props;
        let right = {
            rightId: '',
            description: ''
        };
        let dialog = <div className='drawer' ><Drawer variant="persistent" anchor="right" open={true} >
            <RightView appContext={appContext} right={right} onClose={this.onCloseDialog.bind(this)} />
        </Drawer> </div>;
        this.setState({ progress: null, dialog: dialog });
    }

    rightSelected(s, t) {
        console.log(`trial selected `,  s);
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const { filtered, rights } = this.state;
            const right = filtered ? filtered[s] : rights[s];
            var dialog = <div className='drawer' >
                <Drawer variant="persistent" anchor="right" open={true}>
                    <RightView appContext={appContext} right={right} onClose={this.onCloseDialog.bind(this)} />
                </Drawer>
            </div>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    render(){
        const self = this;
        const { error, rights, filtered, dialog, progress } = this.state;
        let { filter } = this.state;

        const columnData = [
            { id: 'rightId', numeric: false, disabledPadding: false, label: 'Right ID' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'defaults', label: 'Default Roles', formatValue: (v) => {
                if (Array.isArray(v) && v.length > 0) {
                    const access = {
                        "read": "(R)",
                        "write": "(W)",
                        "admin": "(A)"
                    }
                    return v.sort((a,b) => a.roleId.localeCompare(b.roleId)).map((e) => e.roleId + access[e.access]).join(', ');
                } else {
                    return 'None';
                }
            }}
        ];

        return <table style={{width: '100%'}}><tbody>
            <tr><td align='left'>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                </td><td align='right'>
                {error}
                <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
            </td></tr>
            <tr><td colSpan="2">
                <EnhancedTable
                    disableMultiSelect={true}
                    onTable={table => self._rights = table}
                    onSelected={(s, t) => self.rightSelected(s, t)}
                    onAdd={() => self.createRight()}
                    orderBy='rightId'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={filtered ? filtered : rights}
                    title='Rights' />
                {dialog}
            </td></tr>
        </tbody>
        </table>;
    }
}

export default RightsView;

