import React, { Component } from 'react';
import Axios from 'axios';
import EditPatientDelegateDialog from './EditPatientDelegateDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import Config from '@apricityhealth/web-common-lib/Config';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Dialog
} from '@material-ui/core/';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

class PatientDelegate extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            patientId: props.patientId,
            delegates: [],
            modalOpen: false,
            editIndex:0,
            create:false,
            progress: null,
            error: null
        }
    }

    componentDidMount(){
        this.getPatientDelegates()
    }

    saveDelegate(e){
        let { patientId } = this.state;
        var dataObject = {email: e.email}
        let savePatientDelegate = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/delegates`,
            method: 'POST',
            headers: { "Authorization": this.props.appContext.state.idToken },
            data:dataObject
        };
        this.setState({ progress: <CircularProgress />, error: null });
        Axios(savePatientDelegate).then((response) => {
            console.log("savePatientDelegate response:", response);
            this.setState({ dialog: null }, this.getPatientDelegates());
        }).catch((error) => {
            console.log("savePatientDelegate  error:", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientId !== this.props.patientId){
            this.setState({ delegates: [] ,patientId: this.props.patientId}, this.getPatientDelegates);
        }
    }

    getPatientDelegates() {
        let { patientId } = this.state;
        let getPatientDelegates = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/delegates`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        this.setState({ progress: <CircularProgress />, error: null });
        
        console.log("getPatientDelegates request:", getPatientDelegates);
        Axios(getPatientDelegates).then((response) => {
            console.log("getPatientDelegates response:", response.data);
            if (response.data.delegates )
                this.setState({delegates:response.data.delegates, progress:null})
        }).catch((error) => {
            console.log("getPatientDelegates error:", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    handleOnAdd = () => {
        let { delegates } = this.state;
        if (!delegates) delegates= [];

        const newDelegate = {};
        delegates.push( newDelegate );
        this.setState({delegates:delegates, create: true, editIndex: delegates.length-1, modalOpen:true})
    }


    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    deleteDelegate(evt, delIndex) {
        const { patientId, delegates } = this.state
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        if (delegates && delegates[delIndex] && delegates[delIndex].user){
            let deletePatientDelegate = {
                url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/delegates/${delegates[delIndex].user.username}`,
                method: 'DELETE',
                headers: { "Authorization": this.props.appContext.state.idToken },
            };

            this.setState({ progress: <CircularProgress />, error: null });
            Axios(deletePatientDelegate).then((response) => {
                console.log("deletePatientDelegate response:", response);
                this.setState({ progress: null }, this.getPatientDelegates());
            }).catch((error) => {
                console.log("deletePatientDelegate  error:", error);
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }


    render(){
        let { delegates, modalOpen, editIndex, create, progress, error } = this.state;
        let { appContext } = this.props

        let delegateRows = () => {
            var tableRows = []
            if (Array.isArray(delegates)){
                delegates.forEach( (delegateItem, index)=> {
                    if (delegateItem.user){
                        var row = (
                            <TableRow
                                hover
                                tabIndex={index}
                                key={index}
                            >
                                <TableCell>
                                    {delegateItem.user.username}
                                </TableCell>
                                <TableCell>
                                    {delegateItem.user.email}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="Delete" s 
                                        onClick={(evt) => this.deleteDelegate(evt, index)} >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                        tableRows.push(row)
                    }
                })
            }
            return tableRows 
        }
        let editCreateModal = ()=> {
            if (modalOpen){
                return (
                    <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.modalOpen}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Patient Delegate
                        </DialogTitle>
                        <DialogContent>
                            <EditPatientDelegateDialog 
                                delegate={delegates[editIndex]} 
                                appContext={appContext}
                                onCancel={this.handleClose.bind(this)}
                                onDone={this.saveDelegate.bind(this)}
                                create={create}
                            />
                        </DialogContent>
                        <DialogActions>
                        </DialogActions>
                    </Dialog>
                )
            }
        }
        
        return (
            <div>
                {editCreateModal()}
                <Paper>
                <EnhancedTableToolbar
                    title='Patient Delegates'
                    onAdd={this.handleOnAdd}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Patient Delegate</TableCell>
                            <TableCell>Patient Delegate Email</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {delegateRows()}
                    </TableBody>
                </Table>
                {progress}
                <span style={{color: 'red'}}>{error}</span>
                </Paper>
            </div>
        )
    }
}

export default PatientDelegate;