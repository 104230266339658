import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    IconButton,
    TextField,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import {
    getPatientEncounters,
} from '@apricityhealth/web-common-lib/utils/Services';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import DataTypeInstanceView from '../DataTypeInstanceView';

export class PatientEncountersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            key: '',
            value: '',
            dialog: null,
            progress: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const { appContext, patientId } = this.props;
        const { key, value } = this.state;

        if (patientId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });

            const opts = { ...(this.props.opts || {}) };
            if (key && value) {
                opts.key = key;
                opts.value = value;
            }

            getPatientEncounters(appContext, patientId, opts).then((content) => {
                this.setState({ content, progress: null }, this.updateTable.bind(this));
            }).catch((error) => {
                this.setState({ progress: null, error: error.message });
            });
        }
    }

    updateTable() {
        const { content } = this.state;
        const dataColumns = [
            { id: 'resourceId', label: 'Resource ID' },
            { id: 'start', label: 'Start' },
            { id: 'end', label: 'End' },
            { id: 'status', label: 'Status' },
        ];

        let table = <EnhancedTable
            onTable={(table) => { this._types = table }}
            onSelected={(s, t) => this.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='start'
            columnData={dataColumns}
            data={content}
            rowsPerPage={25}
            title='Patient Encounters' />

        this.setState({ table });
    }

    contentSelected(s, t) {
        const { appContext, patientId, planId } = this.props;
        const { content } = this.state;
        const data = content[s[0]];

        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} patientId={patientId} instance={data} planId={planId} dataId={data && data.dataId} onClose={() => this.closeDialog()} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        this.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress, error, key, value } = this.state;

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td>
                <TextField style={{margin: 5, width: 300}} value={key} label='Key' onChange={(e) => this.setState({ key: e.target.value})} />
                <TextField style={{margin: 5, width: 300}} value={value} label='Value' onChange={(e) => this.setState({ value: e.target.value})} />
                </td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {table}
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
}

export default PatientEncountersView;
