import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Store from '@apricityhealth/web-common-lib/reduxStore';
import AppProvider from '@apricityhealth/web-common-lib/AppProvider';
const store = Store();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <AppProvider>
                <App />
            </AppProvider>
        </BrowserRouter>,
    </Provider>,
    document.getElementById('root')
);
