import React, { Component } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    IconButton
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import SelectPatient from "@apricityhealth/web-common-lib/components/SelectPatient";
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import User from '@apricityhealth/web-common-lib/components/User';
import RefreshIcon from '@material-ui/icons/Refresh'
import XMLViewer from 'react-xml-viewer'
import moment from 'moment';

import {
    getBlob,
    getBlobs,
    deleteBlob
} from '@apricityhealth/web-common-lib/utils/Services';


const IMAGE_MIMES = [
    "image/png",
    "image/jpeg"
]

export class ImagesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: '*',
            images: [],
            totalImages: 0,
            dialog: null,
            progress: null
        }
    }

    componentWillMount() {
        console.log("componentWillMount:", this);
        this.loadImages();
    }

    loadImages() {
        let { patientId } = this.state;
        let { appContext } = this.props;

        if (patientId) {
            this.setState({ error: null, progress: <CircularProgress size={20} /> });
            getBlobs(appContext, [`patientId=${patientId}`, `limit=500`]).then(response => {
                console.log("getImages response:", response.data);
                this.setState({
                    images: response.data.images,
                    totalImages: response.data.totalImages,
                    progress: null
                });
            }).catch(err => {
                console.log("getImages error:", err.message);
                this.setState({
                    error: getErrorMessage(err),
                    progress: null
                });
            });
        }
    }

    confirmDelete(blobId) {
        let self = this;
        this.setState({
            dialog: <Dialog
                maxWidth='sm'
                fullWidth={true}
                model="true"
                open={true}

            >
                <DialogTitle>Delete Blob</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this blob?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => { self.deleteBlob(blobId) }}>Yes</Button>
                    <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>No</Button>
                </DialogActions>
            </Dialog>
        })
    }

    deleteBlob(blobId) {
        this.setState({ dialog: null, error: null, progress: <CircularProgress size={20} /> });
        if (blobId) {
            let { appContext } = this.props;
            deleteBlob(appContext, blobId).then(() => {
                this.onCloseDialog();
            }).catch(err => {
                console.log("delete blob", err.message);
                this.setState({
                    error: getErrorMessage(err),
                    progress: null
                });
            });
        }
        this.setState({
            progress: null
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadImages();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }


    imageSelected(s, t) {
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const image = self.state.images[s[0]];
            this.setState({ progress: <CircularProgress size={20} /> });
            getBlob(appContext, image.blobId).then(response => {

                const attachment = {
                    ...image,
                    blob: response
                };

                const dialog = <Dialog
                    maxWidth='md'
                    fullWidth={true}
                    model="true"
                    open={true}

                >
                    <DialogTitle>Blob</DialogTitle>
                    <DialogContent>
                        {(attachment.mimeType === "application/pdf" || attachment.mimeType === "pdf") && <iframe key={attachment._id} style={{ width: "100%", height: "600px" }} title="attachment" src={"data:application/pdf;base64," + attachment.blob.data} />}
                        {(attachment.mimeType === "application/xml" || attachment.mimeType === "xml") && <div style={{ width: "100%", height: "600px" }}><XMLViewer xml={Buffer.from(attachment.blob.data, "base64").toString("utf8")} /></div>}
                        {IMAGE_MIMES.includes(attachment.mimeType) && <img style={styles.imageAttachment} src={"data:" + attachment.mimeType + ";base64," + attachment.blob.data} alt="" />}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete(attachment.blobId) }}>Delete</Button>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Ok</Button>
                    </DialogActions>
                </Dialog>
                self.setState({ progress: null, dialog: dialog });
            }).catch(err => {
                console.log("getImage error:", err.message);
                this.setState({
                    error: err.message,
                    progress: null
                });
            });

        }
    }


    render() {
        const self = this;
        const { patientId, images, progress, error, dialog } = this.state;
        const { appContext } = this.props;

        const columnData = [
            {
                id: 'createDate',
                numeric: false,
                disabledPadding: false,
                label: 'Create Date',
                editType: 'dateLabel',
                formatDisplay: (v, row) => moment(row.createDate).format("LLL")

            },
            { id: 'mimeType', numeric: false, disabledPadding: false, label: "Mime Type" },
            { id: 'ownerId', numeric: false, disabledPadding: false, label: 'Patient', formatValue: (v) => <Patient appContext={appContext} patientId={v} /> },
            { id: 'ownerId', label: 'Owner', formatValue: (v) => <User appContext={appContext} userId={v} /> }

        ];

        return <>
            {dialog}
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            <SelectPatient enableAll={true}
                                style={styles.select}
                                appContext={appContext}
                                patientId={patientId}
                                onSelect={(patientId) => self.setState({ patientId }, () => self.loadImages())} />
                        </td>
                        <td align='right'>
                            <span style={{ color: 'red' }}>{error}</span>
                            {progress || <IconButton
                                style={styles.refresh}
                                onClick={() => { self.loadImages() }}>
                                <RefreshIcon />
                            </IconButton>}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <EnhancedTable
                                disableMultiSelect={true}
                                disableActions={true}
                                onTable={table => self._types = table}
                                onSelected={(s, t) => self.imageSelected(s, t)}
                                orderBy='createDate'
                                order='desc'
                                rowsPerPage={25}
                                columnData={columnData}
                                data={images}
                                title='Blobs' />

                        </td>
                    </tr>
                </tbody>
            </table>
        </>;
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5,
        width: 250
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    imageAttachment: {
        maxWidth: "600px",
        maxHeight: "600px",
    }
}

export default ImagesView;
