import React, { Component } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    FormControl,
    InputLabel
} from '@material-ui/core/';

class EditPatientPhoneDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: JSON.parse(JSON.stringify(props.phone))
        };
    }

    componentDidUpdate(oldProps){
        const oldNumbers = JSON.stringify(oldProps.phoneNumbers)
        const newNumbers = JSON.stringify(this.props.phoneNumbers)
        if (oldNumbers !== newNumbers){
            this.setState({phoneNumbers: JSON.parse(JSON.stringify(this.props.phoneNumbers)) });
        }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel()
    }

    onDone() {
        const { onDone } = this.props;
        if (onDone)
            onDone( this.state.phone );
    }

    render() {
        let { phone } = this.state;

        return <Dialog open={true} onClose={this.onDone.bind(this)}>
            <DialogTitle>Phone Details</DialogTitle>
            <DialogContent>
                <TextField
                    label="Phone Number"
                    style={{ width: 500, margin: 5 }}
                    value={phone.number}
                    onChange={(e) => { phone.number = e.target.value; this.setState({ phone }) }}
                />
                <FormControl style={{ margin: 5, width: 150 }}>
                    <InputLabel>Phone Type</InputLabel>
                    <Select
                        label="Phone Type"
                        value={phone.numberType}
                        placeholder="Phone Type"
                        onChange={(e) => { phone.numberType = e.target.value; this.setState({ phone }) }}
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <MenuItem value='mobile'>Mobile</MenuItem>
                        <MenuItem value='home'>Home</MenuItem>
                        <MenuItem value='work'>Work</MenuItem>
                        <MenuItem value='eFax'>eFax</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField
                        label='Note'
                        style={{ width: 500, margin: 5}}
                        value={phone.note}
                        onChange={(e) => { phone.note = e.target.value; this.setState({ phone }) }}
                />
        </DialogContent>
        <DialogActions>
        <Button variant="contained"  self={this} style={styles.button} onClick={this.onDone.bind(this)}>Ok</Button>
        <Button variant="contained"  self={this} style={styles.button} onClick={this.onCancel.bind(this)}>Cancel</Button>
        </DialogActions>
        </Dialog>;
    }
}

const styles = {
    button:{
        margin:5
    },
    div: {
        margin: 10
    }
}

export default EditPatientPhoneDialog;