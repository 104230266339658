import Config from '@apricityhealth/web-common-lib/Config';
import React from 'react';

import {
    Button,
    CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core/';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { generateCCDA, sendDirectMessage } from '@apricityhealth/web-common-lib/utils/Services';

import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import SelectGender from '@apricityhealth/web-common-lib/components/SelectGender';


const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;
const PRODUCTION = ['production', 'vcc']
const REFERRALS_ADDRESS = PRODUCTION.indexOf(Config.stage) < 0  ? `referrals-${STAGE}@apricity.direct.kno2fy-stage.com` :
    'referrals@apricity-health.direct.kno2fy.com';//production-uri
const RECORDS_ADDRESS = PRODUCTION.indexOf(Config.stage) < 0 ? `records-${STAGE}@apricity.direct.kno2fy-stage.com` :
    'records@apricity-health.direct.kno2fy.com';//production-uri


/**
 * @extends React.Component
 * Direct Message Composition Dialog
 * @memberof module:Admin
 * @alias SendDirectMessageView
 */
export class SendDirectMessageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            /**@type {('referral'|'progress')}*/
            type: 'referral',
            /**@type {('Referral'|'Progress')}*/
            subject: 'Referral',
            recipient: REFERRALS_ADDRESS,
            patientId: '',
            providerId: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            note: '',
            gender: 'male',
            dob: '1999/01/01',
            progress: null,
            postalCode: ""
        };
    }


    onTypeSelected(e) {
        console.log('onTypeSelected:', e);
        const { target: { value: type } } = e;

        switch (type) {
            case 'progress': this.setState(
                {
                    type,
                    subject: 'Progress',
                    recipient: RECORDS_ADDRESS
                });
                break;
            case 'referral': this.setState(
                {
                    type,
                    subject: 'Referral',
                    recipient: REFERRALS_ADDRESS
                });
                break;
            default:
                console.error('onTypeSelected Error: parameter `e.target.value`(type) is invalid');
                break;
        };
    }

    onSend() {
        const { state: { type } } = this;
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        if (type === 'referral') {
            const {
                props: { appContext },
                state: {
                    subject, recipient,
                    firstName, lastName,
                    email, phone,
                    gender, dob, note, postalCode
                }
            } = this;

            const emails = [];
            if (email) emails.push({ emailType: 'primary', email });
            const phoneNumbers = [];
            if (phone) phoneNumbers.push({ numberType: 'mobile', number: phone });

            let address = [{
                addressType: "primary",
                postalCode
                
            }]
            generateCCDA(appContext, {
                type,
                isStructured: true,
                patient: {
                    firstName, lastName,
                    emails, phoneNumbers,
                    gender, dob, address
                },
                data: {
                    text: note
                },
                body: 'Referral from Apricity Health, LLC.'
            }).then(ccda => {
                return sendDirectMessage(appContext, {
                    subject,
                    recipients: [recipient],
                    attachments: [{ file: ccda }]
                });
            }).then(() => {
                this.props.onDone();
            }).catch(err => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else if (type === 'progress') {
            const {
                props: { appContext },
                state: {
                    subject, recipient, note,
                    patientId, providerId
                }
            } = this;
            generateCCDA(appContext, {
                type,
                isStructured: false,
                patientId,
                providerId,
                data: {
                    html: '<html>' + note.replace(/(\r\n|\n|\r)/gm, '<br />') + '</html>',
                }
            }).then(ccda => {
                return sendDirectMessage(appContext, {
                    subject,
                    recipients: [recipient],
                    attachments: [{ file: ccda }],
                    body: note
                });
            }).then(() => {
                this.props.onDone();
            }).catch(err => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else this.setState({ progress: null, error: 'onSendError: invalid C-CDA Type' });
    }


    render() {
        const {
            props: { appContext },
            state: {
                subject, recipient, note,
                firstName, lastName,
                email, phone, gender,
                patientId, providerId,
                error, progress, type, postalCode
            }
        } = this;

        return <Dialog open>
            <DialogTitle>Send Direct Message</DialogTitle>
            <DialogContent>
                <Select style={{ margin: 5, width: 150 }} value={type} onChange={this.onTypeSelected.bind(this)}>
                    <MenuItem value='referral'>Referral</MenuItem>
                    <MenuItem value='progress'>Progress Note</MenuItem>
                </Select>
                <br />
                <TextField label='Recipient' value={recipient} style={{ margin: 5, width: 350 }}
                    onChange={e => this.setState({ recipient: e.target.value })} /><br />
                <TextField label='Subject' value={subject} style={styles.textfield}
                    onChange={e => this.setState({ subject: e.target.value })} /><br />
                {type === 'referral' ?// Referral Note <div>
                    <div>
                        <TextField label='First Name' value={firstName} style={styles.note}
                            onChange={e => this.setState({ firstName: e.target.value })} /><br />
                        <TextField label='Last Name' value={lastName} style={styles.note}
                            onChange={e => this.setState({ lastName: e.target.value })} /><br />
                        <TextField label='E-Mail' value={email} style={styles.note}
                            onChange={e => this.setState({ email: e.target.value })} /><br />
                        <TextField label='Phone' value={phone} style={styles.note}
                            onChange={e => this.setState({ phone: e.target.value })} /><br />
                        <TextField label='Postal Code' value={postalCode} style={styles.note}
                            onChange={e => this.setState({ postalCode: e.target.value })} /><br />
                        <SelectGender value={gender} onChange={e => this.setState({ gender: e })} />
                    </div>
                    :// Progress Note <div>
                    <div>
                        <SelectPatient appContext={appContext} patientId={patientId}
                            onChange={patientId => this.setState({ patientId })} /><br />
                        <SelectProvider appContext={appContext} providerId={providerId}
                            onChange={providerId => this.setState({ providerId })} /><br />
                        <TextField multiline label='Note' rows='4' style={styles.textfield} value={note}
                            onChange={e => this.setState({ note: e.target.value })} />
                    </div>}
            </DialogContent>
            <DialogActions>
                {progress}
                <span style={{ color: 'red' }}>{error}</span>
                <Button onClick={this.props.onDone}>Cancel</Button>
                <Button disabled={progress !== null} onClick={this.onSend.bind(this)}>Send</Button>
            </DialogActions>
        </Dialog>;
    }
};


const styles = {
    note: { margin: 5, width: 300 },
    textfield: { margin: 5, width: 400 }
};


export default SendDirectMessageView;