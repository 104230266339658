import { useCallback } from 'react'

import useData from '@apricityhealth/web-common-lib/hooks/useData';

const ENDPOINT = "types"

const ALERT_LEVEL_ENDPOINT = "alert_levels"
const MODELS_ENDPOINT = "models"

/**
 * useReport - Hook to get orgs.
 * Note: if the orgId is passed in as a 'param' then the getOrg endpoint will be called, otherwise getOrgs is called.
 */
function useTypes(defaultValue) {
    const [data, { loading, error, clearData, fetchData }] = useData(ENDPOINT, defaultValue);

    const getAlertLevels = useCallback(async (suffix, planId, params, args, opts) => {
        let endpoint = `/${planId}/${ALERT_LEVEL_ENDPOINT}`;
        if (suffix) endpoint += `/${suffix}`;
        return await fetchData(endpoint, params, args, opts)
    }, [data, loading, error, clearData, fetchData])

    const getModels = useCallback(async (suffix, planId, params, args, opts) => {
        let endpoint = `/${planId}/${MODELS_ENDPOINT}`;
        if (suffix) endpoint += `/${suffix}`;
        return await fetchData(endpoint, params, args, opts)
    }, [data, loading, error, clearData, fetchData])


    return [{ data, loading, error, clearData, getAlertLevels, getModels }]
}

export default useTypes
