import {
    useTheme,
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Paper,
    IconButton
} from '@material-ui/core/';
import PropTypes from 'prop-types';

import NavigationClose from '@material-ui/icons/Close';
import styles from './MetricsPage.styles'
import ReactJson from 'react-json-view';

Metric.propTypes = {
    classes: PropTypes.object.isRequired,
    metric: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};


function Metric({ classes, metric, onClose }) {
    const theme = useTheme();

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" >Metric Details</Typography>
                </Toolbar>
            </AppBar>

            <Paper key="2" className={classes.jsonPaper}>
                <ReactJson
                    theme={theme.overrides?.ReactJsonView}
                    src={metric}
                    className={classes.json}
                    collapsed={3}
                    name="metric"
                    collapseStringsAfterLength={64}
                    displayDataTypes={false} />
            </Paper>
        </>
    )


}
export default withStyles(styles)(Metric)