import React, { Component } from 'react';
import {
    AppBar, Toolbar, Typography, Button, CircularProgress, TextField, 
    IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tabs, Tab,
    Tooltip,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code'
import CreateIcon from '@material-ui/icons/Create';
import ReactJson from 'react-json-view';

import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import Plan from '@apricityhealth/web-common-lib/components/Plan';

class PatientModelView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            model: null,
            firstName: 'TestModel',
            lastName: 'Patient',
            dob: '2000-01-01',
            count: 100
        }
    }

    componentWillMount() {
        this.onLoadModel(this.props.model);
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.model !== this.props.model) {
            this.onLoadModel(this.props.model);
        }
    }   

    onClose() {
        this.props.onClose();
    }

    onLoadModel(model) {
        if (!model) model = {};
        if (model.count === undefined) model.count = 100;
        if (model.disabled === undefined) model.disabled = false;
        if (!model.modelId) model.modelId = '';
        if (!model.patientId) model.patientId = '';
        if (!model.patient) model.patient = { firstName: 'TestModel', lastName: 'Patient', dob: '2000-01-01' };
        if (!model.description) model.description = '';
        if (!model.data) model.data = [];
        if (!model.conversation) model.conversation = [];

        console.log("onLoadModel:", model );
        this.setState({
            firstName: model.patient.firstName || 'TestModel',
            lastName: model.patient.lastName || 'Patient',
            dob: model.patient.dob || '2000-01-01',
            count: model.count || 100,
            model,
            progress: null,
            dialog: null
        });
    }

    onSaveModel() {
        const { appContext } = this.props;
        const { model } = this.state;

        const saveModel = {
            url: Config.baseUrl + `${Config.pathPrefix}test/models`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: model
        };

        console.log("saveModel:", saveModel);

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Axios(saveModel).then((response) => {
            console.log("saveModel response:", response.data);
            this.setState({ model: response.data, progress: null });
        }).catch((er) => {
            console.log("saveTest error:", er);
            this.setState({ progress: null, error: getErrorMessage(er) });
        });
    }

    onDeleteModel() {
        const { model } = this.state;
        if ( model.modelId ) {
            this.setState({
                dialog: <div>
                    <Dialog
                        model="false"
                        open={true}>
                        <DialogTitle>Delete Model: {`${model.modelId}`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Are you sure you want to delete this model?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" style={styles.button} onClick={this.onCloseDialog.bind(this)}>Cancel</Button>,
                            <Button variant="contained" style={styles.button} onClick={this.onConfirmDeleteModel.bind(this)}>Confirm</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onConfirmDeleteModel() {
        const { appContext: { state: { idToken } } } = this.props;
        const { model } = this.state;

        if (model.modelId) {
            const deleteModel = {
                url: Config.baseUrl + `${Config.pathPrefix}test/models/${model.modelId}`,
                method: 'DELETE',
                headers: { "Authorization": idToken }
            };

            console.log("deleteModel:", deleteModel);

            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Axios(deleteModel).then((response) => {
                console.log("deleteModel response:", response.data);
                this.setState({ progress: null });
                this.onClose();
            }).catch((err) => {
                console.log("deleteTest error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
    }

    onCreatePatients() {
        const { appContext: { state: { idToken } } } = this.props;
        const { lastName, firstName, dob, count, model: { modelId } } = this.state;

        this.setState({dialog: <Dialog open={true}>
            <DialogTitle>Create Patients</DialogTitle>
            <DialogContent>
                <TextField value={firstName} label='First Name' style={{margin: 5, width: 300}} onChange={(e) => this.setState({firstName: e.target.value}, this.onCreatePatients.bind(this))} /><br />
                <TextField value={lastName} label='Last Name' style={{margin: 5, width: 300}} onChange={(e) => this.setState({lastName: e.target.value}, this.onCreatePatients.bind(this))} /><br />
                <TextField value={dob} label='DOB' style={{margin: 5, width: 300}} onChange={(e) => this.setState({dob: e.target.value}, this.onCreatePatients.bind(this))} /><br />
                <TextField value={count} label='# Patients' type='number' style={{margin: 5, width: 300}} onChange={(e) => this.setState({count: e.target.value}, this.onCreatePatients.bind(this))} /><br />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button onClick={() => {
                    const createModelPatient = {
                        url: Config.baseUrl + `${Config.pathPrefix}test/create/model?async=true`,
                        method: 'POST',
                        headers: { "Authorization": idToken },
                        data: {
                            modelId,
                            firstName,
                            lastName,
                            dob,
                            count
                        }
                    };
            
                    console.log("createModelPatient:", createModelPatient);
                    this.setState({ progress: <CircularProgress size={20} />, error: null });
                    Axios(createModelPatient).then((response) => {
                        console.log("createModelPatient response:", response.data);
                        this.setState({ progress: null, dialog: null });
                    }).catch((er) => {
                        console.log("createModelPatient error:", er);
                        this.setState({ progress: null, dialog: null, error: getErrorMessage(er) });
                    });
            
                }} >Create</Button>
            </DialogActions>
            </Dialog>});
    }

    onShowModel() {
        const { model } = this.state;
        this.setState({dialog: <Dialog open={true} maxWidth='lg' fullWidth={true}>
            <DialogContent>
                <ReactJson src={model}
                    collapsed={3} name="Patient Model" collapseStringsAfterLength={64} displayDataTypes={false} />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => this.setState({dialog: null})}>Close</Button>
            </DialogActions>
            </Dialog>});
    }

    render() {
        const { appContext } = this.props;
        const { model, error, dialog, progress, tab } = this.state;

        if (! model ) {
            return null;
        }

        const appBar = this.props.onClose ?
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={this.props.onClose}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">Test Template</Typography>
                </Toolbar>
            </AppBar> : null;

        const dataColumns = [
            { id: '_index', numeric: true, label: 'Order' },
            { id: 'dataId', label: 'Data ID'},
            { id: 'startDay', numeric: true, label: 'Start Day'},
            { id: 'startDay', numeric: true, label: 'End Day', formatValue: (v,r) => {
                return Number(v) + Math.max(...(r.valuesX || [0]));
            }},
            { id: 'interval', numeric: true, label: 'Interval' },
            { id: 'probability', numeric: true, label: 'Probability', editType: 'number' }
        ];
        const conversationColumns = [
            { id: '_index', numeric: true, label: 'Order' },
            { id: 'modelId', numeric: true, label: 'Model ID' },
            { id: 'startDay', numeric: true, label: 'Start Day'},
            { id: 'startDay', numeric: true, label: 'End Day', formatValue: (v,r) => {
                return Number(v) + Math.max(...(r.gradesX || [0]));
            }},
            { id: 'interval', numeric: true, label: 'Interval' },
            { id: 'probability', numeric: true, label: 'Probability', editType: 'number' },
            { id: 'journal', numeric: true, label: 'Journal Length', formatValue: (v) => v.length }
        ];


        let tabs = {
            "Data": <div id='Data' key={0}>
                <EnhancedTable
                    order='asc'
                    orderBy='_index'
                    columnData={dataColumns}
                    data={model.data}
                    rowsPerPage={10}
                    title='Data' />
            </div>,
            "Conversation": <div id='Conversation' key={1}>
                <EnhancedTable
                    order='asc'
                    orderBy='_index'
                    columnData={conversationColumns}
                    data={model.conversation}
                    rowsPerPage={10}
                    title='Conversation' />
            </div>
        };

        const view =
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <p style={{ margin: 5 }}>Model ID: {model.modelId}</p>
                        </td>
                        <td align="right" valign="top">
                            <span style={{color: 'red'}}>{error}</span>
                            <Tooltip title='Save'><IconButton disabled={progress !== null} onClick={this.onSaveModel.bind(this)}>{progress ? progress : <SaveIcon />}</IconButton></Tooltip>
                            <Tooltip title='Create Patients'><IconButton onClick={this.onCreatePatients.bind(this)}><CreateIcon /></IconButton></Tooltip>
                            <Tooltip title="Show JSON"><IconButton onClick={this.onShowModel.bind(this)}><CodeIcon /></IconButton></Tooltip>
                            <Tooltip title='Delete'><IconButton disabled={progress !== null} onClick={this.onDeleteModel.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <span style={styles.description}>Created From Patient ID: {model.patientId}</span><br />
                            <Tooltip title='How many patients to create from this model?'><TextField style={styles.text} value={model.count} label="# Patients" type='number'
                                onChange={(e) => { model.count = e.target.value; this.setState({ model, count: e.target.value }); }} /></Tooltip>
                            <TextField style={styles.text} value={model.patient.firstName} label="First Name"
                                onChange={(e) => { model.patient.firstName = e.target.value; this.setState({ model, firstName: e.target.value }); }} />
                            <TextField style={styles.text} value={model.patient.lastName} label="Last Name"
                                onChange={(e) => { model.patient.lastName = e.target.value; this.setState({ model, lastName: e.target.value }); }} />
                            <TextField style={styles.text} value={model.patient.dob} label="DOB"
                                onChange={(e) => { model.patient.dob = e.target.value; this.setState({ model, dob: e.target.value }); }} />
                            <br />
                            <FormControlLabel style={{margin: 5}} label='Disabled' control={<Checkbox checked={model.disabled} onChange={(e,v) => { model.disabled = v; this.setState({model }) }} />} />
                            <br />
                            <TextField style={styles.description} value={model.description} label="Description"
                                onChange={(e) => { model.description = e.target.value; this.setState({ model }); }} />
                            <br />
                            <br />
                            <b style={styles.description}>Plans:</b>
                            {model.patient.plans.map((p) => {
                                return <Plan appContext={appContext} planId={p.planId} />
                            })}
                            <br />
                            <Tabs value={tab} onChange={(e, newValue) => this.setState({tab: newValue}) }>
                                {Object.keys(tabs).map((t) => <Tab label={t} />)}
                            </Tabs>
                            {tabs[Object.keys(tabs)[tab]]}
                        </td>
                    </tr>
                </tbody>
            </table>;

        return (
            <div style={{ width: 1400 }}>
                {appBar}
                {view}
                {dialog}
            </div>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: '80%'
    },
    table: {
        margin: 5,
        width: '100%'
    },
    select: {
        margin: 5,
        width: '50%'
    },
    button: {
        margin: 5
    },
    div: {
        margin: 5
    },
    panel: {
        margin: 5
    },
    tab: {
        "backgroundColor": "#FFCC80",
        "width": "100%"
    }
}

export default PatientModelView;
