import React, { Component } from 'react';
import {
    TextField,
    CircularProgress,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import Moment from 'moment';
import Sequential from 'promise-sequential';

import {
    getPatientDataGroups, runPatientDetect, getPatientJournals
} from '@apricityhealth/web-common-lib/utils/Services';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';
import JSONDataDialog from '../../dialogs/JSONDataDialog';

import DetectIcon from '@material-ui/icons/TrackChanges';
import CodeIcon from '@material-ui/icons/Code';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function testFilter(filter, data) {
    if (filter === "" || filter === "*") return true;
    if (data && filter) {
        filter = filter.toLowerCase();
        if (data.sessionId && data.sessionId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.userId && data.userId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.groupId && data.groupId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (data.status && data.status.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientDataGroupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null,
            filter:'',
            filtered:'',
            requireJournal: true,
            triage: false
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.opts !== prevProps.opts))
            this.loadContent();
    }

    loadContent() {
        const self = this;
        let { appContext, patientId, opts } = this.props;
        if (patientId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            getPatientDataGroups(appContext, patientId, opts).then((content) => {
                self.setState({ content, progress: null}, self.applyFilter.bind(self))
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }
    
    applyFilter() {
        let { content, filter } = this.state;
        let filtered = [];
        for (let i = 0; i < content.length; ++i) {
            if (!testFilter(filter, content[i])) {
                continue;
            }
            filtered.push(content[i]);
        }
        this.setState({ filtered } );
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    onDeleteGroups(row) {
        console.log("onDeleteGroups:", row );
    }

    runDetectForJournal( group, journal ) {
        //console.log("runDetectForJournal:", group, journal );

        const { appContext } = this.props;
        const { triage } = this.state;
        const { groupId, patientId } = group;
        const { sessionId, createDate } = journal;
        this.setState({error: `Running detect on ${patientId}, group ${groupId}, session ${sessionId} @ ${createDate}...`});
        return runPatientDetect(appContext, patientId, { groupId, sessionId, triage, eventTime: createDate, recalculateModels: true } );
    }

    runDetectOnGroup( group ){
        const self = this;
        const { appContext } = this.props;
        const { requireJournal, triage } = this.state;
        const { groupId, patientId } = group;

        return new Promise((resolve, reject) => {
            if ( requireJournal ) {
                getPatientJournals(appContext, patientId, [`groupId=${groupId}`]).then((data) => {
                    let journals = data.records;
                    // need to execute journals oldest to newest, so sort by createDate
                    journals.sort((a,b) => {
                        return Moment(a.createDate) - Moment(b.createDate);
                    })
        
                    let promises = [];
                    for(let i=0;i<journals.length;++i) {
                        let journal = journals[i];
                        let eod = journal.journal.find((e) => e.eod === true && e.questionId !== 'bail');
                        if (! eod ) continue;       // if no eod flag as true, then it's an incompleted journal
                        promises.push( () => self.runDetectForJournal(group, journal));
                    }
        
                    return Sequential(promises);
                }).then(() => {
                    resolve();
                }).catch((err) => {
                    console.error("runDetect error:", err );
                    reject(err);
                })
            }
            else {
                const { eventTime, sessionId } = group;
                this.setState({error: `Running detect on ${patientId}, group ${groupId}, session ${sessionId} @ ${eventTime}...`});
                runPatientDetect(appContext, patientId, { groupId, sessionId, eventTime, triage, recalculateModels: true } ).then(resolve).catch(reject);
            }
        })
    }

    onRunDetect(table) {
        const self = this;
        const { selected, data } = table.state;

        let rows = [];
        if ( Array.isArray(selected) && selected.length > 0 ) {
            for(let i=0;i<selected.length;++i) {
                rows.push( data[selected[i]] );
            }
        }
        rows.sort((a,b) => {
            return Moment(a.eventTime) - Moment(b.eventTime);
        })

        console.log("onRunDetect:", rows );
        let promises = [];
        for(let i=0;i<rows.length;++i) {
            let row = rows[i];
            promises.push( () => self.runDetectOnGroup(row) );
        }

        this.setState({progress: <CircularProgress size={20} />, error: null});
        Sequential(promises).then(() => {
            self.loadContent();
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)} );
        })
    }

    onViewDataControl(table, value, row, index, id) {
        const self = this;
        let { appContext } = this.props;
        return <Tooltip title='View Data'><IconButton onClick={(e) => {
            table.preventClickThrough(e);
            self.setState({
                dialog: <JSONDataDialog
                    appContext={appContext}
                    dataType={row}
                    onDone={() => {
                        self.setState({ dialog: null });
                    }}
                />
            });
        }}><CodeIcon /></IconButton></Tooltip>;
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { dialog, progress, error, requireJournal, triage } = this.state;
        let { filter, filtered } = this.state;

        const dataColumns = [
            { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Event Time' },
            { id: 'groupId', numeric: false, disabledPadding: false, label: 'Group ID' },
            { id: 'sessionId', numeric: false, disabledPadding: false, label: 'Session ID' },
            { id: 'userId', numeric: false, disabledPadding: false, label: 'User ID', 
                formatValue: (v) => <User appContext={appContext} userId={v} userIdHidden={true} /> },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'details', editControl: this.onViewDataControl.bind(this), label: "Details" }
        ];

        return <table style={{width: '100%'}}><tbody>
            <tr><td>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)) }} />
                <FormControlLabel control={<Checkbox checked={triage} onChange={(e) => self.setState({triage: e.target.checked})} />} label="Triage" />
                <FormControlLabel control={<Checkbox checked={requireJournal} onChange={(e) => self.setState({requireJournal: e.target.checked})} />} label="Require Journal" />
            </td><td align='right'>
                {error}
                <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
            </td></tr>
            <tr><td colSpan={2}>
            <EnhancedTable
                onTable={(table) => { self._types = table }}
                onDelete={this.onDeleteGroups.bind(this)}
                disableAdd={true}
                disableDelete={true}
                onActions={(table, numSelected, actions) => {
                    console.log("onActions:", table, actions, numSelected );
                    if ( numSelected > 0 ) {
                        actions.unshift( <Tooltip key='detect' title='Run Detect'>
                            <IconButton onClick={this.onRunDetect.bind(this,table)}>
                                <DetectIcon />
                            </IconButton>
                        </Tooltip>);
                    }
                }}
                order='desc'
                orderBy='eventTime'
                columnData={dataColumns}
                data={filtered}
                rowsPerPage={25}
                title='Patient Data' />
            {dialog}
        </td></tr>
        </tbody></table>;
    }
}

export default PatientDataGroupView;
