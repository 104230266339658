import React, { Component } from 'react';

import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    MenuItem,
    Select,
} from '@material-ui/core/';

class OverrideDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            override: JSON.parse(JSON.stringify(props.override))
        };
        if (!this.state.override.value)
            this.state.override.value = 'permitted';
        console.log("OverrideDialog:", this.state.override);
    }

    onClose() {
        if (this.props.onClose)
            this.props.onClose();
    }

    onDone() {
        let { override } = this.state;
        console.log("OnDone:", override);
        if (this.props.onDone)
            this.props.onDone(override);
    }
    render() {
        const self = this;
        let { override } = self.state;

        return (
            <Dialog open={true} onClose={() => this.onClose()}>
                <DialogContent>
                    <DialogContent>
                        <Select style={styles.select} value={override.value} onChange={(e) => {
                            override.value = e.target.value; self.setState({ override });
                        }}>
                            <MenuItem value='permitted'>Permitted</MenuItem>
                            <MenuItem value='notPermitted'>Not Permitted</MenuItem>
                        </Select>
                        <br />
                        <TextField
                            label="Duration"
                            type='number'
                            style={styles.duration}
                            value={override.duration}
                            onChange={(e) => { override.duration = e.target.value; self.setState({ override }) }}
                        />
                        <br />
                        <TextField
                            label="Reason"
                            style={styles.reason}
                            value={override.reason}
                            onChange={(e) => { override.reason = e.target.value; self.setState({ override }) }}
                        />
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => this.onDone()}>OK</Button>
                    <Button variant="contained" onClick={() => this.onClose()}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    duration: {
        margin: 5,
        width: 100
    },
    reason: {
        margin: 5,
        width: 400
    },
    select: {
        margin: 5,
        width: 300
    }
}

export default OverrideDialog;
