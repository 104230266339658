import React, { Component } from 'react';
import Axios from 'axios';
import { 
    TextField,
    AppBar, 
    Button,
    Toolbar, 
    CircularProgress,
    Typography, 
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    IconButton 
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import Config from '@apricityhealth/web-common-lib/Config';
import MultiSelectPlan from '@apricityhealth/web-common-lib/components/MultiSelectPlan';

class RightView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog:null,
            progress: null,
            right: props.right
        } 
    }

    componentDidUpdate(oldProps) {
        if (this.props.right !== oldProps.right)
            this.setState({right: this.props.right});
    }
    
    closeView() {
        this.props.onClose();
    }

    deleteRight() { 
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Right: {`${this.state.right.rightId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.cancelDelete.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDelete() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        const { right } = self.state;

        self.setState({ dialog: null });
        if (right.rightId) {
            const deleteRight = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/rights/${right.rightId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress />, error: null });
            console.log("deleteRight:", deleteRight);
            Axios(deleteRight).then(function (response) {
                console.log("deleteRight response:", response);
                self.setState({ progress: null });
                self.closeView();
            }).catch(function (error) {
                console.log("deleteRight error:", error.response);
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    saveRight() {
        const self = this;
        let { right } = self.state;
        right.roleIds = (right.roleIds || []).filter((e) => e);

        const saveRight = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/rights`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: right 
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("saveRight request:", saveRight);
        Axios(saveRight).then(function (response) {
            console.log("saveRight response:", response);
            self.setState({right: response.data, progress: null });
            self.closeView();
        }).catch((error) => {
            console.log("saveRight error:", error);
            self.setState({ progress: null, error: error.message });
        });
    }

    render() {
        const { dialog, right, progress } = this.state;
        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => this.closeView()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Right Details</Typography>
            </Toolbar>
        </AppBar> : null;

        const columnData = [
            { id: 'roleId', label: 'Role ID', editType: 'text' },
            { id: 'access', label: 'Access', editType: 'select', selectValues: ['read', 'write', 'admin'], onAdd: () => 'read' },
            { id: 'planIds', label: 'Plans', onAdd: () => ['*'], editControl: (t,v,r,i) => {
                return <div onClick={t.handleClickEdit.bind(t)}><MultiSelectPlan 
                    appContext={this.props.appContext} 
                    noLabel={true}
                    value={v} 
                    onChange={(planIds) => {
                        t.setDataField(i, 'planIds', planIds);
                    }} 
                    /></div>
            }}
        ];

        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <div style={styles.rightHeader}>
                        <div>Right ID: {right.rightId}</div>
                        <div >
                            <IconButton onClick={this.saveRight.bind(this)}><SaveIcon /></IconButton>
                            <IconButton onClick={this.deleteRight.bind(this)}><DeleteIcon /></IconButton>
                        </div>
                    </div>
                    <br />   
                    <br />
                    <TextField style={{width: 500, margin: 5}} label="Right Id" value={right.rightId} onChange={(e) => {
                            right.rightId = e.target.value.trim().toLowerCase();
                            this.setState({right}); }} />
                     <br />
                    <TextField label="Description" style={{width: 500, margin: 5}} value={right.description}
                        onChange={(e) => {
                            right.description = e.target.value;
                            this.setState({right}); }} />
                    <br />
                    <EnhancedTable
                        orderBy='roleId'
                        rowsPerPage={10}
                        columnData={columnData}
                        data={right.defaults}
                        onDataChanged={(data) => {
                            if (Array.isArray(data)) {
                                for(let i=0;i<data.length;++i) {
                                    data[i].roleId = String(data[i].roleId).trim().toLowerCase();
                                }
                            }
                            right.defaults = data;
                            this.setState({right})
                        }}
                        title='Default Roles' />
                </div>
                <div align='center'>
                    {progress}
                    {dialog}
                </div>
            </div>
        );
    }
}

const styles = {
    rightHeader:{
        marginLeft:12,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    picker: {
        margin: 15,
        width: 150
    },
    appBar: {
        backgroundColor: "#FF9800"
    }
}

export default RightView ;
