import React from 'react';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import { IconButton, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

export class AdditionalTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: props.org
        }
    }

    render() {
        const { appContext, onChange } = this.props;
        const { org} = this.state;

        let addPatientTerms = [];
        if ( org.config['addPatientTerms'] ) {
            try {
                addPatientTerms = JSON.parse( org.config['addPatientTerms'] || "[]" )
            } catch(err) {
                console.error("addPatientTerms parse error:", err );
                addPatientTerms = [];
            }
        }

        const onUpdateOrg = (org) => {
            if ( addPatientTerms.length > 0) {
                org.config['addPatientTerms'] = JSON.stringify(addPatientTerms);
            } else {
                delete org.config['addPatientTerms'];
            }
            this.setState({org}, () => {
                if ( onChange ) onChange( org )
            })
        }
        
        return <div>
            <table>
                <tbody>
                {addPatientTerms.map((term, i) => {
                    return <tr>
                        <td valign='top'>
                            <IconButton style={{margin: 5}} onClick={() => {
                                addPatientTerms.splice(i, 1);
                                onUpdateOrg( org );
                            }}><DeleteIcon /></IconButton>
                        </td>
                        <td valign='top'>
                            <TextField style={{margin: 5, width: 200}} label='Term ID' value={term.termId} onChange={(e) => {
                                term.termId = e.target.value;
                                onUpdateOrg( org );
                            }} />
                            <TextField style={{margin: 5, width: 200}} label='Name' value={term.name} onChange={(e) => {
                                term.name = e.target.value;
                                onUpdateOrg( org );
                            }} />
                            <TextField style={{margin: 5, width: 100}} type='number' label='Version' value={term.version} onChange={(e) => {
                                term.version = e.target.value;
                                onUpdateOrg( org );
                            }} />
                            <SelectLanguage appContext={appContext} language={term.language} onChange={(language) => {
                                term.language = language;
                                onUpdateOrg( org ) 
                            }} />
                            <FormControlLabel 
                                control={<Checkbox checked={term.forcedView} onChange={(e,v) => {
                                    term.forcedView = v;
                                    onUpdateOrg( org );
                                }} />} label='Forced View' />
                        </td>
                        <td valign='top'>
                            <TextField variant='outlined' style={{margin: 5, width: 600}} multiline={true} minRows={5} maxRows={5} label='Text' value={term.text} onChange={(e) => {
                                term.text = e.target.value;
                                onUpdateOrg( org );
                            }} />
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            <br />
            <IconButton style={{ margin: 5 }} onClick={(e) => {
                addPatientTerms.push( { termId: '', name: '', text: '', language: 'en-us' } );
                onUpdateOrg( org );
            }}><AddIcon /></IconButton>
        </div>
    }
}