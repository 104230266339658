// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';

// Free Solid
import { fas } from '@fortawesome/free-solid-svg-icons';

// Pro Light
import { fal } from '@fortawesome/pro-light-svg-icons';

// Pro Regular
import { far } from '@fortawesome/pro-regular-svg-icons';

// Pro Solid
import { fas as fasPro } from '@fortawesome/pro-solid-svg-icons';

export default function loadIcons() {
    library.add(
        fas,
        fal,
        far,
        fasPro
    );
}
