
import React, { Component } from "react";
import {
    withStyles,
    TextField,
    Button,
} from '@material-ui/core/';

import SelectCountry from '@apricityhealth/web-common-lib/components/SelectCountry';

class EditAddressDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.address,
            create: props.create
        };
    }
    componentDidUpdate(oldProps) {
        const oldAddress = JSON.stringify(oldProps.address)
        const newAddress = JSON.stringify(this.props.address)
        if (oldAddress !== newAddress) {
            this.setState({ address: this.props.address, create: this.props.create });
        }
    }
    onCancel() {
        const { onCancel, onCancelDelete, create } = this.props;
        if (onCancelDelete && create)
            onCancelDelete();
        if (onCancel)
            onCancel()
    }

    onDone() {
        const { onDone } = this.props;
        if (onDone)
            onDone();
    }
    render() {
        let { address } = this.state;
        let self = this;
        return (
            <>
                <div >
                    <TextField
                        label="Name"
                        style={{ width: '100%', margin: '5px' }}
                        value={address.addressType}
                        onChange={(e) => { address.addressType = e.target.value; self.setState({ address }) }}
                    />
                    <TextField
                        label="Street 1"
                        style={{ width: '100%', margin: '5px' }}
                        value={address.street1}
                        onChange={(e) => { address.street1 = e.target.value; self.setState({ address }) }}
                    />
                    <TextField
                        label="Street 2"
                        style={{ width: '100%', margin: '5px' }}
                        value={address.street2}
                        onChange={(e) => { address.street2 = e.target.value; self.setState({ address }) }}
                    />
                    <TextField
                        label="City"
                        style={{ width: '100%', margin: '5px' }}
                        value={address.city}
                        onChange={(e) => { address.city = e.target.value; self.setState({ address }) }}
                    />
                    <TextField
                        label="State"
                        style={{ margin: '5px' }}
                        value={address.state}
                        onChange={(e) => { address.state = e.target.value; self.setState({ address }) }}
                    />
                    <TextField
                        label="Postal Code"
                        style={{ margin: '5px' }}
                        value={address.postalCode}
                        onChange={(e) => { address.postalCode = e.target.value; self.setState({ address }) }}
                    />
                    <SelectCountry style={styles.country}
                        value={address.country}
                        onChange={(e) => { address.country = e; self.setState({ address }); }}
                    />
                </div>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Ok</Button>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
            </>
        )
    }
}

const styles = {
    button: {
        margin: 5
    },
    div: {
        margin: 10
    }
}

export default withStyles(styles)(EditAddressDialog);