import React from 'react';
import {
    Drawer,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    AppBar,
    Toolbar,
    Typography
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh'
import ClearIcon from '@material-ui/icons/Clear';
import NavigationClose from '@material-ui/icons/Close';
import StopIcon from '@material-ui/icons/Stop';

import Axios from 'axios';

import Config from '@apricityhealth/web-common-lib/Config';
import LogView from './LogView';
import TreeView from '@rlyle1179/react-treeview';
import Moment from 'moment';

import User from '@apricityhealth/web-common-lib/components/User';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

require('../styles/logs.css');

export class LogsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            request: 0,
            progress: null,
            error: null,
            userId: this.props.userId || '',
            requestId: this.props.requestId || '',
            tag: this.props.tag || '',
            logLevel: this.props.logLevel || '0',
            search: '',
            limit: 1000,
            treeData: [],
            startTime: null,
            endTime: null
        }
        this.logs = [];
    }

    // componentDidUpdate(prevProps) {
    //     if ( prevProps.userId !== this.props.userId || prevProps.requestId !== this.props.requestId || prevProps.tag !== this.props.tag || prevProps.logLevel == this.props.logLevel ) {
    //         this.setState({
    //             userId: this.props.userId || '',
    //             requestId: this.props.requestId || '',
    //             tag: this.props.tag || '',
    //             logLevel: this.props.logLevel || ''
    //         }, this.loadLogs.bind(this));
    //     }
    // }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let newState = this.state;
        urlParams.forEach((v, k) => {
            if (k === 'requestId') {
                newState.requestId = v;
            } else if (k === 'userId') {
                newState.userId = v;
            }
        });
        this.setState(newState, () => this.loadLogs());
    }

    componentWillUnmount() {
        this.abortLoad();
    }

    getLevel(v) {
        const levels = {
            0: 'Debug',
            1: 'Info',
            2: 'Warning',
            3: 'Error',
            4: 'Critical'
        };
        return levels[v];
    }

    getTimeString(date) {
        return Moment(date).format('YYYY/MM/DD hh:mm:ss:SSS A');
    }

    abortLoad() {
        if (this.getLogs) {
            clearInterval(this.getLogs);
            this.getLogs = null;
        }
        if (this.getArchivedLogs) {
            clearInterval(this.getArchivedLogs);
            this.getArchivedLogs = null;
        }
        this.loadingLogs = false;
        this.loadingArchivedLogs = false;
    }

    loadLogs() {
        const { userId, startTime, endTime, limit, requestId, tag, search, logLevel, request } = this.state;
        const newRequest = request + 1;

        this.logs = [];
        this.abortLoad();

        let args = [];
        if (userId)
            args.push("userId=" + encodeURIComponent(userId));
        if (requestId)
            args.push("requestId=" + encodeURIComponent(requestId));
        if (tag)
            args.push("tag=" + encodeURIComponent(tag));
        if (search)
            args.push("search=" + encodeURIComponent(search));
        if (startTime)
            args.push("startTime=" + startTime.toISOString());
        if (endTime)
            args.push("endTime=" + endTime.toISOString());
        if (logLevel)
            args.push("logLevel=" + encodeURIComponent(logLevel));
        args.push("limit=" + limit);

        const getLogs = {
            url: Config.baseUrl + `${Config.pathPrefix}logging/logs?${args.join('&')}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log(`getLogs request ${newRequest}:`, getLogs);
        this.setState({ progress: <CircularProgress size={20} />, error: null, treeData: [], request: newRequest });
        Axios(getLogs).then((response) => {
            if (this.state.request !== newRequest) {
                console.warn(`getLogs request ${newRequest} cancelled...`);
                return false;           // current request is cancelled..
            }

            console.log("getLogs result:", response.data);
            const { logJobId } = response.data;
            const getLogsTimer = setInterval(() => {
                if (this.state.request !== newRequest) {
                    console.warn(`getLogs request ${newRequest} cancelled...`);
                    return false;       // current request is cancelled..
                }
                if (this.loadingLogs) {
                    return false;       // waiting for the current request to complete
                }
                this.loadingLogs = true;

                const getJob = {
                    url: Config.baseUrl + `${Config.pathPrefix}logging/job/${logJobId}`,
                    method: 'GET',
                    headers: { "Authorization": this.props.appContext.state.idToken }
                }

                console.log("getJob request:", getJob);
                Axios(getJob).then((response) => {
                    console.log("getJob result:", response.data);
                    if (this.state.request !== newRequest) {
                        console.warn(`getLogs request ${newRequest} cancelled...`);
                        return false;   // cancelled
                    }
                    const { status, result } = response.data;
                    if (status === 'error') {
                        throw new Error(result.error);
                    } else if (status === 'done') {
                        clearInterval(getLogsTimer);

                        this.logs = result.logs;
                        if (result.archiveJobId) {
                            const getArchivedLogs = setInterval(() => {
                                if (this.state.request !== newRequest) {
                                    console.warn(`getLogs request ${newRequest} cancelled...`);
                                    return false;
                                }
                                if (this.loadingArchivedLogs) {
                                    return false;        // still waiting on the previous request to complete or error
                                }
                                this.loadingArchivedLogs = true;

                                const getArchiveJob = {
                                    url: Config.baseUrl + `${Config.pathPrefix}logging/job/${result.archiveJobId}`,
                                    method: 'GET',
                                    headers: { "Authorization": this.props.appContext.state.idToken }
                                };

                                console.log("getArchiveJob request:", getArchiveJob);
                                Axios(getArchiveJob).then((response) => {
                                    if (this.state.request !== newRequest) {
                                        console.warn(`getLogs request ${newRequest} cancelled...`);
                                        return false;
                                    }
                                    console.log("getArchiveJob result:", response.data);
                                    const { status, result } = response.data;
                                    if (status === 'error') {
                                        throw new Error(result.error);
                                    } else if (status === 'done') {
                                        clearInterval(getArchivedLogs);

                                        let logs = result.logs;
                                        if (logs.length > 0) {
                                            for (let i = 0; i < logs.length; ++i) {
                                                logs[i]._id = `archive${i}`;
                                            }
                                            this.logs = this.logs.concat(logs);
                                            this.setState({ progress: null, error: result.logsTruncated ? 'Logs Truncated!' : null },
                                                this.createTreeData.bind(this));
                                        }
                                        else {
                                            // no logs from the achive, so just clear the rror 
                                            this.setState({ progress: null, error: null });
                                        }
                                    }
                                    this.loadingArchivedLogs = false;
                                }).catch((err) => {
                                    console.error("getArchiveJob error:", err);
                                    this.setState({ error: getErrorMessage(err), progress: null });
                                    clearInterval(getArchivedLogs);
                                    this.loadingArchivedLogs = false;
                                })
                            }, 1000);
                            this.getArchivedLogs = getArchivedLogs;
                            this.setState({ error: "Getting archived logs.." }, this.createTreeData.bind(this));
                        } else {    // if (result.archiveJobId) {
                            this.setState({ progress: null, error: result.logsTruncated ? 'Logs Truncated!' : null }, this.createTreeData.bind(this));
                        }
                    }
                    this.loadingLogs = false;
                }).catch((err) => {
                    console.error("getJob error:", err);
                    this.setState({ error: getErrorMessage(err), progress: null });
                    clearInterval(getLogsTimer);
                    this.loadingLogs = false;
                });
            }, 1000);
            this.getLogs = getLogsTimer;
        }).catch((err) => {
            console.log("getLogs error:", err);
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    createTreeData() {
        let logs = this.logs;
        let logTree = {};
        for (let i = 0; i < logs.length; ++i) {
            let log = logs[i];
            let userId = log.userId || 'anonymous';
            let requestId = log.requestId || 'NA';
            if (!logTree[userId])
                logTree[userId] = {};
            if (!logTree[userId][requestId])
                logTree[userId][requestId] = [];

            logTree[userId][requestId].push(log);
        }

        let treeData = [];
        for (let userId in logTree) {
            let requests = [];
            let logCount = 0;
            let overAllMaxLevel = 0;
            for (let requestId in logTree[userId]) {
                let requestLogs = logTree[userId][requestId];
                let createDate = this.getTimeString(requestLogs[0].createDate);
                let children = [];
                let maxLevel = 0;
                for (let i = 0; i < requestLogs.length; ++i) {
                    let log = requestLogs[i];
                    let logDate = this.getTimeString(log.createDate);
                    let logLevel = this.getLevel(log.level);
                    maxLevel = Math.max(maxLevel, log.level);
                    if (!Array.isArray(log.log))
                        log.log = [JSON.stringify(log.log)];
                    let logText = log.log.length > 0 ? log.log[0].length > 128 ? log.log[0].substring(0, 128) + "..." : log.log[0] : '';

                    if (logText.constructor === Object) {
                        if (logText.SourceContext)
                            logText = logText.SourceContext;
                        else
                            logText = "Complex Log"
                    }

                    let child = {
                        id: `${userId}:${requestId}:${i}`,
                        text: <div key={children.length}>{logDate}<span className={'log-' + logLevel}> [{logLevel}]</span> [{log.tag}]: {logText}</div>,
                        isLeaf: true,
                        log
                    };
                    children.push(child)
                }
                let text = <div>{createDate}, {<span>RequestID: </span>}{<span onClick={() => { this.setState({ requestId }) }}>
                    {requestId}</span>}, {requestLogs.length} logs <span className={'log-' + this.getLevel(maxLevel)}> [{this.getLevel(maxLevel)}]</span></div>
                requests.push({
                    id: `${userId}:${requestId}`,
                    text,
                    children
                });
                logCount += requestLogs.length;
                overAllMaxLevel = Math.max(maxLevel, overAllMaxLevel);
            }
            treeData.push({
                id: userId,
                text: <div onClick={() => { this.setState({ userId }) }}><User appContext={this.props.appContext} userId={userId} />,
                    {logCount} logs <span className={'log-' + this.getLevel(overAllMaxLevel)}>
                        [{this.getLevel(overAllMaxLevel)}]</span></div>,
                children: requests
            });
        }

        console.log("treeData:", treeData);
        this.setState({ treeData });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    logSelected(item, s, t) {
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const log = item.logs[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <LogView appContext={appContext} log={log} onClose={() => this.onCloseDialog()} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    onSelectTreeItem(item) {
        if (item.log) {
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <LogView appContext={this.props.appContext} log={item.log} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
    }

    render() {
        const { userId, startTime, endTime, requestId, search,
            logLevel, treeData, dialog, progress, error, limit } = this.state;

        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={this.props.onClose}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Logs</Typography>
                </Toolbar>
            </AppBar> : null;

        //console.log("render:", treeData );
        let startEndTimes = <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                style={styles.picker}
                ampm={false}
                label="Start Time"
                value={startTime}
                onChange={(date) => this.setState({ startTime: date })}
            />
            <DateTimePicker
                style={styles.picker}
                ampm={false}
                label="End Time"
                value={endTime}
                onChange={(date) => this.setState({ endTime: date })}
            />
        </MuiPickersUtilsProvider>

        return <div>
            {appBar}
            <table style={{ width: '99%' }}>
                <tbody>
                    <tr>
                        <td>
                            <TextField style={styles.limit} type='number' label="Limit" value={limit}
                                onChange={(e) => { this.setState({ limit: e.target.value }) }} />
                            {startEndTimes}
                            <TextField style={styles.text} label="User ID" value={userId}
                                onChange={(e) => { this.setState({ userId: e.target.value }) }} />
                            <TextField style={styles.text} label="Request ID" value={requestId}
                                onChange={(e) => { this.setState({ requestId: e.target.value }) }} />
                            {/* <TextField style={styles.text} label="Tag" value={tag}
                                onChange={(e) => { this.setState({ tag: e.target.value }) }} /> */}
                            <TextField style={styles.text} label="Search" value={search}
                                onChange={(e) => { this.setState({ search: e.target.value }) }} />
                            <FormControl style={styles.level}>
                                <InputLabel>Log Level</InputLabel>
                                <Select value={`${logLevel}`}
                                    onChange={(e) => { this.setState({ logLevel: e.target.value }) }}>
                                    <MenuItem value={'0'}>Debug (0)</MenuItem>
                                    <MenuItem value={'1'}>Info (1)</MenuItem>
                                    <MenuItem value={'2'}>Warning (2)</MenuItem>
                                    <MenuItem value={'3'}>Error (3)</MenuItem>
                                    <MenuItem value={'4'}>Critical (4)</MenuItem>
                                </Select>
                            </FormControl>
                        </td>
                        <td align='right'>
                            <span style={{ color: 'red' }}>{error}</span>
                            <IconButton onClick={() => {
                                this.setState({ userId: '', requestId: '', tag: '', search: '', logLevel: '0', startTime: null, endTime: null, request: this.state.request + 1, progress: null },
                                    this.abortLoad.bind(this))
                            }}><ClearIcon /></IconButton>
                            <IconButton disabled={progress === null} onClick={() => {
                                this.setState({ request: this.state.request + 1, progress: null, error: null }, this.abortLoad.bind(this))
                            }}><StopIcon /></IconButton>
                            <IconButton disabled={progress !== null} onClick={this.loadLogs.bind(this)}>
                                {progress ? progress : <RefreshIcon />}
                            </IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <TreeView
                                selectRow={true}
                                items={treeData}
                                onSelectItem={this.onSelectTreeItem.bind(this)}
                            />
                            {dialog}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5,
        width: 200
    },
    level: {
        margin: 5,
        width: 125
    },
    limit: {
        margin: 5,
        width: 60
    },
    picker: {
        margin: 5,
        width: 130
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default LogsView;
