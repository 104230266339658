import React, { useEffect, useState, useMemo } from 'react';
import Config from '@apricityhealth/web-common-lib/Config';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
    useTheme, withStyles, AppBar, Toolbar, Typography, IconButton, Table, TableBody,
    TableCell, TableHead, TableRow, TableContainer, Paper, ListItem, ListItemIcon, List,
    Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
    ClickAwayListener
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
    Close,
    Warning,
    Info,
    CloudDownload,
    Restore,
    Refresh,
    CheckCircle,
    Description,
    AssignmentReturned,
    ClearAll,
    HourglassEmpty
} from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';

import ReactJson from 'react-json-view';

import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import User from '@apricityhealth/web-common-lib/components/User';
import useAuthentication from '@apricityhealth/web-common-lib/hooks/useAuthentication';
import usePatients from '@apricityhealth/web-common-lib/hooks/usePatients';
import useProviders from '@apricityhealth/web-common-lib/hooks/useProviders';
import useLogging from '@apricityhealth/web-common-lib/hooks/useLogging';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils'
import styles from './DirectMessageAttachmentsPage.styles'
import moment from 'moment';
import {
    getPatient,
    getDeletedPatient
} from '@apricityhealth/web-common-lib/utils/Services';
import LogsView from '@apricityhealth/web-common-lib/views/LogsView';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import useMessaging from './useMessaging';
import useCleanseAgent from './useCleanseAgent';

const log = new Logger();
const fileDownload = require('js-file-download');
const TIMEZONE = (Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTZ');
const INVALID_EMAILS = [
    "none@none.com"
]

DirectMessageAttachment.propTypes = {
    classes: PropTypes.object.isRequired,
    attachment: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

function DirectMessageAttachment({ classes, attachment, onClose, appContext }) {
    const theme = useTheme();
    const [confirmReprocess, setConfirmReprocess] = useState(false);
    const [confirmClearAttach, setConfirmClearAttach] = useState(false);
    const [directMessageAttachment, setDMA] = useState(null);
    const [directMessage, setDM] = useState(null);
    const [hgPatient, setHGPatient] = useState(null);
    const [deletedPatient, setDeletedPatient] = useState(null);
    const [requestId, setRequestId] = useState(null);

    let [{ data: directMessageAttachments, loading: getDirectMessageAttachmentsLoading, getDirectMessageAttachments, error: getDirectMessageAttachmentsError }] = useMessaging();
    let [{ data: directMessages, loading: getDirectMessagesLoading, getDirectMessages, error: getDirectMessagesError }] = useMessaging();
    let [{ data: updateDirectMessageAttachmentData, loading: updateDirectMessageAttachmentLoading, error: updateDirectMessageAttachmentError }] = useMessaging();
    let [{ data: providers, clearData: clearProviders, loading: providersLoading, fetchProviders, error: providersError }] = useProviders();
    let [{ data: patientAccount, clearData: clearPatientAccount, loading: patientAccountLoading, getAccount: getPatientAccount }] = useAuthentication();
    let [{ data: providerAccount, clearData: clearProviderAccount, loading: providerAccountLoading, getAccount: getProviderAccount }] = useAuthentication();
    let [{ data: patients, clearData: clearPatients, loading: patientsLoading, fetchPatients, error: patientsError }] = usePatients();
    let [{ data: consentedBy, clearData: clearConsentedBy, loading: consentedByLoading, fetchProviders: fetchConsentedBy, error: consentedByError }] = useProviders();
    let [{ data: loginSuccessMetrics, clearData: clearLoginSuccessMetrics, loading: successMetricsLoading, fetchMetrics: fetchLoginSuccessMetrics, error: successMetricsError }] = useLogging(true, []);
    let [{ data: loginFailMetrics, clearData: clearLoginFailMetrics, loading: failMetricsLoading, fetchMetrics: fetchLoginFailMetrics, error: failMetricsError }] = useLogging(true, []);
    let [{ loading: processAttachmentLoading, processAttachment, error: processAttachmentError }] = useCleanseAgent();
    let [{ data: bbAttachment, clearData: clearAttachmentData, loading: parseAttachmentLoading, parseAttachment, error: parseAttachmentError }] = useCleanseAgent();

    let loading = patientAccountLoading || providerAccountLoading || getDirectMessageAttachmentsLoading || parseAttachmentLoading || getDirectMessagesLoading || providersLoading || patientsLoading || consentedByLoading || successMetricsLoading || failMetricsLoading || updateDirectMessageAttachmentLoading || processAttachmentLoading;

    let error = useMemo(() => {
        let err = [];
        if (getDirectMessageAttachmentsError) err.push(getDirectMessageAttachmentsError);
        if (getDirectMessagesError) err.push(getDirectMessagesError);
        if (patientsError) err.push(patientsError);
        if (providersError) err.push(providersError);
        if (consentedByError) err.push(consentedByError);
        if (successMetricsError) err.push(successMetricsError);
        if (failMetricsError) err.push(failMetricsError);
        if (updateDirectMessageAttachmentError) err.push(updateDirectMessageAttachmentError);
        if (processAttachmentError) err.push(processAttachmentError);
        if (parseAttachmentError) err.push(parseAttachmentError);
        return err;
    }, [getDirectMessageAttachmentsError, patientsError, providersError, consentedByError, successMetricsError, failMetricsError])

    useEffect(() => {
        return () => {
            setDM(null);
            setDMA(null);
            setDeletedPatient(null)
            clearProviders();
            clearPatients();
            clearPatientAccount();
            clearProviderAccount();
            clearConsentedBy();
            clearLoginSuccessMetrics();
            clearLoginFailMetrics();
            clearAttachmentData();
            setHGPatient(null);
        }
    }, [])


    useEffect(() => {
        internalRefresh();
    }, [attachment])

    // useEffect(() => {
    //     clearPatients();
    //     if (attachment && attachment.patientId) {
    //         let patientId = attachment.patientId;
    //         fetchPatients([patientId], null, { limit: 1 });
    //     }
    // }, [attachment])


    async function internalRefresh() {
        if (attachment) {
            let { attachmentId, messageId, patientId, emrId } = attachment
            clearProviders();
            clearPatients();
            clearPatientAccount();
            clearProviderAccount();
            clearConsentedBy();
            clearLoginSuccessMetrics();
            clearLoginFailMetrics();
            clearAttachmentData();
            setDMA(null)
            setDM(null);
            setHGPatient(null);
            setDeletedPatient(null)
            if (attachmentId && messageId) {
                let args = [`messageId=${messageId}`, `attachmentId=${attachmentId}`, `errors=true`];
                getDirectMessageAttachments(null, args, { limit: 1 });
                getDirectMessages(null, [`messageId=${messageId}`], { limit: 1 });
            }
            if (patientId) {
                fetchPatients([patientId], null, { limit: 1 });
                let deletedPatient = await getDeletedPatient(appContext, patientId);
                if (isArrayValid(deletedPatient))
                    setDeletedPatient(deletedPatient[0]);
            }
            if (emrId) {
                let hgPatient = await getPatient(appContext, emrId, { emr: true } );
                if (hgPatient)
                    setHGPatient(hgPatient.emr);
            }
        }

    }

    function internalDownloadAttachment() {
        const inner = async () => {
            try {
                let args = [`messageId=${directMessageAttachment.messageId}`, `attachmentId=${directMessageAttachment.attachmentId}`, `includeAttachments=true`];
                let dma = await getDirectMessageAttachments(null, args, { limit: 1 });
                let data = dma.data.attachments;
                if (isArrayValid(data))
                    fileDownload(data[0].attachment, `Attachment.xml`);

            } catch (err) {
                console.error(`Error retrieveing attachment `, err)
            }
        }
        inner();

    }

    function internalReprocessAttachment() {

        const inner = async () => {
            try {
                setConfirmReprocess(false)
                directMessageAttachment.processed = false;
                directMessageAttachment.isProcessing = true;
                directMessageAttachment.state = "State cleared: restarting process";
                directMessageAttachment.requestId = null;
                directMessageAttachment.patientId = null;
                directMessageAttachment.emrId = null;
                directMessageAttachment.error = null;
                directMessageAttachment.errors = [];
                await saveDirectMessageAttachment(appContext, directMessageAttachment);
                processAttachment([directMessageAttachment.attachmentId], ["async=true"]);
            } catch (err) {
                console.error(`Error processing attachment `, err)
            }
        }
        inner();

    }

    function internalClearAttachment() {

        const inner = async () => {
            try {
                setConfirmClearAttach(false)
                directMessageAttachment.processed = false;
                directMessageAttachment.isProcessing = false;
                directMessageAttachment.state = null;
                directMessageAttachment.requestId = null;
                directMessageAttachment.emrId = null;
                directMessageAttachment.patientId = null;
                directMessageAttachment.error = null;
                directMessageAttachment.errors = [];
                await saveDirectMessageAttachment(appContext, directMessageAttachment, ["clearErrors=true"]);
            } catch (err) {
                console.error(`Error saving  attachment `, err)
            }
        }
        inner();

    }

    useEffect(() => {
        if (directMessageAttachments && isArrayValid(directMessageAttachments.attachments)) {
            let dm = directMessageAttachments.attachments[0];
            setDMA(dm)
            parseAttachment([dm.attachmentId])
        }
    }, [directMessageAttachments])

    useEffect(() => {
        if (directMessages && isArrayValid(directMessages.messages)) {
            setDM(directMessages.messages[0])
        }
    }, [directMessages])

    useEffect(() => {
        if (updateDirectMessageAttachmentData) {
            setDMA(updateDirectMessageAttachmentData)
        }
    }, [updateDirectMessageAttachmentData])


    useEffect(() => {
        if (patients && patients.patient) {
            let patient = patients.patient;
            if (patient.userId)
                getPatientAccount(patient.userId)
            if (patient.primaryProviderId)
                fetchProviders([patient.primaryProviderId])
            if (patient.primaryProviderId)
                getProviderAccount([patient.primaryProviderId])
            if (patient.consentGivenBy)
                fetchConsentedBy([patient.consentGivenBy])
        }
    }, [patients])




    useEffect(() => {
        if (patientAccount && patientAccount.user) {
            let user = patientAccount.user;
            let loginSuccessArgs = [];
            loginSuccessArgs.push("EventName=LoginSuccess");
            loginSuccessArgs.push(`UserName=${encodeURIComponent(user.email)}`);
            loginSuccessArgs.push("limit=1");
            fetchLoginSuccessMetrics(null, loginSuccessArgs);

            let loginFailArgs = [];
            loginFailArgs.push("EventName=LoginFailure");
            loginFailArgs.push(`UserName=${encodeURIComponent(user.email)}`);
            loginFailArgs.push("limit=1");
            fetchLoginFailMetrics(null, loginFailArgs);
        }
    }, [patientAccount])


    let patient = useMemo(() => {
        return patients?.patient;
    }, [patients, directMessageAttachment])

    let patientUser = useMemo(() => {
        if (patientAccount && patientAccount.user)
            return patientAccount.user;
    }, [patientAccount, directMessageAttachment])

    let providerUser = useMemo(() => {
        if (providerAccount && providerAccount.user)
            return providerAccount.user;
    }, [providerAccount, directMessageAttachment])

    let provider = useMemo(() => {
        return providers?.provider;
    }, [providers, directMessageAttachment])

    let consentedByProvider = useMemo(() => {
        return consentedBy?.provider;
    }, [consentedBy, directMessageAttachment])

    let recentLoginSuccess = useMemo(() => {
        if (loginSuccessMetrics && isArrayValid(loginSuccessMetrics.metrics))
            return loginSuccessMetrics.metrics[0]
    }, [loginSuccessMetrics, directMessageAttachment])

    let recentLoginFail = useMemo(() => {
        if (loginFailMetrics && isArrayValid(loginFailMetrics.metrics))
            return loginFailMetrics.metrics[0]
    }, [loginFailMetrics, directMessageAttachment])


    function validateEmail(email) {
        return !INVALID_EMAILS.includes(email.toLowerCase()) && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

    }
    let warnings = useMemo(() => {
        function getErrorListItem(text, component = "", when = "") {
            return <ListItem key={text}><ListItemIcon style={{ minWidth: "30px" }}><ErrorIcon className={classes.errorSign} /></ListItemIcon><Typography className={classes.error} variant="subtitle2">{text} {component} {when}</Typography> </ListItem>
        }
        function getWarningListItem(text) {
            return <ListItem key={text}><ListItemIcon style={{ minWidth: "30px" }}><Warning className={classes.warningSign} /></ListItemIcon><Typography className={classes.warning} variant="subtitle2">{text}</Typography> </ListItem>
        }
        function getInfoListItem(text) {
            return <ListItem key={text}><ListItemIcon style={{ minWidth: "30px" }}><Info className={classes.infoSign} /></ListItemIcon><Typography className={classes.info} variant="subtitle2">{text}</Typography> </ListItem>
        }
        function getGreenListItem(text) {
            return <ListItem key={text}><ListItemIcon style={{ minWidth: "30px" }}><CheckCircle className={classes.greenSign} /></ListItemIcon><Typography className={classes.green} variant="subtitle2">{text}</Typography> </ListItem>
        }

        function getWaitingListItem(text) {
            return <ListItem key={text} ><ListItemIcon style={{ minWidth: "30px" }}><HourglassEmpty className={classes.blueSign} color="primary" /></ListItemIcon><Typography color="primary" variant="subtitle2">{text}</Typography> </ListItem>
        }
        let list = [];


        //return asap
        if (directMessageAttachment) {

            // if (directMessageAttachment.state && directMessageAttachment.state !== "" && directMessageAttachment.state !== "Process Complete") {
            //     list.push(getWaitingListItem(directMessageAttachment.state));
            // }

            if (toBoolean(directMessageAttachment.isProcessing)) {
                list.push(getWaitingListItem(`Note: This attachment is still processing`));
                return <List dense>{list}</List>;
            }

            let createdAt = directMessageAttachment.createdAt;
            let updatedAt = directMessageAttachment.updatedAt;
            if (Moment(createdAt).isSame(Moment(updatedAt))) {
                list.push(getWarningListItem(`Note: This attachment might not have started processing yet`));
                return <List dense>{list}</List>;
            }

            //errors
            if (directMessageAttachment) {
                if (!Boolean(directMessageAttachment.processed) && !directMessageAttachment.error)
                    list.push(getErrorListItem("This attachment did not finish processing"));

                if (directMessageAttachment && directMessageAttachment.error)
                    list.push(getErrorListItem(`This attachment has an error`));

                if (directMessageAttachment.patientId && !patient) {
                    if (deletedPatient) {
                        list.push(getErrorListItem(`The patient was deleted by`, <User appContext={appContext} userId={deletedPatient.deletedBy} />, moment(deletedPatient.deletedWhen).format("LLL")));
                    } else {
                        list.push(getErrorListItem("The patient was created but might have been deleted"));
                    }

                }


            }
            if (bbAttachment) {
                let firstName = _.get(bbAttachment, "data.demographics.name.first");
                if (!firstName)
                    list.push(getErrorListItem("Patient on attachment has no firstName"));
                let lastName = _.get(bbAttachment, "data.demographics.name.last");
                if (!lastName)
                    list.push(getErrorListItem("Patient on attachment has no lastName"));
                let gender = _.get(bbAttachment, "data.demographics.gender.name");
                if (!gender)
                    list.push(getErrorListItem("Patient on attachment has no gender"));
                let dob = _.get(bbAttachment, "data.demographics.dob.point.date");
                if (!dob)
                    list.push(getErrorListItem("Patient on attachment has no DOB"));

            }


            if (patient && !patientUser) {
                if (patient.userId)
                    list.push(getWarningListItem("This patient user account was deleted"));
                else {
                    console.debug(`attachment.errors) `, directMessageAttachment.errors)
                    if (isArrayValid(directMessageAttachment.errors)) {
                        let dupMessage = directMessageAttachment.errors.find(({ message }) => message.indexOf("User already exists") >= 0);
                        console.debug(`dupMessage `, dupMessage)
                        if (dupMessage)
                            list.push(getWarningListItem("This patient is a possible duplicate"));
                    }
                    list.push(getWarningListItem("This patient user account has not been created"));
                }
            }


            //warnings
            if (patient) {
                let postalCode = _.get(patient, "address[0].postalCode");
                if (!postalCode)
                    list.push(getWarningListItem("Patient created has no postal code"));
                let email = _.get(patient, "emails[0].email");
                let phones = _.get(patient, "phoneNumbers");
                if (!email)
                    list.push(getWarningListItem("Patient created has no email"));

                if (!isArrayValid(phones))
                    list.push(getWarningListItem("Patient created has no phones"));

                if (patient.inactive === true)
                    list.push(getWarningListItem("Patient is marked inactive"));
            }

            if (bbAttachment) {
                let postalCode = _.get(bbAttachment, "data.demographics.addresses[0].zip");
                if (!postalCode)
                    list.push(getWarningListItem("Patient on attachment has no postal code"));

                let emails = _.get(bbAttachment, "data.demographics.email");
                if (!isArrayValid(emails))
                    list.push(getWarningListItem("Patient on attachment has no emails"));
                else {
                    let email = emails.find((p => p.address));
                    if (!email)
                        list.push(getWarningListItem("Patient on attachment has no valid email address"));
                    if (email && !validateEmail(email.address))
                        list.push(getWarningListItem("Patient on attachment has an invalid email address"));
                }


                let phones = _.get(bbAttachment, "data.demographics.phone");
                if (!isArrayValid(phones))
                    list.push(getWarningListItem("Patient on attachment has no phones"));
                else {
                    let phone = phones.find((p => p.number));
                    console.debug(`phone on bbattachment `, phone)
                    if (!phone)
                        list.push(getWarningListItem("Patient on attachment has no valid phone"));
                }
            }

            if (provider && !providerUser) {
                list.push(getWarningListItem("This provider user account has not been created"));
            }

            if (list.length === 0) {
                list.push(getGreenListItem("Everthing looks great!"));
            }

            list.push(<ListItem key={"empty"}></ListItem>);

            //add some positive stuff 
            if (patient && patient.patientId) {
                list.push(getGreenListItem("The patient record was successfully created."));
            }
            if (patientUser) {
                list.push(getGreenListItem("The patient user account was successfully created."));
            }

            if (bbAttachment) {
                let postalCode = _.get(bbAttachment, "data.demographics.addresses[0].zip");
                if (postalCode)
                    list.push(getGreenListItem("Patient on attachment has a postal code"));

                let emails = _.get(bbAttachment, "data.demographics.email");
                if (isArrayValid(emails)) {
                    let email = emails.find((p => p.address));

                    if (email && validateEmail(email.address))
                        list.push(getGreenListItem("Patient on attachment has at least one valid email address"));
                }

                let phones = _.get(bbAttachment, "data.demographics.phone");
                if (isArrayValid(phones)) {
                    let phone = phones.find((p => p.number));
                    if (phone)
                        list.push(getGreenListItem("Patient on attachment has at least one phone"));
                }
            }
            list.push(<ListItem key={"empty"}></ListItem>);

            //info
            if (isArrayValid(directMessageAttachment.errors)) {
                directMessageAttachment.errors.forEach(error => {
                    list.push(getInfoListItem(error.message));
                });
            }
        }

        return <List dense>{list}</List>;
    }, [directMessageAttachment, bbAttachment, directMessage, patient, patients, patientAccount, providerAccount, deletedPatient])

    const dmaTable = useMemo(() => {
        function getRow(text, value) {
            return <TableRow key={text}>
                <TableCell >{text}</TableCell>
                <TableCell style={{ maxWidth: '40rem', wordWrap: "break-word" }}>{value}</TableCell>
            </TableRow >
        }
        let rows = []
        if (directMessageAttachment) {
            rows.push(getRow("Message Id", <Link to={`/directMessages/${directMessageAttachment["messageId"]}`}>{directMessageAttachment["messageId"]}</Link>));
            rows.push(getRow("Attachment Id", directMessageAttachment.attachmentId));
            rows.push(getRow("Patient Id", <Link to={`/patients/${directMessageAttachment["patientId"]}`}>{directMessageAttachment["patientId"]}</Link>));
            rows.push(getRow("Error", <span className={classes.errorText}>{directMessageAttachment["error"]}</span>));

            rows.push(getRow("Processed", String(directMessageAttachment["processed"])));
            rows.push(getRow("Is Processing", String(directMessageAttachment["isProcessing"])));

            rows.push(getRow("Last known state", directMessageAttachment["state"]));
            let createdAt = directMessageAttachment["createdAt"];
            createdAt = createdAt ? Moment(directMessageAttachment["createdAt"]).tz(TIMEZONE).format("LLL") : createdAt;
            rows.push(getRow("Create date", createdAt));

            let updatedAt = directMessageAttachment["updatedAt"];
            updatedAt = updatedAt ? Moment(directMessageAttachment["updatedAt"]).tz(TIMEZONE).format("LLL") : updatedAt;
            rows.push(getRow("Last update date", updatedAt));
        }


        return <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>;

    }, [directMessageAttachment, directMessage, bbAttachment, patient, patientAccount, providerAccount, provider, consentedBy, recentLoginFail, recentLoginSuccess])

    const dmaMetaTable = useMemo(() => {
        function getRow(text, value) {
            return <TableRow key={text}>
                <TableCell >{text}</TableCell>
                <TableCell style={{ maxWidth: '40rem', wordWrap: "break-word" }}>{value}</TableCell>
            </TableRow >
        }
        let rows = []
        if (directMessageAttachment) {

            rows.push(getRow("Provider Id", provider && <Link to={`/providers/${provider["providerId"]}`}>{provider["providerId"]}</Link>));
            rows.push(getRow("Consented By Id", consentedByProvider && <Link to={`/providers/${consentedByProvider["providerId"]}`}>{consentedByProvider["providerId"]}</Link>));

            rows.push(getRow("Request Id", <Link onClick={() => setRequestId(directMessageAttachment["requestId"])}>{directMessageAttachment["requestId"]}</Link>));
            rows.push(getRow("EMR Id", directMessageAttachment["emrId"]));

            rows.push(getRow("Patient first name", _.get(directMessageAttachment, "patient.firstName")));
            rows.push(getRow("Patient last name", _.get(directMessageAttachment, "patient.lastName")));
            rows.push(getRow("Patient gender", _.get(directMessageAttachment, "patient.gender")));
            let dob = _.get(directMessageAttachment, "patient.dob");
            rows.push(getRow("Patient DOB", dob ? Moment(dob).local().format("LL") : ""));

            if (directMessage) {
                rows.push(getRow("From Address", _.get(directMessage, "data.fromAddress")));

                rows.push(getRow("DM Body", _.get(directMessage, "data.body")));
            }

            if (patientUser) {
                rows.push(getRow("User Id", <Link to={`/users/?userId=${patientUser["username"]}`}>{patientUser["username"]}</Link>));
                rows.push(getRow("User status", patientUser["userStatus"]));
                rows.push(getRow("User email", patientUser["email"]));
                rows.push(getRow("User groups", patientUser["groups"] && patientUser["groups"].join(",")));
            }

            rows.push(getRow("Recent Successful Login", recentLoginSuccess && Moment(recentLoginSuccess["createDate"]).local().format("MM-DD-YYYY HH:mm")));
            rows.push(getRow("Recent Failed Login", recentLoginFail && Moment(recentLoginFail["createDate"]).local().format("MM-DD-YYYY HH:mm")));
        }


        return <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>

                </TableHead>
                <TableBody >
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>;

    }, [directMessageAttachment, directMessage, patient, patientAccount, providerAccount, patientUser, providerUser, provider, consentedBy, recentLoginFail, recentLoginSuccess])


    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => onClose()}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" >DM Attachment Details</Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.root}>
                <div className={classes.box} >
                    <div className={classes.row}>
                        <div className={classes.warnings}>
                            {error && <Error>{error.join(", ")}</Error>}
                            {loading && <Loading />}
                            {!loading && warnings}
                        </div>
                        <div className={classes.actions}>
                            <Tooltip title='Reprocess attachment'>
                                <span><IconButton
                                    disabled={loading}
                                    color="primary"
                                    onClick={() => {
                                        setConfirmReprocess(true);
                                    }}
                                >
                                    {<Restore />}
                                </IconButton></span>
                            </Tooltip>
                            <Tooltip title='Clear attachment state and errors'>
                                <span><IconButton
                                    disabled={loading}
                                    color="primary"
                                    onClick={() => {
                                        setConfirmClearAttach(true);

                                    }}
                                >
                                    {<ClearAll />}
                                </IconButton></span>
                            </Tooltip>
                            <Tooltip title='Parse attachment using BB'>
                                <span><IconButton
                                    disabled={loading || !directMessageAttachment || !directMessageAttachment.attachmentId}
                                    color="primary"
                                    onClick={() => {
                                        parseAttachment([directMessageAttachment.attachmentId])
                                    }}
                                >
                                    {<Description />}
                                </IconButton></span>
                            </Tooltip>
                            <Tooltip title='Download BB Document'>
                                <span><IconButton
                                    disabled={loading || !directMessageAttachment || !directMessageAttachment.attachmentId}
                                    color="primary"
                                    onClick={async () => {
                                        let bbAT = await parseAttachment([directMessageAttachment.attachmentId])
                                        fileDownload(JSON.stringify(bbAT, null, 4), `bbAttachment.json`)
                                    }}
                                >
                                    {<AssignmentReturned />}
                                </IconButton></span>
                            </Tooltip>
                            <Tooltip title='Download attachment'>
                                <span><IconButton
                                    disabled={loading || !directMessageAttachment || !directMessageAttachment.attachmentId}
                                    color="primary"
                                    onClick={() => {
                                        internalDownloadAttachment()

                                    }}
                                >
                                    {<CloudDownload />}
                                </IconButton></span>
                            </Tooltip>
                            <Tooltip title='Refresh'>
                                <span><IconButton
                                    disabled={loading}
                                    color="primary"
                                    onClick={() => {
                                        internalRefresh();
                                    }}
                                >
                                    {<Refresh />}
                                </IconButton></span>
                            </Tooltip>
                        </div>

                    </div>
                    {dmaTable}
                    {dmaMetaTable}
                    <div className={classes.jsonPaper}>
                        <ReactJson
                            theme={theme.overrides.ReactJsonView}
                            src={{ directMessageAttachment, directMessage, bbAttachment, patientUser, providerUser, provider, patient, hgPatient, deletedPatient }}
                            className={classes.json}
                            collapsed={1}
                            name="JSONs"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />

                    </div>
                    <Dialog
                        open={confirmReprocess}
                        onClose={() => setConfirmReprocess(false)}
                    >
                        <DialogTitle id="signoutDialog" >Attachment Restart</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>Are you sure you want to process the attachment?</Typography>
                                <Typography>Note: This will kick off an asyc process.</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button id="signoutCancel" className={classes.button} variant="contained" onClick={(e) => { setConfirmReprocess(false) }} >Cancel</Button>
                            <Button id="signoutConfirm" className={classes.button} variant="contained" onClick={(e) => { internalReprocessAttachment() }}>Confirm</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={confirmClearAttach}
                        onClose={() => setConfirmClearAttach(false)}
                    >
                        <DialogTitle id="signoutDialog" >Attachment Clear State and Errors</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>Are you sure you want to clear the attachment state and errors?</Typography>
                                <Typography>Note: This will NOT kick off an asyc process. It will only clear the state and error. The attachment will get picked up in the next cron</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button id="signoutCancel" className={classes.button} variant="contained" onClick={(e) => { setConfirmClearAttach(false) }} >Cancel</Button>
                            <Button id="signoutConfirm" className={classes.button} variant="contained" onClick={(e) => { internalClearAttachment() }}>Confirm</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            {requestId && <ClickAwayListener onClickAway={() => setRequestId(null)}><LogsView rowsPerPage={200} showDebug={true} autoRefresh={false} width={800} appContext={appContext} requestId={requestId} onDone={() => setRequestId(null)} /></ClickAwayListener>}
        </>
    )
}


export async function saveDirectMessageAttachment(appContext, data, args) {
    try {
        const idToken = _.get(appContext, "state.idToken");
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}messaging/directMessageAttachments?${args.join('&')}`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data,
        };
        log.debug("saveDirectMessageAttachment request:", request);
        let response = await AxiosRequest(request);
        log.debug("saveDirectMessageAttachment response:", response.data);
        return response.data;
    } catch (error) {
        log.debug("saveDirectMessageAttachment error:", getErrorMessage(error));
        throw error;
    }
};
export default withStyles(styles)(DirectMessageAttachment)