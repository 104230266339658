import React from 'react';
import Select from 'react-select';
import Moment from 'moment';
import Config from '@apricityhealth/web-common-lib/Config';
import {
    Checkbox,
    CircularProgress,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Tooltip,
    Typography
} from '@material-ui/core/';

import CreateIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';

import { compareAscending } from '../utils/Utils';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";
import SelectProvider from "@apricityhealth/web-common-lib/components/SelectProvider";
import SelectTrial from '@apricityhealth/web-common-lib/components/SelectTrial';

import MultiSelectOrg from '@apricityhealth/web-common-lib/components/MultiSelectOrg';
import MultiSelectPlan from '@apricityhealth/web-common-lib/components/MultiSelectPlan';
import MultiSelectTrial from '@apricityhealth/web-common-lib/components/MultiSelectTrial';
import EmployeeReport from './reports/EmployeeReport';
import EmployerReport from './reports/EmployerReport';
import ApiDurationReport from "./reports/ApiDurationReport";
import SamplesReport from "./reports/SamplesReport";
import OverviewPage from './reports/overview';
import LongitudinalPage from './reports/longitudinal';
import StatisticsPage from './reports/statistics';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import SelectReportJob from '@apricityhealth/web-common-lib/components/SelectReportJob';
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';

import { saveReportJob } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const HEADER_ITEMS = {
    refresh: (self) => {
        return <Tooltip title='Refresh Report'><IconButton
            disabled={self.state.progress !== null}
            style={styles.refresh}
            onClick={self.onRefresh.bind(self)}>
            {self.state.progress || <RefreshIcon />}
        </IconButton></Tooltip>;
    },
    createReportJob: (self) => {
        return <Tooltip title='Create Report Job'><IconButton
            disabled={self.state.createJob !== null}
            style={styles.refresh}
            onClick={self.onCreateReportJob.bind(self)}>
            {self.state.createJob || <CreateIcon />}
        </IconButton></Tooltip>;
    },
    selectReportJob: (self) => {
        return <SelectReportJob appContext={self.props.appContext} reportName={self.state.selectedReport.reportName} jobId={self.state.jobId}
            onChange={(job) => {
                if (job) {
                    let state = { jobId: job.jobId };
                    for (let k in job.args) {
                        state[k] = job.args[k];
                    }
                    self.setState(state);
                }
            }} />;
    },
    planAll: (self) => {
        return <FormControl key='planAll' style={{ width: 250, margin: 5 }}>
            <InputLabel>Select Plan</InputLabel>
            <SelectPlan enableAll={true} style={{ topMargin: 15 }} appContext={self.props.appContext} planId={self.state.planId} onChange={self.onPlanSelected.bind(self)} />
        </FormControl>;
    },
    plan: (self) => {
        return <FormControl key='plan' style={{ width: 250, margin: 5 }}>
            <InputLabel>Select Plan</InputLabel>
            <SelectPlan style={{ topMargin: 15 }} appContext={self.props.appContext} planId={self.state.planId} onChange={self.onPlanSelected.bind(self)} />
        </FormControl>;
    },
    selectPlan: (self) => {
        return <FormControl key='selectPlan' style={{ width: 300, margin: 5 }}>
            <InputLabel>Select Plan</InputLabel>
            <MultiSelectPlan style={{ topMargin: 15 }} appContext={self.props.appContext} value={self.state.planId}
                onChange={self.onPlansSelected.bind(self)} />
        </FormControl>;
    },
    date: (self) => {
        const { startTime, endTime } = self.state;
        return <DateRange key='date'
            appContext={self.props.appContext}
            format={"MM/dd/yyyy"}
            startTime={startTime}
            endTime={endTime}
            endTimeChange={(endTime) => { self.setState({ endTime: Moment(endTime).endOf('day').toDate() }); }}
            startTimeChange={(startTime) => { self.setState({ startTime: Moment(startTime).startOf('day').toDate() }); }} />;
    },
    org: (self) => {
        const { orgId } = self.state;
        return (
            <SelectOrg
                appContext={self.props.appContext}
                orgId={orgId}
                onChange={(orgId) => { self.onOrgSelected(orgId); }} />
        );
    },
    orgAll: (self) => {
        return (
            <SelectOrg
                appContext={self.props.appContext}
                enableAll={true}
                orgId={self.state.orgId}
                onChange={(orgId) => { self.onOrgSelected(orgId); }} />
        );
    },
    selectOrg: (self) => {
        return <FormControl key='selectOrg' style={{ width: 300, margin: 5 }}>
            <InputLabel>Select Trial</InputLabel>
            <MultiSelectOrg style={{ topMargin: 15 }} appContext={self.props.appContext} selectedValue={self.state.orgId} onChange={(org) => { self.onOrgSelected(org.orgId); }} />
        </FormControl>;
    },
    trial: (self) => {
        return <SelectTrial key='trial'
            style={{ width: 150, minWidth: 80, margin: 5 }}
            id={"trial-helper"}
            appContext={self.props.appContext}
            trial={self.state.trialId}
            onChange={(trialId) => { self.onTrialSelected(trialId); }}
        />;
    },
    selectTrial: (self) => {
        return <FormControl key='selectTrial' style={{ width: 300, margin: 5 }}>
            <InputLabel>Select Trial</InputLabel>
            <MultiSelectTrial style={{ topMargin: 15 }} appContext={self.props.appContext} selectedValue={self.state.trialId} onChange={(trial) => { self.onTrialSelected(trial.trialId); }} />
        </FormControl>;
    },
    patientAll: (self) => {
        return <SelectPatient appContext={self.props.appContext} patientId={self.state.patientId} enableAll={true} onChange={(e) => {
            self.onPatientSelected(e);
        }} />;
    },
    patient: (self) => {
        return <SelectPatient appContext={self.props.appContext} patientId={self.state.patientId} onChange={(e) => {
            self.onPatientSelected(e);
        }} />;
    },
    providerAll: (self) => {
        return (
            <SelectProvider appContext={self.props.appContext} providerId={self.state.providerId} enableAll={true}
                onChange={(providerId) => { self.onProviderSelected(providerId); }} />
        );
    },
    provider: (self) => {
        return (
            <SelectProvider appContext={self.props.appContext} providerId={self.state.providerId}
                onChange={(providerId) => { self.onProviderSelected(providerId); }} />
        );
    },
    user: (self) => {
        return <SelectUser enableAll={true} appContext={self.props.appContext} userId={self.state.userId} onChange={(userId) => {
            self.setState({ userId });
        }} />;
    },
    ignoreRTW: (self) => {
        return <FormControlLabel control={<Checkbox checked={self.state.ignoreRTW} onChange={(e) => self.setState({ ignoreRTW: e.target.checked })} />}
            label="Ignore RTW" />;
    }
};

const OTHER_REPORTS = [

];

const DASHBOARD_REPORTS = [
    {
        label: 'API Durations',
        value: 'apiDurations',
        headerItems: ["user", 'refresh'],
        component: ApiDurationReport
    },
    {
        label: 'Metric Samples',
        value: 'metricSamples',
        headerItems: ["user", 'refresh'],
        component: SamplesReport
    },
    {
        label: 'Overview',
        value: 'overview',
        headerItems: [],
        component: OverviewPage
    },
    {
        label: 'Statistics',
        value: 'statistics',
        headerItems: [],
        component: StatisticsPage
    }, {
        label: 'Longitudinal',
        value: 'longitudinal',
        headerItems: [],
        component: LongitudinalPage
    },

];

const EMPLOYE_REPORTS = [
    {
        label: 'Employee Metrics',
        value: 'employeeReport',
        headerItems: ['org', "planAll", 'refresh'],
        component: EmployeeReport
    },
    {
        label: 'Employer Roster Metrics',
        reportName: 'EmployerMetrics',
        isSeries: true,
        value: 'employerMetricsReport',
        headerItems: ['selectReportJob', 'date', 'createReportJob', 'refresh'],
        dateType: 'month',
        component: EmployerReport
    }
]


const REPORTS_MENU = [
    {
        label: "Dashboards",
        options: DASHBOARD_REPORTS
    },
    {
        label: "Other Reports",
        options: OTHER_REPORTS.sort((a, b) => compareAscending(a, b))
    }
];

const ALL_REPORTS = [
    ...DASHBOARD_REPORTS,
    ...OTHER_REPORTS
];


(function () {
    const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;
    console.log(`STAGE in function `, STAGE, DASHBOARD_REPORTS);
    if (STAGE !== "vcc") {
        REPORTS_MENU.unshift(
            {
                label: "Return To Work",
                options: EMPLOYE_REPORTS.sort((a, b) => compareAscending(a, b))
            },
        );
        ALL_REPORTS.push(...EMPLOYE_REPORTS);
    }
})();

export class ReportsView extends React.Component {
    constructor(props) {
        super(props);

        let lastReport = localStorage.getItem("selectedReport");
        if (lastReport) lastReport = JSON.parse(lastReport);
        if (!lastReport) lastReport = "overview"

        this.state = {
            startTime: Moment().subtract(7, 'day').toDate(),
            endTime: Moment().toDate(),
            userId: '',
            jobId: '',
            patientId: "*",
            trialId: '*',
            providerId: "*",
            orgId: "*",
            planId: "*",
            selectedReport: lastReport || DASHBOARD_REPORTS[0],
            dateType: 'week',
            createJob: null,
            progress: null,
            ignoreRTW: false
        };
    }

    componentDidMount() {
        let { selectedReport } = this.state;

        let newState = {};
        let updateState = false;
        let error = null;

        const urlParams = new URLSearchParams(window.location.search);



        urlParams.forEach((v, k) => {
            if (k === 'reportName') {
                let select = ALL_REPORTS.find((e) => e.reportName === v);
                if (!select) {
                    error = `reportName is not found ${v}`;
                }
                if (select && (!selectedReport || select.value !== selectedReport.value)) {
                    newState.selectedReport = select;
                    updateState = true;
                }
            }
            else {
                // assume other parameters are args for the report 
                if (this.state[k] !== v) {
                    newState[k] = v;
                    updateState = true;
                }
            }
        });
        if (updateState && !error) {
            window.history.replaceState(null, '', window.location.pathname);
            console.log("ReportsView updating state:", newState);
            this.setState(newState);
        }
        else if (error) {
            window.history.replaceState(null, '', window.location.pathname);
            console.error(error);
        }
    }

    componentDidUpdate() {
        this.componentDidMount();
    }

    onRefresh() {
        const self = this;
        const { selectedReport } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, selectedReport: null }, () => {
            self.setState({ selectedReport, progress: null });
        })
    }

    onCreateReportJob() {
        const self = this;
        const { selectedReport, userId, startTime, endTime, patientId, orgId, providerId, trialId, planId, ignoreRTW } = this.state;
        let job = {
            reportName: selectedReport.reportName,
            isSeries: selectedReport.isSeries || true,
            args: {}
        };
        //only set the args if the report has configured for them to be set, cant' pass in data to all the reports if they don't need it. It breaks the reprot.

        //only set the date if the date was configured in the header
        const report = selectedReport ? ALL_REPORTS.find(report => (report.value === selectedReport.value)) || null : null;

        //date
        let useDate = report && report.headerItems.find(item => item === "date");
        if (useDate) {
            job.args.startTime = Moment(startTime).toISOString();
            job.args.endTime = Moment(endTime).toISOString();
        }
        //user
        let useUser = report && report.headerItems.find(item => item === "user");
        if (useUser) {
            job.args.userId = userId;
        }

        //planAll or plan
        let usePlan = report && report.headerItems.find(item => item === "planAll" || item === "plan" || "selectPlan" || "selectPlanAll");
        if (usePlan) {
            job.args.planId = planId;
        }
        //trialAll or trial
        let useTrial = report && report.headerItems.find(item => item === "trialAll" || item === "trial");
        if (useTrial) {
            job.args.trialId = trialId;
        }
        //orgAll or org
        let useOrg = report && report.headerItems.find(item => item === "orgAll" || item === "org" || item === "selectOrg");
        if (useOrg) {
            job.args.orgId = orgId;
        }
        //providerAll or provider
        let useProvider = report && report.headerItems.find(item => item === "providerAll" || item === "provider");
        if (useProvider) {
            job.args.providerId = providerId;
        }
        //patientAll or patient
        let usePatient = report && report.headerItems.find(item => item === "patientAll" || item === "patient");
        if (usePatient) {
            job.args.patientId = patientId;
        }
        //ignoreRTW 
        let userIgnoreRTW = report && report.headerItems.find(item => item === "ignoreRTW");
        if (userIgnoreRTW) {
            job.args.ignoreRTW = ignoreRTW;
        }

        console.log("onCreateReportJob:", job);
        this.setState({ createJob: <CircularProgress size={20} />, error: null });
        saveReportJob(this.props.appContext, job).then((result) => {
            let { jobId } = result;
            console.log("onCreateReportJob result:", jobId);
            self.setState({ jobId, createJob: null });         // select the new job
        }).catch((err) => {
            console.error("onCreateReportJob error:", err);
            self.setState({ createJob: null, error: getErrorMessage(err) })
        })
    }

    onReportSelected(event) {
        console.log(`Report selected `, event)
        let { startTime, endTime, dateType } = this.state;
        if (dateType !== event.dateType) {
            dateType = event.dateType || 'week';
            switch (dateType) {
                case 'week':
                    endTime = Moment().toDate();
                    startTime = Moment(endTime).subtract(7, 'days').toDate();
                    break;
                case 'month':
                    endTime = Moment().endOf('month').toDate();
                    startTime = Moment().startOf('month').toDate();
                    break;
                case 'quarter':
                    endTime = Moment().endOf('quarter').toDate();
                    startTime = Moment().startOf('quarter').toDate();
                    break;
                default:
                    console.error(`Unsupported dateType ${dateType}:`, event);
                    break;
            }
        }
        localStorage.setItem("selectedReport", JSON.stringify(event));
        this.setState({ selectedReport: event, startTime, endTime, dateType, jobId: null });
    }

    onPlanSelected(event) {
        console.log("ReportView onPlanSelected:", event);
        this.setState({ planId: event ? event.planId : '' });
    }
    onPlansSelected(e) {
        this.setState({ planId: e ? e.join(',') : '' });
    }

    onTrialSelected(trialId) {
        console.log("onTrialSelected:", trialId);
        this.setState({ trialId, patientId: '*' });
    }

    onPatientSelected(patientId) {
        console.log("onPatientSelected:", patientId);
        this.setState({ patientId });
    }

    // patientBlackListSelected(items) {
    //     let blackListPatients = [];
    //     if (items && items.length) {
    //         items.forEach((item) => {
    //             blackListPatients.push(item.value);
    //         });
    //     }
    //     this.setState({ blackListPatients });
    // }

    onProviderSelected(providerId) {
        console.log("onProviderSelected:", providerId);
        this.setState({ providerId });
    }

    onOrgSelected(orgId) {
        console.log(`onOrgSelected:`, orgId);
        this.setState({ orgId });
    }

    getSelectWidth(label) {
        return ((8 * label.length) + 60);
    }


    render() {
        const {
            props: { appContext },
            state: { dialog, error, selectedReport }
        } = this;
        const report = selectedReport ? ALL_REPORTS.find(report => (report.value === selectedReport.value)) || null : null;

        let reportSelect = () => {
            return <FormControl style={{ margin: 5, minWidth: 150, width: 250 }}>
                <Typography component='span' variant='caption' children='Select Report:' />
                <Select
                    value={selectedReport}
                    options={REPORTS_MENU}
                    onChange={(e) => { this.onReportSelected(e) }}
                />
            </FormControl>;
        };

        let showReport = () => {
            if (report && report.component) {
                const Report = report.component;
                const {
                    jobId,
                    userId,
                    patientId,
                    providerId,
                    orgId,
                    planId,
                    trialId,
                    endTime,
                    startTime
                } = this.state;
                return <Report appContext={appContext}
                    args={{ jobId, userId, startTime, endTime, patientId, orgId, providerId, trialId, planId }}
                    parent={this} />;
            }
        }

        let reportOptions = [];
        if (report) {
            for (let i = 0; i < report.headerItems.length; ++i) {
                let item = report.headerItems[i];
                if (typeof HEADER_ITEMS[item] === 'function') {
                    reportOptions.push(HEADER_ITEMS[item](this));
                }
                else {
                    console.error("Invalid header item:", item);
                }
            }
        }


        let buttons = [
            reportSelect(),
            ...reportOptions
        ];

        return (
            <Grid style={styles.div} container spacing={1}>
                <Grid item xs={12}>
                    <Grid container direction={'row'} spacing={1}>
                        {buttons.map((btn, i) => (
                            <Grid item key={i}>
                                {btn}
                            </Grid>
                        ))}
                        <span style={{ color: 'red' }}>{error}</span>
                    </Grid>
                </Grid>
                <Grid item xs>
                    {showReport()}
                </Grid>
                {dialog}
            </Grid>
        )
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    trials: {
        margin: 5,
        width: 100
    },
    question: {
        margin: 5,
        width: '80%'
    },
    alert: {
        boxShadow: '0 3px 5px 2px #b9a4af4d',
        border: '0px solid rgba(226, 225, 225, 0.705)',
        bordeRadius: '2px',
        marginTop: '20px',
        width: 350,
        cursor: 'pointer'
    },
    tags: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 100
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    }
}

export default ReportsView;