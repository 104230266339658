import React from 'react';

import {
    withStyles,
    Paper,
} from '@material-ui/core/';
import styles from '../RecentActivityPage.styles'
import RecentStory from './RecentStory';

function PatientStory({ loading, patientId, planTypes, alertLevels, modelTypes, detectModels, dataTypes, questionTypes, appContext, selectedAlert }) {

    return (<div>
        {(patientId && patientId !== "*") ?
            <Paper square >
                <RecentStory loading={loading} patientId={patientId} selectedAlert={selectedAlert} planTypes={planTypes} dataTypes={dataTypes} modelTypes={modelTypes} alertLevels={alertLevels} detectModels={detectModels} questionTypes={questionTypes} appContext={appContext} />
            </Paper> : "Please select an individual patient"
        }
    </div >
    )
}
export default withStyles(styles)(PatientStory)