import { colors } from "@material-ui/core"

const styles = ({ palette }) => ({
    colorMain: palette.primary.main,
    colorRequested: palette.secondary.main,
    colorRejected: colors.red,
    container: {
        display: "flex",
        margin: '2rem',
        flexDirection: "column",
    },
    calendar: {
        margin: '2rem',
    },
    popover: {
        margin: '2rem',
        "& .text-link": {
            color: 'blue',
            cursor: 'pointer',
            '&:hover': {
                color: 'red'
            }
        },
        "& .close": {
            fontSize: '0.9rem',
            flex: 1,
        },
        "& .toolbar": {
            marginLeft: 12,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            "textAlign": "right"
        },
    },
    row: {
        display: "flex",
        width: '100%',
    },
    right: {
        alignItems: "center",
        verticalAlign: "center",
        display: "flex",
    },
    search: {
        width: '100%',
    },

})

export default styles
