import React, { Component } from 'react';
import { Drawer, LinearProgress, TextField } from '@material-ui/core/';
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import DeviceView from './DeviceView';
import {
    getDevices,
    getPatients,
    saveDevice
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    getByPatientId,
    getBySerialId
} from '../utils/Utils';



function filterDevice(filter, device) {
    if (device.model && device.model.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (device.description && device.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (device.serial && device.serial.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (device.name && device.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;

    return false;
}
export class DevicesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            devices: [],
            patients: [],
            limit: 250,
            filter: ""
        }
    }
    componentWillMount() {
        this.loadDevices();
        this.loadPatients();

    }

    loadDevices() {
        const self = this;
        let { limit } = this.state;
        const { appContext } = this.props;
        let args = [];
        args.push("limit=" + limit);
        self.setState({
            progress: <LinearProgress color="secondary" />
        });
        getDevices(appContext).then(function (devices) {
            self.setState({ devices, progress: null });
        }).catch(function (error) {
            console.log("getDevices error:", error.response);
            self.setState({ progress: error.message });
        });
    }

    loadPatients() {
        const self = this;
        let { limit } = this.state;
        const { appContext } = this.props;
        let args = [];
        args.push("limit=" + limit);
        self.setState({
            progress: <LinearProgress color="secondary" />
        });
        getPatients(appContext, { inactive: false } ).then((patients) => {
            self.setState({ patients, progress: null });
        }).catch(function (error) {
            console.log("getPatients error:", error.response);
            self.setState({ progress: error.message });
        });
    }
    saveDevices() {
        const self = this;
        let { devices } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <LinearProgress color="secondary" /> });
        saveDevice(appContext, devices).then(function (devices) {
            console.log("save device response:", devices);
            self.setState({ devices, progress: null });
        }).catch(function (error) {
            console.log("save device error:", error);
            self.setState({ progress: error.message });
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadDevices();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    deviceSelected(s, t) {
        console.log("device selected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const deviceId = t.props.data[s[0]]._id
            const devices = self.state.devices 
            const dIndex = devices.findIndex( (device) => device._id === deviceId)
            const device = self.state.devices[dIndex];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <DeviceView
                    appContext={appContext}
                    device={device}
                    onClose={() => self.onCloseDialog()} />
            </Drawer>;
            console.log(`loading dialog`);
            self.setState({ progress: null, dialog: dialog });
        }
        // Nothing is selected..
        else {
            self.setState({ progress: null, dialog: null });
        }
    }
    createDevice() {
        console.log("create device");
        const self = this;
        const { appContext } = this.props;
        const newDevice = { model: '', serial: '', name: '', description: '' };
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <DeviceView appContext={appContext} device={newDevice} onClose={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    mergeDevice(row) {
        let self = this;
        let { devices } = this.state;
        return new Promise((resolve, reject) => {
            if (row.serial) {
                let device = getBySerialId(devices, row.serial);
                if (!device) {
                    device = {
                        serial: row.serial,
                    }
                    devices.push(device);
                }

                if (row.orgId)
                    device.orgId = row.orgId;
                if (row.description)
                    device.description = row.description;
                if (row.name)
                    device.name = row.name;
                if (row.model)
                    device.model = row.model;
                if (row.patientId)
                    device.patientId = row.patientId;
                if (row.dateIssued)
                    device.dateIssued = row.dateIssued;
                if (row.dateReturned)
                    device.dateReturned = row.dateReturned;

                self.setState(devices);
                resolve(device);
            } else {
                console.log(`no serial found for `, row);
                resolve();
            }
        });
    }


    render() {
        const self = this;
        const { devices, dialog, progress, patients, filter } = this.state;

        const columnData = [
            { id: 'name', numeric: false, width: 100, disabledPadding: false, label: 'Name' },
            { id: 'model', numeric: false, width: 100, disabledPadding: false, label: 'Model' },
            { id: 'serial', numeric: false, width: 400, disabledPadding: false, label: 'Serial' },
            { id: 'orgId', numeric: false, width: 100, disabledPadding: false, label: 'Org Id' },
            {
                id: 'patientId', numeric: false, width: 200, disabledPadding: false, label: 'Patient Id', formatValue: (v) => {
                    let patient = getByPatientId(patients, v);
                    if (patient) 
                        return `${patient.firstName} ${patient.lastName}`;
                    else
                        return v;
                }
            },
            { id: 'dateIssued', editType: 'dateLabel', numeric: false, width: 100, disabledPadding: false, label: 'Date Issued' },

        ];
        let filtered = [];
        for (let j = 0; j < devices.length; j++) {
            let device = devices[j];
            if (!filterDevice(filter, device)) {
                continue;
            }
            filtered.push(device);
        }

        let importExport = <ImportExport name={'devices'}
            exportStart={() => {
                return Promise.resolve(devices);
            }}
            exportRow={(row) => {
                delete row.__v;
                delete row._id;
                delete row._version;
                delete row._index;
                delete row.createDate;
                delete row.updatedAt;
                delete row.patientId;
                delete row.orgId;
            }}
            importStart={() => {
                return Promise.resolve();
            }}
            importRow={(row) => {
                return self.mergeDevice(row);
            }}
            importDone={(err) => {
                if (err) {
                    self.setState({ progress: err.message });
                }
                else {
                    self.saveDevices();
                }
            }}
        />;

        let filterInput = <TextField style={{ margin: 5, width: 500 }}
            value={filter}
            label="Filter"
            onChange={(event) => {
                self.setState({ filter: event.target.value })

            }
            } />



        return (
            <div style={styles.div}>
                {filterInput}
                <EnhancedTable disableMultiSelect={true}
                    getRowStyle={(row, index) => { return row.level >= 3 ? { 'backgroundColor': '#E57373' } : null }}
                    onTable={table => self._types = table}
                    onAdd={() => self.createDevice()}
                    onSelected={(s, t) => self.deviceSelected(s, t)}
                    orderBy='model'
                    order='asc'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={filtered}
                    title='Devices' />
                {importExport}
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    text: {
        margin: 5,
        width: 250
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default DevicesView;
