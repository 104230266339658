import React from 'react';

import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core/';

import SelectOrg from '@apricityhealth/web-common-lib/components/SelectOrg';
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import { 
    importCleanPatients
} from '@apricityhealth/web-common-lib/utils/Services';

class ImportPatientsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: 'Imported',
            lastName: 'Patient',
            fileId: '',
            orgId: '',
            userId: ''
        }
    }

    onImport() {
        const { firstName, lastName, fileId, orgId, userId } = this.state;
        let args = [
            `firstName=${encodeURIComponent(firstName)}`,
            `lastName=${encodeURIComponent(lastName)}`,
            `fileId=${encodeURIComponent(fileId)}`,
        ];
        if ( orgId ) {
            args.push(`orgId=${orgId}`)
        }
        if ( userId ) {
            args.push(`orgId=${userId}`)
        }

        importCleanPatients(this.props.appContext, args).then((result) => {
            this.setState({dialog: <Dialog open={true} fullWidth={true} maxWidth='md'>
                    <DialogTitle>Import Job Created</DialogTitle>
                    <DialogContent>
                        <div><pre>{JSON.stringify(result, null, 2) }</pre></div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose}>Done</Button>
                    </DialogActions>
                </Dialog>});
        }).catch((err) => {
            console.error("importCleanPatients error:", err );
        })
    }

    onCancel() {
        this.props.onClose();
    }

    render() {
        const { dialog, firstName, lastName, fileId, orgId, userId } = this.state;

        return <Dialog open={true}>
            <DialogTitle>Import Scrubbed Patients</DialogTitle>
            <DialogContent>
                <TextField style={styles.text} label='Import ID' value={fileId} onChange={(e) => this.setState({fileId: e.target.value})} />
                <TextField style={styles.text} label='First Name' value={firstName} onChange={(e) => this.setState({firstName: e.target.value})} />
                <TextField style={styles.text} label='Last Name' value={lastName} onChange={(e) => this.setState({lastName: e.target.value })} />
                <SelectOrg appContext={this.props.appContext} orgId={orgId} enableNone={true} onChange={(orgId) => this.setState({orgId})} />
                <SelectUser appContext={this.props.appContext} userId={userId} enableNone={true} onChange={(userId) => this.setState({userId})} />
                {dialog}
            </DialogContent>
            <DialogActions>
                <Button disabled={!fileId} onClick={this.onImport.bind(this)}>Import</Button>
                <Button onClick={this.onCancel.bind(this)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    }
}

const styles = { 
    text: {
        margin: 5,
        width: 300
    }
}
export default ImportPatientsDialog;
