import React, { Component } from 'react';
import Moment from 'moment-timezone';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@material-ui/core/';

import EditOfficeHourDialog from './EditOfficeHourDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';

class OfficeHoursView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeZone: props.timeZone || 'America/Chicago',
            officeHours: props.officeHours || [],
            dialog: null
        }
    }

    componentDidUpdate(oldProps){
        const oldHours = JSON.stringify(oldProps.officeHours)
        const newHours = JSON.stringify(this.props.officeHours)
        if (oldHours !== newHours){
            this.setState({officeHours:this.props.officeHours});
        }
    }

    handleOnAdd = () => {
        const { appContext, appointments, orgId } = this.props;
        const { officeHours, timeZone } = this.state;
        const defaultHours = appointments ? { providerIds: [], categories: [], defaultDuration: 15, hours: { start: 480, end: 1020 }, days: { start: 1, end: 5 }} : 
            { hours: { start: 480, end: 1020 }, days: { start: 1, end: 5 }};
        this.setState({dialog: <EditOfficeHourDialog appContext={appContext} orgId={orgId} appointments={appointments} officeHour={defaultHours} timeZone={timeZone} onDone={(r) => {
            officeHours.push(r);
            this.setState({dialog: null, officeHours});
        }} onCancel={() => {
            this.setState({dialog: null});
        }} />});
    }

    editOfficeHour = (event, index) => {
        const { appContext, appointments, orgId } = this.props;
        const { officeHours, timeZone } = this.state;
        this.setState({dialog: <EditOfficeHourDialog appContext={appContext} orgId={orgId} appointments={appointments} officeHour={officeHours[index]} timeZone={timeZone} onDone={(r) => {
            officeHours[index] = r;
            this.setState({dialog: null, officeHours});
        }} onCancel={() => {
            this.setState({dialog: null});
        }} />});
    }

    deleteOfficeHour(evt, index) {
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        const { officeHours } = this.state;
        officeHours.splice(index, 1);
        this.setState({ officeHours});
    }
    render(){
        const self = this;
        const { appointments } = this.props;
        const { officeHours, dialog } = this.state;

        function appointmentHoursItems() {
            var tableRows = []
            if (Array.isArray(officeHours)){
                officeHours.forEach( (officeHour, index)=> {
                    let row = (
                        <TableRow hover onClick={event => self.editOfficeHour(event, index)} tabIndex={index} key={index}>
                            <TableCell>
                                {officeHour.providerIds.length}
                            </TableCell>
                            <TableCell>
                                {officeHour.categories.join(',')}
                            </TableCell>
                            <TableCell>
                                {officeHour.defaultDuration}
                            </TableCell>
                            <TableCell>
                                {Moment.weekdays(officeHour.days.start)}
                            </TableCell>
                            <TableCell>
                                {Moment.weekdays(officeHour.days.end)}
                            </TableCell>
                            <TableCell>
                                {Moment().startOf('day').add(officeHour.hours.start, "minutes").format("h:mm a")}
                            </TableCell>
                            <TableCell>
                                {Moment().startOf('day').add(officeHour.hours.end, "minutes").format("h:mm a")}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(evt) => self.deleteOfficeHour(evt, index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                }) 
            }
            return tableRows 
        }

        function appointmentHoursTable() {
            return <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Provider Count</TableCell>
                        <TableCell>Categories</TableCell>
                        <TableCell>Default Duration</TableCell>
                        <TableCell>Start Day</TableCell>
                        <TableCell>End Day</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {appointmentHoursItems()}
                </TableBody>
            </Table>
        }

        function officeHourItems() {
            var tableRows = []
            if (Array.isArray(officeHours)){
                officeHours.forEach( (officeHour, index)=> {
                    let row = (
                        <TableRow
                            hover
                            onClick={event => self.editOfficeHour(event, index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                {Moment.weekdays(officeHour.days.start)}
                            </TableCell>
                            <TableCell>
                                {Moment.weekdays(officeHour.days.end)}
                            </TableCell>
                            <TableCell>
                                {Moment().startOf('day').add(officeHour.hours.start, "minutes").format("h:mm a")}
                            </TableCell>
                            <TableCell>
                                {Moment().startOf('day').add(officeHour.hours.end, "minutes").format("h:mm a")}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(evt) => self.deleteOfficeHour(evt, index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                }) 
            }
            return tableRows 
        }

        function officeHoursTable() {
            return <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Start Day</TableCell>
                        <TableCell>End Day</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {officeHourItems()}
                </TableBody>
            </Table>
        }

        return (
            <div>
                {dialog}
                <Paper>
                <EnhancedTableToolbar title={appointments ? 'Appointment Hours' : 'Office Hours'} onAdd={this.handleOnAdd} onDelete={(e) => {}} numSelected={0} />
                {appointments ? appointmentHoursTable() : officeHoursTable()}
                </Paper>
            </div>
        )
    }
}


export default OfficeHoursView;

