import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Paper, IconButton } from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';

import ReactJson from 'react-json-view';

class MetricView extends Component {
    constructor(props){
        super(props)
        this.state = {
            metric: {
                metric:{}
            }
        }
    }
    componentDidMount() {
        console.log("componentDidMount:", this);
        if (this.props.metric)
            this.loadMetric(this.props.metric);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.metric && oldProps.metric._id && this.props.metric._id){
            if (this.props.metric._id !== oldProps.metric._id){
                this.loadMetric(this.props.metric);
            }
        }
    }

    closeMetric() {
        console.log("closeAction()", this);
        this.props.onClose();
    }

    loadMetric(metric) {
        console.log("loadMetric:", metric);
        this.setState({
            metric: metric,
            progress: null,
        });
    }

    render() {
        const self = this;
        const { metric, progress } = self.state;

        let elms = [];

        try {
            console.log(metric);
            elms.push(
                <h4 key="1">Metrics:</h4>,
                <Paper key="2" style={styles.jsonPaper}>
                    <ReactJson src={metric.metrics}
                        collapsed={3} name="metrics" collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
                // <h4 key="3">Event:</h4>,
                // <Paper key="4" style={styles.jsonPaper}>
                //     <ReactJson name="event" src={metric.metrics}
                //         collapsed={3} collapseStringsAfterLength={64} displayDataTypes={false} />
                // </Paper>
            );
        }
        catch (err) {
            elms.push(
                <h4 key="1">Event:</h4>,
                <Paper key="2" style={styles.jsonPaper}>
                    <div>{metric.metrics}</div>
                </Paper>
            );
        }

        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.closeMetric()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Metric Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <h4>Details:</h4>
                    <p>Metric Time: {metric.createDate}</p>
                    <p>Tag: {metric.tag}</p>
                    <p>User ID: {metric.userId}</p>
                    <p>Request ID: {metric.requestId}</p>
                    {elms}
                </div>
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 200
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }
}

export default MetricView;
