import React, { Component } from 'react';

import {
    Drawer,
    CircularProgress,
    TextField,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh'
import ClearIcon from '@material-ui/icons/Clear';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import MetricView from './MetricView';
import Axios from 'axios';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';
import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import {
    getMetrics
} from '@apricityhealth/web-common-lib/utils/Services';

export class MetricsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            requestId: '',
            eventName: '',
            startTime: null,
            endTime: null,
            tag: '',
            key: '',
            value: '',
            limit: 250,
            metrics: [],
            error: '',
            progress: null
        }
    }

    componentWillMount() {
        this.loadMetrics();
    }

    componentWillUnmount() {
        if (this.getArchive) {
            clearInterval(this.getArchive);
            this.getArchive = null;
        }
    }


    loadMetrics() {
        const self = this;
        const { userId, eventName, startTime, endTime, limit, requestId, tag, key, value } = this.state;

        let args = [];
        if (eventName) {
            args.push("EventName=" + encodeURIComponent(eventName));
        }
        if (userId) {
            args.push("userId=" + encodeURIComponent(userId));
        }
        if (requestId) {
            args.push("requestId=" + encodeURIComponent(requestId));
        }
        if (tag) {
            args.push("tag=" + encodeURIComponent(tag));
        }
        if (key) {
            args.push("key=" + encodeURIComponent(key));
        }
        if (value) {
            args.push("value=" + encodeURIComponent(value));
        }
        if (startTime) {
            args.push("startTime=" + startTime.toISOString() );
        }
        if (endTime) {
            args.push("endTime=" + endTime.toISOString() );
        }

        args.push("limit=" + limit);

        if ( this.getArchive ) {
            clearInterval(this.getArchive);
            this.getArchive = null;
        }

        this.setState({ progress: <CircularProgress size={20} />, error: '' });
        getMetrics(this.props.appContext, args).then((result) => {
            if (result.archiveJobId) {
                let jobId = result.archiveJobId;
                this.setState({ metrics: result.metrics, progress: null, error: 'Waiting for archived metrics...' });
                this.getArchive = setInterval(() => {
                    const getArchiveJob = {
                        url: Config.baseUrl + `${Config.pathPrefix}logging/job/${jobId}`,
                        method: 'GET',
                        headers: { "Authorization": this.props.appContext.state.idToken }
                    };

                    console.log("getArchiveJob request:", getArchiveJob);
                    Axios(getArchiveJob).then((result) => {
                        let job = result.data;
                        console.log("getArchiveJob result:", job);
                        if (job.result && job.result.metrics) {
                            let { metrics } = job.result;
                            if (metrics.length > 0) {
                                for (let i = 0; i < metrics.length; ++i)
                                    metrics[i]._id = `archive${i}`;

                                metrics = this.state.metrics.concat(metrics);
                                this.setState({ error: '', metrics });
                            }
                            else {
                                // no logs from the achive, so just clear the rror 
                                this.setState({ error: '' });
                            }
                            clearInterval(this.getArchive);
                            this.getArchive = null;
                        }
                    }).catch((err) => {
                        console.error("getArchiveJob error:", err);
                        self.setState({ error: getErrorMessage(err) });
                        clearInterval(this.getArchive);
                        this.getArchive = null;
                    })

                }, 5000);
            } else {
                this.setState({ metrics: result.metrics, progress: null });
            }

        }).catch((error) => {
            console.log("getMetrics error:", error.response);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }


    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadMetrics();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    onPointClick(event) {
        const { appContext } = this.state;
        const dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <MetricView appContext={appContext} metric={event.point.data} onClose={() => this.onCloseDialog()} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
        console.log('Click Event:', event);
    }

    onPointHover(event) {
        //console.log('Hover Event:', event);
    }


    metricSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const metric = self.state.metrics[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <MetricView appContext={appContext} metric={metric} onClose={() => self.onCloseDialog()} />
            </Drawer>;
            self.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            self.setState({ progress: null, dialog: null });
        }
    }

    render() {
        const self = this;
        const { userId, eventName, requestId, startTime, endTime, tag, metrics, key, value, dialog, progress, limit, error } = this.state;

        const columnData = [
            { id: 'createDate',  editType: 'dateLabel', numeric: false, disabledPadding: false, label: 'Time' },
            { id: 'tag', numeric: false, disabledPadding: false, label: 'Tag' },
            // { id: 'metrics', numeric: false, width: 500, disabledPadding: false, label: 'Metric', formatValue: (v) => {
            //     return 'Duration: ' + v.metrics.Duration } },
            { id: 'userId', numeric: false, disabledPadding: false, label: 'User ID', formatValue: (v,row) => {
                return <User appContext={this.props.appContext} userId={v} />;
            } },
            { id: 'requestId', numeric: false, disabledPadding: false, label: 'Request ID' },
            { id: 'metrics', label: 'Duration', formatValue: (v, row) => {
                if ( Array.isArray(v) ) {
                    for(let i=0;i<v.length;++i) {
                        if ( v[i].Duration !== undefined )
                            return v[i].Duration;
                        else if ( v[i].duration !== undefined )
                            return v[i].duration;
                    }
                }
                return '';
            }},
            { id: 'metrics', label: 'Event Name', formatValue: (v, row) => {
                if ( Array.isArray(v) ) {
                    for(let i=0;i<v.length;++i) {
                        if ( v[i].EventName !== undefined )
                            return v[i].EventName;
                        else if ( v[i].eventname !== undefined ) 
                            return v[i].eventname;
                    }
                }
                return '';
            }}
        ];

        let startEndTimes = <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                style={styles.dateTimePicker}
                ampm={false}
                label="Start Time"
                value={startTime}
                onChange={(date) => this.setState({startTime: date}) }
            />
            <DateTimePicker
                style={styles.dateTimePicker}
                ampm={false}
                label="End Time"
                value={endTime}
                onChange={(date) => this.setState({endTime: date} )}
            />
        </MuiPickersUtilsProvider>

        return <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td>
                        <TextField style={styles.limit} type='number' label="Limit" value={limit}
                            onChange={(e) => { self.setState({ limit: e.target.value }) }} />
                        <TextField style={styles.text} label="Event Name" value={eventName}
                            onChange={(e) => { self.setState({ eventName: e.target.value }) }} />
                        {startEndTimes}
                        <TextField style={styles.text} label="User ID" value={userId}
                            onChange={(e) => { self.setState({ userId: e.target.value }) }} />
                        <TextField style={styles.text} label="Request ID" value={requestId}
                            onChange={(e) => { self.setState({ requestId: e.target.value }) }} />
                        <TextField style={styles.text} label="Tag" value={tag}
                            onChange={(e) => { self.setState({ tag: e.target.value }) }} />
                        <TextField style={styles.text} label="Key" value={key}
                            onChange={(e) => { self.setState({ key: e.target.value }) }} />
                        <TextField style={styles.text} label="Value" value={value}
                            onChange={(e) => { self.setState({ value: e.target.value }) }} />
                        <IconButton onClick={() => {
                            self.setState({ error: '', eventName: '', userId: '', requestId: '', tag: '', key: '', value: '', startTime: null, endTime: null });
                            if (this.getArchive) {
                                clearInterval(this.getArchive);
                                this.getArchive = null;
                            }
                        }}><ClearIcon /></IconButton>
                    </td>
                    <td align='right'>
                        <span style={{color: 'red'}}>{error}</span>
                        <IconButton disabled={progress !== null} onClick={this.loadMetrics.bind(this)}>
                            {progress || <RefreshIcon />}
                        </IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <EnhancedTable disableMultiSelect={true}
                            getRowStyle={(row, index) => { return row.level >= 3 ? { 'backgroundColor': '#E57373' } : null }}
                            disableActions={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.metricSelected(s, t)}
                            orderBy='createDate'
                            order='desc'
                            rowsPerPage={25}
                            columnData={columnData}
                            data={metrics}
                            title='Metrics' />
                        {dialog}
                    </td>
                </tr>
            </tbody>
        </table>
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    limit: {
        margin: 5,
        width: 100
    },
    text: {
        margin: 5,
        width: 250
    },
    dateTimePicker: {
        margin: 5,
        width: 130
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    }
}

export default MetricsView;
