import React from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField
} from '@material-ui/core/';

import Moment from 'moment-timezone';
import SelectProvider from "@apricityhealth/web-common-lib/components/SelectProvider";

export function getDayItems() {
    var dayItems = []
    for (let i = 0; i < 7; i++) {
        dayItems.push(<MenuItem key={i} value={i}>{Moment.weekdays(i)}</MenuItem>);
    }
    return dayItems
}

export function getDayPicker(label, value, onChange) {
    return <FormControl style={{margin: 5, width: 200}}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={onChange}>{getDayItems()}</Select>
    </FormControl>;
}

export function getTimePicker(label, value, timeZone, onChange ) {
    let defaultValue = Moment().tz(timeZone).startOf('day').add(value,'minutes').format("HH:mm");
    return <TextField
        style={{margin: 5, width: 150}}
        label={label}
        type="time"
        defaultValue={defaultValue}
        InputLabelProps={{
            shrink: true,
        }}
        inputProps={{
            step: 300, // 5 min
        }}
        onChange={(e) => {
            let parts = e.target.value.split(':');
            let minutes = (Number(parts[0]) * 60) + Number(parts[1]);
            onChange(minutes);
        }}
    />;
}

class EditOfficeHourDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            officeHour: JSON.parse(JSON.stringify(props.officeHour)),
            timeZone: props.timeZone,
            error: null
        };
    }

    componentDidUpdate(oldProps){
        const oldHours = JSON.stringify(oldProps.officeHours)
        const newHours = JSON.stringify(this.props.officeHours)
        if (oldHours !== newHours){
            this.setState({officeHours: JSON.parse(JSON.stringify(this.props.officeHours)) });
        }
    }

    onChangeDay = type => e => {
        let { officeHour } = this.state;
        officeHour.days[type] = e.target.value;
        this.setState({ officeHour });
    }
    onChangeHour = type => e => {
        let { officeHour } = this.state;
        officeHour.hours[type] = e.target.value*60;
        this.setState({ officeHour });
    }

    render() {
        const self = this;
        const { appContext, appointments, orgId } = this.props;
        const { officeHour, timeZone, error } = this.state;

    
        function getAppointmentFields() {
            if (! appointments ) return null;
            return <div>
                <SelectProvider appContext={appContext} orgId={orgId} isMulti={true} providerId={officeHour.providerIds} onChange={(e) => {
                    console.log("SelectProvider.onChange:", e);
                    officeHour.providerIds = e;
                    self.setState({officeHour});
                }} />
                <br />
                <FormControl style={{margin: 5, width: 300}}>
                    <InputLabel>Categories</InputLabel>
                    <Select multiple={true} value={officeHour.categories} onChange={(e) => {
                        console.log("Select Category:", e.target.value );
                        officeHour.categories = e.target.value;
                        self.setState({officeHour});
                    }}>
                        <MenuItem value='teleMedicine'>TeleMedicine</MenuItem>
                        <MenuItem value='phone'>Phone</MenuItem>
                        <MenuItem value='inPerson'>Office Visit</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField style={{margin: 5, width: 200}} label='Default Duration (min)' value={officeHour.defaultDuration} onChange={(e) => {
                    officeHour.defaultDuration = e.target.value;
                    self.setState({officeHour});
                }} type='number' />
            </div>;
        }

        return <Dialog open={true}>
            <DialogTitle>{appointments ? 'Edit Appointment Hours' : 'Edit Office Hours'}</DialogTitle>
            <DialogContent>
                {getAppointmentFields()}
                {getDayPicker("Start Day", officeHour.days.start, (e) => {
                    officeHour.days.start = e.target.value;
                    this.setState({officeHour});
                })}
                <br />
                {getDayPicker("End Day", officeHour.days.end, (e) => {
                    officeHour.days.end = e.target.value;
                    this.setState({officeHour});
                })}
                <br />
                {getTimePicker("Start Time", officeHour.hours.start, timeZone, (e) => {
                    officeHour.hours.start = e;
                    this.setState({officeHour});
                })}
                <br />
                {getTimePicker("End Time", officeHour.hours.end, timeZone, (e) => {
                    officeHour.hours.end = e;
                    this.setState({officeHour});
                })}
                <br />
                <span style={{color: 'red'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
                <Button variant="contained" disabled={error !== null} style={styles.button} onClick={() => this.props.onDone(officeHour) }>Done</Button>
            </DialogActions>
        </Dialog>
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
    linearColorPrimary: {
        backgroundColor: '#b2dfdb',
    },
    linearBarColorPrimary: {
        backgroundColor: '#00695c',
    },
};

EditOfficeHourDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditOfficeHourDialog);
