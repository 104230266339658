import { red, green, orange, yellow } from "@material-ui/core/colors"
const styles = ({ palette, mixins, shadows }) => ({
    root: {
        backgroundColor: palette.background.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        "& .red": {
            color: red["A700"],
            textAlign: "center",
        },
        "& .orange": {
            backgroundColor: orange["A700"],
            color: "#ffff",
            textAlign: "center",
        },
        "& .yellow": {
            backgroundColor: yellow["A700"],
            textAlign: "center",
        },
        "& .green": {
            color: green["800"],
            textAlign: "center",
        },
        "& .fa-list:hover": {
            color: orange["A700"],
        },
        "& .fa-question:hover": {
            color: orange["A700"],
        },
        "& .fa-comment-sms:hover": {
            color: orange["A700"],
        },
        "& .fa-comment-check:hover": {
            color: orange["A700"],
        },
        "& .fa-circle-radiation:hover": {
            color: orange["A700"],
        },
        "& .fa-comment-question:hover": {
            color: orange["A700"],
        },
        "& .fa-user-doctor-message:hover": {
            color: orange["A700"],
        },
    },
    actions: {
        backgroundColor: palette.background.paper,
    },
    box: {
        display: 'flex',
        gap: '1rem',
        overflowX: 'hidden',
        boxShadow: shadows[1],
        border: '0.5px solid',
        borderColor: palette.divider,
        padding: '1rem',
        minHeight: '80vh',
        backgroundColor: palette?.background?.paper,
        borderRadius: mixins?.borderRadius,
        flex: 1,
        width: '100%',
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    tab: {
        "& .MuiTab-wrapper": {
            color: "red",
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },

    noBreak: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        whiteSpace: "nowrap", //this ensures fill to fit the content
    },
    search: {
        display: "flex",
        // width: '100%',
        alignItems: "flex-start",
    },
    labCheck: {
      marginLeft: 10
    },
    refresh: {
        textAlign: "right",
    },
    jsonPaper: {
        padding: '0.2rem',
        width: '100%',
        maxWidth: '50rem',
        flexGrow: 1,
    },
    drawerPaper: {
        width: '45rem',
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        alignContent: "center",
        overflow: "auto"
    },
    limit: {
        margin: '0.5rem',
        width: '6rem'
    },
    validationLimit: {
        margin: '0.5rem',
        width: '10rem'
    },
    validationText: {
        margin: '0.5rem',
        width: '28rem'
    },
    tag: {
        margin: '0.5rem',
        width: '10rem'
    },
    text: {
        margin: '0.5rem',
        width: '20rem'
    },
    table: {
        width: '100%',
    },
    warningSign: {
        color: red["A700"],
        width: '20px',
        height: '20px'
    },
    warning: {
        color: red["A700"],
    },
    right: {
        justifyContent: "flex-end",
        display: "flex",
        width: "100%"
    },
    note: {
        margin: '1rem',
        maxWidth: '95%',
        wordWrap: "normal"
    },
    labAlert: {
        color: 'red'
    },
    alertLevelSelect: {
        width: '30rem',
    },
    noBulletList: {
        padding: 0,
        margin: 0,
        display: "flex",
        paddingBottom: "5px",
        flexDirection: "column"
    },
    icon: {
        width: '1rem',
    },
    smallIcon: {
        width: '0.5rem',
        marginLeft: "5px",
        marginRight: "5px"
    },
    link: {
        width: '1.8rem',
        paddingLeft: "5px",
    },
    smallLink: {
        width: '1rem',
        paddingLeft: "5px",
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: '5px'
    },
    story: {
        display: "flex",
        justifyContent: "flex-end",
        overflow: 'scroll',
        height: "50rem",
        backgroundColor: "#fbf9f6"
    },
    labLink: {
        color: "black",
        verticalAlign: "super",
        fontSize: "0.2rem",
        marginLeft: "2px",
        fontWeight: "bold"
    },
    labLinkSameRow: {
        display: 'flex',
        color: "black",
        verticalAlign: "super",
        fontSize: "0.2rem",
        marginLeft: "2px",
        fontWeight: "bold"
    },
    symptomChange: {
        color: "blue",
     
    },
    legend: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    legendItem: {
        // display: "flex",
        // justifyContent: "flex-start",
     
        margin: "4px",
    },
    legendText: {
        marginLeft: "5px",
    },
    model: {
        display: 'flex',
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        flexDirection: "row",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    symptom: {
        display: 'flex',
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "stretch",
        paddingLeft: "20px",
        width: "100%",
    },
    singleLineName: {
        display: 'flex',
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
    },
    hr: {
        height: "10px",
        backgroundColor: "#b5d8fb",
        border: "none",
    },
    report: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: '70vh',
        alignContent: 'center',
        alignItems: 'center'
    },
})

export default styles
