
import React, { useState, useEffect } from 'react';
import {
    useLocation
} from "react-router-dom";

import {
    Tabs,
    Tab,
    withStyles,
    CircularProgress
} from '@material-ui/core/';
import styles from './RecentActivityPage.styles'
import moment from 'moment';
import AlertsPage from './AlertsPage';
import LabsPage from './LabsPage';
import JournalsPage from './JournalsPage';
import NotesPage from './NotesPage';
import PatientReportPage from './PatientReportPage'
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import AppointmentsPage from './AppointmentsPage';
import PatientStory from './PatientStory';
import MessagesPage from './MessagesPage'
import {
    loadPlans,
    loadDataTypes,
    loadModels,
    getAlertLevels,
    loadDetectModels,
    loadQuestions,
    isArrayValid,
} from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function RecentActivityPage({ classes, appContext }) {
    let location = useLocation();
    let pId = location?.state?.patientId || "*"
    let selectedAlert = location?.state?.selectedAlert
    const urlPath = location?.pathname?.slice(1).split('/');
    if (urlPath && urlPath.length > 1)
        if (urlPath[1].length === 36) //valid UUID
            pId = urlPath[1];

    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [patientId, setPatientId] = useState(pId);
    const [planTypes, setPlanTypes] = useState([]);
    const [dataTypes, setDataTypes] = useState([]);
    const [modelTypes, setModelTypes] = useState([]);
    const [alertLevels, setAlertLevels] = useState([]);
    const [detectModels, setDetectModels] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [patient, setPatient] = useState(null);
    const tabs = [
        <AlertsPage patientId={patientId} planTypes={planTypes} dataTypes={dataTypes} modelTypes={modelTypes} alertLevels={alertLevels} detectModels={detectModels} questionTypes={questionTypes} appContext={appContext} />,
        <JournalsPage patientId={patientId} appContext={appContext} />,
        <LabsPage patientId={patientId} appContext={appContext} />,
        <AppointmentsPage patientId={patientId} appContext={appContext} />,
        <NotesPage patientId={patientId} appContext={appContext} />,
        <MessagesPage patientId={patientId} appContext={appContext} />,
        <PatientReportPage appContext={appContext} />,
        <PatientStory patientId={patientId} selectedAlert={selectedAlert} planTypes={planTypes} dataTypes={dataTypes} modelTypes={modelTypes} detectModels={detectModels} questionTypes={questionTypes} alertLevels={alertLevels} appContext={appContext} loading={loading} />
    ];


    useEffect(() => {
        setPatientId(pId);
        if (location?.state?.tab)
            setTab(Number(location.state.tab));
        window.history.replaceState({}, document.title) //we do this so refreshes won't keep loading the patient passed in.
    }, [location, pId])

    useEffect(() => {
        internalFetchTypes();

        return () => {
            setPatient(null)
        }
    }, [])

    async function internalFetchTypes() {
        setLoading(true)
        try {

            let [planTypes, dataTypes, modelTypes, alertLevels, detectModels, questionTypes] = await Promise.all([
                loadPlans(appContext, "*"),
                loadDataTypes(appContext, { dataId: "*", planId: "*", dependencies: true, resolveConflicts: false }),
                loadModels(appContext, { planId: "*", dependencies: false, resolveConflicts: false }),
                getAlertLevels(appContext, { dependencies: false, planId: "*", resolveConflicts: false }),
                loadDetectModels(appContext, { planId: "*", dependencies: true, resolveConflicts: true }),
                loadQuestions(appContext, { planId: "*", dependencies: true, resolveConflicts: true }),
            ]);
            setDataTypes(dataTypes);
            setModelTypes(modelTypes);
            setAlertLevels(alertLevels)
            setPlanTypes(planTypes);
            setDetectModels(detectModels);
            setQuestionTypes(questionTypes);
        } catch (error) {
            setError(getErrorMessage(error));
            console.error(`Error fetching type data`, getErrorMessage(error))
        }
        setLoading(false)
    }

    let patientPlans = patient && isArrayValid(patient.plans) && isArrayValid(planTypes) ?
        patient.plans.map((p) => {
            let plan = planTypes.find((plan) => plan.planId === p.planId);
            return plan ? `${plan.title} (start: ${p.startDate ? moment(p.startDate).format("ll") : "N/A"})` : ""
        }) : null;

    console.debug(`Recent activity `, patientId, patient)
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.search}>
                    <div>
                        <SelectPatient
                            appContext={appContext}
                            style={{ width: "350px", }}
                            patientId={patientId}
                            fullPatientInfo={true}
                            disabled={loading}
                            enableAll={true}
                            onChange={(patient) => {
                                if ( patient && patient.patientId !== patientId ) {
                                    console.log(`Displaying patient ${patientId}`);
                                    setPatientId(patient.patientId);
                                    setPatient(patient)
                                } else if (!patient && patientId !== '*') {
                                    console.log("Displaying ALL patients");
                                    setPatientId('*');
                                    setPatient(null);
                                }
                            }}
                        />
                        {patient && <>
                            <div style={{ marginTop: "5px", width: "100%" }}>Patient's Plans: {patientPlans && patientPlans.join(", ")}</div>
                            <div style={{ marginTop: "5px", width: "100%" }}>Patient's Time Zone: {patient?.timezone}</div>
                        </>
                        }
                    </div>
                    {loading && <CircularProgress size={22} />}
                </div>
                {error}
                <Tabs
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    value={tab}
                    onChange={(e, t) => setTab(t)}>
                    <Tab label="Alerts" />
                    <Tab label="Journals" />
                    <Tab label="Labs" />
                    <Tab label="Appointments" />
                    <Tab label="Notes" />
                    <Tab label="Messages" />
                    <Tab label="Patient Report Access" />
                    <Tab label="Patient Story" />
                </Tabs>

                <div style={{ flexGrow: 1, width: "100%", paddingRight: "2rem" }}>
                    {tabs[tab]}
                </div>
            </div>
        </div >
    )
}




export default withStyles(styles)(RecentActivityPage)