import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';
import Config from '@apricityhealth/web-common-lib/Config';
import TestView from './TestView';
import TestCreatorView from './TestCreatorView';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';

import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    TextField,
    IconButton,
    Tooltip,
    Paper,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core/';

import Axios from 'axios';

import RefreshIcon from '@material-ui/icons/Refresh';
import RunTestsIcon from '@material-ui/icons/FastForward';
import RunTestsDisableDeleteIcon from '@material-ui/icons/FastForwardOutlined';
import ReportIcon from '@material-ui/icons/Report';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

const fileDownload = require('js-file-download');

function testFilter(filter, test) {
    if (filter === "" || filter === "*") return true;
    if (test) {
        filter = filter.toUpperCase();
        if (test.name && test.name.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (test.description && test.description.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (Array.isArray(test.tags)) {
            for (var i = 0; i < test.tags.length; ++i) {
                if (test.tags[i].indexOf(filter) >= 0)
                    return true;
            }
        }
    }
    return false;
}

export class TestsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'creators',
            creators: [],
            tests: [],
            filter: '',
            progress: null,
            report_progress: null,
            test_progress: null,
            dialog: null,
            download: null,
            upload: null,
            disableDelete: false,
            plan: props.appContext.state.plan,
            maxPermutations: 200
        }
    }

    componentWillMount() {
        this.loadContent();
    }

    loadContent() {
        this.loadTests();
        this.loadTestCreators();
    }

    loadTests() {
        let { plan } = this.state
        const { appContext } = this.props;
        if (plan) {
            const getTests = {
                url: Config.baseUrl + `${Config.pathPrefix}test/${plan.planId}?errors=true&limit=0`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            }

            console.log("getTests:", getTests);
            this.setState({ progress: <CircularProgress size={20} />, error: null, testResults: '' });
            Axios(getTests).then((response) => {
                console.log("getTests response:", response);
                let tests = response.data.tests;
                this.setState({ tests, progress: null }, this.updateFilter.bind(this));
            }).catch((err) => {
                console.log("getTests error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else {
            this.setState({ tests: [] });
        }
    }

    loadTestCreators() {
        let { plan } = this.state
        const { appContext } = this.props;
        if (plan) {
            const getTestCreators = {
                url: Config.baseUrl + `${Config.pathPrefix}test/creator/${plan.planId}/*`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            }

            console.log("getTestCreators:", getTestCreators);
            this.setState({ progress: <CircularProgress size={20} />, error: null, testResults: '' });
            Axios(getTestCreators).then((response) => {
                console.log("getTestCreators response:", response);
                let creators = response.data;
                this.setState({ creators, progress: null });
            }).catch((err) => {
                console.log("getTestCreators error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else {
            this.setState({ creators: [] });
        }
    }

    onGeneratePermutations() {
        const { plan, maxPermutations } = this.state
        const { appContext } = this.props;
        if (plan) {

            const dialog = <Dialog open={true}>
                <DialogTitle>Generate Permuations</DialogTitle>
                <DialogContent>{`Please confirm you would like to generate permutations for ${plan.title}?`}
                    <br />
                    <br />
                    <TextField label='Max Permuations' type='number' value={maxPermutations} onChange={(e) => {
                        this.setState({maxPermutations: e.target.value}, this.onGeneratePermutations.bind(this) );
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({dialog: null})}>Cancel</Button>
                    <Button onClick={() => {
                        const generatePermutations = {
                            url: Config.baseUrl + `${Config.pathPrefix}test/generatePermutations?async=true`,
                            method: 'POST',
                            headers: { "Authorization": appContext.state.idToken },
                            data: {
                                planId: plan.planId,
                                maxPermutations
                            }
                        }
            
                        console.log("generatePermuations request:", generatePermutations);
                        this.setState({ progress: <CircularProgress size={20} />, error: null, dialog: null });
                        Axios(generatePermutations).then((response) => {
                            console.log("generatePermuations response:", response.data);
                            this.setState({ progress: null });
                        }).catch((err) => {
                            console.log("generatePermutations error:", err);
                            this.setState({ progress: null, error: getErrorMessage(err) });
                        });
            
                    }}>Confirm</Button>
                </DialogActions>
            </Dialog>;

            this.setState({dialog});
        }
        else {
            this.setState({ creators: [] });
        }
    }

    updateFilter() {
        let { filter, tests } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < tests.length; ++i) {
                if (testFilter(filter, tests[i]))
                    filtered.push(tests[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onGenerateTestReport() {
        const { appContext } = this.props;

        const recipe = 'xlsx';
        let data = {
            reportName: "TestResults",
            recipe,
        }

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
            data,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken }
        };

        this.setState({ report_progress: <CircularProgress size={20} />, error: null });

        console.log("getReport:", request);
        Axios(request).then((response) => {
            console.log("getReport response:", response);
            let report = response.data;
            report = Buffer.from(report, 'base64');
            fileDownload(report, `testresults.${recipe}`);
            this.setState({ report_progress: null, report });
        }).catch((error) => {
            console.log("getReport error:", error.response);
            this.setState({ report_progress: null, error: error.message });
        });
    }


    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._table)
            this._table.setState({ selected: [] });
        if (this._creator_table)
            this._creator_table.setState({ selected: [] });
    }

    onTestSelected(s, t) {
        const { appContext } = this.props;
        if (s.length > 0) {
            const test = this.state.filtered ? this.state.filtered[s[0]] : this.state.tests[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <TestView appContext={appContext} planId={test.planId} testId={test.testId} onClose={() => this.onCloseDialog()} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    onCreateTest() {
        const { plan } = this.state;
        const { appContext } = this.props;
        if (!plan)
            return alert("Plan must be selected first.");
        const newTest = { planId: plan.planId, testId: '', flags: [], answers: [], data: [], recommendations: [] };
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <TestView appContext={appContext} test={newTest} onClose={() => this.onCloseDialog()} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    onRunTests(disableDelete = false) {
        const { appContext } = this.props;
        const { plan } = this.state;
        if (!plan)
            return alert("Plan must be selected first.");
        const runTests = {
            url: Config.baseUrl + `${Config.pathPrefix}test/run/${plan.planId}?disableDelete=${disableDelete}`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        }

        console.log("runTests:", runTests);
        this.setState({ test_progress: <CircularProgress size={20} />, error: null });
        Axios(runTests).then((response) => {
            console.log("runTests response:", response);
            this.setState({ test_progress: null, error: response.data.status }, this.loadContent(plan.planId));
        }).catch((err) => {
            console.log("runTests error:", err);
            this.setState({ test_progress: null, error: err.message });
        });
    }

    onSelectTestCreator(s,t) {
        const { appContext } = this.props;
        if (s.length > 0) {
            const testCreator = t.state.data[s[0]]; //this.state.filtered ? this.state.filtered[s[0]] : this.state.tests[s[0]];
            const dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <TestCreatorView appContext={appContext} testCreator={testCreator} planId={testCreator.planId} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }

    }

    onRunCreators() {
        const { appContext } = this.props;
        const { plan: { planId } } = this.state;

        const runTestCreators = {
            url: Config.baseUrl + `${Config.pathPrefix}test/creator/run`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: {
                planId,
                creatorId: '*',
                modelId: '*'
            }
        };


        console.log("runTestCreators:", runTestCreators);
        this.setState({ test_progress: <CircularProgress size={20} />, error: null });
        Axios(runTestCreators).then((response) => {
            console.log("runTestCreators response:", response.data );
            this.setState({ test_progress: null });
        }).catch((err) => {
            console.log("runTestCreators error:", err);
            this.setState({ test_progress: null, error: getErrorMessage(err) });
        });

    }

    onCreateTestCreator() {
        console.log("onCreateTestCreator");
        const { plan } = this.state;
        if (!plan)
            return alert("Plan must be selected first.");
        const { appContext } = this.props;
        this.setState({ dialog: <Drawer variant="persistent" anchor="right" open={true}>
            <TestCreatorView appContext={appContext} onClose={this.onCloseDialog.bind(this)} planId={plan.planId} />
        </Drawer> });
    }

    render() {
        const { appContext } = this.props;
        const { tab, dialog, progress } = this.state;

        let tabView = null;
        if ( tab === 'tests' ) {
            let { plan } = this.state;
            const { filter, test_progress, report_progress, error,
                tests, filtered } = this.state;

            const testColumns = [
                { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
                { id: 'lastResult', numeric: false, disabledPadding: false, label: 'Last Result' },
                { id: 'jiraIssue', numeric: false, disabledPadding: false, label: 'Jira' },
                { id: 'results', numeric: true, disabledPadding: false, label: 'Results' },
                { id: 'failures', numeric: true, disabledPadding: false, label: 'Failures' },
                { id: 'errors', numeric: false, disabledPadding: false, label: 'Errors', formatValue: (v, r) => { return v > 0 ? "Yes" : "No" } },
                { id: 'case', numeric: false, disabledPadding: false, label: 'Test Case', formatValue: (v, r) => { return typeof v === 'object' ? "Yes" : "No" } },
            ];

            let testsTable = <EnhancedTable disableMultiSelect={true}
                onTable={table => this._table = table}
                onSelected={(s, t) => this.onTestSelected(s, t)}
                onAdd={() => this.onCreateTest()}
                getRowStyle={(row, index) => { return row.errors > 0 ? { 'backgroundColor': '#E57373' } : null }}
                orderBy='name'
                rowsPerPage={25}
                columnData={testColumns}
                data={filtered ? filtered : tests}
                title='Tests' />;

            let importExport = plan && <ImportExport name={'tests'}
                exportStart={() => {
                    return new Promise((resolve, reject) => {
                        let promises = [];
                        for (let i = 0; i < tests.length; ++i) {
                            const testId = tests[i].testId;
                            const getTest = {
                                url: Config.baseUrl + `${Config.pathPrefix}test/${plan.planId}/${testId}`,
                                method: 'GET',
                                headers: { "Authorization": this.props.appContext.state.idToken }
                            };

                            promises.push(Axios(getTest));
                        }

                        Promise.all(promises).then((results) => {
                            console.log("Promise results:", results);
                            let rows = [];
                            for (let i = 0; i < results.length; ++i) {
                                rows.push(results[i].data.test);
                            }

                            resolve(rows);
                        }).catch((err) => {
                            console.log("Promise error:", err);
                            reject(err);
                        });
                    })
                }}
                exportRow={(row) => {
                    delete row.__v;
                    delete row._id;
                    delete row._version;
                    delete row._index;
                    delete row.createDate;
                    delete row.updatedAt;
                    delete row.planId;
                    delete row.ownerId;
                    delete row.errors;
                }}
                importStart={() => {
                    this.setState({ progress: <CircularProgress /> });
                    const deleteTests = {
                        url: Config.baseUrl + `${Config.pathPrefix}test/${plan.planId}`,
                        method: 'DELETE',
                        headers: { "Authorization": appContext.state.idToken }
                    }
                    console.log("deleteTests:", deleteTests);
                    return Axios(deleteTests);
                }}
                importRows={(rows) => {
                    const addTest = {
                        url: Config.baseUrl + `${Config.pathPrefix}test/${plan.planId}`,
                        method: 'POST',
                        headers: { "Authorization": appContext.state.idToken },
                        data: rows
                    };

                    console.log("addTest:", addTest);
                    return Axios(addTest);
                }}
                importDone={(err) => {
                    if (err) {
                        this.setState({ progress: err.message });
                    }
                    else {
                        this.setState({ progress: null }, this.loadContent.bind(this));
                    }
                }}
            />;

            tabView = <table style={{ width: '100%' }}><tbody>
                <tr><td valign='bottom'>
                    <SelectPlan style={{ margin: 20 }} appContext={appContext}
                        allPlans={true}
                        planId={this.state.plan ? this.state.plan.planId : ''}
                        onChange={(plan) => {
                            if (plan) {
                                appContext.setState({ plan: plan })
                                this.setState({ plan: plan }, this.loadContent.bind(this));
                            }
                        }} />
                    <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                        onChange={(e) => { this.setState({ filter: e.target.value }, this.updateFilter.bind(this)); }} />
                </td><td align='right'>
                        {error}
                        <Tooltip title='Refresh'><IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>
                            {progress ? progress : <RefreshIcon />}</IconButton></Tooltip>
                        <Tooltip title='Run All Tests'><IconButton disabled={test_progress !== null} onClick={this.onRunTests.bind(this, false)}>
                            {test_progress ? test_progress : <RunTestsIcon />}</IconButton></Tooltip>
                        <Tooltip title='Run All Tests, Keep Patients'><IconButton disabled={test_progress !== null} onClick={this.onRunTests.bind(this, true)}>
                            {test_progress ? test_progress : <RunTestsDisableDeleteIcon />}</IconButton></Tooltip>
                        <Tooltip title='Test Report'><IconButton disabled={report_progress !== null} onClick={this.onGenerateTestReport.bind(this)}>
                            {report_progress ? report_progress : <ReportIcon />}</IconButton></Tooltip>
                        {importExport}
                    </td></tr>
                <tr><td colSpan={2}>
                    {testsTable}
                </td></tr>
            </tbody></table>;
        } else if ( tab === 'creators' ) {
            const { creators, error, test_progress } = this.state;

            const columns = [
                { id: 'creatorId', label: 'Creator ID' },
                { id: 'modelId', label: 'Model ID' },
                { id: 'category', label: 'Category' },
                { id: 'jiraTicket', label: 'JIRA Ticket' },
                { id: 'limit', label: 'Limit', numeric: true }
            ];

            let table = <EnhancedTable disableMultiSelect={true}
                onTable={table => this._creator_table = table}
                onSelected={this.onSelectTestCreator.bind(this)}
                onAdd={this.onCreateTestCreator.bind(this)}
                orderBy='creatorId'
                columnData={columns}
                data={creators}
                title='Test Creators' />;

            tabView = <table style={{ width: '100%' }}><tbody>
                <tr><td valign='bottom'>
                    <SelectPlan style={{ margin: 20 }} 
                        appContext={appContext}
                        planId={this.state.plan ? this.state.plan.planId : ''}
                        allPlans={true}
                        onChange={(plan) => {
                            if (plan) {
                                appContext.setState({ plan: plan })
                                this.setState({ plan: plan }, this.loadContent.bind(this));
                            }
                        }} />
                </td><td align='right'>
                        {error}
                        <Tooltip title='Refresh'><IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>
                            {progress ? progress : <RefreshIcon />}</IconButton></Tooltip>
                        <Tooltip title='Run All Creators'><IconButton disabled={test_progress !== null} onClick={this.onRunCreators.bind(this, false)}>
                            {test_progress ? test_progress : <RunTestsIcon />}</IconButton></Tooltip>
                        <Tooltip title='Generate Permutations'><IconButton disabled={progress !== null} onClick={this.onGeneratePermutations.bind(this)}>
                            {progress ? progress : <ReportIcon />}</IconButton></Tooltip>
                    </td></tr>
                <tr><td colSpan={2}>
                    {table}
                </td></tr>
            </tbody></table>;
        }

        return <Paper square>
            <Tabs style={{ width: '100%' }} value={tab}
                variant='scrollable' scrollButtons='on'
                indicatorColor='primary' textColor='primary'
                onChange={(e,tab) => this.setState({tab})}
            >
                <Tab label='Test Creators' value='creators' />
                <Tab label='Tests' value='tests' />
            </Tabs>
            {tabView}
            {dialog}
        </Paper>;

    }
}

export default TestsView;
