import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
} from '@material-ui/core/';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import {
    loadActivities,
    getAccount
} from '@apricityhealth/web-common-lib/utils/Services';
import DataTypeInstanceView from '../DataTypeInstanceView';
import Patient from '@apricityhealth/web-common-lib/components/Patient';

export class ProviderActivityView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.providerId !== prevProps.providerId) || (this.props.userId !== prevProps.userId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;

        let { appContext, args, userId, providerId } = this.props;
        if (userId) {
            if (!args) args = [];
            self.setState({ progress: <CircularProgress /> });

            getAccount(appContext, userId).then((account) => {
                console.debug(`Account `, account)
                return Promise.all([
                    loadActivities(appContext, { username: account.email || account.phone } ),
                    loadActivities(appContext, { providerId } ),
                    loadActivities(appContext, { userId: account.username } )
                ]);
            }).then((content) => {
                content = [].concat(...content) // merge all teh arrays
                content = [...new Map(content.map((m) => [m._id, m])).values()]
                self.setState({ content, progress: null }, self.updateTab.bind(self));
            }).catch((error) => {
                self.setState({ progress: error.message });
            });
        }
    }

    updateTab() {
        const self = this;
        let { content } = this.state;
        const dataColumns = [
            { id: 'createdAt', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Create Time' },
            { id: 'activityType', numeric: false, disabledPadding: false, label: 'Type' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            {
                id: 'patientId', numeric: false, width: 200, disabledPadding: false, label: 'Patient', formatValue: (v) => {
                    return v? <Patient appContext patientId={v} />:""
                }
            },
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='createdAt'
            columnData={dataColumns}
            data={content}
            rowsPerPage={25}
            title='Provider Activities' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext, providerId, planId } = self.props;

        let { content } = self.state;
        let data = content[s[0]];
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} providerId={providerId} instance={data} planId={planId} dataId={data && data.dataId} onClose={() => self.closeDialog()} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress } = this.state;
        return (
            <div style={styles.div}>
                {progress}
                {table}
                {dialog}
            </div>
        );
    }
}

const styles = {
    div: {
        margin: 10,
        textAlign: 'left'
    },
}

export default ProviderActivityView;
