import React from 'react';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Button,
    CircularProgress,
    Drawer,
    IconButton,
    Paper,
    Tab, Tabs,
    Toolbar,
    Tooltip,
    Typography,
    TextField
} from '@material-ui/core/';

import DMImportIcon from '@material-ui/icons/MoveToInbox';
import NavigationClose from '@material-ui/icons/Close';
import ProcessIcon from '@material-ui/icons/Build';
import ClearAllOutlined from '@material-ui/icons/ClearAllOutlined';

import {
    clearDirectMessageErrors,
    getDirectMessages,
    importDirectMessages,
    isArrayValid, isTypeArrayValid,
    processAttachments
} from '@apricityhealth/web-common-lib/utils/Services';

import Logger from '@apricityhealth/web-common-lib/utils/Logger';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import Org from '@apricityhealth/web-common-lib/components/Org';
import SendDirectMessageView from './SendDirectMessageView';

const fileDownload = require('js-file-download');

const log = new Logger();


/**
 * @extends React.Component
 * Direct Message [_In_|_Out_]Box
 * @memberof module:Admin
 * @alias DirectMessagesView
 */
export class DirectMessagesView extends React.Component {
    //#region Component-Lifecycle Methods
    constructor(props) {
        super(props);
        this.state = {
            /**@type {('inbound'|'outbound')}*/
            tab: 'inbound',
            importing: false,
            dialog: null,
            messageId: null,
            userId: null
        };
        /**@private {EnhancedTable} DM [_In_|_Out_]Box*/
        this._table = undefined;
    }

    componentWillMount() {
        console.log("componentWillMount:", window.location.pathname);
        let parts = window.location.pathname.slice(1).split('/');
        if (parts.length > 1) {
            this.setState({ messageId: parts[1] });
        }
    }


    loadContent({ offset = 0, limit = 25, order = 'desc', orderBy = 'createdAt' } = {}) {
        let { messageId, userId } = this.state;
        return new Promise((resolve, reject) => {
            const {
                props: { appContext },
                state: { tab }
            } = this;
            let args = [
                `inbound=${tab === 'inbound'}`,
                `offset=${offset}`, `limit=${limit}`,
                `order=${order}`, `orderBy=${orderBy}`
            ]
            if (messageId && messageId !== "")
                args.push(`messageId=${messageId}`);
            if (userId && userId !== "")
                args.push(`userId=${userId}`);
            getDirectMessages(appContext, args).then(results => {
                let messages = results.messages;
                let totalMessages = results.totalMessages;
                resolve({ data: messages, dataLength: totalMessages });
            }).catch(err => {
                reject(err);
            });
        });
    }

    handleTabChange = (event, tab) => {
        log.debug('handleTabChange:', tab);
        this.setState({ tab }, () => this._table.loadData());
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this._table.setState({ selected: [] });// unselect the question
        this._table.loadData();
    }
    //#endregion


    //#region Component-Event Methods
    onDownloadAttachment(attachment) {
        log.debug('onDownloadAttachment:', attachment);
        if (attachment.file) {
            fileDownload(attachment.file, 'Document.xml');
        } else {
            const { props: { appContext } } = this,
                { id: attachmentId, fileName, mimeType, messageId } = attachment;

            getDirectMessages(appContext, [
                `messageId=${messageId}`, 'includeAttachments=true'
            ]).then(results => {
                let message = results.messages;
                log.debug('onDownloadAttachment message:', message);
                if (isArrayValid(message) && isArrayValid(message[0].attachments)) {
                    const attachmentData = message[0].attachments.find(e => e.attachmentId === attachmentId);
                    if (attachmentData) {
                        log.debug('attachmentData.attachment:', attachmentData.attachment);
                        fileDownload(attachmentData.attachment, fileName, mimeType);
                    } else log.error('Attachment not found:', attachment);
                } else log.error('Message for Attachment not found:', attachment);
            }).catch(err => {
                log.error('onDownloadAttachment Error:', err);
            });
        }
    }

    onImportMessages() {
        const { props: { appContext } } = this;
        this.setState({ importing: true });
        return importDirectMessages(appContext).then(() => {
            this.setState({ importing: false });
        }).catch(error => {
            log.error('onImportMessages Error:', error);
            this.setState({ importing: false });
        });
    }

    /**
     * Present a JSON Viewer for the Selected Direct Message
     * @param {Array<Number>} s ___Selected___ index value(s) for `t.state.data`
     * @param {EnhancedTable} t ___Table___ containing Direct Message(s)
     */
    onSelectMessage(s, t) {
        if (isTypeArrayValid(s, 'number')) {
            const {
                _table: { state: { data } },
                props: { appContext }
            } = this,
                message = data[s[0]];
            const attachments = [];

            log.debug('onSelectMessage:', message);

            if (message.data && isArrayValid(message.data.attachments)) {
                attachments.push(...message.data.attachments.map(
                    (a, i) => <Button style={styles.downloadButton} key={i} variant='contained' onClick={
                        this.onDownloadAttachment.bind(this, a)
                    }>Download Attach: {i}</Button>
                ));
            }

            this.setState({
                dialog: <Drawer open variant='persistent' anchor='right'>
                    <AppBar style={styles.appBar} position='static'> <Toolbar>
                        <IconButton onClick={this.onCloseDialog.bind(this)}><NavigationClose /></IconButton>
                        <Typography variant='title' color='inherit'>Message Details</Typography>
                    </Toolbar></AppBar>
                    <br />
                    <div>{attachments} </div>
                    {message.error ?// Button: Clear Error & Re-Import | No Button [null]
                        <Button style={styles.downloadButton} variant='contained' onClick={
                            clearDirectMessageErrors.bind(null, appContext, message.messageId)
                        }>Clear Error</Button>
                        : null}
                    <br />
                    {message["requestId"] ? <> Request Id: <Link to={`/logs/?requestId=${message["requestId"]}`}>{message["requestId"]}</Link></> : null}
                    <br />
                    <ReactJson style={styles.jsonView} name='Item' displayDataTypes={false} src={message}
                        collapsed={2} collapseStringsAfterLength={64} />
                </Drawer>
            });
        }
        else this.setState({ dialog: null });
    }

    /**__Display__`SendDirectMessageView`__Dialog__*/
    onSendMessage() {
        const { props: { appContext } } = this;
        this.setState({
            dialog: <SendDirectMessageView appContext={appContext} onDone={this.onCloseDialog.bind(this)} />
        });
    }

    /**[_Refresh_|_Reimport_] __all__ `DirectMessageError`(s) ___for this application-context___*/
    onProcessAttachments() {
        const { props: { appContext } } = this;
        this.setState({ importing: true });
        return processAttachments(appContext).then(() => {
            this.setState({ importing: false });
        }).catch(error => {
            log.error('onProcessAttachments Error:', error);
            this.setState({ importing: false });
        });
    }

    //#endregion


    render() {
        const { state: { tab, importing, dialog, messageId, userId } } = this;

        let tabView = null;
        if (tab === 'inbound') {
            const columns = [
                { id: 'messageId', label: 'Message ID' },
                { id: 'createdAt', label: 'Create Date' },
                { id: 'processed', label: 'Processed' },
                { id: 'error', label: 'Error' },
                {
                    id: 'fromAddress', label: 'From', formatValue: (v) => {
                        if (v.startsWith("org:")) {
                            return <span>Import into <Org appContext={this.props.appContext} orgId={v.substring(4)} /></span>;
                        } else {
                            return v;
                        }
                    }
                },
                { id: 'data', label: 'Subject', formatValue: (v) => v ? v.subject : '' }
            ];

            tabView = <>
                <TextField style={styles.text} label='Message ID' value={messageId}
                    onChange={(e) => { this.setState({ messageId: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key === "Enter") this._table.loadData(); }} />
                <TextField style={styles.text} label='User ID' value={userId}
                    onChange={(e) => { this.setState({ userId: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key === "Enter") this._table.loadData(); }} />
                <IconButton
                    onClick={() => {
                        this.setState({ messageId: "", userId: "" });
                    }}><ClearAllOutlined color="primary" /></IconButton>

                <EnhancedTable disableAdd disableMultiSelect title='Inbound Messages'
                    rowsPerPage={25}
                    order='desc'
                    orderBy='createdAt'
                    columnData={columns}
                    data={this.loadContent.bind(this)}
                    onTable={table => this._table = table}
                    onSelected={(s, t) => this.onSelectMessage.call(this, s, t)}
                    onActions={(table, numSelected, actions) => {
                        actions.push(
                            <Tooltip key='process' title='Process Attachments'><IconButton
                                onClick={this.onProcessAttachments.bind(this)}>
                                {importing ? <CircularProgress size={20} /> : <ProcessIcon />}
                            </IconButton></Tooltip>
                        );
                        actions.push(
                            <Tooltip key='import' title='Import Direct Messages'><IconButton
                                onClick={this.onImportMessages.bind(this)}>
                                {importing ? <CircularProgress size={20} /> : <DMImportIcon />}
                            </IconButton></Tooltip>
                        );
                    }}
                />
            </>
        }
        else if (tab === 'outbound') {
            const columns = [
                { id: 'messageId', label: 'Message ID' },
                { id: 'createdAt', label: 'Create Date' },
                { id: 'processed', label: 'Processed' },
                { id: 'error', label: 'Error' },
                { id: 'data', label: 'To', formatValue: (v) => isArrayValid(v.recipients) ? v.recipients.join(',') : '' },
                { id: 'data', label: 'Subject', formatValue: (v) => v ? v.subject : '' }
            ];

            tabView = <>
                <TextField style={styles.text} label='Message ID' value={messageId}
                    onChange={(e) => { this.setState({ messageId: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key === "Enter") this._table.loadData(); }} />
                <TextField style={styles.text} label='User ID' value={userId}
                    onChange={(e) => { this.setState({ userId: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key === "Enter") this._table.loadData(); }} />
                <IconButton
                    onClick={() => {
                        this.setState({ messageId: "", userId: "" });
                    }}><ClearAllOutlined color="primary" /></IconButton>

                <EnhancedTable disableMultiSelect title='Outbound Messages'
                    rowsPerPage={25}
                    order='desc'
                    orderBy='createdAt'
                    columnData={columns}
                    data={this.loadContent.bind(this)}
                    onSelected={(s, t) => this.onSelectMessage.call(this, s, t)}
                    onTable={table => this._table = table}
                    onAdd={this.onSendMessage.bind(this)}
                />
            </>
        }

        return <Paper square>
            <Tabs style={{ width: '100%' }} value={tab}
                variant='scrollable' scrollButtons='on'
                indicatorColor='primary' textColor='primary'
                onChange={this.handleTabChange}
            >
                <Tab label='Inbound' value='inbound' />
                <Tab label='Outbound' value='outbound' />
            </Tabs>
            {tabView}
            {dialog}
        </Paper>;
    }
};


const styles = {
    text: { margin: 10, width: '400px' },
    downloadButton: { margin: 10, width: '200px' },
    jsonView: { width: '1000px', maxWidth: '1000px' },
    appBar: { backgroundColor: '#FF9800', width: '1000px', maxWidth: '1000px' }
};


export default DirectMessagesView;