import React, { useEffect, useState } from "react";

import moment from "moment";
import {
    withStyles,
    Popover,
    Select,
    MenuItem
} from '@material-ui/core/';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';

import { Provider } from "@apricityhealth/web-common-lib/components/Provider";
import { Patient } from "@apricityhealth/web-common-lib/components/Patient";
import { User } from '@apricityhealth/web-common-lib/components/User';

import FullCalendar from '@fullcalendar/react';
import MomentTimeZone from '@fullcalendar/moment-timezone';
import Interaction from '@fullcalendar/interaction';
import DayGrid from '@fullcalendar/daygrid';
import TimeGrid from '@fullcalendar/timegrid';
import ListGrid from '@fullcalendar/list';
import { isArrayValid } from "@apricityhealth/web-common-lib/utils/Services";
import styles from './Calendar.styles'

const TIMEZONES = Object.freeze(
    [
        <MenuItem button key='UTZ' value='UTZ'>UTZ</MenuItem>,
        <MenuItem button key='America/Los_Angeles' value='America/Los_Angeles'>America/Los_Angeles</MenuItem>,
        <MenuItem button key='America/Denver' value='America/Denver'>America/Denver</MenuItem>,
        <MenuItem button key='America/Chicago' value='America/Chicago'>America/Chicago</MenuItem>,
        <MenuItem button key='America/New_York' value='America/New_York'>America/New_York</MenuItem>
    ]);

function Calendar({ appContext, classes, currentTimeZone, events, showCreator }) {
    const [timeZone, setTimezone] = useState(currentTimeZone || moment.tz.guess(true))
    const [popoverFull, setPopoverFull] = useState(false);
    const [popoverEl, setPopoverEL] = useState(null);
    const [popoverInfo, setPopoverInfo] = useState(null);
    let fullCalendar = React.createRef();

    const eventClick = (info) => {
        setPopoverFull(true)
        setPopoverEL(info.el)
        setPopoverInfo(info.event)
    }

    useEffect(() => {
        const calendar = fullCalendar.current && fullCalendar.current.calendar;
        if (calendar) {
            calendar.updateSize();
        }
    }, [timeZone])



    return <div className={classes.container}>
        <div className={classes.row}>
            <div className={classes.search}>
                <div></div>
            </div>
            <div className={classes.right}>
                TimeZone:
                    <Select
                        value={timeZone}
                        style={{ width: 200, marginLeft:"15px" }}
                        onChange={(event) => { setTimezone(event.target.value) }}
                    >
                        {TIMEZONES}
                    </Select>
            </div>
        </div>
        <FullCalendar
            height='auto'
            //className={classes.calendar}
            ref={fullCalendar}
            timeZone={timeZone || 'local'}
            initialView={'timeGridWeek'}
            plugins={[DayGrid, TimeGrid, ListGrid, Interaction, MomentTimeZone]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
            }}
            views={{
                'dayGridFourWeek': {
                    type: 'dayGrid',
                    buttonText: 'month',
                    duration: { weeks: 4 }
                },
                'timeGridThreeDays': {
                    type: 'timeGrid',
                    buttonText: '3 days',
                    dayCount: 3
                }
            }}
            weekends={true}
            slotDuration="00:10:00"
            eventColor={classes.colorMain}
            allDaySlot={false}
            nowIndicator={true}
            slotEventOverlap={false}
            droppable={true}
            editable={true}
            noEventsMessage="No appointments scheduled"
            eventClick={eventClick}
            events={events}
            eventContent={(info) => {
                const { start, end } = info.event;
                const { providerId, patientId } = info.event.extendedProps;
                return <div>
                    {moment(start).tz(timeZone).format("h:mm") + " - " + moment(end).tz(timeZone).format("h:mm")}<br />
                    <Provider appContext={appContext} userId={providerId} />: <Patient appContext={appContext} patientId={patientId} />
                </div>;
            }}
        />
        {popoverInfo && popoverInfo.extendedProps && <Popover
            open={popoverFull}
            anchorEl={popoverEl}
            onClose={() => setPopoverFull(false)}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            elevation={24}
        >
            <div className={classes.popover}>
                <p>
                    <strong>{moment(popoverInfo.start).tz(timeZone).format('dddd, MMMM Do, YYYY')}</strong>
                    &nbsp;&middot;&nbsp;
                    {moment(popoverInfo.start).tz(timeZone).format('h:mma')} - {moment(popoverInfo.end).tz(timeZone).format('h:mma z')}</p>
                <p>
                    <strong>Provider:</strong> <Provider appContext={appContext} userId={popoverInfo.extendedProps.providerId} /><br />
                    <strong>Patient:</strong> <Patient appContext={appContext} patientId={popoverInfo.extendedProps.patientId} /><br />
                    {showCreator === true ? <p><strong>Appointment Creator:</strong> <User appContext={appContext} userId={popoverInfo.extendedProps.userId} userIdHidden={true} /></p> : ''}
                </p>
                <p><strong>Status:</strong> {popoverInfo.extendedProps.status}</p>
                {isArrayValid(popoverInfo.extendedProps.notes) && <p><strong>Notes:</strong> {isArrayValid(popoverInfo.extendedProps.notes) ?
                    <pre style={{ width: '20rem', whiteSpace: 'pre-wrap' }}>{popoverInfo.extendedProps.notes.map((n, index) => `${index + 1}:${n.note}`).join("\n")}</pre> : ""}</p>}
                <span className="toolbar"><span className="close text-link" onClick={() => setPopoverFull(false)}>Close</span></span>
            </div>
        </Popover>
        }
    </div>

}

export default withStyles(styles)(Calendar)

