import {
    setDayOfYear,
    getDayOfYear,
    // setHours,
    // setMinutes
} from 'date-fns';
import Moment from 'moment';

export function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

export function isValidProviderId(array, searchProviderId) {
    for (let i = 0; i < array.length; i++) {
        let element = array[i];
        if (element.providerId === searchProviderId)
            return true;

    }
    return false;
}

export function getByProviderId(array, searchProviderId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element&&  (element.providerId === searchProviderId))
                return element;

        }
    return null;
}

export function getByUsername(array, searchUsername) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.username === searchUsername)
                return element;

        }
    return null;
}

export function getByUserId(array, searchUserId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.userId === searchUserId)
                return element;

        }
    return null;
}

export function isValidPatientId(array, searchPatientId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.patientId === searchPatientId)
                return true;

        }
    return false;
}


export function getBySerialId(array, serial) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.serial === serial)
                return element;

        }
    return null;
}
export function getByPatientId(array, searchPatientId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.patientId === searchPatientId)
                return element;

        }
    return null;
}

export function isValidEmail(array, searchEmail) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (isArrayValid(element.emails)) {
                let emails = element.emails;
                for (let j = 0; j < emails.length; j++) {
                    let email = emails[j];
                    if (email.email.toLowerCase() === searchEmail.toLowerCase())
                        return true;

                }
            }
        }
    return false;
}

export function getByEmail(array, searchEmail) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (isArrayValid(element.emails)) {
                let emails = element.emails;
                for (let j = 0; j < emails.length; j++) {
                    let email = emails[j];
                    if (email.email.toLowerCase() === searchEmail.toLowerCase())
                        return element;

                }
            }
        }
    return null;
}

export function findByModelId(array, modelId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].modelId === modelId) {
                return array[index];
            }
        }
    return null;
}
export function findByDataId(array, dataId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].dataId === dataId) {
                return array[index];
            }
        }
    return null;
}

export function getProviderById(providers, providerId) {
    if (isArrayValid(providers))
        if (isArrayValid(providers)) {
            for (let j = 0; j < providers.length; j++) {
                let provider = providers[j];
                if (provider.providerId === providerId)
                    return provider;
            }
        }
    return null
}


export function arrayRemoveLocation(array, index) {
    if (isArrayValid(array))
        array.splice(index, 1);
}


export function getOfficeHours(date, officeHours) {
    if (isArrayValid(officeHours)) {
        let dayOfWeek = date.getDay();
        for (let j = 0; j < officeHours.length; j++) {
            let officeHour = officeHours[j];
            if (officeHour.days.end <= officeHour.days.start) {
                if (dayOfWeek >= officeHour.days.start || dayOfWeek <= officeHour.days.end)
                    return officeHour;
            } else {
                if (dayOfWeek >= officeHour.days.start && dayOfWeek <= officeHour.days.end)
                    return officeHour;
            }
        }
    }
    return null;
}


export function validateSchedule(schedules) {
    let messages = [];
    if (isArrayValid(schedules)) {
        let now = new Date();
        let nowDayOfYear = getDayOfYear(now);
        for (let j = nowDayOfYear; j < 365; j++) {
            now = setDayOfYear(now, j);
            if (!findScheduleForDayOfYear(now, schedules))
                messages.push(`No valid schedule for ${Moment(now).local().format("MM-DD-YYYY")}`);
        }

        //need to make sure  there is  a red alert schedule
        //need  to make sure no  gaps  at all..  which means checking  every minute of the  day 
    } else {
        messages.push("No valid schedules found");
    }
    return messages;
}

export function findScheduleForDayOfYear(date, schedules) {

    if (isArrayValid(schedules)) {
        let nowDayOfYear = getDayOfYear(date);
        for (let j = 0; j < schedules.length; j++) {
            let schedule = schedules[j];
            let startDayOfYear = getDayOfYear(new Date(schedule.start));
            let endDayOfYear = getDayOfYear(new Date(schedule.start));
            if (startDayOfYear === nowDayOfYear || endDayOfYear === nowDayOfYear) return true;
        }

    }

    return false;
}


// export function checkEveryMinute(day, schedules) {
//     let messages = [];
//     if (isArrayValid(schedules)) {

//         let minutesCheck  = new Date();
//         setHours(minutesCheck, 0);
//         setMinutes(minutesCheck, 0);

//         //check every minute of the day to ensure there are no gaps 
//         for (let j = 0; j < 1440; j++) {
//             let schedule = schedules[j];
//             let start = new Date(schedule.start);
//             let end = new Date(schedule.start);
//             if (startDayOfYear === nowDayOfYear || endDayOfYear === nowDayOfYear) return true;
//         }

//     } else {
//         messages.push(`Minutes check failed: no valid schedule passed for day-${day} `);
//     }
//     return messages;

// }
export function addMinutes(time, minutes, addDay = false) {
    console.log("addMinutes:", time, minutes, addDay);
    let moment = Moment(time).add(Math.floor(minutes / 60), 'hour').add(Math.floor(minutes % 60), 'minute');
    if (addDay === true)
        moment = moment.add(1, 'day');
    return moment.toDate();
}




export function getElementByName(array, name) {
    if (isArrayValid(array)) {
        for (let index = 0; index < array.length; index++) {
            let element = array[index];
            if (element.name === name)
                return element;
        }
    }
    return null;
}


export function getArrayElement(array, name) {
    if (isArrayValid(array)) {
        for (let index = 0; index < array.length; index++) {
            let element = array[index];
            if (element[name])
                return element[name];
        }
    }
    return null;
}

export function compareAscending(a, b) {
  if (a.label < b.label) //sort string ascending
      return -1; 
  if (a.label > b.label)
      return 1;
  return 0;
}