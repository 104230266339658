import React, { Component } from 'react';
import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { Link } from 'react-router-dom';
import {
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    Drawer,
    DialogTitle,
    DialogContentText,
    DialogContent,
    TextField,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Tooltip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    LinearProgress,
} from '@material-ui/core/';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';
import RefreshIcon from '@material-ui/icons/Refresh';
import UpdateProviderIcon from '@material-ui/icons/LocalHospital';
import HistoryIcon from '@material-ui/icons/History';
import EditPatientIcon from '@material-ui/icons/Create';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import JSONDataDialog from '../../dialogs/JSONDataDialog';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';
import { saveOrg, updatePatient } from '@apricityhealth/web-common-lib/utils/Services';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment-timezone';

import '../../styles/org.css'

const Promise = require("bluebird");
const Sequential = require('promise-sequential');
const MAX_ROSTER = 500;

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

class RosterView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            roster: [],
            filtered: [],
            progress: null,
            filter: "",
            org: props.org,
            total: 0,
            searchField: "employeeId",
            searchValue: ""
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent(offset = 0, roster = null) {
        let self = this;
        let { searchField, searchValue } = self.state;
        let { orgId } = this.state.org;
        let { appContext } = this.props;

        if (orgId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            let args = [
                `offset=${offset}`,
                `limit=${MAX_ROSTER}`
            ];
            if (searchValue) {
                args.push(`searchField=${searchField}`);
                args.push(`searchValue=${searchValue}`);
            }
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster?${args.join('&')}`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };

            console.log("get roster request", request);
            Axios(request).then((result) => {
                console.log("get roster response:", result);
                let total = result.data.totalCount;
                let response = result.data.roster;
                if (Array.isArray(roster))
                    roster = response.concat(roster);
                else
                    roster = response;

                if (response.length === MAX_ROSTER) {
                    self.loadContent(offset + MAX_ROSTER, roster);
                } else {
                    self.setState({ roster, total, progress: null, error: null }, self.applyFilter.bind(self));
                }
            }).catch((err) => {
                console.log("getRoster error:", err);
                self.setState({ progress: null, error: getErrorMessage(err) });
            });
        } else {
            this.setState({
                roster: []
            });
        }
    }

    applyFilter() {
        let { roster, filter } = this.state;
        let filtered = [];
        if (filter && isArrayValid(roster)) {
            for (var i = 0; i < roster.length; ++i) {
                if (testFilter(filter, roster[i]))
                    filtered.push(roster[i]);
            }
        } else {
            filtered = roster
        }
        this.setState({ filtered });
    }

    deleteRosterItem(rosterItem) {
        let { orgId } = this.state.org;
        let { appContext } = this.props;
        let self = this;
        if (rosterItem) {
            self.setState({ dialog: null, progress: <CircularProgress /> });
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster/${encodeURIComponent(rosterItem.employeeId)}`,
                method: 'DELETE',
                headers: { "Authorization": appContext.state.idToken }
            };
            console.log("delete roster item request:", request);
            Axios(request).then((response) => {
                console.log("delete roster item response", response);
                return self.loadContent();
            }).then(() => {
                if (this._types)
                    this._types.setState({ selected: [] });
            }).catch((err) => {
                console.log("delete oster item error:", getErrorMessage(err));
                self.setState({ progress: getErrorMessage(err) });
            });
        }
    }

    updateRosterItem(rosterItem) {
        let { orgId } = this.state.org;
        let { appContext } = this.props;
        let self = this;
        return new Promise((resolve, reject) => {
            if (rosterItem) {
                self.setState({ dialog: null, progress: <CircularProgress /> });
                const request = {
                    url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster/?override=true`,
                    method: 'POST',
                    data: rosterItem,
                    headers: { "Authorization": appContext.state.idToken }
                };
                console.log("save roster item request:", request);
                Axios(request).then((response) => {
                    console.log("save roster item response", response);
                    return self.loadContent();
                }).then((results) => {
                    if (this._types)
                        this._types.setState({ selected: [] });
                    return resolve(results)
                }).catch((err) => {
                    console.log("save roster item error:", getErrorMessage(err));
                    self.setState({ progress: getErrorMessage(err) });
                    reject(err.response.data.error)
                });
            } else {
                resolve();
            }
        });

    }

    cancel() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    addRosterItem() {
        let self = this;
        let { appContext } = this.props
        let rosterItem = {
            employeeId: "",
            firstName: "",
            lastName: "",
            status: "",
            patientId: "",
            dob: ""
        };
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <RosterItemDialog
                appContext={appContext}
                roster={rosterItem}
                onDelete={(roster) => { self.deleteRosterItem(roster) }}
                onSave={(roster) => { self.updateRosterItem(roster) }}
                onClose={() => { self.cancel() }}
            />
        </Drawer>;

        self.setState({ dialog })
    }

    rosterItemSelected(s, t) {
        if (s.length > 0) {
            const { filtered, roster } = this.state;
            const rosterItem = filtered ? filtered[s[0]] : roster[s[0]];

            let self = this;
            let { appContext } = this.props
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <RosterItemDialog
                    appContext={appContext}
                    roster={rosterItem}
                    onDelete={(roster) => { self.deleteRosterItem(roster) }}
                    onSave={(roster) => { self.updateRosterItem(roster) }}
                    onClose={() => { self.cancel() }}
                />
            </Drawer>;

            self.setState({ dialog })
        }
    }

    exportRoster() {
        let { filtered } = this.state;
        let report = [];
        if (isArrayValid(filtered)) {
            filtered.sort((a, b) => {
                if (a.firstName < b.firstName)
                    return -1;
                else if (a.firstName > b.firstName)
                    return 1;
                return 0;
            });

            for (let index = 0; index < filtered.length; index++) {
                let roster = filtered[index];
                let orgId = roster.orgId;
                let employeeId = roster.employeeId;
                let supervisor = roster.supervisor;
                let department = roster.department;
                let patientId = roster.patientId;
                let firstName = roster.firstName;
                let lastName = roster.lastName;
                let dob = roster.dob;
                let location = roster.location;
                let status = roster.status;
                report.push({ firstName, lastName, dob, location, status, employeeId, patientId, orgId, supervisor, department });
            }
        }

        return report;
    }

    importRoster(importRoster) {
        let self = this;
        let { org, roster } = this.state;
        let orgId = org.orgId;

        const { appContext } = this.props;
        return new Promise((resolve, reject) => {
            // let roster = [];
            let currentRoster = roster

            // firstly, scan for any existing roster entries that are no longer in the CSV file and delete them..
            let promises = [];
            for (let i = 0; i < currentRoster.length; ++i) {
                let current = currentRoster[i];
                let existing = importRoster.find((e) => e.employeeId === current.employeeId);
                if (!existing) {
                    // remove this employee from the roster
                    console.log(`Deleting ${current.employeeId} from the roster.`)
                    promises.push(deleteRoster(appContext, orgId, current.employeeId));
                } else if (! existing.patientId && current.patientId) {
                    // copy over the exiting patientId into this record..
                    existing.patientId = current.patientId;
                }
            }
            // now update the roster with the current data..

            org.lastRosterImport = Moment().toISOString();
            promises.push(saveOrg(appContext, org));
            // promises.push(updateRoster(appContext, orgId, importRoster));
            Promise.all(promises).then(() => {
                let rosterChunked = sliceIntoChunks(importRoster, 150);
                return Promise.map(rosterChunked, (roster) => {
                    return updateRoster(appContext, orgId, roster)
                }, { concurrency: 1 });
            }).then(() => {
                console.log(`importing done, records updated.`);
                self.loadContent();
                resolve();
            }).catch((err) => {
                console.error("Error:", err);
                self.setState({ progress: getErrorMessage(err) });
                reject(err);
            })
        });
    }

    updateProvider() {
        const self = this;
        console.log("updateProvider");
        let dialog = <Dialog open={true}>
            <DialogTitle>Update Provider</DialogTitle>
            <DialogContent>{`Please confirm you would like to update the primary care provider for all patients in this roster?`}</DialogContent>
            <DialogActions>
                <Button onClick={() => self.setState({ dialog: null })}>Cancel</Button>
                <Button onClick={() => {
                    let { org, roster } = self.state;
                    let { appContext } = self.props;

                    let promises = [];
                    for (let i = 0; i < roster.length; ++i) {
                        let entry = roster[i];
                        if (!entry.patientId) continue;       // skip roster entries with no patientId
                        promises.push(() => {
                            self.setState({
                                dialog: <Dialog open={true} maxWidth='sm' fullWidth={true}>
                                    <DialogContent>
                                        {`Updating patient ${entry.patientId}...`}
                                        <br />
                                        <br />
                                        <LinearProgress variant='determinate' value={(i * 100) / roster.length} />
                                    </DialogContent>
                                </Dialog>
                            });

                            return updatePatient(appContext, entry.patientId, { primaryProviderId: org.defaultProviderId });
                        })
                    }

                    Sequential(promises).then(() => {
                        self.setState({ dialog: null });
                    }).catch((err) => {
                        self.setState({ dialog: null, error: getErrorMessage(err) });
                    });
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    render() {
        let self = this;
        let { filtered, dialog, progress, filter, error, org, total, searchValue, searchField } = self.state;
        console.log("filtered:", filtered);
        let itemsDisabled = true
        if (org.orgId) {
            itemsDisabled = false
        }
        if (!filtered) filtered = []
        let searchSelect = <FormControl style={{ margin: 5, width: 200 }} >
            <InputLabel>Search By:</InputLabel>
            <Select
                value={searchField}
                onChange={(e) => self.setState({ searchField: e.target.value })}
            >
                <MenuItem key='employeeId' value='employeeId'>Employee Id</MenuItem>
                <MenuItem key='name' value='name'>Name</MenuItem>
                <MenuItem key='status' value='status'>status</MenuItem>
                <MenuItem key='dob' value='dob'>DOB</MenuItem>
                <MenuItem key='location' value='location'>Location</MenuItem>
                <MenuItem key='department' value='department'>Department</MenuItem>
                <MenuItem key='supervisor' value='supervisor'>Supervisor</MenuItem>
            </Select>
        </FormControl>;

        let rosterColumns = [
            { id: 'employeeId', width: 50, label: 'Employee Id' },
            { id: 'patientId', width: 50, label: 'Patient Id' },
            { id: 'firstName', width: 50, label: 'First Name' },
            { id: 'lastName', width: 50, label: 'Last Name' },
            { id: 'status', width: 50, label: 'Status' },
            { id: 'dob', width: 50, label: 'DOB' },
            { id: 'location', width: 50, label: 'Location' },
        ];
        let rosterTable = <div className='table'><EnhancedTable
            disableActions={itemsDisabled}
            disableMultiSelect={true}
            onAdd={() => self.addRosterItem()}
            onTable={table => self._types = table}
            onSelected={(s, t) => self.rosterItemSelected(s, t)}
            rowsPerPage={25}
            orderBy='firstName'
            columnData={rosterColumns}
            data={filtered}
            title={`Roster - (DB contains ${total ? total : "..."})`} /></div>;


        let filterInput = <TextField style={{ margin: 5, width: 300 }} value={filter} label="Filter"
            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)); }} />;
        let searchInput = <TextField style={{ margin: 5, width: 250 }}
            value={searchValue} label="DB Search"
            onChange={(e) => { searchValue = e.target.value; self.setState({ searchValue }); }}
            onKeyDown={(e) => { if (e.keyCode === 13) self.loadContent() }}
        />;


        let importButton = <Tooltip title="Import Roster">
            <ImportExport
                toolTip="Roster"
                name={`Roster`}
                note={`File must be in the correct format with the correct col headings`}
                asText={true}
                // ignoreExport={true}
                exportStart={() => {
                    return Promise.resolve(self.exportRoster());
                }}
                exportRow={() => { }}
                importStart={() => {
                    self.setState({ progress: <CircularProgress size={20} /> });
                    return Promise.resolve();
                }}
                importRows={(rows) => {
                    return self.importRoster(rows);
                }}
                importRow={() => { }}
                importDone={() => {
                    self.setState({
                        progress: null,
                        dialog: <Dialog open={true}>
                            <DialogTitle>Import done</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => self.setState({ dialog: null })}>Ok</Button>
                            </DialogActions>
                        </Dialog>
                    });
                }}
            />
        </Tooltip>;

        let getHeaderItems = !itemsDisabled ? <span>
            <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this, 0, null)}>{progress ? progress : <RefreshIcon />}</IconButton>
            <Tooltip title='Update Provider'><IconButton onClick={this.updateProvider.bind(this)}><UpdateProviderIcon /></IconButton></Tooltip>
            {importButton}
        </span> : <div>Save organization to enable roster editing.</div>

        return <table style={{ width: '100%' }
        } >
            <tbody>
                <tr>
                    <td align='left'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {searchSelect}
                            {searchInput}
                            {filterInput}
                        </div>
                    </td>
                    <td>
                        {error}
                        {getHeaderItems}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {rosterTable}
                        {dialog}
                    </td>
                </tr>
            </tbody>
        </table>
    }
}


class RosterItemDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roster: props.roster,
            dialog: null,
        };
    }

    componentDidUpdate(oldProps) {
        if (oldProps.roster !== this.props.roster) {
            this.setState({ roster: this.props.roster });
        }
    }

    onSave() {
        let { roster } = this.state;
        if (this.props.onSave)
            this.props.onSave(roster);
    }

    onClose() {
        if (this.props.onClose)
            this.props.onClose();
    }
    onDelete() {
        let { roster } = this.state;
        this.setState({ dialog: null });
        if (this.props.onDelete)
            this.props.onDelete(roster);
    }

    cancel() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete roster item</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancel() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.onDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onShowCode() {
        this.setState({
            dialog: <JSONDataDialog
                appContext={this.props.appContext}
                dataType={this.state.roster}
                onDone={this.cancel.bind(this)} />
        });
    }

    render() {
        const self = this;
        let { roster, dialog } = self.state;
        let { appContext } = this.props;
        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => self.onClose()}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="title" color="inherit">Roster Item Details</Typography>
                </Toolbar>
            </AppBar> : null;

        return <div>
            {appBar}
            <div style={{ "textAlign": "right", "margin": "15px" }}>
                {roster.patientId && <Tooltip title='Patient History'><Link to={`/patient_history/${roster.patientId}`}><IconButton><HistoryIcon /></IconButton></Link></Tooltip>}
                {roster.patientId && <Tooltip title='Edit Patient'><Link to={`/patients/${roster.patientId}`}><IconButton><EditPatientIcon /></IconButton></Link></Tooltip>}
                <Tooltip title='View JSON'><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                <Tooltip title='Save'><IconButton onClick={() => { self.onSave(); }}><SaveIcon /></IconButton></Tooltip>
                <Tooltip title='Delete'><IconButton onClick={() => { self.confirmDelete(); }}><DeleteIcon /></IconButton></Tooltip>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Employee Id:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.employeeId}
                                onChange={(e) => { roster.employeeId = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="First Name:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.firstName}
                                onChange={(e) => { roster.firstName = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Last Name:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.lastName}
                                onChange={(e) => { roster.lastName = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Location:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.location}
                                onChange={(e) => { roster.location = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Supervisor:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.supervisor}
                                onChange={(e) => { roster.supervisor = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Department:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.department}
                                onChange={(e) => { roster.department = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <TextField
                                label="Internal Mail Info:"
                                style={{ width: '500px', margin: '5px' }}
                                value={roster.internalMailInfo}
                                onChange={(e) => { roster.internalMailInfo = e.target.value; self.setState({ roster }) }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colSpan='2'>
                            <div style={{ margin: 5 }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        keyboard
                                        label={'DOB'}
                                        format={"yyyy-MM-dd"}
                                        autoComplete="off"
                                        style={styles.text}
                                        disableFuture
                                        mask={value =>
                                            // handle clearing outside if value can be changed outside of the component
                                            value ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/] : []
                                        }
                                        onChange={(dob) => { roster.dob = Moment(dob).utcOffset(0).toISOString(); self.setState({ roster }); }}
                                        animateYearScrolling={false}
                                        value={Moment(roster.dob).utcOffset(0).toISOString()}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <FormControl style={{ width: '245px', marginLeft: '10px', margin: '5px' }} >
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={roster.status}
                                    onChange={(e) => { roster.status = e.target.value; self.setState({ roster }) }}
                                >
                                    <MenuItem value='active'>Active</MenuItem>
                                    <MenuItem value='inactive'>In-Active</MenuItem>
                                </Select>
                            </FormControl>
                        </td>


                    </tr>

                    <tr>
                        <td align="left" colSpan='2'>
                            <span style={{ marginLeft: "5px" }}>
                                <SelectPatient
                                    enableNone={true}
                                    appContext={appContext}
                                    patientId={roster.patientId}
                                    onSelect={(patientId) => {
                                        roster.patientId = patientId;
                                        self.setState({ roster })
                                    }} />
                            </span>
                        </td>
                    </tr>

                </tbody>
            </table>
            {dialog}
        </div>

    }

}
function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

function testFilter(filter, item) {
    if (item) {
        filter = filter.toUpperCase();
        if (item.employeeId && item.employeeId.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.patientId && item.patientId.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.firstName && item.firstName.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.lastName && item.lastName.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.status && item.status.toUpperCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}


function updateRoster(appContext, orgId, roster) {
    return new Promise((resolve, reject) => {
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster?override=true`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: roster
        };
        console.log("updateRoster request:", request);
        Axios(request).then((response) => {
            console.log("updateRoster response:", response.data);
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

function deleteRoster(appContext, orgId, employeeId = '*') {
    return new Promise((resolve, reject) => {
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster/${encodeURIComponent(employeeId)}`,
            method: 'DELETE',
            headers: { "Authorization": appContext.state.idToken }
        };
        console.log("deleteRoster request:", request);
        Axios(request).then((response) => {
            console.log("deleteRoster response:", response.data);
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
// function getEntireRoster(idToken, orgId) {
//     return new Promise((resolve, reject) => {
//         let url = Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/roster?limit=1000000`;
//         const reqeust = {
//             url,
//             method: 'GET',
//             headers: {"Authorization": idToken }
//         };
//         console.log("get roster request", reqeust);
//         Axios(reqeust).then((result) => {
//             console.log("get roster response:", result);
//             resolve(result.data.roster);
//         }).catch((err) => {
//             console.log("get roster error", err);
//             reject(err);
//         });
//     })
// }



// const chunk = (arr, size) =>
//     Array.from({length: Math.ceil(arr.length / size) }, (v, i) =>
//         arr.slice(i * size, i * size + size)
//     );


const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: '600px'
    },
    contactHeader: {
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contactHours: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    select: {
        margin: 10,
        width: '100%',
        "textAlign": "left"
    },
    button: {
        margin: 5,
        width: '200px'
    },
    div: {
        margin: 0
    },
    timezone: {
        margin: 5,
    },
    question: {
        margin: 5,
        width: 400
    },
    name: {
        margin: 5,
        width: 200
    },
    phone: {
        margin: 5,
        width: 200
    },
    table: {
        width: '500px'
    },
    grid: {
        width: '60%',
    },
    td: {
        "textAlign": "right"
    }
}

export default RosterView;
