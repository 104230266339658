import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core/';


import EditPatientEmailDialog from './EditPatientEmailDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';


class PatientEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: [],
            dialog: null
        }
    }

    componentDidMount(){
        this.setState({
            emails: this.props.emails || []
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.emails !== this.props.emails){
            this.setState({emails: this.props.emails, dialog: null})
        }
    }

    onAddEmail() {
        let { emails } = this.state;
        if (! Array.isArray(emails) ) emails = [];

        emails.push( { email: '', emailType:'primary'} );
        let index = emails.length - 1;

        this.setState({ emails:emails,  
            dialog: <EditPatientEmailDialog 
                email={emails[index]}
                onCancel={() => {
                    emails.splice( index, 1 );
                    this.setState({emails, dialog: null});
                }}
                onDone={(v) => {
                    emails[index] = v;
                    this.setState({ emails, dialog: null});
                }}
            />
        })
    }

    onEditEmail( index ) {
        const { emails } = this.state;
        this.setState({
            dialog: <EditPatientEmailDialog
                email={emails[index]}
                onCancel={() => {
                    this.setState({dialog: null});
                }}
                onDone={(v) => {
                    emails[index] = v;
                    this.setState({ emails, dialog: null});
                }}
            />
        });
    }

    onDeleteEmail(emailIndex, evt) {
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        let { emails } = this.state;
        emails.splice( emailIndex, 1 );
        this.setState({emails});
    }

    render(){
        let { emails, dialog } = this.state;

        let emailRows = () => {
            var tableRows = []
            if (Array.isArray(emails)){
                emails.forEach( (emailItem, index)=> {
                    var row = (
                        <TableRow
                            hover
                            onClick={this.onEditEmail.bind(this,index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                {emailItem.email}
                            </TableCell>
                            <TableCell>
                                {emailItem.emailType}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"  
                                    onClick={this.onDeleteEmail.bind(this,index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                })
            }
            return tableRows 
        }
        return (
            <div>
                {dialog}
                <Paper>
                <EnhancedTableToolbar
                    title='Emails'
                    onAdd={this.onAddEmail.bind(this)}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Email Type</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailRows()}
                    </TableBody>
                </Table>
                </Paper>
            </div>
        )
    }
}

export default PatientEmail;