// ===========================|| DASHBOARD - BAJAJ AREA CHART ||=========================== //

const chartData = {
    options: {
        chart: {
            id: 'bar-chart',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                padding: '0rem'
            }
        },
        xaxis: {
            type: 'category',
            categories: ['high', 'medium', 'low'],
        },
        title: {
            text: 'IRAE Overall Alerts',
            floating: true,
            align: 'center',
            style: {
                color: 'red'
            }
        },
        fill: {
            type: 'solid'
        },
        dataLabels: {
            enabled: true,
            offsetY: 20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        grid: {
            show: false
        }
    },
};

export default chartData;
