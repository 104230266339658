import { useCallback } from 'react'

import useData from '@apricityhealth/web-common-lib/hooks/useData';

const ENDPOINT = "apricity-cleanse"

const OPTS = {
    PROCESS_ATTACHMENTS: "/directMessages/process",
    PARSE_ATTACHMENTS: "/directMessages/parse",
}

/**
 * useMessaging - Hook to get messages related data.
 */
function useCleanseAgent(defaultValue) {
    const [data, { loading, error, clearData, fetchData }] = useData(ENDPOINT, defaultValue);

    const processAttachment = useCallback(async (params, args, opts) => {
        return await fetchData(OPTS.PROCESS_ATTACHMENTS, params, args, opts)
    }, [data, loading, error, clearData, fetchData])

    const parseAttachment = useCallback(async (params, args, opts) => {
        return await fetchData(OPTS.PARSE_ATTACHMENTS, params, args, opts)
    }, [data, loading, error, clearData, fetchData])
    return [{ data, loading, error, clearData, processAttachment, parseAttachment }]
}

export default useCleanseAgent
