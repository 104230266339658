import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
} from '@material-ui/core/';

import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import UserView from './UserView';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

export class UsersView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: null,
            error: null,
            filter: 'email',
            filterValue: ''
        }
    }

    componentWillMount() {
        const urlPath = window.location.pathname.slice(1).split('/');
        if ( urlPath[1] ) {
            this.onOpenUser(urlPath[1]);
        }
    }

    loadUsers({ offset, limit }) {
        const self = this;
        return new Promise((resolve, reject) => {
            const { filter, filterValue } = this.state;
            console.log(`filter`, filter, filterValue)
            let args = [];
            if ((filter && filterValue)) args.push(`${filter}=${encodeURIComponent(filterValue)}`);
            const getUsers = {
                url: Config.baseUrl + `${Config.pathPrefix}authentication/users?offset=${offset}&limit=${limit}&${args.join('&')}`,
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            console.log("loadUsers:", getUsers);
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Axios(getUsers).then((response) => {
                console.log("loadUsers response:", response);
                resolve({ data: response.data.users, dataLength: response.data.totalUsers || -1 });
                self.setState({ progress: null });
            }).catch(function (error) {
                console.error("loadUsers error:", error.response);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        })
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._table) {
            this._table.loadData();
            this._table.setState({ selected: [] });        // unselect the question
        }
    }

    onUserSelected(s, t) {
        if (s.length > 0) {
            const user = t.state.data[s[0]];
            this.onOpenUser( user.username );
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onOpenUser(userId) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <UserView appContext={this.props.appContext} username={userId} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    onCreateUser() {
        console.log("createUser");
        const self = this;
        const { appContext } = this.props;
        const newUser = { username: '', email: '', phone: '', groups: [] };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <UserView appContext={appContext} user={newUser} onClose={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ dialog: dialog });
    }

    render() {
        const self = this;
        const { filter, filterValue, dialog, error } = this.state;

        // console.log(`users to show `, users);
        const columnData = [
            { id: 'username', numeric: false, disabledPadding: false, label: 'User ID' },
            { id: 'email', numeric: false, disabledPadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disabledPadding: false, label: 'Phone' },
            { id: 'userStatus', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'createDate', numeric: false, disabledPadding: false, label: 'Create Date' },
        ];

        const selectFilter = <FormControl style={{ margin: 5, width: 300 }} >
            <InputLabel>Select Filter</InputLabel>
            <Select value={filter}
                onChange={(e) => { self.setState({ filter: e.target.value, filterValue: e.target.value === 'group' ? 'administrators' : '' }) }}>
                <MenuItem value='email'>Email</MenuItem>
                <MenuItem value='userId'>User ID</MenuItem>
                <MenuItem value='phone'>Phone Number</MenuItem>
                <MenuItem value='group'>Group</MenuItem>
            </Select>
        </FormControl>;
        const selectFilterValue = filter === 'group' ? <FormControl style={{ margin: 5, width: 300 }} >
            <InputLabel>Select Group</InputLabel>
            <Select value={filterValue}
                onChange={(e) => { self.setState({ filterValue: e.target.value }) }}>
                <MenuItem value='administrators'>Administrators</MenuItem>
                <MenuItem value='experts'>Experts</MenuItem>
                <MenuItem value='patients'>Patients</MenuItem>
                <MenuItem value='providers'>Providers</MenuItem>
                <MenuItem value='debug'>Debug</MenuItem>
            </Select></FormControl> : <TextField style={styles.text} label='Filter' value={filterValue}
                onChange={(e) => { self.setState({ filterValue: e.target.value }) }}
                onKeyDown={(e) => { if (e.keyCode === 13) self._table.loadData(); }} />;

        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {selectFilter}
                {selectFilterValue}
            </td><td align='right'>
                    {error}
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable disableMultiSelect={true}
                    onTable={table => self._table = table}
                    onSelected={(s, t) => self.onUserSelected(s, t)}
                    onAdd={() => self.onCreateUser()}
                    orderBy='email'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={self.loadUsers.bind(self)}
                    title='Users' />
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 5,
        width: 200
    },
    text: {
        margin: 5,
        width: 300
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default UsersView;
