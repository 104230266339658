import React, { Component } from 'react';
import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import {
    CircularProgress,
    IconButton,
} from '@material-ui/core/';

import SaveIcon from '@material-ui/icons/Save';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import SelectLocation from '@apricityhealth/web-common-lib/components/SelectLocation';

class CodeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            codes: [],
            orginalCodes: [],
            progress: null,
            org: props.org
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        let { orgId } = this.state.org;
        let { appContext } = this.props;

        if (orgId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/registrationCode`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };

            console.log("getRegistrationCodes request", request);
            Axios(request).then((result) => {
                console.log("getRegistrationCodes result:", result.data);
                this.setState({ progress: null, codes: result.data, orginalCodes: JSON.parse(JSON.stringify(result.data)) });
            }).catch((err) => {
                console.log("getRegistrationCodes error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        } else {
            this.setState({
                codes: []
            });
        }
    }

    deleteRegistationCode(code) {
        let { orgId } = this.state.org;
        let { appContext } = this.props;
        if (code) {
            this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/registrationCode/${encodeURIComponent(code)}`,
                method: 'DELETE',
                headers: { "Authorization": appContext.state.idToken }
            };
            console.log("deleteRegistationCode request:", request);
            Axios(request).then((result) => {
                console.log("deleteRegistationCode result:", result.data);
                return this.loadContent();
            }).then(() => {
                if (this._types)
                    this._types.setState({ selected: [] });
            }).catch((err) => {
                console.error("deleteRegistationCode error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
    }

    updateRegistrationCode(code) {
        let { orgId } = this.state.org;
        let { appContext } = this.props;
        if (code) {
            this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}/registrationCode`,
                method: 'POST',
                data: code,
                headers: { "Authorization": appContext.state.idToken }
            };
            console.log("saveRegistrationCode request:", request);
            Axios(request).then((result) => {
                console.log("saveRegistrationCode response", result);
                return this.loadContent();
            }).catch((err) => {
                console.error("saveRegistrationCode error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
    }

    onSaveChanges() {
        const { orginalCodes, codes } = this.state;
        let updateCodes = codes.filter((e) => !orginalCodes.find((k) => k.registrationCode === e.registrationCode) || orginalCodes.find((k) => k.registrationCode === e.registrationCode && k.locationId !== e.locationId));
        let deleteCodes = orginalCodes.filter((e) => !codes.find((k) => k.registrationCode === e.registrationCode));

        if (updateCodes.length > 0)
            this.updateRegistrationCode(updateCodes);
        if (deleteCodes.length > 0)
            deleteCodes.map((e) => this.deleteRegistationCode(e.registrationCode));
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { appContext } = this.props;
        let { codes, dialog, progress, error, org } = this.state;

        let rosterColumns = [
            { id: 'registrationCode', width: 300, label: 'Registration Code', editType: 'text' },
            {
                id: 'locationId', label: 'Location ID', editControl: (table, value, row, index, id) => {
                    return <SelectLocation appContext={appContext} orgId={org.orgId} locationId={value} enableNone={true} onChange={(locationId) => {
                        console.log("onChange location:", locationId);
                        table.setDataField(index, id, locationId)
                    }} />
                }
            }
        ];

        let rosterTable = <EnhancedTable
            disableMultiSelect={false}
            onDataChanged={(data) => {
                console.log("onDataChanged:", data);
                this.setState({ codes: data });
            }}
            onActions={(table, numSelected, actions) => {
                actions.push(<IconButton onClick={this.onSaveChanges.bind(this)}>{progress ? progress : <SaveIcon />}</IconButton>)
            }}
            onTable={table => this._types = table}
            rowsPerPage={25}
            orderBy='registrationCode'
            columnData={rosterColumns}
            data={codes}
            title='Registration Codes' />;


        return <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td align='left'>
                    </td>
                    <td align='right'>
                        {error}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {rosterTable}
                        {dialog}
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}

export default CodeView;
