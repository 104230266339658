import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Paper,
    IconButton,
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

class RecommendationView extends Component {

    close() {
        this.props.onClose();
    }

    render() {
        const self = this;
        const { recommendation, recommendationType } = self.props;

        let name = '';
        if (recommendationType)
            name = recommendationType.name.substring(0, 100);

        let elms = [];

        if (recommendation)
            elms.push(<Paper key="1" style={styles.jsonPaper}>
                <ReactJson src={recommendation}
                    collapsed={3} name={"Recommendation Instance"} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);

        if (recommendationType)
            elms.push(<Paper key="1" style={styles.jsonPaper}>
                <ReactJson src={recommendationType}
                    collapsed={3} name={"Recommendation Type"} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);


        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.close()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">{name} Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="left">
                {appBar}
                {elms}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }
}

export default RecommendationView;
