import React, { Component } from 'react';

import {
    CircularProgress,
    FormControl,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core/';

import DurationGraph from '../../components/DurationGraph';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import {
    getMetrics,
} from '@apricityhealth/web-common-lib/utils/Services';

const METRIC_TYPES = {
    "API": {
        "selectGraphType": true,
        "sample": ["duration"],
        "groupBy": [
            "*", 
            "endPoint"
        ],
        "defaultGroup": "endPoint",
        "defaultGraphType": "average"
    },
    "PatientAction": {
        "selectGraphType": true,
        "sample": [
            "count"
        ],
        "groupBy": [
            "*",
            "Action"
        ],
        "defaultGroup": "Action",
        "defaultGraphType": "total"
    },
    "ProviderAction": {
        "selectGraphType": true,
        "sample": [
            "count"
        ],
        "groupBy": [
            "*",
            "Action"
        ],
        "defaultGroup": "*",
        "defaultGraphType": "total"
    },
    "ImportEMR": {
        "selectGraphType": true,
        "sample": [
            "vitalSigns",
            "labs",
            "imaging",
            "observations",
            "encounters",
            "conditions",
            "medications",
            "procedures",
            "diagnosticReports",
            "documents"
        ],
        "groupBy": [
            "*",       
            "PatientId",
            "EmrId"
        ],
        "defaultGroup": "*",
        "defaultGraphType": "total"
    }
};

/*
[
  {
    "conditions": 6,
    "observations": 3,
    "medications": 1,
    "EventName": "ImportEMR",
    "EmrId": "f30890fc-a521-4dd6-a6ce-c7d5b9f47479",
    "PatientId": "3bbe8302-35c8-4a3a-a3e1-4e9fe0838339"
  }
]
*/

export class SamplesReport extends Component {
    constructor(props) {
        super(props);

        const graphType = localStorage.getItem('SamplesReport.graphType') || 'total';
        const eventName =  localStorage.getItem('SamplesReport.eventName') || 'ImportEMR';
        const groupBy = localStorage.getItem('SamplesReport.groupBy') || '*';
        const sample = localStorage.getItem('SamplesReport.sample') || 'observations';

        this.state = {
            graphType,
            eventName,
            sample,
            groupBy,
            series: [],
            group: '*',
            groups: []
        }
    }

    componentDidMount() {
        this.loadMetrics();
    }

    componentDidUpdate(oldProps) {
        if (JSON.stringify(oldProps.args) !== JSON.stringify(this.props.args))
            this.loadMetrics();
    }

    getSeries(samples) {
        let groups = {};
        groups["*"] = { name: '*', data: [] };

        const { groupBy, graphType, eventName } = this.state;
        console.log(`getSeries, eventName: ${eventName}, groupBy: ${groupBy}, graphType: ${graphType}, samples:`, samples );

        for (let date in samples) {
            const time = new Date(date).getTime();
            const day = samples[date];
            console.log("day:", day );

            // we need to handle things if the data is grouped 
            if ( groupBy !== '*' ) {
                for(let k in day) {
                    if (!groups[k]) groups[k] = { name: k, data: [] };
                    let sample = Number(day[k][graphType]); 
                    console.log(`k: ${k}, time: ${time}, sample: ${sample}`)
                    groups[k].data.push([time, sample]);
                }

                let dayValues = Object.values(day);
                if ( dayValues.length > 0 ) {
                    let daySample = undefined;
                    if ( graphType === 'average' ) {
                        daySample = dayValues.reduce((p,c) => p + c.average, 0) / dayValues.length;       // average the average of all the groups
                    } else if ( graphType === 'min') {
                        daySample = dayValues.reduce((p,c) => p < c.min ? p : c.min, dayValues[0].min);               // get the min from all the groups
                    } else if ( graphType === 'max' ) {
                        daySample = dayValues.reduce((p,c) => p > c.max ? p : c.max, dayValues[0].max);               // get the max from all the groups
                    } else if ( graphType === 'total') {
                        daySample = dayValues.reduce((p,c) => p + c.total,0);
                    } else if ( graphType === 'count') {
                        daySample = dayValues.reduce((p,c) => p + c.count,0);
                    }

                    if (daySample !== undefined) {
                        console.log(`time: ${time}, daySample: ${daySample}`)
                        groups["*"].data.push([time, Number(daySample)]);
                    }
                }
            } else {
                // no grouping on the data
                groups["*"].data.push([time, Number(day[graphType])])
            }
        }

        console.log("groups:", groups );

        // sort all the data in the series by their time
        let series = [];
        for (let k in groups) {
            let g = groups[k];
            g.data.sort((a, b) => a[0] - b[0]);
            series.push(g);
        }

        console.log("getSeries result:", series );
        return series;
    }

    loadMetrics() {
        const { appContext } = this.props;

        const { eventName, sample, groupBy, group } = this.state;
        const { userId } = this.props.args;

        let args = [ 
            `EventName=${eventName}`,
            `sample=${sample}`
        ];
        if ( groupBy !== '*') {
            args.push(`groupBy=${groupBy}`)
        }

        let updateGroups = true;
        if (userId) {
            args.push(`userId=${userId}`);
            updateGroups = false;           // we don't want to update our groups when the user is picked
        }
        if (group && group !== '*') {
            // limit our query to metrics with the group we actually need
            args.push(`key=${groupBy}`);
            args.push(`value=${encodeURIComponent(group)}`)
            updateGroups = false;
        }

        this.props.parent.setState({ progress: <CircularProgress size={20} />, error: null });
        getMetrics(appContext, args).then((result) => {
            let { groups } = this.state;
            let { samples } = result;

            let series = this.getSeries(samples);
            if (updateGroups) {
                groups = [];
                for (let i = 0; i < series.length; ++i) {
                    groups.push(series[i].name);
                }
                groups.sort();
                //groups.unshift('*');            // put the no group at the top
            }

            // filter the data down to the specific group, we could allow all series and multiple graphs to get displayed at some point if we like
            if ( group !== '*')
                series = series.filter((e) => e.name === group );     
            let graph = <DurationGraph
                name={sample}
                series={series}
            />
            let groupItems = [];
            for (let i = 0; i < groups.length; i++) {
                let g = groups[i];
                groupItems.push(<MenuItem key={g} value={g}>{g}</MenuItem>);
            }
            let groupSelect = <FormControl style={styles.text}>
                <InputLabel>Group:</InputLabel>
                <Select value={group} onChange={(e) => { this.setState({ group: e.target.value }, this.loadMetrics.bind(this)) }}>
                    {groupItems}
                </Select>
            </FormControl>;

            const eventSelect = <FormControl style={styles.text}>
                <InputLabel>Event Name:</InputLabel>
                <Select value={eventName} onChange={(e) => {

                    const metricType = METRIC_TYPES[e.target.value];
                    const sample = metricType.defaultSample || metricType.sample[0];
                    const groupBy = metricType.defaultGroup || metricType.groupBy[0];
                    const graphType = metricType.defaultGraphType || "count";

                    localStorage.setItem("SamplesReport.eventName", e.target.value);
                    localStorage.setItem("SamplesReport.sample", sample);
                    localStorage.setItem("SamplesReport.groupBy", groupBy);
                    localStorage.setItem("SamplesReport.graphType", graphType);
                    
                    this.setState({ 
                        eventName: e.target.value, 
                        sample, 
                        groupBy,
                        graphType
                    }, this.loadMetrics.bind(this));
                }}>
                    {Object.keys(METRIC_TYPES).map((eventName) => {
                        return <MenuItem key={eventName} value={eventName}>{eventName}</MenuItem>
                    })}
                </Select>
            </FormControl>;

            const sampleSelect = <FormControl style={styles.text}>
                <InputLabel>Sample:</InputLabel>
                <Select value={sample} onChange={(e) => {
                    localStorage.setItem("SamplesReport.sample", e.target.value);
                    this.setState({ sample: e.target.value }, this.loadMetrics.bind(this));
                }}>
                    {Object.values(METRIC_TYPES[eventName].sample).map((v) => {
                        return <MenuItem key={v} value={v}>{v}</MenuItem>
                    })}
                </Select>
            </FormControl>;

            const groupBySelect = <FormControl style={styles.text}>
                <InputLabel>Group By:</InputLabel>
                <Select value={groupBy} onChange={(e) => {
                    localStorage.setItem("SamplesReport.groupBy", e.target.value);
                    this.setState({ groupBy: e.target.value, group: '*' }, this.loadMetrics.bind(this));
                }}>
                    {Object.values(METRIC_TYPES[eventName].groupBy).map((v) => {
                        return <MenuItem key={v} value={v}>{v}</MenuItem>
                    })}
                </Select>
            </FormControl>;

            this.setState({ eventSelect, sampleSelect, groupBySelect, groupSelect, groups, series, graph });
            this.props.parent.setState({ progress: null });
        }).catch(function (error) {
            console.log("loadMetrics error:", error);
            this.props.parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    render() {
        const { dialog, graph, eventSelect, sampleSelect, groupBySelect, groupSelect, graphType, eventName } = this.state;
        const selectGraphType = METRIC_TYPES[eventName].selectGraphType;

        const graphTypeSelect = <FormControl style={styles.text}>
            <InputLabel>Graph Type:</InputLabel>
            <Select value={graphType} onChange={(e) => {
                localStorage.setItem("SamplesReport.graphType", e.target.value);
                this.setState({ graphType: e.target.value }, this.loadMetrics.bind(this))
            }}>
                <MenuItem value={'total'}>Total</MenuItem>
                <MenuItem value={'count'}>Count</MenuItem>
                <MenuItem value={'average'}>Average</MenuItem>
                <MenuItem value={'min'}>Min</MenuItem>
                <MenuItem value={'max'}>Max</MenuItem>
            </Select>
        </FormControl>;

        return (
            <table style={{ width: '95%' }}><tbody>
                <tr>
                    <td colSpan={2}>
                        {eventSelect}
                        {graph && selectGraphType && graphTypeSelect}
                        {sampleSelect}
                        {groupBySelect}
                        {groupSelect}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {graph}
                    </td>
                </tr>
                {dialog}
            </tbody></table>
        );
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5,
        width: 250
    }, number: {
        margin: 5,
        width: 100
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    }
}

export default SamplesReport;
