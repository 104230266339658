import React, { Component } from 'react';
import {
    AppBar, Toolbar, Typography, Button, CircularProgress, TextField, Drawer,
    IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tabs, Tab,
    FormControlLabel, Checkbox, Tooltip
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import TestResultView from './TestResultView';
import AssignmentIcon from '@material-ui/icons/Assignment'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import RunTestIcon from '@material-ui/icons/PlayArrow';
import CreateTestCaseIcon from '@material-ui/icons/Create';

import Axios from 'axios';
import Moment from 'moment';
import Config from '@apricityhealth/web-common-lib/Config';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';

const fileDownload = require('js-file-download');

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

function formatDate(date) {
    if (typeof date === 'string')
        date = Moment(date).toDate();
    if (date.getFullYear) {
        return date.getFullYear() + "-" + addZero((date.getMonth() + 1)) + "-" +
            addZero(date.getDate()) + "T" + addZero(date.getHours()) + ":" + addZero(date.getMinutes());
    }
    return null;
}

class TestView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            test: null,
            results: [],
            errors: [],
            topTab: 'inputs',
            bottomTab: '0'
        }
    }
    componentWillMount() {
        console.log("componentWillMount:", this);
        const { test, planId, testId } = this.props;
        if (test)
            this.loadTest(test);
        else if (planId && testId)
            this.getTest(planId, testId);
    }
    componentWillReceiveProps(props) {
        const { test, planId, testId } = props;
        if (test !== this.props.test)
            this.loadTest(test);
        else if (planId !== this.props.planId || testId !== this.props.testId)
            this.getTest(planId, testId);
    }
    closeTest() {
        console.log("closeTest()", this);
        this.props.onClose();
    }

    getTest(planId, testId) {
        const self = this;
        const getTest = {
            url: Config.baseUrl + `${Config.pathPrefix}test/${planId}/${testId}`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };
        console.log("getTest:", getTest);
        this.setState({ rule: null, progress: <CircularProgress /> });

        Axios(getTest).then((res) => {
            console.log("getTest results:", res);
            self.loadTest(res.data.test);
        }).catch((err) => {
            console.log("getTest error:", err);
            if (self.props.onNewTest) {
                self.loadTest(self.props.onNewTest());
            }
            else {
                self.setState({ progress: err.message });
            }
        });
    }

    updateErrors(planId, testId) {
        const self = this;
        return new Promise((resolve, reject) => {
            const getErrors = {
                url: Config.baseUrl + `${Config.pathPrefix}test/errors/${planId}/${testId}`,
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            console.log("getErrors:", getErrors);
            Axios(getErrors).then((result) => {
                console.log("getErrors result:", result);
                let errors = result.data.errors;
                self.setState({ errors }, () => { resolve(errors) });
            }).catch((err) => {
                console.log("getErrors error:", err);
                reject(err);
            });
        });
    }

    updateResults(planId, testId) {
        const self = this;
        return new Promise((resolve, reject) => {
            const getResults = {
                url: Config.baseUrl + `${Config.pathPrefix}test/results/${planId}/${testId}`,
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            console.log("getResults:", getResults);
            Axios(getResults).then((result) => {
                console.log("getResults result:", result);
                let results = result.data.results;
                self.setState({ results }, () => { resolve(results) });
            }).catch((err) => {
                console.log("getResults error:", err);
                reject(err);
            });
        });
    }

    loadTest(test, errors, results) {
        this.updateErrors(test.planId, test.testId);
        this.updateResults(test.planId, test.testId);
        this.setState({
            test,
            progress: null,
            dialog: null
        });
    }

    saveTest() {
        console.log("saveTest()", this);
        const self = this;
        const { appContext } = this.props;
        const { test } = this.state;

        self.setState({ progress: <CircularProgress /> });

        const planId = test.planId;
        const saveTest = {
            url: Config.baseUrl + `${Config.pathPrefix}test/${planId}`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: test
        };

        console.log("saveTest:", saveTest);
        Axios(saveTest).then(function (response) {
            console.log("saveTest response:", response);
            self.setState({ test: response.data.test, progress: null });
        }).catch(function (error) {
            console.log("saveTest error:", error);
            self.setState({ progress: error.message });
        });
    }

    deleteTest() {
        console.log("deleteTest()", this);
        const self = this;
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Test: {`${self.state.test.testId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteTest() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDialog() {
        this.setState({ dialog: null });
    }

    confirmDeleteTest() {
        const self = this;
        const test = self.state.test;
        const planId = test.planId;

        self.setState({ dialog: null });
        if (test.testId) {
            const deleteTest = {
                url: Config.baseUrl + `${Config.pathPrefix}test/${planId}/${test.testId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteTest:", deleteTest);
            Axios(deleteTest).then(function (response) {
                console.log("deleteTest response:", response);
                self.setState({ progress: null });
                self.closeTest();
            }).catch(function (error) {
                console.log("deleteTest error:", error);
                self.setState({ progress: error.message });
            });
        }
    }

    deleteErrors() {
        const self = this;
        const { appContext } = this.props;
        const { test } = this.state;

        const deleteErrors = {
            url: Config.baseUrl + `${Config.pathPrefix}test/errors/${test.planId}/${test.testId}`,
            method: 'DELETE',
            headers: { "Authorization": appContext.state.idToken }
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("deleteErrors:", deleteErrors);
        Axios(deleteErrors).then((response) => {
            test.errors = [];
            self.setState({ progress: null, errors: [] });
            console.log("deleteErrors response:", response);
        }).catch((err) => {
            self.setState({ progress: null });
            console.log("deleteErrors error:", err);
        });
    }

    deleteResults() {
        const self = this;
        const { appContext } = this.props;
        const { test } = this.state;

        const deleteResults = {
            url: Config.baseUrl + `${Config.pathPrefix}test/results/${test.planId}/${test.testId}`,
            method: 'DELETE',
            headers: { "Authorization": appContext.state.idToken }
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("deleteResults:", deleteResults);
        Axios(deleteResults).then((response) => {
            test.errors = [];
            self.setState({ progress: null, results: [] });
            console.log("deleteResults response:", response);
        }).catch((err) => {
            self.setState({ progress: null });
            console.log("deleteResults error:", err);
        });
    }

    runTest() {
        console.log("runTest()");
        const self = this;
        const test = self.state.test;
        const planId = test.planId;

        if (test.testId) {
            const runTest = {
                url: Config.baseUrl + `${Config.pathPrefix}test/run/${planId}/${test.testId}`,
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("runTest:", runTest);
            Axios(runTest).then(function (response) {
                console.log("runTest response:", response);
                const message = response.data.message;
                self.setState({ progress: message });
                self.updateErrors(planId, test.testId);
                self.updateResults(planId, test.testId);
            }).catch(function (error) {
                console.log("runTest error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }
    getReport() {
        console.log("getReport()");
        let self = this;
        const { appContext } = self.props;
        const { test } = self.state;
        const planId = test.planId;
        const testId = test.testId;
        const recipe = 'pdf';
        if (testId) {
            this.setState({ progress: <CircularProgress /> });

            let data = {
                reportName: "TestPermutationResults",
                recipe,
                args: {
                    testId,
                    planId
                }
            }

            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
                data,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("getReport:", request);
            Axios(request).then((response) => {
                console.log("getReport response:", response);
                let report = response.data;
                if (recipe === 'pdf')
                    report = Buffer.from(report, 'base64');
                fileDownload(report, `testresults.${recipe}`);
                self.setState({ progress: null, report });
            }).catch((error) => {
                console.log("getReport error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }

    updatePatientConditions(conditions) {
        //console.log("updatePatientConditions:", this, conditions);
        let test = this.state.test;
        test.patient.conditions = conditions;
        this.setState({ test });
    }

    updatePatientMedications(medications) {
        //console.log("updatePatientMedications:", this, medications);
        let test = this.state.test;
        test.patient.medications = medications;
        this.setState({ test });
    }

    updateFlags(flags) {
        //console.log("updatePatientFlags:", this, flags);
        let test = this.state.test;
        test.flags = flags;
        this.setState({ test });
    }

    updateJournal(journal) {
        console.log("updateJournal:", this, journal);
        let test = this.state.test;
        test.journal = journal;
        this.setState({ test });
    }

    updateHistory(data) {
        //console.log("updateData:", this, data);
        let test = this.state.test;
        test.history = data;
        this.setState({ test });
    }

    updateData(data) {
        //console.log("updateData:", this, data);
        let test = this.state.test;
        test.data = data;
        this.setState({ test });
    }

    onSourceControl(table, value, row, index, id) {
        return <TextField value={JSON.stringify(value)}
            onChange={(e) => table.setDateField(index, id, JSON.parse(e.target.value))} />;
    }

    updateRecommendations(data) {
        //console.log("updateRecommendations:", this, data);
        let test = this.state.test;
        test.recommendations = data;
        this.setState({ test });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._results)
            this._results.setState({ selected: [] });
    }

    onSelectResult(s, t) {
        console.log("onSelectResult()", this, s, t);

        const { appContext } = this.props;

        if (s.length > 0) {
            const result = this.state.results[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <TestResultView appContext={appContext} result={result} onClose={this.closeDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    onToggleCase() {
        console.log("onToggleCase");
        const { test } = this.state;
        if (test.case === undefined)
            test.case = { patientId: '', eventTime: new Date() };
        else
            delete test.case;
        this.setState({ test });
    }

    createTestCase(patientId, eventTime) {
        console.log("deleteTest()", this);
        const self = this;
        const { appContext, planId } = this.props;
        const { test } = this.state;

        if (!patientId)
            patientId = '';
        if (!eventTime)
            eventTime = new Date();

        let testCase = JSON.parse(JSON.stringify(test));
        testCase.case = { patientId, eventTime };
        delete testCase.testId;

        this.setState({
            testCase,
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Create Test Case</DialogTitle>
                    <DialogContent>
                        <SelectPatient style={styles.select}
                            appContext={appContext}
                            planId={planId}
                            patientId={testCase.case.patientId}
                            onSelect={(v) => { self.createTestCase(v, testCase.eventTime) }} />
                        <TextField label="Select Event Time"
                            type="datetime-local"
                            value={formatDate(testCase.case.eventTime)}
                            onChange={(e) => { self.createTestCase(testCase.patientId, Moment(e.target.value).toDate()) }} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmCreateTestCase() }}>Create</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    confirmCreateTestCase() {
        const self = this;
        const { appContext } = this.props;
        const { testCase } = this.state;

        console.log("confirmCreateTestCase()", testCase);
        self.setState({ progress: <CircularProgress /> });

        const planId = testCase.planId;
        const saveTest = {
            url: Config.baseUrl + `${Config.pathPrefix}test/${planId}`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: testCase
        };

        console.log("saveTest:", saveTest);
        Axios(saveTest).then(function (response) {
            console.log("saveTest response:", response);
            self.setState({ dialog: null, testCase: null, progress: null });
        }).catch(function (error) {
            console.log("saveTest error:", error);
            self.setState({ dialog: null, progress: error.message });
        });
    }

    render() {
        const self = this;
        const { appContext, planId } = this.props;
        const { test, errors, results, dialog, progress, topTab } = this.state;
        let { bottomTab } = self.state;

        const appBar = this.props.onClose ?
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => self.props.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">Test Template</Typography>
                </Toolbar>
            </AppBar> : null;

        if (test) {
            if (!test.name)
                test.name = '';
            if (!test.description)
                test.description = '';
            if (!test.patient)
                test.patient = { conditions: [], medications: [] };
            if (!test.flags)
                test.flags = [];
            if (!test.journal)
                test.journal = [];
            if (!test.history)
                test.history = [];

            const flags = test.flags || [];
            const flagsColumns = [
                { id: 'flagId', numeric: false, disabledPadding: false, label: 'Flag' },
            ];
            const conditions = test.patient.conditions || [];
            const conditionColumns = [
                { id: 'conditionId', numeric: false, disabledPadding: false, label: 'Condition' },
            ];

            const medications = test.patient.medications || [];
            const medicationColumns = [
                { id: 'medicationId', numeric: false, disabledPadding: false, label: 'Medication' },
            ];

            const journal = test.journal || [];
            const journalColumns = [
                { id: '_index', numeric: false, disabledPadding: false, label: 'Order' },
                { id: 'questionId', numeric: false, disabledPadding: false, label: 'Question' },
                { id: 'answerId', numeric: false, disabledPadding: false, label: 'Answer' },
                { id: 'answerText', numeric: false, disabledPadding: false, label: 'Text', editType: 'text' },
            ];

            const history = test.history || [];
            const historyColumns = [
                { id: 'eventTime', numeric: false, disabledPadding: false, label: 'Date', editType: 'date' },
                { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
                { id: 'data', numeric: false, disabledPadding: false, label: 'Values', editType: 'number-array' },
                { id: 'status', numeric: false, disabledPadding: false, label: 'Status', editType: 'text' }
            ];

            const data = test.data || [];
            const dataColumns = [
                { id: '_index', numeric: false, disabledPadding: false, label: 'Order' },
                { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
                { id: 'tupleIndex', numeric: false, disabledPadding: false, label: 'Tuple Index' },
                { id: 'value', numeric: false, disabledPadding: false, label: 'Value', editType: 'text' },
            ];

            const recommendations = test.recommendations;
            const recommendationsColumns = [
                { id: '_index', numeric: false, disabledPadding: false, label: 'Order' },
                { id: 'recommendId', numeric: false, disabledPadding: false, label: 'Recommend ID' },
                { id: 'name', numeric: false, disabledPadding: false, label: 'Name', editType: 'text' }
            ];

            const errorColumns = [
                { id: 'createDate', numeric: false, disabledPadding: false, label: 'Date' },
                { id: 'message', numeric: false, disabledPadding: false, label: 'Message' }
            ];
            const resultColumns = [
                { id: 'createDate', numeric: false, disabledPadding: false, label: 'Date' },
                { id: 'errs', numeric: false, disabledPadding: false, label: 'Errors', formatValue: (v, r) => { return v ? `${v.length}` : '0'; } }
            ];

            const topTabs = <Tabs style={styles.tab} variant="scrollable" value={topTab}
                onChange={(e, t) => self.setState({ topTab: `${t}` })} >
                <Tab label={'Inputs'} value={'inputs'} />
                <Tab label={'Expected Output'} value={'outputs'} />
                <Tab label={'Last Errors'} value={'errors'} />
                <Tab label={'Results'} value={'results'} />
            </Tabs>;

            if (topTab === 'inputs') {
                if (bottomTab > '4')
                    bottomTab = '4';
                var bottomTabs = <Tabs style={styles.tab} variant="scrollable" value={bottomTab}
                    onChange={(e, t) => self.setState({ bottomTab: `${t}` })} >
                    <Tab label={`Flags (${flags.length || '0'})`} value={'0'} />
                    <Tab label={`Conditions (${conditions.length || '0'})`} value={'1'} />
                    <Tab label={`Medications (${medications.length || '0'})`} value={'2'} />
                    <Tab label={`Historical Data (${history.length || '0'})`} value={'3'} />
                    <Tab label={`Conversation (${journal.length || '0'})`} value={'4'} />
                </Tabs>;
            } else if (topTab === 'outputs') {
                if (bottomTab < '5')
                    bottomTab = '5';
                else if (bottomTab > '6')
                    bottomTab = '6';

                bottomTabs = <Tabs style={styles.tab} variant="scrollable" value={bottomTab}
                    onChange={(e, t) => self.setState({ bottomTab: `${t}` })} >
                    <Tab label={`Data (${data.length || '0'})`} value={'5'} />
                    <Tab label={`Recommendations (${recommendations.length || '0'})`} value={'6'} />
                </Tabs>;
            } else if (topTab === 'errors') {
                bottomTab = '7';
                bottomTabs = null;
            } else if (topTab === 'results') {
                bottomTab = '8';
                bottomTabs = null;
            }

            let tabs = [
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='flagId'
                        columnData={flagsColumns}
                        onDataChanged={this.updateFlags.bind(this)}
                        data={flags}
                        rowsPerPage={5}
                        title='Flags' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='_index'
                        columnData={conditionColumns}
                        onDataChanged={this.updatePatientConditions.bind(this)}
                        data={conditions}
                        rowsPerPage={5}
                        title='Conditions' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='_index'
                        columnData={medicationColumns}
                        onDataChanged={this.updatePatientMedications.bind(this)}
                        data={medications}
                        rowsPerPage={5}
                        title='Medications' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='eventTime'
                        columnData={historyColumns}
                        onDataChanged={this.updateHistory.bind(this)}
                        data={history}
                        rowsPerPage={5}
                        title='Historical Data' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='_index'
                        columnData={journalColumns}
                        onDataChanged={this.updateJournal.bind(this)}
                        data={journal}
                        rowsPerPage={5}
                        title='Journal' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='_index'
                        columnData={dataColumns}
                        onDataChanged={this.updateData.bind(this)}
                        data={data}
                        rowsPerPage={5}
                        title='Data' />
                </div>,
                <div>
                    <EnhancedTable
                        order='asc'
                        orderBy='_index'
                        columnData={recommendationsColumns}
                        onDataChanged={this.updateRecommendations.bind(this)}
                        data={recommendations}
                        rowsPerPage={5}
                        title='Recommendations' />
                </div>,
                <div align="right">
                    <EnhancedTable
                        disableActions={true}
                        order='desc'
                        orderBy='createDate'
                        columnData={errorColumns}
                        data={errors}
                        rowsPerPage={5}
                        title='Errors' />
                    <Button variant="contained" style={styles.button} onClick={() => { self.deleteErrors(); }}>Delete Errors</Button>
                </div>,
                <div align="right">
                    <EnhancedTable
                        onSelected={this.onSelectResult.bind(this)}
                        onTable={table => self._results = table}
                        disableActions={true}
                        order='desc'
                        orderBy='createDate'
                        columnData={resultColumns}
                        data={results}
                        rowsPerPage={5}
                        title='Results' />
                    <Button variant="contained" style={styles.button} onClick={() => { self.deleteResults(); }}>Delete Results</Button>
                </div>
            ];

            let caseEnabled = test.case !== null && test.case !== undefined ? true : false;
            console.log("caseEnabled: ", caseEnabled)
            if (caseEnabled) {
                var caseEdit = <div>
                    <SelectPatient style={styles.select}
                        appContext={appContext}
                        planId={planId}
                        patientId={test.case.patientId}
                        onSelect={(v) => { test.case.patientId = v; this.setState({ test }); }} />
                    <TextField label="Select Event Time"
                        type="datetime-local"
                        value={formatDate(test.case.eventTime)}
                        onChange={(e) => { test.case.eventTime = Moment(e.target.value).toDate(); this.setState({ test }) }} />
                </div>;
            }

            let caseInfo = <div>
                <FormControlLabel control={<Checkbox checked={caseEnabled} onChange={this.onToggleCase.bind(this)} />}
                    label="Enable Test Case" />
                {caseEdit}
            </div>;

            var testInfo =
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <p style={{ margin: 5 }}>Test ID: {test.testId}</p>
                            </td>
                            <td align="right" valign="top">
                                <Tooltip title='Save'><IconButton onClick={this.saveTest.bind(this)}><SaveIcon /></IconButton></Tooltip>
                                <Tooltip title='Delete'><IconButton onClick={this.deleteTest.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                                <Tooltip title='Run Test'><IconButton onClick={this.runTest.bind(this)}><RunTestIcon /></IconButton></Tooltip>
                                <Tooltip title='Get Report'><IconButton onClick={this.getReport.bind(this)}><AssignmentIcon /></IconButton></Tooltip>
                                <Tooltip title='Create Test Case'><IconButton onClick={this.createTestCase.bind(this)}><CreateTestCaseIcon /></IconButton></Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <TextField style={styles.text} value={test.name} label="Name"
                                    onChange={(e) => { test.name = e.target.value; self.setState({ test }); }} />
                                <TextField style={styles.description} value={test.description} label="Description"
                                    onChange={(e) => { test.description = e.target.value; self.setState({ test }); }} />
                                <TextField style={styles.text} value={test.jiraIssue} label="Jira"
                                    onChange={(e) => { test.jiraIssue = e.target.value; self.setState({ test }); }} />
                                <TextField style={styles.text} value={test.category} label="Category"
                                    onChange={(e) => { test.category = e.target.value; self.setState({ test }); }} />
                                <br />
                                {caseInfo}
                                <br />
                                {topTabs}
                                {bottomTabs}
                                {tabs[bottomTab]}
                            </td>
                        </tr>
                    </tbody>
                </table>;
        }

        return (
            <div style={{ width: 1400 }}>
                {appBar}
                {testInfo}
                {progress}
                {dialog}
            </div>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: '80%'
    },
    table: {
        margin: 5,
        width: '100%'
    },
    select: {
        margin: 5,
        width: '50%'
    },
    button: {
        margin: 5
    },
    div: {
        margin: 5
    },
    panel: {
        margin: 5
    },
    tab: {
        "backgroundColor": "#FFCC80",
        "width": "100%"
    }
}

export default TestView;
