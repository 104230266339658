//import Config from '@apricityhealth/web-common-lib/Config';
import React, { Component } from 'react';
import {
    AppBar, Toolbar, Typography, 
    IconButton, Tabs, Tab, Drawer
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import JSONView from './JSONView';

class TestResultView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: props.result,
            topTab: 'errors'
        }
    }
    componentWillMount() {
        console.log("componentWillMount:", this);
    }
    componentWillReceiveProps(props) {
        if ( props.result !== this.props.result )
            this.setState( {result: props.result });
    }
    closeTest() {
        console.log("closeTest()", this);
        this.props.onClose();
    }

    onCloseDetails() {
        this.state.table.setState({selected: [] });     //  de-select the item from the table
        this.setState({dialog: null});
    }

    onShowDetails(selected, table) {
        if ( selected.length > 0 ) {
            const details = table.props.data[selected[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <JSONView appContext={this.props.appContext} data={details} onClose={this.onCloseDetails.bind(this)} />
            </Drawer>;
            this.setState({ dialog, table });
        }
    }

    render() {
        const self = this;
        const { result, dialog, progress, topTab } = self.state;

        const appBar = this.props.onClose ?
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => self.props.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">Test Result</Typography>
                </Toolbar>
            </AppBar> : null;

        if (result) {
            const dataColumns = [
                { id: 'eventTime', numeric: false, disabledPadding: false, label: 'Date' },
                { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
                { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v) => { return Array.isArray(v) ? '[' + v.join(',') + ']' : v } },
                { id: 'source', numeric: false, disabledPadding: false, label: 'Source', formatValue: (v) => { return JSON.stringify(v) } },
            ];

            const recommendationsColumns = [
                { id: '_index', numeric: false, disabledPadding: false, label: 'Order' },
                { id: 'recommendId', numeric: false, disabledPadding: false, label: 'Recommend ID' },
                { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
                { id: 'description', numeric: false, disabledPadding: false, label: 'Description' }
            ];
            const errorColumns = [
                { id: 'createDate', numeric: false, disabledPadding: false, label: 'Date' },
                { id: 'message', numeric: false, disabledPadding: false, label: 'Message' }
            ];

            const topTabs = <Tabs style={styles.tab} variant="scrollable" value={topTab}
                onChange={(e, t) => self.setState({ topTab: `${t}` })} >
                <Tab label={'Errors'} value={'errors'} />
                <Tab label={'Warnings'} value={'warnings'} />
                <Tab label={'Data'} value={'data'} />
                <Tab label={'Recommendations'} value={'recommendations'} />
            </Tabs>;

            let tab = null;
            if (topTab === 'errors') {
                tab = <EnhancedTable
                    disableMultiSelect={true}
                    disableActions={true}
                    order='desc'
                    orderBy='createDate'
                    columnData={errorColumns}
                    data={result.errs}
                    rowsPerPage={25}
                    onSelected={this.onShowDetails.bind(this)}
                    title='Errors' />;
            } else if ( topTab === 'warnings' ) {
                tab = <EnhancedTable
                    disableMultiSelect={true}
                    disableActions={true}
                    order='desc'
                    orderBy='createDate'
                    columnData={errorColumns}
                    data={result.warnings}
                    rowsPerPage={25}
                    onSelected={this.onShowDetails.bind(this)}
                    title='Warnings' />;
            } else if (topTab === 'data') {
                tab = <EnhancedTable
                    disableMultiSelect={true}
                    disableActions={true}
                    order='asc'
                    orderBy='eventTime'
                    columnData={dataColumns}
                    data={result.data}
                    rowsPerPage={25}
                    onSelected={this.onShowDetails.bind(this)}
                    title='Data' />;
            } else if (topTab === 'recommendations') {
                tab = <EnhancedTable
                    disableMultiSelect={true}
                    disableActions={true}
                    order='asc'
                    orderBy='_index'
                    columnData={recommendationsColumns}
                    data={result.recommendations}
                    rowsPerPage={25}
                    onSelected={this.onShowDetails.bind(this)}
                    title='Recommendations' />;
            }

            var testResult =
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <p style={{ margin: 5 }}>Test ID: {result.testId}</p>
                            </td>
                        </tr>
                        <tr>
                            <td align="left">
                                {topTabs}
                                {tab}
                            </td>
                        </tr>
                    </tbody>
                </table>;
        }

        return (
            <div align="center" height="100%">
                {appBar}
                {testResult}
                {progress}
                {dialog}
            </div>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: '80%'
    },
    table: {
        margin: 5,
        width: 1024
    },
    select: {
        margin: 5,
        width: '50%'
    },
    button: {
        margin: 5
    },
    div: {
        margin: 5
    },
    panel: {
        margin: 5
    },
    tab: {
        "backgroundColor": "#FFCC80",
        "width": "100%"
    }
}

export default TestResultView;
