import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    CircularProgress,
    IconButton,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Paper,
    Tooltip,
    ListItemText,
    Tabs,
    Tab
} from '@material-ui/core/';
import { Navigate } from 'react-router-dom';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";
import SelectLocation from '@apricityhealth/web-common-lib/components/SelectLocation';
import NavigationClose from '@material-ui/icons/Close';
import Schedule from '@material-ui/icons/Schedule';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment'
import CodeIcon from '@material-ui/icons/Code';
import UsersIcon from '@material-ui/icons/Person';
import LogsIcon from '@material-ui/icons/DataUsage';
//import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import SelectUser from '../../components/SelectUser';
import ReactJson from 'react-json-view';
import ProviderOrgsView from './ProviderOrgsView';
import HistoricalProviderView from '../HistoricalProviderView/HistoricalProviderView';

import OwnerInfo from '@apricityhealth/web-common-lib/components/OwnerInfo'
import Config from '@apricityhealth/web-common-lib/Config';
import {
    getProvider
} from '@apricityhealth/web-common-lib/utils/Services';

import Axios from 'axios';
import moment from 'moment';
import User from '@apricityhealth/web-common-lib/components/User';

const TIMEZONE = (Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTZ');
const fileDownload = require('js-file-download');

const GROUPS = {
    administrators: 'Administrators',
    support: 'Support',
    employer: 'Employers',
    auditors: 'Auditors',
    debug: 'Debug',
    experts: 'Experts',
    nophi: 'Nophi',
    patients: 'Patients',
    providers: 'Providers'
};

function isValidDate(d) {
    let ts = Date.parse(d);
    if (isNaN(ts))
        return false;
    else
        return true;
}

class ProviderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'record',
            currentTab: 'Organizations',
            progress: null,
            provider: null
        }
    }

    resetProvider() {
        this.setState({
            provider: {
                timezone: TIMEZONE,
                providerId: '',
                title: '',
                lastName: '',
                firstName: '',
                emails: [{ emailType: 'primary', email: '', level: '0' }],
                phoneNumbers: [{ numberType: 'mobile', number: '', level: '0' }],
                orgId: '',
                roleType: 'nurse',
                validationDate: null,
                groups: ['providers'],
                inactive: false
            }
        });
    }

    closeProvider() {
        this.props.onClose();
    }

    componentDidMount() {
        this.resetProvider();
        this.loadProvider(this.props.providerId);
    }

    componentDidUpdate(oldProps) {
        if (this.props.providerId !== oldProps.providerId) {
            this.loadProvider(this.props.providerId);
        }
    }

    showScheduleInstructions() {
        const self = this;
        var d = new Date();
        var minutes = d.getTimezoneOffset();
        var hours = minutes / 60;
        var day = d.getDay()
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        self.setState({
            dialog: <Dialog model="false" open={true}>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            You are {hours} hours off UTC. Please add {hours} to the schedule time. <br />
                            Your time zone is {tzid} and the current  day is {day}  <br />
                            <br /><br />
                            The schedule string follows the following format: (day,hour, minute),
                            <ul>
                                <li> * is wildcard, ranges are seperated with a dash (-) </li>
                                <li> *,0-12,0 = Any day, between midnight (0:00) and noon (12:00) </li>
                                <li> 6-0,*,* = Saturday & Sunday, any time </li>
                                <li> Day 0 is Sunday.</li>
                                <li> No spaces nor parenthesis</li>
                            </ul>
                            Examples:
                            <ul>
                                <li>*,*,0-30 will get notified any day but only within the first 30 moin</li>
                                <li>*,*,31-59 will get notified any day but only in the second half of the hour</li>
                            </ul>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={(e) => { this.setState({ dialog: null }) }}>Ok</Button>
                </DialogActions>
            </Dialog>
        });
    }

    loadProvider(providerId) {
        if (providerId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            getProvider(this.props.appContext, providerId).then((provider) => {
                if (!provider) provider = {};
                this.setState({ provider, progress: null });
            }).catch((err) => {
                console.log("getProvider error:", err);
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
        }
        else {
            this.resetProvider();
        }
    }

    saveProvider() {
        const { provider } = this.state;

        // remove old fields
        delete provider.orgId;
        delete provider.roleType;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        const saveProvider = {
            url: Config.baseUrl + `${Config.pathPrefix}providers`,
            method: 'POST',
            headers: { "Authorization": this.props.appContext.state.idToken },
            data: provider
        };

        console.log("saveProvider:", saveProvider);
        Axios(saveProvider).then((response) => {
            console.log("saveProvider response:", response);
            this.setState({ progress: null, provider: response.data.provider });
        }).catch((error) => {
            console.log("saveProvider error:", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deleteProvider() {
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Provider: {`${this.state.provider.providerId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ margin: 5}} onClick={() => { this.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={{ margin: 5}} onClick={() => { this.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const provider = this.state.provider;

        this.setState({ dialog: null });
        if (provider.providerId) {
            const deleteProvider = {
                url: Config.baseUrl + `${Config.pathPrefix}providers/${provider.providerId}`,
                method: 'DELETE',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            this.setState({ progress: <CircularProgress size={20} />, error: null });
            console.log("deleteProvider:", deleteProvider);
            Axios(deleteProvider).then(function (response) {
                console.log("deleteProvider response:", response);
                this.setState({ progress: null });
                this.closeProvider();
            }).catch(function (error) {
                console.log("deleteProvider error:", error.response);
                this.setState({ progress: null, error: error.message });
            });
        }
    }

    getReport() {
        const { appContext } = this.props;
        const { provider } = this.state;
        const providerId = provider.providerId;
        const recipe = 'pdf';
        if (providerId) {
            let data = {
                reportName: "ProviderPatients",
                recipe,
                args: {
                    providerId,
                }
            }

            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
                data,
                method: 'POST',
                headers: { "Authorization": appContext.state.idToken }
            };

            console.log("getReport:", request);
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Axios(request).then((response) => {
                console.log("getReport response:", response);
                let report = response.data;
                if (recipe === 'pdf')
                    report = Buffer.from(report, 'base64');
                fileDownload(report, `DailySummaryReport.${recipe}`);
                this.setState({ progress: null, report });
            }).catch((error) => {
                console.log("getReport error:", error.response);
                this.setState({ progress: null, error: error.message });
            });
        }
    }

    showCode() {
        const { provider } = this.state;
        let dialog = <Dialog open={true} maxWidth='md' fullWidth={true} onClose={() => this.setState({ dialog: null })}>
            <DialogContent>
                <ReactJson src={provider}
                    collapsed={3} name="provider" collapseStringsAfterLength={64} displayDataTypes={false} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => this.setState({ dialog: null })}>OK</Button>
            </DialogActions>
        </Dialog>;
        this.setState({ dialog });
    }

    onSelectGroup(e) {
        let { provider } = this.state;
        provider.groups = e.target.value;
        this.setState({ provider });
    }

    onOpenUser() {
        const { userId } = this.state.provider;
        this.setState({ redirect: <Navigate to={`/users/${userId}`} />} );
        // let dialog = <Drawer variant="persistent" anchor="right" open={true}>
        //     <UserView
        //         appContext={this.props.appContext}
        //         username={userId}
        //         onClose={this.onCloseDialog.bind(this)}
        //     />
        // </Drawer>;
        // this.setState({ dialog });
    }

    onOpenLogs() {
        const { userId } = this.state.provider;
        this.setState({ redirect: <Navigate to={`/logs?userId=${userId}`} />} );
        // let dialog = <Drawer variant="persistent" anchor="right" open={true}>
        //     <LogsView
        //         appContext={this.props.appContext}
        //         userId={userId}
        //         onClose={this.onCloseDialog.bind(this)}
        //     />
        // </Drawer>;
        // this.setState({ dialog });
    }

    onTimeZone(e) {
        let { provider } = this.state;
        let timezone = e.target.value;
        provider.timezone = (timezone || TIMEZONE);
        this.setState({ provider });
    }

    render() {
        const { appContext } = this.props;
        const { redirect, currentTab, view, provider, progress, dialog } = this.state;
        const { userId } = appContext.state;
        const { error } = this.state;

        if ( redirect ) {
            return redirect;
        }
        
        console.log("appContext.state:", appContext.state );
        const appBar = this.props.onClose ?
            <AppBar style={{ backgroundColor: "#6060B0" }} position="static">
                <Toolbar>
                    <IconButton onClick={this.closeProvider.bind(this)}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Provider {provider ? provider.providerId : ''}</Typography>
                </Toolbar>
            </AppBar> : null;

        let viewContent = null;
        if (view === 'record') {
            if (provider) {
                if (provider.title === undefined) provider.title = '';
                if (provider.firstName === undefined) provider.firstName = '';
                if (provider.middleName === undefined) provider.middleName = '';
                if (provider.lastName === undefined) provider.lastName = '';
                if (provider.credentials === undefined) provider.credentials = '';
                if (provider.suffix === undefined) provider.suffix = '';
                if (provider.NPI === undefined) provider.NPI = '';

                let alertFilterColumns = [
                    { id: 'modelId', label: 'Model ID', editType: 'text' },
                    { id: 'category', label: 'Category', editType: 'text' },
                    { id: 'patientId', label: 'Patient ID', editType: 'text' }
                ];
                const alertFiltersTable = <EnhancedTable
                    rowsPerPage={25}
                    onDataChanged={(data) => {
                        provider.alertFilters = data;
                        this.setState({ provider });
                    }}
                    orderBy='_index'
                    columnData={alertFilterColumns}
                    data={provider.alertFilters}
                    title='Alert Filters' />;

                const emailColumns = [
                    { id: '_index', width: 25, label: 'Order' },
                    {
                        id: 'emailType', editType: 'select', label: 'Type', default: 'primary', selectValues: [
                            { value: 'primary', label: 'Primary' },
                            { value: 'direct', label: 'Direct Email' }
                        ]
                    },
                    { id: 'email', width: 300, editType: 'text', label: 'Email' },
                    {
                        id: 'level', width: 50, editType: 'select', label: 'Level', default: '0', selectValues: [
                            { value: '0', label: 'All' },
                            { value: '1', label: 'Green', style: { color: '#098b34' } },
                            { value: '2', label: 'Yellow', style: { color: '#FCD12A' } },
                            { value: '3', label: 'Red', style: { color: '#e4041a' } }
                        ]
                    },
                    { id: 'schedule', editType: 'text', label: 'Schedule (day,hour,min)' },
                ];
                const emailTable = <EnhancedTable
                    rowsPerPage={2}
                    onDataChanged={(data) => {
                        provider.emails = data;
                        this.setState({ provider });
                    }}
                    orderBy='_index'
                    columnData={emailColumns}
                    data={provider.emails}
                    title='Emails' />;
                const minutes = new Date().getTimezoneOffset();
                const hours = minutes / 60;
                const phoneColumns = [
                    { id: '_index', width: 50, label: 'Order' },
                    {
                        id: 'numberType', editType: 'select', label: 'Type', default: 'mobile', selectValues: [
                            { value: 'mobile', label: 'Mobile' },
                            { value: 'home', label: 'Home' },
                            { value: 'work', label: 'Work' }
                        ]
                    },
                    { id: 'name', editType: 'text', label: 'Name' },
                    { id: 'number', editType: 'phone', label: 'Number' },
                    {
                        id: 'level', width: 150, editType: 'select', label: 'Level', default: '0', selectValues: [
                            { value: '0', label: 'All' },
                            { value: '1', label: 'Green', style: { color: '#098b34' } },
                            { value: '2', label: 'Yellow', style: { color: '#FCD12A' } },
                            { value: '3', label: 'Red', style: { color: '#e4041a' } }
                        ]
                    },
                    { id: 'schedule', editType: 'text', label: `Schedule (day,hour,min): +${hours}` },
                ];
                const phoneTable = <EnhancedTable
                    rowsPerPage={2}
                    onDataChanged={(data) => {
                        provider.phoneNumbers = data;
                        this.setState({ provider });
                    }}
                    orderBy='_index'
                    columnData={phoneColumns}
                    data={provider.phoneNumbers}
                    title='Phone Numbers' />;
                const onCallColumns = [
                    {
                        id: 'orgId', label: 'Organization', editControl: (table, value, row, index, id) => <SelectOrg appContext={appContext} orgId={value} onChange={(orgId) => {
                            table.setDataField(index, id, orgId);
                        }} />
                    },
                    {
                        id: 'locationId', label: 'Location', editControl: (table, value, row, index, id) => <SelectLocation appContext={appContext} orgId={row.orgId} locationId={value} enableNone={true}
                            onChange={(locationId) => {
                                table.setDataField(index, id, locationId);
                            }} />
                    },
                    {
                        id: 'status', editType: 'select', label: 'Status', default: '1', selectValues: [
                            { value: '0', label: 'Off-Duty' },
                            { value: '1', label: '1st Priority' },
                            { value: '2', label: '2nd Priority' },
                            { value: '3', label: '3rd Priority' },
                            { value: '4', label: '4th Priority' },
                            { value: '5', label: '5th Priority' }
                        ]
                    },
                    { id: 'offHours', editType: 'checkbox', label: 'Off Hours' }
                ];
                const onCallTable = <EnhancedTable
                    rowsPerPage={2}
                    onDataChanged={(data) => {
                        provider.onCallStatus = data;
                        this.setState({ provider });
                    }}
                    orderBy='_index'
                    columnData={onCallColumns}
                    data={provider.onCallStatus}
                    title='On Call Status' />;

                let exclusionColumns = [
                    { id: 'start', editType: 'datetime', label: 'Start Date', width: 250 },
                    { id: 'end', editType: 'datetime', label: 'End Date', width: 250 },
                    { id: 'note', editType: 'text', label: 'Note', width: 400 }
                ];
                let exclusionTable = <EnhancedTable
                    rowsPerPage={2}
                    onDataChanged={(data) => {
                        provider.appointmentExclusions = data;
                        this.setState({ provider });
                    }}
                    orderBy='start'
                    order='desc'
                    columnData={exclusionColumns}
                    data={provider.appointmentExclusions}
                    title='Appointment Exclusions' />;

                if (!provider.contactMethod) provider.contactMethod = 'phone';
                if (!provider.roleType) provider.roleType = '';
                if (!provider.groups) provider.groups = ['providers'];

                let headerItems = <div style={{
                    marginLeft: 12,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{ marginLeft: 10, marginTop: 5 }}>
                        <div>Provider ID: {provider.providerId}</div>
                    </div>
                    <div style={{ margin: 5, textAlign: "right" }} valign="top">
                        <span style={{ color: 'red' }}>{error}</span>
                        <Tooltip title="Save"><IconButton disabled={progress !== null} onClick={this.saveProvider.bind(this)}>{progress ? progress : <SaveIcon />}</IconButton></Tooltip>
                        <Tooltip title="Show JSON"><IconButton onClick={this.showCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                        {provider && provider.userId ? <Tooltip title='Open Logs'>
                            <IconButton id='logs' onClick={this.onOpenLogs.bind(this)}><LogsIcon /></IconButton></Tooltip> : null}
                        {provider && provider.userId ? <Tooltip title='Open User'>
                            <IconButton id='user' onClick={this.onOpenUser.bind(this)}><UsersIcon /></IconButton></Tooltip> : null}
                        <Tooltip title="Daily Summary Report"><IconButton onClick={this.getReport.bind(this)}><AssignmentIcon /></IconButton></Tooltip>
                        <Tooltip title="Delete"><IconButton onClick={this.deleteProvider.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                    </div>
                </div>;

                const sectionRow = {
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 15,
                    marginBottom: 15,
                };
                let tabs = {
                    "Organizations": <div key='Organizations' style={sectionRow}>
                        <ProviderOrgsView appContext={appContext} providerId={provider ? provider.providerId : null} />
                    </div>,
                    "On Call Status": <div key='On Call Status' style={sectionRow}>
                        {onCallTable}
                    </div>,
                    "Appointment Exclusions": <div key='Appointment Exclusions' style={sectionRow}>
                        {exclusionTable}
                    </div>,
                    "Emails": <div key='Emails' style={sectionRow}>
                        <IconButton onClick={() => this.showScheduleInstructions()}><Schedule /></IconButton>
                        <Paper style={{ margin: 10 }}>
                            {emailTable}
                        </Paper>
                    </div>,
                    "Phone Numbers": <div key='Phone Numbers' style={sectionRow}>
                        <IconButton onClick={() => this.showScheduleInstructions()}><Schedule /></IconButton>
                        <Paper style={{ margin: 10 }}>
                            {phoneTable}
                        </Paper>
                    </div>,
                    "Alert Filters": <div key='Alert Filters' style={sectionRow}>
                        <Paper style={{ margin: 10 }}>
                            {alertFiltersTable}
                        </Paper>
                    </div>
                };

                let displayTabs = [];
                for (let k in tabs) {
                    displayTabs.push(<Tab key={k} label={k} style={{ color: 'white' }} value={k} />);
                }

                let editProvider =
                    <div>
                        <div style={sectionRow}>
                            <Paper style={{ margin: 10 }}>
                                <TextField style={{width: 250, margin: 5}} label="Provider NPI" value={provider.NPI}
                                    onChange={(e) => { provider.NPI = e.target.value; this.setState({ provider }) }} />

                                <FormControl style={{margin: 5, width: 100 }}>
                                    <InputLabel>Title</InputLabel>
                                    <Select value={provider.title}
                                        onChange={(e) => {
                                            provider.title = e.target.value;
                                            this.setState({ provider });
                                        }} >
                                        <MenuItem value={''}>-NONE-</MenuItem>
                                        <MenuItem value={'Dr'}>Dr</MenuItem>
                                        <MenuItem value={'Mr'}>Mr</MenuItem>
                                        <MenuItem value={'Mrs'}>Mrs</MenuItem>
                                        <MenuItem value={'Ms'}>Ms</MenuItem>
                                        <MenuItem value={'Miss'}>Miss</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <TextField style={{width: 250, margin: 5}} label="First Name" value={provider.firstName}
                                    onChange={(e) => { provider.firstName = e.target.value; this.setState({ provider }); }} />
                                <TextField style={{width: 150, margin: 5}} label="Middle Name" value={provider.middleName}
                                    onChange={(e) => { provider.middleName = e.target.value; this.setState({ provider }); }} />
                                <TextField style={{width: 250, margin: 5}} label="Last Name" value={provider.lastName}
                                    onChange={(e) => { provider.lastName = e.target.value; this.setState({ provider }); }} />
                                <FormControl style={{width: 100, margin: 5}}>
                                    <InputLabel>Suffix</InputLabel>
                                    <Select value={provider.suffix}
                                        onChange={(e) => {
                                            provider.suffix = e.target.value;
                                            this.setState({ provider });
                                        }} >
                                        <MenuItem value={''}>-NONE-</MenuItem>
                                        <MenuItem value={'Jr'}>Jr</MenuItem>
                                        <MenuItem value={'Sr'}>Sr</MenuItem>
                                        <MenuItem value={'I'}>I</MenuItem>
                                        <MenuItem value={'II'}>II</MenuItem>
                                        <MenuItem value={'III'}>III</MenuItem>
                                    </Select>
                                </FormControl>
                                <br></br>
                                <FormControl style={{margin: 5, width: 200}}>
                                    <InputLabel labelId="credentials">Credentials</InputLabel>
                                    <Select
                                        labelId="credentials"
                                        value={provider.credentials}
                                        onChange={(e) => {
                                            provider.credentials = e.target.value;
                                            this.setState({ provider });
                                        }} >
                                        <MenuItem value={''}>-NONE-</MenuItem>
                                        <MenuItem value={'MD'}>MD</MenuItem>
                                        <MenuItem value={'RN'}>RN</MenuItem>
                                        <MenuItem value={'PT'}>PT</MenuItem>
                                        <MenuItem value={'OT'}>OT</MenuItem>
                                        <MenuItem value={'SLP'}>SLP</MenuItem>
                                        <MenuItem value={'RD'}>RD</MenuItem>
                                        <MenuItem value={'SW'}>SW</MenuItem>
                                        <MenuItem value={'Administrator'}>Administrator</MenuItem>
                                        <MenuItem value={'Aesthetician'}>Aesthetician</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={{margin: 5, width: 200}}>
                                <InputLabel>Contact Method:</InputLabel>
                                <Select value={provider.contactMethod}
                                    onChange={(e) => {
                                        provider.contactMethod = e.target.value;
                                        this.setState({ provider });
                                    }} >
                                    <MenuItem value={'email'}>Email</MenuItem>
                                    <MenuItem value={'phone'}>Phone</MenuItem>
                                </Select></FormControl>                                
                                <TextField required select component='form' name='timezone' label='Timezone' style={{margin: 5, width: 250}}
                                    value={provider.timezone || 'UTC'} onChange={(e) => this.onTimeZone(e)}
                                    children={moment.tz.names().map((name) => <MenuItem button key={name} value={name}>{name}</MenuItem>)} />
                                <br />
                                <SelectUser
                                    appContext={appContext}
                                    userId={provider.userId}
                                    label='User'
                                    enableNone={true}
                                    onChange={(userId) => {
                                        console.log("selectUser:", userId);
                                        provider.userId = userId;
                                        this.setState({ provider })
                                    }}
                                />
                                <FormControl style={{ width: 400, margin: 5 }}>
                                    <InputLabel>Select Provider Groups</InputLabel>
                                    <Select multiple value={provider.groups} renderValue={(selected) => selected.join(', ')} onChange={this.onSelectGroup.bind(this)}>
                                        {Object.keys(GROUPS).sort().map((k) => <MenuItem value={k}><Checkbox checked={provider.groups.indexOf(k) >= 0} /><ListItemText primary={GROUPS[k]} /></MenuItem>)}
                                    </Select>
                                </FormControl>
                                <br />
                                <span style={{margin: 5}}>
                                    <FormControlLabel control={<Checkbox checked={provider.inactive}
                                        onChange={(e, v) => {
                                            console.log("provider inactive:", v);
                                            provider.inactive = v;
                                            this.setState({ provider });
                                        }}
                                    />} label="Inactive" />
                                    <FormControlLabel control={<Checkbox checked={isValidDate(provider.validationDate)} onChange={(e, v) => {
                                        provider.validationDate = v ? new Date().toISOString() : null;
                                        provider.validatedBy = userId;
                                        this.setState({ provider });
                                    }} />} label={provider.validationDate ? `Validated on ${provider.validationDate}` : "Validate"} />
                                    {provider.validationDate ? <span><i> validated by <User appContext={appContext} userId={provider.validatedBy} userIdHidden={true} /></i></span> : null}
                                </span>
                            </Paper>
                        </div>
                        <div style={sectionRow}>
                            {displayTabs.length > 0 ? <div>
                                <Tabs style={{ backgroundColor: "#7070A0" }} variant="scrollable" value={currentTab}
                                    onChange={(e, t) => {
                                        this.setState({ currentTab: t });
                                    }} >
                                    {displayTabs}
                                </Tabs>
                                {tabs[currentTab]}
                            </div> : null}
                            <br />
                            <OwnerInfo appContext={appContext} model={provider} />
                        </div>
                    </div>;

                viewContent = <div>{headerItems}{editProvider}</div>;
            } // end if (provider)
        } else if (view === 'history') {
            if (provider) {
                viewContent = <HistoricalProviderView appContext={appContext} providerId={provider.providerId} disableEdit />;
            }
        }

        return (
            <div style={{ textAlign: 'left', width: '70vw' }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {appBar}
                    <Tabs value={view} onChange={(e, tab) => this.setState({ view: tab })} style={{ backgroundColor: "#D0D0F0" }}>
                        <Tab value='record' label='Record' />
                        <Tab value='history' label='History' />
                    </Tabs>
                    {viewContent}
                    {dialog}
                </div>
            </div>
        );
    }
}

export default ProviderView;
