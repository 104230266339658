import React, { Component } from 'react';
import {
    isArrayValid
} from '../utils/Utils';

const ReactHighstock = require('react-highcharts/ReactHighstock');

export class DurationGraph extends Component {

    getSeries() {
        if (this.props.series) {
            return this.props.series;
        }
        return [];
    }

    getName() {
        if (this.props.name) {
            return this.props.name;
        }
        return 'name';
    }

    getIrae() {
        if (this.props.irae) {
            return this.props.irae;
        }
        return 'irae';
    }

    render() {
        let self = this;
        let data = this.getSeries();
        let series = [];
        if (isArrayValid(data)) {
            for (let index = 0; index < data.length; index++) {
                let member = data[index];

                series.push({
                    name: member.name,
                    data: member.data,
                   
                })

            }
        }

        var config = {
            credits: { enabled: false },
            legend: {
                enabled: true,
                align: 'right',
                borderColor: 'black',
                borderWidth: 1,
                itemDistance: 25,
                layout: 'vertical',
                verticalAlign: 'top',
                y: 100,
                shadow: true
            },
            chart: {
                zoomType: 'xy'
            },
            rangeSelector: {
                selected: 4
            },
            title: {
                text: self.getName()
            },
            plotOptions: {
                series: {
                    showInNavigator: true
                },
                connectNulls: true
            },
            series
        };

        let durationChart = <ReactHighstock config={config} ref="chart" />
        return (
            <div>
                {durationChart}
            </div>
        );
    }
}

export default DurationGraph;