import { useCallback } from 'react'

import useData from '@apricityhealth/web-common-lib/hooks/useData';

const ENDPOINT = "messaging"

const OPTS = {
    DIRECT_MESSAGE_ATTACHMENTS: "/directMessageAttachments",
    DIRECT_MESSAGE: "/directMessages",
}

/**
 * useMessaging - Hook to get messages related data.
 */
function useMessaging(defaultValue) {
    const [data, { loading, error, clearData, fetchData, postData }] = useData(ENDPOINT, defaultValue);

    const getDirectMessageAttachments = useCallback(async (params, args, opts) => {
        return await fetchData(OPTS.DIRECT_MESSAGE_ATTACHMENTS, params, args, opts)
    }, [data, loading, error, clearData, fetchData])

    const getDirectMessages = useCallback(async (params, args, opts) => {
        return await fetchData(OPTS.DIRECT_MESSAGE, params, args, opts)
    }, [data, loading, error, clearData, fetchData])

    const updateDirectMessageAttachment = useCallback(async ( data) => {
        return await postData(OPTS.DIRECT_MESSAGE_ATTACHMENTS, data)
    }, [data, loading, error, clearData, fetchData])

    return [{ data, loading, error, clearData, getDirectMessageAttachments, getDirectMessages, updateDirectMessageAttachment }]
}

export default useMessaging
