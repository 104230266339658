import React, { useState, useEffect } from 'react';
import {
    useTheme,
    withStyles,
    Drawer,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core/';
import {
    ClearAllOutlined,
    Refresh
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridSortModel } from '@mui/x-data-grid';// eslint-disable-line
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import Org from '@apricityhealth/web-common-lib/components/Org';

import {
    isArrayValid,
    processAttachments,
    getDirectMessageAttachments
} from '@apricityhealth/web-common-lib/utils/Services';

import styles from './DirectMessageAttachmentsPage.styles'
import DirectMessageAttachment from './DirectMessageAttachment';
import Moment from 'moment-timezone';
import DMAValidation from './DMAValidation';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';


function DirectMessageAttachmentsPage({ classes, appContext }) {

    // Break url path into an object with a view and id
    const urlPath = window.location.pathname.slice(1).split('/');
    const path = {
        view: urlPath[0] === '' ? null : urlPath[0],
        attachmentId: urlPath.length > 1 ? urlPath[1] : null
    };

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [showDMA, setShowDMA] = useState(false);
    const [page, setPage] = useState(0);
    const [confirmReprocess, setConfirmReprocess] = useState(false);
    const [totalAttachments, setTotalAttachments] = useState(0);
    const [selectedDMA, setSelectedDMA] = useState({ attachmentId: '' });
    const [pageSize, setPageSize] = useState(10);
    const [attachments, setAttchments] = useState([]);
    const [searchValue, setSearchValue] = useState(path.attachmentId || "");
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'updatedAt', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));

    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])

    async function internalFetchData() {
        setLoading(true);
        setError(null)
        try {
            let args = [];
            if (searchValue) {
                args.push(`search=${encodeURIComponent(searchValue)}`);
            }
            args.push(`offset=${page * pageSize}`);
            args.push(`limit=${pageSize}`);
            let data = await getDirectMessageAttachments(appContext, args);
            let attachments = data.attachments;
            attachments.forEach(element => {
                element.id = element.attachmentId;
            });
            setAttchments(attachments);
            setTotalAttachments(data.totalAttachments);
            setLoading(false)
        } catch (error) {
            setLoading(false);
            setError(getErrorMessage(error))
            console.error(`Error fetching dm attachments data `, getErrorMessage(error))
        }
    }

    async function internalProcessAttachments() {
        setConfirmReprocess(false)
        let idToken = sessionStorage.getItem("idToken");
        let appContext = { state: { idToken } };
        setLoading(true)
        await processAttachments(appContext);
        setLoading(false)
    }

    useEffect(() => {
        if (searchValue === "") //Only do this if there is no search value.. we manually call search when teh enter key is pressed
            internalFetchData();
    }, [searchValue])

    // Auto fetch the data on page change or search
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize])

    function dmaSelected(dma) {
        if (dma) {
            setSelectedDMA(dma);
            setShowDMA(true);
        }
    }

    const columns = [
        {
            field: 'updatedAt',
            headerName: 'Last Update',
            type: "date",
            width: 200,
            renderCell: (params) => {
                let v = params.value;
                return Moment(v).format("LLL");
            }
        },
        {
            field: 'validated',
            headerName: 'Validation',
            width: 150,
            renderCell: (params) => {
                return <DMAValidation appContext={appContext} attachment={params.row} />
            }
        },
        { field: 'state', headerName: 'Last known state', width: 250, },
        {
            field: 'patientId', headerName: 'Patient', width: 250, renderCell: (params) => {
                let v = params.value;
                return v ? <Patient appContext={appContext} patientId={v} /> : v
            },
        },
        {
            field: 'orgId', headerName: 'Org', width: 250, renderCell: (params) => {
                let v = params.value;
                return v ? <Org appContext={appContext} orgId={v} /> : v
            },
        },
    ];




    function onClose() {
        setShowDMA(false)
        setSelectedDMA({ attachmentId: '' });
        internalFetchData();
    }





    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                        <TextField className={classes.text} label="Search for attachment" value={searchValue}
                            onKeyDown={(e) => { if (e.key === "Enter") internalFetchData() }}
                            onChange={(e) => setSearchValue(e.target.value)}
                            helperText="Will search all fields for the value"
                        />
                        <IconButton
                            onClick={() => {
                                setSearchValue("")
                            }}><ClearAllOutlined color="primary" /></IconButton>
                    </div>
                    <div className={classes.right}>
                        <Tooltip title={"Process attachments"}>
                            <IconButton
                                color="primary"
                                onClick={() => setConfirmReprocess(true)}
                            >
                                {loading ? <Loading /> : <FontAwesomeIcon className={classes.icon} icon="fa-solid fa-forward" />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Refresh"}>
                            <IconButton
                                color="primary"
                                style={{ marginRight: '2rem' }}
                                onClick={() => internalFetchData()}
                            >
                                {loading ? <Loading /> : <Refresh />}
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {error}
                <div style={{ flexGrow: 1, width: "100%", paddingRight: "2rem" }}>
                    {isArrayValid(attachments) && <DataGrid autoHeight pagination checkboxSelection
                        className={classes.root}
                        style={{ backgroundColor: theme.palette.background.paper }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        columns={columns}
                        rows={attachments}
                        pageSize={pageSize}
                        rowCount={totalAttachments}
                        paginationMode="server"
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 25, 50]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        selectionModel={selectedDMA.attachmentId ? [selectedDMA.attachmentId] : []}
                        sortingMode='client'
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        isRowSelectable={({ row }) => row.id === selectedDMA?.attachmentId}
                        onCellClick={({ row }) => row.id === selectedDMA?.attachmentId ? onClose() : dmaSelected(row)}
                        onRowClick={({ row }) => row.id === selectedDMA?.attachmentId ? onClose() : dmaSelected(row)}
                    />}
                </div>

                <Drawer variant="persistent" anchor="right" open={showDMA}>
                    <DirectMessageAttachment attachment={selectedDMA} onClose={onClose} appContext={appContext} />
                </Drawer>

                <Dialog
                    open={confirmReprocess}
                    onClose={() => setConfirmReprocess(false)}
                >
                    <DialogTitle id="signoutDialog" >Process Attachments</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>Attachments in the following state will get processed: <ul><li>processed=false</li><li>isProcessing=false</li></ul></Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="signoutCancel" className={classes.button} variant="contained" onClick={(e) => { setConfirmReprocess(false) }} >Cancel</Button>
                        <Button id="signoutConfirm" className={classes.button} variant="contained" onClick={(e) => { internalProcessAttachments() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}


export default withStyles(styles)(DirectMessageAttachmentsPage)