import React from 'react';
import T from 'i18n-react';

import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Config from '@apricityhealth/web-common-lib/Config';
import LoginView from '@apricityhealth/web-common-lib/views/LoginView';

import ActivitesView from './views/ActivitesView';
import AppointmentsView from './views/AppointmentsView';
import DevicesView from './views/DevicesView';
import DirectMessagesView from './views/DirectMessagesView/DirectMessagesView';
import DirectMessageAttachmentsPage from './views/directMessageAttachments';
import EmployeesPage from './views/employees'
import RecentActivityPage from './views/recentActivity';
import FeedbackView from './views/FeedbackView';
import BlobsView from './views/BlobsView';
import LogsView from './views/LogsView';
import MetricsView from './views/MetricsView';
import NotificationsView from './views/NotificationsView';
import OrgsView from './views/OrgsView/OrgsView';
import PatientsLabData from './views/PatientsLabData';
import PatientModelsView from './views/PatientModelsView';
import PatientsView from './views/PatientsView/PatientsView';
import PromoteView from './views/PromoteView';
import ProvidersView from './views/ProvidersView';
import ReportJobsView from './views/ReportJobsView';
import ReportSchedulesView from './views/ReportSchedulesView';
import ReportsView from './views/ReportsView';
import RightsView from './views/RightsView';
import SubscriptionsView from './views/SubscriptionsView';
import TestsView from './views/TestsView';
import TrialsView from './views/TrialsView';
import UserInfoView from './views/UserInfoView';
import UsersView from './views/UsersView';
import CurrentOpsView from './views/CurrentOpsView';
import MaintenanceWindowsView from './views/MaintenanceWindowsView';
import Anthropic from './views/anthropic';
import { setTextContent } from '@apricityhealth/web-common-lib/reduxStore/app'
import SelectTheme from '@apricityhealth/web-common-lib/components/SelectThemeV2';
import {
    Button,
    withStyles,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core/';

import { Logger } from '@apricityhealth/web-common-lib';

import AppHeader from './AppHeader';
import MenuView from './MenuView';

// Font Awesome Icons
import loadIcons from './Icons';
import {
    loadText
} from '@apricityhealth/web-common-lib/utils/Services';

import './App.css';

loadIcons();

const log = new Logger();
const version = require('../package.json').version;

log.update({ clientName: 'admin-web-client', clientVersion: version });
class App extends React.Component {
    constructor(props) {
        super(props);

        let language = localStorage.getItem("language") || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
        language = language.toLowerCase();

        // Break url path into an object with a view and id
        const urlPath = window.location.pathname.slice(1).split('/');
        const path = {
            view: urlPath[0] === '' ? null : urlPath[0],
            id: urlPath.length > 1 ? urlPath[1] : null,
            jobId: urlPath.length > 2 ? urlPath[2] : null,
        };

        console.log(`path `, path);

        // Break url query into an object
        const query = {};
        const urlSearch = window.location.search.slice(1).split('&');
        urlSearch.forEach(function (p) {
            p = p.split('=');
            return p[0] === '' ? p[0] : query[p[0]] = decodeURIComponent(p[1] || '');
        });

        this.state = {
            username: '',
            idToken: null,
            refreshToken: null,
            dialog: null,
            open: true,
            path: path,
            query: query,
            language
        };
    }

    async onSignIn() {
        let { language } = this.state;
        let idToken = sessionStorage.getItem("idToken");
        let texts = await loadText({ state: { idToken } }, { planId: "*", language, dependencies: true, resolveConflicts: true });
        let textContent = {};
        texts.forEach(text => {
            if (!textContent[text.language]) textContent[text.language] = {};
            textContent[text.language][text.textId] = text;
        });
        this.props.dispatch(setTextContent(textContent));
    }

    onSignOut() {
        const self = this;
        const { classes } = self.props;

        this.handleMenuClose();
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    onClose={() => self.cancelSignOut()}
                >
                    <DialogTitle id="signoutDialog" >Sign Out</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to sign out?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="signoutCancel" className={classes.button} variant="contained" self={self} onClick={(e) => { self.cancelSignOut(); }} >Cancel</Button>,
                        <Button id="signoutConfirm" className={classes.button} variant="contained" self={self} onClick={(e) => { self.confirmSignOut(); }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelSignOut() {
        log.debug("cancelSignOut:", this);
        this.handleMenuClose();
        this.setState({ dialog: null });
    }


    confirmSignOut() {
        log.debug("confirmSignOut:", this);
        this.handleMenuClose();
        log.update({ userId: null, idToken: null });
        this.setState({ username: '', idToken: null, refreshToken: null, dialog: null, patientViews: [] });
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userId');
    }


    confirmSettings() {
        log.debug("confirmSettings:", this);
        this.handleMenuClose();
        this.setState({ dialog: null });
        localStorage.setItem("language", this.state.language);
    }

    onSettings() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    onClose={() => self.confirmSettings()}
                >
                    <DialogTitle>Settings</DialogTitle>
                    <DialogContent>
                        <UserInfoView appContext={this} />
                        <SelectTheme labelStyle={{ marginLeft: '1rem' }} style={{ marginLeft: '1rem' }} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.confirmSettings(); }}>DONE</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onToggleLeftNav() {
        var newState = !this.state.open;
        this.setState({ open: newState });
    }

    //TODO - move appHeader functions to appHeader
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleProfileMenuOpen = event => {
        this.setState({ accountMenuOpen: false });
    };

    onHideLeftNav() {
        this.setState({ open: false });
    }

    render() {
        const self = this;
        const { idToken, language, anchorEl, mode, open, progress, dialog } = this.state;
        const isMenuOpen = Boolean(anchorEl);

        T.setTexts(require(`./local/${language}.json`));
        if (!idToken) {
            const stage = Config.stage !== 'production' ? Config.stage.toUpperCase() : '';

            // display the login page if we don't have an idToken..
            return (
                <div className="App">
                    <div>
                        <LoginView appContext={self} title={'Administrator Login'} group='administrators' stage={stage}
                            onDone={self.onSignIn.bind(self)} />
                    </div>
                </div>
            );
        }
        else {
            const contentStyle = Object.assign({}, styles.content);
            if (open)
                contentStyle.marginLeft = DRAWER_WIDTH;

            contentStyle.marginTop = "3rem";

            return (
                <>
                    <MenuView
                        appContext={this}
                        mode={mode}
                        open={open}
                        language={language}
                        onSignOut={this.onSignOut.bind(this)}
                    />
                    <div style={contentStyle}>
                        <AppHeader
                            appContext={this}
                            open={open}
                            auth={idToken}
                            mode={mode}
                            isMenuOpen={isMenuOpen}
                            anchorEl={anchorEl}
                            username={this.state.username}
                            onSettings={this.onSettings.bind(this)}
                            onSignOut={this.onSignOut.bind(this)}
                            onToggle={this.onToggleLeftNav.bind(this)}
                            handleMenu={this.handleMenu.bind(this)}
                            handleMenuClose={this.handleMenuClose.bind(this)}
                        />
                        {progress}
                        <Routes>
                            <Route
                                path=""
                                element={<ReportsView appContext={this} />}
                            />
                            <Route
                                path="reports"
                                element={<ReportsView appContext={this} />}
                            />
                            <Route
                                path="report_jobs"
                                element={<ReportJobsView appContext={this} />}
                            />
                            <Route
                                path="providers/*"
                                element={<ProvidersView appContext={this} />}
                            />
                            <Route
                                path="patients/*"
                                element={<PatientsView appContext={this} />}
                            />
                            <Route
                                path="organizations/*"
                                element={<OrgsView appContext={this} />}
                            />
                            <Route
                                path="users/*"
                                element={<UsersView appContext={this} />}
                            />
                            <Route
                                path="employees"
                                element={<EmployeesPage appContext={this} />}
                            />
                            <Route
                                path="patient_labs"
                                element={<PatientsLabData appContext={this} />}
                            />
                            <Route
                                path="patient_models"
                                element={<PatientModelsView appContext={this} />}
                            />
                            <Route
                                path="devices"
                                element={<DevicesView appContext={this} />}
                            />
                            <Route
                                path="report_schedules"
                                element={<ReportSchedulesView appContext={this} />}
                            />
                            <Route
                                path="subscriptions"
                                element={<SubscriptionsView appContext={this} />}
                            />
                            <Route
                                path="directMessages/*"
                                element={<DirectMessagesView appContext={this} />}
                            />
                            <Route
                                path="directMessageAttachments/*"
                                element={<DirectMessageAttachmentsPage appContext={this} />}
                            />
                            <Route
                                path="recentActivity/*"
                                element={<RecentActivityPage appContext={this} />}
                            />
                            <Route
                                path="logs/*"
                                element={<LogsView appContext={this} />}
                            />
                            <Route
                                path="notifications"
                                element={<NotificationsView appContext={this} />}
                            />
                            <Route
                                path="promote"
                                element={<PromoteView appContext={this} />}
                            />
                            <Route
                                path="metrics"
                                element={<MetricsView appContext={this} />}
                            />
                            <Route
                                path="activities"
                                element={<ActivitesView appContext={this} />}
                            />
                            <Route
                                path="blobs"
                                element={<BlobsView appContext={this} />}
                            />
                            <Route
                                path="anthropic"
                                element={<Anthropic appContext={this} />}
                            />
                            <Route
                                path="currentOps"
                                element={<CurrentOpsView appContext={this} />}
                            />
                            <Route
                                path="feedback"
                                element={<FeedbackView appContext={this} />}
                            />
                            <Route
                                path="tests"
                                element={<TestsView appContext={this} />}
                            />
                            <Route
                                path="appointments/*"
                                element={<AppointmentsView appContext={this} />}
                            />
                            <Route
                                path="trials/*"
                                element={<TrialsView appContext={this} />}
                            />
                            <Route
                                path="rights"
                                element={<RightsView appContext={this} />}
                            />
                            <Route
                                path="maintenancewindows"
                                element={<MaintenanceWindowsView appContext={this} />}
                            />
                        </Routes>
                        {dialog}
                    </div>
                </>
            );
        }
    }
};


export const DRAWER_WIDTH = 300;
const styles = theme => ({
    content: {
        align: 'left',
        transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',

    },
    contentShift: { marginLeft: DRAWER_WIDTH },
    formControl: { minWidth: 280 },
    selectEmpty: { marginTop: 20 },
    // necessary for content to be below app bar
});

App.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default connect()(withStyles(styles)(App));