import React, { Component } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    FormControl,
    InputLabel
} from '@material-ui/core/';

class EditPatientEmailDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: JSON.parse(JSON.stringify(props.email))
        };
    }

    componentDidUpdate(oldProps){
        const oldEmail = JSON.stringify(oldProps.email)
        const newEmail = JSON.stringify(this.props.email)
        if (oldEmail !== newEmail){
            this.setState({email: JSON.parse(JSON.stringify(this.props.email))});
        }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel()
    }

    onDone() {
        const { onDone } = this.props;
        if (onDone)
            onDone( this.state.email );
    }

    render() {
        let { email } = this.state;
        let self = this;
        return (
            <Dialog open={true} onClose={this.onDone.bind(this)}>
                <DialogTitle>Email Details</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Email"
                        style={{width: 500 ,margin: 5}}
                        value={email.email}
                        onChange={(e) => { email.email = e.target.value; self.setState({ email }) }}
                    />
                    <br />
                    <FormControl style={{margin: 5, width: 150}}>
                        <InputLabel>Email Type</InputLabel>
                        <Select
                            placeholder="Email Type"
                            value={email.emailType}
                            onChange={(e) => {email.emailType = e.target.value; self.setState({email})}}>
                            <MenuItem value={'primary'}>Primary</MenuItem>
                            <MenuItem value={'secondary'}>Secondary</MenuItem>
                            <MenuItem value={'direct'}>Direct</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"  self={self} style={styles.button} onClick={this.onDone.bind(this)}>Ok</Button>
                    <Button variant="contained"  self={self} style={styles.button} onClick={this.onCancel.bind(this)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            )
    }
}

const styles = {
    button:{
        margin:5
    },
    div: {
        margin: 10
    }
}

export default EditPatientEmailDialog;