import React, { Component } from 'react';
import { MenuItem, Select, FormControl, InputLabel, Checkbox } from '@material-ui/core/';

import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

export class SelectSubscriptionPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: props.orgId,
            value: props.value || [],
            plans: [],
            items: []
        }
    }

    componentDidMount() {
        this.getSubscriptionPlans();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.orgId !== this.props.orgId)
            this.setState({ orgId: this.props.orgId }, this.getSubscriptionPlans.bind(this) );
    }

    getSubscriptionPlans() {
        const { orgId } = this.state;
        const { appContext: { state: { idToken }} } = this.props;

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}billing/${orgId}/plans?disableFilter=true`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log("getSubscriptionPlans request:", request );
        Axios(request).then(({data}) => {
            console.log("getSubscriptionPlans result:", data );
            data.sort((a, b) => a.subscriptionPlanData.name.localeCompare( b.subscriptionPlanData.name, undefined, { sensitivity: 'base' } ));
            this.setState({plans: data}, this.updateItems.bind(this) );
        }).catch((err) => {
            console.error("getSubscriptionPlans error:", err);
        });
    }

    updateItems() {
        const { plans, value } = this.state;
        let items = [];
        for (let i = 0; i < plans.length; ++i) {
            const plan = plans[i];
            items.push(<MenuItem key={i} value={plan.id}>
                <Checkbox checked={value.indexOf(plan.id) >= 0} />
                {plan.subscriptionPlanData.name}
                </MenuItem>);
        }
        this.setState({ items });
    }

    render() {
        const { items, value, plans } = this.state;
        return <FormControl style={this.props.style || { margin: 5, width: 300 }}>
            <InputLabel>{this.props.label || "Subscription Plans"}</InputLabel>
                <Select multiple={true} value={value} 
                    renderValue={() => plans.filter((e) => value.indexOf(e.id) >= 0).map((k) => k.subscriptionPlanData.name).join(", ")}
                    onChange={(e) => {
                        const value = e.target.value;
                        this.setState({ value }, () => {
                            const { onChange } = this.props;
                            if ( onChange ) onChange(value);
                            this.updateItems();
                        });
                    }}>
                    {items}
                </Select>
        </FormControl>;
    }
}

export default SelectSubscriptionPlans;
