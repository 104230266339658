import React, { Component } from 'react';

import {
    CircularProgress,
    FormControl,
    Select,
    InputLabel, Typography,
    MenuItem, List, ListItem
} from '@material-ui/core/';

import DurationGraph from '../../components/DurationGraph';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import User from '@apricityhealth/web-common-lib/components/User';
import DateTime from '../../components/DateTime';
import {
    getMetrics, isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';
import Moment from 'moment';

export class ApiDurationReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            endPoint: '*',
            endPoints: [],
            userMetrics: []
        }
    }

    componentDidMount() {
        this.loadMetrics();
    }

    componentDidUpdate(oldProps) {
        if (JSON.stringify(oldProps.args) !== JSON.stringify(this.props.args))
            this.loadMetrics();
    }

    getSeries(samples) {
        console.log("getSeries:", samples );
        let endPoints = {};
        endPoints["*"] = { name: '*', data: [] };

        for (let date in samples) {
            let time = new Date(date).getTime();
            let day = samples[date];
            let dayAverage = 0;
            let dayCount = 0;
            for (let endPoint in day) {
                let average = Number(day[endPoint].average.toFixed(1));
                if (!endPoints[endPoint]) endPoints[endPoint] = { name: endPoint, data: [] };
                endPoints[endPoint].data.push([time, average]);  // TODO: Graph min & max
                dayAverage += average;
                dayCount += 1;
            }
            dayAverage /= dayCount;
            endPoints["*"].data.push([time, Number(dayAverage)]);
        }

        let series = [];
        for (let k in endPoints) {
            let endPoint = endPoints[k];
            endPoint.data.sort((a, b) => a[0] - b[0]);
            series.push(endPoint);
        }

        return series;
    }

    loadMetrics() {
        const self = this;
        const { appContext } = this.props;

        let { endPoint, endPoints, userMetrics } = this.state;
        let { userId } = this.props.args;

        let args = [
            'sample=duration',
            'groupBy=endPoint'
        ];

        let updateLists = true;
        if (userId) {
            args.push(`userId=${userId}`);
            updateLists = false;
        }
        if (endPoint !== '*') {
            args.push('key=endPoint');
            args.push(`value=${encodeURIComponent(endPoint)}`)
            updateLists = false;
        }
        else {
            args.push('key=duration');
        }

        this.props.parent.setState({ progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        promises.push(getMetrics(appContext, args));
        if (userId && (userId !== '*')) {
            let args = [
                `key=endPoint`,
                `limit=500`,
                `userId=${userId}`
            ];
            promises.push(getMetrics(appContext, args));
        }
        Promise.all(promises).then((results) => {
            let samples = results[0].samples;

            let series = self.getSeries(samples);
            if (updateLists) {
                endPoints = [];
                for (let i = 0; i < series.length; ++i) {
                    endPoints.push(series[i].name);
                }
                endPoints.sort();
            }
            series = series.filter((e) => e.name === endPoint );     
            if (results.length === 2) {
                userMetrics = results[1].metrics.reverse();
            }
            let durationGraph = <DurationGraph
                name={'API Duration'}
                series={series}
            />
            let endPointItems = [];
            for (let index = 0; index < endPoints.length; index++) {
                let endPoint = endPoints[index];
                endPointItems.push(<MenuItem key={endPoint} value={endPoint}>{endPoint}</MenuItem>);
            }
            let endPointSelect = <FormControl style={styles.text}>
                <InputLabel>End-Point:</InputLabel>
                <Select value={endPoint} onChange={(e) => { self.setState({ endPoint: e.target.value }, self.loadMetrics.bind(self)) }}>
                    {endPointItems}
                </Select>
            </FormControl>;

            let userGraph = null
            if (isArrayValid(userMetrics)) {
                let data = userMetrics.map((metric) => { return { x: Moment(metric.createDate).valueOf(), y: metric.metrics[0].duration, z: "rayz", url: "url", name: "ray1", metric } });
                let graphSeries = [{ type: 'area', name: "", data }];
                userGraph = <DateTime height={200}
                    series={graphSeries}
                    name={""}
                    pointHover={(data) => this.updatePointHover(data)}
                />
            }

            self.setState({ endPoints, userMetrics, series, durationGraph, endPointSelect, userGraph });
            self.props.parent.setState({ progress: null });
        }).catch(function (error) {
            console.log("loadMetrics error:", error);
            self.props.parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    updatePointHover({ chart, e }) {
        if (e && e.target && e.target.metric)
            this.setState({ hoverMetric: e.target.metric })
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    render() {
        const { dialog, hoverMetric, durationGraph, endPointSelect, userGraph } = this.state;
        const { appContext } = this.props;

        let list = [];
        if (hoverMetric) {
            if (isArrayValid(hoverMetric.metrics)) {
                list.push(<ListItem key='1' style={{ wordWrap: 'break-word' }}><Typography style={{ wordWrap: 'break-word', width: '500px' }} variant="body2" gutterBottom>EndPoint: {hoverMetric.metrics[0].endPoint}</Typography> </ListItem>);
                list.push(<ListItem key='2' style={{ wordWrap: 'break-word' }}><Typography style={{ wordWrap: 'break-word', width: '500px' }} variant="body2" gutterBottom>Url: {hoverMetric.metrics[0].url}</Typography> </ListItem>);
                list.push(<ListItem key='3' style={{ wordWrap: 'break-word' }}><Typography style={{ wordWrap: 'break-word', width: '500px' }} variant="body2" gutterBottom>Duration: {hoverMetric.metrics[0].duration}</Typography> </ListItem>);
            }
            if (hoverMetric.userId)
                list.push(<ListItem key='4' > <Typography variant="subtitle2">UserId: <User appContext={appContext} userId={hoverMetric.userId} /></Typography> </ListItem>);
            else
                list.push(<ListItem key='4' onClick={() => this.getUser(hoverMetric.userId)}> <Typography variant="subtitle2">UserId: {hoverMetric.userId}</Typography> </ListItem>);

            list.push(<ListItem key='5'><Typography variant="subtitle2">Create Date: {hoverMetric.createDate}</Typography> </ListItem>);
            list.push(<ListItem key='6'><Typography variant="subtitle2">Client: {hoverMetric.tag}</Typography> </ListItem>);
        }
        let hoverList = <List dense disablePadding style={{ margin: 0, padding: 0 }}>{list}</List>;


        return (
            <table style={{ width: '95%' }}><tbody>
                <tr>
                    <td colSpan={2}>
                        {endPointSelect}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {durationGraph}

                    </td>
                </tr>
                <tr>
                    <td valign='top' style={{ width: '70%' }}>
                        {userGraph}
                    </td>
                    <td style={{ width: '30%' }}>
                        {hoverList}
                    </td>
                </tr>
                <tr>
                    <td>
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5,
        width: 250
    }, number: {
        margin: 5,
        width: 100
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    }
}

export default ApiDurationReport;
