import Config from '@apricityhealth/web-common-lib/Config';

import React, { Component } from 'react';
import {
    AppBar, Toolbar, Typography, DialogActions, DialogTitle, DialogContentText, DialogContent,
    Dialog, CircularProgress, IconButton, TextField, Button
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import NavigationClose from '@material-ui/icons/Close';
import Axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

class NotificationView extends Component {
    constructor(props){
        super(props)
        this.state = {
            notification: {}
        }
    }

    componentDidMount() {
        this.loadNotification(this.props.notifyId);
    }

    componentDidUpdate(oldProps) {
        if (this.props.notifyId !== oldProps.notifyId ){
            this.loadNotification(this.props.notifyId);
        }
    }

    closeNotification() {
        this.props.onClose();
    }

    loadNotification(notifyId) {
        const self = this;

        if (notifyId) {
            self.setState({ notification: null, progress: <CircularProgress /> });
            const getNotification = {
                url: Config.baseUrl + `${Config.pathPrefix}logging/notify?notifyId=${notifyId}`,
                method: 'GET',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            console.log("getNotification:", getNotification);
            Axios(getNotification).then((response) => {
                console.log("getNotification response:", response);
                self.setState({ notification: response.data[0], progress: null });
            }).catch((err) => {
                console.log("getNotification error:", err);
                self.setState({ progress: err.message });
            });
        }
        else {
            this.setState({
                notification: {
                    logText: '',
                    logLevel: 0,
                    logTag: '',
                    notify: []
                }
            });
        }
    }

    saveNotification() {
        const self = this;
        const { notification } = self.state;

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress /> });

        const saveNotification = {
            url: Config.baseUrl + `${Config.pathPrefix}logging/notify`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: notification
        };

        console.log("saveNotification:", saveNotification);
        Axios(saveNotification).then(function (response) {
            console.log("saveNotification response:", response);
            self.setState({ notification: response.data, progress: null });
        }).catch(function (error) {
            console.log("saveNotification error:", error);
            self.setState({ progress: error.message });
        });
    }

    deleteNotification() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Notification: {`${self.state.notification.notifyId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDelete() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDelete() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        const notification = self.state.notification;

        self.setState({ dialog: null });
        if (notification.notifyId) {
            const deleteNotification = {
                url: Config.baseUrl + `${Config.pathPrefix}logging/notify/${notification.notifyId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteNotification:", deleteNotification);
            Axios(deleteNotification).then(function (response) {
                console.log("deleteNotification response:", response);
                self.setState({ progress: null });
                self.closeNotification();
            }).catch(function (error) {
                console.log("deleteNotification error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }

    render() {
        const self = this;
        const { notification, progress, dialog } = self.state;

        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={this.closeNotification.bind(this)}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Notification Details</Typography>
                </Toolbar>
            </AppBar> : null;

        if (notification) {
            let notifyColumns = [
                { id: 'notifyType', editType: 'text', label: 'Type (email,sms,slack)' },
                { id: 'notifyTarget', width: 500, editType: 'text', label: 'Target' }
            ];
            let notifyTable = <EnhancedTable
                rowsPerPage={2}
                onDataChanged={(data) => {
                    notification.notify = data;
                    self.setState({ notification });
                }}
                orderBy='notifyType'
                columnData={notifyColumns}
                data={notification.notify}
                title='Notify' />;

            var editNotification = <table style={styles.table}>
                <tbody>
                    <tr>
                        <td style={styles.td} valign="top">
                            <IconButton onClick={() => { self.saveNotification(); }}><SaveIcon /></IconButton>
                            <IconButton onClick={() => { self.deleteNotification(); }}><DeleteIcon /></IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Notify ID: {notification.notifyId}</p>
                            <TextField style={styles.question} label="Log Text" value={notification.logText}
                                onChange={(e) => { notification.logText = e.target.value; self.setState({ notification }); }} />
                            <TextField style={styles.question} label="Log Level (0:Debug,1:Info,2:Warning,3:Error,4:Critical)" value={notification.logLevel}
                                onChange={(e) => { notification.logLevel = e.target.value; self.setState({ notification }); }} />
                            <TextField style={styles.question} label="Log Tag" value={notification.logTag}
                                onChange={(e) => { notification.logTag = e.target.value; self.setState({ notification }); }} />
                            {notifyTable}
                        </td>
                    </tr>
                </tbody>
            </table>;
        }

        return (
            <div style={styles.div} align="right" >
                {appBar}
                {editNotification}
                {dialog}
                {progress}

            </div>


        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 5
    },
    div: {
        margin: 0
    },
    question: {
        margin: 5,
        width: 400
    },
    phone: {
        margin: 5,
        width: 200
    },
    table: {
        "width": 500
    },
    td: {
        "textAlign": "right"
    }
}

export default NotificationView;
