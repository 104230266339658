import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    TextField,
    IconButton,
    Tooltip,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core/';

import Axios from 'axios';

import RefreshIcon from '@material-ui/icons/Refresh';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import SelectPatient from "@apricityhealth/web-common-lib/components/SelectPatient";
import Config from '@apricityhealth/web-common-lib/Config';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils'
import PatientModelView from './PatientModelView';



function testFilter(filter, model) {
    if (filter === "" || filter === "*") return true;
    if (model) {
        filter = filter.toUpperCase();
        if (model.modelId && model.modelId.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toUpperCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PatientModelsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            filtered: [],
            filter: '',
            progress: null,
            dialog: null,
            error: null,
            createModel: {
                modelId: '',
                patientId: '',
                description: '',
                firstName: 'TestModel',
                lastName: 'Patient'
            }
        }
    }

    componentWillMount() {
        this.loadContent();
    }

    loadContent() {
        const { appContext } = this.props;

        const getPatientModels = {
            url: Config.baseUrl + `${Config.pathPrefix}test/models`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        }

        console.log("getPatientModels:", getPatientModels);
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Axios(getPatientModels).then((response) => {
            console.log("getPatientModels response:", response.data);
            const { models, modelsTruncated } = response.data;
            this.setState({ models, progress: null, error: modelsTruncated ? 'Models results truncated!' : null }, this.updateFilter.bind(this));
        }).catch((err) => {
            console.error("getPatientModels error:", err);
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    createTestModel() {
        const { createModel } = this.state
        const { appContext } = this.props;
        const dialog = <Dialog open={true}>
            <DialogTitle>Create Patient Model</DialogTitle>
            <DialogContent>{`Please confirm you would like to create a new patient model?`}
                <br />
                <br />
                <TextField style={{margin: 5, width: 300}} label='Model ID' value={createModel.modelId} onChange={(e) => {
                    createModel.modelId = e.target.value.replace(/\s/g, '');
                    this.setState({createModel}, this.createTestModel.bind(this) );
                }} />
                <br />
                <SelectPatient appContext={appContext} patientId={createModel.patientId} onChange={(patientId) => {
                    createModel.patientId = patientId;
                    this.setState({createModel}, this.createTestModel.bind(this));
                }} />
                <br />
                <TextField style={{margin: 5, width: 500}} label='Description' value={createModel.description} onChange={(e) => {
                    createModel.description = e.target.value;
                    this.setState({createModel}, this.createTestModel.bind(this) );
                }} />
                <br />
                <TextField style={{margin: 5, width: 250}} label='First Name' value={createModel.firstName} onChange={(e) => {
                    createModel.firstName = e.target.value;
                    this.setState({createModel}, this.createTestModel.bind(this) );
                }} />
                <TextField style={{margin: 5, width: 250}} label='Last Name' value={createModel.lastName} onChange={(e) => {
                    createModel.lastName = e.target.value;
                    this.setState({createModel}, this.createTestModel.bind(this) );
                }} />
                <br />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button onClick={() => {
                    const createPatientModel = {
                        url: Config.baseUrl + `${Config.pathPrefix}test/models/create`,
                        method: 'POST',
                        headers: { "Authorization": appContext.state.idToken },
                        data: {
                            ...createModel
                        }
                    }
        
                    console.log("createPatientModel request:", createPatientModel);
                    this.setState({ progress: <CircularProgress size={20} />, error: null, dialog: null });
                    Axios(createPatientModel).then((response) => {
                        console.log("createPatientModel response:", response.data);
                        createModel.modelId = '';
                        createModel.patientId = '';
                        this.setState({ progress: null, createModel }, this.loadContent.bind(this));
                    }).catch((err) => {
                        console.log("createPatientModel error:", err);
                        this.setState({ progress: null, error: getErrorMessage(err) });
                    });
        
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    updateFilter() {
        let { filter, models } = this.state;
        if (filter) {
            let filtered = [];
            for (var i = 0; i < models.length; ++i) {
                if (testFilter(filter, models[i]))
                    filtered.push(models[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }


    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._table)
            this._table.setState({ selected: [] });
    }

    onModelSelected(s, t) {
        const { appContext } = this.props;
        if (s.length > 0) {
            const { filtered, models } = this.state;
            const model = filtered ? filtered[s[0]] : models[s[0]];
            const dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <PatientModelView appContext={appContext} model={model} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    render() {
        const { dialog, progress, filter, error, models, filtered } = this.state;

        const columns = [
            { id: 'modelId', label: 'Model ID' },
            { id: 'description', label: 'Description' },
            { id: 'count', label: '# Patients', numeric: true },
            { id: 'disabled', label: 'Disabled', formatValue: (v) => toBoolean(v) === true ? 'Yes' : 'No' },
            { id: 'data', numeric: true, label: 'Data', formatValue: (v) => v.length },
            { id: 'conversation', numeric: true, label: 'Conversations', formatValue: (v) => v.length },
            { id: 'createdAt', label: 'Created' }
        ];

        const table = <EnhancedTable 
            disableMultiSelect={true}
            onTable={table => this._table = table}
            onSelected={(s, t) => this.onModelSelected(s, t)}
            onAdd={this.createTestModel.bind(this)}
            orderBy='modelId'
            rowsPerPage={25}
            columnData={columns}
            data={filtered ? filtered : models}
            title='Tests' />;

        const view = <table style={{ width: '100%' }}><tbody>
            <tr><td valign='bottom'>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { this.setState({ filter: e.target.value }, this.updateFilter.bind(this)); }} />
            </td><td align='right'>
                    <span style={{color: 'red'}}>{error}</span>
                    <Tooltip title='Refresh'><IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>
                        {progress ? progress : <RefreshIcon />}</IconButton></Tooltip>
                </td></tr>
            <tr><td colSpan={2}>
                {table}
            </td></tr>
        </tbody></table>;

        return <Paper square>
            {view}
            {dialog}
        </Paper>;

    }
}

export default PatientModelsView;
