import { useState, useEffect, useRef } from 'react';
import { useList } from 'react-use';
import PropTypes from 'prop-types'
import Moment from 'moment-timezone';
import {
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Paper,
    DialogActions,
    Button,
    IconButton,
    TextField,
    DialogTitle,
    DialogContent,
    Dialog,
    Drawer,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';
import {
    Close,
    Refresh
} from '@material-ui/icons';

import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import DateTimeRange from '@apricityhealth/web-common-lib/components/DateTimeRange';
import ReactJson from 'react-json-view';
import useData from '@apricityhealth/web-common-lib/hooks/useData';
import Error from '@apricityhealth/web-common-lib/components/Error';

const GROUPS = ["patients", "providers", "support"];
const dateFormat = 'llll';
const columnData = [
    {
        id: 'startTime', width: '200px', numeric: false, disabledPadding: false, label: 'Start', formatValue: (v, row) => {
            return v ? Moment(v).format(dateFormat) : "";
        }
    },
    {
        id: 'endTime', width: '200px', numeric: false, disabledPadding: false, label: 'End', formatValue: (v, row) => {
            return v ? Moment(v).format(dateFormat) : "";
        }
    },
    { id: 'disableLogin', numeric: false, disabledPadding: false, label: 'Login Disabled' },
    {
        id: 'groups', numeric: false, disabledPadding: false, label: 'Groups', formatValue: (v, row) => {
            return v && v.join(",");
        }
    },
    { id: 'message', numeric: false, disabledPadding: false, label: 'Message' },

];


function MaintenanceWindowsView({ classes, appContext }) {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [currentMaintenanceWindow, setCurrentMaintenanceWindow] = useState(null);
    let [data, { loading, fetchData, postData, deleteData, error }] = useData('content/maintenanceWindows', appContext?.state?.idToken, []);

    const [maintenanceWindows, { set, updateAt, insertAt, removeAt }] = useList([]);

    console.debug(`MaintenanceWindowsView `, data, maintenanceWindows);
    useEffect(() => {
        console.debug(`data changed`, data);
        if (Array.isArray(data)) //post data is not the full set so we dont' update unless its an array of data
            set(data);
        else {
            fetchData()
        }
    }, [data])

    // Auto fetch the data on mount
    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    let table = useRef(null);

    function newMaintenanceWindow() {
        const startTime = Moment().seconds(0).add(1,'hour').toDate();
        const endTime = Moment(startTime).add(1,'hour');
        const maintenanceWindow = {
            "message": "ongoingMaintenance",
            startTime,
            endTime,
            disableLogin: true,
            groups: ["patients", "providers", "support"]
        };
        setCurrentMaintenanceWindow(maintenanceWindow);
        setIndex(-1);
        setOpen(true);
    }

    function maintenanceWindowSelected(s, t) {
        console.debug(`maintenanceWindowSelected`, s, t, maintenanceWindows);
        if (s.length > 0) {
            let index = s[0];
            console.debug(`index`, index);
            let maintenanceWindow = maintenanceWindows[index];
            console.debug(`maintenanceWindowSelected`, maintenanceWindow);
            setCurrentMaintenanceWindow(maintenanceWindow);
            setIndex(index);
            setOpen(true);
        }
    }

    function onClose() {
        if (table && table.current)
            table.current.setState({ selected: [] });        // unselect the question
        setOpen(false);
    }

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td>{error && <Error> {error}</Error>}</td>
                    <td align='right'>
                        <IconButton disabled={loading} onClick={() => { fetchData() }}>
                            {loading ? <CircularProgress size={20} /> : <Refresh />}
                        </IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <EnhancedTable
                            disableMultiSelect={true}
                            disableActions={loading}//investigate
                            disableSelect={loading}
                            onTable={t => table.current = t}
                            onSelected={(s, t) => maintenanceWindowSelected(s, t)}
                            onAdd={() => newMaintenanceWindow()}
                            orderBy='startTime'
                            order='startTime'
                            rowsPerPage={25}
                            columnData={columnData}
                            data={maintenanceWindows}
                            title='Maintenance Windows' />
                        <Drawer variant="persistent" anchor="right" open={open}>
                            <MaintenanceWindowDialog
                                classes={classes}
                                maintWindow={currentMaintenanceWindow}
                                onDone={(maintenanceWindow) => {
                                    postData(null, maintenanceWindow); // update the one element that changed
                                    if (index === -1) {
                                        insertAt(0, maintenanceWindow)
                                    } else {
                                        updateAt(index, maintenanceWindow);
                                    }
                                    onClose();
                                }}
                                onDelete={(maintenanceWindow) => {
                                    console.debug(`onDelete `, maintenanceWindow)
                                    deleteData(null, null, [`maintenanceId=${maintenanceWindow.maintenanceId}`]);
                                    removeAt(index)
                                    onClose();
                                }}
                                onClose={() => onClose()}
                            />
                        </Drawer>;
                    </td>
                </tr>
            </tbody>
        </table>
    );
}


MaintenanceWindowDialog.propTypes = {
    maintWindow: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}


function MaintenanceWindowDialog({ maintWindow, classes, onClose, onDone, onDelete }) {
    const [internalState, setInternalState] = useState({ groups: [], stages: [] });
    const [dialogOpen, setDialogOpen] = useState(false);

    console.log(`MaintenanceWindowDialog`, maintWindow, internalState)
    useEffect(() => {
        setInternalState(maintWindow);
        return () => {
            setInternalState(null)
        }
    }, [maintWindow]);

    return (
        <div >
            <AppBar className={classes.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => onClose()}>
                        <Close />
                    </IconButton>
                    <Typography variant="h5" color="inherit">Maintenance Window Details</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ "textAlign": "right", "margin": "15px" }}>
                <IconButton onClick={() => setDialogOpen(true)}><CodeIcon /></IconButton>
                <IconButton onClick={() => onDone(internalState)}><SaveIcon /></IconButton>
                <IconButton onClick={() => onDelete(internalState)}><DeleteIcon /></IconButton>
            </div>
            {internalState && <>
                <div className={classes.div}>
                    <TextField
                        label="Message:"
                        style={{ width: '600px' }}
                        rowsMax="5"
                        multiline
                        value={internalState?.message}
                        onChange={(e) => {
                            let message = e.target.value;
                            setInternalState({ ...internalState, message });
                        }}
                    />
                    <br /> <br />
                    <DateTimeRange
                        ampm={true}
                        startLabel="Window Start"
                        endLabel="Window End"
                        width={"210px"}
                        startTime={internalState?.startTime}
                        endTime={internalState?.endTime}
                        endTimeChange={(endTime) => { 
                            const startTime = Moment(endTime).isBefore(internalState.startTime) ? Moment(endTime).seconds(0).subtract(1,'hour').toDate() : internalState?.startTime;
                            setInternalState({ ...internalState, endTime, startTime }); 
                        }}
                        startTimeChange={(startTime) => { 
                            const endTime = Moment(startTime).seconds(0).add(1,'hour').toDate();
                            setInternalState({ ...internalState, startTime, endTime }); 
                        }}
                    />
                    <br /> <br />
                    <div className={classes.grid}>
                        <Checkbox
                            onChange={(e, v) => {
                                setInternalState({ ...internalState, disableLogin: v })
                            }}
                            checked={internalState.disableLogin === true}
                            value={"Disable Login"}
                        />
                        <div className={classes.grid1}>Disable Login</div>
                    </div>
                    <br />
                    <Typography>Select the groups:</Typography>
                    {GROUPS.map((group) => {
                        return <div className={classes.grid}>
                            <Checkbox
                                key={group}
                                onChange={(e) => {
                                    if (internalState.groups.includes(group)) {
                                        internalState.groups = internalState.groups.filter(item => item !== group);
                                        setInternalState({ ...internalState })
                                    } else {
                                        internalState.groups = [group, ...internalState.groups];
                                        setInternalState({ ...internalState })

                                    }
                                }}
                                checked={internalState.groups.includes(group)}
                                value={group}
                            />
                            <div className={classes.grid1}>{group}</div>
                        </div>
                    })}
                </div>
            </>}
            <Dialog
                model="true"
                maxWidth={'md'}
                fullWidth={true}
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
            >
                <DialogTitle>Maintenance Window</DialogTitle>
                <DialogContent>
                    <Paper key="2" className={classes.jsonPaper}>
                        <ReactJson src={internalState}
                            collapsed={3} name="Maintenance Window" collapseStringsAfterLength={64} displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} variant="contained" onClick={() => setDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )

}

const styles = ({ palette }) => ({
    div: {
        margin: "10px"
    },
    appBar: {
        backgroundColor: "#FF9800",
        width: '700px'
    },
    button: {
        margin: '1rem',
    },
    error: {
        margin: '1rem',
        color: "red",
    },
    typePreview: {
        color: "grey",
        marginLeft: '2rem'
    },
    dataTypes: {
        background: 'yellow',
        width: '100%'
    },
    name: {
        width: '100%'
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    grid1: {
        flexGrow: 1
    },
    grid2: {
        minWidth: '50%',
        color: 'gray',
        '&[data-found="1"]': {
            color: 'red',
        }
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }

});

export default withStyles(styles)(MaintenanceWindowsView);