
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    withStyles,
    useTheme,
    IconButton,
    DialogTitle,
    DialogContent,
    Dialog,
    Paper,
    Tooltip,
} from '@material-ui/core/';
import {
    Refresh,
    Code,
} from '@material-ui/icons';
import { green, red, yellow, orange } from '@material-ui/core/colors';
import Draggable from 'react-draggable';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { MapInteractionCSS } from 'react-map-interaction';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import User from '@apricityhealth/web-common-lib/components/User';
import {
    getPatientJournals,
    getAllPatientData,
    loadNotes,
    getPatient,
    loadActivities,
    getPatientDataGroups
} from '@apricityhealth/web-common-lib/utils/Services';
import { getAlert } from '../AlertLevel';
import DetectModel from '../DetectModel';
import '../questionTable.css';
import styles from '../RecentActivityPage.styles'
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import DataChangesView from '../DataChangesView';
import HorizontalDateRange from '../HorizontalDateRange';
import {
    generateComplaintList
} from '../utils'
import ReactMarkdown from 'react-markdown'
import { resolveConflicts } from '@apricityhealth/web-common-lib/utils/Utils';
import triageMask from './light-face-mask-user.svg'

const DATE_FORMAT = 'MM/DD';
const DAILY_TRIGGER_SYMPTOMS_QUESTIONIDS = ["dailyTriggerSymptoms", "postopDailyTriggerSymptoms"];
const DAILY_TRIGGER_NONE_ANSWERIDS = "71272e79-872d-4555-bc5e-da3769c6c580";

let COLORS = {
    "orange": orange["A700"],
    "yellow": yellow["A700"],
    "red": red["A700"],
    "green": green["800"]
}

function sortByAttribute(array, field, sortOrder) {
    if (isArrayValid(array)) {
        return array.sort(
            function (a, b) {
                if (a[field] === b[field]) {
                    return a[field].localeCompare(b[field]);
                } else {
                    return sortOrder.indexOf(a[field]) - sortOrder.indexOf(b[field]);
                }
            }
        );
    }
    return array;
}

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function RecentStory({ loading: parentLoading, classes, patientId, planTypes, alertLevels, modelTypes, detectModels, dataTypes: allDataTypes, questionTypes, appContext, selectedAlert }) {
    let dataTypes = isArrayValid(allDataTypes) ? allDataTypes.filter((type) => type.category !== "labTest") : [];
    let labTypes = isArrayValid(allDataTypes) ? allDataTypes.filter((type) => type.category === "labTest") : [];
    const textContent = useSelector(state => state.app.textContent) //we need to listen to language changes to reload text
    const theme = useTheme();
    const [startTime, setStartTime] = useState(selectedAlert ? moment(selectedAlert.eventTime).subtract(5, "days").startOf("day").toDate() : moment().subtract(30, "days").startOf("day").toDate());
    const [endTime, setEndTime] = useState(moment().endOf("day").toDate());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [patientData, setPatientData] = useState([]);
    const [patientDataGroups, setPatientDataGroups] = useState([]);
    const [notes, setNotes] = useState([]);
    const [labs, setLabs] = useState([]);
    const [journals, setJournals] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDialogContent, setCurrentPopover] = useState({});
    const [patient, setPatient] = useState(null);
    const [activities, setActivities] = useState([]);
    const [timeZone, setTimeZone] = useState(moment.tz.guess());
    useEffect(() => {
        return () => {
            setCurrentPopover({})
            setNotes([])
            setAlerts([])
            setActivities([])
            setPatient(null)
        }
    }, [])

    async function internalFetchData() {
        setLoading(true)
        setCurrentPopover({})
        setNotes([])
        setAlerts([])
        setActivities([])
        setPatient(null)
        try {
            if (patientId && patientId !== "*" && !loading) {
                const opts = { startTime: startTime.toISOString(), endTime: endTime.toISOString() };
                let [notes, journalsData, patientData, patientDataGroups, patient, activities] = await Promise.all([
                    loadNotes(appContext, patientId, opts ),
                    getPatientJournals(appContext, patientId),
                    getAllPatientData(appContext, patientId, startTime, endTime),
                    getPatientDataGroups(appContext, patientId, opts ),
                    getPatient(appContext, patientId),
                    loadActivities(appContext, { patientId, activityType: 'PatientRejectedCallback' })
                ])

                setActivities(activities)
                setNotes(notes.notes);
                setJournals(journalsData.records || []);
                setPatientData(patientData);
                setPatientDataGroups(patientDataGroups);

                let alerts = patientData.filter((data) => data.dataId === "iraeOverallAlert");
                if (Array.isArray(alerts)) {
                    alerts.forEach(element => {
                        element.id = element._id;
                        if (alertLevels) {
                            let alertLevel = alertLevels[element.data[0]]
                            if (alertLevel)
                                element.priority = alertLevel.priority
                        }
                    });
                    setAlerts(alerts);
                }
                if (isArrayValid(labTypes)) {
                    let labDataIds = labTypes.map((t) => t.dataId);
                    let labs = patientData.filter((data) => labDataIds.includes(data.dataId));
                    if (Array.isArray(labs)) {
                        labs.forEach(element => {
                            element.id = element._id;
                        });
                    }
                    setLabs(labs);
                }

                if (patient && patient.timezone) {
                    setTimeZone(patient.timezone);
                }

                setPatient(patient)

            }

            setLoading(false)
        } catch (error) {
            console.error(`Error fetching alert data `, error)
            setError(getErrorMessage(error))
            setLoading(false)
        }
    }
    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [startTime, endTime, patientId])


    const modelTypeSelected = (event, model) => {
        let detectModel = detectModels.find((detect) => detect.modelDataId === model.dataId)
        setCurrentPopover(<DetectModel readOnly={true} model={modelTypes} conditionModel={model} detectModel={detectModel} alerts={alertLevels} dataTypes={dataTypes} />);
        setAnchorEl(event.currentTarget);
    };

    const changesSelected = (event, modelDataType, changesFrom, changesTo, providerId, modelInstanceId, modelInstance) => {
        setCurrentPopover(<DataChangesView changesTo={changesTo} changesFrom={changesFrom} modelDataType={modelDataType} providerId={providerId} modelInstanceId={modelInstanceId} modelInstance={modelInstance} />);
        setAnchorEl(event.currentTarget);
    }



    function findLabTriggeredSymptoms(alerts, labDataId, sessionId, index, lab) {
        let triggeredSymptoms = []
        if (sessionId) {
            if (isArrayValid(alerts)) {
                for (let index1 = 0; index1 < alerts.length; index1++) {
                    let alert = alerts[index1];
                    if (isArrayValid(alert.source)) {
                        let sources = alert.source;
                        for (let index2 = 0; index2 < sources.length; index2++) {
                            let source = sources[index2];
                            if (isArrayValid(source.complaints)) {
                                let complaints = source.complaints;
                                for (let index3 = 0; index3 < complaints.length; index3++) {
                                    const complaint = complaints[index3];
                                    if (isArrayValid(complaint.symptoms)) {
                                        let symptoms = complaint.symptoms;
                                        for (let index4 = 0; index4 < symptoms.length; index4++) {
                                            const symptom = symptoms[index4];
                                            if (symptom.sessionId && (symptom.sessionId === sessionId) && (symptom.dataId === labDataId)) {
                                                ///yah found a symptom triggered by this lab 
                                                triggeredSymptoms.push({ ...symptom, alert: complaint.alert })
                                                if (!symptom.labs) symptom.labs = [];
                                                if (!symptom.labs.find((l) => l.labDataId === labDataId))
                                                    symptom.labs.push({ labDataId, index, lab });
                                            }

                                        }
                                    }

                                }

                            }

                        }
                    }
                }
            }
        }
        return triggeredSymptoms;
    }

    function labsList(dayLabs) {
        let labs = [];
        dayLabs = sortByAttribute(dayLabs, "highestAlert", ['red', 'orange', 'yellow', 'green'])

        dayLabs.forEach(({ eventTime, dataId, data, triggeredSymptoms, highestTriggeredAlert, triggeredIndex }, index) => {
            if (!highestTriggeredAlert) highestTriggeredAlert = "black"
            let name = dataId;
            let dataDescriptions = JSON.stringify(data);
            let labType = labTypes.find((type) => type.dataId === dataId);
            if (labType) {
                name = labType.name;
                dataDescriptions = data.map((d, index) => {
                    let description = (index < labType.tupleDescriptions.length) ? labType.tupleDescriptions[index].description : 'n/a';
                    return <li className={classes.symptom} style={{ paddingLeft: "2px" }}>{description}:&nbsp; {isNaN(d) ? d : typeof d === 'number' ? Number(d.toFixed(3)) : <i>{d}</i>} </li>
                });
            }
            labs.push(<div className={classes.row}><div className={classes.noBreak}>{moment(eventTime).format("hh:mm a")}</div>&nbsp;<div  ><strong style={{ color: highestTriggeredAlert }}>{name}</strong>{isArrayValid(triggeredSymptoms) ? <><FontAwesomeIcon className={classes.smallLink} style={{ paddingLeft: "4px", }} icon="fa-regular fa-chart-network" /><sup><span className={classes.labLink} >{triggeredIndex}</span></sup> </> : null}<ul style={{ marginTop: "0px", paddingLeft: "25px" }}>{dataDescriptions}</ul></div></div>)
        })
        return <div>{labs}</div>
    }

    function noteList(dayNotes) {
        let notes = [];
        dayNotes.forEach((n, index) => {
            if (n.note) {
                if (n.category === "other") {
                    notes.push(
                        <div style={{ width: "100%", minWidth: "30rem" }}>
                            < ReactMarkdown>
                                {n.note}
                            </ReactMarkdown >
                        </div>);
                } if (n.category === "triage") {
                    if (n.soap?.triageNote) {
                        notes.push(
                            <div style={{ width: "100%", minWidth: "30rem" }}>
                                < ReactMarkdown>
                                    {n.soap?.triageNote}
                                </ReactMarkdown ></div>);
                    }
                } else {
                    let addIndex = n.note.indexOf("ADDITIONAL NOTES:");
                    let subIndex = n.note.indexOf("SUBJECTIVE");
                    let noteText = "";
                    if (addIndex > 0) {
                        if (subIndex > addIndex) {
                            noteText = n.note.slice(addIndex + 18, subIndex);
                        } else {
                            noteText = n.note.slice(addIndex + 18);
                        }
                        if (noteText.trim().toLowerCase() !== "none") {
                            notes.push(
                                <div style={{ width: "100%", minWidth: "30rem" }}>
                                    < ReactMarkdown>
                                        {noteText}
                                    </ReactMarkdown ></div>);
                        }
                    }
                }
            }
        })
        return <div>{notes}</div>
    }

    function findNoTriggerAnswer(sessionId) {
        let triggerAnswer = "none"
        //see if there is a dailyTriggerSymptoms and if the answer is none.. this is valuable
        let journal = Array.isArray(journals) && journals.find((j) => j.sessionId === sessionId);
        if (journal && isArrayValid(journal.journal)) {
            let dailyTriggerQuestion = journal.journal.find((j) => DAILY_TRIGGER_SYMPTOMS_QUESTIONIDS.includes(j.questionId));
            if (dailyTriggerQuestion) {
                let { answers } = dailyTriggerQuestion;
                if (isArrayValid(answers)) {
                    let answer = answers.find((a) => a.answerId === DAILY_TRIGGER_NONE_ANSWERIDS);
                    if (answer)
                        triggerAnswer = <div className={classes.row}>{<strong style={{ width: "6.5rem", color: COLORS["green"] }}>No symptoms</strong>}<FontAwesomeIcon onClick={(e) => handleClick(e, { journal })} style={{ cursor: "pointer" }} className={classes.icon} icon="fa-light fa-comment-check" /></div>

                }
            }
        }
        return triggerAnswer;
    }

    function getAlertSource(alert, day) {
        let alertCreatedAt = alert.eventTime;
        let journal = Array.isArray(journals) && journals.find((j) => j.sessionId === alert.sessionId);
        let checkInTime = journal ? journal.createDate : alertCreatedAt;
        let triageAssessedAt = null;
        let triageAssessedBy = null;
        let client = null;

        let triageAssessmentSource = alert.source.find((s) => s.action && s.action === "triage-assessment");
        if (triageAssessmentSource) {
            client = triageAssessmentSource.client;
            triageAssessedAt = triageAssessmentSource.updatedAt;
            triageAssessedBy = triageAssessmentSource.updatedBy;
        }

        if (!client) {
            client = alert.source.find((s) => s.client);
            if (client)
                client = client.client;
        }

        let origin = alert.source.find((s) => s.origin);
        if (origin)
            origin = origin.origin;
        let userType = null;
        let userTypeSource = alert.source.find((s) => s.userType);
        let userId = null;
        if (userTypeSource) {
            userType = userTypeSource.userType;
            userId = userTypeSource.userId;
        }

        let isLab = alert.source.find((s) => s.lab === true);
        let isSMS = alert.source.find((s) => s.sms === true);
        let isMobileClient = client === "patient-mobile-client";
        let isRosettaClient = client === "rosetta-web-client";
        let isProviderClient = client === "provider-web-client";

        let alertSource = [];
        if (isMobileClient) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Mobile"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-mobile-screen-button" /></HtmlTooltip></span>);
        }
        if (isRosettaClient) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Rosetta"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-book-open-reader" /></HtmlTooltip></span>);
        }
        if (isProviderClient) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Provider Client"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-browser" /></HtmlTooltip></span>);
        }
        if (isLab) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Lab"}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-flask" /></HtmlTooltip></span>);
        }
        if (isSMS) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"SMS"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-comment-sms" /></HtmlTooltip></span>);
        }

        if (userType === "provider" && !triageAssessedAt) {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Provider: <Provider appContext={appContext} userId={userId} /></>}><FontAwesomeIcon onClick={(e) => handleClick(e, { journal })} style={{ cursor: "pointer" }} className={classes.icon} icon="fa-light fa-user-doctor-message" /></HtmlTooltip></span>);
        }
        if (userType === "patient") {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Patient: <User appContext={appContext} userId={userId} /></>}><FontAwesomeIcon onClick={(e) => handleClick(e, { journal })} style={{ cursor: "pointer" }} className={classes.icon} icon="fa-light fa-face-mask" /></HtmlTooltip></span>);
        }
        if (userType === "delegate") {
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Delegate:<User appContext={appContext} userID={userId} /> </>}><FontAwesomeIcon onClick={(e) => handleClick(e, { journal })} style={{ cursor: "pointer" }} className={classes.icon} icon="fa-light fa-comments" /></HtmlTooltip></span>);
        }

        if (triageAssessedAt) {
            if (moment(triageAssessedAt).tz(timeZone).isSame(moment(day).tz(timeZone), 'day')) {
                triageAssessedAt = moment(triageAssessedAt).tz(timeZone).format("h:mm:ss a");
            } else {
                triageAssessedAt = moment(triageAssessedAt).tz(timeZone).format("MMM D, h:mm:ss a");
                triageAssessedAt = <span style={{ color: "#DD571C" }}>{triageAssessedAt}</span>
            }
            alertSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Provider: <Provider appContext={appContext} providerId={triageAssessedBy} /></>}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-person-circle-xmark" /></HtmlTooltip></span>)
            alertSource.push(<>&nbsp;{triageAssessedAt}</>);
        } else if (checkInTime) {
            if (moment(checkInTime).tz(timeZone).isSame(moment(day).tz(timeZone), 'day')) {
                checkInTime = moment(checkInTime).tz(timeZone).format("h:mm a");
            } else {
                checkInTime = moment(checkInTime).tz(timeZone).format("MMM D, h:mm a");
                checkInTime = <span style={{ color: "#DD571C" }}>{checkInTime}</span>
            }
            alertSource.push(<>&nbsp;{checkInTime}</>);
        }
        return alertSource;
    }

    function getTriageQuestionsAction(alert, day) {
        let triageQuestionsSource = [];
        let journal = Array.isArray(journals) && journals.find((j) => j.sessionId === alert.sessionId);
        let triageUpdatedAt = null;
        let triageUpdatedBy = null;
        let triageAction = isArrayValid(journal?.source) ? journal.source.find((s) => s.action && s.action === "triage-questions") : null;
        if (triageAction) {
            triageUpdatedAt = triageAction.updatedAt;
            triageUpdatedBy = triageAction.updatedBy;
        }
        let triageQuestions = isArrayValid(journal?.journal) ? journal.journal.filter((q) => Boolean(q.triage)) : [];
        if (isArrayValid(triageQuestions)) {
            if (!triageUpdatedAt)
                triageUpdatedAt = journal.updatedAt; //this is for backwareds compatibility
            journal = triageQuestions;
            if (!triageUpdatedBy && triageQuestions[0].userType === "provider")
                triageUpdatedBy = triageQuestions[0].userId; //this is for backwareds compatibility

        }

        if (triageUpdatedAt) {
            triageQuestionsSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Provider: <Provider appContext={appContext} userId={triageUpdatedBy} /></>}><img alt="triagejournal" onClick={(e) => handleClick(e, { triageQuestions })} style={{ cursor: "pointer" }} className={classes.icon} src={triageMask}></img></HtmlTooltip></span>);
            if (moment(triageUpdatedAt).tz(timeZone).isSame(moment(day).tz(timeZone), 'day')) {
                triageUpdatedAt = moment(triageUpdatedAt).tz(timeZone).format("h:mm a");
            } else {
                triageUpdatedAt = moment(triageUpdatedAt).tz(timeZone).format("MMM D, h:mm a");
                triageUpdatedAt = <span style={{ color: "#DD571C" }}>{triageUpdatedAt}</span>
            }
            triageQuestionsSource.push(<>&nbsp;{triageUpdatedAt}</>)
        }


        return triageQuestionsSource;
    }

    function getTriageAction(alert, day) {
        let client = null;
        let isTriage = false;
        let isAckAll = false;
        let triagedBy = null;
        let triagedAt = null;

        let triageAction = alert.source.find((s) => s.action && s.action === "triage");
        if (triageAction) {
            isTriage = true;
            client = triageAction.client;
            triagedAt = triageAction.updatedAt;
            triagedBy = triageAction.updatedBy;
        }

        let ackAllGreenAction = alert.source.find((s) => s.action && s.action === "acknowledge-all-green");
        if (ackAllGreenAction) {
            isAckAll = true;
            client = ackAllGreenAction.client;
            triagedAt = ackAllGreenAction.updatedAt;
            triagedBy = ackAllGreenAction.updatedBy;
        }

        let dataGroup = patientDataGroups.find((group) => group.groupId === alert.groupId);
        if (dataGroup) {
            if (!isAckAll) {
                //try the data group.. for backwards compatibility 
                let ackAll = dataGroup.source.find((s) => s.action && s.action === "acknowledge-all-green");
                if (ackAll) {
                    isAckAll = true;
                    client = ackAll.client;
                    triagedBy = ackAll.updatedBy;
                    triagedAt = ackAll.updatedAt;
                }
            }

            if (!isTriage) { //for backwareds compatibility
                let triageAction = dataGroup.source.find((s) => s.action && s.action === "triage" && s.sessionId === alert.sessionId);
                if (triageAction) {
                    isTriage = true;
                    client = triageAction.client;
                    triagedAt = triageAction.updatedAt;
                    triagedBy = triageAction.updatedBy;
                }
            }

        }


        let isMobileClient = client === "patient-mobile-client";
        let isRosettaClient = client === "rosetta-web-client";
        let isProviderClient = client === "provider-web-client";

        let triageSource = [];

        if (isMobileClient) {
            triageSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Mobile"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-mobile-screen-button" /></HtmlTooltip></span>);
        }
        if (isRosettaClient) {
            triageSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Rosetta"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-book-open-reader" /></HtmlTooltip></span>);
        }
        if (isProviderClient) {
            triageSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={"Provider Client"}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-browser" /></HtmlTooltip></span>);
        }
        if (isTriage) {
            triageSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Provider: <Provider appContext={appContext} userId={triagedBy} /></>}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-clipboard-list-check" /></HtmlTooltip></span>);
        }
        if (isAckAll) {
            triageSource.push(<span style={{ width: "2rem" }}><HtmlTooltip title={<>Provider: <Provider appContext={appContext} userId={triagedBy} /></>}><FontAwesomeIcon className={classes.icon} style={{ color: "green" }} icon="fa-regular fa-badge-check" /></HtmlTooltip></span>);
        }
        if (triagedAt) {
            if (moment(triagedAt).tz(timeZone).isSame(moment(day).tz(timeZone), 'day')) {
                triagedAt = moment(triagedAt).tz(timeZone).format("h:mm:ss a");
            } else {
                triagedAt = moment(triagedAt).tz(timeZone).format("MMM D, h:mm:ss a");
                triagedAt = <span style={{ color: "#DD571C" }}>{triagedAt}</span>
            }
            triageSource.push(<>&nbsp;{triagedAt}</>)
        }

        return triageSource;
    }


    function buildSessionHistory(alerts, day) {
        //alerts are all in the same session.

        alerts = _.cloneDeep(alerts); //clone in case we modify stuff we don't affect the rest 
        alerts = _.sortBy(alerts, [(item) => new Date(item.eventTime)]); //sort in ascending

        let dayComplaintList = [];
        let symptomList = [];
        let errors = [];

        if (!isArrayValid(alerts)) return { dayComplaintList, history: null };
        let rows = []
        // alerts = _.sortBy(alerts, (a) => new Date(a.createdAt));

        let totalAlerts = alerts.length;
        for (let index = 0; index < alerts.length; index++) {
            const nextAlert = alerts[index];
            let isLast = index === (totalAlerts - 1);

            // alerts.forEach((nextAlert, index) => {
            let alert = _.cloneDeep(nextAlert)
            // let { alertSource, alertCreatedAt, checkInTime } = getAlertSourceAndTime(alert, day);
            let alertSource = getAlertSource(alert, day);
            let triageAction = getTriageAction(alert, day);
            let triageQuestionsAction = getTriageQuestionsAction(alert, day)

            // let { alertCreatedAt, checkInTime, traigeUpdateDate } = alertTimeHistory(alert, day);
            let complaintSource = alert.source.find((s) => Array.isArray(s.complaints));
            let alertComplaints = [];
            if (complaintSource) alertComplaints = complaintSource.complaints;
            if (!alertComplaints) alertComplaints = [];
            //backwards compatibility for green alerts that were deleted. This can be removed at some point since we are keepingn those in the source now
            alertComplaints = getMissingComplaints(alert, alertComplaints, alerts);

            if (isArrayValid(alertComplaints)) {
                complaintSource.complaints = alertComplaints;
                let rejectedActivity = activities.find((a) => a.activityType === "PatientRejectedCallback" && a.sessionId === nextAlert.sessionId);
                let { complaints, errors: complaintErrors } = generateComplaintList({ classes, rejectedActivity, alert, patientData, dataTypes: resolveConflicts(dataTypes, patient.planIds, "dataId"), modelTypes: resolveConflicts(modelTypes, patient.planIds, "modelId"), planTypes, modelTypeSelected, changesSelected, textContent });
                symptomList = complaints;
                errors = [...errors, ...complaintErrors];
            } else {
                //no compliants at all.. so lets make sure the patient emphatically indicated no symptoms and show it if so.. otherwise put none
                symptomList = findNoTriggerAnswer(alert.sessionId);
            }
            //this is for a session.. so the first alert in the session is the 'check in'
            rows.push(
                <tr>
                    <td style={{ minWidth: "14rem", verticalAlign: "top", paddingLeft: index === 0 ? "0" : "2rem" }}>
                        {alertSource}
                        {index === 0 && isArrayValid(triageQuestionsAction) ? <><br /><span style={{ paddingLeft: "2rem" }}>{triageQuestionsAction}<br /></span></> : null}
                        <span style={{ paddingLeft: index === 0 ? "2rem" : "0" }}>
                            {isLast && <div style={{ paddingLeft: index === 0 ? "2rem" : "0" }}>{triageAction}</div>}
                        </span>
                    </td>
                    <td style={{ minWidth: "3rem", width: "30rem", verticalAlign: "top" }}>{symptomList}</td>
                </tr>)
            // });
        }
        let history =
            <table className="empty_table">
                <tbody>
                    {rows}
                </tbody>
            </table>

        return { history, dayComplaintList, errors };

    }

    function getMissingComplaints(alert, alertComplaints, alerts) {
        //backwards compatibility for green alerts that were deleted
        alerts = _.sortBy(alerts, (a) => new Date(a.eventTime)).reverse();
        let priorAlert = alerts.find((a) => a.groupId === alert.groupId && moment(a.eventTime).tz(timeZone).isBefore(moment(alert.eventTime).tz(timeZone)));

        if (priorAlert) {
            let complaints = priorAlert.source.find((s) => isArrayValid(s.complaints));
            if (complaints) complaints = complaints.complaints;
            if (isArrayValid(complaints)) {
                //find any that are not in the current list of complaints 
                let removedComplaints = complaints.filter((c) => !alertComplaints.find((ac) => ac.modelId === c.modelId));
                if (isArrayValid(removedComplaints)) {
                    removedComplaints = _.cloneDeep(removedComplaints);
                    removedComplaints.forEach(c => {
                        c.removed = true;
                        c.alert = 'green'
                    });
                    alertComplaints = [...alertComplaints, ...removedComplaints];
                }
            }
        }
        return alertComplaints;
    }

    const story = useMemo(() => {
        if (!loading && !parentLoading) {
            if (isArrayValid(labs)) {
                let sortedlabs = _.sortBy(labs, (item) => new Date(item.eventTime));
                let sortedAlerts = _.sortBy(alerts, (item) => new Date(item.eventTime));
                let labTriggeredIndex = 1;
                for (let index = 0; index < sortedlabs.length; index++) {
                    const lab = sortedlabs[index];
                    lab.highestAlert = 'green';
                    let { dataId, sessionId } = lab;
                    let triggeredSymptoms = findLabTriggeredSymptoms(sortedAlerts, dataId, sessionId, labTriggeredIndex, lab);
                    if (isArrayValid(triggeredSymptoms)) {
                        triggeredSymptoms = sortByAttribute(triggeredSymptoms, "alert", ['red', 'orange', 'yellow', 'green'])
                        let highestAlert = triggeredSymptoms[0].alert;
                        lab.highestAlert = highestAlert;
                        let alertLvel = getAlert(highestAlert);
                        let color = alertLvel ? alertLvel.color : highestAlert
                        lab.triggeredSymptoms = triggeredSymptoms;
                        lab.triggeredIndex = labTriggeredIndex;
                        lab.highestTriggeredAlert = color;
                        labTriggeredIndex += 1;
                    }

                }
            }
            let errors = []

            let headers = [<th >Day</th>];
            let dayAlerts = [<th>Alert</th>];
            let complaints = [<th>Symptoms<sup>1</sup></th>];
            let storyNotes = [<th>Actions<sup>2</sup></th>];
            let labColumns = [<th>Labs</th>];
            let days = moment(endTime).tz(timeZone).diff(moment(startTime).tz(timeZone), 'days', true);
            for (let i = 0; i < days + 1; ++i) {
                let day = moment(startTime).tz(timeZone).add(i, 'days');
                let highestAlert = "white";
                // let hasLab = false;
                let dayComplaints = [];
                let dayNotes = "";
                let labText = "";
                let sameDayNotes = [];
                let sameDayLabs = [];
                let sameDayJournals = journals.filter((a) => moment(a.createDate).tz(timeZone).isSame(day, "day"));
                let sessionErrors = [];
                let sameDayAlerts = alerts.filter((a) => moment(a.eventTime).tz(timeZone).isSame(day, "day"));
                if (isArrayValid(sameDayAlerts)) {
                    sameDayAlerts = _.sortBy(sameDayAlerts, [(item) => new Date(item.eventTime), (item) => item.priority]).reverse();
                    highestAlert = sameDayAlerts[0].data[0];
                    let groupBySession = _.groupBy(sameDayAlerts, "sessionId");
                    Object.entries(groupBySession).forEach(([sessionId, alerts]) => {
                        let sessionHistory = buildSessionHistory(alerts, day);
                        sessionErrors = sessionErrors.concat(...sessionHistory.errors);
                        if (sessionHistory.history)
                            dayComplaints.push(sessionHistory.history);
                    });
                }
                errors = errors.concat(...sessionErrors);
                if (isArrayValid(notes)) {

                    sameDayNotes = notes.filter((a) => moment(a.createdAt).tz(timeZone).isSame(day.tz(timeZone), "day"));
                    if (isArrayValid(sameDayNotes)) {
                        sameDayNotes = _.sortBy(sameDayNotes, (item) => new Date(item.createdAt)).reverse();
                        dayNotes = noteList(sameDayNotes);
                    }
                }
                if (isArrayValid(labs)) {
                    sameDayLabs = labs.filter((a) => moment(a.eventTime).tz(timeZone).isSame(day.tz(timeZone), "day"));
                    if (isArrayValid(sameDayLabs)) {
                        sameDayLabs = _.sortBy(sameDayLabs, (item) => new Date(item.eventTime)).reverse();
                        labText = labsList(sameDayLabs);
                    }
                }
                let canSelect = isArrayValid(sameDayAlerts) || isArrayValid(sameDayNotes) || isArrayValid(sameDayJournals) || isArrayValid(sameDayNotes) ? true : false;
                headers.push(<th>
                    <div style={{ display: "flex" }}>
                        {moment(day).tz(timeZone).format(DATE_FORMAT)}
                        <div style={{ width: "100%", textAlign: "right", marginLeft: '5px' }}>
                            {canSelect ? <FontAwesomeIcon className={classes.icon} icon="fa-regular fa-list" style={{ cursor: canSelect ? "pointer" : null }} onClick={(e) => handleClick(e, { sameDayAlerts, sameDayJournals, sameDayNotes, sameDayLabs, })} /> : null}
                        </div>
                    </div>
                </th>)
                dayAlerts.push(<td style={{ backgroundColor: COLORS[highestAlert], height: "30px", textAlign: "right" }}></td>);
                complaints.push(<td style={{ verticalAlign: "top", padding: "0px", }}>{dayComplaints}</td>)
                storyNotes.push(<td style={{ backgroundColor: "white", verticalAlign: "top" }}>{dayNotes}</td>)
                labColumns.push(<td style={{ backgroundColor: "white", verticalAlign: "top" }}>{labText}</td>)
            }

            setError(Array.from(new Set(errors)).map((s, index) => `${index + 1}. ${s}`).join("\n"))


            headers.push(<th>Day</th>)
            dayAlerts.push(<th>Alert</th>)
            complaints.push(<th>Symptoms</th>)
            storyNotes.push(<th>Actions</th>)
            labColumns.push(<th>Labs</th>)

            return <>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    <table className="simple_table" style={{ width: "20rem", marginBottom: "0px" }}>
                        <tbody>
                            <tr>{headers}</tr>
                            <tr>{dayAlerts}</tr>
                            <tr>{complaints}</tr>
                            <tr>{storyNotes}</tr>
                            <tr>{labColumns}</tr>
                        </tbody>
                    </table>
                    <div className={classes.legend}>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-face-mask" /><span className={classes.legendText}>Patient Checkin</span></div>
                        <div className={classes.legendItem}><img alt="triagejournal" className={classes.icon} src={triageMask}></img><span className={classes.legendText}>Triage Questions</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-comment-sms" /><span className={classes.legendText}>SMS Checkin</span></div>                   <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-comment-check" /><span className={classes.legendText}>Patient Selected No Symptoms</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-mobile-screen-button" /><span className={classes.legendText}>Mobile Device</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-clipboard-list-check" /><span className={classes.legendText}>Physcian Triaged</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} style={{ color: "green" }} icon="fa-regular fa-badge-check" /><span className={classes.legendText}>Acknowledge All Green</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-circle-radiation" /><span className={classes.legendText}>Detect Model</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-user-doctor" /><span className={classes.legendText}>Physcian</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-user-doctor-message" /><span className={classes.legendText}>Physcian Checkin</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-light fa-person-circle-xmark" /><span className={classes.legendText}>Physcian Change</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-user-gear" /><span className={classes.legendText}>Admin Console</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-flask" /><span className={classes.legendText}>Lab</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-book-open-reader" /><span className={classes.legendText}>Rosetta Web Client</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-browser" /><span className={classes.legendText}>Provider Web Client</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-notes-medical" /><span className={classes.legendText}>EMR Import</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-chart-network" /><span className={classes.legendText}>Lab Driven PRO</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-solid fa-play" /><span className={classes.legendText}>Trigger</span></div>
                        <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-calendar-xmark" /><span className={classes.legendText}>Rejected Callback</span></div>
                    </div>
                </div>
                <div style={{ marginTop: '0px', paddingTop: '0px' }}><i><sup>1</sup>The check-in time can sometimes be a few seconds or even minutes before the alert since the check in can take time.</i></div>
                <div style={{ marginTop: '0px', paddingTop: '0px' }}><i><sup>2</sup>Actions are notes and only show after 'additional' in the note and if other than 'none', as well as category 'other'.</i></div>
            </>
        } else {
            return null;
        }

    }, [loading, parentLoading])


    const showCode = (event, content) => {
        setCurrentPopover(<Paper key="2" className={classes.jsonPaper}>
            <ReactJson
                src={{ alerts, alertLevels, notes, patientData, journals, labs, questionTypes, modelTypes, dataTypes, labTypes }}
                theme={theme?.overrides?.ReactJsonView}
                collapsed={3}
                name="Data"
                collapseStringsAfterLength={64}
                displayDataTypes={false} />
        </Paper>)
        setAnchorEl(event.currentTarget);
    }

    const handleClick = (event, content) => {
        setCurrentPopover(<ReactJson
            src={content}
            theme={theme?.overrides?.ReactJsonView}
            collapsed={5}
            name="Content"
            collapseStringsAfterLength={64}
            displayDataTypes={false} />)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setCurrentPopover(null);
        setAnchorEl(null);
    };


    let warning = (patientId && patientId === "*") ? "Please select an individual patient" : null;
    const open = Boolean(anchorEl);

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                {warning}
                {error && <ReactMarkdown>{error}</ReactMarkdown>}
                {!warning && <div className={classes.row}>
                    <div className={classes.search} style={{ marginBottom: "-10px" }}>
                        <div style={{ width: "700px" }}>
                            <DateRange
                                format={"MM/dd/yyyy"}
                                startTime={startTime}
                                endTime={endTime}
                                endTimeChange={(endTime) => setEndTime(moment(endTime).endOf("day"))}
                                startTimeChange={(startTime) => setStartTime(moment(startTime).startOf("day"))}
                            />
                            <HorizontalDateRange onChange={(range) => setStartTime(moment().subtract(range, 'days'))} />
                        </div>
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={(e) => showCode(e)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            disabled={loading}
                            color="primary"
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>}
                {story && !loading &&
                    <div className={classes.story} >
                        <MapInteractionCSS showControls >
                            {!warning && story}
                        </MapInteractionCSS>
                    </div>
                }
            </div>
            <Dialog
                open={open && Boolean(currentDialogContent)}
                onClose={handleClose}
                maxWidth={'xl'}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <div style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}><button onClick={handleClose}>Close</button></div>
                </DialogTitle>
                <DialogContent>
                    {currentDialogContent}
                </DialogContent>
            </Dialog>
        </div>
    )
}


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default withStyles(styles)(RecentStory)