import React, { Component } from 'react';
import Config from '@apricityhealth/web-common-lib/Config';
import { axios } from '@apricityhealth/web-common-lib';
import JSONView from './JSONView';
import _ from 'lodash';

import {
    CircularProgress, 
    IconButton, 
    Drawer,
    Tooltip,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import RefreshIcon from '@material-ui/icons/Refresh'

export class CurrentOpsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOps: [],
            progress: null,
            dialog: null,
            autoRefresh: true
        }
    }

    componentWillMount() {
        this.loadContent();
        this._timer = setInterval( this.autoRefresh.bind(this), 1000 * 5 );
    }

    componentWillUnmount() {
        clearInterval( this._timer );
    }

    autoRefresh() {
        if ( this.state.autoRefresh ) 
            this.loadContent();
    }

    loadContent() {
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        const getCurrentOps = {
            url: Config.baseUrl + `${Config.pathPrefix}rosetta/currentOp`,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        };
        console.log("getCurrentOps request:", getCurrentOps);
        axios(getCurrentOps).then(response => {
            console.log("getCurrentOps response:", response);

            for(let i=0;i<response.data.inprog.length;++i) {
                let inprog = response.data.inprog[i];
                let command = _.get(inprog, "originatingCommand") || _.get(inprog, "command");
                if ( command ) {
                    inprog._command = command;
                    let requestId = _.get(command,"comment") || _.get( _.find( _.get(command,"pipeline", []), (obj) => _.get(obj, "$match.$comment")), "$match.$comment");
                    if ( requestId ) 
                        inprog.requestId = requestId;
                }
            }
            this.setState({
                currentOps: response.data.inprog,
                progress: null
            });
        }).catch(err => {
            console.log("getImages error:", err.message);
            this.setState({
                error: getErrorMessage(err),
                progress: null
            });
        });
    }


    onCloseDialog() {
        this.state.table.setState({selected: [] });     //  de-select the item from the table
        this.setState({dialog: null});
    }


    onSelectCurrentOp(selected, table) {
        if ( selected.length > 0 ) {
            const details = table.props.data[selected[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <JSONView appContext={this.props.appContext} data={details} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog, table });
        }
    }

    render() {
        const self = this;
        const { currentOps, progress, error, dialog, autoRefresh } = this.state;

        const columnData = [
            { id: 'opid', label: 'Op ID' },
            { id: 'microsecs_running', numeric: true, label: 'Run Time (seconds)', formatValue: (v) => `${v / 1000000}` },
            { id: '_command', label: "Command", formatValue: (v) => JSON.stringify(v).substr(0, 128) },
            { id: 'requestId', label: 'Request ID' }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                    </td>
                    <td align="right">
                        <span style={{ color: 'red' }}>{error}</span>
                        <Tooltip title='Auto Refresh this view every 5 seconds.'><FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={autoRefresh}
                            onChange={(e, v) => { self.setState({ autoRefresh: v }) }} />} label="Auto Refresh" /></Tooltip>
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable disableMultiSelect={true}
                            disableActions={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.onSelectCurrentOp(s, t)}
                            orderBy='microsecs_running'
                            rowsPerPage={25}
                            order='desc'
                            columnData={columnData}
                            data={currentOps}
                            title='Current Ops' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default CurrentOpsView;
