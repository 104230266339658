import React, { useState, useEffect, useMemo } from 'react';
import {
    AppBar,
    useTheme,
    withStyles,
    IconButton,
    Button,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    Toolbar,
    Typography,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';
import {
    Refresh,
    Code,
} from '@material-ui/icons';
import NavigationClose from '@material-ui/icons/Close';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import Select from 'react-select';

import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import Patient, { getPatientPromise } from '@apricityhealth/web-common-lib/components/Patient';
import Org from '@apricityhealth/web-common-lib/components/Org';
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import {
    getPatientData,
    getPatientDataGroups,
    getPatient,
} from '@apricityhealth/web-common-lib/utils/Services';
import AlertLevel from './AlertLevel';
import styles from './RecentActivityPage.styles'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import Config from '@apricityhealth/web-common-lib/Config';
import AlertDetailsPage from './AlertDetailsPage';


let STAGE = Config.stage;
let ignorePLans = STAGE === 'production' ? [
    "6d16691e-b336-4d8e-be91-d80d4b547cd7",
    "e93e2337-0100-4fe4-bc16-00fa635ccc98",
    "5a850b0a-0d20-4063-afcf-f9c6f4529674",
    "70810e9d-7c24-42aa-a3cc-3f0998332cc4",
    "60c38fac-20a3-44c8-a923-bdcd145e1f3b",
    "527a57e6-6105-4775-a369-cce80c89025c",
    "5f4897fa-f898-439b-b007-ddb386c5a581",
] : STAGE === 'vcc' ? ["63575a2f-2c1e-4e2f-a0e2-a908a1ae0354"] : [];

function AlertsPage({ classes, patientId, planTypes, dataTypes, modelTypes, alertLevels, appContext }) {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(500);
    const [pageSize, setPageSize] = useState(50);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'eventTime', sort: 'desc' }]));
    const [selectionModel, setSelectionModel] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedAlertLevels, setSelectedAlertLevels] = useState(sessionStorage.getItem("alertFlags") ? JSON.parse(sessionStorage.getItem("alertFlags")) : [{ label: "Red", value: "red" }, { label: "Orange", value: "orange" }, { label: "Yellow", value: "yellow" }]);
    const [selectedAlert, setSelectedAlert] = useState({});

    const [alerts, setAlerts] = useState([])
    const [showLabs, setShowLabs] = useState(false)
    const [showCode, setShowCode] = useState(false);

    const [dataGroups, setDataGroups] = useState([])

    // Auto fetch the data on page or patientId change
    useEffect(() => {
        setAlerts([]);
        setError(null)
        setSelectedAlert(null);
        setShowAlert(false);
        internalFetchData();
    }, [page, pageSize, patientId, selectedAlertLevels, showLabs])


    useEffect(() => {
        sessionStorage.setItem("alertFlags", JSON.stringify(selectedAlertLevels))
    }, [selectedAlertLevels])

    async function internalFetchData() {
        try {
            setLoading(true)
            let args = []
            args.push(`dataId=iraeOverallAlert`);
            args.push(`offset=${page * pageSize}`);
            args.push(`limit=${pageSize}`);
            if (isArrayValid(selectedAlertLevels)) {
                args.push(`data=${selectedAlertLevels.map(a => a.value)}`)
            }
            if (Boolean(showLabs)) {
                args.push(`lab=true`);
            } else {
                args.push(`lab=false`);
            }
            // console.debug(`Alerts Page Fetching patient data `, patientId)
            let data = await getPatientData(appContext, patientId, args);
            // console.debug(`Alerts Page Fetching patient data response `, data)

            setTotalCount(data.totalData);
            let alerts = data.data;
            if (Array.isArray(alerts)) {
                alerts.forEach(element => {
                    element.id = element._id;
                });
                let groupId = alerts.map((d) => d.groupId);
                let dataGroups = await getPatientDataGroups(appContext, patientId, { groupId: groupId.join(',') });
                setAlerts(alerts);
                setDataGroups(dataGroups);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error));
            console.error(`Error fetching patient data `, getErrorMessage(error))
        }
    }


    const alertLevelOptions = useMemo(() => {
        if (alertLevels) {
            let entries = Object.entries(alertLevels);
            entries = entries.filter(([a, b]) => !ignorePLans.includes(b.planId));
            let triageAlertLevels = entries.filter(([a, b]) => !b.triage);
            return triageAlertLevels && isArrayValid(triageAlertLevels) ? triageAlertLevels.map(([a, b]) => { return { label: b.name, value: b.alertLevelId } }) : []
        } else {
            return [];
        }
    }, [alertLevels])

    const columns = useMemo(() => {
        return [
            {
                field: 'patientId', headerName: 'Patient', width: 250, renderCell: (params) => {

                    let v = params.value;
                    return <Patient patientId={v} />
                },
            },
            {
                field: 'data',
                headerName: 'Alert',
                width: 120,
                renderCell: (params) => {
                    let v = params.value;
                    let row = params.row;
                    return isArrayValid(v) ? <AlertLevel planId={row.planId} alertId={v[0]} /> : "N/A"
                },
            },
            {
                field: 'eventTime', headerName: 'Event Time',
                type: "date",
                width: 200,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },

            {
                field: 'status',
                headerName: 'Status',
                width: 130,
            },
            {
                field: 'source1',
                headerName: 'Source',
                width: 140,
                renderCell: (params) => {
                    let row = params.row;
                    let v = row.source;
                    if (isArrayValid(v)) {
                        let lro = v.find(source => source.lab === true);
                        if (lro) {
                            return `Lab`
                        }
                        let sms = v.find(source => source.sms === true);
                        if (sms) {
                            return `SMS`
                        }
                        let cro = v.find(source => source.userType === "provider");
                        if (cro) return "Provider"

                        let pro = v.find(source => source.userType === "patient");
                        if (pro) return "Patient"

                        let delegate = v.find(source => source.userType === "delegate");
                        if (delegate) return "Delegate"
                    }

                    return 'unknown'
                },
                cellClassName: (params) => params.value
            },
            {
                field: 'source2',
                headerName: 'Provider',
                width: 100,
                renderCell: (params) => {
                    let row = params.row;
                    let v = row.source;
                    if (isArrayValid(v)) {
                        let cro = v.find(source => source.userType === "provider");
                        if (cro) return <Provider userId={cro.userId} />
                    }
                    if (row.status === "final") {
                        let dataGroup = dataGroups.find((group) => group.groupId === row.groupId);
                        if (dataGroup && dataGroup.providerId) {
                            return <Provider userId={dataGroup.providerId} />
                        }
                    }
                    return "";

                },
                cellClassName: (params) => params.value
            },
            {
                field: 'source2',
                headerName: 'Validated',
                width: 200,
                renderCell: (params) => {
                    let row = params.row;
                    if (row.status === "final") {
                        let dataGroup = dataGroups.find((group) => group.groupId === row.groupId);
                        if (dataGroup && dataGroup.validatedDate) {
                            return moment(dataGroup.validatedDate).format("llll")
                        }
                    }
                    return "";

                },
                cellClassName: (params) => params.value
            },
            {
                field: 'source3',
                headerName: 'Origin',
                width: 150,
                renderCell: (params) => {
                    let row = params.row;
                    let v = row.source;
                    if (isArrayValid(v)) {
                        let origin = v.find(source => source.origin);
                        if (origin)
                            return origin.origin
                    }

                    return 'unknown'
                },
                cellClassName: (params) => params.value
            },
            {
                field: 'source4',
                headerName: 'Org',
                width: 200,
                renderCell: (params) => {
                    let row = params.row;
                    let patientId = row.patientId;
                    return <PatientOrg appContext={appContext} patientId={patientId} />;
                },
                cellClassName: (params) => params.value
            },

            {
                field: 'patientId2',
                headerName: 'Patient Exported',
                width: 190,
                renderCell: (params) => {
                    let row = params.row;
                    return <PatientExport patientId={row.patientId} appContext={appContext} />
                },
            },

        ]
    }, [alerts, dataGroups]);


    async function alertSelected(alert) {
        if (alert) {
            //get the alert data based on groupId
            setSelectedAlert(alert);
            setSelectionModel([alert.id])
            setShowAlert(true);
        }
    }

    function onClose() {
        setShowAlert(false)
        setSelectionModel([]);
    }

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                        Alert levels:
                        <Select
                            className={classes.alertLevelSelect}
                            isMulti
                            options={alertLevelOptions}
                            name="alert levels"
                            value={selectedAlertLevels}
                            onChange={(event) => setSelectedAlertLevels(event)}
                        />
                        <FormControlLabel
                            className={classes.labCheck}
                            control={<Checkbox checked={showLabs}
                                onChange={(e, v) => setShowLabs(!showLabs)}
                            />}
                            label="Labs" />
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}

                    {alerts && <>Note: Updated at can reflect the time the provider updated the alert.<DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50, 100, 150]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={false}
                        columns={columns}
                        rowCount={totalCount}
                        rows={alerts}
                        onRowClick={({ row }) => alertSelected(row)}
                    /></>}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Alerts</DialogTitle>
                    <DialogContent>
                        <div className={classes.drawerPaper}>
                            <ReactJson
                                className={classes.jsonPaper}
                                src={{ alerts, alertLevels, dataTypes, modelTypes }}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Alerts"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showAlert}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={() => onClose()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" >{loading ? <div className={classes.singleLineName}>Loading...<Loading style={{ color: "white" }} /></div> : "Details"}</Typography>
                        </Toolbar>
                    </AppBar>
                    <AlertDetailsPage appContext={appContext} planTypes={planTypes} selectedAlert={selectedAlert} dataTypes={dataTypes} dataGroups={dataGroups} modelTypes={modelTypes} />
                </Drawer>
            </div>
        </div >
    )
}


function PatientExport({ patientId, appContext }) {
    const [patient, setPatient] = useState(null);
    // Auto fetch the data on mount
    useEffect(() => {
        async function inner() {
            try {
                let patient = await getPatient(appContext, patientId);
                if (patient)
                    setPatient(patient)
            } catch (error) {
                console.error("Error getting patient export flag", error)
            }
        }
        inner();
    }, [patientId])

    return (
        patient ? String(patient.exportFlag) : "n/a"
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

function PatientOrg({ patientId, appContext }) {
    const [patient, setPatient] = useState(null);

    useEffect(() => {
        async function inner() {
            try {
                let patient = await getPatientPromise(appContext, patientId);
                // console.debug(`patient promise patient `, patient)
                if (patient)
                    setPatient(patient)
            } catch (error) {
                console.error("Error getting patient export flag", error)
            }
        }
        inner();
    }, [patientId])


    return (
        patient ? <Org appContext={appContext} orgId={patient.primaryOrgId} /> : "n/a"
    )

}

export default withStyles(styles)(AlertsPage)