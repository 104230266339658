import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import clsx from 'clsx';

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from '@material-ui/core/';
import { DRAWER_WIDTH } from './App';

import T from 'i18n-react';

class AppHeader extends Component {

    render(){
        const { username, isMenuOpen, anchorEl, auth,  open, classes } = this.props;
        const {handleMenu, handleMenuClose, onToggle, onSettings,onSignOut } = this.props;
        let pathname = window.location.pathname.slice(1).split('/');
        pathname = pathname[0]

        let pathString = pathname
        return(
            <div>
            <div className={classes.toolbar} />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar disableGutters={!open}>
                <IconButton
                id="toggle"
                  onClick={onToggle}
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu">
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={classes.grow}>
                    {<T.span text={pathString} />}
                </Typography>
                {auth && (
                  <div>
                    <span >{username}</span>
                    <IconButton
                      aria-owns={isMenuOpen ? 'menu-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle id="userProfle" />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={isMenuOpen}
                      onClose={handleMenuClose}
                    >
                      <MenuItem id="settings" onClick={(e) => { onSettings(); }}>Settings</MenuItem>
                      <MenuItem id="signout" onClick={(e) => { onSignOut(); }}>Logout</MenuItem>
                    </Menu>
                  </div>
                )}
              </Toolbar>
            </AppBar>
            </div>
       ) 
    }
}

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: '350ms',
      duration: '350ms',
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: '350ms',
      duration: '350ms',
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 36,
  },
  grow: {
    flexGrow: 1,
  }
});

export default withStyles(styles, { withTheme: true })(AppHeader);