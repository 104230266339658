import React from "react";
import Moment from 'moment';
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import SelectPlan from "@apricityhealth/web-common-lib/components/SelectPlan";

const DATE_FORMAT = 'yyyy-MM-DD';

class EditPatientPlanDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planId: '',
            startDate: '',
            endDate: ''
        };
    }

    componentDidMount() {
        this.loadPlan(this.props.plan);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.plan !== this.props.plan) {
            this.loadPlan(this.props.plan);
        }
    }

    loadPlan( plan ) {
        this.setState({
            planId: plan.planId,
            startDate: plan.startDate ? Moment(plan.startDate).format(DATE_FORMAT) : '',
            endDate: plan.endDate ? Moment(plan.endDate).format(DATE_FORMAT) : ''
        })
    }

    render() {
        console.log("EditPatientPlanDialog.render()", this.state );
        const { planId, startDate, endDate } = this.state;
        const { appContext } = this.props

        return <Dialog
            onClose={this.props.onClose}
            open={true}
        >
            <DialogTitle onClose={this.props.onClose}>
                Plans
            </DialogTitle>
            <DialogContent>
                <div align='left'>
                    <SelectPlan label='Select Plan' appContext={appContext} style={{ margin: 5, width: 300 }} showInactive={true} planId={planId} onChange={(p) => {
                        console.log("SelectPlan.onChange:", p );
                        if (p && p.planId) {
                            this.setState({ planId: p.planId });
                        }
                    }} />
                    <br />
                    <FormControlLabel label='Start Date' control={<Checkbox checked={!!startDate} onChange={(e,v) => {
                        if ( v ) {
                            this.setState({startDate: Moment().format(DATE_FORMAT)})
                        } else {
                            this.setState({startDate: ''});
                        }
                    }} />} />
                    <div><TextField disabled={!startDate} style={{margin: 5, width: 250}} type='date' value={startDate} onChange={(e) => {
                        if (Moment(e.target.value).isValid()) {
                            console.log("startDate:", e.target.value )
                            this.setState({startDate: Moment(e.target.value).format(DATE_FORMAT)})
                        }
                    }} /></div>
                    <br />
                    <FormControlLabel label='End Date' control={<Checkbox checked={!!endDate} onChange={(e,v) => {
                        if ( v ) {
                            this.setState({endDate: Moment().format(DATE_FORMAT)})
                        } else {
                            this.setState({endDate: ''});
                        }
                    }} />} />
                    <div><TextField disabled={!endDate} style={{margin: 5, width: 250}}  type='date' value={endDate} onChange={(e) => {
                        if (Moment(e.target.value).isValid()) {
                            console.log("endDate:", e.target.value )
                            this.setState({endDate: Moment(e.target.value).format(DATE_FORMAT)})
                        }
                    }} /></div>
                <br />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.props.onClose()}>Cancel</Button>
                <Button onClick={() => this.props.onDone({ planId, startDate, endDate })}>Done</Button>
            </DialogActions>
        </Dialog>

    }
}

export default EditPatientPlanDialog;
