import React from 'react';
import {
    AppBar, Toolbar, Typography, Button, CircularProgress, TextField,
    IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    FormControl, InputLabel, MenuItem, Select, Tabs, Tab, Tooltip
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ReportIcon from '@material-ui/icons/Report';

import GetErrorReportIcon from '@material-ui/icons/Assignment'

import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import SelectAnswer from '@apricityhealth/web-common-lib/components/SelectAnswer';

import { loadDetectModels, loadModels } from '@apricityhealth/web-common-lib/utils/Services';

class ErrorReportDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testCreator: JSON.parse(JSON.stringify(props.testCreator)),
            errors: props.errors,
            answerId: ''
        }
    }

    render() {
        const { appContext, planId } = this.props;
        const { testCreator, errors } = this.state;
        let { answerId } = this.state;

        let questionErrors = [];
        for(let i=0;i<errors.length;++i) {
            const error = errors[i];
            let found = [ ...error.message.matchAll(/No response found for question \[(\S+)\]/gm) ];
            if ( found.length > 0 ) {
                const questionId = found[0][1];
                questionErrors.push(<div key={i}>
                    {found[0][0]}
                    <br />
                    <br />
                    <FormControl style={{margin: 5, width: 300}}>
                        <InputLabel>Select Answer</InputLabel>
                        <SelectAnswer appContext={appContext} planId={planId} questionId={questionId} answerId={answerId} enableNone={true} onChange={(v) => {
                            let journal = testCreator.journal.find((e) => e.questionId === questionId);
                            if (! journal ) {
                                testCreator.journal.push({questionId, answerId: v.answerId, answerText: v.answer ? v.answer.text[0] : '' })
                            } else {
                                journal.answerId = v.answerId;
                                journal.answerText = v.answer ? v.answer.text[0] : '';
                            }
                        }} />
                    </FormControl>
                    <br />
                </div>);
            }
        }

        if ( questionErrors.length === 0 ) {
            questionErrors.push(<div key='none'>No questions errors found!</div>)
        }

        return <Dialog open={true} maxWidth='md' fullWidth={true}>
            <DialogTitle>Test Error Report</DialogTitle>
            <DialogContent>
                {questionErrors}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={this.props.onCancel}>Cancel</Button>
                <Button variant='contained' onClick={() => this.props.onDone(testCreator)}>Ok</Button>
            </DialogActions>
        </Dialog>;
    }
}

class TestCreatorView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 'journal',
            detectModels: [],
            models: [],
            testCreator: {
                creatorId: '',
                modelId: '',
                triggerId: '',
                category: 'pro',
                jiraTicket: '',
                limit: 200,
                journal: [],
                history: []
            }
        }
    }

    componentWillMount() {
        const { testCreator, planId, creatorId } = this.props;
        if (testCreator)
            this.loadTestCreator(testCreator);
        else if (planId && creatorId)
            this.getTestCreator(planId, creatorId);
        this.loadModels();
    }
    componentDidUpdate(prevProps) {
        const { testCreator, planId, creatorId } = this.props;
        if (prevProps.testCreator !== testCreator || prevProps.planId !== planId || prevProps.creatorId !== creatorId) {
            if (testCreator)
                this.loadTestCreator(testCreator);
            else if (planId && creatorId)
                this.getTestCreator(planId, creatorId);
        }
    }

    onCloseView() {
        this.props.onClose();
    }

    loadModels() {
        const { planId } = this.props;

        Promise.all([
            loadDetectModels(this.props.appContext, { planId, dependencies: true }),
            loadModels( this.props.appContext, { planId, dependencies: true })
        ]).then(([detectModels, models ]) => {
            const { testCreator } = this.state;
            if (!testCreator.triggerId) {
                const detectModel = detectModels.find((e) => e.modelId === testCreator.modelId);
                console.log("detectModel:", detectModel );
                if ( detectModel ) {
                    const model = models.find((e) => e.dataId === detectModel.modelDataId);
                    console.log("model:", model );
                    if ( model ) {
                        testCreator.triggerId = model.modelId;
                    }
                }
            }
    
            this.setState({ detectModels, models, testCreator });
        });
    }

    getTestCreator(planId, creatorId) {
        const getTestCreator = {
            url: Config.baseUrl + `${Config.pathPrefix}test/creator/${planId}/${creatorId}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };
        console.log("getTestCreator:", getTestCreator);
        this.setState({ progress: <CircularProgress />, error: null });

        Axios(getTestCreator).then((res) => {
            console.log("getTestCreator results:", res);
            this.loadTestCreator(res.data);
        }).catch((err) => {
            console.log("getTestCreator error:", err);
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    loadTestCreator(testCreator) {
        if (testCreator.triggerId === undefined ) testCreator.triggerId = '';
        this.setState({
            testCreator,
            progress: null
        });
    }

    saveTestCreator() {
        const { appContext, planId } = this.props;
        const { testCreator } = this.state;

        const saveTestCreator = {
            url: Config.baseUrl + `${Config.pathPrefix}test/creator/${planId}`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: testCreator
        };

        this.setState({ progress: <CircularProgress />, error: null });

        console.log("saveTestCreator:", saveTestCreator);
        Axios(saveTestCreator).then((response) => {
            console.log("saveTestCreator response:", response);
            this.setState({ testCreator: response.data, progress: null });
        }).catch((error) => {
            console.log("saveTestCreator error:", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    runTestCreator() {
        const { appContext } = this.props;
        const { testCreator } = this.state;

        const runTestCreator = {
            url: Config.baseUrl + `${Config.pathPrefix}test/creator/run`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: testCreator
        };

        this.setState({ progress: <CircularProgress />, error: null });

        console.log("runTestCreator:", runTestCreator);
        Axios(runTestCreator).then((response) => {
            console.log("runTestCreator response:", response.data );
            this.setState({ progress: null });
        }).catch((err) => {
            console.log("runTestCreator error:", err);
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    deleteTestCreator() {
        console.log("deleteTestCreator()", this);
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Test Creator: {`${this.state.testCreator.creatorId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.onCancelDialog.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.onConfirmDeleteTestCreator.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCancelDialog() {
        this.setState({ dialog: null });
    }

    onConfirmDeleteTestCreator() {
        const { testCreator } = this.state;

        this.setState({ dialog: null });
        if (testCreator.creatorId) {
            const deleteTestCreator = {
                url: Config.baseUrl + `${Config.pathPrefix}test/creator/${testCreator.planId}/${testCreator.creatorId}`,
                method: 'DELETE',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            this.setState({ progress: <CircularProgress />, error: null });
            console.log("deleteTestCreator:", deleteTestCreator);
            Axios(deleteTestCreator).then((response) => {
                console.log("deleteTestCreator response:", response);
                this.setState({ progress: null });
                this.onCloseView();
            }).catch((error) => {
                console.log("deleteTestCreator error:", error);
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    getErrorReport() {
        const { testCreator } = this.state;

        const getTestErrors = {
            url: Config.baseUrl + `${Config.pathPrefix}test/errors/${this.props.planId}/*?category=${testCreator.modelId}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        this.setState({progress: <CircularProgress />, error: null});
        console.log("getErrorReport request:", getTestErrors);
        Axios(getTestErrors).then((response) => {
            console.log("getErrorReport result:", response.data);
            this.setState({
                progress: null, 
                dialog: <ErrorReportDialog appContext={this.props.appContext} errors={response.data.errors} testCreator={testCreator} 
                    onCancel={() => this.setState({dialog: null})}
                    onDone={(testCreator) => {
                        console.log("onDone:", testCreator );
                        this.setState({testCreator, dialog: null})
                    }}
                /> 
            });
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        });
    }

    render() {
        const { testCreator, error, dialog, progress, detectModels, models, tab } = this.state;

        const appBar = this.props.onClose ?
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => this.props.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">Test Creator</Typography>
                </Toolbar>
            </AppBar> : null;

        const detectItems = [];
        detectModels.forEach((model) => {
            if (model.category === testCreator.category) {
                detectItems.push(<MenuItem key={model.modelId} value={model.modelId}>{model.modelId}</MenuItem>)
            }
        })

        const modelItems = [];
        models.forEach((model) => {
            if (model.category === testCreator.category) {
                modelItems.push(<MenuItem key={model.modelId} value={model.modelId}>{model.modelId}</MenuItem>)
            }
        })



        let tabView = null;
        if ( tab === 'journal' ) {
            const columns = [
                { id: 'questionId', label: 'Question ID', editType: 'text' },
                { id: 'answerId', label: 'Answer ID', editType: 'text' },
                { id: 'answerText', label: 'Answer Text', editType: 'text' },
            ];

            tabView = <EnhancedTable 
                orderBy='questionId'
                rowsPerPage={25}
                columnData={columns}
                data={testCreator.journal}
                onDataChanged={(data) => {
                    testCreator.journal = data;
                    this.setState({testCreator});
                }}
                title='Journal' />;
            
        } else if ( tab === 'history') {
            const columns = [
                { id: 'dataId', label: 'Data ID', editType: 'text' },
                { id: 'data', label: 'Data', editType: 'number-array' },
            ];

            tabView = <EnhancedTable 
                orderBy='dataId'
                rowsPerPage={25}
                columnData={columns}
                data={testCreator.history}
                onDataChanged={(data) => {
                    testCreator.history = data;
                    this.setState({testCreator});
                }}
                title='History' />;
        }

        return (
            <div style={{ width: 1000 }}>
                {appBar}
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <p style={{ margin: 5 }}>Creator ID: {testCreator.creatorId}</p>
                            </td>
                            <td align="right" valign="top">
                                {error}
                                <Tooltip title='Save Creator'><IconButton onClick={this.saveTestCreator.bind(this)}><SaveIcon /></IconButton></Tooltip>
                                <Tooltip title='Delete Creator'><IconButton onClick={this.deleteTestCreator.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                                <Tooltip title='Run Test Creator'><IconButton onClick={this.runTestCreator.bind(this)}><ReportIcon /></IconButton></Tooltip>
                                <Tooltip title='Get Error Report'><IconButton onClick={this.getErrorReport.bind(this)}><GetErrorReportIcon /></IconButton></Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <FormControl style={{ margin: 5, width: 300 }}>
                                    <InputLabel>Category</InputLabel>
                                    <Select value={testCreator.category} onChange={(e) => {
                                        testCreator.category = e.target.value;
                                        this.setState({ testCreator });
                                    }}>
                                        <MenuItem value={'pro'}>PRO</MenuItem>
                                        <MenuItem value={'lab'}>LAB</MenuItem>
                                        <MenuItem value={'triage'}>TRIAGE</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <FormControl style={{ margin: 5, width: 300 }}>
                                    <InputLabel>Detect Model</InputLabel>
                                    <Select value={testCreator.modelId} onChange={(e) => {
                                        const detectModel = detectModels.find((k) => k.modelId === e.target.value);
                                        if ( detectModel ) {
                                            testCreator.modelId = e.target.value;
                                            const model = models.find((k) => k.dataId === detectModel.modelDataId);
                                            console.log("model:", model );
                                            if ( model ) {
                                                testCreator.triggerId = model.modelId;
                                            }
                                        }
                                        this.setState({ testCreator });
                                    }}>
                                        {detectItems}
                                    </Select>
                                </FormControl>
                                <br />
                                <FormControl style={{ margin: 5, width: 300 }}>
                                    <InputLabel>Trigger Model</InputLabel>
                                    <Select value={testCreator.triggerId} onChange={(e) => {
                                        const modelType = models.find((k) => k.modelId === e.target.value);
                                        if ( modelType ) {
                                            testCreator.triggerId = e.target.value;
                                            const detectModel = detectModels.find((k) => k.modelDataId === modelType.dataId )
                                            if (detectModel) {
                                                testCreator.modelId = detectModel.modelId;
                                            }
                                        }
                                        this.setState({ testCreator });
                                    }}>
                                        {detectItems}
                                    </Select>
                                </FormControl>
                                <br />
                                <TextField style={styles.text} value={testCreator.jiraTicket} label="JIRA Ticket"
                                    onChange={(e) => { testCreator.jiraTicket = e.target.value; this.setState({ testCreator }); }} />
                                <br />
                                <TextField style={styles.text} value={testCreator.limit} label="Limit" type='number'
                                    onChange={(e) => { testCreator.limit = e.target.value; this.setState({ testCreator }); }} />
                                <br />
                                <Tabs style={{ width: '100%' }} value={tab}
                                    variant='scrollable' scrollButtons='on'
                                    indicatorColor='primary' textColor='primary'
                                    onChange={(e,tab) => this.setState({tab})}
                                >
                                    <Tab label='Journal' value='journal' />
                                    <Tab label='History' value='history' />
                                </Tabs>
                                {tabView}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {progress}
                {dialog}
            </div>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 300
    },
    table: {
        margin: 5,
        width: '100%'
    },
    button: {
        margin: 5
    }
}

export default TestCreatorView;
