import { red } from "@material-ui/core/colors"
const styles = ({ palette, mixins, shadows }) => ({
    root: {
        backgroundColor: palette.background.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginRight: '20px',
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

    },
    actions: {
        backgroundColor: palette.background.paper,
    },
    box: {
        display: 'flex',
        gap: '1rem',
        overflowX: 'hidden',
        boxShadow: shadows[2],
        border: '1px solid',
        borderColor: palette.divider,
        padding: '1rem',
        minHeight: '80vh',
        backgroundColor: palette?.background?.paper,
        borderRadius: mixins?.borderRadius,
        flex: 1,
        width: '100%',
        justifyContent: "start",
        flexDirection: "column",
    },
    tab: {
        "& .MuiTab-wrapper": {
            color: "red",
        },
    },
    row: {
        display: "flex",
        width: '100%',
    },
    search: {
        width: '100%',
    },
    refresh: {
        textAlign: "right",
    },
    jsonPaper: {
        width: 700,
    },
    limit: {
        margin: '0.5rem',
        width: '6rem'
    },
    tag: {
        margin: '0.5rem',
        width: '10rem'
    },
    text: {
        margin: '0.5rem',
        width: '20rem'
    },
    table: {
        width: '100%',

    },
    warningSign: {
        color: 'red',
        width: '20px',
        height: '20px'
    },
    warning: {
        color: red["A700"],
    },
})

export default styles
