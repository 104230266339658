import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Paper, IconButton } from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

class JournalView extends Component {
    componentWillMount() {
        if (this.props.journal)
            this.loadjournal(this.props.journal);
    }
    componentWillReceiveProps(props) {
        if (this.props.journal !== props.journal)
            this.loadjournal(props.journal);
    }

    closejournal() {
        this.props.onClose();
    }

    loadjournal(journal) {
        this.setState({
            journal: journal,
            progress: null,
        });
    }

    render() {
        const self = this;
        const { journal, progress } = self.state;
        return (
            <div align="left" style={styles.div}>
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closejournal()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="title" color="inherit">journal Details</Typography>
                    </Toolbar>
                </AppBar>
                {progress}
                <Paper key="2" style={styles.jsonPaper}>
                    <ReactJson src={journal}
                        collapsed={3} name="journal" collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>

            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 0
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }
}

export default JournalView;
