import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    IconButton,
    Tooltip,
} from '@material-ui/core/';

import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import Moment from 'moment';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PatientLabData from './PatientLabData';

import {
    getPatient,
    getPatientData,
    savePatientData,
    runPatientDetect
} from '@apricityhealth/web-common-lib/utils/Services';

export class PatientsLabData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: "",
            content: [],
            dialog: null,
            progress: null,
            error: null,
            startTime: Moment().subtract(30, 'day').toDate(),
            endTime: Moment().endOf('day').toDate()
        }
        this.dataColumns = [
            { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Event Time' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
            { id: 'groupId', numeric: false, disabledPadding: false, label: 'Group ID' },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v, r) => { return v ? v.join(',') : '' } }
        ];
    }

    getPatientData() {
        const self = this;
        const { appContext } = self.props;
        const { patientId, startTime, endTime } = self.state;

        self.setState({ progress: <CircularProgress size={20} />, error: null });
        if (patientId) {
            getPatient(appContext, patientId).then((patient) => {
                self.setState({ patient });
                let args = []
                args.push("startTime=" + startTime);
                args.push("endTime=" + endTime);
                args.push("category=labTest")
                getPatientData(appContext, patientId, args).then((data) => {
                    self.setState({ content:data.data, progress: null })
                }).catch((error) => {
                    self.setState({ progress: null, error: error.message });
                });
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    addData(data) {
        const self = this
        const { patientId } = self.state;
        const { appContext } = self.props;
        if ( data.length > 0 ) {
            let eventTime = data[0].eventTime;
            this.setState({ progress: <CircularProgress size={20} />, dialog: null, error: null });
            savePatientData(appContext, patientId, data).then(() => {
                self.getPatientData()
                runPatientDetect(appContext, patientId, { lab: true, eventTime, source: [{ origin: 'admin-web-client' }] }).then(() => {
                }).catch((error) => {
                    self.setState({ progress: null, error: getErrorMessage(error) });
                });

            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            })
        }
    }

    startPatientDetect() {
        const self = this
        const { patientId } = self.state;
        const { appContext } = self.props;
        this.setState({ progress: <CircularProgress size={20} />, dialog: null, error: null });
        runPatientDetect(appContext, patientId, { lab: true, source: [{ origin: 'admin-web-client' }] }).then(() => {
            self.getPatientData()
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });

    }

    handleOnAdd() {
        const self = this
        let { appContext } = this.props;
        let { patient } = this.state
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <PatientLabData
                appContext={appContext}
                patient={patient}
                addData={(data) => self.addData(data)}
                onClose={() => self.onClose()}
            />
        </Drawer>;
        this.setState({ dialog: dialog });
    }

    onClose() {
        this.setState({ dialog: null });
    }

    render() {
        const self = this;
        let { content, patientId, startTime, dialog, endTime, progress, error } = self.state;
        const { appContext } = self.props;
        let addButton = () => {
            let disabled = false
            if (!patientId || progress !== null) {
                disabled = true
            }
            return (
                <Tooltip title="Add Lab Data">
                    <IconButton
                        id="addLabData"
                        disabled={disabled}
                        onClick={(e) => { this.handleOnAdd(); }}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )
        }
        let runPatientDetectButton = () => {
            let disabled = false
            if (!patientId || progress !== null) {
                disabled = true
            }
            return (
                <Tooltip title="Run Patient Detect">
                    <IconButton
                        id="runPatientDetect"
                        disabled={disabled}
                        onClick={(e) => { this.startPatientDetect(); }}>
                        <TrackChangesIcon />
                    </IconButton>
                </Tooltip>
            )

        }
        let table = <EnhancedTable
            rowIdPrefix="patientLab"
            onTable={(table) => { self._types = table }}
            disableMultiSelect={true}
            disableSelect={true}
            order='desc'
            orderBy='eventTime'
            columnData={this.dataColumns}
            data={content}
            disableActions={true}
            rowsPerPage={25}
            title='Lab Data'
        />
        let patientSelect = <SelectPatient style={styles.select}
            appContext={appContext}
            patientId={patientId}
            onChange={(patientId) => self.setState({ patientId }, self.getPatientData.bind(self))} />
        let dateRange = <DateRange
            appContext={appContext}
            format={"MM/dd/yyyy"}
            startTime={startTime}
            endTime={endTime}
            endTimeChange={(endTime) => { self.setState({ endTime }) }}
            startTimeChange={(startTime) => { self.setState({ startTime }) }} />

        return <table
            style={{ width: '100%' }}><tbody>
                <tr><td align='left'>
                    {patientSelect}
                    {dateRange}
                </td><td align='right'>
                        {error}
                        <IconButton id="refresh" disabled={progress !== null} onClick={this.getPatientData.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                        {runPatientDetectButton()}
                        {addButton()}
                    </td></tr>
                <tr><td colSpan="2">
                    {table}
                    {dialog}
                </td></tr>
            </tbody>
        </table>;
    }
}
export default PatientsLabData;
const styles = {
    select: {
        margin: 15,
        width: 400
    },
    spacer: {
        flexGrow: 1
    },
    menuBar: {
        alignItems: 'center',
        display: 'flex',
        margin: 15
    },
    main: {
        textAlign: 'left',
        width: '100%',
    },
}
