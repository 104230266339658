import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import _ from 'lodash';

import {
    useState,
    useEffect,
} from 'react'

const log = new Logger();

export async function getMedicationType(appContext, { medicationId = '*', planId = null, dependencies = true, search, limit = 100 }) {
    try {
        const idToken = _.get(appContext, "state.idToken");
        if (!planId) {
            planId = _.get(appContext, 'state.plan.planId');
            if (!planId) {
                throw new Error("No plan selected.");
            }
        }
        let args = [];
        args.push(`dependencies=${dependencies}`)
        args.push(`limit=${limit}`)
        if (search && search !== "") {
            args.push(`search=${encodeURIComponent(search)}`)
        }

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/medications/${medicationId}?${args.join("&")}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        log.debug(`getMedicationType request:`, request);
        let response = await AxiosRequest(request);
        log.debug(`getMedicationType response:`, response.data);
        return response.data;
    } catch (error) {
        log.error(`getMedicationType error:`, getErrorMessage(error));
        throw error;
    }
}

function SelectMedication({ appContext, medicationId = "*", planId, onChange, multiple, style, variant, disabled, dependencies, className, fullMedicationInfo, placeholder, label, disableClearable }) {
    const [items, setItems] = useState([])
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState(null)
    const debouncedSearchTerm = useDebounce(search, 300);

    const loadMedication = async () => {
        try {
            let medications = [];
            let dataStore = _.get(appContext, "stores.DataTypesStore");
            if (dataStore && search !== "") {
                medications = dataStore.getMedications();
            } else {
                medications = await getMedicationType(appContext, { search, medicationId, planId, dependencies })
            }
            let items = []
            for (let i = 0; i < medications.length; ++i) {
                const medication = medications[i];
                items.push({ label: medication.name, medication, value: medication.medicationId });
            }
            let found = items.find((i) => i.value === medicationId);
            setItems(items)
            setSelected(found)
        } catch (error) {
            log.debug("loading medication error:", getErrorMessage(error));
            throw error
        }
    }

    useEffect(() => {
        if (selected?.value === medicationId) return
        let found = items.find((i) => i.value === medicationId);
        if (found) {
            setSelected(found)
        } else {
            loadMedication();
        }
    }, [medicationId])

    useEffect(() => {
        if (debouncedSearchTerm !== "" && debouncedSearchTerm !== selected?.label) {
            let med = items.find(med => med.label === debouncedSearchTerm)
            if (!med)
                loadMedication();
        }
    }, [debouncedSearchTerm])


    return <Autocomplete
        id="medicationSelect"
        multiple={multiple}
        className={className}
        style={style || { margin: 5, width: 300 }}
        options={items}
        blurOnSelect
        selectOnFocus
        placeholder={placeholder || "Select a medication"}
        disabled={disabled}
        getOptionLabel={option => {
            return option?.label || ""
        }}
        disableClearable={disableClearable}
        value={selected}
        getOptionSelected={(event, option) => {
            return event?.value === option?.value
        }}
        onInputChange={(event, value) => {
            setSearch(value)
        }}
        onChange={(event, option) => {
            if (typeof onChange === 'function') {
                if (Boolean(fullMedicationInfo))
                    onChange(option?.medication)
                else
                    onChange(option?.value)
            }
            setSelected(option)
        }}
        renderInput={(params) => <TextField {...params} variant={variant} label={label || "Medication"} />}
    />
}


// Hook
function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}
export default SelectMedication;
