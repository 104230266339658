import {
    useTheme,
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Paper,
    IconButton,
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import styles from './RecentActivityPage.styles'
import ReactJson from 'react-json-view';


function PatientActivity({ classes, alert, journal, lab, note, onClose }) {
    const theme = useTheme();

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" >Details</Typography>
                </Toolbar>
            </AppBar>

            <Paper key="2" className={classes.jsonPaper}>
                <ReactJson
                    theme={theme.overrides?.ReactJsonView}
                    src={{ alert, journal, lab, note }}
                    className={classes.json}
                    collapsed={3}
                    name="alert"
                    collapseStringsAfterLength={64}
                    displayDataTypes={false} />
                {note && note.note && <div className={classes.note}><pre  className={classes.pre}>{note.note}</pre></div>}
            </Paper>
        </>
    )


}
export default withStyles(styles)(PatientActivity)