import React from 'react';
import {
    IconButton,
    Paper,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
} from '@material-ui/core/';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";

import DeleteIcon from '@material-ui/icons/Delete';

import {
    getProviderOrgs,
    addProviderOrg,
    deleteProviderOrg
} from '@apricityhealth/web-common-lib/utils/Services';

import SelectRole from '../../components/SelectRole';

class ProviderOrgsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerId: props.providerId,
            orgs: []
        }
    }

    componentDidMount() {
        if (this.state.providerId) {
            getProviderOrgs(this.props.appContext, this.state.providerId).then((orgs) => {
                this.setState({ orgs });
            }).catch((err) => {
                console.error("getProviderOrgs error:", err);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.providerId !== this.props.providerId) {
            this.setState({ providerId: this.props.providerId }, this.componentDidMount.bind(this));
        }
    }

    onAdd() {
        const { orgs } = this.state;
        orgs.push({ orgId: '', roleType: '' });
        this.setState({ orgs });
    }

    onDelete(i) {
        const { appContext } = this.props;
        const { providerId, orgs } = this.state;
        let deleteOrgId = orgs[i].orgId;
        console.log("onDelete:", deleteOrgId);

        orgs.splice(i, 1);
        this.setState({ orgs });
        deleteProviderOrg(appContext, providerId, deleteOrgId).catch((err) => {
            console.error("onDelete error:", err);
        })
    }

    onChangeOrg(i, orgId) {
        const { appContext } = this.props;
        const { providerId, orgs } = this.state;
        let deleteOrgId = orgs[i].orgId;
        console.log("onChange:", deleteOrgId, "=>", orgId);
        if (deleteOrgId !== orgId) {
            orgs[i].orgId = orgId;
            this.setState({ orgs });

            Promise.all([
                orgId ? addProviderOrg(appContext, providerId, orgId, orgs[i].roleType) : Promise.resolve(),
                deleteOrgId ? deleteProviderOrg(appContext, providerId, deleteOrgId) : Promise.resolve()
            ]).catch((err) => {
                console.error("onChange error:", err);
            });
        }
    }

    onChangeRole(i, roleType) {
        const { appContext } = this.props;
        const { providerId, orgs } = this.state;
        let previousRoleType = orgs[i].roleType;
        console.log("onChangeRole:", previousRoleType, "=>", roleType);
        if (previousRoleType !== roleType) {
            orgs[i].roleType = roleType;
            this.setState({ orgs });

            addProviderOrg(appContext, providerId, orgs[i].orgId, roleType).catch((err) => {
                console.error("onChangeRole error:", err);
            })
        }
    }

    render() {
        const { appContext, providerId } = this.props;
        const { dialog, orgs } = this.state;

        let rows = [];
        for (let i = 0; i < orgs.length; ++i) {
            rows.push(
                <TableRow key={i}>
                    <TableCell>
                        <SelectOrg appContext={appContext} orgId={orgs[i].orgId} onChange={this.onChangeOrg.bind(this, i)} />
                    </TableCell>
                    <TableCell>
                        <SelectRole appContext={appContext} orgId={orgs[i].orgId} value={orgs[i].roleType} onChange={this.onChangeRole.bind(this, i)} />
                    </TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="Delete"
                            onClick={this.onDelete.bind(this, i)} >
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        return (
            <div>
                {dialog}
                <Paper style={styles.sectionPaperContainer}>
                    <EnhancedTableToolbar
                        error={!providerId ? 'No valid provider ID, please save first.' : ''}
                        title='Organizations'
                        onAdd={this.onAdd.bind(this)}
                        numSelected={0}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Organization</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
};

const styles = {
    sectionPaperContainer: {
        margin: 10
    }
}

export default ProviderOrgsView;
