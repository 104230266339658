import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import Moment from 'moment';
import {
    CircularProgress, TextField,
    Drawer, FormControl, InputLabel,
    Paper, MenuItem, Select,
    AppBar,
    Typography,
    Toolbar,
    IconButton,
    Tooltip
} from '@material-ui/core/';
import CreateIcon from '@material-ui/icons/AddCircle';
import NavigationClose from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";
import DateRange from "@apricityhealth/web-common-lib/components/DateRange";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import {
    saveReportJob, getReportJob, isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

const fileDownload = require('js-file-download');
const sanitize = require("sanitize-filename");

function filterContent(filter, report) {
    if (report.reportName && report.reportName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (report.args && report.args.clientReportName && report.args.clientReportName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (report.args && report.args.orgName && report.args.orgName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    return false;
}


class ReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        let { appContext, job } = this.props;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        getReportJob(appContext, job.jobId, { result: true } ).then((result) => {
            let report = result[0];
            this.setState({ progress: null, report });
        }).catch((error) => {
            this.setState({ progress: getErrorMessage(error) })
        });
    }
    onCloseDialog() {
        if (typeof this.props.onClose === 'function')
            if (this.props.onClose());
    }

    downloadCSV() {
        let { report } = this.state;
        let content = report && report.result ? report.result.content : "";
        let reportName = report.args.downloadReportName || report.reportName;
        let orgName = report.args.orgName;
        orgName = orgName ? orgName + "_" : "";
        if (reportName.indexOf(".csv") < 0)
            reportName += ".csv"
        let fileName = sanitize(orgName + reportName);
        fileDownload(content, fileName);
    }

    render() {
        let { report, progress } = this.state;
        return <Drawer variant="persistent" anchor="right" open={true}>
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => this.onCloseDialog()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography style={{ width: 500 }} variant="h6" color="inherit">{report && report.args ? report.args.clientReportName ? report.args.clientReportName : report.reportName : ""}</Typography>
                </Toolbar>
            </AppBar>
            {report && !report.isSeries && report.status === "done" && report.result && report.result.content && <Tooltip title='Download CSV'><IconButton style={{ width: 40 }} onClick={this.downloadCSV.bind(this)}><DownloadIcon /></IconButton></Tooltip>}
            {report && !report.isSeries && report.status === "done" && report.result && (report.result.indexOf('https://') === 0) && <a href={report.result}>{report && report.reportName}</a>}

            <Paper key="2" style={styles.jsonPaper}>
                {progress}
                {!progress && <ReactJson src={report}
                    collapsed={3} name="Employees" collapseStringsAfterLength={64} displayDataTypes={false} />}
            </Paper>
        </Drawer >
    }
}
export class EmployeeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: props.args.orgId,
            startDate: Moment().subtract(1, 'month').toDate(),
            endDate: new Date(),
            planId: props.args.planId,
            dialog: null,
            series: [],
            jobs: [],
            filter: "",
            reportItem: ReportOptions[0].id,
        }
        this.reloadTimer = undefined;
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate() {
        let { orgId, planId } = this.state;
        let args = this.props.args;
        if ((orgId !== args.orgId) || (planId !== args.planId)) {
            this.setState({ orgId: args.orgId, planId: args.planId })
        }
    }

    loadContent() {
        let { appContext } = this.props;
        this.props.parent.setState({ progress: <CircularProgress size={20} />, error: null });
        getReportJob(appContext, '*', { result: false, reportName: [...new Set(ReportOptions.map((o) => o.reportName))].join(",")} ).then((jobs) => {
            this.setState({ jobs });
            this.props.parent.setState({ progress: null, error: null });
        }).catch((error) => {
            console.log(`loadContent `, getErrorMessage(error));
            this.props.parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    createReport() {
        let { reportItem, startDate, endDate } = this.state;
        let { appContext } = this.props;
        let { orgId, planId } = this.props.args;

        let report = ReportOptions.find(report => report.id === reportItem);

        if (report.orgId) {
            report.args.orgId = orgId;
            //report.args.orgName = getOrg(orgId) && getOrg(orgId).name;
        }
        if (report.planId)
            report.args.planId = planId;
        if (report.dateSelect) {
            report.args.startDate = startDate;
            report.args.endDate = endDate;
        }

        let job = {
            reportName: report.reportName,
            recipe: report.recipe ? report.recipe : 'text',
            args: report.args,
            isSeries: report.isSeries
        }

        this.props.parent.setState({ progress: <CircularProgress size={20} />, error: null });
        saveReportJob(appContext, job).then(result => {
            const { jobId } = result;
            this.setState({ jobId }, () => this.loadContent());
        }).catch((error) => {
            console.error("onCreateReportJob error:", getErrorMessage(error));
            this.props.parent.setState({ error: getErrorMessage(error) });
        })

    }


    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._table)
            this._table.setState({ selected: [] });        // unselect the question
    }


    employeeSelected(s, t) {
        let { appContext } = this.props;
        if (s.length > 0) {
            const job = this._table.state.data[s[0]];
            let dialog = < ReportView appContext={appContext} job={job} onClose={() => this.onCloseDialog()} />;
            this.setState({ dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    render() {
        let self = this;
        let { appContext } = this.props;
        let { dialog, reportItem, jobs, filter, startDate, endDate } = this.state;
        let { progress } = this.props.parent.state;

        let report = ReportOptions.find(report => report.id === reportItem)
        let columns = [
            {
                id: 'createdAt', width: 140, editType: 'label', label: 'Create Time', formatValue: (v) => {
                    return Moment(v).format('MM/DD/YYYY h:mm A');
                }
            },
            { id: "status", width: 40, editType: 'label', label: "Status" },
            {
                id: "reportName", width: 220, editType: 'label', label: "Report Name", formatValue: (v, row) => {
                    return row.args ? row.args.clientReportName ? row.args.clientReportName : v : v;
                }
            },
            {
                id: 'args', width: 220, editType: 'label', label: 'Org', formatValue: (v) => {
                    return v.orgId ? <SelectOrg appContext={appContext} orgId={v.orgId} isLabel={true} /> : "*";
                }
            },
            {
                id: 'userId', width: 300, editType: 'label', label: 'User', formatValue: (v) => {
                    return <User appContext={appContext} userId={v} userIdHidden isLabel={true} />;
                }
            },
        ];
        let filtered = [];
        filtered = isArrayValid(jobs) ? jobs.filter((r) => (filterContent(filter, r))) : []

        let dateRange = report.dateSelect && <DateRange key='date'
            appContext={appContext}
            format={"MM/dd/yyyy"}
            startTime={startDate}
            endTime={endDate}
            endTimeChange={(endDate) => { self.setState({ endDate }) }}
            startTimeChange={(startDate) => { self.setState({ startDate }) }} />;
        return (
            <div>
                <table style={{ width: '95%' }}>
                    <tbody>
                        <tr>
                            <td width='800px' align='left' valign='top'>
                                <FormControl style={{ marginLeft: 4,marginRight:4, width: 400 }}>
                                    <InputLabel>'Select a report to generate...'</InputLabel>
                                    <Select
                                        onChange={(e) => { this.setState({ 'reportItem': e.target.value }) }}
                                        value={reportItem || ''}
                                    >
                                        {ReportOptions ? ReportOptions.map(reportOption => {
                                            return < MenuItem key={reportOption.id} value={reportOption.id} >{reportOption.label}</MenuItem>
                                        }) : []}
                                    </Select>
                                </FormControl>
                                {dateRange}
                            </td>

                            <td align='left' valign='top'>
                                <Tooltip title='Create Report'><IconButton disabled={progress !== null} onClick={this.createReport.bind(this)}><CreateIcon /></IconButton></Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <TextField style={{ margin: 5, width: 500 }}
                                    value={filter} label="Filter" onChange={(event) => { self.setState({ filter: event.target.value }) }} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <EnhancedTable disableMultiSelect={true}
                                    disableActions={true}
                                    onTable={table => self._table = table}
                                    onSelected={(s, t) => self.employeeSelected(s, t)}
                                    orderBy='lastName'
                                    order='lastName'
                                    rowsPerPage={25}
                                    columnData={columns}
                                    data={filtered}
                                    title='Employee Reports' />
                            </td>
                        </tr>
                    </tbody>
                </table>;
                {dialog}
            </div >
        );
    }
}



const ReportOptions = [
    {
        "id": "allCheckInData",
        "label": "All Check-In Data (Monthly Historical)",
        "dateSelect": true,
        "planId": true,
        "orgId": true,
        "isSeries": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "includeDemographics": true,
            "downloadReportName": "AllCheckInDataHistorical.csv",
            "clientReportName": "All Check-In Data Historical",
            "location": null
        }
    },
    {
        "id": "covid19TestResults",
        "label": "Covid-19 Test Results (All Historical Results)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "isSeries": true,
        "timeZone": true,
        "reportName": "EmployeeCovidTests",
        "args": {
            "limit": 0,
            "ignoreNoTests": true,
            "ignoreInactive": true,
            "dataIds": "positiveForCovid19,covidTestResult,covidTestDate,covidTestSample,covidTestLab,covidTestPhoto",
            "downloadReportName": "covid19TestResults.json",
            "clientReportName": "Employee Covid Test Results"
        }
    },
    {
        "id": "covid19TestResultsCSV",
        "label": "Covid-19 Test Results (All Historical Results - CSV)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "recipe": "csv",
        "isSeries": false,
        "timeZone": true,
        "reportName": "EmployeeCovidTests",
        "args": {
            "limit": 0,
            "ignoreNoTests": true,
            "ignoreInactive": true,
            "dataIds": "positiveForCovid19,covidTestResult,covidTestDate,covidTestSample,covidTestLab,covidTestPhoto",
            "downloadReportName": "covid19TestResults.csv",
            "clientReportName": "Employee Covid Test Results"
        }
    },
    {
        "id": "covid19Vaccination",
        "label": "Covid-19 Vaccinations Results (All Historical Results)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "isSeries": true,
        "timeZone": true,
        "reportName": "EmployeeCovidVaccines",
        "args": {
            "limit": 0,
            "hasData": true,
            "downloadReportName": "covid19Vaccines.json",
            "clientReportName": "Employee Covid Vaccines"
        }
    },
    {
        "id": "covid19VaccinationCSV",
        "label": "Covid-19 Vaccinations Results (All Historical Results - CSV)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "isSeries": false,
        "timeZone": true,
        "reportName": "EmployeeCovidVaccines",
        "args": {
            "limit": 0,
            "hasData": true,
            "downloadReportName": "covid19Vaccines.csv",
            "clientReportName": "Employee Covid Vaccines"
        }
    },
    {
        "id": "dailyStatusConfirmed",
        "label": "Daily Status (Confirmed - Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "isSeries": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "hasCheckIn": true,
            "days": 1,
            "lastOverallStatus": [
                "confirmed"
            ],
            "downloadReportName": "dailyStatusConfirmed.csv",
            "clientReportName": "Daily Status Confirmed"
        }
    },
    {
        "id": "dailyStatusHigh",
        "label": "Daily Status (High - Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "orgId": true,
        "isSeries": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "days": 1,
            "hasCheckIn": true,
            "lastOverallStatus": [
                "high"
            ],
            "downloadReportName": "dailyStatusHigh.csv",
            "clientReportName": "Daily Status High"
        }
    },
    {
        "id": "dailyStatusIntermediate",
        "label": "Daily Status (Intermediate - Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "isSeries": true,
        "orgId": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "days": 1,
            "hasCheckIn": true,
            "lastOverallStatus": [
                "medium"
            ],
            "downloadReportName": "dailyStatusIntermediate.csv",
            "clientReportName": "Daily Status Intermediate"
        }
    },
    {
        "id": "dailyStatusLow",
        "label": "Daily Status (Low - Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "isSeries": true,
        "orgId": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "days": 1,
            "hasCheckIn": true,
            "lastOverallStatus": [
                "low"
            ],
            "downloadReportName": "dailyStatusLow.csv",
            "clientReportName": "Daily Status Low"
        }
    },
    {
        "id": "dailyStatusCompleted",
        "label": "Daily Status (Completed - Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "isSeries": true,
        "orgId": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "days": 1,
            "lastOverallStatus": [
                "recovered"
            ],
            "downloadReportName": "dailyStatusCompleted.csv",
            "clientReportName": "Daily Status Completed"
        }
    },
    {
        "id": "missedCheckIns",
        "label": "Missed CheckIns (Last 24 Hours)",
        "dateSelect": false,
        "planId": true,
        "isSeries": true,
        "orgId": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "missedCheckIn": true,
            "days": 1,
            "downloadReportName": "missedCheckIn.csv",
            "clientReportName": "Daily Employee Missed Check In"
        }
    },
    {
        "id": "quarantineIsolation",
        "label": "Quarantine/Isolation (Last 24 Hours)",
        "dateSelect": false,
        "isSeries": true,
        "planId": true,
        "orgId": true,
        "reportName": "Employee",
        "args": {
            "limit": 0,
            "hasCheckIn": true,
            "lastOverallStatus": ["quarantine"],
            "days": 1,
            "downloadReportName": "quarantineIsololation.csv",
            "clientReportName": "Daily Employee Quarantine/Isolation"
        }
    },
    {
        "id": "osha",
        "label": "OSHA Vaccination Report (Series)",
        "dateSelect": false,
        "planId": true,
        "recipe": "json",
        "isSeries": true,
        "orgId": true,
        "reportName": "EmployeeOSHA",
        "args": {
            "downloadReportName": "OSHAVaccination",
            "clientReportName": "OSHA Vaccination"
        }
    },
    {
        "id": "oshaCSV",
        "label": "OSHA Vaccination Report (CSV)",
        "dateSelect": false,
        "planId": true,
        "recipe": "csv",
        "isSeries": false,
        "orgId": true,
        "reportName": "EmployeeOSHA",
        "args": {
            "downloadReportName": "OSHAVaccination",
            "clientReportName": "OSHA Vaccination"
        }
    },
]

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: '1000px'
    },
    auditHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    fill: {
        flexGrow: 1
    },
    content: {
        margin: 10
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: "80%",
        borderRadius: 6
    },
    button: {
        margin: 10,
        width: "80%"
    },
    div: {
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5,
        width: 250
    }, number: {
        margin: 5,
        width: 100
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    input: {
        margin: 5,
        width: 200
    }
}


export default EmployeeReport;
