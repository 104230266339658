import React, { Component } from "react";
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser'
import {
    withStyles,
    Button,
} from '@material-ui/core/';

class EditPatientDelegateDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delegate: props.delegate,
            create: props.create
        };
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel()
    }

    onDone(e) {
        const { onDone , onCancel} = this.props;
        const { delegate } = this.state
        if (onDone)
            onDone(delegate.userId);
            onCancel()
    }
    render() {
        let { delegate } = this.state;
        if (delegate.userId === undefined){
            delegate.userId = ''
        }        
        let { appContext } = this.props
        let self = this;
        return (
            <div >
                <SelectUser 
                    style={styles.patientItem}
                    appContext={appContext} 
                    groups={"patients"}
                    userId={delegate}
                    label='Delegate User'
                    onChange={(e) => {delegate.userId = e; self.setState({delegate})}}
                /> 
                <br/>
                <Button variant="contained"  self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Save</Button>
                <Button variant="contained"  self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
            </div>

        )
    }
}

const styles = {
    button:{
        margin:5
    },
    div: {
        margin: 10
    }
}


export default withStyles(styles)(EditPatientDelegateDialog);