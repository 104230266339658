import React, { Component } from 'react';
import Axios from 'axios';
import { Drawer, CircularProgress, TextField } from '@material-ui/core/';
import Config from '@apricityhealth/web-common-lib/Config';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import TrialView from './TrialView';


function testFilter(filter, trial) {
    if (trial) {
        filter = filter.toLowerCase();
        if (trial.name && trial.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (trial.description && trial.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (trial.trialId && trial.trialId.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}
class TrialsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trials: [],
            filter: ''
        }
    }

    componentWillMount() {
        this.loadTrials();
    }
    loadTrials(){
        const self = this;
        const getTrials = {
            url: Config.baseUrl + `${Config.pathPrefix}trials`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getTrials:", getTrials);
        this.setState({ progress: <CircularProgress /> });
        Axios(getTrials).then((response) => {
            console.log("getOrgs response:", response);
            self.setState({ trials: response.data.trials, progress: null }, self.updateFilter.bind(self));
        }).catch((error) => {
            console.log("getOrgs error:", error.response);
            self.setState({ progress: error.message });
        });
    }
    updateFilter() {
        let { trials, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i <  trials.length; ++i) {
                if (testFilter(filter, trials[i]))
                filtered.push(trials[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }
    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadTrials();
        if (this._trials)
            this._trials.setState({ selected: [] });        // unselect ?? 
    }
    createTrial() {
        const { appContext } = this.props;
        let trial = {
            startDate: Date(),
            endDate: null,
            name:'',
            description:''
        }
        var dialog = <div className='drawer' ><Drawer variant="persistent" anchor="right" open={true} >
            <TrialView appContext={appContext} trial={trial} onClose={this.onCloseDialog.bind(this)} />
        </Drawer> </div>;
        this.setState({ progress: null, dialog: dialog });
    }
    trialSelected(s, t) {
        console.log(`trial selected `,  s);
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const { filtered, trials } = this.state;
            const trial = filtered ? filtered[s] : trials[s];
            console.log(`trial selected `,  trial);
            var dialog = <div className='drawer' >
                <Drawer variant="persistent" anchor="right" open={true}>
                    <TrialView appContext={appContext} trial={trial} onClose={this.onCloseDialog.bind(this)} />
                </Drawer>
            </div>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }
    render(){
        const self = this;
        const { trials, filtered, dialog, progress } = this.state;
        let { filter } = this.state;

        const columnData = [
            { id: 'trialId', numeric: false, disabledPadding: false, label: 'Trial ID' },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'startDate', editType:'dateLabel', numeric: false, disabledPadding: false, label: 'Start Date' },
            { id: 'endDate', editType:'dateLabel', numeric: false, disabledPadding: false, label: 'End Date' }
        ];
        return (
             <div style={styles.div}>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                {progress}       
                <EnhancedTable
                disableMultiSelect={true}
                onTable={table => self._trials = table}
                onSelected={(s, t) => self.trialSelected(s, t)}
                onAdd={() => self.createTrial()}
                orderBy='trialId'
                columnData={columnData}
                data={filtered ? filtered : trials}
                title='Trials' />
                {dialog}
            </div>
        )
    }
}

export default TrialsView;
/*
                <EnhancedTable
                    disableMultiSelect={true}
                    onTable={table => self._plans = table}
                    onSelected={(s, t) => self.typeSelected(s, t)}
                    onAdd={() => self.createType()}
                    orderBy='tags'
                    columnData={columnData}
                    data={filtered ? filtered : plans}
                    title='Plans' />
                    */
const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    }
}