import React, { Component } from 'react';
import Axios from 'axios';
import { 
    TextField,
    AppBar, 
    Button,
    Toolbar, 
    CircularProgress,
    Typography, 
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    IconButton 
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import Config from '@apricityhealth/web-common-lib/Config';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import JSONDataDialog from '../dialogs/JSONDataDialog';
import CodeIcon from '@material-ui/icons/Code';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';


class TrialView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog:null,
            progress: null,
            trial:props.trial
        } 
    }

    componentDidUpdate(oldProps) {
        if (this.props.trial !== oldProps.trial)
            this.loadTrial(this.props.trial);
    }
    
    closeTrial() {
        console.log("closeTrial()", this);
        this.props.onClose();
    }

    onChange = type => e => {
        let { trial } = this.state;
        trial[type] = e.target.value;
        this.setState({ trial });
    }
    changeDate(time){
        let { trial } = this.state;
        if (time.endDate){
            trial.endDate = time.endDate
        }
        if (time.startDate){
            trial.startDate = time.startDate
        }
        this.setState({ trial });
    }

    loadTrial(trial) {
        console.log("loadTrial:", trial);
        this.setState({
            trial: trial,
            progress: null,
        });
    }
    deleteTrial() { 
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Trial: {`${self.state.trial.name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDelete() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDelete() {
        this.setState({ dialog: null });
    }

    onShowCode() {
        this.setState({
            dialog: <JSONDataDialog
                appContext={this.props.appContext}
                dataType={this.state.trial}
                onDone={this.cancelDelete.bind(this)} />
        });
    }

    confirmDelete() {
        const self = this;
        const { trial } = self.state;

        self.setState({ dialog: null });
        if (trial.trialId) {
            const deleteTrial = {
                url: Config.baseUrl + `${Config.pathPrefix}trials/${trial.trialId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteTrial:", deleteTrial);
            Axios(deleteTrial).then(function (response) {
                console.log("deleteTrial response:", response);
                self.setState({ progress: null });
                self.closeTrial();
            }).catch(function (error) {
                console.log("deleteTrial error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }
    saveTrial() {
        const self = this;
        let { trial } = self.state;

        const saveTrial = {
            url: Config.baseUrl + `${Config.pathPrefix}trials`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: trial 
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("saveTrial request:", saveTrial);
        Axios(saveTrial).then(function (response) {
            console.log("saveTrial response:", response);
            self.setState({trial: response.data, progress: null });
        }).catch(function (error) {
            console.log("saveTrial error:", error);
            self.setState({ progress: error.message });
        });
    }

    render() {
        const self = this;
        const { dialog, trial, progress } = self.state;
        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.closeTrial()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Trial Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <div style={styles.trialHeader}>
                        <div>Trial ID: {trial.trialId}</div>
                        <div >
                            <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                            <IconButton onClick={(event) => { self.saveTrial(); }}><SaveIcon /></IconButton>
                            <IconButton onClick={(event) => { self.deleteTrial(); }}><DeleteIcon /></IconButton>
                        </div>
                    </div>
                    <br />   <br />
                    <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        width='800' height='50'
                        value={trial.name}
                        onChange={self.onChange("name")}
                    /> <br />   <br />
                    <TextField
                        fullWidth
                        label="Description:"
                        variant="outlined"
                        width='800' height='100'
                        value={trial.description}
                        multiline={true}
                        rows={10}
                        onChange={self.onChange("description")}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            style={styles.picker}
                            openTo="year"
                            label="Start Date"
                            format="MM/dd/yyyy"
                            value={trial.startDate}
                            onChange={(startDate) => { self.changeDate({ startDate }) }}
                        />
                        <DatePicker
                            style={styles.picker}
                            openTo="year"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={trial.endDate}
                            onChange={(endDate) => { self.changeDate({ endDate }) }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                {progress}
                {dialog}
            </div>
        );
    }
}

/*
    trialId: { type: String,  required:true},
    name: { type: String },
    description: { type: String },
    startDate: { type: Date,  required:true},
    endDate: { type: Date,  required:false},
*/

const styles = {
    trialHeader:{
        marginLeft:12,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    picker: {
        margin: 15,
        width: 150
    },
    appBar: {
        backgroundColor: "#FF9800"
    }
}

export default TrialView ;
