import React, { Component } from 'react';
import {
    CircularProgress,
    Tooltip,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    Input,
    InputLabel
} from '@material-ui/core/';

import DownloadIcon from '@material-ui/icons/CloudDownload';
import RefreshIcon from '@material-ui/icons/Refresh';
import Config from '@apricityhealth/web-common-lib/Config'
import Axios from '@apricityhealth/web-common-lib/utils/Axios';

const fileDownload = require('js-file-download');

export default class PatientReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            progress: null,
            level: 1,
            recipe: "pdf"
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const self = this;
        let { patientId } = this.props;
        try {
            if (patientId) {
                let { appContext } = this.props;
                let { recipe, level } = this.state;
                let data = {
                    reportName: "PatientSummary",
                    args: {
                        patientId,
                        level: Number(level)
                    },
                    recipe,
                }

                const request = {
                    url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
                    data,
                    method: 'POST',
                    headers: { "Authorization": appContext.state.idToken }
                };

                self.setState({ progress: <CircularProgress size={20} />, error: null });

                console.debug("getReport:", request);
                Axios(request).then((response) => {
                    console.debug("getReport response:", response);
                    let report = response.data;
                    // if (recipe === 'pdf')
                    // report = Buffer.from(report, 'base64');
                    self.setState({ progress: null, report });
                }).catch((error) => {
                    console.error("getReport error:", error.response);
                    self.setState({ progress: null, error: error.message });
                });

            }
        } catch (error) {
            console.error("Failed to generate test report", error);
            self.setState({ progress: null, error: error.message });

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.patientId !== prevProps.patientId)
            this.loadContent();
    }

    downloadReport() {
        let { report, recipe } = this.state;
        if (recipe === 'pdf')
            report = Buffer.from(report, 'base64');
        fileDownload(report, `PatientSummary.${recipe}`);
    }


    render() {
        let { progress, error, report, recipe, level } = this.state;
        if (recipe === 'pdf')
            report = report ? "data:application/pdf;base64," + report : null;

        return <div style={{ overflow: 'scroll', height: 1000 }}>
            <FormControl key="controltype" style={styles.recipe} >
                <InputLabel>Recipe</InputLabel>
                <Select
                    value={recipe}
                    onChange={(selection) => {
                        recipe = selection.target.value;
                        this.setState({ recipe }, () => this.loadContent())
                    }}
                    input={<Input name="controlType" id="controlType-helper" />}
                >
                    <MenuItem key={'pdf'} value={"pdf"}>PDF</MenuItem>
                    <MenuItem key={'html'} value={"html"}>HTML</MenuItem>
                </Select>
            </FormControl>
            <FormControl key="level" style={styles.recipe} >
                <InputLabel>Level</InputLabel>
                <Select
                    value={level}
                    onChange={(selection) => {
                        level = selection.target.value;
                        this.setState({ level }, () => this.loadContent())
                    }}
                    input={<Input name="controlType" id="controlType-helper" />}
                >
                    <MenuItem key={'1'} value={"1"}>1</MenuItem>
                    <MenuItem key={'2'} value={"2"}>2</MenuItem>
                </Select>
            </FormControl>
            <IconButton onClick={this.loadContent.bind(this)}><RefreshIcon /></IconButton>
            <Tooltip title='Download Patient Report'>
                <IconButton disabled={report == null} id="downloadPatient" onClick={() => { this.downloadReport(); }}>{progress ? progress : <DownloadIcon />}</IconButton>
            </Tooltip>
            <span style={{color: 'red'}}>{error}</span>
            {report && recipe === 'pdf' && <iframe style={styles.report} title="patient-report" src={report}></iframe>}
            {report && recipe === 'html' && <iframe style={styles.report} title="patient-report" srcdoc={report}></iframe>}


        </div>
    }
}

const styles = {
    report: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: '70vh',
        alignContent: 'center',
        alignItems: 'center'
    },
    recipe: {
        margin: 5,
        width: 130
    },
}
