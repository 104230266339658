

import React, { useState } from 'react';
import {
    TextField,
    withStyles,
    FormControl,
    Select,
    InputLabel,
    Input,
    MenuItem,
    CircularProgress,
} from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { claude } from '@apricityhealth/web-common-lib/accessors/ClaudeA';

import styles from './Anthropic.styles'

function isNull(v) {
    return v === null || v === undefined || v === '';
}

const MODEL_IDS = [
    "anthropic.claude-instant-v1",
    "anthropic.claude-v1",
    "anthropic.claude-v2"
]

function Anthropic({ classes, appContext }) {
    const [modelId, setModelId] = useState("anthropic.claude-instant-v1")
    const [prompt, setPrompt] = useState(`Rewrite this in natural language, target audience is 25 year old cancer patient. Do not add a lot of extra words. The text to rewrite it is this: [- Avoid alcohol, smoking, and caffeine
        - Oxygen supplementation if already in use
        - Limit fluid intake
        - Well balanced dietReduce sodium in diet
        - Adequate sleep
        - Oxygen supplementation if already in use
        - Hydrate- Pedialyte/Gatorade/6 tsp sugar, 1/2 tsp salt in 1 Liter of water
        - Avoid alcohol, smoking, and caffeine
        - Limit fluid intake
        - Well balanced diet]?`);
    const [maxTokens, setMaxTokens] = useState(300);
    const [temperature, setTemperature] = useState(0.5);
    const [topP, setTopP] = useState();
    const [topK, setTopK] = useState();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [responseTime, setResponseTime] = useState(0);


    async function sendClaude() {

        try {
            setLoading(true)
            setError(null)
            let input = { modelId, prompt, max_tokens_to_sample: parseInt(maxTokens) };
            if (!isNull(topP))
                input.topP = parseFloat(Number(topP).toFixed(1));
            if (!isNull(temperature))
                input.temperature = parseFloat(Number(temperature).toFixed(1));
            if (!isNull(topK))
                input.topK = parseFloat(Number(topK).toFixed(1));
            let startTime = Date.now();
            let response = await claude(appContext, input);
            let elapsed = Date.now() - startTime;
            setResponse(response?.data);
            setResponseTime(elapsed);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error))
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.prompt}>
                <FormControl style={{ margin: 5 }} className={classes.formControl}>
                    <InputLabel htmlFor="modelId">Model Id</InputLabel>
                    <Select
                        value={modelId}
                        onChange={(e) => setModelId(e.target.value)}
                        input={<Input name="modelId" id="modelId" />}
                    >
                        {MODEL_IDS.map((modelId) => <MenuItem key={modelId} value={modelId}>{modelId}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    disabled={loading}
                    style={{ margin: 5, width: 100 }}
                    label="Max Tokens:"
                    value={maxTokens}
                    onChange={(e) => setMaxTokens(e.target.value)}
                />
                <TextField
                    disabled={loading}
                    style={{ margin: 5, width: 100 }}
                    label="Temperature:"
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                />
                <TextField
                    disabled={loading}
                    style={{ margin: 5, width: 100 }}
                    label="Top P:"
                    value={topP}
                    onChange={(e) => setTopP(e.target.value)}
                />
                <TextField
                    disabled={loading}
                    style={{ margin: 5, width: 100 }}
                    label="Top K:"
                    value={topK}
                    onChange={(e) => setTopK(e.target.value)}
                />
                {error && <><br />
                    <div style={{ color: "red" }}>{error}</div>
                    <br />
                </>}
                <br /> <br />
                <TextField
                    fullWidth
                    disabled={loading}
                    style={{ margin: 5 }}
                    label="Prompt:"
                    variant="outlined"
                    value={prompt}
                    multiline={true}
                    minRows={10}
                    onChange={(e) => setPrompt(e.target.value)}
                />

                {loading ? <CircularProgress size={25} /> : <FontAwesomeIcon style={{ margin: 5 }} icon="fa-regular fa-paper-plane-top" beatFade size='lg'
                    disabled={loading}
                    onClick={(e) => sendClaude()}
                />}
                {response && <><TextField
                    fullWidth
                    style={{ margin: 5 }}
                    label="Response:"
                    variant="outlined"
                    value={response}
                    multiline={true}
                    minRows={10}
                />{responseTime && <><br />Response took: {responseTime / 1000} seconds</>}
                </>}
            </div>
            <br /> <br />
            <div className={classes.links} >
                Note: Claude attribute reference guide (will open a new tab): <a target='_blank' rel='noopener noreferrer' href="https://docs.anthropic.com/claude/reference/complete_post" >Claude Anthropic Reference</a>
            </div>
        </div>
    )

}
export default withStyles(styles)(Anthropic)