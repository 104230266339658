import React from 'react';
import Axios from 'axios';

import {
    CircularProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Tooltip
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';

import { isArrayValid } from '../../utils/Utils';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Config from '@apricityhealth/web-common-lib/Config';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import SelectLocation from '@apricityhealth/web-common-lib/components/SelectLocation';
import { addProviderOrg, deleteProviderOrg, getProvider, saveProvider } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import SelectRole from '../../components/SelectRole';

class EditProviderDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            org: props.org,
            providers: JSON.parse(JSON.stringify(props.providers)),
            progress: null,
            error: null
        }
    }

    onCancel() {
        this.props.onClose();
    }

    onRemove() {
        const { providers, org } = this.state;
        this.setState({progress: <CircularProgress />, error: null });

        let promises = [];
        for(let i=0;i<providers.length;++i) {
            let provider = providers[i];
            promises.push( deleteProviderOrg(this.props.appContext, provider.providerId, org.orgId ) );
        }
        Promise.all(promises).then(() => {
            this.props.onClose(true);
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    onDone() {
        const { appContext } = this.props;
        const { providers, org } = this.state;
        
        this.setState({progress: <CircularProgress />, error: null });

        let promises = [];
        for(let i=0;i<providers.length;++i) {
            let provider = providers[i];

            promises.push( new Promise((resolve, reject) => {
                let promises = [
                    // this will add or update the association of this provider with this org
                    addProviderOrg( appContext, provider.providerId, org.orgId, provider.roleType )
                ];
                if ( this.props.providers[i].providerId && this.props.providers[i].providerId !== provider.providerId ) {
                    // remove the association between the old providerId and this org then..
                    promises.push( deleteProviderOrg( appContext, this.props.providers[i].providerId, org.orgId ));
                }
    
                Promise.all(promises).then(() => {
                    // grab the current provider record so we can update
                    return getProvider( appContext, provider.providerId );
                }).then((result) => {
                    let onCallStatus = result.onCallStatus.filter((e) => e.orgId !== org.orgId);
                    if ( provider.offHours === 0 || provider.offHours === 2) {
                        onCallStatus.push( { orgId: org.orgId, locationId: provider.locationId, offHours: false, status: provider.status })
                    }
                    if ( provider.offHours === 1 || provider.offHours === 2) {
                        onCallStatus.push( { orgId: org.orgId, locationId: provider.locationId, offHours: true, status: provider.status })
                    }
                    result.onCallStatus = onCallStatus;

                    return saveProvider( appContext, result );
                }).then(() => {
                    resolve();
                }).catch((err) => {
                    console.error("onDone error:", err );
                    reject(err);
                })
            }))
        }

        Promise.all(promises).then(() => {
            this.props.onClose(true);
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    render() {
        const { providers, org, progress, error } = this.state;
        console.log("providers:", providers );
        return <Dialog open={true} PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogTitle>Edit Provider</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }}>
                {providers.length === 1 ? <SelectProvider appContext={this.props.appContext} providerId={providers[0].providerId} onChange={(providerId) => {
                    providers[0].providerId = providerId;
                    this.setState({providers});
                }} /> : null }
                <SelectRole appContext={this.props.appContext} orgId={org.orgId} value={providers[0].roleType} onChange={(roleType) => {
                    for(let i=0;i<providers.length;++i) providers[i].roleType = roleType;
                    this.setState({providers});
                }} />
                <SelectLocation appContext={this.props.appContext} orgId={org.orgId} locationId={providers[0].locationId} enableNone={true} onChange={(locationId) => {
                    for(let i=0;i<providers.length;++i) providers[i].locationId = locationId;
                    this.setState({providers});
                }} />
                <br />
                <FormControl style={{margin: 5, width: 150}}>
                    <InputLabel>Priority</InputLabel>
                    <Select label='Priority' value={providers[0].status} onChange={(e) => {
                        for(let i=0;i<providers.length;++i) providers[i].status = e.target.value;
                        this.setState({ providers });
                    }}>
                        <MenuItem value={0}>Off Duty</MenuItem>
                        <MenuItem value={1}>1st</MenuItem>
                        <MenuItem value={2}>2nd</MenuItem>
                        <MenuItem value={3}>3rd</MenuItem>
                        <MenuItem value={4}>4th</MenuItem>
                        <MenuItem value={5}>5th</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <FormControl style={{margin: 5, width: 150}}>
                    <InputLabel>Hours</InputLabel>
                    <Select label='Hours' value={providers[0].offHours} onChange={(e) => {
                        for(let i=0;i<providers.length;++i) providers[i].offHours = e.target.value;
                        this.setState({ providers });
                    }}>
                    <MenuItem value={0}>Office Hours</MenuItem>
                    <MenuItem value={1}>After Hours</MenuItem>
                    <MenuItem value={2}>Office & After Hours</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <span style={{color: 'red'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                {progress}
                <Button variant='contained' onClick={this.onCancel.bind(this)}>Cancel</Button>
                <Button variant='contained' disabled={progress !== null} onClick={this.onRemove.bind(this)}>Remove</Button>
                <Button variant='contained' disabled={progress !== null} onClick={this.onDone.bind(this)}>Done</Button>
            </DialogActions>
        </Dialog>;
    }

};

class ProvidersView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            org: props.org,
            onCallProviders: []
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.org !== this.props.org) {
            this.setState({org: this.props.org}, this.loadContent.bind(this))
        }
    }

    loadContent(){
        const self = this;
        const { org } = this.state;
        if (org.orgId ) {
            const getProviders = {
                url: Config.baseUrl + `${Config.pathPrefix}providers?orgId=${org.orgId}&limit=0&inactive=false`,
                method: 'GET',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            console.log("getProviders:", getProviders);
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Axios(getProviders).then((response) => {
                console.log("getProviders response:", response);
                let providers = response.data.providers;
                let onCallProviders = [];
                for(let i=0;i<providers.length;++i) {
                    let provider = providers[i];
                    let onCallFound = false;
                    if ( isArrayValid(provider.onCallStatus)) {
                        let onCallStatus = provider.onCallStatus.filter((k) => k.orgId === org.orgId);
                        if ( onCallStatus.length > 0 ) {
                            // 0 = onHours, 1 = offHours, 2 = onHours and offHours
                            let offHours = onCallStatus.reduce((p,c) => {
                                if ( c.offHours ) {
                                    p = p < 0 ? 1 : p === 0 ? 2 : p;
                                } else {
                                    p = p < 0 ? 0 : p === 1 ? 2 : p;
                                }
                                return p;
                            }, -1);

                            let status = onCallStatus[0];
                            onCallProviders.push({ providerId: provider.providerId, roleType: provider.roleType, name: `${provider.lastName}, ${provider.firstName}`, 
                                locationId: status.locationId, status: status.status, offHours });
                            onCallFound = true;
                        }
                    }

                    if (! onCallFound ) {
                        onCallProviders.push({ providerId: provider.providerId, roleType: provider.roleType, name: `${provider.lastName}, ${provider.firstName}`, 
                            locationId: '', status: 5, offHours: 0 });
                    }
                }

                self.setState({ onCallProviders, progress: null });
            }).catch((error) => {
                console.log("getProviders error:", error);
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    onCloseDialog(reload) {
        this.setState({dialog: null}, reload ? this.loadContent.bind(this) : undefined);
    }

    onSelected(e, table) {
        if (e.length > 0 ) {
            let providers = [];
            for(let i=0;i<e.length;++i) {
                providers.push( table.state.data[e[i]]);
            }
            console.log("onSelect:", providers );

            this.setState({dialog: <EditProviderDialog appContext={this.props.appContext} org={this.props.org} providers={providers} onClose={this.onCloseDialog.bind(this)} />});
        }
    }

    onAdd(e) {
        console.log("onAdd:", e );
        this.setState({dialog: <EditProviderDialog appContext={this.props.appContext} org={this.props.org} providers={[e]} onClose={this.onCloseDialog.bind(this)} />});
    }

    onDelete(e) {
        console.log("onDelete:", e );
        const { org } = this.state;
        deleteProviderOrg(this.props.appContext, e.providerId, org.orgId ).then(() => {
            this.loadContent();
        });
    }

    render() {
        const self = this;
        let { onCallProviders, progress, error, dialog, org } = this.state;
        if (! Array.isArray(org.locations)) org.locations = [];

        let onCallColumns = [
            { id: 'name', label: 'Provider Name' },
            { id: 'roleType', label: 'Role' },
            { id: 'locationId', label: 'Location', formatValue: (v,r) => {
                let location = org.locations.find((e) => e.locationId === v);
                return location ? location.name : v ? "<INVALID LOCATION" : "<ALL>";
            } },
            { id: 'status', label: 'Priority', formatValue: (v,r) => {
                const LABELS = {
                    0: "Off Duty",
                    1: "1st",
                    2: "2nd",
                    3: "3rd",
                    4: "4th",
                    5: "5th"
                }
                return LABELS[v];
            } },
            { id: 'offHours', label: 'Hours', formatValue: (v,r) => {
                return v === '0' ? "Office" : v === '1' ? "After" : "Office/After";
            }}
        ];

        return <table style={{width: '100%'}}><tbody>
                <tr><td align='left'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this, 0, null)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
                <tr><td colSpan={2}>
                    <EnhancedTable
                        disableMultiSelect={false}
                        rowsPerPage={5}
                        orderBy='name'
                        columnData={onCallColumns}
                        data={onCallProviders}
                        //onSelected={this.onSelected.bind(this)}
                        onActions={(table, numSelected, actions) => {
                            actions.unshift(
                                <Tooltip key='edit' title='Edit'><IconButton 
                                    onClick={() => self.onSelected(table.state.selected, table)}> <EditIcon />
                                </IconButton></Tooltip>
                            );
                            // actions.push(
                            //     <Tooltip key='process' title='Process Attachments'><IconButton
                            //         onClick={this.onProcessAttachments.bind(this)}>
                            //             {importing ? <CircularProgress size={20} /> : <ProcessIcon />}
                            //     </IconButton></Tooltip>
                            // );
                            // actions.push(
                            //     <Tooltip key='import' title='Import Direct Messages'><IconButton
                            //         onClick={this.onImportMessages.bind(this)}>
                            //             {importing ? <CircularProgress size={20} /> : <DMImportIcon />}
                            //     </IconButton></Tooltip>
                            // );
                        }}                        
                        onAdd={this.onAdd.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        title='Providers' />
                    {dialog}
                </td></tr>
        </tbody></table>
    }
}

export default ProvidersView;
