
import React, { useState } from 'react';
import {
    Button,
    FormGroup,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@material-ui/core/';
import {
    getReportSeries
} from '@apricityhealth/web-common-lib/utils/Services'

const fileDownload = require('js-file-download');

const EXPORT_FIELD_LIST = [
    "patientId",
    "userId",
    "emrId",
    "primaryProviderId",
    "primaryOrgId",
    "primaryLocationId",
    "patientIds",
    "title",
    "firstName",
    "middleName",
    "lastName",
    "dob",
    "gender",
    "orgFields",
    "phoneNumbers",
    "emails",
    "plans",
    "trials",
    "deceased",
    "inactive",
    "timezone",
    "language",
    "acceptedTerms",
    "selfRegistered",
    "providerRegistered",
    "providerRegisteredId",
    "referralDate",
    "medicationsLastReviewed",
    "medicationsLastReviewedBy",
    "alertCounts",
    "loginCount"
]
function toProjectionObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i)
        rv[arr[i]] = false;
    return rv;
}


function ExportPatients({ onClose, orgId, planId, appContext }) {
    const [open, setOpen] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [state, setState] = useState(toProjectionObject(EXPORT_FIELD_LIST));

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const onCancel = () => {
        setDownloading(false)
        setOpen(false)
        if (typeof onClose === "function")
            onClose();

    }

    const onDownload = async () => {
        setDownloading(true)
        let project = Object.keys(state).filter(field => state[field] === true);
        project = project.join(",")
        let report = await getReportSeries(appContext, 'PatientDataSelect', { project, planId, orgId }, "csv");
        fileDownload(report.data, `PatientsExport.csv`);
        onCancel();
    }

    return <Dialog open={open} onClose={() => onCancel()}>
        <DialogContent>
            {downloading ? <CircularProgress size={25} /> :
                <FormGroup row>
                    {EXPORT_FIELD_LIST.map(field => <FormControlLabel
                        control={<Checkbox checked={state[field]} onChange={handleChange} name={field} />}
                        label={field}
                    />)}
                </FormGroup>}
        </DialogContent>
        <DialogActions>
            <Button variant="contained" disabled={downloading} onClick={() => onDownload()}>Download</Button>
            <Button variant="contained" disabled={downloading} onClick={() => onCancel()}>Cancel</Button>
        </DialogActions>

    </Dialog>
}

export default ExportPatients