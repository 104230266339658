import React, { Component } from 'react';
import Boost from 'highcharts/modules/boost';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
Boost(Highcharts);

function isFunction(v) {
    return typeof (v) === 'function';
}


export class DateTime extends Component {

    getHeight() {
        if (this.props.height) {
            return this.props.height;
        }
        return null;
    }
    getWidth() {
        if (this.props.width) {
            return this.props.width;
        }
        return null;
    }
    getSeries() {
        if (this.props.series) {
            return this.props.series;
        }
        return [];
    }

    getYAxisTitle() {
        if (this.props.yAxisTitle) {
            return this.props.yAxisTitle;
        }
        return null;
    }
    getXAxisTitle() {
        if (this.props.xAxisTitle) {
            return this.props.xAxisTitle;
        }
        return null;
    }

    getName() {
        if (this.props.name) {
            let name = this.props.name;
            return name;
        }
        return null;
    }

    pointSelected(chart, e) {
        if (this.props.pointSelected) {
            this.props.pointSelected(chart, e);
        }
    }

    pointHover(data) {
        if (isFunction(this.props.pointHover)) {
            this.props.pointHover(data);
        }
    }

    render() {
        let self = this;
        let series = this.getSeries();
        var options = {
            credits: { enabled: false },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false,
            },
            chart: {
                height: self.getHeight(),
                width: self.getWidth(),
                zoomType: 'x'
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: this.getXAxisTitle()
                }

            },
            title: {
                text: this.getName()
            },
            yAxis: {
                title: {
                    text: this.getYAxisTitle()
                },
                min: 0,
            },
            plotOptions: {
                series: {
                    boostThreshold: 500,
                    allowPointSelect: true,
                    point: {
                        events: {
                            mouseOver: function (e) {
                                var chart = this.series.chart;
                                self.pointHover({ chart, e })
                                return true;
                            },

                        }
                    }
                },
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: 1000
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.y}'
            },
            series
        }
        let barChart = <HighchartsReact highcharts={Highcharts} options={options} />
        return (
            <div className="app">
                {barChart}
            </div>
        );
    }
}

export default DateTime;