import React, { Component } from 'react';
import {
    CircularProgress,
    Button, Tooltip, IconButton,
    Paper,
    Typography,
    Tab, Tabs,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core/';
import {
    isArrayValid,
    getReportJob
} from '@apricityhealth/web-common-lib/utils/Services';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import ReactJson from 'react-json-view';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { parse } from 'json2csv';

import '../../styles/table.css';
import _ from 'lodash';

export class EmployerReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            tables: [],
            employerMetrics: null,
            currentTab: 0,
            orgs: []
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.args.jobId !== this.props.args.jobId) {
            this.loadContent();
        }
    }

    componentWillUnmount() {
        if (this.reloadTimer) {
            clearTimeout(this.reloadTimer);
            delete this.reloadTimer;
        }
    }

    loadContent() {
        this.componentWillUnmount();

        const self = this;
        let { jobId } = this.props.args;
        if (jobId) {
            this.props.parent.setState({ progress: <CircularProgress size={20} />, error: null });
            let s3 = false;
            getReportJob(this.props.appContext, jobId, { result: true } ).then((jobs) => {
                if (jobs.length < 1) throw new Error(`job ${jobId} not found!`);
                let job = jobs[0];
                self.setState({ job });
                if (job.status === 'active')
                    self.reloadTimer = setTimeout(self.loadContent.bind(self), 5000);            // try again in 5 seconds
                if (typeof job.result === 'string' && (job.result.indexOf('https://') === 0)) {
                    s3 = true;
                    return fetch(job.result);
                } else {
                    let employerMetrics = _.get(job, 'result.series');
                    return Promise.resolve(employerMetrics);
                }
            }).then((results) => {
                if (s3)
                    return results.json();
                else
                    return Promise.resolve(results)
            }).then((employerMetrics) => {
                self.setState({ employerMetrics });
                self.props.parent.setState({ progress: null });
            }).catch((error) => {
                console.error("get content error:", getErrorMessage(error));
                self.props.parent.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this.rosterTable)
            this.rosterTable.setState({ selected: [] });        // unselect the question
    }

    handleTabChange = (event, currentTab) => {
        this.setState({ currentTab });
    };

    showJSONView(content, name = "") {
        const self = this;
        if (content) {
            self.setState({
                dialog:
                    <Dialog
                        maxWidth={'xl'}
                        fullWidth={true}
                        model="false"
                        open={true}
                        onClose={() => self.onCloseDialog()}
                    >
                        <DialogTitle>{name}</DialogTitle>
                        <DialogContent>
                            <Paper key="2" style={styles.jsonPaper}>
                                <ReactJson src={content}
                                    collapsed={3} name={name} collapseStringsAfterLength={64} displayDataTypes={false} />
                            </Paper>
                        </DialogContent>
                        <DialogActions>
                            <Button style={styles.button} variant="contained" self={self} onClick={(e) => { self.onCloseDialog(); }} >Close</Button>,
                        </DialogActions>
                    </Dialog>
            })
        } else {
            self.setState({ dialog: null });
        }
    }


    getRosterContent() {
        let self = this;
        let { employerMetrics } = this.state;
        console.log(`Roster Content `, employerMetrics);

        let orgs = employerMetrics && Array.isArray(employerMetrics.orgs) ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let statusNames = new Set();
        let data = []
        orgs.forEach(org => {
            try {
                let totals = org.rosterTotals ? JSON.parse(JSON.stringify(org.rosterTotals.status)) : {};
                Object.keys(totals).forEach((status) => {
                    statusNames.add(status);
                });
                totals.name = org.name;
                totals.total = org.rosterTotals ? org.rosterTotals.total : 0;
                totals.qualified = org.patientTotals ? org.patientTotals.qualified : 0;
                totals.registeredPatients = org.patientTotals ? org.patientTotals.registered : 0;
                totals.registeredRoster = org.rosterTotals ? org.rosterTotals.registered : 0;
                data.push(totals)
            } catch (err) {
                console.error("getRosterContent error:", err);
            }
        });


        let names = [];
        statusNames = Array.from(statusNames)
        statusNames.sort((a, b) => {
            if (a < b)
                return -1;
            else if (a > b)
                return 1;
            return 0;
        });

        statusNames.forEach(name => {
            names.push({
                id: name, numeric: true, width: '5px', label: name, editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            })
        });

        let columns = [
            { id: 'name', width: '200px', label: 'Name', editType: 'label' },
            {
                id: 'total', numeric: true, width: '5px', label: 'Total', editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            {
                id: 'registeredPatients', numeric: true, width: '5px', label: 'Registered (data)', editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            }, {
                id: 'registeredRoster', numeric: true, width: '5px', label: 'Registered (roster)', editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            {
                id: 'qualified', numeric: true, width: '5px', label: 'Qualified', editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            ...names,
        ];

        let rosterTable = <EnhancedTable key='rosterTable'
            disableActions={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.state.tables["RosterTable"] = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;


        let headings = [];
        statusNames.forEach(name => {
            headings.push(<th key={name}>{name}</th>)
        });

        let values = [];
        let rosterTotals = employerTotals ? employerTotals.rosterTotals : null;
        if (rosterTotals)
            statusNames.forEach(name => {
                values.push(<td key={name}>{rosterTotals.status[name]}</td>)
            });

        let totalRegisteredByData = employerTotals.patientTotals ? employerTotals.patientTotals.totalEmployeePatients : 'NA';
        let totalRegisteredByRoster = employerTotals.rosterTotals ? employerTotals.rosterTotals.totalRegisteredByRoster : 'NA';
        let totalQualified = employerTotals.patientTotals ? employerTotals.patientTotals.numQualifiedPatients : 'NA';
        let totalAddedToPlan = employerTotals.patientsAddedToRTW;
        let rosterTotalsTable = <table key='rosterTotalsTable' className="styled_table">
            <tbody>
                <tr>
                    <th>Total Organizations</th>
                    {headings}
                </tr>
                <tr>
                    <td>{orgs && orgs.length}</td>
                    {values}
                </tr>
            </tbody>
        </table>

        let overallTotalsTable = <table key='overallTotalsTable' className="styled_table">
            <tbody>
                <tr>

                    <th>Total Employees</th>
                    <th>Total Registered (via roster)</th>
                    <th>Total Registered (via data)</th>
                    <th>Total Added to RTW Plan</th>
                    <th>Total Qualified</th>
                </tr>
                <tr>

                    <td>{rosterTotals && rosterTotals.total}</td>
                    <td>{totalRegisteredByRoster}</td>
                    <td>{totalRegisteredByData}</td>
                    <td>{totalAddedToPlan}</td>
                    <td>{totalQualified}</td>
                </tr>
            </tbody>
        </table>

        let notes = [];
        let rosterNotes = employerTotals.notes ? employerTotals.notes : [];
        rosterNotes.forEach(note => {
            if (note) {
                notes.push(<Typography><i>{note.note} </i>
                    <SearchIcon
                        style={{ margin: '2px', paddingBottom: '4px', width: '20px', height: '20px' }}
                        onClick={(e) => {
                            self.showJSONView(note.duplicates, "Duplicates")
                        }} ></SearchIcon>
                </Typography>)
            }
        });

        return [rosterTotalsTable, overallTotalsTable, notes, rosterTable];
    }

    exportRosterTable() {
        let { employerMetrics } = this.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let rosterTotals = employerTotals ? employerTotals.rosterTotals : {};
        let patientTotals = employerTotals ? employerTotals.patientTotals : {};

        //the colums in the csv. Since we are adding fields dynamically based on the data we don't want to add the same field twice, but we do want to add ones not yet seen
        let fields = new Set();
        //the data being passed for the csv
        let dataArray = []


        orgs.forEach(org => {
            //add each org roster counts
            let totals = org.rosterTotals ? JSON.parse(JSON.stringify(org.rosterTotals.status)) : {};
            Object.keys(totals).forEach((status) => {
                fields.add(status);
            });
            totals.name = org.name;
            totals.totalRoster = org.rosterTotals ? org.rosterTotals.total : 0;
            totals.qualified = org.patientTotals ? org.patientTotals.qualified : 0;
            totals.registeredViaData = org.patientTotals ? org.patientTotals.registered : 0;
            totals.registeredViaRoster = org.rosterTotals ? org.rosterTotals.registered : 0;

            //add each org alert totals
            let overallAlertCounts = org.patientTotals ? JSON.parse(JSON.stringify(org.patientTotals.overallAlertCounts)) : {};
            Object.keys(overallAlertCounts).forEach((alert) => {
                fields.add(alert);
                totals[alert] = overallAlertCounts[alert] ? overallAlertCounts[alert] : "";
            });

            //add each org vaccine counts
            let vaccineCounts = org.vaccinationCounts ? org.vaccinationCounts : {};
            Object.keys(vaccineCounts).forEach(vaccine => {
                // fields.add(vaccine);
                let counts = vaccineCounts[vaccine];
                Object.keys(counts).forEach(dose => {
                    // console.log(`vaccine dose count`, vaccine, dose, counts[dose])
                    let name = vaccine + ` (${dose})`;
                    fields.add(name);
                    totals[name] = counts[dose]
                });
                // let strung = JSON.stringify(vaccineCounts[vaccine]);
                // totals[vaccine] = strung.substring(1, strung.length - 1)
            });

            //add positive covid tests
            fields.add("positiveForCovid")
            totals["positiveForCovid"] = org.patientTotals ? org.patientTotals.positiveForCovid : 'N/A';

            //add total events
            fields.add("totalEvents")
            totals["totalEvents"] = org.patientTotals ? org.patientTotals.totalEvents : 'N/A';



            dataArray.push(totals)
        });

        fields = Array.from(fields);



        //add totals across all orgs. 
        let totals = {}
        totals.name = `Num Orgs: ${orgs.length}`;
        totals.totalRoster = employerTotals && employerTotals.rosterTotals.total;
        totals.qualified = employerTotals.patientTotals ? employerTotals.patientTotals.numQualifiedPatients : 0;
        totals.registeredViaRoster = rosterTotals.totalRegisteredByRoster;
        totals.registeredViaData = patientTotals.totalEmployeePatients;

        //add alert totals across all orgs
        let overallAlertCounts = employerTotals.patientTotals ? JSON.parse(JSON.stringify(employerTotals.patientTotals.overallAlertCounts)) : {};
        Object.keys(overallAlertCounts).forEach((alert) => {
            console.log(`alert `, alert, overallAlertCounts[alert])
            totals[alert] = overallAlertCounts[alert] ? overallAlertCounts[alert] : "";
        });

        //add employee status across all orgs
        let statusCounts = employerTotals.rosterTotals ? JSON.parse(JSON.stringify(employerTotals.rosterTotals.status)) : {};
        Object.keys(statusCounts).forEach(field => {
            totals[field] = (employerTotals && employerTotals.rosterTotals.status[field]);
        });


        //add total vaccine counts
        let vaccineCounts = employerTotals.vaccineCounts ? employerTotals.vaccineCounts : {};
        Object.keys(vaccineCounts).forEach(vaccine => {
            let strung = JSON.stringify(vaccineCounts[vaccine]);
            totals[vaccine] = strung.substring(1, strung.length - 1)
        });

        //add positive covid tests
        totals["positiveForCovid"] = employerTotals.patientTotals ? employerTotals.patientTotals.totalPositiveCovidTests : 'N/A';

        //add total events
        totals["totalEvents"] = employerTotals.patientTotals ? employerTotals.patientTotals.totalEvents : 'N/A';

        //this unshifting is to get the overall totals at the 'top' of the csv.. a little trick
        dataArray.unshift({ "name": "" });
        dataArray.unshift(totals);
        fields.unshift("registeredViaRoster")
        fields.unshift("registeredViaData")
        fields.unshift("qualified")
        fields.unshift("totalRoster")
        fields.unshift("name");

        // remove zeros so it looks better
        dataArray.forEach(sub => {
            Object.keys(sub).forEach(field => {
                if (sub[field] === 0) sub[field] = "";
            });

        });
        var result = parse(dataArray, { fields });
        const fileDownload = require('js-file-download');
        fileDownload(result, `OrgRosterTotals.csv`);
        this.setState({ progress: null });
    }

    getIraeOverallTotals() {
        let self = this;
        let { employerMetrics } = this.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let statusNames = new Set();
        let data = []
        orgs.forEach(org => {
            let totals = org.patientTotals ? JSON.parse(JSON.stringify(org.patientTotals.overallAlertCounts)) : {};
            Object.keys(totals).forEach((status) => {
                statusNames.add(status);
            });
            totals.name = org.name;
            data.push(totals)
        });

        let names = [];
        statusNames.forEach(name => {
            names.push({
                id: name, numeric: true, width: 10, label: name, editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            })
        });

        let columns = [
            { id: 'name', width: 200, label: 'Name', editType: 'label' },
            ...names,
        ];

        let orgOverallAlertsTable = <EnhancedTable
            disableActions={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.state.tables["OverallAlerts"] = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;

        let headings = [];
        statusNames.forEach(name => {
            headings.push(<th key={name}>{name}</th>)
        });

        let values = [];
        let alertCounts = (employerTotals && employerTotals.patientTotals) ? employerTotals.patientTotals.overallAlertCounts : null;
        if (alertCounts)
            statusNames.forEach(name => {
                values.push(<td key={name}>{alertCounts[name]}</td>)
            });

        let totalsTable = <table key='overallTotalsTable' className="styled_table">
            <tbody>
                <tr>
                    <th>Total Organizations</th>
                    {headings}
                </tr>
                <tr>
                    <td>{orgs && orgs.length}</td>
                    {values}
                </tr>
            </tbody>
        </table>
        return [totalsTable, orgOverallAlertsTable];
    }

    getCovidAlertTables() {
        let self = this;
        let { employerMetrics } = this.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let statusNames = new Set();
        let data = []
        orgs.forEach(org => {
            let totals = org.patientTotals ? JSON.parse(JSON.stringify(org.patientTotals.covidAlertCounts)) : {};
            Object.keys(totals).forEach((status) => {
                statusNames.add(status);
            });
            totals.name = org.name;
            data.push(totals)
        });

        let names = [];
        statusNames.forEach(name => {
            names.push({
                id: name, numeric: true, width: 10, label: name, editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            })
        });

        let columns = [
            { id: 'name', width: 200, label: 'Name', editType: 'label' },
            ...names,
        ];

        let orgCovidAlertsTable = <EnhancedTable
            disableActions={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.state.tables["OverallAlerts"] = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;


        let headings = [];
        statusNames.forEach(name => {
            headings.push(<th key={name}>{name}</th>)
        });

        let values = [];
        let alertCounts = (employerTotals && employerTotals.patientTotals) ? employerTotals.patientTotals.covidAlertCounts : null;
        if (alertCounts)
            statusNames.forEach(name => {
                values.push(<td key={name}>{alertCounts[name]}</td>)
            });

        let totalsTable = <table key='overallTotalsTable' className="styled_table">
            <tbody>
                <tr>
                    <th>Total Organizations</th>
                    {headings}
                </tr>
                <tr>
                    <td>{orgs && orgs.length}</td>
                    {values}
                </tr>
            </tbody>
        </table>
        return [totalsTable, orgCovidAlertsTable];
    }


    getVaccineTables() {
        let self = this;
        let { employerMetrics } = this.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let data = []
        let vaccineNames = new Set();
        orgs.forEach(org => {
            let totals = { name: org.name };
            let vaccinationCounts = org.vaccinationCounts ? JSON.parse(JSON.stringify(org.vaccinationCounts)) : {};
            Object.keys(vaccinationCounts).forEach((vaccine) => {
                let counts = vaccinationCounts[vaccine];
                Object.keys(counts).forEach(dose => {
                    let nameDose = vaccine + ` (${dose})`;
                    vaccineNames.add(nameDose);
                    totals[nameDose] = counts[dose]
                });
            });
            data.push(totals);
        });

        vaccineNames = Array.from(vaccineNames);
        vaccineNames.sort((a, b) => {
            if (a < b)
                return -1;
            else if (a > b)
                return 1;
            return 0;
        })
        let names = [];
        vaccineNames.forEach(name => {
            names.push({ id: name, width: 10, label: name, editType: 'label' })
        });

        let columns = [
            { id: 'name', width: 200, label: 'Name', editType: 'label' },
            ...names,
        ];

        let orgVaccineTable = <EnhancedTable
            disableActions={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.state.tables["OverallAlerts"] = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;


        let headings = [];
        vaccineNames.forEach(name => {
            headings.push(<th key={name}>{name}</th>)
        });

        let values = [];
        let vaccineCounts = employerTotals ? employerTotals.vaccineCounts : null;


        if (vaccineCounts) {
            Object.keys(vaccineCounts).forEach(vaccine => {
                let counts = vaccineCounts[vaccine];
                Object.keys(counts).forEach(dose => {
                    let name = vaccine + ` (${dose})`;
                    values.push(<td key={name}>{counts[dose]}</td>)
                });
            });
        }

        let totalsTable = <table key='overallTotalsTable' className="styled_table">
            <tbody>
                <tr>
                    <th>Total Organizations</th>
                    {headings}
                </tr>
                <tr>
                    <td>{orgs && orgs.length}</td>
                    {values}
                </tr>
            </tbody>
        </table>

        let note = <Typography><i>These are the vaccine status, not the dose. These are metrics of how many patients have completed up to a certainl level of vaccine.</i></Typography>
        return [totalsTable, note, orgVaccineTable];
    }

    getExposureAlertTables() {
        let self = this;
        let { employerMetrics } = this.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let employerTotals = employerMetrics ? employerMetrics.employerTotals : {};
        let statusNames = new Set();
        let data = []
        orgs.forEach(org => {
            let totals = org.patientTotals ? JSON.parse(JSON.stringify(org.patientTotals.exposureAlertCounts)) : {};
            Object.keys(totals).forEach((status) => {
                statusNames.add(status);
            });
            totals.name = org.name;
            data.push(totals)
        });

        let names = [];
        statusNames.forEach(name => {
            names.push({
                id: name, numeric: true, width: 10, label: name, editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            })
        });

        let columns = [
            { id: 'name', width: 200, label: 'Name', editType: 'label' },
            ...names,
        ];

        let orgExposureAlertsTable = <EnhancedTable
            key='orgExposureAlertsTable'
            disableActions={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.state.tables["OverallAlerts"] = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;

        let headings = [];
        statusNames.forEach(name => {
            headings.push(<th key={name}>{name}</th>)
        });

        let values = [];
        let alertCounts = employerTotals && employerTotals.patientTotals ? employerTotals.patientTotals.exposureAlertCounts : null;
        if (alertCounts)
            statusNames.forEach(name => {
                values.push(<td key={name}>{alertCounts[name]}</td>)
            });

        let totalsTable = <table key='overallTotalsTable' className="styled_table">
            <tbody>
                <tr>
                    <th>Total Organizations</th>
                    {headings}
                </tr>
                <tr>
                    <td>{orgs && orgs.length}</td>
                    {values}
                </tr>
            </tbody>
        </table>

        return [totalsTable, orgExposureAlertsTable];
    }


    getEventTotalsTable() {
        let self = this;
        let { employerMetrics } = self.state;
        let orgs = employerMetrics ? employerMetrics.orgs : [];
        let columns = [
            { id: "name", width: 150, label: "Name", editType: 'label' },
            {
                id: "totalEvents", numeric: true, width: 150, label: "Events", editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            {
                id: "positiveForCovid", numeric: true,  width: 150, label: "Positive Covid Test Events", editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            {
                id: "covidEventsCount", numeric: true, width: 150, label: "Symptom events", editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            },
            {
                id: "exposureEventsCount", numeric: true, width: 150, label: "Exposure events", editType: 'label', formatValue: (v) => {
                    if (v === "0")
                        return ``;
                    else
                        return v;
                }
            }
        ];


        let data = []
        orgs.forEach(org => {
            let totals = org.patientTotals ? JSON.parse(JSON.stringify(org.patientTotals)) : {};
            totals.name = org.name;
            data.push(totals)
        });

        let orgEventsTable = <EnhancedTable
            disableActions={true}
            disableSelect={true}
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.rosterTable = table}
            rowsPerPage={orgs.length}
            orderBy='name'
            columnData={columns}
            data={data}
            title={`${orgs ? orgs.length : 0} organizations metrics`} />;

        let patientTotals = employerMetrics && employerMetrics.employerTotals ? employerMetrics.employerTotals.patientTotals : {}

        let showMeanTimesButton = <SearchIcon
            style={{ margin: '2px', paddingBottom: '4px', width: '20px', height: '20px' }}
            onClick={(e) => {
                self.setState({
                    dialog:
                        <Dialog
                            model="false"
                            open={true}
                            onClose={() => self.onCloseDialog()}
                        >
                            <DialogTitle>Raw times between exposure and symptoms</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{isArrayValid(patientTotals.daysBetweenActivtyAndSymotom) ? patientTotals.daysBetweenActivtyAndSymotom.join(",") : "None"}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button style={styles.button} variant="contained" self={self} onClick={(e) => { self.onCloseDialog(); }} >Close</Button>,
                            </DialogActions>
                        </Dialog>
                })
            }} >

        </SearchIcon>



        let eventsTable = <div>
            <Typography variant="h6" style={{ width: '400px' }}>Employee event totals<sup>1,2</sup></Typography>
            <table class="styled_table">

                <tbody>
                    <tr>
                        <th>Total employees with 'an' event</th>
                        <th>Total positive covid tests</th>
                        <th>Employees with both Exposure and Symptoms</th>
                        <th>Mean time between exposure and symptom</th>
                        <th>Symptom employees</th>
                        <th>Exposure employees</th>
                        <th>Never develop symptoms after exposure<sup>3</sup></th>
                    </tr>
                    <tr>
                        <td>
                            {patientTotals.numEventPatients}
                        </td>
                        <td>
                            {patientTotals.totalPositiveCovidTests}
                        </td>
                        <td>
                            {patientTotals.numBothExposureAndSymptomPatients}
                        </td>
                        <td>
                            {Number.parseFloat(patientTotals.meanDaysBetweenActivtyAndSymotom).toFixed(2)}{isArrayValid(patientTotals.daysBetweenActivtyAndSymotom) ? showMeanTimesButton : null}
                        </td>
                        <td>
                            {patientTotals.numCovidEventPatients}
                        </td>
                        <td>
                            {patientTotals.numExposureEventPatients}
                        </td>
                        <td>
                            {patientTotals.symptomsNeverShow}
                        </td>
                    </tr>

                </tbody>
            </table>
            <Typography><i>1. Employees can have more than one event. This number may be lower than the total events since total events, in the table below, are not 'employee' counts but rather 'event' counts.</i></Typography>
            <Typography><i>2. An event is defined as any value (exposure or symptom) that is not 'low'.</i></Typography>
            <Typography><i>3. Keep in mind the exposure could have been recent and just not enough calendar time.</i></Typography>
        </div>
        return [eventsTable, orgEventsTable];
    }

    getMiscellaneousContent() {
        let self = this;
        let { job } = self.state;

        let jsonView = <Paper key="2" style={styles.jsonPaper}>
            <ReactJson src={job}
                collapsed={3} name={job && job.reportName} collapseStringsAfterLength={64} displayDataTypes={false} />
        </Paper>

        return [jsonView];
    }

    render() {
        let self = this;
        let { job, progress, error, dialog, currentTab } = this.state;

        if (!job) {
            return <div>Select a report job to view this report!</div>;
        }
        if (!job.result) {
            if (job.status === 'active') {
                return <div>Waiting for report job {job.jobId} {job.startTime ? `to finish, Started at ${job.startTime}` : "to start..."}</div>;
            }
            else {
                return <div style={{ textColor: 'red' }}>Report job has failed: {job.error}</div>;
            }
        }

        let rosterTotals = self.getRosterContent();
        let overallTotals = self.getIraeOverallTotals();
        let covidAlertTotals = self.getCovidAlertTables();
        let vaccineTotals = self.getVaccineTables();
        let exposureTotals = self.getExposureAlertTables();
        let eventsTotals = self.getEventTotalsTable();
        let miscellaneousContent = self.getMiscellaneousContent()


        let tabs =
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={currentTab}
                onChange={this.handleTabChange}>
                <Tab label="Roster Totals" />
                <Tab label="Overall Alerts" />
                <Tab label="Covid Alerts" />
                <Tab label="Vaccines" />
                <Tab label="Exposure Alerts" />
                <Tab label="Events" />
                <Tab label="Miscellaneous" />
                {"hello"}
            </Tabs>

        return (
            <div>
                <span style={{ textColor: 'red' }}>{error}</span>
                <br />
                <Paper outlined style={{ padding: '10px' }}>
                    <table><tbody><tr><td>{tabs}</td><td><Tooltip title='Download CSV'><IconButton onClick={this.exportRosterTable.bind(this)}><DownloadIcon /></IconButton></Tooltip></td></tr></tbody></table>
                    {(currentTab === 0) && <div> <br />{rosterTotals}</div>}
                    {(currentTab === 1) && <div><br />{overallTotals}</div>}
                    {(currentTab === 2) && <div><br />{covidAlertTotals}</div>}
                    {(currentTab === 3) && <div><br />{vaccineTotals}</div>}
                    {(currentTab === 4) && <div><br />{exposureTotals}</div>}
                    {(currentTab === 5) && <div><br />{eventsTotals}</div>}
                    {(currentTab === 6) && <div><br />{miscellaneousContent}</div>}
                    {dialog}
                </Paper>
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: '1000px'
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: "80%",
        borderRadius: 6
    },
    div: {
        textAlign: 'left'
    },
    button: {
        margin: 5
    },

}


export default EmployerReport;
