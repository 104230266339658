import React from 'react';
import Axios from 'axios';

import { 
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel 
} from '@material-ui/core/';

import Config from '@apricityhealth/web-common-lib/Config';

export class SelectRole extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orgId: props.orgId,
            roles: [],
            value: props.value || ''
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.orgId !== this.props.orgId) {
            this.setState({orgId: this.props.orgId}, this.loadContent.bind(this));
        }
        if ( oldProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    loadContent() {
        const self = this;
        const { orgId } = this.state;

        const getOrg = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${orgId}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log("getOrg:", getOrg);
        Axios(getOrg).then((result) => {
            console.log("getOrg response:", result);
            self.setState({ roles: result.data.org.roles });
        }).catch((err) => {
            console.log("getOrg error:", err);
        });
    }

    onChange(e) {
        console.log("SelectRole.onChange:", e );
        this.setState({value: e.target.value});
        if (this.props.onChange)
            this.props.onChange(e.target.value);
    }

    render() {
        let { roles, value } = this.state;

        function MakePretty(s) {
            return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
        }

        let items = [];
        for(let i=0;i<roles.length;++i) {
            items.push(<MenuItem key={i} value={roles[i].roleId}>{MakePretty(roles[i].roleId)}</MenuItem>);
        }

        return <FormControl style={this.props.style || {margin:5, width:300}}>
            <InputLabel>{this.props.label || "Select Role"}</InputLabel>
            <Select value={value} onChange={this.onChange.bind(this)}>
                {items}
            </Select></FormControl>;
    }
}

export default SelectRole;
