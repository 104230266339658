import '../styles/devices.css'
import React, { Component } from 'react';
import {
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    TextField,
    CircularProgress,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Typography
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    saveDevice,
    deleteDevice
} from '@apricityhealth/web-common-lib/utils/Services';

class DeviceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device: {
                name:'',
                model:'',
                serial:'',
                description:'',
                patientId:'',
                orgId:'' 
            } 
        }
        console.log(`DeviceView`);
    }

    componentDidMount(){
        const {device} = this.props
        this.setState({device:device})
    }
    componentDidUpdate(oldProps) {
        if (oldProps.device && oldProps.device._id &&
        this.props.device._id){
            if (oldProps.device._id !== this.props.device._id){
                let device = {
                    name:'',
                    model:'',
                    serial:'',
                    description:'',
                    patientId:'',
                    orgId:'' 
                } 
                const newDevice = { ...device, ...this.props.device}
                this.setState({ device: newDevice });
            }
        }
    }

    onClose() {
        this.props.onClose();
    }

    onChange = type => e => {
        let { device } = this.state;
        device[type] = e.target.value;
        this.setState({ device });
    }

    save() {
        const self = this;
        let { device } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress /> });
        saveDevice(appContext, device).then(function (response) {
            console.log("save device response:", response);
            self.setState({ progress: null });
        }).catch(function (error) {
            console.log("save device error:", error);
            self.setState({ progress: error.message });
        });
    }

    delete() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete device</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancel() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancel() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        let { device } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress /> });
        deleteDevice(appContext, device).then(function (response) {
            console.log("delete device response:", response);
            self.onClose();
        }).catch(function (error) {
            console.log("delete device error:", error);
            self.setState({ progress: error.message });
        });
    }



    render() {
        const self = this;
        let { device, progress, dialog } = self.state;
        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => self.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Device Details</Typography>
                </Toolbar>
            </AppBar> : null;


        return (
            <div>
                {appBar}
                <div style={{"textAlign":"right","margin":"15px"}}>
                    <IconButton onClick={() => { self.save(); }}><SaveIcon /></IconButton>
                    <IconButton onClick={() => { self.delete(); }}><DeleteIcon /></IconButton>
                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Name:"
                        style={{ width: '500px' }}
                        value={device.name}
                        onChange={self.onChange("name")}
                    />
                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Model:"
                        style={{ width: '500px' }}
                        value={device.model}
                        onChange={self.onChange("model")}
                    />

                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Serial:"
                        style={{ width: '500px' }}
                        value={device.serial}
                        onChange={self.onChange("serial")}
                    />

                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Description:"
                        style={{ width: '500px' }}
                        value={device.description}
                        onChange={self.onChange("description")}
                    />

                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Patient Id:"
                        style={{ width: '500px' }}
                        value={device.patientId}
                        onChange={self.onChange("patientId")}
                    />

                </div>
                <div style={{ margin: '10px' }}>
                    <TextField
                        label="Org Id:"
                        style={{ width: '500px' }}
                        value={device.orgId}
                        onChange={self.onChange("orgId")}
                    />

                </div>
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: '1000px'
    },
    button: {
        margin: 5
    },
    td: {
        "textAlign": "right"
    }

}

export default DeviceView;
