import React from 'react';

import {
    Drawer,
    CircularProgress,
    TextField,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import Config from '@apricityhealth/web-common-lib/Config';
import FeedbackItemView from './FeedbackItemView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import Axios from 'axios';

export class FeedbackView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedbackId: props.appContext.state.path.id,
            userId: props.appContext.state.query.userId,
            subject: props.appContext.state.query.subject,
            comment: props.appContext.state.query.comment,
            feedback: [],
            limit: 250,
            patients: [],
            providers: []
        }

        console.log(this.state);
    }

    componentWillMount() {
        this.loadFeedback();
    }

    loadFeedback() {
        const self = this;
        const { userId, feedbackId, subject, comment, limit } = this.state;
        let args = [];
        if (userId)
            args.push("userId=" + encodeURIComponent(userId));
        if (feedbackId)
            args.push("feedbackId=" + encodeURIComponent(feedbackId));
        if (subject)
            args.push("subject=" + encodeURIComponent(subject));
        if (comment)
            args.push("subject=" + encodeURIComponent(comment));
        args.push("limit=" + limit);

        const getFeedback = {
            url: Config.baseUrl + `${Config.pathPrefix}messaging/feedback?${args.join('&')}`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getFeedback:", getFeedback);
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Axios(getFeedback).then(function (response) {
            console.log("getFeedback response:", response);
            self.setState({ feedback: response.data.feedback, progress: null });
        }).catch(function (error) {
            console.log("getFeedback error:", error);
            self.setState({ progress: null, error: error.message });
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadFeedback();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    feedbackSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const feedback = self.state.feedback[s[0]];

            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <FeedbackItemView
                    appContext={appContext}
                    username={feedback.userId}
                    feedback={feedback}
                    onClose={() => self.onCloseDialog()} />
            </Drawer>;
            self.setState({ progress: null, dialog: dialog });
        }
        // Nothing is selected..
        else {
            self.setState({ progress: null, dialog: null });
        }
    }

    render() {
        const self = this;
        const { userId, feedbackId, subject, comment, feedback, dialog, progress, error } = this.state;

        const columnData = [
            { id: 'createDate', editType: 'dateLabel', numeric: false, width: 100, disabledPadding: false, label: 'Date' },
            { id: 'subject', numeric: false, width: 400, disabledPadding: false, label: 'Subject' },
            { id: 'status', numeric: false, width: 100, disabledPadding: false, label: 'Status' },
            {
                id: 'comment', numeric: false, width: 100, disabledPadding: false, label: 'Feedback', formatValue: (v) => {
                    return v.length > 128 ? v.substring(0, 128) + "..." : v;
                }
            }
        ];

        return <table style={{ width: '100%' }}><tbody>
            <tr><td align='left'>
                <TextField style={styles.text} label="User ID" value={userId}
                    onChange={(e) => { self.setState({ userId: e.target.value }, self.loadFeedback.bind(self)) }} />
                <TextField style={styles.text} label="Feedback ID" value={feedbackId}
                    onChange={(e) => { self.setState({ feedbackId: e.target.value }, self.loadFeedback.bind(self)) }} />
                <TextField style={styles.text} label="Subject" value={subject}
                    onChange={(e) => { self.setState({ subject: e.target.value }, self.loadFeedback.bind(self)) }} />
                <TextField style={styles.text} label="Comment" value={comment}
                    onChange={(e) => { self.setState({ comment: e.target.value }, self.loadFeedback.bind(self)) }} />
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadFeedback.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable disableMultiSelect={true}
                    getRowStyle={(row, index) => { return row.level >= 3 ? { 'backgroundColor': '#E57373' } : null }}
                    disableActions={true}
                    onTable={table => self._types = table}
                    onSelected={(s, t) => self.feedbackSelected(s, t)}
                    orderBy='createDate'
                    order='desc'
                    columnData={columnData}
                    data={feedback}
                    rowsPerPage={25}
                    title='Feedbacks' />
                {dialog}

            </td></tr>
        </tbody></table>;
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    text: {
        margin: 5,
        width: 250
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default FeedbackView;
