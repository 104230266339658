import React from 'react';

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    InputAdornment,
    TextField
} from '@material-ui/core';

//eslint-disable-next-line
import { OrgAccessRule } from '@apricityhealth/web-common-lib/utils/Services';


/**
 * @typedef DirectMessageRuleProps
 * @property   {String} [domain] Direct Message Address Domain for any `Apricity Tenant-Organization`
 * @property {Function} onDone   Direct Message Rule Created `ie. onClose`
 * @property {Function} onSave   Direct Message Rule Saved to Organization
 */
/**
 * @extends React.Component<DirectMessageRuleProps,OrgAccessRule>
 */
export class DirectMessageRuleView extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            directMessageAddress: '',
            planIds: [],
            trialIds: []
        };

        if (props.domain && typeof props.domain !== 'string') throw new TypeError(
            '`props.domain` must be a string');
        if (typeof props.onDone !== 'function') throw new TypeError(
            '`props.onDone` must be a function');
        if (typeof props.onSave !== 'function') throw new TypeError(
            '`props.onSave` must be a function');
    }


    /**@param {React.ChangeEvent} e `Event` trigger-source*/
    onDirectAddress(e) {
        const { target: { value: directMessageAddress } } = e;
        if (typeof directMessageAddress !== 'string') throw new TypeError(
            'parameter `e.target.value: directMessageAddress` must be a string');
        this.setState({ directMessageAddress });
    }
    /**@param {React.ChangeEvent} e `Event` trigger-source*/
    onPlanIds(e) {// NOT-IMPLEMENTED
        const { target: { value: planIds } } = e;
        if (typeof planIds !== 'string') throw new TypeError(
            'parameter `e.target.value: planIds` must be a string');
        this.setState({ planIds });
    }
    /**@param {React.ChangeEvent} e `Event` trigger-source*/
    onTrialIds(e) {// NOT-IMPLEMENTED
        const { target: { value: trialIds } } = e;
        if (typeof trialIds !== 'string') throw new TypeError(
            'parameter `e.target.value: trialIds` must be a string');
        this.setState({ trialIds });
    }


    render() {
        const { props, props: { domain = '' } } = this;
        return <Dialog open fullWidth maxWidth='md'>
            <DialogTitle>Direct Message Organization Access Rule</DialogTitle>
            <DialogContent><form autoComplete='on'>
                <TextField fullWidth required id='directMessageAddress' label='Direct Address'
                    InputProps={domain && {
                        endAdornment: <InputAdornment style={{ width: '55%' }} position='end' children={[`@${domain}`]} />// IF `props.domain` exists
                }} onChange={this.onDirectAddress.bind(this)} /><br />
                <TextField fullWidth disabled id='planIds'  label='PlanId Override'  onChange={this.onPlanIds.bind(this)} />
                <TextField fullWidth disabled id='trialIds' label='TrialId Override' onChange={this.onTrialIds.bind(this)} />
            </form></DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onSave(this.state); }}>Save</Button>
                <Button onClick={props.onDone}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
};


export default DirectMessageRuleView;