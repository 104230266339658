import React from 'react';
import '../../styles/org.css'
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    IconButton
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import DeleteIcon from '@material-ui/icons/Delete';


class ContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: props.contact,
            dialog: null
        }
    }
    componentDidUpdate(oldProps){
        const oldContact = JSON.stringify(oldProps.contact)
        const newContact = JSON.stringify(this.props.contact)
        if (oldContact !== newContact){
            this.setState({contact:this.props.contact});
        }
    }

    confirmDeleteContact() {
        this.setState({ dialog: null });
        if (this.props.onDelete) {
            this.props.onDelete(this.state.contact);
        }
    }

    cancel() {
        this.setState({ dialog: null });
    }

    deleteContact() {
        const self = this;
        self.setState({
            dialog:
            <Dialog
                title={`Delete Contact: ${self.state.contact.contactId}, are you sure?`}
                model="false"
                open={true}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Delete Contact: {`${self.state.contact.contactId}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => { self.cancel() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteContact() }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        });
    }
    handleChangeStart(e) {
        console.log(`e.target.value `, e.target.value);
        let { contact } = this.state;
        contact.hours.start = e.target.value*60;
        this.setState({ contact });
    };
    handleChangeEnd(e) {
        console.log(`e.target.value `, e.target.value);
        let { contact } = this.state;
        contact.hours.end = e.target.value*60;
        this.setState({ contact });
    };
    render() {
        let self = this;
        let { contact, dialog } = self.state;

        if (contact) {
            let emailColumns = [
                { id: '_index', width: 50, label: 'Order' },
                { id: 'emailType', width: 50, editType: 'text', label: 'Type' },
                { id: 'email', width: 200, editType: 'text', label: 'Email' },
                { id: 'level', width: 50, editType: 'select', label: 'Level', selectValues: [{ value: '0', label: 'All' },{ value: '1', label: 'Green', style: { color: '#098b34' } }, { value: '2', label: 'Yellow', style: { color: '#FCD12A' } }, { value: '3', label: 'Red', style: { color: '#e4041a' } }] },
                { id: 'schedule', width: 100, editType: 'text', label: 'Schedule (day,hour,min)' },
            ];
            let emailTable = <div className='contact_table'><EnhancedTable
                rowsPerPage={2}
                onDataChanged={(data) => {
                    contact.emails = data;
                    self.setState({ contact });
                }}
                orderBy='_index'
                columnData={emailColumns}
                data={contact.emails}
                title='Emails' /></div>

            var minutes = new Date().getTimezoneOffset();

            var hours = minutes / 60;

            let phoneColumns = [
                { id: '_index', width: 50, padding: '10px', label: 'Order' },
                { id: 'name', width: 150, editType: 'text', label: 'Name' },
                { id: 'numberType', width: 100, editType: 'text', label: 'Type' },
                { id: 'level', width: 50, editType: 'select', label: 'Level', selectValues: [
                    { value: '0', label: 'All' },
                    { value: '1', label: 'Green', style: { color: '#098b34' } }, 
                    { value: '2', label: 'Yellow', style: { color: '#FCD12A' } }, 
                    { value: '3', label: 'Red', style: { color: '#e4041a' } }
                ] },
                { id: 'number', width: 200, editType: 'phone', label: 'Number' },
                { id: 'schedule', width: 100, editType: 'text', label: `Schedule (day,hour,min): +${hours}` },
            ];

            let phoneTable = <div className='contact_table'><EnhancedTable
                rowsPerPage={2}
                onDataChanged={(data) => {
                    contact.phoneNumbers = data;
                    self.setState({ contact });
                }}
                orderBy='_index'
                columnData={phoneColumns}
                data={contact.phoneNumbers}
                title='Phone Numbers' /></div>
            // let timeZone = this.props.org ? this.props.org.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
            // let orgTimeZoneOffset = Math.abs(moment.tz(timeZone).utcOffset() / 60);

            if (!contact.hours)
                contact.hours = {};
            var editcontact = <div className='contact_table' >
                    <div>
                        <div style={styles.contactHeader}>
                            <TextField style={styles.name} label="Name" value={contact.contactId}
                                onChange={(e) => { contact.contactId = e.target.value; self.setState({ contact }); }} />
                            <div align="right">
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(evt) => self.deleteContact()} >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    <div >
                        <div>
                            {emailTable}
                            {phoneTable}
                            {dialog}
                        </div>
                    </div>
            </div>
        }
        return editcontact;
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    contactHeader: {
        marginLeft:15,
        marginRight:15,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'
    },
    contactHours:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'flex-end'
    },
    button: {
        margin: 5,
        width: '200px'
    },
    div: {
        margin: 0
    },
    timezone: {
        margin: 5,
    },
    question: {
        margin: 5,
        width: 400
    },
    name: {
        margin: 5,
        width: 200
    },
    phone: {
        margin: 5,
        width: 200
    },
    table: {
        width: '500px'
    },
    grid: {
        width: '60%',
    },
    td: {
        "textAlign": "right"
    }
}

export default ContactView;
