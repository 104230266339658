import React, { Component } from 'react';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Navigate } from 'react-router-dom';
import Moment from 'moment';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { OwnerInfo } from '@apricityhealth/web-common-lib/components/OwnerInfo'
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import SelectOrg from '@apricityhealth/web-common-lib/components/SelectOrg';
import SelectGender from '@apricityhealth/web-common-lib/components/SelectGender';
import SelectRace from '@apricityhealth/web-common-lib/components/SelectRace';
import SelectEthnicity from '@apricityhealth/web-common-lib/components/SelectEthnicity';
import SelectLocation from '@apricityhealth/web-common-lib/components/SelectLocation';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';

import PatientEmail from './PatientEmail'
import PatientPhone from './PatientPhone'
import PatientPlans from './PatientPlans'
import PatientTrials from './PatientTrials'
import PatientDelegate from './PatientDelegate'
import PatientProvider from './PatientProvider'
import PatientOrgsView from './PatientOrgsView';
import OverrideDialog from './OvrrideDialog';

import Axios from 'axios';
import AddressView from '../OrgsView/AddressView'
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Paper,
    IconButton,
    CircularProgress,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Checkbox,
    Tooltip,
    Tab,
    Tabs
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';
import AssignmentIcon from '@material-ui/icons/Assignment'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import ExportIcon from '@material-ui/icons/CloudDownload';
import ImportEMRIcon from '@material-ui/icons/LocalHospital';
import CopyIcon from '@material-ui/icons/FileCopy';
import UsersIcon from '@material-ui/icons/Person';
import LogsIcon from '@material-ui/icons/DataUsage';

import Config from '@apricityhealth/web-common-lib/Config';
import ReactJson from 'react-json-view';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import HistoricalPatientView from '../HistoricalPatientView/HistoricalPatientView';

import {
    getPatient,
    getPatientData,
    savePatientData,
    isArrayValid,
    deletePatient,
    exportCleanPatient,
    importPatientEMR
} from '@apricityhealth/web-common-lib/utils/Services';

import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';

import SendMessageDialog from '../SendMessageDialog'
import moment from 'moment'
import PatientChart from './PatientChart';

const fileDownload = require('js-file-download');

const DATETIME_FORMAT = 'yyyy-MM-DDTHH:mm';

export class PatientView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'record',
            currentTab: '0',
            dialog: null,
            progress: null,
            export_progress: null,
            error: null,
            showHeader: props.showHeader,
            showHeatmapAndSymptoms: props.showHeatmapAndSymptoms,
            showRecommendations: props.showRecommendations,
            isOverride: false,
            override: {
                value: 'permitted',
                duration: 5,
                reason: ''
            },
            importOptions: {
                fullImport: false,
                importConditions: true,
                importMedications: true,
                importProcedures: true,
                importDocuments: true,
                importReports: true,
                importEncounters: true,
                sinceDate: undefined,
                startDate: undefined,
                endDate: undefined
            },
            redirect: null
        }
    }

    componentWillMount() {
        if (this.props.patientId) {
            this.loadPatient(this.props.patientId);
        } else {
            this.clearPatient();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient || prevProps.patientId !== this.props.patientId) {
            this.componentWillMount();
        }
    }

    clearPatient() {
        //components require default values to not give controlled/uncontrolled errors
        this.setState({
            patient: {
                patientId: null,
                emrId: '',
                userId: '',
                emails: [],
                address: [],
                dob: null,
                gender: '',
                race: '',
                ethnicity: '',
                acceptedTerms: null,
                acceptedTermsBy: '',
                phoneNumbers: [],
                plans: [],
                contactMethod: 'email',
                firstName: '',
                lastName: '',
                middleName: '',
                ownerId: '',
                primaryProviderId: '',
                primaryOrgId: '',
                _version: ''
            }
        });
    }

    closeExport() {
        this.setState({ dialog: null, progress: null });
    }

    closePatientView() {
        this.props.onClose();
    }

    savePatient() {
        let { patient } = this.state;

        try {
            let dob = moment(patient.dob);
            if (dob.isValid()) {
                patient.dob = dob.utcOffset(0).format("YYYY-MM-DD");
            } else {
                patient.dob = '';
            }
            patient.race = patient.race !== 'unspecified' ? patient.race : ''
            patient.ethnicity = patient.ethnicity !== 'unspecified' ? patient.ethnicity : ''

            let savePatient = null;
            if (patient.patientId) {
                savePatient = {
                    url: Config.baseUrl + `${Config.pathPrefix}patients/${patient.patientId}?getOrgs=true`,
                    method: 'PUT',
                    headers: { "Authorization": this.props.appContext.state.idToken },
                    data: patient
                };
            } else {
                savePatient = {
                    url: Config.baseUrl + `${Config.pathPrefix}patients?getOrgs=true`,
                    method: 'POST',
                    headers: { "Authorization": this.props.appContext.state.idToken },
                    data: patient
                };
            }

            this.setState({ progress: <CircularProgress size={20} />, error: null });
            console.log("savePatient request:", savePatient);
            Axios(savePatient).then((response) => {
                if (response.data && response.data.patient)
                    this.setState({ patient: response.data.patient })
                if (this.providersView)
                    this.providersView.getPatientProviders();

                console.log("savePatient response:", response);
                this.setState({ progress: null });
            }).catch((error) => {
                console.log("savePatient error:", error);
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
        catch (err) {
            this.setState({ error: getErrorMessage(err) });
        }
    }

    exportPatient() {
        let { patient } = this.state;
        let { appContext } = this.props;
        let patientId = patient.patientId;
        this.setState({ export_progress: <CircularProgress size={20} />, error: null });
        exportCleanPatient(appContext, patientId).then((newPatientId) => {
            console.log(`clean patient export `, newPatientId)

            this.setState({
                export_progress: null,
                dialog:
                    <Dialog model="true" open={true}>
                        <DialogTitle>Scrubbed Patient Exported</DialogTitle>
                        <DialogContent>
                            <div>Patient has been exported to ID: <b>{newPatientId}</b>
                                <CopyToClipboard text={newPatientId}><Tooltip title='Copy to clipboard'><IconButton><CopyIcon /></IconButton></Tooltip></CopyToClipboard>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" style={styles.button} onClick={() => { this.closeExport() }}>Ok</Button>,
                        </DialogActions>
                    </Dialog>

            });
        }).catch((error) => {
            this.setState({ export_progress: null, error: error.message });
        });
    }

    loadPatient(patientId) {
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        let promises = [];
        promises.push(getPatient(this.props.appContext, patientId, { getOrgs: true } ));
        promises.push(getPatientData(this.props.appContext, patientId, ['dataId=permittedOverride', 'limit=1']));
        Promise.all(promises).then((results) => {
            let { override, isOverride, importOptions } = this.state;
            let patient = results[0];
            let data = results[1].data;

            if (patient.patientId) {
                function checkField(patient, field, v = '') {
                    if (patient[field] === undefined) patient[field] = v;
                }
                checkField(patient, 'emrId');
                checkField(patient, 'userId');
                checkField(patient, 'firstName');
                checkField(patient, 'lastName');
                checkField(patient, 'middleName');

                if (patient.dob) {
                    patient.dob = moment(patient.dob).utcOffset(0).format("YYYY-MM-DD");
                }

                isOverride = false;
                if (data && isArrayValid(data)) {
                    let permitted = data[0];
                    let date = permitted.eventTime;
                    override.value = permitted.data[0] || '';
                    override.duration = permitted.data[1] ? permitted.data[1] : 5;
                    override.message = permitted.data[2] ? permitted.data[2] : '';

                    let sinceOverride = moment().diff(date, 'days');
                    if (override.value)
                        isOverride = sinceOverride <= override.duration;
                }

                this.setState({ patient, importOptions, override, isOverride, progress: null });
            } else {
                //TODO - services resolves failed request -
                //not sure why, but placing errors here
                this.setState({ progress: 'Patient could not be loaded' });
                this.clearPatient()
            }

        }).catch((err) => {
            console.log("getPatient error:", err);
            this.setState({ progress: null, error: err.message });
            this.clearPatient()
        });
    }

    confirmDelete() {
        const { patient } = this.state;
        let name = '';
        if (patient && patient.firstName) {
            name = patient.firstName + " " + patient.lastName;
        } else if (this.props.patient && this.props.patient.patientId) {
            name = this.props.patient.patientId
        }
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Patient: {`${name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { this.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { this.deletePatient() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    deletePatient() {
        const { patient } = this.state;
        this.setState({ dialog: null });
        if (patient.patientId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            deletePatient(this.props.appContext, patient.patientId).then((response) => {
                this.setState({ progress: null });
                this.closePatientView();
            }).catch((error) => {
                this.setState({ progress: null, error: error.message });
            });
        }
    }

    sendMessage() {
        const { patient } = this.state;
        let { appContext } = this.props
        this.setState({
            dialog: <div>
                <SendMessageDialog
                    patient={patient}
                    appContext={appContext}
                    name={patient.firstName + " " + patient.lastName}
                    onCancel={() => this.setState({
                        dialog: null
                    })}
                    onDone={() => this.setState({
                        dialog: null
                    })}

                />
            </div>
        });
    }

    showPatientSummaryOptions() {
        let { showHeatmapAndSymptoms, showRecommendations } = this.state;
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Report Options:</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormControlLabel style={styles.question} control={<Checkbox defaultChecked="true" disabled="true" onChange={(e, v) => {
                                //this.setState( {showHeader: e.target.checked});
                            }} />} label="Show Header?" />
                            <FormControlLabel style={styles.question} control={<Checkbox defaultChecked={showHeatmapAndSymptoms} onChange={(e, v) => {
                                this.setState({ showHeatmapAndSymptoms: e.target.checked });
                            }} />} label="Show Heatmap and Symptoms?" />
                            <FormControlLabel style={styles.question} control={<Checkbox defaultChecked={showRecommendations} onChange={(e, v) => {
                                this.setState({ showRecommendations: e.target.checked });
                            }} />} label="Show Recommendations?" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { this.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { this.getPatientSummary() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    getPatientSummary() {
        this.setState({ dialog: null });
        const { onDone } = this.props;
        let { patient, showHeader, showHeatmapAndSymptoms, showRecommendations } = this.state;
        if (onDone)
            onDone(showHeader, showHeatmapAndSymptoms, showRecommendations);
        try {
            if (patient) {
                let patientId = patient.patientId;
                let { appContext } = this.props;
                const recipe = 'pdf';
                let data = {
                    reportName: "PatientSummary",
                    recipe,
                    args: { patientId, showHeader, showHeatmapAndSymptoms, showRecommendations }
                }

                const request = {
                    url: Config.baseUrl + `${Config.pathPrefix}reporting/report`,
                    data,
                    method: 'POST',
                    headers: { "Authorization": appContext.state.idToken }
                };

                this.setState({ progress: <CircularProgress size={20} />, error: null });

                console.log("getReport:", request);
                Axios(request).then((response) => {
                    console.log("getReport response:", response);
                    let report = response.data;
                    if (recipe === 'pdf')
                        report = Buffer.from(report, 'base64');
                    fileDownload(report, `PatientSummary.pdf`);
                    this.setState({ progress: null, report });
                }).catch((error) => {
                    console.log("getReport error:", error.response);
                    this.setState({ progress: null, error: getErrorMessage(error) });
                });

            }
        } catch (error) {
            console.error("Failed to generate test report", error);
            this.setState({ progress: null, error: getErrorMessage(error) });

        }
        if (onDone)
            onDone(showHeader, showHeatmapAndSymptoms, showRecommendations);
    }

    handleOverride(isOverride) {
        console.log(`handle override`, isOverride)
        if (isOverride)
            this.removeOverride()
        else
            this.askOverride();
    }

    askOverride() {
        let { patient, override } = this.state;
        if (patient && patient.patientId) {
            this.setState({
                dialog: <OverrideDialog override={override} onDone={(override) => {
                    this.saveOverride(override)
                }} onClose={() => {
                    this.onCloseDialog()
                }} />
            });
        }
    }

    saveOverride(override) {
        let { patient } = this.state;
        let { appContext } = this.props;
        if (patient && patient.patientId) {
            this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
            let patientId = patient.patientId;
            let data = {
                dataId: "permittedOverride",
                patientId: patientId,
                data: [
                    override.value, override.duration, override.reason
                ],
                status: 'preliminary',
                baseline: false,
                source: [
                    { origin: 'admin-web-client' }
                ]
            }

            savePatientData(appContext, patientId, data).then(() => {
                this.setState({ override, progress: null, error: null }, this.loadPatient.bind(this, patientId));
            }).catch((error) => {
                console.error("getRoster error:", error.response);
                this.setState({ progress: null, error: getErrorMessage(error) });
            })
        }

    }

    removeOverride() {
        let { patient } = this.state;
        let { appContext } = this.props;
        if (patient && patient.patientId) {
            let patientId = patient.patientId;
            this.setState({ progress: <CircularProgress size={20} />, error: null });

            let override = {
                dataId: "permittedOverride",
                patientId: patientId,
                data: [
                ],
                status: 'preliminary',
                baseline: false,
                source: [
                    { origin: 'admin-web-client' }
                ]
            }

            savePatientData(appContext, patientId, override).then(() => {
                this.setState({ progress: null, error: null }, this.loadPatient.bind(this, patientId));
            }).catch((error) => {
                console.error("getRoster error:", error.response);
                this.setState({ progress: null, error: getErrorMessage(error) });
            })

        } else {
            this.setState({ error: 'no patient on employee' });
        }

    }

    showCode() {
        const { patient } = this.state;
        console.log("showCode:", patient);
        let dialog = <Dialog open={true} maxWidth='md' fullWidth={true} onClose={() => this.setState({ dialog: null })}>
            <DialogContent>
                <ReactJson src={patient}
                    collapsed={3} name="patient" collapseStringsAfterLength={64} displayDataTypes={false} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => this.setState({ dialog: null })}>OK</Button>
            </DialogActions>
        </Dialog>;
        this.setState({ dialog });
    }

    onOpenUser() {
        const { userId } = this.state.patient;
        this.setState({ redirect: <Navigate to={`/users/${userId}`} /> })
        // let dialog = <Drawer variant="persistent" anchor="right" open={true}>
        //     <UserView
        //         appContext={this.props.appContext}
        //         username={userId}
        //         onClose={this.onCloseDialog.bind(this)}
        //     />
        // </Drawer>;
        // this.setState({ dialog });
    }

    onOpenLogs() {
        const { userId } = this.state.patient;
        this.setState({ redirect: <Navigate to={`/logs?userId=${userId}`} /> })
        // let dialog = <Drawer variant="persistent" anchor="right" open={true}>
        //     <LogsView
        //         appContext={this.props.appContext}
        //         userId={userId}
        //         onClose={this.onCloseDialog.bind(this)}
        //     />
        // </Drawer>;
        // this.setState({ dialog });
    }

    importPatientEMR() {
        const { patient, importOptions: {
            importConditions,
            importMedications,
            importProcedures,
            importDocuments,
            importReports,
            importEncounters,
            fullImport,
            sinceDate,
            startDate,
            endDate
        } } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null, dialog: null });
        
        let types = [];
        if ( importConditions ) types.push('Condition');
        if ( importMedications ) types.push('MedicationStatement');
        if ( importProcedures ) types.push('Procedure');
        if ( importDocuments ) types.push('DocumentReference');
        if ( importReports ) types.push('DiagnosticReport');
        if ( importEncounters ) types.push('Encounter');
        types = types.join(',');
        
        importPatientEMR(this.props.appContext, patient.emrId, { fullImport, types, sinceDate, startDate, endDate, async: true } ).then(() => {
            this.setState({ progress: null });
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) });
        })
    }

    onImportPatientEMR() {
        const { patient, importOptions } = this.state;
        const { 
            importConditions,
            importMedications,
            importProcedures,
            importDocuments,
            importReports,
            importEncounters,
            fullImport,
            sinceDate,
            startDate,
            endDate
        } = importOptions;

        const handleImportOption = (property, exclude) => (e,v) => {
            const { importOptions } = this.state;
            importOptions[property] = typeof importOptions[property] === 'boolean' ? v : (v ? Moment().format(DATETIME_FORMAT) : undefined);
            if ( Array.isArray(exclude) && exclude.length > 0 ) {
                for(let i=0;i<exclude.length;++i) {
                    const isBoolean = typeof importOptions[exclude[i]] === 'boolean';
                    importOptions[exclude[i]] = isBoolean ? false : undefined;
                }
            }
            this.setState({ importOptions }, this.onImportPatientEMR.bind(this) )
        };
        if (patient.emrId) {
            const isError = Moment(startDate) > Moment(endDate);
            this.setState({
                dialog: <Dialog open={true}>
                    <DialogTitle>Import Patients EMR Data</DialogTitle>
                    <DialogContent>
                        Please confirm you would like to re-import this patients EMR data?<br /><br />
                        <FormControlLabel label='Full Import' control={<Checkbox checked={fullImport} onChange={handleImportOption('fullImport', ['sinceDate', 'startDate', 'endDate'])} />} /><br />
                        <FormControlLabel label='Since Date' control={<Checkbox checked={sinceDate !== undefined} onChange={handleImportOption('sinceDate', ['fullImport', 'startDate', 'endDate'])} />} /><br />
                        {sinceDate && <div><TextField type='datetime-local' value={Moment(sinceDate).format(DATETIME_FORMAT)} onChange={(e) => {
                            if (moment(e.target.value).isValid()) {
                                importOptions.sinceDate = Moment(e.target.value).format(DATETIME_FORMAT);
                                this.setState({ importOptions }, this.onImportPatientEMR.bind(this));
                            }
                        }} /></div>}
                        <FormControlLabel label='Start Date' control={<Checkbox checked={startDate !== undefined} onChange={handleImportOption('startDate', ['fullImport', 'sinceDate'])} />} /><br />
                        {startDate && <div><TextField type='datetime-local' value={Moment(startDate).format(DATETIME_FORMAT)} onChange={(e) => {
                            if (moment(e.target.value).isValid()) {
                                importOptions.startDate = Moment(e.target.value).format(DATETIME_FORMAT);
                                this.setState({ importOptions }, this.onImportPatientEMR.bind(this));
                            }
                        }} error={isError} helperText={isError ? 'Start date must be before end date.' : ''} /></div>}
                        <FormControlLabel label='End Date' control={<Checkbox checked={endDate !== undefined} onChange={handleImportOption('endDate', ['fullImport', 'sinceDate'])} />} /><br />
                        {endDate && <div><TextField type='datetime-local' value={Moment(endDate).format(DATETIME_FORMAT)} onChange={(e) => {
                            if (moment(e.target.value).isValid()) {
                                importOptions.endDate = Moment(e.target.value).format(DATETIME_FORMAT);
                                this.setState({ importOptions }, this.onImportPatientEMR.bind(this));
                            }
                        }} error={isError} helperText={isError ? 'End date must be after start date.' : ''} /></div>}
                        <h4>Import Types</h4>
                        <div style={{marginLeft: 50}}>
                            <FormControlLabel label='Conditions' control={<Checkbox checked={importConditions} onChange={handleImportOption('importConditions')} />} /><br />
                            <FormControlLabel label='Medications' control={<Checkbox checked={importMedications} onChange={handleImportOption('importMedications')} />} /><br />
                            <FormControlLabel label='Procedures' control={<Checkbox checked={importProcedures} onChange={handleImportOption('importProcedures')} />} /><br />
                            <FormControlLabel label='Documents' control={<Checkbox checked={importDocuments} onChange={handleImportOption('importDocuments')} />} /><br />
                            <FormControlLabel label='Diagnostic Reports' control={<Checkbox checked={importReports} onChange={handleImportOption('importReports')} />} /><br />
                            <FormControlLabel label='Encounter' control={<Checkbox checked={importEncounters} onChange={handleImportOption('importEncounters')} />} /><br />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ dialog: null })}>Cancel</Button>
                        <Button disabled={isError} onClick={this.importPatientEMR.bind(this)}>Import EMR Data</Button>
                    </DialogActions>
                </Dialog>
            });
        }
    }

    render() {
        const { appContext } = this.props;
        const { view, patient, progress, export_progress, error, dialog, isOverride, override, redirect } = this.state;

        if (redirect) {
            return redirect;
        }

        const appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton id="closePatient" onClick={() => this.closePatientView()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Patient {patient ? patient.patientId : ''} </Typography>
            </Toolbar>
        </AppBar> : null;

        let viewContent = null;
        switch (view) {
            case 'record':
                {
                    let { currentTab } = this.state;

                    let importStatus = '';
                    if (patient && patient.importStatus360) {
                        const { lastUpdated, error } = patient.importStatus360;
                        importStatus = `, Last Updated: ${lastUpdated}, Last Error: ${error ? error : 'None'}`;
                    }

                    let headerItems =
                        <table style={styles.patientHeader}><tbody>
                            <tr><td>
                                <table><tbody>
                                    <tr><td>Patient ID:</td><td>{patient ? patient.patientId : ''}</td></tr>
                                    {patient && patient.importId && <tr><td>Import ID:</td><td>{patient.importId}</td></tr>}
                                    <tr><td>Version:</td><td>{patient ? patient._version : ''}</td></tr>
                                    <tr><td>User ID:</td><td>{patient ? patient.userId : ''}</td></tr>
                                    <tr><td>TOS Date:</td><td>{patient && patient.acceptedTerms ? moment(patient.acceptedTerms).format("LLL") : ''}</td></tr>
                                    {override && override.value ? <tr><td>Override:</td><td>{override.value}, Days: {override.duration}, Reason: {override.message || 'NA'}</td></tr> : null}
                                    {patient && patient.emrId ? <tr><td>EMR ID:</td><td>{patient.emrId}, Last Import: {patient.lastImport}</td></tr> : null}
                                    {patient && patient.commonwellId ? <tr><td>CW ID:</td><td>{patient.commonwellId}{importStatus}</td></tr> : null}
                                    {patient && patient.deceased ? <tr style={{ color: 'red' }}><td>Patient Deceased</td></tr> : null}
                                </tbody></table>
                            </td><td align='right' valign='top'>
                                    <span style={{ color: 'red' }}>{error}</span>
                                    <Tooltip title='Save'>
                                        <IconButton id="save" disabled={progress !== null} onClick={this.savePatient.bind(this)}>{progress ? progress : <SaveIcon />}</IconButton>
                                    </Tooltip>
                                    <Tooltip title='Show JSON'>
                                        <IconButton id="showJson" onClick={this.showCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                                    {patient && patient.userId ? <Tooltip title='Open Logs'>
                                        <IconButton id='logs' onClick={this.onOpenLogs.bind(this)}><LogsIcon /></IconButton></Tooltip> : null}
                                    {patient && patient.userId ? <Tooltip title='Open User'>
                                        <IconButton id='user' onClick={this.onOpenUser.bind(this)}><UsersIcon /></IconButton></Tooltip> : null}
                                    <Tooltip title='Import EMR'>
                                        <IconButton id="importEMR" onClick={this.onImportPatientEMR.bind(this)} disabled={!patient || !patient.emrId}>
                                            <ImportEMRIcon /></IconButton></Tooltip>
                                    <Tooltip title='Download Patient Report'>
                                        <IconButton id="downloadPatient" onClick={() => { this.getPatientSummary(); }}><AssignmentIcon /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Send Message'>
                                        <IconButton id="sendMessage" onClick={() => { this.sendMessage(); }}><SendIcon /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Export Scrubbed Patient'>
                                        <IconButton id="exportPatient" disabled={export_progress !== null} onClick={this.exportPatient.bind(this)}>
                                            {export_progress ? export_progress : <ExportIcon />}</IconButton>
                                    </Tooltip>
                                    <Tooltip id="delete" title='Delete'><IconButton onClick={() => { this.confirmDelete(); }}><DeleteIcon /></IconButton></Tooltip>
                                </td></tr>
                        </tbody></table>;

                    let patientItems = null;
                    if (patient) {
                        const trialIds = Array.isArray(patient.orgs) ? patient.orgs.reduce((p,c) => p.concat(c.trialIds), []).map((e) => e.trialId).filter((v,i,a) => a.indexOf(v) === i).join(',') 
                            : [];

                        let tabs = [
                            <div key='Organizations' style={styles.sectionRow}>
                                <PatientOrgsView patientId={patient.patientId} appContext={appContext} />
                            </div>,
                            <div key='Providers' style={styles.sectionRow}>
                                <PatientProvider patientId={patient.patientId} appContext={appContext} ref={view => this.providersView = view} />
                            </div>,
                            <div key='Address' style={styles.sectionRow}>
                                <AddressView address={patient.address} appContext={appContext} />
                            </div>,
                            <div key='Emails' style={styles.sectionRow}>
                                <PatientEmail emails={patient.emails} appContext={appContext} />
                            </div>,
                            <div key='Phone Numbers' style={styles.sectionRow}>
                                <PatientPhone phoneNumbers={patient.phoneNumbers} appContext={appContext} />
                            </div>,
                            <div key='Plans' style={styles.sectionRow}>
                                <PatientPlans plans={patient.plans} appContext={appContext} />
                            </div>,
                            <div key='Trials' style={styles.sectionRow}>
                                <PatientTrials trials={patient.trials} appContext={appContext} 
                                    trialIds={trialIds} />
                            </div>,
                            <div key='Delegates' style={styles.sectionRow}>
                                <PatientDelegate patientId={patient.patientId} appContext={appContext} />
                            </div>,
                            <div key='Patient Ids' style={styles.sectionRow}>
                                <EnhancedTable
                                    orderBy='systemName'
                                    order='desc'
                                    columnData={[
                                        { id: 'systemName', editType: 'text', label: 'System Name', width: 400 },
                                        { id: 'systemId', editType: 'text', label: 'System ID', width: 400 },
                                        { id: 'location', editType: 'text', label: 'Location' }
                                    ]}
                                    data={patient.patientIds}
                                    onDataChanged={(data) => {
                                        patient.patientIds = data;
                                        this.setState({ patient });
                                    }}
                                    title='Patient Ids' />
                            </div>
                        ];

                        let displayTabs = [];
                        for (let i = 0; i < tabs.length; ++i) {
                            const tab = tabs[i];
                            displayTabs.push(<Tab key={i} label={tab.key} style={{ color: 'white' }} value={`${i}`} />);
                        }
                        if (currentTab < 0 || currentTab >= (displayTabs.length)) {
                            currentTab = '0';
                        }
                        if (!patient.providerRegistered) patient.providerRegistered = null;
                        if (!patient.selfRegistered) patient.selfRegistered = null;
                        if (!patient.referralDate) patient.referralDate = null;
                        if (!patient.inactiveDate) patient.inactiveDate = null;
                        if (!patient.medicationsLastReviewed) patient.medicationsLastReviewed = null;
                        if (!patient.acceptedTerms) patient.acceptedTerms = null;

                        patientItems = <div>
                            <div style={styles.sectionRow}>
                                <Paper style={styles.sectionPaperContainer}>
                                    <div style={styles.patientRow}>
                                        <TextField
                                            label="First Name"
                                            style={styles.patientItem}
                                            value={patient.firstName}
                                            onChange={(e) => { patient.firstName = e.target.value; this.setState({ patient }) }}
                                        />
                                        <TextField
                                            label="Middle Name"
                                            style={styles.patientItem}
                                            value={patient.middleName}
                                            onChange={(e) => { patient.middleName = e.target.value; this.setState({ patient }) }}
                                        />
                                        <TextField
                                            label="Last Name"
                                            style={styles.patientItem}
                                            value={patient.lastName}
                                            onChange={(e) => { patient.lastName = e.target.value; this.setState({ patient }) }}
                                        />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <TextField
                                            label="DOB (YYYY-MM-DD)"
                                            style={{ ...styles.patientItem, marginTop: 15, width: 150 }}
                                            value={patient.dob}
                                            onChange={(e) => { patient.dob = e.target.value; this.setState({ patient }) }}
                                        />
                                        <SelectGender style={styles.gender} value={patient.gender} onChange={(e) => {
                                            patient.gender = e; this.setState({ patient })
                                        }} />
                                        <SelectRace style={styles.gender} value={patient.race} onChange={(e) => {
                                            patient.race = e; this.setState({ patient })
                                        }} />
                                        <SelectEthnicity style={styles.gender} value={patient.ethnicity} onChange={(e) => {
                                            patient.ethnicity = e; this.setState({ patient })
                                        }} />
                                        <SelectLanguage style={styles.gender} value={patient.language} onChange={(e) => {
                                            patient.language = e; this.setState({ patient })
                                        }} />
                                        <FormControl style={styles.contactMethod}>
                                            <InputLabel>Contact Method:</InputLabel>
                                            <Select
                                                value={patient.contactMethod || ''}
                                                onChange={(e) => { patient.contactMethod = e.target.value; this.setState({ patient }) }}
                                            >
                                                <MenuItem value={'email'}>Email</MenuItem>
                                                <MenuItem value={'phone'}>Phone</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={styles.patientRow}>
                                        <SelectProvider
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            providerId={patient.primaryProviderId}
                                            enableNone={true}
                                            label='Primary Provider'
                                            onChange={(providerId) => {
                                                patient.primaryProviderId = providerId;
                                                this.setState({ patient })
                                            }}
                                        />
                                        <SelectOrg
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            orgId={patient.primaryOrgId}
                                            label='Primary Org'
                                            onChange={(orgId) => {
                                                patient.primaryOrgId = orgId; this.setState({ patient })
                                            }}
                                        />
                                        <SelectLocation
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            orgId={patient.primaryOrgId}
                                            locationId={patient.primaryLocationId}
                                            label='Primary Location'
                                            enableNone={true}
                                            onChange={(locationId) => {
                                                patient.primaryLocationId = locationId; this.setState({ patient })
                                            }}
                                        />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <TextField
                                            label="User ID"
                                            style={{ ...styles.patientItem, marginTop: 15 }}
                                            value={patient.userId}
                                            onChange={(e) => { patient.userId = e.target.value; this.setState({ patient }) }}
                                        />
                                        <SelectUser
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            groups={"patients"}
                                            userId={patient.userId}
                                            label='User Account'
                                            onChange={(userId) => { patient.userId = userId; this.setState({ patient }) }}
                                        />
                                        <TextField
                                            label="EMR ID"
                                            style={{ ...styles.patientItem, marginTop: 15 }}
                                            value={patient.emrId}
                                            onChange={(e) => { patient.emrId = e.target.value; this.setState({ patient }) }}
                                        />
                                        <TextField
                                            label="Subscription ID"
                                            style={{ ...styles.patientItem, marginTop: 15 }}
                                            value={patient.subscriptionId}
                                            onChange={(e) => { patient.subscriptionId = e.target.value; this.setState({ patient }) }}
                                        />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <MuiPickersUtilsProvider key={"acceptedTerms"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                margin="normal"
                                                format="MMM d, yyyy"
                                                label="Accepted Terms Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.acceptedTerms}
                                                onChange={(time) => {
                                                    patient.acceptedTerms = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <SelectUser
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            userId={patient.acceptedTermsBy}
                                            label='Accepted Terms By'
                                            onChange={(userId) => { patient.acceptedTermsBy = userId; this.setState({ patient }) }}
                                        />
                                        <Tooltip title='Clear Accepted Terms'><IconButton onClick={() => { patient.acceptedTermsBy = null; this.setState({ patient }) }}><DeleteIcon /></IconButton></Tooltip>
                                        <MuiPickersUtilsProvider key={"medsLastReviewed"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="Meds Reviewed"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.medicationsLastReviewed}
                                                onChange={(time) => {
                                                    patient.medicationsLastReviewed = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <SelectUser
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            userId={patient.medicationsLastReviewedBy}
                                            label='Meds Reviewed By'
                                            onChange={(userId) => {
                                                patient.medicationsLastReviewedBy = userId;
                                                this.setState({ patient })
                                            }}
                                        />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <MuiPickersUtilsProvider key={"referralDate"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="Referral Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.referralDate}
                                                onChange={(time) => {
                                                    patient.referralDate = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider key={"selfregistered"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="Self Registered Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.selfRegistered}
                                                onChange={(time) => {
                                                    patient.selfRegistered = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider key={"providerRegistered"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="Provider Reg Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.providerRegistered}
                                                onChange={(time) => {
                                                    patient.providerRegistered = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider key={"InactiveDate"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="InActive Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.inactiveDate}
                                                onChange={(time) => {
                                                    patient.inactiveDate = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <MuiPickersUtilsProvider key={"patientCreated"} utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                clearable
                                                format="MMM d, yyyy"
                                                margin="normal"
                                                label="Patient Created Date"
                                                style={{ width: 200, margin: 5 }}
                                                ampm={true}
                                                value={patient.patientCreated}
                                                onChange={(time) => {
                                                    patient.patientCreated = time;
                                                    this.setState({ patient })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <SelectUser
                                            style={styles.patientItem}
                                            appContext={appContext}
                                            userId={patient.providerRegisteredId}
                                            label='Provider Reg By'
                                            onChange={(userId) => {
                                                patient.providerRegisteredId = userId;
                                                this.setState({ patient })
                                            }}
                                        />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={patient.exportFlag} onChange={(e, v) => {
                                            patient.exportFlag = v; this.setState({ patient });
                                        }} />} label='Export' />

                                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={patient.inactive} onChange={(e, v) => {
                                            patient.inactive = v;
                                            if (patient.inactive === true)
                                                patient.inactiveDate = new Date().toISOString();
                                            else {
                                                delete patient.inactiveDate;
                                            }
                                            this.setState({ patient });
                                        }} />} label='Inactive' />
                                        <FormControlLabel
                                            style={{ margin: 5 }}
                                            control={
                                                <Checkbox
                                                    checked={isOverride}
                                                    disabled={!(patient && patient.patientId)}
                                                    onChange={() => { console.log(`selected`); this.handleOverride(isOverride) }} />
                                            }
                                            label={"Override"} />
                                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={patient.deceased} onChange={(e, v) => {
                                            patient.deceased = v; this.setState({ patient });
                                        }} />} label='Deceased' />
                                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={patient.enableTrialNotification} onChange={(e, v) => {
                                            patient.enableTrialNotification = v; this.setState({ patient });
                                        }} />} label='Enable Trials Notification' />
                                    </div>
                                    <div style={styles.patientRow}>
                                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={patient.reminderEnabled || false} onChange={(e, v) => {
                                            patient.reminderEnabled = v; this.setState({ patient });
                                        }} />} label='Enable Reminder' />
                                        <TextField style={{ margin: 10, width: 150 }} id='reminderTime' type='time' value={moment(patient.reminderTime).format("HH:mm")}
                                            onChange={(e) => {
                                                console.log("onChange:", e.target.value);
                                                let time = e.target.value;
                                                let reminderTime = new Date();
                                                reminderTime.setHours(time.substring(0, time.indexOf(':')));
                                                reminderTime.setMinutes(time.substring(time.indexOf(':') + 1));
                                                reminderTime.setSeconds(0);

                                                patient.reminderTime = reminderTime;
                                                this.setState({ patient });
                                            }} />

                                        <FormControl style={{ width: 150, margin: 0, top: -5 }}>
                                            <InputLabel>Interval</InputLabel>
                                            <Select value={patient.reminderInterval} onChange={(e) => {
                                                patient.reminderInterval = e.target.value;
                                                this.setState({ patient });
                                            }} >
                                                <MenuItem value={1440}>Daily</MenuItem>
                                                <MenuItem value={10080}>Weekly</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Tooltip title='Reset reminder'><IconButton onClick={() => {
                                            patient.reminderEnabled = null;
                                            patient.reminderTime = null;
                                            patient.reminderInterval = null;
                                            this.setState({ patient });
                                        }}><DeleteIcon /></IconButton></Tooltip>
                                    </div>
                                    <div />
                                </Paper>
                            </div>
                            <div style={styles.sectionRow}>
                                {displayTabs.length > 0 ? <div>
                                    <Tabs style={styles.tab} variant="scrollable" value={currentTab}
                                        onChange={(e, t) => {
                                            this.setState({ currentTab: `${t}` });
                                        }} >
                                        {displayTabs}
                                    </Tabs>
                                    {tabs[currentTab]}
                                </div> : null}
                                <br />
                                <OwnerInfo appContext={appContext} model={patient} />
                            </div>
                        </div>
                    } // end if (patient)

                    viewContent = <div>{headerItems}{patientItems}</div>;
                }
                break;
            case 'historical':
                viewContent = patient ? <HistoricalPatientView appContext={appContext} patientId={patient.patientId} disableEdit /> : null;
                break;
            case 'chart':
                viewContent = patient ? <PatientChart appContext={appContext} patient={patient} /> : null;
                break;
            default:
                break;
        }

        return (
            <div style={styles.main} align="left">
                <div style={styles.patientForm}>
                    {appBar}
                    <Tabs style={styles.topTab} variant='scrollable' value={view}
                        onChange={(e, t) => {
                            this.setState({ view: t })
                        }}>
                        <Tab value='record' label='Record' />
                        <Tab value='historical' label='History' />
                        <Tab value='chart' label='Chart' />
                    </Tabs>
                    {viewContent}
                </div>
                {dialog}
            </div>
        );
    }
}


const styles = {
    main: {
        textAlign: 'left',
        width: '70vw'
    },
    contactMethod: {
        width: '150px',
        margin: 15
    },
    gender: {
        width: '150px',
        margin: 15
    },
    patientForm: {
        display: 'flex',
        flexDirection: 'column'
    },
    patientHeader: {
        width: '100%'
    },
    sectionHeader: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '64px',
        paddingLeft: '24px'
    },
    sectionPaperContainer: {
        margin: 10
    },
    patientRow: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    duration: {
        margin: 5,
        width: 100
    },
    reason: {
        margin: 5,
        width: 400
    },
    select: {
        margin: 5,
        width: 300
    },
    patientItem: {
        margin: 5,
        width: 300
    },
    patientId: {
        width: 500,
        margin: 5
    },
    button: {
        margin: 5
    },
    appBar: {
        backgroundColor: "#606060"
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 1000,
        borderRadius: 6
    },
    sectionRow: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15,
        marginBottom: 15,
    },
    delete: {
        margin: 5,
        "textAlign": "right"
    },
    topTab: {
        "backgroundColor": "#D0D0D0"
    },
    tab: {
        "backgroundColor": "#707070"
    }
}

export default PatientView;
