import React from 'react';

import { 
    CircularProgress, 
    Drawer, 
    IconButton,
    TextField,
    FormControlLabel,
    Checkbox    
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import { getOrgs } from '@apricityhealth/web-common-lib/utils/Services';
import Logger from '@apricityhealth/web-common-lib/utils/Logger';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import OrgView from './OrgView';

import '../../styles/org.css'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';


function testFilter(filter, org) {
    if (org) filter = String(filter).toLowerCase();
    else return false;// Invalid Org param
    if (String(org.orgId).toLowerCase().indexOf(filter) >= 0) return true;
    else if (String(org.name).toLowerCase().indexOf(filter) >= 0) return true;
    else return false;
};


const log = new Logger();


/**
 * @extends React.Component
 * Apricity Tenant-Organization(s) View
 * @memberof module:Admin
 * @alias OrgsView
 */
export class OrgsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            orgs: [],
            showInactive: false,
            showUnvalidated: false
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const { props: { appContext } } = this;
        this.setState({ error: null, progress: <CircularProgress size={20} /> });
        getOrgs(appContext, { inactive: this.state.showInactive, validated: !this.state.showUnvalidated }).then( (orgs) => {
            this.setState({ progress: null, error: null, orgs }, this.applyFilter.bind(this))
        }).catch( err => {
            this.setState({ progress: null, error: getErrorMessage(err) })
        });
    }

    applyFilter() {
        const { state: { orgs, filter } } = this,
            filtered = orgs.filter(o => testFilter(filter, o));
        if (filtered.length > 0) this.setState({ filtered });
        else this.setState({ filtered: null });
    }

    createOrg() {
        const {
            props: { appContext }
        } = this;
        this.setState({
            progress: null,
            dialog: <React.Fragment key='drawer'><Drawer open variant='persistent' anchor='right'>
                <OrgView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
            </Drawer></React.Fragment>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types) this._types.setState({ selected: [] });        // unselect the question
    }

    orgSelected(s, t) {
        if (s && typeof s[0] === 'number') {// OrgTable selected index
            const {
                props: { appContext },
                state: { filtered, orgs }
            } = this,
                org = filtered ? filtered[s[0]] : orgs[s[0]];
            log.debug('Org selected:', org);
            this.setState({
                progress: null,
                dialog: <React.Fragment key='drawer'><Drawer open variant='persistent' anchor='right'>
                    <OrgView appContext={appContext} orgId={org.orgId} onClose={this.onCloseDialog.bind(this)} />
                </Drawer></React.Fragment>
            });
        }
        else this.setState({ progress: null, dialog: null });
    }


    render() {
        const { state: { orgs, filtered, filter, dialog, progress, error, showInactive, showUnvalidated } } = this;
        const columnData = [
            { id: 'orgId',       label: 'Org ID' },
            { id: 'name',          label: 'Name' },
            { id: 'shortName',        label: 'Short Name' },
            { id: 'patientDNS',  label: 'Patient DNS' },
            { id: 'providerDNS', label: 'Provider DNS' },
            { id: 'idPrefix', label: 'ID Prefix' },
            { id: 'directMessageDomain', label: 'DM Domain' }
        ];
        return <div><table width='100%'><tbody>
            <tr>
                <td>
                    <TextField className='filter' label='Filter' value={filter} onChange={
                        (e) => this.setState({ filter: e.target.value }, this.applyFilter.bind(this))}
                    />
                    <FormControlLabel control={<Checkbox checked={showInactive}
                        onChange={(e, v) => {
                            this.setState({ showInactive: v }, () => this.loadContent());
                        }}
                    />} label="Show Inactive" />
                    <FormControlLabel control={<Checkbox checked={showUnvalidated}
                        onChange={(e, v) => {
                            this.setState({ showUnvalidated: v }, () => this.loadContent());
                        }}
                    />} label="Show Unvalidated" />
                </td>
                <td align='right'>
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>
                        {progress ? progress : <RefreshIcon />}
                    </IconButton>
                    <span className='error'>{error}</span>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <EnhancedTable
                        disableMultiSelect
                        title='Organizations'
                        orderBy='name'
                        rowsPerPage={25}
                        columnData={columnData}
                        data={filtered || orgs}
                        onTable={(table) => this._types = table}
                        onSelected={this.orgSelected.bind(this)}
                        onAdd={this.createOrg.bind(this)}
                    />
                </td>
            </tr>
            <tr>
                <td align='right'>{dialog}</td>
            </tr>
        </tbody></table></div>;
    }
};


export default OrgsView;