import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Paper, IconButton } from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';


class JSONView extends Component {
    close() {
        this.props.onClose();
    }

    render() {
        const self = this;
        const { data, type } = self.props;
        return (
            <div align="left" style={styles.div}>
                {this.props.onClose ? <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.close()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="title" color="inherit">Content Details</Typography>
                    </Toolbar>
                </AppBar> : null}
                <Paper key="2" style={styles.jsonPaper}>
                    <ReactJson src={data} name={this.props.name || 'root'}
                        collapsed={3} collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
                {type && <Paper key="3" style={styles.jsonPaper}>
                    <ReactJson src={type} name={this.props.typeName || 'root'}
                        collapsed={3} collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>}

            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 0
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }
}

export default JSONView;
