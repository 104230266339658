import React, { Component } from 'react';
import {
    TextField,
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Procedure from '@apricityhealth/web-common-lib/components/Procedure';
export class PatientProceduresView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            procedures: props.procedures || [],
            filtered: [],
            dialog: null,
            progress: null,
            filter: ''
        }
    }

    componentDidMount() {
        this.applyFilter();
    }

    componentDidUpdate(prevProps) {
        if (this.props.procedures !== prevProps.procedures)
            this.applyFilter();
    }

    applyFilter() {
        let { filter, procedures } = this.state;
        let filtered = [];
        for (let i = 0; i < procedures.length; ++i) {
            const procedure = procedures[i];
            if (filter) {
                if ( procedure.procedureId.indexOf(filter.toLocaleLowerCase()) >= 0 ) {
                    filtered.push(procedure);
                } else if (procedure.ref) {
                    const { label } = procedure.ref.state;
                    if ( label && label.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                        filtered.push(procedure);
                    }
                }
            } else {
                filtered.push(procedure);
            }
        }
        this.setState({ filtered });
    }
    
    render() {
        const { planId, appContext } = this.props;
        const { dialog, filter, filtered } = this.state;
        const dataColumns = [
            { id: 'startDate', label: 'Start Date' },
            { id: 'endDate', label: 'End Date' },
            { id: 'procedureId', label: 'Name', formatValue: (v, r) => {
                return <Procedure appContext={appContext} planId={planId} procedureId={v} ref={(ref) => r.ref = ref} />;
            }},
            { id: 'text', label: 'Text' }
        ];

        let filterInput = <div >
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => this.setState({ filter: e.target.value }, this.applyFilter.bind(this))} />
        </div>;
        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                {filterInput}
            </td><td align='right'>
                    {dialog}
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable
                    onTable={(table) => { this._types = table }}
                    order='desc'
                    orderBy='startDate'
                    columnData={dataColumns}
                    data={filtered}
                    rowsPerPage={25}
                    title='Procedures' />
            </td></tr>
        </tbody></table>;
    }
}

export default PatientProceduresView;
