import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    IconButton,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import {
    loadActivities,
} from '@apricityhealth/web-common-lib/utils/Services';
import DataTypeInstanceView from '../DataTypeInstanceView';

export class PatientActivityView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            dialog: null,
            progress: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.patientId !== prevProps.patientId) || (this.props.args !== prevProps.args))
            this.loadContent();
    }

    loadContent() {
        const self = this;

        let { appContext, patientId, args } = this.props;
        if (patientId) {
            if (!args) args = [];
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            loadActivities(appContext, { patientId } ).then((content) => {
                self.setState({ content, progress: null }, self.updateTable.bind(self));
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    updateTable() {
        const self = this;
        let { content } = this.state;
        const dataColumns = [
            { id: 'createdAt', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Create Time' },
            { id: 'activityType', numeric: false, disabledPadding: false, label: 'Type' },
            { id: 'providerId', numeric: false, disabledPadding: false, label: 'Provider' },
        ];

        let table = <EnhancedTable
            onTable={(table) => { self._types = table }}
            onSelected={(s, t) => self.contentSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            order='desc'
            orderBy='createdAt'
            columnData={dataColumns}
            data={content}
            rowsPerPage={25}
            title='Patient Activities' />

        self.setState({ table });
    }

    contentSelected(s, t) {
        let self = this;
        let { appContext, patientId, planId } = self.props;

        let { content } = self.state;
        let data = content[s[0]];
        console.log(`selected `, data.dataId);
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DataTypeInstanceView appContext={appContext} patientId={patientId} instance={data} planId={planId} dataId={data && data.dataId} onClose={() => self.closeDialog()} />
        </Drawer>;
        if (this._types)
            this._types.setState({ selected: [] });
        self.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    render() {
        const { table, dialog, progress, error } = this.state;

        return <table style={{width: '100%'}}><tbody>
            <tr><td align='right'>
                {error}
                <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
            </td></tr>
            <tr><td>
                {table}
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientActivityView;
