
import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/';

let DEFAULT_OPTIONS = [
    {
        label: '7d',
        value: 7
    }, {
        label: '14d',
        value: 14
    }, {
        label: '30d',
        value: 30
    }, {
        label: '90d',
        value: 90
    }
];
function HorizontalDateRange({ classes, options, onChange }) {
    const [selected, setSelected] = useState((options || DEFAULT_OPTIONS)[0].value)
    const handleChange = (value) => {
        setSelected(value)
        if (typeof onChange === 'function')
            onChange(value);
    };
    let tabOptions = options || DEFAULT_OPTIONS;

    return (
        <Tabs
            value={selected}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, tab) => handleChange(tab)}
            className={classes.root}
        >
            {
                tabOptions && tabOptions.map(option => (
                    <Tab key={option.value} className={classes.tab} disabled={option.disabled} label={option.label} value={option.value} />
                ))
            }
        </Tabs>
    );
}

let styles = () => ({
    tab: {
        '@media (min-width: 0px)': {
            minWidth: "50px"
        }
    }
})

export default withStyles(styles)(HorizontalDateRange)