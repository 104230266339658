import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog
} from '@material-ui/core/';


import EditAddressDialog from './EditAddressDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {
    arrayRemoveLocation
} from '../../utils/Utils';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';


class AddressView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: props.address,
            modalOpen: false,
            editIndex:0,
            create:false
        }
    }

    handleOnAdd = () => {
        const self = this;
        let { address } = self.state;
        const newAddress = {} ;
        if (!address )
            address = [];
        address.push( newAddress );
        this.setState({address:address, create: true, editIndex: address.length-1, modalOpen:true})
    }

    editAddress = (event, index) => {
        this.setState({create: false, editIndex: index, modalOpen:true})
    }

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    deleteAddress(evt, addressIndex) {
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        let { address, editIndex } = this.state;
        var delIndex 
        if (addressIndex !== undefined){
            delIndex = addressIndex
        }else{
            delIndex = editIndex
        }
        if (delIndex > -1) {
            arrayRemoveLocation(address, delIndex);
            this.setState({address});
        }
    }

    componentDidUpdate(oldProps){
        const oldAddress = JSON.stringify(oldProps.address)
        const newAddress = JSON.stringify(this.props.address)
        if (oldAddress !== newAddress){
            this.setState({address:this.props.address});
        }
    }

    render(){
        const self = this;
        let { address, modalOpen, editIndex, create } = self.state;
        let { appContext } = this.props
        let addresses = () => {
            var tableRows = []
            if (Array.isArray(address)){
                address.forEach( (addressItem, index)=> {
                    var row = (
                        <TableRow
                            hover
                            onClick={event => this.editAddress(event, index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                {addressItem.addressType}
                            </TableCell>
                            <TableCell>
                                {addressItem.street1}
                            </TableCell>
                            <TableCell>
                                {addressItem.street2}
                            </TableCell>
                            <TableCell>
                                {addressItem.city}
                            </TableCell>
                            <TableCell>
                                {addressItem.state}
                            </TableCell>
                            <TableCell>
                                {addressItem.postalCode}
                            </TableCell>
                            <TableCell>
                                {addressItem.country}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(evt) => this.deleteAddress(evt, index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                })
            }
            return tableRows 
        }
        let editCreateModal = ()=> {
            if (modalOpen){
                return (
                    <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.modalOpen}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Address Details
                        </DialogTitle>
                        <DialogContent>
                            <EditAddressDialog 
                                address={address[editIndex]} 
                                appContext={appContext}
                                onCancel={this.handleClose.bind(this)}
                                onCancelDelete={this.deleteAddress.bind(this)}
                                onDone={this.handleClose.bind(this)}
                                create={create}
                            />
                        </DialogContent>
                        <DialogActions>
                        </DialogActions>
                    </Dialog>
                )
            }
        }
        
        return (
            <div>
                {editCreateModal()}
                <Paper>
                <EnhancedTableToolbar
                    title='Addresses'
                    onAdd={this.handleOnAdd}
                    onDelete={() => {}}
                    numSelected={0}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Street 1</TableCell>
                            <TableCell>Street 2</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addresses()}
                    </TableBody>
                </Table>
                </Paper>
            </div>
        )
    }
}

export default AddressView;