import React from 'react';
import Axios from 'axios';

import {
    CircularProgress,
    TextField,
    Tooltip,
    Chip,
    Avatar,
    IconButton,
    MenuItem,
    Menu,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import DefaultRoles from '@material-ui/icons/GroupAdd';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Config from '@apricityhealth/web-common-lib/Config';

import '../../styles/org.css'
class RolesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            filter: "",
            org: props.org,
            filtered: [],
            rights: [],
            profile: 'ApricityCare',
            appendRoles: false
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.org !== this.props.org) {
            this.setState({ org: this.props.org }, this.loadContent.bind(this))
        }
    }

    loadContent() {
        const getRights = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/rights`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log("getRights:", getRights);
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Axios(getRights).then((response) => {
            console.log("getRights response:", response);
            let rights = response.data;
            rights.sort((a, b) => {
                if (a.rightId < b.rightId)
                    return -1;
                else if (a.rightId > b.rightId)
                    return 1;
                return 0;
            })

            // TODO: Remove invalid rights from this current org

            this.setState({ rights, progress: null }, this.applyFilter.bind(this));
        }).catch((error) => {
            console.log("getRights error:", error);
            this.setState({ progress: null, error: error.message });
        });
    }

    applyFilter() {
        let { org, filter } = this.state;
        let { roles } = org;

        function testFilter(filter, item) {
            if (item) {
                filter = filter.toUpperCase();
                if (item.roleId && item.roleId.toUpperCase().indexOf(filter) >= 0)
                    return true;
            }
            return false;
        }

        let filtered = [];
        if (filter) {
            for (var i = 0; i < roles.length; ++i) {
                if (testFilter(filter, roles[i]))
                    filtered.push(roles[i]);
            }
        } else {
            filtered = null;
        }
        this.setState({ filtered });
    }

    handleClose() {
        this.setState({ dialog: null })
    }

    accessUpdated(roleId, right, access) {
        let { org } = this.state;
        let { roles } = org;
        let role = roles.find((role) => role.roleId === roleId);
        if (role) {
            if (access === "none") {
                let location = role.rights.findIndex((r) => r.right === right);
                if (location >= 0)
                    role.rights.splice(location, 1);
            } else {
                let roleRight = role.rights.find((r) => r.right === right);
                if (roleRight) roleRight.access = access;

            }
            this.setState({ dialog: null, org })
        }

    }

    addRight(roleRights, right, roleId) {
        roleRights.push({ right: right.rightId, access: 'read' });
        this.setState({ dialog: null })

    }
    onChipRightsControl(table, value, row, index, id) {
        let { roleId } = row;
        let { rights } = this.state;

        let roleRights = value;
        let self = this;
        let items = [];
        let remainingRights = rights.filter((r) => !roleRights.find((role) => role.right === r.rightId))

        for (let i = 0; i < roleRights.length; ++i) {
            const { right, access } = roleRights[i];
            const rightDetail = rights.find((e) => e.rightId === right);
            if (access !== "none")
                items.push(<Tooltip key={items.length} title={rightDetail ? rightDetail.description : ''}><Chip key={i}
                    style={{ margin: '0.1rem' }}
                    variant="outlined"
                    color="primary"
                    avatar={<Avatar>{access === 'admin' ? 'A' : access === 'write' ? 'W' : access === 'read' ? 'R' : '-'}</Avatar>}
                    label={right}
                    onClick={(e) => {
                        self.setState({
                            dialog: <Menu
                                id="access-menu"
                                anchorEl={e.currentTarget}
                                keepMounted
                                open={true}
                                onClose={this.handleClose.bind(this)}
                            >
                                <MenuItem value='read' onClick={this.accessUpdated.bind(this, roleId, right, "read")}>Read</MenuItem>
                                <MenuItem value='write' onClick={this.accessUpdated.bind(this, roleId, right, "write")}>Write</MenuItem>
                                <MenuItem value="admin" onClick={this.accessUpdated.bind(this, roleId, right, "admin")}>Admin</MenuItem>
                            </Menu>
                        })

                    }}
                    onDelete={() => {
                        roleRights.splice(i, 1);
                        self.setState({ rights });
                    }}
                /></Tooltip>);
        }
        if (remainingRights.length > 0)
            items.push(<Tooltip key={items.length} title='Add Access Control'>
                <IconButton
                    color="primary"
                    onClick={(e) => {
                        self.setState({
                            dialog: <Menu
                                id="access-menu"
                                anchorEl={e.currentTarget}
                                keepMounted
                                open={true}
                                onClose={this.handleClose.bind(this)}
                            >
                                {remainingRights.map((right) => <MenuItem value={right.rightId} onClick={this.addRight.bind(this, roleRights, right, roleId)}>{right.description}</MenuItem>)}

                            </Menu>
                        })

                    }}
                >
                    <AddIcon
                    />
                </IconButton>
            </Tooltip>)

        return items;
    }

    createDefaultRoles() {
        let dialog = <Dialog open={true}>
            <DialogTitle>Create Default Roles?</DialogTitle>
            <DialogContent>Confirm the creation of default roles for this organization...<br />
                <FormControlLabel label="Append Roles" control={<Checkbox checked={this.state.appendRoles} 
                    onChange={(e,appendRoles) => this.setState({appendRoles}, this.createDefaultRoles.bind(this))} />} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => { this.setState({ dialog: null }) }}>Cancel</Button>
                <Button variant='contained' onClick={() => {
                    let { org, rights } = this.state;
                    org.roles = this.state.appendRoles ? [ ...org.roles ] : [];     
                    
                    for(let i=0;i<rights.length;++i) {
                        const right = rights[i];
                        const defaults = right.defaults || [];
                        for(let k=0;k<defaults.length;++k) {
                            const role = defaults[k];
                            if ( !role.planIds.find((planId) => planId === '*' || org.planIds.find((k) => k.planId === planId))) {
                                // org doesn't have a required plan for this role..
                                continue;
                            }
                            const existingRole = org.roles.find((e) => e.roleId === role.roleId);
                            if ( existingRole ) {
                                const LEVELS = ['read', 'write', 'admin'];
                                const existingRight = existingRole.rights.find((r) => r.right === right.rightId);
                                if ( existingRight ) {
                                    if ( LEVELS.indexOf(existingRight.access) < LEVELS.indexOf(role.access) ) {
                                        existingRight.access = role.access;
                                    }
                                } else {
                                    existingRole.rights.push({ right: right.rightId, access: role.access } )
                                }
                            } else {
                                org.roles.push( { roleId: role.roleId, rights: [{ right: right.rightId, access: role.access}] } );
                            }
                        }
                    }

                    this.setState({ org, filtered: null, dialog: null }, this.applyFilter.bind(this));
                }}>Yes</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    render() {
        let self = this;
        let { org, filtered, dialog, progress, filter, error } = this.state;

        let rolesColumns = [
            { id: 'roleId', editType: 'text', label: 'Role Id' },
            { id: 'rights', label: 'Rights', editControl: this.onChipRightsControl.bind(this), onAdd: () => { return [] } }
        ];

        if (!Array.isArray(org.roles)) {
            org.roles = [];
        }
        console.log("org.roles:", org.roles );
        return <table key='RolesView' style={{ width: '100%' }}><tbody>
            <tr><td align='left'>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)); }} />
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this, 0, null)}>{progress ? progress : <RefreshIcon />}</IconButton>
                    <IconButton onClick={this.createDefaultRoles.bind(this)}><DefaultRoles /></IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable
                    rowsPerPage={5}
                    orderBy='roleId'
                    columnData={rolesColumns}
                    data={filtered ? filtered : org.roles}
                    onDataChanged={(data) => {
                        for(let i=0;i<data.length;++i) {
                            data[i].roleId = (data[i].roleId || '').trim().toLowerCase();
                        }
                        org.roles = data
                    }}
                    title='Roles' />
                {dialog}
            </td></tr>
        </tbody></table>
    }
}

export default RolesView;
