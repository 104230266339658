import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    LinearProgress,
    FormControlLabel,
    Radio,
    FormControl,
    RadioGroup,
    FormLabel
} from '@material-ui/core/';

import {
    sendMessage
} from '@apricityhealth/web-common-lib/utils/Services';

class SendMessageDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {
                message: 'Test message from Apricity Health',
                subject: 'Message from Apricity Health',
                providerId: props.appContext.state.userId
            }

        };
        if (props.patient) {
            this.state.message.patientId = props.patient.patientId;
            this.state.message.to = props.patient.patientId;
        } else if (props.provider) {
            this.state.message.providerId = props.provider.providerId;
            this.state.message.to = props.provider.providerId;
        }
    }


    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        let self = this;
        const { onDone, appContext } = this.props;
        const { message } = this.state;

        self.setState({ progress: <LinearProgress /> });
        sendMessage(appContext, message).then(function (response) {
            self.setState({ progress: null });
        }).catch(function (error) {
            self.setState({ progress: error.message });
        });

        if (onDone)
            onDone(message);
    }

    onChange(e) {
        let { message } = this.state;
        let type = e.target.name;

        delete message.phone;
        delete message.email;
        message.contactMethod = type;
        message[type] = e.target.value;

        this.setState({ message });
        if (this.props.onChange)
            this.props.onChange(message);
    }



    getContactOptions() {
        let self = this;
        let { message } = this.state;
        let { patient, provider } = this.props;
        let contacts = [];
        let emails = [];
        if (patient)
            emails = patient.emails;
        if (provider)
            emails = provider.emails;

        for (let index = 0; index < emails.length; index++) {
            let email = emails[index];
            contacts.push(<FormControlLabel value={email.email} control={<Radio name='email' />} label={email.email} />);
        }
        let phones = [];

        if (patient)
            phones = patient.phoneNumbers;
        if (provider)
            phones = provider.phoneNumbers;
        for (let index = 0; index < phones.length; index++) {
            let phone = phones[index];
            contacts.push(<FormControlLabel value={phone.number} control={<Radio name='phone' />} label={phone.number} />);
        }


        return <FormControl align='left'>
            <FormLabel >Contacts</FormLabel>
            <RadioGroup
                aria-label="contacts"
                name="contacts"
                value={message.contactMethod === 'phone' ? message.phone : message.email}
                onChange={(e) => { self.onChange(e) }}
            >
                {contacts}
            </RadioGroup>
        </FormControl>;


    }



    render() {
        let { message } = this.state;
        let { name, patient, provider } = this.props
        let self = this;

        let preferred = '';
        if (patient)
            preferred = patient.contactMethod;
        if (provider)
            preferred = provider.contactMethod;
        let title = null;
        if (name)
            title = <div>Select the contact to send a message to <b>{name}</b>. Note: User preferred contact is <b>{preferred}</b>.</div>

        let contactSelector = self.getContactOptions();

        return (
            <Dialog
                maxWidth={'md'}
                fullWidth={true}
                model="false"
                open={true}
            >
                <DialogContent >
                    {title}
                    <div style={{ marginTop: '20px' }} />
                    <div align='left' style={{ align: 'left' }}>
                        {contactSelector}
                    </div>
                    <div style={{ marginTop: '20px' }} />
                    <TextField
                        fullWidth
                        name='subject'
                        label="Subject:"
                        width='200' height='100'
                        value={message.subject}
                        onChange={(e) => { self.onChange(e, "subject") }}
                    />
                    <div style={{ marginTop: '10px' }} />
                    <TextField
                        fullWidth
                        multiline={true}
                        name='message'
                        rows={2}
                        label="Message:"
                        variant="outlined"
                        width='200' height='100'
                        value={message.message}
                        onChange={(e) => { self.onChange(e, "message") }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Send</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>

        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    }
};

SendMessageDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(SendMessageDialog);
