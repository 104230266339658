import React, { Component } from 'react';
import {
    CircularProgress,
    IconButton,
    Tab,
    Tabs,
    Paper,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Drawer,
    AppBar,
    Toolbar,
    Typography
} from '@material-ui/core/';

import ReactJson from 'react-json-view';
import Moment from 'moment';

import NavigationClose from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import ResetHistoryIcon from '@material-ui/icons/PowerSettingsNew';
import PatientIcon from '@material-ui/icons/Edit';

import PatientRecommendationsView from './PatientRecommendationsView';
import PatientJournalsView from './PatientJournalsView';
import PatientDataView from './PatientDataView';
import PatientForecastView from './PatientForecastView';
import PatientDataGroupView from './PatientDataGroupView';
import PatientLabsView from './PatientLabsView';
import PatientMedicationsView from './PatientMedicationsView';
import PatientProceduresView from './PatientProceduresView';
import PatientConditionsView from './PatientConditionsView';
import PatientEncountersView from './PatientEncountersView';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import Config from '@apricityhealth/web-common-lib/Config';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';

import PatientView from '../PatientsView/PatientView';

import {
    getAllPlanIds,
    getPatient,
} from '@apricityhealth/web-common-lib/utils/Services';

import PatientActivityView from './PatientActivityView';
import PatientNotesView from './PatientNotesView';
import PatientAppointmentsView from './PatientAppointmentsView';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import PatientReportView from './PatientReportView';
import PatientMessagesView from './PatientMessagesView';
import moment from 'moment'

const log = new Logger();
export class HistoricalPatientView extends Component {
    constructor(props) {
        super(props);

        //we need this becuase app.js is not updated on a redirect from within admin..
        const urlPath = window.location.pathname.slice(1).split('/');
        const path = {
            view: urlPath[0] === '' ? null : urlPath[0],
            id: urlPath.length > 1 ? urlPath[1] : null
        };

        let endTime = props.endTime ? Moment(props.endTime).toDate() : Moment().endOf('day').toDate();
        let startTime = props.startTime ? Moment(props.startTime).toDate() : Moment(endTime).subtract(30, 'day').toDate();
        this.state = {
            dialog: null,
            patientId: path.id || props.patientId || "",
            patient: null,
            tab: 'data',
            endTime,
            startTime
        }
    }

    componentDidMount() {
        if (this.state.patientId) this.getPatientData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.patientId !== prevProps.patientId) {
            this.setState({ patientId: this.props.patientId }, this.getPatientData.bind(this))
        }
    }

    getPatientData() {
        const { appContext } = this.props;
        const { patientId } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        if (patientId) {
            getPatient(appContext, patientId).then((patient) => {
                this.setState({ progress: null, patient });
            }).catch((error) => {
                this.setState({ progress: null, error: error.message });
            });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    onResetHistory() {
        const { appContext } = this.props;
        this.setState({
            dialog: <Dialog open>
                <DialogTitle>Reset Patient History?</DialogTitle>
                <DialogContent>This will erase all data, journals, groups, notes, etc from this patient. Are you positive?</DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                    <Button onClick={() => {
                        const resetContext = {
                            url: Config.baseUrl + `${Config.pathPrefix}dialog/conversation/${this.state.patientId}`,
                            method: 'POST',
                            headers: { "Authorization": appContext.state.idToken },
                            data: { resetContext: true }
                        };

                        log.debug("resetContext request:", resetContext);
                        this.setState({ progress: <CircularProgress size={20} />, dialog: null });
                        AxiosRequest(resetContext).then((response) => {
                            log.debug("resetContext response:", response.data);
                            this.getPatientData();
                        }).catch((error) => {
                            log.error("resetContext error:", error);
                            this.setState({ progress: null, error: getErrorMessage(error) });
                        });

                    }}>Confirm Reset</Button>
                </DialogActions>
            </Dialog>
        });

    }
    savePatient() {
        let { patient } = this.state;

        try {
            let dob = moment(patient.dob);
            if (!dob.isValid())
                throw new Error("DOB date is invalid (YYYY-MM-DD)");

            patient.dob = dob.utcOffset(0).format("YYYY-MM-DD");
            patient.race = patient.race !== 'unspecified' ? patient.race : ''
            patient.ethnicity = patient.ethnicity !== 'unspecified' ? patient.ethnicity : ''

            let savePatient = null;
            if (patient.patientId) {
                savePatient = {
                    url: Config.baseUrl + `${Config.pathPrefix}patients/${patient.patientId}`,
                    method: 'PUT',
                    headers: { "Authorization": this.props.appContext.state.idToken },
                    data: patient
                };
            } else {
                savePatient = {
                    url: Config.baseUrl + `${Config.pathPrefix}patients`,
                    method: 'POST',
                    headers: { "Authorization": this.props.appContext.state.idToken },
                    data: patient
                };
            }

            this.setState({ progress: <CircularProgress size={20} />, error: null });
            console.log("savePatient request:", savePatient);
            AxiosRequest(savePatient).then((response) => {
                console.log("savePatient response:", response);
                if (response.data && response.data.patient)
                    this.setState({ patient: response.data.patient })
                this.setState({ progress: null });
            }).catch((error) => {
                console.log("savePatient error:", error);
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
        catch (err) {
            this.setState({ error: getErrorMessage(err) });
        }
    }

    onEditPatient() {
        const { patientId } = this.state;
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <PatientView
                disableHistory={true}
                appContext={this.props.appContext}
                patientId={patientId}
                onClose={this.onCloseDialog.bind(this)}
            />
        </Drawer>;
        this.setState({ dialog });
    }

    render() {
        const { patientId, patient, progress, error, tab, startTime, endTime, dialog } = this.state;
        const { appContext } = this.props;

        let planId = patient ? getAllPlanIds(patient) : "";
        let args = [];
        let opts = {};
        if (startTime) {
            args.push("startTime=" + startTime.toISOString());
            opts.startTime = startTime.toISOString();
        }
        if (endTime) {
            args.push("endTime=" + endTime.toISOString());
            opts.endTime = endTime.toISOString();
        }

        const appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={this.props.onClose}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Patient History</Typography>
            </Toolbar>
        </AppBar> : null;

        const tabs = patient ? {
            "data": <PatientDataView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "groups": <PatientDataGroupView appContext={appContext} patientId={patientId} planId={planId} opts={opts} />,
            "json": <Paper key="2" style={styles.jsonPaper}><ReactJson src={patient} collapsed={3} name="patients"
                collapseStringsAfterLength={64} displayDataTypes={false} /></Paper>,
            "recommendations": <PatientRecommendationsView appContext={appContext} patientId={patientId} planId={planId} />,
            "journals": <PatientJournalsView appContext={appContext} patientId={patientId} />,
            "encounters": <PatientEncountersView appContext={appContext} patientId={patient.patientId} opts={opts} />,
            "conditions": <PatientConditionsView appContext={appContext} conditions={patient.conditions} planId={planId} />,
            "medications": <PatientMedicationsView appContext={appContext} medications={patient.medications} save={(medications) => { patient.medications = medications; this.setState({ patient }, () => this.savePatient()); }} planId={planId} />,
            "procedures": <PatientProceduresView appContext={appContext} procedures={patient.procedures} planId={planId} />,
            "labs": <PatientLabsView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "activities": <PatientActivityView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "appointments": <PatientAppointmentsView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "notes": <PatientNotesView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "messages": <PatientMessagesView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
            "summary": <PatientReportView appContext={appContext} patientId={patientId} />,
            "forecasts": <PatientForecastView appContext={appContext} patientId={patientId} planId={planId} args={args} />,
        } : {};

        const table = <div style={styles.patientForm}>
            <table style={{ width: '100%' }}><tbody>
                <tr><td>
                    <span style={{ marginLeft: 10 }}>
                        {!this.props.patientId ?
                            <SelectPatient
                                style={{ width: 400, margin: 5 }}
                                appContext={appContext}
                                patientId={patientId}
                                onSelect={(patientId) => this.setState({ patientId }, this.getPatientData.bind(this))} /> : null}
                        <DateRange
                            appContext={appContext}
                            format={"MM/dd/yyyy"}
                            startTime={startTime}
                            endTime={endTime}
                            endTimeChange={(endTime) => { this.setState({ endTime: Moment(endTime).endOf('day').toDate() }) }}
                            startTimeChange={(startTime) => { this.setState({ startTime }) }} />
                    </span>
                </td><td align='right'>
                        {error}
                        {this.props.disableEdit !== true ? <Tooltip title='Edit Patient'>
                            <IconButton onClick={this.onEditPatient.bind(this)}><PatientIcon /></IconButton>
                        </Tooltip> : null}
                        <Tooltip title='Reset History'>
                            <IconButton onClick={this.onResetHistory.bind(this)}><ResetHistoryIcon /></IconButton>
                        </Tooltip>
                        <IconButton disabled={progress !== null} onClick={this.getPatientData.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                    </td></tr>
            </tbody></table>
            {patient ?
                <Paper square >
                    <Tabs style={styles.tab} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" value={tab}
                        onChange={(e, t) => {
                            this.setState({ tab: t })
                        }}>
                        <Tab label="Data" value='data' />
                        <Tab label="Journals" value='journals' />
                        <Tab label="Notes" value='notes' />
                        <Tab label="Activities" value='activities' />
                        <Tab label="Appointments" value='appointments' />
                        <Tab label="Messages" value='messages' />
                        <Tab label="Recommendations" value='recommendations' />
                        <Tab label="Summary" value='summary' />
                        <Tab label="Encounters" value='encounters' />
                        <Tab label="Conditions" value='conditions' />
                        <Tab label="Medications" value='medications' />
                        <Tab label="Procedures" value='procedures' />
                        <Tab label="Groups" value='groups' />
                        <Tab label="Forecasts" value='forecasts' />
                    </Tabs>
                </Paper> : null}
            {tabs[tab]}
            {dialog}
        </div>;

        if (appBar) {
            return <div style={styles.main} align="left">
                {appBar}
                {table}
            </div>;
        }
        else {
            return table;
        }
    }
}

const styles = {
    appBar: {
        width: '100%'
    },
    tab: {
        backgroundColor: '#F0F0F0',
        width: '100%'
    },
    main: {
        textAlign: 'left',
        width: '65vw'
    },
    patientForm: {
        display: 'flex',
        flexDirection: 'column'
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    },
    refresh: {
        marginTop: 8,
    },
    buttons: {
        margin: 5,
        "textAlign": "right"
    },
    button: {
        margin: 10
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    select: {
        marginLeft: 10,

    }
};

export default HistoricalPatientView;
