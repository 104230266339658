import React from 'react';
import {
    Drawer,
    TextField,
    Tooltip,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import ImportIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DetectIcon from '@material-ui/icons/TrackChanges';

import PatientView from './PatientView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import ImportPatientsDialog from '../../dialogs/ImportPatientsDialog';
import DetectAllDialog from '../../dialogs/DetectAllDialog';

import {
    getPatientsPage
} from '@apricityhealth/web-common-lib/utils/Services';

import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import SelectOrg from '@apricityhealth/web-common-lib/components/SelectOrg';
import SelectTrial from '@apricityhealth/web-common-lib/components/SelectTrial';
import ExportPatients from './ExportPatients';

export class PatientsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            patients: [],
            planId: '*',
            orgId: '*',
            trialId: '*',
            showExported: false,
            showImported: false,
            showInactive: false,
            showDates: false
        }
    }

    componentWillMount() {
        let parts = window.location.pathname.slice(1).split('/');
        if (parts.length > 1) {
            this.onViewPatient(parts[1]);
        }
    }

    loadContent({ offset, limit, order, orderBy }) {
        const { appContext } = this.props;
        let { search, planId, orgId, trialId, showExported, showImported, showInactive, showDates } = this.state;

        return new Promise((resolve, reject) => {
            let args = [`offset=${offset}`, `limit=${limit}`, `order=${order}`, `orderBy=${orderBy}`];
            if (search) {
                args.push(`search=${encodeURIComponent(search)}`);
            }
            if (planId && planId !== '*') {
                args.push(`planId=${planId}`)
            }
            if (orgId && orgId !== '*') {
                args.push(`orgId=${orgId}`)
            }
            if (trialId && trialId !== '*') {
                args.push(`trialId=${trialId}`)
            }
            if (showExported) {
                args.push("exportFlag=true");
            }
            if (showInactive) {
                args.push('inactive=true');
            } else {
                args.push('inactive=false');
            }
            if (showImported) {
                args.push("importId=*")
            }
            if (showDates) {
                args.push(`project=patientCreated,consentGiven,acceptedTerms,selfRegistered,providerRegistered,referralDate`)
            }

            getPatientsPage(appContext, args).then((result) => {
                let data = result.patients;
                let dataLength = result.totalPatients;
                return resolve({ data, dataLength });
            }).catch((err) => {
                console.log("loadContent error:", err);
                reject(err);
            });
        })
    }

    onViewPatient(patientId) {
        const { appContext } = this.props;
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <PatientView
                appContext={appContext}
                patientId={patientId}
                onClose={this.onCloseDialog.bind(this)}
            />
        </Drawer>;
        this.setState({ dialog: dialog });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._table) {
            this._table.loadData();
            this._table.setState({ selected: [] });        // unselect the question
        }
    }

    patientSelected(s, t) {
        if (s.length > 0) {
            const patient = t.state.data[s[0]];
            this.onViewPatient(patient.patientId);
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    createPatient() {
        const { appContext } = this.props;
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <PatientView
                appContext={appContext}
                onClose={this.onCloseDialog.bind(this)}
            />
        </Drawer>;
        this.setState({ dialog });
    }

    onImportPatients() {
        let dialog = <ImportPatientsDialog appContext={this.props.appContext} onClose={this.onCloseDialog.bind(this)} />;
        this.setState({ dialog });
    }

    onRunDetect() {
        this.setState({
            dialog: <DetectAllDialog appContext={this.props.appContext} onClose={() => {
                this.setState({ dialog: null })
            }} />
        });
    }

    downloadCSV() {
        let { planId, orgId } = this.state;
        this.setState({
            dialog: <ExportPatients appContext={this.props.appContext} planId={planId} orgId={orgId} onClose={() => {
                this.setState({ dialog: null })
            }} />
        });
    }

    render() {
        let { search, dialog, planId, orgId, trialId, showExported, showImported, showInactive, showDates } = this.state;

        function isNull(v) {
            return v === '' || v === null || v === undefined || v === 'null';
        }

        const columnData = [
            {
                id: 'patientIds', numeric: false, label: 'Patient IDs', formatValue: (v) => {
                    if (Array.isArray(v)) {
                        let patientIds = [];
                        for (let i = 0; i < v.length; ++i) {
                            const patientId = v[i];
                            if (patientId.systemName.startsWith('org:')) {
                                patientIds.push(patientId.systemId);
                            }
                        }
                        patientIds = patientIds.join(',');
                        if (patientIds.length > 16) {
                            patientIds = patientIds.substring(0, 16) + "...";
                        }
                        return patientIds;
                    } else {
                        return '';
                    }
                }
            },
            { id: 'emrId', label: 'EMR', formatValue: (v) => (isNull(v) ? "No" : "Yes") },
            { id: 'firstName', label: 'First Name' },
            { id: 'lastName', label: 'Last Name' },
            {
                id: 'emails', numeric: false, disabledPadding: false, width: "100", label: 'Emails', formatValue: (v) => {
                    let result = "";
                    for (let i = 0; i < v.length; ++i) {
                        if (v[i].email) {
                            if (result) result += ",";
                            result += v[i].email;
                        }
                    }
                    return result;
                }
            },
            {
                id: 'phoneNumbers', label: 'Phone Numbers', formatValue: (v) => {
                    let result = "";
                    for (let i = 0; i < v.length; ++i) {
                        if (v[i].number) {
                            if (result) result += ",";
                            result += v[i].number;
                        }
                    }
                    return result;
                }
            },
        ];

        if (showDates) {
            columnData.push(
                { id: 'patientCreated', label: 'Patient Created', editType: "dateLabel" },
                { id: 'acceptedTerms', label: 'Accepted Terms', editType: "dateLabel" },
                { id: 'selfRegistered', label: 'Self Registered', editType: "dateLabel" },
                { id: 'providerRegistered', label: 'Provider Registered', editType: "dateLabel", width: "200px", },
                { id: 'referralDate', label: 'Referral Date', editType: "dateLabel" }
            );
        }

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField id="searchPatients" style={{ margin: 5, width: 500 }} label='Search' value={search}
                            onChange={(e) => { this.setState({ search: e.target.value }) }}
                            onKeyDown={(e) => { if (e.keyCode === 13) this._table.loadData(); }}
                        />
                        <FormControlLabel control={<Checkbox checked={showInactive}
                            onChange={(e, v) => {
                                this.setState({ showInactive: v }, () => this._table.loadData());
                            }}
                        />} label="Show Inactive" />
                        <FormControlLabel control={<Checkbox checked={showExported}
                            onChange={(e, v) => {
                                this.setState({ showExported: v }, () => this._table.loadData());
                            }}
                        />} label="Show Exported" />
                        <FormControlLabel control={<Checkbox checked={showImported}
                            onChange={(e, v) => {
                                this.setState({ showImported: v }, () => this._table.loadData());
                            }}
                        />} label="Show Imported" />
                        <FormControlLabel control={<Checkbox checked={showDates}
                            onChange={(e, v) => {
                                this.setState({ showDates: v }, () => this._table.loadData());
                            }}
                        />} label="Show Dates" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SelectPlan id="selectPlan" label='Select Plan' style={{ margin: 5, width: 300 }} enableAll={true} appContext={this.props.appContext} planId={planId} onChange={(plan) => {
                            this.setState({ planId: plan ? plan.planId : '' }, () => { this._table.loadData() });
                        }} />
                        <SelectOrg id="selectOrg" style={{ width: 300, margin: 5 }} enableAll={true} appContext={this.props.appContext} orgId={orgId} onChange={(orgId) => {
                            this.setState({ orgId }, () => { this._table.loadData() });
                        }} />
                        <SelectTrial id="selectTrial" label='Select Trial' style={{ width: 300, margin: 5 }} appContext={this.props.appContext} trialId={trialId} onChange={(trialId) => {
                            this.setState({ trialId }, () => { this._table.loadData() });
                        }} />
                    </div>
                </td>
                <td align='right'>
                    <Tooltip title='Run detect on all patients'>
                        <IconButton onClick={this.onRunDetect.bind(this)}><DetectIcon /></IconButton>
                    </Tooltip>
                </td>
            </tr>
            <tr><td colSpan={2}>
                <EnhancedTable id={`patientData`} disableMultiSelect={true}
                    onActions={(table, numSelected, actions) => {
                        actions.push(<Tooltip key='import' title='Import Scrubbed Patients'>
                            <IconButton onClick={this.onImportPatients.bind(this)}>
                                <ImportIcon />
                            </IconButton>
                        </Tooltip>);
                        actions.push(<Tooltip title='Download Patients CSV'>
                            <IconButton onClick={() => this.downloadCSV()}><DownloadIcon /></IconButton>
                        </Tooltip>)
                    }}
                    onTable={table => this._table = table}
                    onAdd={() => this.createPatient()}
                    onSelected={(s, t) => this.patientSelected(s, t)}
                    orderBy='lastName'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={this.loadContent.bind(this)}
                    title='Patients' />
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default PatientsView;
