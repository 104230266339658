
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green, red, yellow, orange } from '@material-ui/core/colors';
import {
    withStyles,
    Tooltip,
} from '@material-ui/core/';

import {
    isArrayValid,
} from '@apricityhealth/web-common-lib/utils/Services';
import Text from './Text';
import Provider from '@apricityhealth/web-common-lib/components/Provider';

let COLORS = {
    "orange": orange["A700"],
    "yellow": yellow["A700"],
    "red": red["A700"],
    "green": green["800"]
}

export function generateComplaintList({ classes, alert, rejectedActivity, patientData, dataTypes, modelTypes, planIds, showZeroAnswers, modelTypeSelected, changesSelected, textContent }) {
    let complaintList = [];
    let errors = [];


    function getValueDescription(valueDescriptions, value) {
        for (let i = 0; i < valueDescriptions.length; ++i) {
            const desc = valueDescriptions[i];
            if (Number(desc.value) === Number(value)) {
                const text = getLocalizedText(desc.description);
                if (text !== undefined) {
                    return text;
                }
            }
        }
        // no localized text description was found, so just return a blank string, so the description will not be shown
        return '';
    }
    function getLocalizedText(text) {
        let keyStart = text.indexOf('[[');
        if (keyStart >= 0) {
            let keyEnd = text.indexOf(']]', keyStart);
            if (keyEnd > keyStart) {
                const key = text.substring(keyStart + 2, keyEnd);
                return textContent["en-us"][key] && textContent["en-us"][key].text;
            }
        }
        return text;
    }

    if (alert && isArrayValid(alert.source) && isArrayValid(patientData) && isArrayValid(dataTypes) && isArrayValid(modelTypes)) {
        let { planId } = alert;
        let patientGroupData = patientData.filter((d) => d.groupId === alert.groupId)

        // let planIds = addDependentPlans([planId], planTypes);
        let source = alert.source.find((i) => isArrayValid(i.complaints));
        //need to see if this alert has a triage assessment and if so we need the updateId so we can find those specific changes
        let triageAssessmentUpdateId = null;
        let triageAssessmentSource = alert.source.find((s) => s.action && s.action === "triage-assessment");
        if (triageAssessmentSource) {
            triageAssessmentUpdateId = triageAssessmentSource.updateId;
        }


        if (source && isArrayValid(source.complaints)) {
            for (let index = 0; index < source.complaints.length; index++) {
                const complaint = source.complaints[index];
                let { symptoms, alert, sessionId, modelId, category } = complaint;
                let color = alert;
                let isLab = category === "lab";

                let modelDataType = modelTypes.find((type) => type.modelId === modelId);
                if (!modelDataType) {
                    errors.push(`${modelId} not found. Plan ${planId} might not have that model any longer, or the patient has changed plans`)
                    console.log(`Model type not found for ${modelId}  AlertPlan:${planId}, Plans:${planIds}`)
                    console.log(`all models for ${modelId}`, modelTypes.filter((type) => type.modelId === modelId));
                    continue;
                }
                let modelName = modelDataType ? modelDataType.name : modelId;
                let dataType = dataTypes.find((d) => d.dataId === modelDataType.dataId && planId === d.planId);
                console.debug(`Data type not found based on plan for  `, modelDataType.dataId);
                if (!dataType) {
                    dataType = dataTypes.filter((d) => d.dataId === modelDataType.dataId);
                    if (dataType.length > 1) {
                        errors.push(`Mutiple data types found for model dataType ${modelDataType.dataId}`)
                        console.debug(`more than one data type found in the dependent plans `, dataType);
                    }
                    if (dataType.length > 0) {
                        dataType = dataType[0];
                    }
                }

                if (!dataType) {
                    errors.push(`No data types found for model dataType ${modelDataType.dataId}`)
                    console.debug(`No data type found `, dataType, modelDataType.dataId, planId, planIds, dataTypes.filter((d) => d.dataId === modelDataType.dataId));
                    continue;
                }

                let complaintModel = patientGroupData.find((d) => d.dataId === modelDataType.dataId);
                if (complaintModel) {
                    let modelInstanceId = null;
                    let modelInstance = null;
                    let updatedFrom, updatedTo, updatedBy = null;
                    if (triageAssessmentUpdateId) {
                        let changeData = complaintModel.source.find((s) => isArrayValid(s.updatedFrom) && s.sessionId === sessionId && s.updateId === triageAssessmentUpdateId);
                        if (changeData) {
                            updatedFrom = changeData.updatedFrom;
                            updatedTo = changeData.updatedTo;
                            updatedBy = changeData.updatedBy;
                            modelInstanceId = complaintModel._id;
                            modelInstance = complaintModel;
                        }
                    }
                    if (!updatedTo) updatedTo = complaintModel.data;
                    if (updatedTo?.length !== dataType.tupleDescriptions?.length) {
                        modelName = `${modelName} (unaligned modeltype ${updatedTo?.length} : ${dataType.tupleDescriptions?.length}) `;
                        console.debug(`unaligned modeltype  `, dataType, updatedTo, dataType.tupleDescriptions);
                    }

                    complaintList.push(<div key={modelName} className={classes.model} ><strong style={{ color: COLORS[color] }} >{modelName}</strong>{modelTypeSelected && <HtmlTooltip title={"Detect Model"}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-circle-radiation" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={(e) => modelTypeSelected(e, modelDataType)} /></HtmlTooltip>}{rejectedActivity && alert === "yellow" && <HtmlTooltip title={"Patient Rejected Callback"}><FontAwesomeIcon className={classes.icon} style={{ marginLeft: "5px", height: "1.2rem" }} icon="fa-regular fa-calendar-xmark" /></HtmlTooltip>} {updatedFrom && changesSelected ? <HtmlTooltip title={<>Provider: <Provider userId={updatedBy} /></>}><FontAwesomeIcon onClick={(e) => changesSelected(e, dataType, updatedFrom, updatedTo, updatedBy, modelInstanceId, modelInstance)} icon="fa-light fa-person-circle-xmark" style={{ cursor: "pointer", marginLeft: "5px", height: "1.2rem" }} /></HtmlTooltip> : null}</div>);

                    //add the triggers
                    let triggers = symptoms.filter((s) => s.type === "trigger");
                    if (isArrayValid(triggers)) {
                        for (let index = 0; index < triggers.length; index++) {
                            const { text, answer } = triggers[index];
                            complaintList.push(
                                <div key={modelId + 'trigger' + index + text} className={classes.symptom} style={{ fontSize: "1rem" }}>{<div><FontAwesomeIcon className={classes.smallIcon} style={{ paddingLeft: "0px", marginLeft: "0px" }} icon="fa-solid fa-play" /></div>}{text}:&nbsp;{isNaN(answer) ? <Text textId={answer} /> : parseFloat(Number(answer).toFixed(3))}{isLab ? <div><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-flask" /></div> : ""}</div>
                            );
                        }
                    }

                    if (isArrayValid(updatedTo)) {
                        let { tupleDescriptions } = dataType;

                        for (let index = 0; index < updatedTo.length; index++) {
                            const symptomValue = updatedTo[index];
                            let symptomValueDesc = `index: ${index}`;
                            let answerText = null;
                            let changedFrom = null;
                            let changedFromText = null;
                            if (tupleDescriptions.length >= index) {
                                let tupleDescription = tupleDescriptions[index];

                                if (!tupleDescription) {
                                    errors.push(`tupleDescription not found for symptom value ${symptomValue} in array position ${index}. Data type ${dataType.dataId} might have changed`)
                                    console.debug(`tupleDescription not found`, dataType, modelDataType, updatedTo, index, tupleDescriptions);
                                }
                                if (tupleDescription) {
                                    symptomValueDesc = tupleDescription.description;
                                    let { valueDescriptions } = tupleDescription;
                                    if (isArrayValid(valueDescriptions)) {
                                        answerText = getValueDescription(valueDescriptions, symptomValue);
                                        // console.debug(`\t updated from `, updatedFrom, index)
                                        if (isArrayValid(updatedFrom) && updatedFrom.length >= index) {
                                            changedFrom = updatedFrom[index];
                                            if (symptomValue !== changedFrom) {
                                                changedFromText = getValueDescription(valueDescriptions, changedFrom);
                                            }
                                        }
                                    }

                                }
                            }

                            if ((Boolean(showZeroAnswers) || Number(symptomValue) !== 0) || (Number(changedFrom) !== 0)) {
                                let durationText = getDuration({ patientGroupData, dataType, sessionId });
                                let change = changedFrom !== null && Number(symptomValue) !== Number(changedFrom) ? <div className={classes.symptomChange}>Changed From:&nbsp;{changedFromText}&nbsp;({changedFrom})</div> : "";
                                let answerString = <div className={classes.row} style={{ alignItems: "flex-start" }} >{symptomValueDesc}:&nbsp;{Number.isNaN(Number.parseFloat(answerText)) ?<span dangerouslySetInnerHTML={{ __html: answerText}} /> : Number(Number.parseFloat(answerText).toFixed(3))}&nbsp;{isLab ? "" : <span>({symptomValue})</span>}&nbsp;{change}</div>

                                complaintList.push(
                                    <div key={modelId + 'symptom' + index + symptomValueDesc} className={classes.symptom} style={{ fontSize: "1rem" }}>{answerString}&nbsp;{durationText}</div>
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    let complaints = isArrayValid(complaintList) ? <div className={classes.noBulletList}>{complaintList}</div> : [];
    return { complaints, errors }
}


function getDuration({ patientGroupData, dataType, sessionId }) {
    let tuples = dataType ? dataType.tupleDescriptions : [];
    let durationText = ""
    let durationTup = tuples ? tuples.find((t) => t.classType === "Duration") : null;
    if (durationTup) {
        let symptomData = patientGroupData ? patientGroupData.find((data) => data.dataId === dataType.dataId && data.sessionId === sessionId) : null;
        let duration = symptomData ? symptomData.data[durationTup.index] : "";
        if (Number(duration) === 0.00) duration = 0; //don't show 0. .. just noise 
        let durationSameTup = tuples.find((t) => t.classType === "DurationSame");
        let durationSame = durationSameTup && symptomData ? symptomData.data[durationSameTup.index] : "";
        if (Number(durationSame) === 0.00) durationSame = 0;//don't show 0. .. just noise 
        durationText = duration || durationSame ? `[${duration || "0"},${durationSame || "0"}]` : "";
    }
    return durationText;
}

// function addDependentPlans(planIds, plans) {
//     if (planIds === '*') {
//         return plans.map((e) => e.planId);
//     }
//     if (!Array.isArray(planIds))
//         throw new Error("planIds not an array.");
//     if (!Array.isArray(plans))
//         throw new Error("plans not an array.");
//     for (let i = 0; i < plans.length; ++i) {
//         let plan = plans[i];
//         if (planIds.indexOf(plan.planId) >= 0) {
//             if (Array.isArray(plan.dependencies)) {
//                 for (let j = 0; j < plan.dependencies.length; ++j) {
//                     let dependId = plan.dependencies[j];
//                     if (planIds.indexOf(dependId) < 0) {
//                         planIds.push(dependId);
//                         i = -1;
//                     }
//                 }
//             }
//         }
//     }
//     return planIds;
// }
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);