import React from 'react';
import _ from 'lodash';

import { 
    FormControl,
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Select, 
    Checkbox, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField
} from '@material-ui/core';

import Sequential from 'promise-sequential';
//import T from 'i18n-react';

import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import Plan from '@apricityhealth/web-common-lib/components/Plan';

import { loadPlans, sendSlack } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const MAX_RETRIES = 5;          // how many times to make a request retry

const COLLECTIONS = [
    {
        name: "Alert Levels",
        get: "types/{{planId}}/alert_levels/*?&dependencies=false",
        delete: "types/{{planId}}/alert_levels/*?noChangeLog=true",
        post: "types/{{planId}}/alert_levels?noChangeLog=true"
    },
    {
        name: "Alert Types",
        get: "types/{{planId}}/alerts/*?&dependencies=false",
        delete: "types/{{planId}}/alerts/*?noChangeLog=true",
        post: "types/{{planId}}/alerts?noChangeLog=true"
    },
    {
        name: "Broadcast Groups",
        get: "types/{{planId}}/broadcast_groups/*?&dependencies=false",
        delete: "types/{{planId}}/broadcast_groups/*?noChangeLog=true",
        post: "types/{{planId}}/broadcast_groups?noChangeLog=true"
    },
    {
        name: "Conditions",
        limit: 1000,
        get: "types/{{planId}}/conditions/*?&dependencies=false&offset={{offset}}&limit={{limit}}",
        delete: "types/{{planId}}/conditions/*?noChangeLog=true",
        post: "types/{{planId}}/conditions?noChangeLog=true"
    },
    {
        name: "Configs",
        get: "types/{{planId}}/configs/*?&dependencies=false",
        delete: "types/{{planId}}/configs/*?noChangeLog=true",
        post: "types/{{planId}}/configs?noChangeLog=true"
    },
    {
        name: "Data Models",
        get: "types/{{planId}}/data_models/*?&dependencies=false",
        delete: "types/{{planId}}/data_models/*?noChangeLog=true",
        post: "types/{{planId}}/data_models?noChangeLog=true"
    },
    {
        name: "Data Types",
        limit: 1000,
        get: "types/{{planId}}/data/*?&dependencies=false&offset={{offset}}&limit={{limit}}",
        delete: "types/{{planId}}/data/*?noChangeLog=true",
        post: "types/{{planId}}/data?noChangeLog=true"
    },
    {
        name: "Detect Models",
        get: "types/{{planId}}/detect_models/*?&dependencies=false",
        delete: "types/{{planId}}/detect_models/*?noChangeLog=true",
        post: "types/{{planId}}/detect_models?noChangeLog=true"
    },
    {
        name: "Diagnose Models",
        get: "types/{{planId}}/diagnose_models/*?&dependencies=false",
        delete: "types/{{planId}}/diagnose_models/*?noChangeLog=true",
        post: "types/{{planId}}/diagnose_models?noChangeLog=true"
    },
    {
        name: "Education",
        get: "types/{{planId}}/education/*?&dependencies=false",
        delete: "types/{{planId}}/education/*?noChangeLog=true",
        post: "types/{{planId}}/education?noChangeLog=true"
    },
    {
        name: "Flags",
        get: "types/{{planId}}/flags/*?&dependencies=false",
        delete: "types/{{planId}}/flags/*?noChangeLog=true",
        post: "types/{{planId}}/flags?noChangeLog=true"
    },
    {
        name: "Followup Types",
        get: "types/{{planId}}/followups/*?&dependencies=false",
        delete: "types/{{planId}}/followups/*?noChangeLog=true",
        post: "types/{{planId}}/followups?noChangeLog=true"
    },
    {
        name: "Graphs",
        get: "types/{{planId}}/graphs/*?&dependencies=false",
        delete: "types/{{planId}}/graphs/*?noChangeLog=true",
        post: "types/{{planId}}/graphs?noChangeLog=true"
    },
    {
        name: "Medications",
        limit: 1000,
        get: "types/{{planId}}/medications/*?&dependencies=false&offset={{offset}}&limit={{limit}}",
        delete: "types/{{planId}}/medications/*?noChangeLog=true",
        post: "types/{{planId}}/medications?noChangeLog=true"
    },
    {
        name: "Models",
        get: "types/{{planId}}/models/*?&dependencies=false",
        delete: "types/{{planId}}/models/*?noChangeLog=true",
        post: "types/{{planId}}/models?noChangeLog=true"
    },
    {
        name: "Observation Rules",
        limit: 1000,
        get: "types/{{planId}}/observations/*?&dependencies=false&offset={{offset}}&limit={{limit}}",
        delete: "types/{{planId}}/observations/*?noChangeLog=true",
        post: "types/{{planId}}/observations?noChangeLog=true"
    },
    {
        name: "Predictor Instances",
        get: "apricity-forecast/{{planId}}/instances/*/*",
        delete: (self, env, collection, planId, data ) => {
            console.log("delete predictor instances:", env, collection, planId, data );
            return new Promise((resolve, reject) => {
                // we only want to delete predictor models that have actually been removed from the target, 
                // so load up those models and compare again the ones we will be saving.
                self.createRequest( env, collection, 'get', null, planId ).then((instances) => {
                    let promises = [];
                    for(let i=0;i<instances.length;++i) {
                        let instance = instances[i];
                        if ( !data.find((e) => e.predictorId === instance.predictorId && e.instanceId === instance.instanceId) ) {
                            promises.push( self.createRequest( env, { delete: `apricity-forecast/${planId}/instances/${instance.predictorId}/${instance.instanceId}`}, 'delete' ) );
                        }
                    }
                    return Promise.all(promises);
                }).then((results) => {
                    resolve(results);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        post: "apricity-forecast/{{planId}}/instances/*"
    },
    {
        name: "Predictor Models",
        get: "apricity-forecast/{{planId}}/predictor/*",
        delete: (self, env, collection, planId, data ) => {
            console.log("delete predictors:", env, collection, planId, data );
            return new Promise((resolve, reject) => {
                // we only want to delete predictor models that have actually been removed from the target, 
                // so load up those models and compare again the ones we will be saving.
                self.createRequest( env, collection, 'get', null, planId ).then((predictors) => {
                    let promises = [];
                    for(let i=0;i<predictors.length;++i) {
                        let predictor = predictors[i];
                        if ( !data.find((e) => e.predictorId === predictor.predictorId) ) {
                            promises.push( self.createRequest( env, { delete: `apricity-forecast/${planId}/predictor/${predictor.predictorId}`}, 'delete' ) );
                        }
                    }
                    return Promise.all(promises);
                }).then((results) => {
                    resolve(results);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        post: "apricity-forecast/{{planId}}/predictor"
    },
    {
        name: "ML Models",
        limit: 100,
        getField: 'models',
        get: "apricity-forecast/{{planId}}/ml/*?offset={{offset}}&limit={{limit}}",
        delete: "apricity-forecast/{{planId}}/ml/*?noChangeLog=true",
        post: "apricity-forecast/{{planId}}/ml?noChangeLog=true"
    },
    {
        name: "Procedures",
        limit: 1000,
        get: "types/{{planId}}/procedures/*?&dependencies=false&offset={{offset}}&limit={{limit}}",
        delete: "types/{{planId}}/procedures/*?noChangeLog=true",
        post: "types/{{planId}}/procedures?noChangeLog=true"
    },
    {
        name: "Questions",
        get: "dialog/questions/{{planId}}?&dependencies=false",
        getField: 'questions',
        delete: "dialog/questions/{{planId}}?noChangeLog=true",
        post: "dialog/questions/{{planId}}?noChangeLog=true"
    },
    {
        name: "Recommendation Models",
        get: "types/{{planId}}/recommend_models/*?&dependencies=false",
        delete: "types/{{planId}}/recommend_models/*?noChangeLog=true",
        post: "types/{{planId}}/recommend_models?noChangeLog=true"
    },
    {
        name: "Recommendation Types",
        get: "types/{{planId}}/recommendations/*?&dependencies=false",
        delete: "types/{{planId}}/recommendations/*?noChangeLog=true",
        post: "types/{{planId}}/recommendations?noChangeLog=true"
    },
    {
        name: "Recommendation Categories",
        get: "types/{{planId}}/recommend_categories/*?&dependencies=false",
        delete: "types/{{planId}}/recommend_categories/*?noChangeLog=true",
        post: "types/{{planId}}/recommend_categories?noChangeLog=true"
    },
    {
        name: "Recommendation Groups",
        get: "types/{{planId}}/recommend_group_types/*?&dependencies=false",
        delete: "types/{{planId}}/recommend_group_types/*?noChangeLog=true",
        post: "types/{{planId}}/recommend_group_types?noChangeLog=true"
    },
    {
        name: "Recommendation Protocols",
        get: "types/{{planId}}/recommend_protocols/*?&dependencies=false",
        delete: "types/{{planId}}/recommend_protocols/*?noChangeLog=true",
        post: "types/{{planId}}/recommend_protocols?noChangeLog=true"
    },
    {
        name: "Rights (Shared)",
        get: "authentication/rights",
        delete: "authentication/rights/*",
        post: "authentication/rights",
        shared: true
    },
    {
        name: "Rules",
        get: "rules/{{planId}}/*?&dependencies=false",
        getField: 'rules',
        delete: "rules/{{planId}}/*",
        post: "rules/{{planId}}"
    },
    {
        name: "Subscriptions (Shared)",
        getField: 'subscriptions',
        get: "apricity-cleanse/subscription",
        delete: "apricity-cleanse/subscription/*",
        post: "apricity-cleanse/subscription",
        shared: true
    },
    {
        name: "Test Creators",
        limit: 250,
        get: "test/creator/{{planId}}/*?offset={{offset}}&limit={{limit}}",
        delete: "test/creator/{{planId}}/*?cleanTests=false",
        post: "test/creator/{{planId}}"
    },
    {
        name: "Tests",
        limit: 250,
        get: "test/{{planId}}?full=true&offset={{offset}}&limit={{limit}}",
        getField: 'tests',
        delete: "test/{{planId}}/*",
        post: "test/{{planId}}"
    },
    {
        name: "Text",
        limit: 500,
        get: "content/text?planId={{planId}}&dependencies=false&offset={{offset}}&limit={{limit}}",
        getField: 'text',
        delete: "content/text/*?planId={{planId}}&language=*&noChangeLog=true",
        post: "content/text?noChangeLog=true"
    },
    {
        name: "Trials (Shared)",
        limit: 250,
        get: "trials/?offset={{offset}}&limit={{limit}}&showExpired=true",
        getField: 'trials',
        delete: "trials/*",
        post: "trials/",
        shared: true
    },
].sort((a,b) => a.name.localeCompare(b.name) );

class PromoteLoginDialog extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        this.state = {
            target: props.target,
            username: '',
            password: '',
            mfaRequired: false,
            mfaCode: '',
            disabled: false,
            error: null,
            device: query.get('device') || localStorage.getItem('device')
        }
    }

    onCancel() {
        this.props.onCancel();
    }

    onLogin() {
        const { username, password, target, device, mfaCode } = this.state;
        let targetEnv = Config.environments[target];

        const request = {
            url: Config.baseUrl + `${targetEnv.pathPrefix}anon/authentication/login`,
            method: 'POST',
            data: {
                "Username": username.trim(),
                "Password": password.trim(),
                "Group": "administrators",
                "device": device
            }
        };
        if ( mfaCode ) {
            request.data.mfaCode = mfaCode;
        }

        console.log("login request", request);
        this.setState({disabled: true});
        Axios(request).then((response) => {
            console.log("login response:", response);
            this.props.onLogin(response.data);
        }).catch((err) => {
            const errorCode = _.get(err, 'response.data.error');
            console.error("login error:", err, errorCode);
            if (errorCode === 'mfaRequired') {
                this.setState({disabled: false, mfaRequired: true});
            }
            else {
                this.setState({disabled: false, error: getErrorMessage(err)})
            }
        });
    }

    render() {
        const self = this;
        const { username, password, target, disabled, error, mfaRequired, mfaCode } = this.state;
        let targetEnv = Config.environments[target];

        return <Dialog open={true} disableEnforceFocus={true}>
            <DialogTitle>Login {targetEnv.name}</DialogTitle>
            <DialogContent>
                {mfaRequired ?
                    <TextField autoComplete="mfa-code" type="number" style={styles.input} disabled={disabled} id="mfaCode" label={'MFA Code'} value={mfaCode}
                        onChange={(e) => { this.setState({ mfaCode: e.target.value }); }}
                    /> :
                    <React.Fragment>
                        <TextField disabled={disabled} autoComplete="email" style={styles.input} autoFocus={true} id="username" label={'Email'} value={username}
                            onChange={(e) => { self.setState({ username: e.target.value }); }} />
                        <br />
                        <TextField disabled={disabled} autoComplete="current-password" style={styles.input} id="password" type="password" label={'Password'} value={password}
                            onChange={(e) => { self.setState({ password: e.target.value }); }}
                        />
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <span style={{color: 'red'}}>{error}</span>
                <Button disabled={disabled} variant='contained' onClick={this.onCancel.bind(this)}>Cancel</Button>
                <Button disabled={disabled} variant='contained' onClick={this.onLogin.bind(this)}>Login</Button>
            </DialogActions>
        </Dialog>        
    }
};

class PromoteView extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            checked: COLLECTIONS.map(() => true),
            source: Config.stage === 'local' ? 'develop' : Config.stage,
            target: (Config.stage === 'local' || Config.stage === 'develop') ? 'test' : Config.stage === 'test' ? 'demo' : 'production',
            planId: '*',
            idToken: null,
            refreshToken: null,
            dialog: null,
            promoting: false,
            progress: null,
            error: null,
            cancelled: false
        }
        console.log("state:", this.state );
    }

    onCloseDialog() {
        this.setState({dialog: null});
    }

    onPromoteData() {
        const { checked, source, target, planId } = this.state;
        let collections = [];
        for(let i=0;i<checked.length;++i) {
            if ( checked[i] ) collections.push( COLLECTIONS[i].name );
        }
        let sourceEnv = Config.environments[source].name;
        let targetEnv = Config.environments[target].name;

        let dialog = <Dialog open={true} onClose={this.onCloseDialog.bind(this)}>
            <DialogTitle>Confirm Data Promotion</DialogTitle>
            <DialogContent>
                <div>Please confirm the promotion of data from <b>{sourceEnv}</b> to <b>{targetEnv}</b>.</div>
                <br />
                <div><b>Plan:</b> <Plan appContext={this.props.appContext} planId={planId} /></div>
                <div><b>Collections:</b> {collections.join(',')}</div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button variant='contained' onClick={this.confirmPromoteData.bind(this)}>Confirm</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog, progress: [] });
    }

    confirmPromoteData() {
        const self = this;
        const { target } = this.state;

        let dialog = <PromoteLoginDialog target={target} 
            onCancel={() => {
                self.setState({dialog: null});
            }} 
            onLogin={(result) => {
                self.setState({ idToken: result.idToken}, self.startDataPromote.bind(self) );
            }}
        />;
        this.setState({dialog});
    }

    loadTargetPlans(planId) {
        const self = this;
        const { target, idToken } = this.state;
        let targetEnv = Config.environments[target];

        return new Promise((resolve, reject) => {
            if (!planId || planId === '*') planId = ''
            const request = {
                url: Config.baseUrl + `${targetEnv.pathPrefix}plans/${planId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            console.log(`loadTargetPlans request `, request);
            self.axiosRetry(request).then((response) => {
                console.log(`loadTargetPlans response:`, response);
                resolve(response.data.plans || [ response.data.plan ] );
            }).catch((error) => {
                console.log(`loadTargetPlans error `, error);
                reject(error);
            });
        });
    }
    
    addTargetPlan(plan) {
        const self = this;
        const { target, idToken } = this.state;
        let targetEnv = Config.environments[target];

        return new Promise((resolve, reject) => {
            const request = {
                url: Config.baseUrl + `${targetEnv.pathPrefix}plans/`,
                method: 'POST',
                headers: { "Authorization": idToken },
                data: plan
            }
            console.log(`addTargetPlan request `, request);
            self.axiosRetry(request).then((response) => {
                console.log(`addTargetPlan response:`, response);
                resolve(response.data);
            }).catch((error) => {
                console.log(`addTargetPlan error `, error);
                reject(error);
            });
        });
    }

    deleteTargetPlan(planId) {
        const self = this;
        const { target, idToken } = this.state;
        let targetEnv = Config.environments[target];

        return new Promise((resolve, reject) => {
            const request = {
                url: Config.baseUrl + `${targetEnv.pathPrefix}plans/${planId}`,
                method: 'delete',
                headers: { "Authorization": idToken }
            };

            console.log("deleteTargetPlan request:", request );
            self.axiosRetry( request ).then((result) => {
                console.log("deleteTargetPLan result:", result );
                resolve(result);
            }).catch((err) => {
                console.error("deleteTargetPlan error:", err );
                reject(err);
            })
        });
    }

    getPlanIds() {
        const self = this;
        const { planId } = this.state;
        return new Promise((resolve, reject) => {
            let plans = null;
            self.updateProgress("Loading plans...");
            loadPlans(this.props.appContext, planId).then((result) => {
                plans = result;
                console.log("plans:", plans );
                // load the plans from the target, remove any plans we load from the target that are not in the source
                self.updateProgress("Loading target plans...");
                return self.loadTargetPlans(planId);     
            }).then((result) => {
                let targetPlans = result;
                console.log("targetPlans:", targetPlans );

                // delete plans, only if planId is * and the target has a plan that is not in the source..
                if ( planId === '*' ) {
                    let promises = [];
                    for(let i=0;i<targetPlans.length;++i) {
                        let targetPlan = targetPlans[i];
                        let plan = plans.find((e) => e.planId === targetPlan.planId );
                        if (! plan ) {
                            promises.push( self.deleteTargetPlan( targetPlan.planId ));    
                        }
                    }
                    return Promise.all(promises);
                }
                else {
                    return Promise.resolve("skipped plan delete");
                }
            }).then((result) => {
                console.log("deleteTargetPlans result:", result );
                // now, re-add the plans from the source
                let promises = [];
                for(let i=0;i<plans.length;++i) {
                    promises.push( self.addTargetPlan( plans[i] ) );
                }
                self.updateProgress("Adding target plans...");
                return Promise.all(promises);
            }).then((result) => {
                console.log("addPlans result:", result );
                resolve(plans.map((e) => e.planId));
            }).catch((err) => {
                reject(err);
            })
        })
    }

    startDataPromote() {
        const self = this;
        const { checked, target } = this.state;
        const { appContext } = this.props;
        const { username } = appContext.state;

        console.log("startDataPromote");

        
        this.setState({dialog: null, promoting: true, cancelled: false, error: null });
        sendSlack(appContext, "testing", `${username} is promoting data to ${target}.`).then(() => {
            return this.getPlanIds();
        }).then((planIds) => {
            console.log("planIds:", planIds );

            let promises = [];
            for(let i=0;i<checked.length;++i) {
                if ( ! checked[i] ) continue;
                promises.push( () => self.promoteData(COLLECTIONS[i], planIds ) );
            }

            Sequential(promises).then(() => {
                console.log("Data Promotion done!");
                return sendSlack(appContext, "testing", `${username} is done promoting data to ${target}.`);
            }).then(() => {
                self.setState({progress: null, promoting: false});
            }).catch((err) => {
                console.error("Caught Error:", err );
                self.setState({progress: null, cancelled: true, promoting: false, error: <span style={{color: 'red'}}>ERROR: {getErrorMessage(err)}</span> });
            });
        }).catch((err) => {
            console.error("startDataPromote error:", err );
            self.setState({progress: null, promoting: false, error: <span style={{color: 'red'}}>ERROR: {getErrorMessage(err)}</span> });
        });
    }

    updateProgress( message ) {
        const self = this;
        if (! this.state.error ) {
            let progress = <Dialog open={true} maxWidth={'sm'} fullWidth={true}>
                <DialogContent>{message}</DialogContent>
                <DialogActions><Button variant='contained' onClick={() => {
                    self.setState({progress: null, cancelled: true, promoting: false });
                }}>Cancel</Button></DialogActions>
            </Dialog>;
            this.setState({progress});
        }
    }

    axiosRetry( request, retries = 0) {
        const self = this;
        return new Promise((resolve, reject) => {
            Axios( request ).then((result) => {
                resolve(result);
            }).catch((err) => {
                console.error("request error:", err );
                if ( retries < MAX_RETRIES ) {
                    setTimeout( () => {
                        self.axiosRetry( request, retries + 1 ).then((result) => {
                            resolve(result);
                        }).catch((err) => {
                            reject(err);
                        })
                    }, 1000)
                }
                else {
                    reject(err);
                }
            })
        })
    }

    createRequest( env, collection, method, data, planId ) {
        const self = this;
        const { appContext } = this.props;
        const { target } = this.state;
        let targetEnv = Config.environments[target];

        if ( typeof collection[method] === 'function') {
            return collection[method]( this, env, collection, planId, data );
        }

        function processRequest( data, offset = 0 ) {
            return new Promise((resolve, reject) => {
                let idToken = env.pathPrefix === targetEnv.pathPrefix ? self.state.idToken : appContext.state.idToken;
                let path = planId ? collection[method].replace(/{{planId}}/g, planId) : collection[method];
                path = path.replace(/{{offset}}/g, `${offset}` );
                if ( collection.limit > 0 )
                    path = path.replace(/{{limit}}/g, `${collection.limit}` );
                const request = {
                    url: Config.baseUrl + `${env.pathPrefix}${path}`,
                    method,
                    headers: { "Authorization": idToken }
                };
                if ( method === 'post' || method === 'put') {
                    if ( collection.limit !== undefined )
                        request.data = data.slice( offset, offset + collection.limit );
                    else if ( data.length > 1 )  
                        request.data = data;
                    else if ( data.length > 0 )
                        request.data = data[0];
                    else 
                        return resolve();       // no data to upload, so just resolve
                }
                console.log(`Request ${method}:`, request );
                self.axiosRetry( request ).then((result) => {
                    console.log(`Response ${method}:`, result );
                    if ( method === 'get') {
                        let getData = collection.getField !== undefined ? result.data[collection.getField] : result.data;
                        if ( Array.isArray(getData))
                            data.push( ...getData );
                        else
                            data.push( getData );
                        if ( collection.limit !== undefined && getData.length === collection.limit ) {
                            processRequest( data, offset + collection.limit ).then(resolve).catch(reject);    
                        } else {
                            resolve( data );
                        }
                    }
                    else if ( method === 'post' || method === 'put' ) {
                        if ( collection.limit !== undefined && (offset + collection.limit) < data.length ){
                            processRequest( data, offset + collection.limit).then(resolve).catch(reject);
                        } else {
                            resolve();
                        }
                    }
                    else { // method === 'delete'
                        resolve();
                    }
                }).catch(reject);
            })
        }

        console.log(`createRequest ${method}:`, data );
        return processRequest( data || [] );
    }

    batchRequest( env, collection, planIds, method, data ) {
        let promises = [];
        if ( collection.shared !== true ) {
            for(let i=0;i<planIds.length;++i) {
                promises.push( this.createRequest( env, collection, method, data ? data[i] : null, planIds[i] ));
            }
        } else {
            promises.push( this.createRequest( env, collection, method, data ? data[0] : null) );
        }
        return promises;
    }

    promoteData(collection, planIds) {
        const self = this;
        const { cancelled, target, source } = this.state;
        if ( cancelled )
            return Promise.reject(new Error("Promote cancelled."));

        console.log("promoteData:", collection, planIds );
        return new Promise((resolve, reject) => {
            let sourceEnv = Config.environments[source];
            let targetEnv = Config.environments[target];

            self.updateProgress(`Getting data from ${sourceEnv.name}/${collection.name} ...`);

            let data = [];
            Promise.all( self.batchRequest( sourceEnv, collection, planIds, 'get' )).then((results) => {
                console.log("get results:", results );
                for(let i=0;i<results.length;++i) {
                    let result = results[i];
                    if ( result === undefined ) {
                        return Promise.reject(new Error(`Failed to find field ${collection.getField} in data.`));
                    }
                    data.push( result );
                }

                if ( collection['delete']) {
                    self.updateProgress(`Delete data from ${targetEnv.name}/${collection.name} ...`);
                    return Promise.all( self.batchRequest( targetEnv, collection, planIds, 'delete', data ));
                }
                else {
                    return Promise.resolve();
                }
            }).then((results) => {
                console.log("delete results:", results );

                self.updateProgress(`Saving data to ${targetEnv.name}/${collection.name} ...`);
                return Promise.all( self.batchRequest( targetEnv, collection, planIds, 'post', data ) );
            }).then((results) => {
                console.log("post results:", results );
                resolve();
            }).catch((err) => {
                console.error("get error:", err );
                reject(err);
            });
        })
    }

    render() {
        const self = this;
        const { planId, source, target, checked, dialog, promoting, progress, error } = this.state;

        console.log(`planId: ${planId}, target: ${target}, progress:`, progress );

        let environments = [];
        for(let k in Config.environments) {
            if ( k === source ) continue;     // skip our current env
            let e = Config.environments[k];
            environments.push(<MenuItem key={k} value={k}>{e.name}</MenuItem>);
        }

        let selectEnvironment = <FormControl style={{width: 300, margin: 5}}>
            <InputLabel>Select Target Environment:</InputLabel>
            <Select value={target} onChange={(e) => {
                self.setState({target: e.target.value});
            }}>
                {environments}
            </Select>
        </FormControl>;

        let collections = [];
        let toggleAll = false;
        for(let i=0;i<COLLECTIONS.length;++i) {
            let collection = COLLECTIONS[i];
            toggleAll |= checked[i];
            collections.push( <FormControlLabel key={i} label={collection.name} control={
                <Checkbox checked={checked[i]} onChange={(e,v) => {
                    checked[i] = v;
                    self.setState({checked});
                }} /> } /> );
            if ( i > 0 && (i % 5) === 0 )
                collections.push( <br key={`br${i}`} /> );
        }
        collections.push( <FormControlLabel key='toggleAll' label={'Toggle All'} control={
            <Checkbox checked={toggleAll} onChange={(e,v) => {
                for(let i=0;i<checked.length;++i) checked[i] = v;
                self.setState({checked});
            }} /> } /> );

        return <div style={{margin: 15}}>
            {selectEnvironment}
            <FormControl style={{width: 300, margin: 5}}>
                <InputLabel>Promote Plan:</InputLabel>
                <SelectPlan appContext={this.props.appContext} style={{topMargin: 15}} enableAll={true} planId={planId} onChange={(plan) => {
                    console.log("onChange plan:", plan );
                    if ( plan ) {
                        self.setState({planId: plan.planId });
                    } else {
                        self.setState({planId: '*'});
                    }
                }} />
            </FormControl>
            <br />
            {collections}
            <br />
            <Button disabled={promoting} variant='contained' onClick={this.onPromoteData.bind(this)}>Promote Data</Button>
            <br />
            <br />
            {progress}
            {dialog}
            {error}
        </div>;
    }
};

const styles = {
    input: {
        width: 250
    }
}
export default PromoteView;
