import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import ProviderView from './ProviderView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import SelectOrg from "@apricityhealth/web-common-lib/components/SelectOrg";

import {
    getProviders
} from '@apricityhealth/web-common-lib/utils/Services';

export class ProvidersView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            data: [],
            orgId: null,
            showInactive: false,
            showUnvalidated: false
        }
    }

    componentWillMount() {
        console.log("componentWillMount:", window.location.pathname);
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            this.onViewProvider(parts[1]);
        }
    }

    loadContent({ offset, limit, order, orderBy }) {
        const { appContext } = this.props;
        return new Promise((resolve, reject) => {
            const { search, orgId, showInactive, showUnvalidated } = this.state;
            const query = { offset, limit, inactive: showInactive, validated: !showUnvalidated, order, orderBy};
            if ( orgId ) query.orgId = orgId;
            if ( search ) query.search = search;
            console.log("loadContent:", query );
            getProviders(appContext, query).then((result) => {
                let data = result.providers;
                let dataLength = result.dataLength;
                resolve({ data, dataLength });
            }).catch((err) => {
                console.log("loadProviders error:", err);
                reject(err);
            });
        })
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._table) {
            this._table.loadData();
            this._table.setState({ selected: [] });        // unselect the question
        }
    }

    providerSelected(s, t) {
        if (s.length > 0) {
            const provider = t.state.data[s[0]];
            this.onViewProvider( provider.providerId );
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onViewProvider(providerId) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <ProviderView appContext={this.props.appContext} providerId={providerId} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createProvider() {
        const { appContext } = this.props;
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <ProviderView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { dialog, error, progress, search, orgId, showInactive, showUnvalidated } = this.state;

        const columnData = [
            { id: 'providerId', label: 'Provider ID' },
            { id: 'NPI', label: 'NPI' },
            { id: 'firstName', label: 'First Name' },
            { id: 'lastName', label: 'Last Name' },
            {
                id: 'emails', label: 'Emails', formatValue: (v) => {
                    let result = "";
                    for (let i = 0; i < v.length; ++i) {
                        if ( v[i].email ) {
                            if (result) result += ",";
                            result += v[i].email;
                        }
                    }
                    return result;
                }
            },
            {
                id: 'phoneNumbers', label: 'Phone Numbers', formatValue: (v) => {
                    let result = "";
                    for (let i = 0; i < v.length; ++i) {
                        if (v[i].number) {
                            if (result) result += ",";
                            result += v[i].number;
                        }
                    }
                    return result;
                }
            },
            { id: "validationDate", label: "Validation Date" }
        ];

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td align='left'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextField style={{ margin: 5, width: 500 }} label='Search' value={search}
                            onChange={(e) => { self.setState({ search: e.target.value }) }}
                            onKeyDown={(e) => { if (e.keyCode === 13) self._table.loadData(); }} />
                        <SelectOrg
                            appContext={appContext}
                            enableAll={true}
                            orgId={orgId}
                            onChange={(orgId) => {
                                self.setState({ orgId }, () => {
                                    self._table.loadData();
                                });
                            }}
                        />
                        <FormControlLabel control={<Checkbox checked={showInactive}
                            onChange={(e, v) => {
                                this.setState({ showInactive: v }, () => this._table.loadData() );
                            }}
                        />} label="Show Inactive" />
                        <FormControlLabel control={<Checkbox checked={showUnvalidated}
                            onChange={(e, v) => {
                                this.setState({ showUnvalidated: v }, () => this._table.loadData() );
                            }}
                        />} label="Show Unvalidated" />
                    </div>
                </td>
                <td align='right'>
                    {error}
                </td>
            </tr>
            <tr>
                <td>{progress}</td>
            </tr>
            <tr><td colSpan={2}>
                <EnhancedTable disableMultiSelect={true}
                    onTable={table => self._table = table}
                    onSelected={(s, t) => self.providerSelected(s, t)}
                    onAdd={() => self.createProvider()}
                    orderBy='lastName'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={self.loadContent.bind(self)}
                    title='Providers' />
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default ProvidersView;
