import React, { useEffect, useMemo } from 'react';
import {
    useTheme,
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    ListItem,
    ListItemIcon,
    List
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
    Close,
    Warning
} from '@material-ui/icons';


import ReactJson from 'react-json-view';
import Chart from 'react-apexcharts';

import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import useAuthentication from '@apricityhealth/web-common-lib/hooks/useAuthentication';
import useLogging from '@apricityhealth/web-common-lib/hooks/useLogging';
import useReport, { RECIPES } from '@apricityhealth/web-common-lib/hooks/useReport';
import useJournals from '@apricityhealth/web-common-lib/hooks/useJournals';
import usePatients from '@apricityhealth/web-common-lib/hooks/usePatients';
import useOrgs from '@apricityhealth/web-common-lib/hooks/useOrgs';
import useProviders from '@apricityhealth/web-common-lib/hooks/useProviders';


import styles from './EmployeesPage.styles'
import chartData from './chart';



let DATA_ID_OPTIONS = [
    { value: 'iraeOverallAlert', label: 'IRAE Overall' },
    { value: 'covidAlert', label: 'Covid Alert' },
    { value: 'exposureAlert', label: 'Exposure Alert' }
]
let REPORT_NAME = "PatientMetrics";

let DAYS = '90';


Employee.propTypes = {
    classes: PropTypes.object.isRequired,
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

function Employee({ classes, employee, onClose }) {
    const theme = useTheme();

    let [{ data: patients, clearData: clearPatients, loading: patientsLoading, fetchPatients, error: patientsError }] = usePatients();
    let [{ data: providers, clearData: clearProviders, loading: providersLoading, fetchProviders, error: providersError }] = useProviders();
    let [{ data: consentedBy, clearData: clearConsentedBy, loading: consentedByLoading, fetchProviders: fetchConsentedBy, error: consentedByError }] = useProviders();
    let [{ data: journals, clearData: clearJournals, loading: journalsLoading, fetchJournals, error: journalsError }] = useJournals();
    let [{ data: orgs, clearData: clearOrgs, loading: orgsLoading, fetchOrgs, error: orgsError }] = useOrgs();
    let [{ data: account, clearData: clearAccount, loading: accountLoading, error: accountError, getAccount }] = useAuthentication();
    let [{ data: loginSuccessMetrics, clearData: clearLoginSuccessMetrics, loading: successMetricsLoading, fetchMetrics: fetchLoginSuccessMetrics, error: sucessMetricsError }] = useLogging(true, []);
    let [{ data: loginFailMetrics, clearData: clearLoginFailMetrics, loading: failMetricsLoading, fetchMetrics: fetchLoginFailMetrics, error: failMetricsError }] = useLogging(true, []);
    let [{ data: report, clearData: clearReport, loading: reportLoading, fetchReport, error: reportError }] = useReport();

    let loading = patientsLoading || providersLoading || journalsLoading || orgsLoading || accountLoading || successMetricsLoading || failMetricsLoading || reportLoading || consentedByLoading;

    let error = useMemo(() => {
        let err = [];
        if (patientsError) err.push(patientsError);
        if (providersError) err.push(providersError);
        if (journalsError) err.push(journalsError);
        if (orgsError) err.push(orgsError);
        if (accountError) err.push(accountError);
        if (sucessMetricsError) err.push(sucessMetricsError);
        if (failMetricsError) err.push(failMetricsError);
        if (reportError) err.push(reportError);
        if (consentedByError) err.push(consentedByError);

        return err;
    }, [patientsError, consentedByError, providersError, journalsError, orgsError, accountError, sucessMetricsError, failMetricsError, reportError])


    useEffect(() => {
        return () => {
            clearPatients()
            clearProviders()
            clearConsentedBy()
            clearJournals()
            clearOrgs()
            clearAccount()
            clearLoginSuccessMetrics()
            clearLoginFailMetrics()
            clearReport()
        }
    }, [onClose])

    useEffect(() => {
        if (employee && employee.patientId) {
            let patientId = employee.patientId;
            fetchPatients([patientId], null, { limit: 1 });
            if (patientId) {
                let reportArgs = {
                    patientId,
                    dataId: DATA_ID_OPTIONS.map(item => item.value).join(","),
                    startTime: Moment().subtract(DAYS, 'day').toISOString()
                };
                fetchReport({ reportName: REPORT_NAME, args: reportArgs }, RECIPES.JSON);
                fetchJournals([patientId], null, { limit: 1 });
            }
        }
    }, [employee.patientId, employee.employeeId, employee.orgId])

    useEffect(() => {
        if (employee && employee.orgId) {
            fetchOrgs([employee.orgId]);
        }
    }, [employee.patientId, employee.employeeId, employee.orgId])


    useEffect(() => {
        if (patients && patients.patient) {
            let patient = patients.patient;
            if (patient.userId)
                getAccount(patient.userId)
            if (patient.primaryProviderId)
                fetchProviders([patient.primaryProviderId])
            if (patient.consentGivenBy)
                fetchConsentedBy([patient.consentGivenBy])
        }
    }, [patients])

    useEffect(() => {
        if (account && account.user) {
            let user = account.user;
            let loginSuccessArgs = [];
            loginSuccessArgs.push("EventName=LoginSuccess");
            loginSuccessArgs.push(`UserName=${encodeURIComponent(user.email)}`);
            loginSuccessArgs.push("limit=1");
            fetchLoginSuccessMetrics(null, loginSuccessArgs);

            let loginFailArgs = [];
            loginFailArgs.push("EventName=LoginFailure");
            loginFailArgs.push(`UserName=${encodeURIComponent(user.email)}`);
            loginFailArgs.push("limit=1");
            fetchLoginFailMetrics(null, loginFailArgs);
        }
    }, [account])

    let patient = useMemo(() => {
        return patients?.patient;
    }, [patients, employee])

    let provider = useMemo(() => {
        return providers?.provider;
    }, [providers, employee])

    let consentedByProvider = useMemo(() => {
        return consentedBy?.provider;
    }, [consentedBy, employee])

    let org = useMemo(() => {
        if (orgs && orgs.org)
            return orgs.org;
    }, [orgs, employee])

    let journal = useMemo(() => {
        if (journals && Array.isArray(journals.records))
            return journals.records[0];
    }, [journals, employee])

    let patientTotals = useMemo(() => {
        if (report && report.series)
            return report.series.patientTotals
    }, [report, employee])

    let user = useMemo(() => {
        if (account && account.user)
            return account.user;
    }, [account, employee])

    let recentLoginSuccess = useMemo(() => {
        if (loginSuccessMetrics && isArrayValid(loginSuccessMetrics.metrics))
            return loginSuccessMetrics.metrics[0]
    }, [loginSuccessMetrics, employee])

    let recentLoginFail = useMemo(() => {
        if (loginFailMetrics && isArrayValid(loginFailMetrics.metrics))
            return loginFailMetrics.metrics[0]
    }, [loginFailMetrics, employee])





    const employeeTable = useMemo(() => {
        function getRow(text, value) {
            return <TableRow key={text}>
                <TableCell>{text}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow >
        }
        let rows = []
        if (employee) {
            rows.push(getRow("Employee Id", employee["employeeId"]));
            rows.push(getRow("Patient Id", employee["patientId"]));
            rows.push(getRow("DOB", employee["dob"]));
            rows.push(getRow("First Name", employee["firstName"]));
            rows.push(getRow("Last Name", employee["lastName"]));
            rows.push(getRow("Location", employee["location"]));
            rows.push(getRow("Status", employee["status"]));
            rows.push(getRow("Department", employee["department"]));
            rows.push(getRow("Supervisor", employee["supervisor"]));

            rows.push(getRow("Org Id", org && org["orgId"]));
            rows.push(getRow("Org Name", org && org["name"]));

            rows.push(getRow("Provider Id", provider && provider["providerId"]));

            rows.push(getRow("Consented By Id", consentedByProvider && consentedByProvider["providerId"]));

            rows.push(getRow("User Status", user && user["userStatus"]));
            rows.push(getRow("User Groups", Array.isArray(user?.groups) && user.groups.join(",")));


            rows.push(getRow("Most Recent Journal", journal && Moment(journal.createDate).local().format("MM-DD-YYYY HH:mm")));
            rows.push(getRow("Most Recent Journal Status", journal && journal.eod ? "eod" : journal && journal.bail ? "bail" : ""));

            rows.push(getRow(`# Completed Check-Ins (${DAYS} days)`, patientTotals && patientTotals["completeCheckIn"]));
            rows.push(getRow(`# In-Complete Check-Ins (${DAYS} days)`, patientTotals && patientTotals["inCompleteCheckIn"]));
            rows.push(getRow(`# Baselines (${DAYS} days)`, patientTotals && patientTotals["baselineCheckIn"]));
            rows.push(getRow(`# Bailed (${DAYS} days)`, patientTotals && patientTotals["bailedCheckIn"]));
            patientTotals?.mostRecentAlertData && Object.keys(patientTotals.mostRecentAlertData).forEach((alert) => {
                let alertName = DATA_ID_OPTIONS.find(item => item.value === alert).label;
                let data = patientTotals.mostRecentAlertData[alert][0]
                rows.push(getRow(`Most recent ${alertName}`, `${Moment(data.eventTime).local().format("LLL")}; ${data.data[0]}`));
            });

            rows.push(getRow("Recent Successful Login", recentLoginSuccess && Moment(recentLoginSuccess["createDate"]).local().format("MM-DD-YYYY HH:mm")));
            rows.push(getRow("Recent Failed Login", recentLoginFail && Moment(recentLoginFail["createDate"]).local().format("MM-DD-YYYY HH:mm")));
        }
        return <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell >Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>;

    }, [employee, patient, user, provider, consentedBy, journal, org, patientTotals, recentLoginFail, recentLoginSuccess])

    let warnings = useMemo(() => {
        function getListItem(text) {
            return <ListItem key={text}><ListItemIcon><Warning className={classes.warningSign} /></ListItemIcon><Typography className={classes.warning} variant="subtitle2">{text}</Typography> </ListItem>
        }

        let list = [];

        if (!employee)
            list.push(getListItem(">No employee selected"));

        if (employee && employee.orgId && !org)
            list.push(getListItem("Employee assigned to an org that does not exist"));

        if (employee && !employee.orgId)
            list.push(getListItem("Employee is not assigned to any org"));

        if (employee && !employee.patientId)
            list.push(getListItem("This employee is not currently registered"));

        if (employee && employee.patientId && (!account || !patient))
            list.push(getListItem("This employee was registered but the user account does not exist"));

        if (employee && patient) {
            let employeeId = employee.employeeId;
            let patientId = patient.patientIds.find(id => id.systemId === employeeId);
            if (!patientId)
                list.push(getListItem("Warning: Patient record systemId does not match employeeId"));
        }

        if (!recentLoginSuccess && recentLoginFail)
            list.push(getListItem("This user has no login history"));

        if (!journal)
            list.push(getListItem("This employee has no journals"));

        if (!patientTotals || !Object.keys(patientTotals?.mostRecentAlertData))
            list.push(getListItem("This employee has no alert data"));

        if (employee && employee.status !== 'active')
            list.push(getListItem("This employee is not marked active in the roster"));

        if (employee && provider)
            list.push(getListItem("This employee is also a provider"));

        return <List dense>{list}</List>;
    }, [employee, provider, patient, account, journal, patientTotals])



    let totalsChart = useMemo(() => {
        let tiles = [];
        if (patientTotals && patientTotals.dataTotals) {
            let dataTotals = patientTotals?.dataTotals;
            //we want irae to be first so just use the id list
            if (dataTotals) {
                DATA_ID_OPTIONS.forEach(option => {
                    let dataChart = JSON.parse(JSON.stringify(chartData))
                    let categories = [];
                    let series = [];
                    let dataId = option.value;
                    let name = option.label;
                    dataChart.options.title.text = name;
                    let alertData = dataTotals[dataId];
                    if (alertData) {
                        let data = []
                        let colors = [];
                        Object.keys(alertData).forEach(alert => {
                            categories.push(alertData[alert].name);
                            data.push(alertData[alert].count);
                            colors.push(alertData[alert].color)
                        });
                        series.push({ data });
                        dataChart.options.colors = colors;
                        dataChart.options.xaxis.categories = categories;
                        tiles.push(<Chart
                            key={dataId}
                            options={dataChart.options}
                            series={series}
                            type="bar"
                            height={150}
                            width={200} />)
                    }
                })
            }
        }

        return tiles
    }, [patientTotals]);


    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => onClose()}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" >Employee Details</Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.root}>

                <div className={classes.box}>
                    {!loading && warnings}
                    {loading && <Loading />}
                    {error && <Error>{error.join(", ")}</Error>}
                    {employeeTable}
                    <Typography variant="subtitle2">Note: Metrics go back {DAYS} days</Typography>
                    <div className={classes.row}>
                        {totalsChart}
                    </div>
                    <div className={classes.jsonPaper}>
                        <ReactJson
                            theme={theme.overrides.ReactJsonView}
                            src={{ employee, patient, provider, consentedByProvider, journal, org, user, patientTotals, recentLoginSuccess, recentLoginFail }}
                            className={classes.json}
                            collapsed={1}
                            name="JSONs"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />

                    </div>
                </div>
            </div>
        </>
    )


}
export default withStyles(styles)(Employee)