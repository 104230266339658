import React, { useEffect, useState, useMemo } from 'react';
import {
    withStyles, CircularProgress, Tooltip,

} from '@material-ui/core/';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
    Error,
    Warning,
    CheckCircle,
    HourglassEmpty
} from '@material-ui/icons';

import { getProviderPromise } from '@apricityhealth/web-common-lib/components/Provider';
import { getPatientPromise } from '@apricityhealth/web-common-lib/components/Patient';
import { getUserPromise } from '@apricityhealth/web-common-lib/components/User';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils'
import {
    isArrayValid,
    getDeletedPatient
} from '@apricityhealth/web-common-lib/utils/Services';
import styles from './DirectMessageAttachmentsPage.styles'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

DMAValidation.propTypes = {
    classes: PropTypes.object.isRequired,
    attachment: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

function DMAValidation({ classes, appContext, attachment }) {
    const [provider, setProvider] = useState(null);
    const [patient, setPatient] = useState(null);
    const [patientUser, setPatientUser] = useState(null);
    const [providerUser, setProviderUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deletedPatient, setDeletedPatient] = useState(null);


    useEffect(() => {
        internalRefresh();
    }, [attachment?.attachmentId])

    async function internalRefresh() {
        if (attachment) {
            try {
                setLoading(true);
                setDeletedPatient(null);
                if (attachment.patientId) {
                    let [patient, deletedPatient] = await Promise.all([
                        getPatientPromise(appContext, attachment.patientId),
                        getDeletedPatient(appContext, attachment.patientId)
                    ]);
                    if (isArrayValid(deletedPatient))
                        setDeletedPatient(deletedPatient[0]);
                    if (patient?.patient) patient = patient.patient;
                    setPatient(patient)
                    if (patient) {
                        if (patient.primaryProviderId) {
                            let provider = await getProviderPromise(appContext, patient.primaryProviderId);
                            if (provider?.provider) provider = provider.provider;
                            setProvider(provider)
                            if (provider.userId) {
                                let account = await getUserPromise(appContext, provider.userId)
                                setProviderUser(account)
                            }
                        }
                        if (patient.userId) {
                            let account = await getUserPromise(appContext, patient.userId)
                            setPatientUser(account)
                        }
                    }
                }
            } catch (error) {
                console.error(getErrorMessage(error))
            }
        }
        setLoading(false)
    }


    let busy = useMemo(() => {
        let list = [];
        if (attachment) {
            if (attachment.state === "Error") {
                return list;
            }
            if (!attachment.state && !attachment.error && !toBoolean(attachment.isProcessing) && !toBoolean(attachment.processed)) {
                list.push(`This attachment has not started processing`);
                return list;
            }
            let createdAt = attachment.createdAt;
            let updatedAt = attachment.updatedAt;
            if (attachment.state === "State cleared waiting to get picked up") {
                list.push(`This attachment is waiting in line`);
                return list;
            }
            if (attachment.state === "Waiting in line") {
                list.push(`This attachment is waiting in line`);
                return list;
            }
            if (Moment(updatedAt).isSame(Moment(createdAt))) {
                list.push(`This attachment has not started processing`);
                return list;
            }
            if (attachment && (toBoolean(attachment.isProcessing))) {
                list.push(`This attachment is still processing`);
                return list;
            }
        }
        return list;
    }, [attachment])

    let errors = useMemo(() => {
        let list = [];
        if (attachment) {
            if (attachment && attachment.error) {
                list.push(attachment.error);
                return list;
            }
        }


        return list;
    }, [attachment])

    let warnings = useMemo(() => {
        let list = [];

        if (patient && !patientUser) {
            if (patient.userId)
                list.push("This patient user account was deleted");
            else
                list.push("This patient user account has not been created");
            return list;
        }
        if (deletedPatient) {
            list.push("This patient was deleted");
        }

        if (!provider) {
            list.push("The provider has not been created");
            return list;
        }

        if (!providerUser) {
            list.push("The provider user has not been created");
            return list;
        }

        if (attachment && !toBoolean(attachment.processed))
            list.push("This attachment did not finish processing");

        return list;
    }, [attachment, provider, patient, providerUser, patientUser, deletedPatient])

    let isBusy = busy.length > 0;
    let isError = errors.length > 0;
    let isWarning = warnings.length > 0;
    return (
        <>
            {loading ? <CircularProgress size={10} /> : ""}
            {!loading && isBusy ? <HtmlTooltip title={busy.map(element => <li key={element}>{element}</li>)}><HourglassEmpty color="primary" /></HtmlTooltip> : ""}
            {!loading && !isBusy && isError ? <HtmlTooltip title={errors.map(element => <li key={element}>{element}</li>)}><Error className={classes.errorSign} /></HtmlTooltip> : ""}
            {!loading && !isBusy && !isError && isWarning ? <HtmlTooltip title={warnings.map(element => <li key={element}>{element}</li>)}><Warning className={classes.warningSign} /></HtmlTooltip> : ""}
            {!loading && !isBusy && !isError && !isWarning && <CheckCircle className={classes.greenSign} />}
        </>
    )
}



const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default withStyles(styles)(DMAValidation)