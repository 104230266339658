
import React, { useState, useEffect, useMemo } from 'react';
import {
    useTheme,
    Tabs,
    Badge,
    Tab,
    withStyles,
    Drawer,
    IconButton,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField
} from '@material-ui/core/';
import {
    ClearAllOutlined,
    Refresh
} from '@material-ui/icons';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';

import Error from '@apricityhealth/web-common-lib/components/Error';
import Org from '@apricityhealth/web-common-lib/components/Org';
import useData from '@apricityhealth/web-common-lib/hooks/useData';
import useReport, { RECIPES } from '@apricityhealth/web-common-lib/hooks/useReport';
import usePatients from '@apricityhealth/web-common-lib/hooks/usePatients';

import styles from './EmployeesPage.styles'
import Employee from './Employee';



function EmployeesPage({ classes, appContext }) {
    const theme = useTheme();
    const [tab, setTab] = useState(0);
    const [employees, setEmployees] = useState([])
    const [rosterDuplicates, setRosterDuplicates] = useState([])
    const [showEmployee, setShowEmployee] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [selectionModel, setSelectionModel] = useState([]);
    const [searchField, setSearchField] = useState('employeeId');
    const [filter, setFilter] = useState("");
    const [searchValue, setSearchValue] = useState("");


    let [data, { loading: rosterLoading, fetchData, error: rosterError }] = useData("orgs/*/roster", []);
    let [{ data: patients, loading: patientsLoading, fetchPatients, error: patientsError }] = usePatients();
    let [{ data: report, loading: reportLoading, fetchReport, error: reportError }] = useReport();

    let loading = rosterLoading || reportLoading || patientsLoading

    let error = useMemo(() => {
        let err = [];
        if (rosterError) err.push(rosterError);
        if (reportError) err.push(reportError);
        if (patientsError) err.push(patientsError);
        return err;
    }, [rosterError, reportError, patientsError])


    function employeeSelected(employee) {
        if (employee) {
            setSelectedEmployee(employee);
            setSelectionModel([employee.id])
            setShowEmployee(true);
        }
    }

    const columns = useMemo(() => {
        return [
            { field: 'employeeId', headerName: 'Employee', width: 300, },
            { field: 'patientId', headerName: 'Patient ID', width: 300, },
            {
                field: 'orgId', headerName: 'Org', width: 300, renderCell: (params) => {
                    let v = params.value;
                    return <Org appContext={appContext}  orgId={v} />
                },
            },
            { field: 'firstName', headerName: 'First Name', width: 200, },
            { field: 'lastName', headerName: 'Last Name', width: 200, },
            { field: 'status', headerName: 'Status', width: 125, },
            { field: 'dob', headerName: 'DOB', width: 125, },
            { field: 'location', headerName: 'Location', width: 135, },
        ]
    }, []);

    const searchOptions = useMemo(() => {
        return [
            <MenuItem key='employeeId' value='employeeId'>Employee Id</MenuItem>,
            <MenuItem key='name' value='name'>Name</MenuItem>,
            <MenuItem key='status' value='status'>Status</MenuItem>,
            <MenuItem key='dob' value='dob'>DOB</MenuItem>,
            <MenuItem key='location' value='location'>Location</MenuItem>,
            <MenuItem key='department' value='department'>Department</MenuItem>,
            <MenuItem key='supervisor' value='supervisor'>Supervisor</MenuItem>,
            <MenuItem key='orgId' value='orgId'>Org Id</MenuItem>,
            <MenuItem key='patientId' value='patientId'>Patient Id</MenuItem>,
            <MenuItem key='userId' value='userId'>User Id</MenuItem>
        ]
    }, []);

    useEffect(() => {
        if (data) {
            let roster = data.roster;
            if (Array.isArray(roster)) {
                roster.forEach(element => {
                    element.id = element._id;
                });
                roster = filterRoster(filter, roster)
                setEmployees(roster);
            }
        }
    }, [data, filter])

    useEffect(() => {
        if (report && report.series) {
            let roster = report.series;
            if (Array.isArray(roster)) {
                roster.forEach(element => {
                    element.id = element._id;
                });
                roster = filterRoster(filter, roster)
                setRosterDuplicates(roster);
            }


        }
    }, [report])

    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])

    useEffect(() => {
        if (patients && Array.isArray(patients.patients)) {
            let patient = patients.patients[0];
            let employeeId = patient.patientIds.map(patientId => patientId.systemId);
            let args = []
            args.push(`searchField=employeeId`);
            args.push(`searchValue=${encodeURIComponent(employeeId.join(","))}`);
            fetchData(null, null, args);
        }
    }, [patients])


    function internalFetchData() {
        try {
            let args = []
            if (searchValue) {
                if (searchField === "userId" && searchValue) {
                    fetchPatients(null, [`userId=${searchValue}`]);
                } else {
                    args.push(`searchField=${searchField}`);
                    args.push(`searchValue=${encodeURIComponent(searchValue)}`);
                }
            }

            fetchData(null, null, args, { limit: 1000 });
            fetchReport({ reportName: "RosterDuplicates", args: { orgId: "*" } }, RECIPES.JSON);

        } catch (error) {
            console.error(`Error fetching metrics data `, error)
        }
    }

    function onClose() {
        setShowEmployee(false)
        setSelectionModel([]);
    }

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <Tabs
                    variant="scrollable"
                    // scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    value={tab}
                    onChange={(e, t) => setTab(t)}>
                    <Tab style={{ marginTop: "10px" }} label="Employees" />
                    <Tab label={<Badge style={{ right: 5, top: 5 }} badgeContent={rosterDuplicates && (rosterDuplicates.length / 2)} color='error'>
                        Roster Duplicates
                    </Badge>} />

                </Tabs>

                {tab === 0 && <>
                    <div className={classes.row}>
                        <div className={classes.search}>
                            <FormControl className={classes.text} >
                                <InputLabel>Search By:</InputLabel>
                                <Select
                                    value={searchField}
                                    onChange={(e) => setSearchField(e.target.value)}
                                >
                                    {searchOptions}
                                </Select>
                            </FormControl>
                            <TextField className={classes.text} label="Search" value={searchValue}
                                onKeyDown={(e) => { if (e.key === "Enter") internalFetchData() }}
                                onChange={(e) => setSearchValue(e.target.value)} />
                            <TextField className={classes.text} label="Filter" value={filter}
                                onKeyDown={(e) => { if (e.key === "Enter") internalFetchData() }}
                                onChange={(e) => setFilter(e.target.value)} />
                            <IconButton
                                onClick={() => {
                                    setSearchField("employeeId")
                                    setFilter("");
                                    setSearchValue("");
                                    internalFetchData();
                                }}><ClearAllOutlined color="primary" /></IconButton>
                        </div>
                        <div className={classes.refresh}>
                            <IconButton
                                color="primary"
                                style={{ marginRight: '2rem' }}
                                onClick={() => internalFetchData()}
                            >
                                {loading ? <Loading /> : <Refresh />}
                            </IconButton>
                        </div>
                    </div>
                    {error && <Error>{error.join(", ")}</Error>}
                    <div style={{ flexGrow: 1, width: "100%", paddingRight: "2rem" }}>
                        {employees && <DataGrid
                            className={classes.root}
                            pagination
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            selectionMode="Single"
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            style={{ backgroundColor: theme.palette.background.paper }}
                            rowsPerPageOptions={[10, 25, 50]}
                            selectionChange={(event) => console.log(`selection`, event)}
                            autoHeight
                            selectionModel={selectionModel}
                            checkboxSelection={true}
                            rows={employees}
                            columns={columns}
                            onChange={(e) => console.log(`on change `, e)}
                            onRowClick={({ row }) => employeeSelected(row)}
                        />}
                    </div>
                </>}

                {tab === 1 && <>
                    <div style={{ flexGrow: 1, width: "100%", paddingRight: "2rem" }}>
                        {rosterDuplicates && <DataGrid
                            className={classes.root}
                            pagination
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            selectionMode="Single"
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            style={{ backgroundColor: theme.palette.background.paper }}
                            rowsPerPageOptions={[10, 25, 50]}
                            selectionChange={(event) => console.log(`selection`, event)}
                            autoHeight
                            selectionModel={selectionModel}
                            checkboxSelection={true}
                            rows={rosterDuplicates}
                            columns={columns}
                            onChange={(e) => console.log(`on change `, e)}
                            onRowClick={({ row }) => employeeSelected(row)}
                        />}
                    </div>

                </>}
                <Drawer variant="persistent" anchor="right" open={showEmployee}>
                    <Employee employee={selectedEmployee} onClose={() => onClose()} />
                </Drawer>
            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

function filterRoster(filter, roster) {
    let filtered = [];
    if (filter) {
        for (var i = 0; i < roster.length; ++i) {
            if (testFilter(filter, roster[i]))
                filtered.push(roster[i]);
        }
    } else {
        filtered = roster
    }
    return filtered;
}



function testFilter(filter, item) {
    if (item) {
        filter = filter.toUpperCase();
        if (item.employeeId && item.employeeId.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.patientId && item.patientId.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.department && item.department.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.location && item.location.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.supervisor && item.supervisor.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.firstName && item.firstName.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.lastName && item.lastName.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.status && item.status.toUpperCase().indexOf(filter) >= 0)
            return true;
        if (item.orgId && item.orgId.toUpperCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}


export default withStyles(styles)(EmployeesPage)