import React, { useState, useEffect, useMemo } from 'react';
import {
    useTheme,
    withStyles,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    TextField
} from '@material-ui/core/';
import {
    ClearAllOutlined,
    Refresh,
    Code
} from '@material-ui/icons';
import _ from 'lodash';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { isArrayValid, loadDataTypes } from '@apricityhealth/web-common-lib/utils/Services';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import useData from '@apricityhealth/web-common-lib/hooks/useData';
import Patient from '@apricityhealth/web-common-lib/components/Patient'
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import PatientActivity from './PatientActivity';
import styles from './RecentActivityPage.styles'

function LabsPage({ classes, patientId, appContext }) {
    const theme = useTheme();
    const [validationLimit, setValidationLimit] = useState(2)
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [totalCount, setTotalCount] = useState(500);
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'eventTime', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));

    const [validationText, setValidationText] = useState(localStorage.getItem("validationText") || "normalized");
    const [showLab, setshowLab] = useState(false);
    const [selectedLab, setSelectedLab] = useState({});
    const [selectedLabType, setSelectedLabType] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [labs, setLabs] = useState([])
    const [labTypes, setLabTypes] = useState([])
    const [showCode, setShowCode] = useState(false);
    let [data, { loading, fetchData, error }] = useData("patients/", []);



    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])

    async function internalFetchData() {
        try {
            let suffix = `*/data`;
            if (patientId) {
                suffix = `${patientId}/data`;
            }
            let args = []
            if (searchValue) {
                args.push(`dataId=${encodeURIComponent(searchValue)}`);
            } else {
                args.push(`category=labTest`);
            }
            fetchData(suffix, null, args, { offset: page * pageSize, limit: pageSize });

            let allLabTypes = await loadDataTypes(appContext, { category: "labTest", planId: "*", dependencies: true });
            setLabTypes(allLabTypes);

        } catch (error) {
            console.error(`Error fetching data `, error)
        }
    }

    useEffect(() => {
        if (data) {
            let labs = data.data;
            let total = data.totalData;
            setTotalCount(total);
            if (Array.isArray(labs)) {
                labs.forEach(element => {
                    element.id = element._id;
                });
                labs = _.sortBy(labs, [(item) => new Date(item.eventTime), (item) => item.dataId]).reverse();
                setLabs(labs);
            }
        }
    }, [data])


    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize, patientId])

    // Auto save the validation text when it changes
    useEffect(() => {
        internalValidate();
    }, [validationText])

    function internalValidate() {
        let validationTextArray = validationText.toLowerCase().split(",");
        labs.forEach((lab) => {
            //find the lab type
            lab.isOutOfNorm = false; //set them all to false
            let type = labTypes.find((t) => t.dataId === lab.dataId);
            if (type) {
                let tupleDesc = type.tupleDescriptions;
                if (isArrayValid(tupleDesc)) {
                    let normalizedTupes = tupleDesc.filter((d) => validationTextArray.find((t) => {
                        return t === d.description.toLowerCase();
                    }));
                    if (isArrayValid(normalizedTupes)) {
                        normalizedTupes.forEach((normalizedTupe) => {
                            let index = normalizedTupe.index;
                            let data = lab.data;
                            if (isArrayValid(data)) {
                                if (Math.abs(Number(data[index])) > Number(validationLimit))
                                    lab.isOutOfNorm = true;
                            }
                        })
                    }
                }
            }
        })
        setLabs(labs);
    }

    useEffect(() => {
        internalValidate();
    }, [labTypes, validationLimit, labs])

    useEffect(() => {
        if (searchValue === "") //Only do this if there is no search value.. we manually call search when teh enter key is pressed
            internalFetchData();
    }, [searchValue])

    const columns = useMemo(() => {
        return [
            {
                field: 'patientId', headerName: 'Patient', width: 300, renderCell: (params) => {
                    let v = params.value;
                    return <Patient patientId={v} />
                },
            },
            {
                field: 'eventTime', headerName: 'Event Time',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'dataId',
                headerName: 'Data Id',
                width: 200,

            },
            {
                field: 'data',
                headerName: 'Lab',
                width: 300,
                renderCell: (params) => {
                    let v = params.value;
                    return Array.isArray(v) ? v.join(", ") : v
                }
            },
            {
                field: 'source',
                headerName: 'Provider',
                width: 150,
                renderCell: (params) => {
                    let v = params.value;
                    if (isArrayValid(v)) {
                        let cro = v.find(source => source.userType === "provider");
                        if (cro) return <Provider userId={cro.userId} />
                    }
                    return "";

                }
            },

        ]
    }, []);

    function labSelected(lab) {
        if (lab) {
            setSelectedLab(lab);
            setSelectionModel([lab.id])
            setshowLab(true);
            let type = labTypes.find((t) => t.dataId === lab.dataId);
            if (type)
                setSelectedLabType(type)
            else
                setSelectedLabType(null)
        }
    }

    function onClose() {
        setshowLab(false)
        setSelectionModel([]);
    }

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                        <TextField className={classes.validationLimit}
                            label="Validation Limit"
                            value={validationLimit}
                            helperText="Max ABS normalized value"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "0.1"
                            }}
                            onKeyDown={(e) => { if (e.key === "Enter") internalValidate() }}
                            onChange={(e) => {
                                setValidationLimit(e.target.value)
                            }}
                        />
                        <TextField
                            className={classes.validationText}
                            multiline
                            rowsMax={5}
                            helperText="Comma delimited list of tuple descriptions to use for normalized validation"
                            label="Tuple description text(s)"
                            value={validationText}
                            onKeyDown={(e) => { if (e.key === "Enter") internalValidate() }}
                            onChange={(e) => setValidationText(e.target.value.toLowerCase())} />
                        <TextField className={classes.text} label="Search for dataId(s)" value={searchValue}
                            onKeyDown={(e) => { if (e.key === "Enter") internalFetchData() }}
                            onChange={(e) => setSearchValue(e.target.value)}
                            helperText="Comma delimited list of dataId"
                        />
                        <IconButton
                            onClick={() => {
                                setSearchValue("")
                            }}><ClearAllOutlined color="primary" /></IconButton>
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {labs && <DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}

                        rowCount={totalCount} //should be the total count from the query if there is one
                        rows={labs}
                        onRowClick={({ row }) => labSelected(row)}

                        getRowClassName={(params) => {
                            let row = params.row;
                            return row.isOutOfNorm ? "red" : ""
                        }}

                    />}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Labs</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={{ labs, labTypes }}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Labs"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showLab}>
                    <PatientActivity lab={{ lab: selectedLab, type: selectedLabType }} onClose={() => onClose()} />
                </Drawer>

            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(LabsPage)