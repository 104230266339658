import React, { Component } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import Trial from '@apricityhealth/web-common-lib/components/Trial';
import SelectTrial from '@apricityhealth/web-common-lib/components/SelectTrial';

import {
    arrayRemoveLocation
} from '../../utils/Utils';

class OrgTrialsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trialIds: props.trialIds,
            modalOpen: false,
            editIndex:0,
            dialogTrial: { trialId: '', default: false },
            create:false
        }
    }

    //on mount, fetch all trials

    editTrial = (event, index) => {
        let { trialIds } = this.state;
        this.setState({create: false, dialogTrial: JSON.parse(JSON.stringify(trialIds[index])), editIndex: index, modalOpen:true})
    }


    handleOnAdd = () => {
        this.setState({create: true, dialogTrial: { trialId: '', default: false }, modalOpen:true})
    }
    onCancel(){
        this.setState({ modalOpen:false })
    }

    onDone(){
        let {trialIds, editIndex, dialogTrial, create} = this.state
        if (create){
           trialIds.push(dialogTrial) 
        }else{
           trialIds[editIndex] = dialogTrial;
           // if setting a default trial, make sure all other trials are off
           if ( dialogTrial.default ) {
               for(let i=0;i<trialIds.length;++i) {
                   if ( i !== editIndex ) trialIds[i].default = false;
               }
           }
        }
        this.setState({ trialIds, modalOpen:false })
    }

    deleteTrial(evt, trialIndex) {
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }
        let { trialIds, editIndex } = this.state;
        var delIndex 
        if (trialIndex !== undefined){
            delIndex = trialIndex 
        }else{
            delIndex = editIndex
        }
        if (delIndex > -1) {
            arrayRemoveLocation(trialIds, delIndex);
            this.setState({trialIds});
        }
    }

    changeOrder(evt, index){
        const self = this;
        let { trialIds} = this.props;
        if (evt){
            evt.stopPropagation();
            evt.preventDefault();
        }

        if (evt.currentTarget.id ==='upButton'){
            let temp =  trialIds[index];
            trialIds[index] = trialIds[index-1];
            trialIds[index-1] = temp;
        }
        if (evt.currentTarget.id ==='downButton'){
            let temp =  trialIds[index];
            trialIds[index] = trialIds[index+1];
            trialIds[index+1] = temp;
        }
        self.setState(trialIds)
    }
    render(){
        const { appContext } = this.props;
        const { trialIds, modalOpen, dialogTrial } = this.state;
        const getUpButton = (index) =>{
            if (index !== 0 && trialIds.length > 1){
                return ( 
                    <IconButton id='upButton' name={index} onClick={(evt) => { this.changeOrder(evt, index) }}>
                        <KeyboardArrowUpIcon/>
                    </IconButton>
                )
            }
        }
        const getDownButton = (index) =>{
            if (index !== trialIds.length-1 && trialIds.length > 0 ){
                return ( 
                    <IconButton id='downButton' name={index} onClick={(evt) => { this.changeOrder(evt, index) }}>
                        <KeyboardArrowDownIcon/>
                    </IconButton>
                )
            }
        }
        const trials = () => {
            var tableRows = []
            if (Array.isArray(trialIds)){
                trialIds.forEach( (trialItem, index)=> {
                    const row = (
                        <TableRow
                            hover
                            onClick={event => this.editTrial(event, index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                <Trial appContext={appContext} trialId={trialItem.trialId} />
                            </TableCell>
                            <TableCell>
                                {trialItem.trialId}
                            </TableCell>
                            <TableCell>
                                {trialItem.default ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                                {getDownButton(index)}
                            </TableCell>
                            <TableCell>
                                {getUpButton(index)}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"  
                                    onClick={(evt) => this.deleteTrial(evt, index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                })
            }
            return tableRows 
        }
        const editCreateModal = ()=> {
            if (modalOpen){
                return (
                    <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.modalOpen}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Trial Details
                        </DialogTitle>
                        <DialogContent>
                            
                        <SelectTrial appContext={appContext} active={true} trialId={dialogTrial.trialId} onChange={(trialId) => {
                            dialogTrial.trialId = trialId;
                            this.setState({dialogTrial});
                        }} />
                        <br />
                            <FormControlLabel label='Default' control={
                            <Checkbox checked={dialogTrial.default} onChange={(e,v) => {
                                dialogTrial.default = v;
                                this.setState({dialogTrial});
                            }} /> } />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained"  self={this} style={styles.button} onClick={(e) => { this.onDone() }}>Ok</Button>
                            <Button variant="contained"  self={this} style={styles.button} onClick={(e) => { this.onCancel() }}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        }
        return (
            <div>
                {editCreateModal()}
                <Paper>
                <EnhancedTableToolbar
                    title='Trials'
                    onAdd={this.handleOnAdd}
                    onDelete={() => {}}
                    numSelected={0}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Default</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trials()}
                    </TableBody>
                </Table>
                </Paper>
            </div>
        )
    }
}
export default OrgTrialsView;

const styles = {
    button:{
        margin:5
    },
    div: {
        margin: 10
    }
}