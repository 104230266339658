import React, { Component } from 'react';
import NavigationClose from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DoneIcon from '@material-ui/icons/Done';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Tooltip,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core/';

export class PatientLabData extends Component {

    constructor(props) {
        super(props);
        let patient = JSON.parse(JSON.stringify(props.patient));
        let planIds = [ 'f3bc08a0-2c79-4d3d-b3d0-af5bf960ce44' ];                   // default to the IRAE protected plan..
        let planId = planIds[0];
        if ( Array.isArray(patient.plans) && patient.plans.length > 0 ) {
            planId = patient.plans[0].planId;
            planIds = patient.plans.map((e) => e.planId );
        }

        this.state = {
            patient,
            planId,
            planIds,
            labDate: new Date(),
            labTestItems: [],
            dialog: null,
            labModels: null,
            labModelType: '',
            allLabTriggers: [],
            error: null,
            disableButton: false
        };
    }

    componentDidMount() {
        this.loadLabModels();
    }

    loadLabModels() {
        const { planId } = this.state;
        const getLabModels = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/models/*?dependencies=true&category=lab`,
            method: 'GET',
            headers: { "Authorization": "Bearer " + this.props.appContext.state.idToken }
        };

        Axios(getLabModels).then(response => {
            this.setState({
                labModelType: '',
                labModels: response.data,
                allLabTriggers:
                    response.data
                    .filter(item => item.laboratory.length > 0)
                    .sort((a,b) => {return a.name > b.name ? -1 : 1})
                    .reduce( (a,b) => {a[b.modelId] = b.laboratory; return a}, {})
            });
        }).catch(err => {
            console.log("Unable to load lab models", err);
        });
    };

    getLabRanges() {
        const { planId, labDataIds } = this.state;

        // hard coded plan ID to IRAE editable
        const getLabModels = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/observations/*?dataId=${labDataIds}&dependencies=true`,
            method: 'GET',
            headers: { "Authorization": "Bearer " + this.props.appContext.state.idToken }
        };

        console.log("getObservations request:", getLabModels );
        Axios(getLabModels).then(response => {
            console.log("getObservations response:", response.data );
            const sortedResponse = response.data.sort(
                (a, b) => { return b.dataId === a.dataId ? 0 : b.dataId > a.dataId ? -1 : 1 });

            this.setState({
                labTriggersForModel: sortedResponse
            }, () => {
                let results = [];
                this.state.labTriggersForModel && this.state.labTriggersForModel
               // .filter(item => ((item.system !== 'urn:oid:1.2.840.114350.1.13.423.2.7.2.768282')))   // TODO: pending zach fixing CCDA conformance, we'll exclude all system with urn:oid
                .map(item => { 
                    let result = { source: { range: {} }, checked: false };
                    result._id = item._id;
                    result.dataId = item.dataId;
                    result.name = item.name;
                    result.code = item.code;
                    result.system = item.system;
                    result.normalizedValue = 0.0;
                    result.normalizeTuple = item.normalizedTupleIndex;
                    result.unit = item.targetUnit || item.unit || '';
                    result.valueTuple = item.tupleIndex;
                    result.unitTuple = item.unitTupleIndex;
                    result.source.range = {
                        high: (item.referenceRange || []).length ? item.referenceRange[0].high : 1,
                        low: (item.referenceRange || []).length ? item.referenceRange[0].low : 0
                    };
                    result.value = this.calculateValue( 0.0, result.source.range.low, result.source.range.high).toFixed(2);

                    return results.push(result);
                });
                this.setState({ 'labTestItems': results })

            });
        });
    }

    // calculate a normalized value from an actual value
    calculateNormalizedValue(value, low, high) {
        let normValue = 0;
        if (value >= low && value < high) {
            normValue = (((value - low) / (high - low)) - 0.5) * 2;
        } else if (value < low) {
            normValue = (low !== 0) ?  -(low / value) : 0;
        } else if (value >= high) {
            normValue = value / high
        }
        if (!isNaN(normValue - parseFloat(normValue))) {
            normValue = Math.round(normValue * 1000) / 1000
        }

        return normValue
    }

    // calculate a value from the normalized value
    calculateValue(normalized, low, high) {
        if ( normalized >= -1.0 && normalized <= 1.0) {
            return (((normalized * 0.5) + 0.5) * (high - low)) + low;
        }
        else if ( normalized > 1.0 ) {
            return normalized * high;
        }
        else if ( normalized < -1.0 ) {
            return -(low / normalized); 
        }
        return 0;
    }

    calculateRangeValue(direction, low, high) {
        let value = 0
        if (direction === 'normal') {
            value = 0.5 * (high - low)
        }
        return value + low
    }

    onChange(e) {
        let { labTestItems } = this.state
        const index = parseInt(e.currentTarget.name, 10)
        let item = labTestItems[index];
        const low = item.source.range.low
        const high = item.source.range.high

        let calcNormalize = false;
        let calcValue = false;
        if (e.currentTarget.id === 'highButton') {
            item.normalizedValue = Number(item.normalizedValue) + 1;
            calcValue = true;
        }
        else if (e.currentTarget.id === 'normalButton') {
            item.normalizedValue = 0;
            calcValue = true;
        }
        else if (e.currentTarget.id === 'lowButton') {
            item.normalizedValue = Number(item.normalizedValue) - 1;
            calcValue = true;
        }
        else if (e.target.id === 'value') {
            item.value = e.target.value;
            calcNormalize = true;
        }
        else if (e.target.id === 'normalized') {
            item.normalizedValue = e.target.value;
            calcValue = true;
        }
        else if (e.target.id === 'low') {
            item.source.range.low = e.target.valueAsNumber
        }
        else if (e.target.id === 'high') {
            item.source.range.high = e.target.valueAsNumber
        }
        else if (e.target.id === 'unit') {
            item.unit = e.target.value
        }
        else if (e.target.id === 'select') {
            item.checked = e.target.checked
        }

        if (calcNormalize) {
            item.normalizedValue = this.calculateNormalizedValue(item.value, low, high).toFixed(2)
        }
        else if ( calcValue ) {
            item.value = this.calculateValue(item.normalizedValue, low, high).toFixed(2);            
        }

        this.setState({ labTestItems })
    }

    labDateChange(date) {
        let { labDate } = this.state;
        labDate = date;
        this.setState({ labDate });
    }

    cancelAdd() {
        this.setState({ dialog: null, error: null });
    }

    addData() {
        const { labTestItems, patient, labDate } = this.state
        const selectedLabItems = labTestItems.filter(x => x.checked)
        console.log(patient)
        //construct data to submit object 
        let labResults = []
        selectedLabItems.forEach((item) => {
            let labResult = {}
            labResult.data = []
            labResult.data = Array(item.unitTuple).fill('')
            labResult.data[item.valueTuple] = item.value
            labResult.data[item.normalizeTuple] = item.normalizedValue
            labResult.data[item.unitTuple] = item.unit
            labResult.source = []
            let range = {
                range: [
                    {
                        low: item.source.range.low,
                        high: item.source.range.high
                    }]
            }
            labResult.source.push(range)
            labResult.patientId = patient.patientId
            labResult.eventTime = labDate.toISOString()
            labResult.dataId = item.dataId
            labResult.status = "submitted";
            
            labResults.push(labResult)
        })
        this.props.addData(labResults)
    }

    labModelChange(e) {
        const { allLabTriggers } = this.state;
        if (e.target && e.target.value) {
            const labModelType = e.target.value
            const labDataIds = allLabTriggers[labModelType] && allLabTriggers[labModelType].map(item => {
                return item.dataId;
            }).join(",");

            console.log("labDataIds:", labDataIds );
            this.setState({labModelType, labDataIds}, this.getLabRanges.bind(this));
            //this.getLabRanges(labDataIds, labModel);
        }
    }

    addDataPressed(e) {
        const self = this
        const { patient, labDate, error, labTestItems, disableButton } = this.state
        let labDataItems = []
        const selectedLabItems = labTestItems.filter(x => x.checked)
        const uniqueDataIds = _.uniqBy(selectedLabItems, 'dataId')
        const hasDuplicates = selectedLabItems.length !== uniqueDataIds.length
        if (hasDuplicates) {
            labDataItems.push(<div style={{ color: "red" }}>You have duplicate DataIds. Please select only one</div>)
        }
        selectedLabItems.forEach((item) => {
            let normalizedStyle = { color: "green" }
            if (item.normalizedValue < 0 || item.normalizedValue > 1)
                normalizedStyle = { color: "red" }

            const dataItem =
                <div key={labDataItems.length}>
                    <div style={styles.dialogItemName}>
                        {item.dataId}
                    </div>
                    <div style={styles.dialogItem}>
                        <span style={normalizedStyle}>Value: {item.value}</span>
                    </div>
                    <div style={styles.dialogItem}>
                        <span style={normalizedStyle}>Normalized: {item.normalizedValue}</span>
                    </div>
                    <div style={styles.dialogItem}>
                        <span>Unit: {item.unit}</span>
                    </div>
                    <div style={styles.dialogItem}>
                        <span>Low: {item.source && item.source.range.low}</span>
                    </div>
                    <div style={styles.dialogItem}>
                        <span>High: {item.source && item.source.range.high}</span>
                    </div>
                </div>
            labDataItems.push(dataItem)
        })
        var dialog =
            <Dialog
                maxWidth='lg'
                model="false"
                open={true}>
                <DialogTitle>Add Patient Data</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: "left" }}>You are about to add the following lab data to <b>{patient.firstName} {patient.lastName}</b> on <b>{labDate.toLocaleDateString()}</b></DialogContentText>
                    <br />
                    <hr />
                    <DialogContentText>{labDataItems}</DialogContentText>
                    <hr />
                    <br />
                    {error && `<font color='red'>${error}</font>`}
                </DialogContent>
                <DialogActions>
                    <Button id="cancel" variant="contained" style={styles.button} onClick={() => { self.cancelAdd() }}>Cancel</Button>,
                    <Button id="chart" variant="contained" disabled={hasDuplicates || disableButton} style={styles.button} onClick={() => { self.addData() }}>To longitudinal chart</Button>
                    <Button id="gorilla" variant="contained" disabled={hasDuplicates || disableButton} style={styles.button} onClick={() => { self.generateCCDA() }}>To HIE</Button>
                </DialogActions>
            </Dialog>
        this.setState({ dialog: dialog });
    }

    generateCCDA() {
        const { labTestItems, patient } = this.state
        const selectedLabItems = labTestItems.filter(x => x.checked)
        const generateCCDA = {
            url: Config.baseUrl + `${Config.pathPrefix}reporting/generateCCDA/`,
            method: 'POST',
            headers: { "Authorization": "Bearer " + this.props.appContext.state.idToken },
            data: {
                patientId: patient.patientId,
                isStructured: true,
                type: "progress note",
                data: {
                    assessment: { text: 'test assessment' },
                    plan: { text: 'test plan' },
                    results: {
                        text: 'test results',
                        entries: selectedLabItems.map(item => {
                            // JSON shaped for call to /reporting/generateCCDA
                            return {
                                labId: item._id,
                                labCode: item.code,
                                labSystem: 'LOINC',
                                labName: 'Lab Panel',   // TODO: place holder until we can include panel into observation rule
                                effectiveTime: Moment().format('YYYYMMDDHHmmZZ'), 
                                observationName: item.name,
                                observationId: uuidv4(), 
                                observationValue: item.value,
                                observationHigh: item.source.range.high.toString(),
                                observationLow: item.source.range.low.toString(),
                                observationUnits: item.unit,
                                observationCode: item.code,
                                observationSystem: item.system.search('urn:oid') ? 'LOINC' : item.system.replace('urn:oid','EPIC'),
                            }
                        })
                    }
                }
            }
        };

        this.setState({disableButton: true})
        Axios(generateCCDA).then(response => {
            if (!response.data) return;
            this.setState({disableButton: false})
            const ccda = response.data;
            this.sendToHealthGorilla(ccda);
        }).catch(err => {
            this.setState({ error: 'Unable to generate CCDA' });
            console.log("Unable to generate CCDA", err);
        });
    };

    sendToHealthGorilla(ccda) {
        const strippedCcda = ccda.replace('\\"', '"');
        const sendHGHeaders = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/import/ccda?async=false`,
            method: 'POST',
            contentType: 'application/xml',
            headers: { "Authorization": "Bearer " + this.props.appContext.state.idToken },
            data: strippedCcda
        }

        Axios(sendHGHeaders).then(response => {
            this.setState({ dialog: null, error: null });
        }).catch(err => {
            this.setState({ error: 'Unable to submit to HG' });
            console.log("Unable to submit to HG", err);
        });
    };

    closeContent() {
        if (this.state.modified === true) {
            this.displayModifiedDialog();
        } else {
            this.props.onClose();
        }
    }

    render() {
        const self = this
        const { labDate, labModelType, labTestItems, dialog, patient } = this.state
        const generateLabTriggerCategories = () => {
            const { labModels } = this.state;
            let menuItems = [];

            if (labModels) {
                labModels
                .sort((a,b) => {return a.name > b.name ? 1 : -1})
                .map( (item, key) => {
                    const menuItem = <MenuItem value={item.modelId} key={key}>{item.name}</MenuItem>
                    menuItems.push(menuItem)
                    return null;
            });

            return menuItems;
            }
        };


        const addDataButton = () => {
            const noItemsChecked = _.every(self.state.labTestItems, ['checked', false])
            return (
                    <div style={styles.addButton}>
                        <Button id="addDataLabTo" variant="contained" disabled={noItemsChecked} style={styles.button} onClick={(e) => { self.addDataPressed(e) }}>Add Lab Data To...</Button>
                    </div>
            )
        }
        const generateLabDataItems = () => {
            let sectionItems = []
            if (labTestItems.length) {
                labTestItems.forEach((item, index) => {
                    let isNonBooleanType = item.unit !== 'boolean';
                    let normalizedStyle = { color: "green", width: 180 }
                    if (item.normalizedValue < 0 || item.normalizedValue > 1)
                        normalizedStyle = { color: "red", width: 180 }
                    const sectionItem = (
                        <TableRow>
                            <TableCell style={{ width: 10 }}>
                                <Checkbox
                                    id="select"
                                    datakey={`select-${item.system}-${item.code}-${item.unit}`}
                                    name={index}
                                    checked={item.checked}
                                    onChange={self.onChange.bind(this)}
                                />
                            </TableCell>
                            <TableCell style={{ width: 20 }}>
                                {item.name}  {item.code ? '(' + item.code + ')' : ''}
                            </TableCell>
                            <TableCell style={{ width: 180 }}>
                                <TextField
                                    fullWidth
                                    label='Value'
                                    id="value"
                                    datakey={`value-${item.system}-${item.code}-${item.unit}`}
                                    name={index}
                                    type='number'
                                    value={`${item.value}`}
                                    onChange={self.onChange.bind(this)}
                                />
                            </TableCell>
                            {isNonBooleanType &&
                                <TableCell style={normalizedStyle}>
                                    <TextField
                                        fullWidth
                                        label="Normalized"
                                        id="normalized"
                                        dataKey={`normalized-${item.system}-${item.code}-${item.unit}`}
                                        name={index}
                                        type='number'
                                        value={`${item.normalizedValue}`}
                                        onChange={self.onChange.bind(this)}
                                    />
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 180 }} >
                                    <TextField
                                        fullWidth
                                        label='Unit'
                                        id="unit"
                                        datakey={`unit-${item.system}-${item.code}-${item.unit}`}
                                        name={index}
                                        value={item.unit}
                                        onChange={self.onChange.bind(this)}
                                    />
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 180 }} >
                                    <TextField
                                        fullWidth
                                        label='Low'
                                        id="low"
                                        datakey={`low-${item.system}-${item.code}-${item.unit}`}
                                        name={index}
                                        type='number'
                                        value={`${item.source.range.low}`}
                                        onChange={self.onChange.bind(this)}
                                    />
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 180 }}>
                                    <TextField
                                        fullWidth
                                        label='High'
                                        id="high"
                                        datakey={`high-${item.system}-${item.code}-${item.unit}`}
                                        name={index}
                                        type='number'
                                        value={`${item.source.range.high}`}
                                        onChange={self.onChange.bind(this)}
                                    />
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 50 }}>
                                    <Tooltip title="Low">
                                        <IconButton id="lowButton" datakey={`lowButton-${item.system}-${item.code}-${item.unit}`} name={index} onClick={(e) => { self.onChange(e) }}>
                                            <KeyboardArrowDownIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 50 }}>
                                    <Tooltip title="Normal">
                                        <IconButton id="normalButton" datakey={`normalButton-${item.system}-${item.code}-${item.unit}`} name={index} onClick={(e) => { self.onChange(e) }}>
                                            <DoneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            }
                            {isNonBooleanType &&
                                <TableCell style={{ width: 50 }}>
                                    <Tooltip title="High">
                                        <IconButton id="highButton" datakey={`highButton-${item.system}-${item.code}-${item.unit}`} name={index} onClick={(e) => { self.onChange(e) }}>
                                            <KeyboardArrowUpIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            }
                        </TableRow>
                    )
                    sectionItems.push(sectionItem)
                })
            }
            return sectionItems

        }

        const { planId, planIds } = this.state;
        console.log(`planId: ${planId}, planIds:`, planIds );
        return (
            <div id="patientLabData"  >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton id="close" onClick={() => self.closeContent()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">{patient.firstName} {patient.lastName} - Add Patient Lab Data</Typography>
                    </Toolbar>
                </AppBar>
                <div style={styles.sectionPaperContainer}>
                    <Typography style={styles.sectionHeader} variant="h6">Add Lab Data</Typography>
                    <SelectPlan appContext={this.props.appContext} planId={planId} planIds={planIds} onChange={(plan) => {
                        if ( plan ) {
                            this.setState({planId: plan.planId}, this.loadLabModels.bind(this));
                        }
                    }} label='Select Plan' />
                    <FormControl id="labTriggerModel" style={styles.patientItem}>
                        <InputLabel > Lab Triggers from Models</InputLabel>
                        <Select style={styles.select} label='Lab Triggers' value={labModelType} onChange={(e) => { self.labModelChange(e, "labModelType"); }}>
                            {generateLabTriggerCategories()}
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker style={{margin: 5}}
                            id="startDate"
                            label="Start Day"
                            value={labDate}
                            format={"MM/dd/yyyy"}
                            onChange={(labDate) => { self.labDateChange(labDate) }}
                        />
                    </MuiPickersUtilsProvider>
                    <br />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='none' />
                                <TableCell>DataId</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Normalized Value</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Low</TableCell>
                                <TableCell>High</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {generateLabDataItems()}
                        </TableBody>
                    </Table>
                    {addDataButton()}
                </div>
                {dialog}
            </div>
        )
    }

}

export default PatientLabData;

const styles = {
    select: {
        width: 400
    },
    tableRow: {
        height: 100
    },
    appBar: {
        width: '100%',
        backgroundColor: "#FF9800"
    },
    sectionPaperContainer: {
        margin: 20,
        padding: 20

    },
    patientItem: {
        margin: 5
    },
    dialogItem: {
        width: '18%',
        display: 'inline-block',
        textAlign: 'right'
    },
    dialogItemName: {
        width: '200',
        display: 'inline-block',
        textAlign: 'left'
    },
    addButton: {
        textAlign: 'right',
        margin: 15
    },
    sectionHeader: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '64px',
    },
    sectionRow: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15,
        marginBottom: 15,
    }
};