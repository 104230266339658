import React, { Component } from "react";
import { Drawer, TextField, CircularProgress, IconButton, Select, MenuItem } from "@material-ui/core/";

import RefreshIcon from "@material-ui/icons/Refresh";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { getPatientJournalsPage } from "@apricityhealth/web-common-lib/utils/Services";

import JournalView from "../JournalView";

export class PatientJournalsView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			searchField: "groupId",
			content: [],
			dialog: null,
			progress: null,
		};
	}

	componentDidMount() {
		this._table.loadData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.patientId !== prevProps.patientId) this._table.loadData();
	}

	loadContent({ offset, limit }) {
		let { appContext, patientId } = this.props;
		const { search, searchField } = this.state;

		if (patientId) {
			const args = {offset, limit}
			if (search) {
				args[searchField] = search;
			}

			this.setState({ progress: <CircularProgress size={20} />, error: null });
			return getPatientJournalsPage(appContext, {patientId, ...args})
				.then((data) => {
					this.setState({ progress: null });
					return { data: data.records, dataLength: data.count };
				})
				.catch((error) => {
					this.setState({ progress: null, error: error.message });
					return { data: [], dataLength: 0 };
				});
		} else {
			return Promise.resolve({ data: [], dataLength: 0 });
		}
	}

	getColumns() {
		return [
			{
				id: "createDate",
				numeric: false,
				editType: "dateLabel",
				disabledPadding: false,
				label: "Journal Date",
			},
			{
				id: "groupId",
				numeric: false,
				disabledPadding: false,
				label: "Group ID",
			},
			{
				id: "sessionId",
				numeric: false,
				disabledPadding: false,
				label: "Session ID",
			},
			{
				id: "baseline",
				numeric: false,
				disabledPadding: false,
				label: "Is Baseline",
			},
			{
				id: "eod",
				label: "EOD",
				formatValue: (v, r) => {
					let eod = Array.isArray(r.journal) ? r.journal.find((e) => e.eod === true) : false;
					if (eod) {
						if (eod.questionId === "bail") return "Bail";
						else return "Yes";
					} else {
						return "No";
					}
				},
			},
			{ id: "status", numeric: false, disabledPadding: false, label: "Status" },
		];
	}

	contentSelected([selectedIndex]) {
		if (selectedIndex === undefined) {
			this.closeDialog();
			return;
		}

		let self = this;
		let { appContext } = self.props;
		let data = this._table.state.data[selectedIndex];
		let dialog = (
			<Drawer variant="persistent" anchor="right" open={true}>
				<JournalView appContext={appContext} journal={data} onClose={() => self.closeDialog()} />
			</Drawer>
		);
		self.setState({ dialog });
	}

	closeDialog() {
		this.setState({ dialog: null });
		if (this._table) this._table.setState({ selected: [] });
	}

	onSearchFieldChange(e) {
		if (e.target.value !== this.state.searchField) {
			this.setState({ searchField: e.target.value }, () => this.state.search && this._table.loadData());
		}
	}

	render() {
		const self = this;
		const { dialog, progress, error, search, searchField } = this.state;
		const dataColumns = this.getColumns();

		return (
			<table style={{ width: "100%" }}>
				<tbody>
					<tr>
						<td>
							<Select
								style={{ margin: "20px 5px 5px", width: 150 }}
								value={searchField}
								onChange={this.onSearchFieldChange.bind(this)}
							>
								<MenuItem value={"groupId"}>Group ID</MenuItem>
								<MenuItem value={"sessionId"}>Session ID</MenuItem>
							</Select>

							<TextField
								style={{ margin: 5, width: 500 }}
								label="Search"
								value={search}
								onChange={(e) => {
									this.setState({ search: e.target.value });
								}}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										this.closeDialog();
										this._table.loadData();
									}
								}}
							/>
						</td>
						<td align="right">
							{error}
							<IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>
								{progress ? progress : <RefreshIcon />}
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>
							<EnhancedTable
								onTable={(table) => (self._table = table)}
								onSelected={(s, t) => self.contentSelected(s, t)}
								disableMultiSelect={true}
								disableActions={true}
								order="desc"
								orderBy="createDate"
								columnData={dataColumns}
								data={self.loadContent.bind(self)}
                                rowsPerPage={25}
								title="Journals"
							/>
							{dialog}
						</td>
					</tr>
				</tbody>
			</table>
		);
	}
}

export default PatientJournalsView;
