import React, { Component } from 'react';
import {
    CircularProgress,
    AppBar, Toolbar, IconButton, Typography,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Drawer, Paper
} from '@material-ui/core/';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import NavigationClose from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import Axios from 'axios';
import ReactJson from 'react-json-view';

import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import User from '@apricityhealth/web-common-lib/components/User';
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import { getProvider, loadActivities } from '@apricityhealth/web-common-lib/utils/Services'
import { parse } from 'json2csv';

export class ActivitesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: null,
            error: null,
            filter: '',
            fiterValue: '',
            activities: [],
            activityTypes: [],
            activityType: "*",
            patientId: "*",
            providerId: "*",
            userId: "*",
            startTime: null,
            endTime: null,
        }
    }

   
    async downloadCSV() {
        const { activityType, patientId, providerId, startTime, userId, endTime } = this.state;
        const { appContext } = this.props;
        let promises = [];
        if (activityType === '*' && providerId === "*") return;

        let opts = {};
        if (activityType !== '*') {
            opts.activityType = activityType;
        }
        if (patientId !== '*') {
            opts.patientId = patientId;
        }
        if (providerId !== '*') {
            opts.providerId = providerId;
        }
        if (userId !== '*') {
            opts.userId = userId;
        }
        if (startTime) {
            opts.startTime = startTime.toISOString();
        }
        if (endTime) {
            opts.endTime = endTime.toISOString();
        }

        let activities = await loadActivities(appContext, opts )
        activities.forEach(activity => {
            promises.push(getProvider(appContext, activity.providerId))
        });
        let providers = await Promise.all(promises);
        providers = providers.map(a => a); //remove nulls

        activities = activities.map((activity) => {
            let provider = providers.find((p) => p && p.providerId === activity.providerId);
            if (provider)
                activity = { ...activity, ...provider };

            activity.activityTime = moment(activity.createdAt).format("LLLL");
            return activity;
        })
        let fields = [
            {
                label: 'First Name',
                value: 'firstName',
                default: 'NULL'
            },
            {
                label: 'Last Name',
                value: 'lastName',
                default: 'NULL'
            },
            {
                label: 'Activity',
                value: 'activityType',
                default: 'NULL'
            },
            {
                label: 'Activity Time',
                value: 'activityTime',
                default: 'NULL'
            },

        ]
        var result = parse(activities, { fields });
        const fileDownload = require('js-file-download');
        fileDownload(result, `ProviderActivity.csv`);

    }

    loadData({ offset, limit, order, orderBy }) {
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        return new Promise((resolve, reject) => {
            const { activityType, patientId, providerId, userId, startTime, endTime } = this.state;
            let args = [
                `offset=${offset}`,
                `limit=${limit}`,
                `order=${order}`,
                `orderBy=${orderBy}`
            ];
            if (activityType !== '*') {
                args.push(`activityType=${activityType}`,)
            }
            if (patientId !== '*') {
                args.push(`patientId=${patientId}`,)
            }
            if (providerId !== '*') {
                args.push(`providerId=${providerId}`,)
            }
            if (userId !== '*') {
                args.push(`userId=${userId}`,)
            }
            if (startTime) {
                args.push("startTime=" + startTime.toISOString());
            }
            if (endTime) {
                args.push("endTime=" + endTime.toISOString());
            }
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}activity/?${args.join('&')}`,
                method: 'GET',
                headers: { "Authorization": this.props.appContext.state.idToken }
            };

            console.log("getActivities request:", request);
            Axios(request).then((response) => {
                console.log("getActivities response:", response.data);
                const { activities, totalActivities, activityTypes } = response.data;
                this.setState({ progress: null, activities, activityTypes });
                resolve({ data: activities, dataLength: totalActivities });
            }).catch((error) => {
                console.error("getActivities error:", getErrorMessage(error));
                this.setState({ progress: null, error: getErrorMessage(error) });
                reject(error);
            });
        })
    }

    onClose() {
        this.setState({ dialog: null });
        if (this._table)
            this._table.setState({ selected: [] });
    }

    itemSelected(s, t) {
        if (s.length > 0) {
            const item = this._table.state.data[s[0]]; //filtered ? filtered[s[0]] : roster[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.onClose()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Item</Typography>
                    </Toolbar>
                </AppBar>
                <Paper key="2" style={styles.jsonPaper}>
                    <ReactJson src={item}
                        collapsed={3} name={""} collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
            </Drawer >

            this.setState({ dialog })
        }
    }

    render() {
        const { dialog, activityType, error, progress, patientId, providerId, userId, activityTypes, startTime, endTime, } = this.state;
        const { appContext } = this.props;

        let columns = [
            { id: 'activityType', label: 'Activity Type', editType: "label" },
            {
                id: 'createdAt',
                width: 200,
                editType: 'label',
                label: 'Created At',
                numeric: true,
                formatValue: (v) => {
                    return moment(v).format('MM/DD/YYYY h:mm A');
                },
                formatValueSort: (v) => {
                    return v ? v.valueOf() : v;
                }
            },
            {
                id: 'userId', editType: 'label', label: 'User', formatValue: (v) => {
                    return v ? <User appContext={appContext} userId={v} userIdHidden={true} /> : '';
                }
            },
            {
                id: 'patientId', editType: 'label', label: 'Patient', formatValue: (v) => {
                    return v ? <Patient appContext={appContext} patientId={v} /> : '';
                }
            },
            {
                id: 'providerId', editType: 'label', label: 'Provider', formatValue: (v) => {
                    return v ? <Provider appContext={appContext} providerId={v} /> : '';
                }
            },
            { id: 'description', label: 'Description', editType: "label" },

        ];

        const activityTable = <EnhancedTable
            disableActions={true}
            disableMultiSelect={true}
            onTable={table => this._table = table}
            onSelected={(s, t) => this.itemSelected(s, t)}
            rowsPerPage={25}
            orderBy='createdAt'
            order='desc'
            columnData={columns}
            data={this.loadData.bind(this)}
            title={`Activities`} />;

        const activitySelect = <FormControl style={{ margin: 10, width: 240 }} >
            <InputLabel>Activity Type:</InputLabel>
            <Select value={activityType} onChange={(e) => this.setState({ activityType: e.target.value }, () => this._table.loadData())}>
                <MenuItem key='*' value='*'>All</MenuItem>
                {activityTypes.sort().map((type) => {
                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                })}

            </Select>
        </FormControl>;

        return <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td align='left'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {activitySelect}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    style={{ margin: 10, width: 180 }}
                                    ampm={false}
                                    label="Start Time"
                                    value={startTime}
                                    onChange={(date) => this.setState({ startTime: date }, () => this._table.loadData())}
                                />
                                <DateTimePicker
                                    style={{ margin: 10, width: 180 }}
                                    ampm={false}
                                    label="End Time"
                                    value={endTime}
                                    onChange={(date) => this.setState({ endTime: date }, () => this._table.loadData())}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <SelectUser
                                appContext={appContext}
                                userId={userId}
                                onChange={(e) => this.setState({ userId: e }, () => this._table.loadData())}
                                enableAll />
                            <SelectPatient
                                appContext={appContext}
                                patientId={patientId}
                                onChange={(e) => this.setState({ patientId: e }, () => this._table.loadData())}
                                enableAll />
                            <SelectProvider
                                appContext={appContext}
                                providerId={providerId}
                                onChange={(e) => this.setState({ providerId: e }, () => this._table.loadData())}
                                enableAll />
                        </div>
                    </td>
                    <td align='right'>
                        <Tooltip title='Download CSV'><IconButton disabled={activityType === '*' && providerId === "*"} onClick={() => this.downloadCSV()}><DownloadIcon /></IconButton></Tooltip>
                        <IconButton onClick={() => this._table.loadData()}><RefreshIcon /></IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} >
                        {progress}{error}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {activityTable}
                        {dialog}
                    </td>
                </tr>
            </tbody></table >
    }
}

const styles = {

    jsonPaper: {
        padding: 10,
        width: "100%",
        borderRadius: 6
    }
}

export default ActivitesView;
