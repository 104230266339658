import React, { Component } from 'react';
import { Drawer, CircularProgress, TextField } from '@material-ui/core/';
import Axios from 'axios';

import Config from '@apricityhealth/web-common-lib/Config';
import NotificationView from './NotificationView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

function testFilter(filter, notification) {
    if ( notification ) {
        filter = filter.toUpperCase();
        if ( notification.logText && notification.logText.toUpperCase().indexOf( filter ) >= 0 )
            return true;
    }   
    return false;
}

export class NotificationsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            notifications: [],
            orgs: []
        }
    }
    componentWillMount() {
        this.loadNotifications();
    }

    loadNotifications() {
        const self = this;
        const getNotifications = {
            url: Config.baseUrl + `${Config.pathPrefix}logging/notify`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getNotifications:", getNotifications);
        this.setState({ progress: <CircularProgress /> });
        Axios(getNotifications).then( (response) => {
            console.log("getNotifications response:", response);
            self.setState({ notifications: response.data, progress: null }, self.applyFilter.bind(self) );
        }).catch( (error) => {
            console.log("getNotifications error:", error.response);
            self.setState({ progress: error.message });
        });
    }

    applyFilter() {
        let { notifications, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < notifications.length; ++i) {
                if (testFilter(filter, notifications[i]))
                    filtered.push(notifications[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadNotifications();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    notificationSelected(s, t) {
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const { filtered, notifications } = this.state;
            const notification = filtered ? filtered[s[0]] : notifications[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <NotificationView appContext={appContext} notifyId={notification.notifyId} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    createNotification() {
        const { appContext } = this.props;
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <NotificationView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }
    render() {
        const self = this;
        let { notifications, filtered, filter, dialog, progress } = this.state;

        const columnData = [
            { id: 'notifyId', numeric: false, disabledPadding: false, label: 'Notify ID' },
            { id: 'logText', numeric: false, disabledPadding: false, label: 'Log Text' },
            { id: 'logLevel', numeric: false, disabledPadding: false, label: 'Log Level' },
            { id: 'logTag', numeric: false, disabledPadding: false, label: 'Log Tag' },
            { id: 'notify', numeric: false, disabledPadding: false, label: 'Notify', formatValue: (v) => { 
                let result = "";
                for(let i=0;i<v.length;++i) {
                    if ( result ) result += ",";
                    result += v[i].notifyType + ":" + v[i].notifyTarget;
                }
                return result;
            } }
        ];

        let filterInput = <div>
            <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.applyFilter.bind(self)); }} />
        </div>;

        return (
            <div style={styles.div}>
                {filterInput}
                <EnhancedTable disableMultiSelect={true}
                    onTable={table => self._types = table}
                    onSelected={(s, t) => self.notificationSelected(s, t)}
                    onAdd={() => self.createNotification()}
                    orderBy='notifyId'
                    columnData={columnData}
                    data={filtered ? filtered : notifications}
                    title='Log Notifications' />
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    div: {
        margin: 10,
        textAlign: 'left'
    }
}

export default NotificationsView;
