import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    CircularProgress,
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { loadDataTypes, getPatientData, loadModels, deletePatientData } from '@apricityhealth/web-common-lib/utils/Services';
import { isArrayValid } from '../utils/Utils';

import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip } from '@material-ui/core';

class DataTypeInstanceView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            instance: props.instance,
            dataId: props.dataId,
            type: null,
            patientData: [],
            conditionModels: []
        }
    }

    componentDidMount() {
        this.loadContent();
    }


    componentDidUpdate(prevProps) {
        if (this.props.instance !== prevProps.instance)
            this.setState({ instance: this.props.instance });
        if (this.props.dataId !== prevProps.dataId)
            this.loadContent();
    }

    onClose() {
        this.props.onClose();
    }

    loadContent() {
        const self = this;
        let { appContext, planId, patientId, dataId } = self.props;

        //need the plan Id to load the condition model
        if (!appContext.state.plan)
            appContext.state.plan = { planId };

        if (dataId) {
            self.setState({ progress: <CircularProgress />, error: null });

            let promises = [];
            promises.push(loadDataTypes(appContext, { dataId, planId, dependencies: true } ));
            promises.push(getPatientData(appContext, patientId, [`dataId=${dataId}`] ));
            promises.push(getPatientData(appContext, patientId, [`dataId=${dataId}`, 'baseline=true' ] ));
            promises.push(loadModels(appContext, { dependencies: true } ));
            Promise.all(promises).then((response) => {
                let type = response[0][0];
                let patientData = response[1].data;
                let patientBaslineData = response[2].data;
                let conditionModels = response[3];
                self.setState({ progress: null, type, conditionModels, patientData, patientBaslineData });

            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onDeleteData() {
        const { instance } = this.state;
        deletePatientData( this.props.appContext, instance.patientId, instance.dataId, instance._id ).then((result) => {
            this.onClose();
        });
    }

    render() {
        const self = this;
        let { instance, type, progress, dialog, patientData, patientBaslineData, dataId, conditionModels } = this.state;
        let name = type ? type.name : '';

        let elms = [];
        let conditionModel = null;
        if (instance && type) {
            let copy = JSON.parse(JSON.stringify(instance));
            let data = copy.data;
            let tupleDescriptions = type.tupleDescriptions;

            let classification = {};
            conditionModel = conditionModels.find((e) => e.dataId === dataId);
            if (conditionModel) {
                if (isArrayValid(conditionModel.triggerSymptoms)) {
                    conditionModel.triggerSymptoms.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Trigger';
                    });
                }
                if (isArrayValid(conditionModel.primarySymptoms)) {
                    conditionModel.primarySymptoms.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Primary';
                    });
                }
                if (isArrayValid(conditionModel.associatedSymtom)) {
                    conditionModel.associatedSymtom.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Associated';
                    });
                }
                if (isArrayValid(conditionModel.clinicalModifiers)) {
                    conditionModel.clinicalModifiers.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Clinical Modifier';
                    });
                }
                if (isArrayValid(conditionModel.negativeSymptoms)) {
                    conditionModel.negativeSymptoms.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Pertinent Negative';
                    });
                }
                if (isArrayValid(conditionModel.laboratory)) {
                    conditionModel.laboratory.forEach(symtom => {
                        classification[symtom.modelTupleIndex] = 'Laboratory';
                    });
                }
            }
            if (Array.isArray(data)) {
                let newData = {};
                for (let i = 0; i < data.length; ++i) {
                    let tupleDesc = tupleDescriptions.find((e) => e.index === i);
                    if (tupleDesc && tupleDesc.description) {
                        newData[`${i}:${tupleDesc.description}:${classification[i] ? classification[i] : ""}`] = data[i];
                    }
                    else {
                        newData[`${i}`] = data[i];
                    }
                }
                copy.data = newData;
            }

            elms.push(<Paper key={elms.length} style={styles.jsonPaper}>
                <ReactJson src={copy}
                    collapsed={3} name={name} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);
        } else {
            elms.push(<Paper key={elms.length} style={styles.jsonPaper}>
                <ReactJson src={instance}
                    collapsed={3} name={name} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);
        }

        if (type) {
            elms.push(<Paper key={elms.length} style={styles.jsonPaper}>
                <ReactJson src={type}
                    collapsed={true} name={name} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);
        }

        if (conditionModel) {
            elms.push(<Paper key={elms.length} style={styles.jsonPaper}>
                <ReactJson src={conditionModel}
                    collapsed={true} name={name} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);
        }


        if (isArrayValid(patientBaslineData)) {
            const dataColumns = [
                { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Event Time' },
                { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
                { id: 'baseline', numeric: false, disabledPadding: false, label: 'Baseline' },
                { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v, r) => { return v ? v.join(',').slice(0, 20) : '' } }
            ];
            elms.push(<div key={elms.length} maxwidth="100px"><EnhancedTable
                key={elms.length}
                onSelected={(s, t) => {
                    this.setState({ instance: patientData[s[0]] });
                }}
                disableMultiSelect={true}
                disableActions={true}
                order='desc'
                orderBy='eventTime'
                columnData={dataColumns}
                data={patientBaslineData}
                rowsPerPage={1}
                title='Baseline History' /></div>);
        }


        if (isArrayValid(patientData)) {
            const dataColumns = [
                { id: 'eventTime', numeric: false, editType: 'dateLabel', disabledPadding: false, label: 'Event Time' },
                { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
                { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
                { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v, r) => { return v ? v.join(',').slice(0, 20) : '' } }
            ];
            elms.push(<div key={elms.length} maxwidth="100px"><EnhancedTable
                key={elms.length}
                onSelected={(s, t) => {
                    this.setState({ instance: patientData[s[0]] });
                }}
                disableMultiSelect={true}
                disableActions={true}
                order='desc'
                orderBy='eventTime'
                columnData={dataColumns}
                data={patientData}
                rowsPerPage={25}
                title='Patient History' /></div>);
        }

        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.onClose()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">{name} Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align='center'>
                {appBar}
                <div align='left'>
                    <Tooltip title='Delete Data Point'><IconButton onClick={this.onDeleteData.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                    <br />
                    {elms}
                </div>
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        maxWidth: '100%',
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        maxWidth: 700,
        borderRadius: 6
    },
    delete: {
        margin: 5,
        "textAlign": "right"
    }
}

export default DataTypeInstanceView;
