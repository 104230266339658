import { useState } from 'react';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import {
    withStyles,
    Paper, Button,
    Dialog, DialogContent, DialogActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';
import styles from './RecentActivityPage.styles'
import './questionTable.css';
import Provider from '@apricityhealth/web-common-lib/components/Provider';
import ReactJson from 'react-json-view';


function DataChangesView({ classes, modelDataType, changesFrom, changesTo, providerId, modelInstanceId, modelInstance }) {
    const [showModelInstance, setShowModelInstance] = useState(false);
    const [unevenTuples, setUnevenTupes] = useState(false);
    const [showZeroAnswers, setShowZeroAnswers] = useState(false)

    let rows = [];

    if (modelDataType && isArrayValid(modelDataType.tupleDescriptions) && isArrayValid(changesFrom) && isArrayValid(changesTo)) {
        let tupleDescriptions = modelDataType.tupleDescriptions;
        if (!unevenTuples && (tupleDescriptions.length !== changesFrom.length)) {
            setUnevenTupes(true)
        }
        for (let index = 0; index < changesFrom.length; index++) {
            const changeFrom = changesFrom[index];
            const changeTo = changesTo[index];
            const hasChange = changeFrom !== changeTo;
            if (hasChange || showZeroAnswers) {
                if (index <= tupleDescriptions.length) {
                    let tupleDesc = tupleDescriptions[index];
                    if (tupleDesc) {
                        if (tupleDesc.description) {
                            rows.push(<tr key={index}><td style={{ width: "20rem" }}>{tupleDesc.description}</td><td>{changeFrom}</td><td>{changeTo}</td></tr>);
                        } else {
                            rows.push(<tr key={index}><td >{tupleDesc.classType} (missing description)</td><td>{changeFrom}</td><td>{changeTo}</td></tr>);
                        }
                    } else {
                        rows.push(<tr key={index}><td ><i>{"A Tuple Was Not Found / Model might have changed"}</i></td><td>{changeFrom}</td><td>{changeTo}</td></tr>);
                    }
                } else {
                    rows.push(<tr key={index}><td ><i>{"Tuple Not Found / Model might have changed"}</i></td><td>{changeFrom}</td><td>{changeTo}</td></tr>);
                }
            }
        }
    }
    return (
        <Paper key="2" className={classes.jsonPaper} style={{ width: "60rem" }}>
            <div className={classes.row} style={{ marginBottom: "0.5rem" }} >
                {modelDataType ? <div style={{ width: "100%" }}><strong>{modelDataType.name}</strong></div> : ""}
                {modelInstanceId ? <div style={{ width: "100%", cursor: "pointer" }} onClick={() => modelInstance ? setShowModelInstance(true) : null}>_id: <strong>{modelInstanceId}</strong></div> : ""}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} >
                    {providerId ? <div style={{  width: "250px", textAlign: "right" }}>Updated By: <Provider userId={providerId} /></div> : null}
                    <div style={{ textAlign: "right" }}>
                        <FormControlLabel
                        labelPlacement="start"
                        control={<Checkbox checked={showZeroAnswers}
                            onChange={(_e, v) => setShowZeroAnswers(!showZeroAnswers)}
                        />}
                        label="Zero Answers" />
                    </div>
                </div>
            </div>

            <table className="simple_table" style={{ width: "100%" }}>
                <tbody>
                    <tr><th>Data Field</th><th>Change From</th><th>Change To</th></tr>
                    {rows}
                </tbody>
            </table>
            {unevenTuples && <span style={{ color: "red" }}><i>Note: Original {modelDataType.name} might have changed as the changed array length is not the same as the current model tuple descriptions length.</i></span>}
            {<Dialog
                model="false"
                maxWidth={'md'}
                fullWidth={true}
                align="left"
                open={showModelInstance}
                onClose={() => setShowModelInstance(false)}
            >

                <DialogContent>
                    <ReactJson src={modelInstance}
                        collapsed={3} name={modelInstanceId} collapseStringsAfterLength={64} displayDataTypes={false} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={classes.button} onClick={(e) => { setShowModelInstance(false) }} >Ok</Button>
                </DialogActions>
            </Dialog>}
        </Paper>
    )


}
export default withStyles(styles)(DataChangesView)