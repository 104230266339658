import React, { Component } from 'react';
import {
    LinearProgress,
    IconButton,
    Tab,
    Tabs,
    Paper,
} from '@material-ui/core/';
import Moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh'
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import {
    getAllPlanIds,
    getProvider,
} from '@apricityhealth/web-common-lib/utils/Services';
import ProviderActivityView from './ProviderActivityView';
import ProviderMessagesView from './ProviderMessagesView';

class HistoricalProviderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            providerId: this.props.providerId || '',
            userId: null,
            tab: 'activities',
            startTime: Moment().subtract(30, 'day').toDate(),
            endTime: Moment().toDate()
        }
        this.getContent()
    }


    getContent() {
        const self = this;
        const { appContext } = self.props;
        const { providerId } = self.state;
        self.setState({ progress: <LinearProgress /> });
        if (providerId) {
            getProvider(appContext, providerId).then((provider) => {
                let planId = getAllPlanIds(provider);
                self.setState({ progress: null, provider, userId: provider.userId, planId, });
            }).catch((error) => {
                self.setState({ progress: JSON.stringify(error.message, null, 4) });
            });
        }
    }


    closeDialog() {
        this.setState({ dialog: null });
        if (this._types)
            this._types.setState({ selected: [] });
    }

    handleTabChange = (event, tab) => {
        this.setState({ tab });
    };

    render() {
        const self = this;
        const { providerId, userId, planId, progress, tab, startTime, endTime } = self.state;
        const { appContext } = self.props;

        let args = [];
        if (startTime)
            args.push("startTime=" + startTime);
        if (endTime)
            args.push("endTime=" + endTime);

        //really should cache all these
        const providerSelect = !this.props.providerId ? <SelectProvider style={styles.select}
            appContext={appContext}
            providerId={providerId}
            onSelect={(providerId) => self.setState({ providerId }, self.getContent.bind(self))} /> : null;

        const dateRange = <DateRange
            appContext={appContext}
            format={"MM/dd/yyyy"}
            startTime={startTime}
            endTime={endTime}
            endTimeChange={(endTime) => { self.setState({ endTime }) }}
            startTimeChange={(startTime) => { self.setState({ startTime }) }} />

        const refresh = <IconButton
            style={styles.refresh}
            onClick={() => { self.getContent() }}>
            <RefreshIcon />
        </IconButton>;

        const tabs = {
            "activities": <ProviderActivityView appContext={appContext} userId={userId} providerId={providerId} planId={planId} args={args} />,
            "messages": <ProviderMessagesView appContext={appContext} userId={userId} providerId={providerId} planId={planId} args={args} />
        };

        return (
            <div style={styles.div}>
                {providerSelect}
                {dateRange}
                {refresh}
                {progress}
                <Paper square >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        value={tab}
                        onChange={(e, tab) => this.setState({ tab })}>
                        <Tab value='activities' label="Activities" />
                        <Tab value='messages' label="Messages" />
                    </Tabs>
                </Paper>
                {tabs[tab]}
            </div>
        );
    }
}

const styles = {
    div: {
        margin: 10,
        textAlign: 'left'
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    },
    refresh: {
        marginTop: 8,
    },
    buttons: {
        margin: 5,
        "textAlign": "right"
    },
    button: {
        margin: 10
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    select: {
        margin: 10,
        width: 300
    }
};

export default HistoricalProviderView;
