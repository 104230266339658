import React, { Component } from 'react';
import Moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog
} from '@material-ui/core/';

import EditPatientTrialDialog from './EditPatientTrialDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import Trial from '@apricityhealth/web-common-lib/components/Trial';

class PatientTrials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trials: props.trials || [],
            modalOpen: false,
            editIndex: -1,
            create: false
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trials !== this.props.trials) {
            this.setState({ trials: this.props.trials })
        }
    }

    handleOnAdd = () => {
        const self = this;
        let { trials } = self.state;
        trials.push({ trialId: '' });
        this.setState({ trials, create: true, editIndex: trials.length - 1, modalOpen: true })
    }

    editTrial = (event, index) => {
        this.setState({ create: false, editIndex: index, modalOpen: true })
    }

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    deleteTrial(evt, trialIndex) {
        if (evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        let { trials, editIndex } = this.state;
        var delIndex
        if (trialIndex !== undefined) {
            delIndex = trialIndex
        } else {
            delIndex = editIndex
        }
        if (delIndex >= 0) {
            trials.splice(delIndex, 1);
            this.setState({ trials });
        }
    }

    render() {
        const { appContext } = this.props;
        const { trials, modalOpen, editIndex, create } = this.state;
        const trialRows = () => {
            var tableRows = []
            if (Array.isArray(trials)) {
                trials.forEach((trial, index) => {
                    let formattedEndDate = trial.endDate ? Moment(trial.endDate).format("MM-DD-YYYY") : ''
                    let formattedStartDate = trial.startDate ? Moment(trial.startDate).format("MM-DD-YYYY") : ''
                    let formattedDroppedDate = trial.droppedDate ? Moment(trial.droppedDate).format("MM-DD-YYYY") : ''
                    let row = (
                        <TableRow
                            hover
                            onClick={event => this.editTrial(event, index)}
                            tabIndex={index}
                            key={index}
                        >
                            <TableCell>
                                <Trial appContext={appContext} trialId={trial.trialId} />
                            </TableCell>
                            <TableCell>
                                {formattedStartDate}
                            </TableCell>
                            <TableCell>
                                {formattedEndDate}
                            </TableCell>
                            <TableCell>
                                {formattedDroppedDate}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(evt) => this.deleteTrial(evt, index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    tableRows.push(row)
                })
            }
            return tableRows
        }
        let editCreateModal = () => {
            const { appContext, trialIds } = this.props
            if (modalOpen) {
                return (
                    <Dialog
                        onClose={this.handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.modalOpen}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Trial Details
                        </DialogTitle>
                        <DialogContent>
                            <EditPatientTrialDialog
                                trial={trials[editIndex]}
                                trialIds={trialIds}
                                appContext={appContext}
                                onCancel={this.handleClose.bind(this)}
                                onCancelDelete={this.deleteTrial.bind(this)}
                                onDone={this.handleClose.bind(this)}
                                create={create}
                            />
                        </DialogContent>
                        <DialogActions>
                        </DialogActions>
                    </Dialog>
                )
            }
        }

        return (
            <div>
                {editCreateModal()}
                <Paper>
                    <EnhancedTableToolbar
                        title='Trials'
                        onAdd={this.handleOnAdd}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Dropped Date</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trialRows()}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default PatientTrials;