import React, { useState, useEffect, useMemo } from 'react';
import {
    AppBar,
    useTheme,
    withStyles,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    Toolbar,
    Typography,
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import { red, orange, yellow, green } from "@material-ui/core/colors"
import {
    Refresh,
    Code
} from '@material-ui/icons';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import Select from 'react-select';

import {
    isArrayValid,
    getAlertLevels,
    getPatientJournals
} from '@apricityhealth/web-common-lib/utils/Services';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import Patient from '@apricityhealth/web-common-lib/components/Patient'

import './questionTable.css';
import styles from './RecentActivityPage.styles'
import Config from '@apricityhealth/web-common-lib/Config';
let STAGE = Config.stage;
let ignorePLans = STAGE === 'production' ? [
    "6d16691e-b336-4d8e-be91-d80d4b547cd7",
    "e93e2337-0100-4fe4-bc16-00fa635ccc98",
    "5a850b0a-0d20-4063-afcf-f9c6f4529674",
    "70810e9d-7c24-42aa-a3cc-3f0998332cc4",
    "60c38fac-20a3-44c8-a923-bdcd145e1f3b",
    "527a57e6-6105-4775-a369-cce80c89025c",
    "5f4897fa-f898-439b-b007-ddb386c5a581",
] : STAGE === 'vcc' ? ["63575a2f-2c1e-4e2f-a0e2-a908a1ae0354"] : [];

function JournalsPage({ classes, patientId, appContext }) {
    const theme = useTheme();
    const [totalCount, setTotalCount] = useState(500);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'createDate', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));
    const [selectedFlags, setSelectedFlags] = useState(sessionStorage.getItem("journalFlags") ? JSON.parse(sessionStorage.getItem("journalFlags")) : [{ label: "Red", value: "redAlert" }, { label: "Orange", value: "orangeAlert" }, { label: "Yellow", value: "yellowAlert" }]);
    const [showPatient, setShowPatient] = useState(false);
    const [selectedJournal, setSelectedJournal] = useState({});
    const [journals, setJournals] = useState([])
    const [showCode, setShowCode] = useState(false);
    const [alertLevels, setAlertLevels] = useState([]);

    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [])

    async function internalFetchData() {
        setLoading(true);
        setError(null);
        try {
            let params = [];
            if (patientId) {
                params.push(patientId);
            } else {
                params.push("*");
            }
            let args = []
            if (isArrayValid(selectedFlags)) {
                args.push(`flags=${selectedFlags.map(a => a.value)}`)
            }
            args.push(`offset=${page * pageSize}`);
            args.push(`limit=${pageSize}`);
            let [journalsData, alertLevels] = await Promise.all([
                getPatientJournals(appContext, patientId, args),
                getAlertLevels(appContext, { dependencies: true, planId: "*", resolveConflicts: true })
            ]);
            setAlertLevels(alertLevels)
            let journals = journalsData.records;
            if (Array.isArray(journals)) {
                journals.forEach(element => {
                    element.id = element._id;
                });
            }
            setJournals(journals);
            setTotalCount(journalsData.count);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error);
            console.error(`Error fetching alert data `, error)
        }
    }
    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize, patientId, selectedFlags])

    useEffect(() => {
        sessionStorage.setItem("journalFlags", JSON.stringify(selectedFlags))
    }, [selectedFlags])


    const columns = useMemo(() => {
        return [
            {
                field: 'patientId', headerName: 'Patient', width: 300, renderCell: (params) => {
                    let v = params.value;
                    return <Patient patientId={v} />
                },
            },
            {
                field: 'createDate', headerName: 'Create Date',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'flags', headerName: 'Flags', width: 300, renderCell: (params) => {
                    let v = params.value;
                    if (isArrayValid(v)) {
                        let color = 'black';
                        let redAlert = v.includes('redAlert');
                        if (redAlert)
                            color = red["A700"];
                        let orangeAlert = v.includes('orangeAlert');
                        if (orangeAlert)
                            color = orange["A700"];
                        let yellowAlert = v.includes('yellowAlert');
                        if (yellowAlert)
                            color = yellow["A700"];
                        let greenAlert = v.includes('greenAlert');
                        if (greenAlert)
                            color = green["A700"];
                        return <span style={{ color }} >{v.join(", ")}</span>
                    }
                    return v;
                },
            },
            {
                field: 'eod', headerName: 'EOD?', width: 200,
                renderCell: (params) => {
                    let row = params.row;
                    let v = params.value;
                    return row.baseline ? `${String(v)} (baseline)` : String(v)
                }
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 150,
            },

        ]
    }, []);

    function journalSelected(journal) {
        if (alert) {
            setSelectedJournal(journal);
            setSelectionModel([journal.id])
            setShowPatient(true);
        }
    }

    function onClose() {
        setShowPatient(false)
        setSelectionModel([]);
    }

    const flagOptions = useMemo(() => {
        if (alertLevels) {
            let entries = Object.entries(alertLevels);
            entries = entries.filter(([a, b]) => !ignorePLans.includes(b.planId));
            let triageAlertLevels = entries.filter(([a, b]) => !b.triage);
            return triageAlertLevels && isArrayValid(triageAlertLevels) ? triageAlertLevels.map(([a, b]) => { return { label: b.name, value: b.addFlag } }) : []
        } else {
            return [];
        }
    }, [alertLevels])

    function getQuestionTable(journal) {
        if (isArrayValid(journal)) {
            let rows = [];
            for (let index = 0; index < journal.length; index++) {
                let question = journal[index];
                let answers = question.answers;
                rows.push(<tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td>
                        {<span dangerouslySetInnerHTML={{ __html: question.question }} />}
                        {<ol className="no-padding-list">{isArrayValid(answers) ? answers.map(ans => <li key={ans.answerId}> <span dangerouslySetInnerHTML={{ __html: ans.text }} /></li>) : "No Answers"}</ol>}
                    </td>
                </tr>);;
            }

            return <table className="styled_table">
                <tr>
                    <th style={{ width: "4%" }}>Order</th>
                    <th style={{ width: "100%" }}>Question</th>
                </tr>
                {rows}
            </table>;
        } else {
            return null;
        }

    }
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                        Flags:
                        <Select
                            className={classes.alertLevelSelect}
                            isMulti
                            options={flagOptions}
                            name="flags"
                            value={selectedFlags}
                            onChange={(event) => setSelectedFlags(event)}
                        />
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {journals && <DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}
                        rowCount={totalCount} //should be the total count from the query if there is one
                        rows={journals}
                        onRowClick={({ row }) => journalSelected(row)}
                    />}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Journals</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={journals}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Journals"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showPatient}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={() => onClose()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" >Details</Typography>
                        </Toolbar>
                    </AppBar>

                    <Paper key="2" className={classes.jsonPaper}>
                        {getQuestionTable(selectedJournal.journal)}
                        <ReactJson
                            theme={theme.overrides?.ReactJsonView}
                            src={selectedJournal}
                            className={classes.json}
                            collapsed={1}
                            name="journal"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />
                    </Paper>
                </Drawer>

            </div>
        </div >
    )
}




function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(JournalsPage)