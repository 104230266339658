import React, { Component } from "react";
import {
    withStyles, IconButton,
    Button
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import SelectTrial from "@apricityhealth/web-common-lib/components/SelectTrial";
class EditPatientTrialDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trial: props.trial || { trialId: '', startDate: '', endDate: '', droppedDate: '' },
            create: props.create
        };
    }

    componentDidUpdate(oldProps) {
        if (JSON.stringify(oldProps.trial) !== JSON.stringify(this.props.trial)) {
            this.setState({ trial: this.props.trial, create: this.props.create });
        }
    }

    onCancel() {
        const { onCancel, onCancelDelete, create } = this.props;
        if (onCancelDelete && create)
            onCancelDelete();
        if (onCancel)
            onCancel()
    }

    onDone() {
        const { onDone } = this.props;
        if (onDone)
            onDone();
    }
    render() {
        const { appContext, trialIds } = this.props;
        const { trial } = this.state;
        console.log("trialIds:", trialIds );
        return (
            <>
                <div >
                    <SelectTrial appContext={appContext} active={true} trialIds={trialIds} trialId={trial.trialId} onChange={(trialId) => {
                        trial.trialId = trialId;
                        this.setState({ trial });
                    }} />
                </div>
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        format={"MM/dd/yyyy"}
                        mask={value =>
                            // handle clearing outside if value can be changed outside of the component
                            value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                        }
                        style={styles.picker}
                        openTo="year"
                        label="Start Date"
                        value={trial.startDate ? trial.startDate : null}
                        onChange={(startDate) => { trial.startDate = startDate.toISOString(); this.setState({ trial }) }}
                    />
                    <IconButton style={{ marginTop: 5 }} aria-label="Delete" onClick={() => { trial.startDate = null; this.setState({ trial }) }}><DeleteIcon /></IconButton>
                    <br />
                    <DatePicker
                        format={"MM/dd/yyyy"}
                        mask={value =>
                            // handle clearing outside if value can be changed outside of the component
                            value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                        }
                        style={styles.picker}
                        openTo="year"
                        label="End Date"
                        value={trial.endDate ? trial.endDate : null}
                        onChange={(endDate) => { trial.endDate = endDate.toISOString(); this.setState({ trial }) }}
                    />
                    <IconButton style={{ marginTop: 5 }} aria-label="Delete" onClick={() => { trial.endDate = null; this.setState({ trial }) }}><DeleteIcon /></IconButton>
                    <br />
                    <DatePicker
                        style={styles.picker}
                        openTo="year"
                        label="Dropped Date"
                        format="MM/dd/yyyy"
                        mask={value =>
                            // handle clearing outside if value can be changed outside of the component
                            value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                        }
                        value={trial.droppedDate ? trial.droppedDate : null}
                        onChange={(e) => { trial.droppedDate = e.toISOString(); this.setState({ trial }) }}
                    />
                    <IconButton style={{ marginTop: 5 }} aria-label="Delete" onClick={() => { trial.droppedDate = null; this.setState({ trial }) }}><DeleteIcon /></IconButton>

                </MuiPickersUtilsProvider>
                <br />
                <br />
                <Button variant="contained" self={this} style={styles.button} onClick={(e) => { this.onDone() }}>Ok</Button>
                <Button variant="contained" self={this} style={styles.button} onClick={(e) => { this.onCancel() }}>Cancel</Button>
            </>
        )
    }
}

const styles = {
    button: {
        margin: 5
    },
    div: {
        margin: 10
    }
}

export default withStyles(styles)(EditPatientTrialDialog);
