import React from 'react';
import ChangePassword from '@apricityhealth/web-common-lib/components/ChangePassword';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import {
    Button,
} from '@material-ui/core/';
import jwtDecode from 'jwt-decode';


class UserInfoView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialog: null,
            language: props.appContext.state.language,
        }
    }

    render() {
        const self = this;
        const { appContext } = self.props;
        const { dialog } = self.state;
        const idToken = appContext.state.idToken;
        const token = jwtDecode(idToken);

        return (
            <div align="left" style={styles.div}>
                <table style={styles.table}>
                    <tbody>
                        <tr><td>User ID:</td><td>{token.sub}</td></tr>
                        <tr><td>Email:</td><td>{token.email}</td></tr>
                        <tr><td>Phone Number:</td><td>{token.phone_number}</td></tr>
                    </tbody>
                </table>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({
                        dialog:
                            <ChangePassword appContext={appContext} username={appContext.state.username}
                                onDone={() => { self.setState({ dialog: null }); }} />
                    });
                }}>Change Password</Button>
                <br />
                <br />
                <SelectLanguage appContext={appContext} language={self.state.language} onChange={(newLanguage) => {
                    self.setState({ language: newLanguage });
                    appContext.setState({ language: newLanguage });
                }} />
                <br />
                {dialog}
            </div>
        );
    }
}

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    },
    table: {
        "width": "100%",
        "margin": 10
    },
    td: {
        "textAlign": "right"
    },
};

export default UserInfoView;
