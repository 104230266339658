import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    useTheme,
    withStyles,
    Paper,
    Tooltip,
    Checkbox,
    FormControlLabel
} from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactJson from 'react-json-view';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import Error from '@apricityhealth/web-common-lib/components/Error';
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import {
    isArrayValid,
    getPatientData,
    getPatient,
    getPatientJournals,
} from '@apricityhealth/web-common-lib/utils/Services';

import styles from './RecentActivityPage.styles'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import {
    generateComplaintList
} from './utils'
import { resolveConflicts } from '@apricityhealth/web-common-lib/utils/Utils';


function AlertDetailsPage({ classes, selectedAlert, dataGroups, dataTypes, modelTypes, planTypes, appContext }) {
    let patientId = selectedAlert && selectedAlert.patientId;
    const textContent = useSelector(state => state.app.textContent) //we need to listen to language changes to reload text
    const navigate = useNavigate();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [patientGroupData, setPatientGroupData] = useState([])
    const [patient, setPatient] = useState(null);
    const [patientPlans, setPatientPlans] = useState([]);
    const [patientJournals, setPatientJournals] = useState([]);
    const [showZeroAnswers, setShowZeroAnswers] = useState(false)

    // Auto fetch the data on mount
    useEffect(() => {
        internalFetchData();
    }, [selectedAlert])

    async function internalFetchData() {
        try {
            setError(null)
            setPatientPlans([])
            setPatient(null)
            setPatientGroupData([]);
            if (selectedAlert) {
                setLoading(true)
                if (selectedAlert.groupId) {
                    let args = []
                    args.push(`groupId=${selectedAlert.groupId}`);
                    let [data, patient, journals] = await Promise.all([
                        getPatientData(appContext, patientId, args),
                        getPatient(appContext, patientId),
                        getPatientJournals(appContext, patientId, args)
                    ]);
                    data = data.data;
                    if (Array.isArray(data)) {
                        setPatientGroupData(data);
                    }
                    if(journals?.records)
                    setPatientJournals(journals.records)
                    if (patient) {
                        setPatient(patient);
                        if (isArrayValid(patient.plans) && isArrayValid(planTypes)) {
                            let patientPlans = patient.plans.map((p) => {
                                let plan = planTypes.find((plan) => plan.planId === p.planId);
                                return plan ? `${plan.title} (start: ${moment(p.startDate).format("ll")})` : ""
                            })
                            setPatientPlans(patientPlans)
                        }
                    }
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error));
            console.error(`Error fetching patient data `, getErrorMessage(error))
        }
    }

    const complaintList = useMemo(() => {
        if (patient) {
            let { complaints, errors } = generateComplaintList({ classes, showZeroAnswers, alert: selectedAlert, patientData: patientGroupData, dataTypes: resolveConflicts(dataTypes, patient.planIds, "dataId"), modelTypes: resolveConflicts(modelTypes, patient.planIds, "modelId"), planTypes ,textContent});
            setError(errors)
            return <div key="1">
                {complaints}
                <br></br>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", marginRight: "10px" }}>
                    <div className={classes.legendItem}> [Duration, Duration Same]</div>
                    <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-regular fa-flask" /><span className={classes.legendText}>Lab</span></div>
                    <div className={classes.legendItem}><FontAwesomeIcon className={classes.icon} icon="fa-solid fa-play" /><span className={classes.legendText}>Trigger</span></div>
                </div>

            </div>
        }
        return <></>
    }, [patient, showZeroAnswers, selectedAlert, dataTypes, modelTypes, planTypes, patientGroupData]);

    let alertDataGroup = selectedAlert ? dataGroups.find((group) => group.groupId === selectedAlert.groupId) : null;

    return (
        <Paper key="2" className={classes.drawerPaper}>
            {error && <Error>{error}</Error>}
            <div className={classes.row} style={{ alignItems: "flex-start", alignContent: "flex-end" }}>
                <div style={{ width: "100%" }}>
                    <div style={{ marginLeft: "5px" }}> {selectedAlert && selectedAlert.patientId && <Patient patientId={selectedAlert.patientId} />}&nbsp;-&nbsp;{selectedAlert && selectedAlert.patientId}</div>
                    <div style={{ marginLeft: "5px" }}>Patient's Plans: {patientPlans && patientPlans.join(", ")}</div>
                </div>
                <div className={classes.right} >
                    {loading ? <div className={classes.singleLineName}>Loading...<Loading style={{ color: "black" }} /></div> : null}
                    <FormControlLabel
                         style={{ width: "140px" }}
                        control={<Checkbox checked={showZeroAnswers}
                            onChange={(e, v) => setShowZeroAnswers(!showZeroAnswers)}
                        />}
                        label="Zero Answers" />
                    <Tooltip title={'Open Patient'}><FontAwesomeIcon className={classes.legendItem} style={{ height: "1.5rem", marginRight: '1rem', cursor: "pointer", }} icon="fa-light fa-clipboard-medical" disabled={!loading} onClick={() => navigate(`/patients/${selectedAlert.patientId}`, { state: { patientId: selectedAlert.patientId, selectedAlert, tab: 7 } })} /></Tooltip>
                    <Tooltip title={'Open Story'}><FontAwesomeIcon className={classes.legendItem} style={{ height: "1.5rem", marginRight: '1rem', cursor: "pointer", }} icon="fa-light fa-chart-user" disabled={!loading} onClick={() => navigate(`/recentActivity`, { state: { patientId: selectedAlert.patientId, selectedAlert, tab: 7 } })} /></Tooltip>
                </div>

            </div>
            <Paper key="2" className={classes.jsonPaper}>
                {complaintList}
                {/* <br/><br/>
                <strong>New list: </strong>{symptomsLabels}
                <br/><br/>
                <strong>Legacy list: </strong>{complaintText}
                <br/><br/> */}
            </Paper>
            <ReactJson
                theme={theme.overrides?.ReactJsonView}
                src={{ selectedAlert, patientJournals, alertDataGroup, patientGroupData, patient, patientPlans, planTypes }}
                className={classes.json}
                collapsed={1}
                name="Alert"
                collapseStringsAfterLength={64}
                displayDataTypes={false} />
        </Paper>
    )
}


export default withStyles(styles)(AlertDetailsPage)