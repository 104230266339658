import React, { useState, useEffect, useMemo } from 'react';
import {
    AppBar,
    useTheme,
    withStyles,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Drawer,
    Toolbar,
    Typography,
    Tooltip,
    CircularProgress
} from '@material-ui/core/';
import {
    Refresh,
    Code
} from '@material-ui/icons';
import NavigationClose from '@material-ui/icons/Close';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import Select from 'react-select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
    isArrayValid,
    getBlob,
    loadNotes,
    sendDirectMessage,
    getPatient,
    getProvider
} from '@apricityhealth/web-common-lib/utils/Services';
import User from "@apricityhealth/web-common-lib/components/User";
import Error from '@apricityhealth/web-common-lib/components/Error';
import Patient from '@apricityhealth/web-common-lib/components/Patient'
import Provider from '@apricityhealth/web-common-lib/components/Provider'
import styles from './RecentActivityPage.styles'
import ReactMarkdown from 'react-markdown';
import XMLViewer from 'react-xml-viewer'
import {
    sendFax
} from '@apricityhealth/web-common-lib/accessors/FaxA'
const fileDownload = require('js-file-download');

const noteCategoryOptions = [{ label: "Triage", value: "triage" }, { label: "Workup", value: "workup" }, { label: "Messages", value: "messages" }, { label: "Other", value: "other" }]

function NotesPage({ classes, patientId, appContext }) {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState(/**@type {GridSortModel}*/([{ field: 'createdAt', sort: 'desc' }/* , { field: 'processed', sort: 'desc' } */]));
    const [error, setError] = useState(null);
    const [selectedNoteCategories, setSelectedNoteCategories] = useState([]);
    const [showPatient, setShowNote] = useState(false);
    const [selectedNote, setSelectedNote] = useState({});
    const [notes, setNotes] = useState([]);
    const [patient, setPatient] = useState(null);
    const [pcp, setPcp] = useState(null);
    const [loadingPCP, setLoadingPCP] = useState(false);
    const [pcpDirectEmail, setPcpDirectEmail] = useState(null);
    const [totalNotes, setTotalNotes] = useState(500)
    const [showCode, setShowCode] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [attachment, setAttachment] = useState(null)
    const [showFaxNumber, setShowFaxNumber] = useState(false);
    const [showSendDM, setShowSendDM] = useState(false);
    const [faxNumber, setFaxNumber] = useState(null);
    const [faxError, setFaxError] = useState(null);
    const [dmError, setDMError] = useState(null);
    const [isFaxing, setIsFaxing] = useState(false);
    const [sendingDM, setSendingDM] = useState(false);
    const [dmSentSucessMessage, setDMSentSucessMessage] = useState(false);

    async function internalFetchData() {
        setNotes([])
        setPatient(null);
        setPcp(null);
        setPcpDirectEmail(null)
        setFaxError(null)
        setError(null)
        setLoading(true);
        try {
            let opts = {offset: page * pageSize, limit: pageSize };
            if (isArrayValid(selectedNoteCategories)) {
                opts.category = selectedNoteCategories.map(a => a.value).join(',');
            }
            let content = await loadNotes(appContext, patientId, opts )
            let totalNotes = content.total;
            let notes = content.notes.map((n) => { return { ...n, id: n._id } });
            setNotes(notes);
            setTotalNotes(totalNotes)

        } catch (error) {
            setError(getErrorMessage(error));
            console.error(`Error fetching alert data `, error)
        }
        setLoading(false)
    }

    // Auto fetch the data on page change
    useEffect(() => {
        internalFetchData();
    }, [page, pageSize, patientId, selectedNoteCategories])


    async function faxReport() {

        if (faxNumber && attachment.blob) {
            try {
                setIsFaxing(true)
                setFaxError(null)
                setError(null)
                await sendFax(appContext, attachment.blob, faxNumber, "Encounter Report");
                setIsFaxing(false)
                setFaxNumber(null)
            } catch (error) {
                setFaxError("Fax unable to be sent.")
                setIsFaxing(false)
                setFaxNumber(null)
                setError(getErrorMessage(error));
            }
        }

    }

    async function loadDMAddress() {
        setLoadingPCP(true)
        if (attachment) {
            let patient = await getPatient(appContext, selectedNote.patientId);
            if (patient) {
                let pcp = await getProvider(appContext, patient.primaryProviderId);
                if (pcp) {
                    const pcpDirectEmail = pcp?.emails?.find(i => i.emailType === 'direct');
                    if (pcpDirectEmail)
                        setPcpDirectEmail(pcpDirectEmail.email);
                    setPcp(pcp)

                }
                setPatient(patient);
            }
        }
        setLoadingPCP(false)
    }

    async function directMessage() {
        try {
            if (pcpDirectEmail) {
                setSendingDM(true)
                await sendDirectMessage(appContext, {
                    subject: "Triage Note",
                    recipients: [pcpDirectEmail],
                    attachments: [{ file: Buffer.from(attachment.blob.data, "base64").toString("utf8") }]
                });
                setSendingDM(false)
                setDMSentSucessMessage("Direct message successfully sent")
            }
        } catch (error) {
            setDMError(getErrorMessage(error))
            setSendingDM(false)
        }

    }

    const columns = useMemo(() => {
        return [
            {
                field: 'createdAt', headerName: 'Created At',
                type: "date",
                width: 250,
                renderCell: (params) => {
                    let v = params.value;
                    return moment(v).format("llll");
                }
            },
            {
                field: 'patientId', headerName: 'Patient', width: 250, renderCell: (params) => {
                    let v = params.value;
                    return <Patient appContext={appContext} patientId={v} />
                },
            },
            {
                field: 'userId', headerName: 'Provider', width: 250, renderCell: (params) => {
                    let row = params.row
                    return (row && row.providerId && <Provider appContext={appContext} providerId={row.providerId} />)
                        || (row && row.userId && <User appContext={appContext} userId={row.userId} />) || "n/a"
                },
            },
            {
                field: 'category', headerName: 'Category', width: 150
            },

            {
                field: 'note', headerName: 'Note', width: 400, renderCell: (params) => (
                    params.value
                )
            },
        ]
    }, []);

    function noteSelected(journal) {
        if (alert) {
            setSelectedNote(journal);
            setSelectionModel([journal.id])
            setShowNote(true);
        }
    }

    function onClose() {
        setShowNote(false)
        setSelectionModel([]);
    }

    function downloadAttachment(attachment) {
        if (attachment?.blob) {
            let data = null;
            if (attachment.mimeType === "xml") {
                data = Buffer.from(attachment.blob.data, "base64").toString("utf8")
            } else if (attachment.mimeType === "pdf") {
                data = Buffer.from(attachment.blob.data, "base64");
            }

            if (data)
                fileDownload(data, `Document.${attachment.mimeType}`);
        }
    }

    async function loadAttachment(attachment) {
        setPatient(null);
        setPcp(null);
        setPcpDirectEmail(null)
        if (attachment && attachment.blobId) {
            try {
                let blob = await getBlob(appContext, attachment.blobId);
                if (blob) {
                    setAttachment({ ...attachment, blob });
                    setShowAttachment(true)
                }
            } catch (error) {
                console.log("Notes save error:", getErrorMessage(error));
                this.setState({ progress: getErrorMessage(error) });
            }
        }
    }


    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div className={classes.search}>
                        Note Category:
                        <Select
                            className={classes.alertLevelSelect}
                            isMulti
                            options={noteCategoryOptions}
                            name="Categories"
                            value={selectedNoteCategories}
                            onChange={(event) => setSelectedNoteCategories(event)}
                        />
                    </div>
                    <div className={classes.right}>
                        <IconButton
                            color="primary"
                            disabled={loading}
                            onClick={() => setShowCode(true)}>
                            <Code />
                        </IconButton>
                        <IconButton
                            color="primary"
                            style={{ marginRight: '2rem' }}
                            onClick={() => internalFetchData()}
                        >
                            {loading ? <Loading /> : <Refresh />}
                        </IconButton>
                    </div>
                </div>
                <div style={{ flexGrow: 1, width: "100%" }}>
                    {error && <Error>{error}</Error>}
                    {notes && <DataGrid
                        className={classes.root}
                        density="compact"
                        style={{ backgroundColor: theme.palette.background.paper }}
                        pagination
                        pageSize={pageSize}
                        paginationMode="server"
                        page={page}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        selectionMode="Single"
                        selectionChange={(event) => console.log(`selection`, event)}
                        selectionModel={selectionModel}
                        sortingMode='client'
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model[0].field !== sortModel[0].field)
                                setSortModel(model);
                            else if (model[0].sort !== sortModel[0].sort)
                                setSortModel(model);
                        }}
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}

                        rowCount={totalNotes} //should be the total count from the query if there is one
                        rows={notes}
                        onRowClick={({ row }) => noteSelected(row)}
                    />}
                </div>
                <Dialog
                    model="true"
                    maxWidth={'md'}
                    fullWidth={true}
                    open={showCode}
                    onClose={() => setShowCode(false)}
                >
                    <DialogTitle>Notes</DialogTitle>
                    <DialogContent>
                        <Paper key="2" className={classes.jsonPaper}>
                            <ReactJson
                                src={notes}
                                theme={theme?.overrides?.ReactJsonView}
                                collapsed={3}
                                name="Notes"
                                collapseStringsAfterLength={64}
                                displayDataTypes={false} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Drawer variant="persistent" anchor="right" open={showPatient}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={() => onClose()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" >Details</Typography>
                        </Toolbar>
                    </AppBar>

                    <Paper key="2" className={classes.drawerPaper}>
                        {selectedNote && selectedNote.note && <div className={classes.note}><ReactMarkdown>{selectedNote.note.replaceAll("\n", "  \n")}</ReactMarkdown></div>}
                        {selectedNote.userId ? <><strong>Author: </strong><User appContext={appContext} userId={selectedNote.userId} userIdHidden={true} /> </> : ""}
                        {selectedNote.providerId ? <><strong>Author: </strong><Provider appContext={appContext} providerId={selectedNote.providerId} /> </> : ""}

                        {isArrayValid(selectedNote.attachments) &&
                            <table className="simple_table" style={{ width: "100%" }}>
                                <thead>
                                    <tr><td style={{ textAlign: "center" }} colSpan={3}>Attachments</td></tr>
                                    <tr><th>Author</th><th>Title</th><th>Mime</th></tr>
                                </thead>
                                <tbody>
                                    {selectedNote.attachments.map((attachment) => {
                                        return <tr><td>{attachment.author}</td><td>{attachment.title}</td><td>{attachment.mimeType}<Tooltip title="Show Attachment"><IconButton onClick={() => { loadAttachment(attachment); }}><VisibilityIcon /></IconButton></Tooltip></td></tr>
                                    })

                                    }
                                </tbody>
                            </table>
                        }
                        <ReactJson
                            className={classes.jsonPaper}
                            theme={theme.overrides?.ReactJsonView}
                            src={selectedNote}
                            collapsed={3}
                            name="Note"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />

                    </Paper>
                </Drawer>
                {attachment && <Dialog
                    maxWidth={'md'}
                    fullWidth={true}
                    align="left"
                    model="false"
                    open={showAttachment}
                >
                    <DialogContent>
                        <Paper key="2">
                            {/* {attachment && <iframe key={attachment._id} className={classes.report} title="attachment" src={`data:${attachment.blob?.mimeType};base64,` + attachment.blob?.data} />} */}
                            {(attachment.mimeType === "pdf" || attachment.mimeType === "application/pdf") && <iframe key={attachment._id} style={{ width: "900px", height: "800px" }} title="attachment" src={"data:application/pdf;base64," + attachment.blob.data} />}
                            {(attachment.mimeType === "xml" || attachment.mimeType === "application/xml") && <div style={{ width: 1000 }}><XMLViewer xml={Buffer.from(attachment.blob.data, "base64").toString("utf8")} /></div>}
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        {attachment?.blob && <Button variant="contained" onClick={() => { downloadAttachment(attachment) }}>Download</Button>}
                        {attachment?.blob && attachment?.mimeType === "xml" && <Button variant="contained" onClick={() => { setShowSendDM(true); loadDMAddress(); }}>Send Direct Message CCDA</Button>}
                        {attachment?.blob && attachment?.mimeType === "pdf" && <Button variant="contained" onClick={() => { setFaxError(null); setShowFaxNumber(true) }}>Fax Report</Button>}
                        <Button variant="contained" onClick={() => { setShowAttachment(false); setAttachment(null) }}>Close</Button>
                    </DialogActions>
                </Dialog>}

                {showFaxNumber &&
                    <Dialog open={true} >
                        <DialogTitle>Enter Fax Number</DialogTitle>
                        <DialogContent >
                            <div style={{ color: "red" }}>{faxError}</div>
                            {isFaxing ? <CircularProgress /> :
                                <MuiPhoneNumber
                                    inputStyle={styles.phone}
                                    defaultCountry={'us'}
                                    value={faxNumber}
                                    onChange={(phone) => { setFaxNumber(phone) }}
                                />
                            }

                        </DialogContent>
                        <DialogActions>

                            <Button color="primary" variant="contained" onClick={() => setShowFaxNumber(false)}>Cancel</Button>
                            <Button color="primary" variant="contained" onClick={() => faxReport()}>Fax Report</Button>
                        </DialogActions>
                    </Dialog>}

                {showSendDM &&
                    <Dialog open={true} >
                        <DialogTitle>Send Direct Message</DialogTitle>
                        <DialogContent >
                            <div style={{ color: "red" }}>{dmError}</div>
                            {loadingPCP && <><CircularProgress size="22" />Loading pcp...</>}
                            {sendingDM && <><CircularProgress size="22" /> Sending message...</>}
                            <br />
                            {dmSentSucessMessage}
                            {!loadingPCP && !dmSentSucessMessage && pcpDirectEmail ? `The direct message will be sent to ${pcpDirectEmail}` : "No valid direct address found on provider"}
                            <br />
                            {!loadingPCP && !dmSentSucessMessage && pcp ? `Provider: ${pcp.firstName} ${pcp.lastName}` : `No provider found for the Primary Care Provider Id (${patient?.primaryProviderId})`}

                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={() => setShowSendDM(false)}>Cancel</Button>
                            {pcpDirectEmail && <Button color="primary" variant="contained" onClick={() => directMessage()}>Send Message</Button>}
                        </DialogActions>
                    </Dialog>}
            </div>
        </div >
    )
}


function CustomToolbar() {
    return (
        <GridToolbarContainer >
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

export default withStyles(styles)(NotesPage)