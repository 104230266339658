import Config from '@apricityhealth/web-common-lib/Config';
import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    CircularProgress,
    IconButton,
    Button,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Tooltip,
    TextField,
} from '@material-ui/core/';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import Back from '@material-ui/icons/ArrowBack';
import ConfirmIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import Axios from 'axios';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import DateTimeRange from '@apricityhealth/web-common-lib/components/DateTimeRange';
import Org from "@apricityhealth/web-common-lib/components/Org";
import MUIDataTable from 'mui-datatables'
import {
    loadNotes,
    saveNote,
    deleteNote
} from '@apricityhealth/web-common-lib/utils/Services';
import ReactJson from 'react-json-view';
import moment from 'moment';

class AppointmentView extends Component {

    constructor(props) {
        super(props);
        let timezone = props.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.state = {
            appointment: props.appointment || {},
            showJSON: false,
            timezone,
            timeZoneSource: 'provider'
        }

        if (!props.appointment) {
            this.state.appointment = {
                providerId: '',
                status: "requested",
                patientId: '',
                start: moment().tz(timezone).toDate(),
                end: moment().tz(timezone).toDate(),
                orgId: props.orgId
            }
        }
    }

    componentDidMount() {
        this.loadNotes();
    }

    close() {
        this.props.onClose();
    }

    componentDidUpdate(oldProps) {
        if (this.props.appointment && oldProps.appointment && this.props.appointment.appointmentId !== oldProps.appointment.appointmentId) {
            this.setState({ appointment: this.props.appointment }, () => this.loadNotes());
        }
    }

    loadNotes() {
        const self = this;
        let { appContext } = this.props;
        let { appointment } = this.state;
        if (appointment && appointment.appointmentId && appointment.patientId) {
            let opts = { appointmentId: appointment.appointmentId };
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            loadNotes(appContext, appointment.patientId, opts).then((notes) => {
                self.setState({ notes: notes.notes, progress: null });
            }).catch((error) => {
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    saveNote(note) {
        this.onClose();
        let { appContext } = this.props;
        let { appointment } = this.state;
        note.appointmentId = appointment.appointmentId;
        note.patientId = appointment.patientId;
        note.providerId = appointment.providerId;
        note.category = "appointment";

        this.setState({ progress: <CircularProgress /> });
        saveNote(appContext, appointment.patientId, note).then(() => {
            this.loadNotes();
        }).catch(function (error) {
            console.log("Notes save error:", error.response);
            this.setState({ progress: error.message });
        });
    }

    deleteNote(note) {
        let { appContext } = this.props;
        this.setState({ progress: <CircularProgress /> });
        deleteNote(appContext, note.patientId, note.noteId).then(() => {
            this.loadNotes();
        }).catch(function (error) {
            console.log("Notes save error:", error.response);
            this.setState({ progress: error.message });
        });
    }

    saveAppointment() {
        const self = this;
        let { appointment } = this.state;
        self.setState({ progress: <CircularProgress /> });

        const request = {
            data: appointment,
            headers: { "Authorization": self.props.appContext.state.idToken }
        };
        let isCreateNew = false;

        if (appointment.appointmentId) {
            request.url = Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}`;
            request.method = "POST"
        } else {
            request.url = Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/create`;
            request.method = "PUT";
            isCreateNew = true;
        }

        console.log("saveAppointment:", request);
        Axios(request).then((response) => {
            console.log("saveAppointment response:", response);
            if (Boolean(isCreateNew)) {
                let note = {
                    note: `Initial appointment created on ${response.data.createdAt}\nstart: ${response.data.start}\nend: ${response.data.end}\n`
                }
                self.setState({ appointment: response.data, progress: null }, () => this.saveNote(note));
            } else {
                self.setState({ appointment: response.data, progress: null });
            }

        }).catch((err) => {
            console.log("saveAppointment error:", err);
            self.setState({ progress: err.message });
        });
    }

    confirmAppointment() {
        const self = this;
        let { appointment } = this.state;
        self.setState({ progress: <CircularProgress /> });

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}/confirm`,
            method: "POST",
            data: appointment,
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("confirmAppointment:", request);
        Axios(request).then((response) => {
            console.log("confirmAppointment response:", response);
            self.setState({ appointment: response.data, progress: null });
        }).catch((err) => {
            console.log("confirmAppointment error:", err);
            self.setState({ progress: err.message });
        });
    }

    rejectAppointment() {
        const self = this;
        let { appointment } = this.state;
        self.setState({ progress: <CircularProgress /> });

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}/reject`,
            method: "POST",
            data: appointment,
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("rejectAppointment:", request);
        Axios(request).then((response) => {
            console.log("rejectAppointment response:", response);
            self.setState({ appointment: response.data, progress: null });
        }).catch((err) => {
            console.log("rejectAppointment error:", err);
            self.setState({ progress: err.message });
        });
    }

    deleteAppointment() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Appointment: {`${self.state.appointment.appointmentId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDelete() }}>Cancel</Button>
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }
    cancelDelete() {
        this.setState({ dialog: null });
    }
    confirmDelete() {
        const self = this;
        let { appointment } = this.state;
        self.setState({ dialog: null });
        if (appointment.appointmentId) {
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${appointment.orgId}/appointments/${appointment.appointmentId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteAppointment request :", request);
            Axios(request).then((response) => {
                console.log("deleteAppointment response:", response);
                self.setState({ progress: null });
                self.close();
            }).catch(function (error) {
                console.log("deleteAppointment error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }



    showJSONView() {
        var newState = !this.state.showJSON;
        this.setState({ showJSON: newState });
    }

    addNote() {
        const self = this;

        self.setState({
            dialog:
                <Note
                    onClose={() => self.onClose()}
                    onNote={(note) => {
                        this.saveNote(note);
                    }} />
        });
    }

    deleteNoteInner(selectedRows) {
        this.onClose();
        const self = this;
        if (selectedRows.data.length === 1) {
            let selectedIndex = selectedRows?.data[0].index;
            let { notes } = self.state;
            let note = notes[selectedIndex];
            this.deleteNote(note);
        }
    }

    onClose() {
        this.setState({ dialog: null })
        if (this._types) this._types.setState({ selected: [] });
    }

    noteSelected(selectedRows) {
        const self = this;
        if (selectedRows.data.length === 1) {
            let selectedIndex = selectedRows?.data[0].index;
            let { notes } = self.state;
            let note = notes[selectedIndex];
            if (note) {
                self.setState({
                    dialog:
                        <Note
                            note={note}
                            onClose={() => self.onClose()}
                            onNote={(note) => {
                                self.saveNote(note)
                            }} />
                });
            }
        }
    }

    providerChanged(provider) {
        let { timeZoneSource, timezone } = this.state;
        if (timeZoneSource === "provider") {
            if (provider && provider.timezone)
                timezone = provider.timezone;
        } else if (timeZoneSource === "org") {
            timezone = this.props.timezone
        }
        this.setState({ provider, timezone })
    }



    render() {
        const self = this;
        let { appointment, notes, progress, dialog, showJSON, timezone } = self.state;
        if (!appointment) appointment = {};
        let { appContext } = this.props;
        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={this.close.bind(this)}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="title" color="inherit">Appointment Details</Typography>
                </Toolbar>
            </AppBar> : null;
        var header =
            <div style={styles.providerHeader}>
                <div>
                    Appointment Id: {appointment.appointmentId}<br />
                    Org Id: {appointment.orgId} (<Org appContext={appContext} orgId={appointment.orgId} />)
                </div>
                <div>

                </div>
                <div style={styles.toolbar} valign="top">
                    <Tooltip title="JSON"><IconButton onClick={() => { self.showJSONView(); }}>{showJSON ? < Back /> : < CodeIcon />}</IconButton></Tooltip>
                    <Tooltip title="Confirm"><IconButton disabled={showJSON} onClick={() => { self.confirmAppointment(); }}><ConfirmIcon /></IconButton></Tooltip>
                    <Tooltip title="Reject"><IconButton disabled={showJSON} onClick={() => { self.rejectAppointment(); }}><CancelIcon /></IconButton></Tooltip>
                    <Tooltip title="Save"><IconButton disabled={showJSON} onClick={() => { self.saveAppointment(); }}><SaveIcon /></IconButton></Tooltip>
                    <Tooltip title="Delete"><IconButton disabled={showJSON} onClick={() => { self.deleteAppointment(); }}><DeleteIcon /></IconButton></Tooltip>
                </div>
            </div>

        const columns = [
            {
                name: 'createdAt', numeric: false, disabledPadding: false, label: 'Created At',
                options: {
                    customBodyRender: value => moment(value).format('LLL'),
                }
            },
            { name: 'note', numeric: false, disabledPadding: false, label: 'Note' },
        ];


        return (
            <div style={styles.main} >
                {appBar}
                <div style={styles.providerForm}>
                    {progress}
                    {header}
                    {showJSON &&
                        <Paper key="2" style={styles.providerForm}>
                            <ReactJson src={{ appointment, notes }}
                                collapsed={3} name="appointment" collapseStringsAfterLength={64} displayDataTypes={false} />
                        </Paper>
                    }
                    {!showJSON && appointment &&
                        <Paper key="2" elevation="0" style={styles.providerForm}>
                            <SelectProvider
                                appContext={appContext}
                                providerId={appointment.providerId}
                                onChange={(providerId) => {
                                    appointment.providerId = providerId; self.setState({ appointment })
                                }}
                                onChangeProvider={(provider) => this.providerChanged(provider)}
                            />

                            <SelectPatient
                                appContext={appContext}
                                patientId={appointment.patientId}
                                onChange={(patientId) => {
                                    appointment.patientId = patientId; self.setState({ appointment })
                                }}
                            />

                            <DateTimeRange
                                width='20rem'
                                startLabel={`Start Time (${timezone})`}
                                endLabel={`End Time (${timezone})`}
                                appContext={appContext}
                                endError={moment(appointment.end).isBefore(moment(appointment.start))}
                                endHelperText={moment(appointment.end).isBefore(moment(appointment.start)) && "End cannot be before start"}
                                startTime={appointment.start}
                                endTime={appointment.end}
                                endTimeChange={(endTime) => { appointment.end = moment(endTime).tz(timezone).toDate(); self.setState({ appointment }) }}
                                startTimeChange={(startTime) => { appointment.start = moment(startTime).tz(timezone).toDate(); self.setState({ appointment }) }} />

                            <FormControl style={{ margin: 5, width: 300 }}>
                                <InputLabel>Select Status</InputLabel>
                                <Select value={appointment.status}
                                    onChange={(e) => { appointment.status = e.target.value; self.setState({ appointment }) }} >
                                    <MenuItem value={"completed"}>Completed</MenuItem>
                                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                    <MenuItem value={"inProgress"}>In Progress</MenuItem>
                                    <MenuItem value={"rejected"}>Rejected</MenuItem>
                                    <MenuItem value={"requested"}>Requested</MenuItem>
                                </Select>
                            </FormControl>
                            <MUIDataTable
                                options={{
                                    filter: false,
                                    rowsPerPage: 10,
                                    elevation: 0,
                                    search: false,
                                    print: false,
                                    download: false,
                                    viewColumns: false,
                                    selectToolbarPlacement: 'replace',
                                    customToolbar: ({ displayData }) => (
                                        <div style={{ display: 'flex', verticalAlign: 'middle', justifyContent: 'flex-end' }}>
                                            {(appointment.appointmentId === undefined) ? <pre>Appointment must be created to add notes</pre> : ""}
                                            <Tooltip title='Add' key='edit'><IconButton disabled={progress !== null || appointment.providerId === null || appointment.patientId === null || appointment.appointmentId === null} onClick={() => this.addNote(displayData)}><AddIcon /></IconButton></Tooltip>
                                        </div>
                                    ),
                                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                                        if (selectedRows.data.length === 1) {
                                            return <div style={styles.toolbar}>
                                                <Tooltip title='Edit' key='edit'><IconButton onClick={() => this.noteSelected(selectedRows, displayData, setSelectedRows)}><CreateIcon /></IconButton></Tooltip>
                                                <Tooltip title='Delete' key='delete'><IconButton onClick={() => this.deleteNoteInner(selectedRows, displayData, setSelectedRows)}><DeleteIcon /></IconButton></Tooltip>
                                            </div>
                                        }
                                    },
                                }}
                                columns={columns}
                                data={notes}
                                title="Notes" />
                        </Paper>
                    }
                    {dialog}
                </div>
            </div>
        );
    }
}


class Note extends Component {

    constructor(props) {
        super(props);
        this.state = {
            note: props.note || { text: "", createdAt: new Date() }
        }
    }

    onClose() {
        if (typeof this.props.onClose === 'function')
            this.props.onClose();
    }

    onNote() {
        let { note } = this.state;
        if (typeof this.props.onNote === 'function')
            this.props.onNote(note);
    }

    render() {
        let { note } = this.state;
        let self = this;
        return (
            <Dialog
                model="false"
                open={true}>
                <DialogTitle>Appointment Note</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        variant="outlined"
                        style={{
                            margin: 5, width: '500px', height: '100px', border: '1px'
                        }}
                        minRows={3}
                        value={note.note}
                        label="Note"
                        onChange={(e) => { note.note = e.target.value; self.setState({ note }) }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => { this.onNote() }}>Ok</Button>
                    <Button variant="contained" style={styles.button} onClick={() => { this.onClose() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    main: {
        textAlign: 'left',
        width: '60vw'
    },
    timeZone: {
        margin: 5,
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 1000,
        borderRadius: 6
    },
    providerForm: {
        margin: 5,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column'
    },
    providerHeader: {
        marginLeft: 12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sectionPaperContainer: {
        margin: 10
    },
    sectionRow: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15,
        marginBottom: 15,
    },
    sectionHeader: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '64px',
        paddingLeft: '24px'
    },
    providerRow: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginLeft: 15
    },
    appBar: {
        backgroundColor: "#FF9800"
    },
    ownerInfo: {
        marginLeft: 20
    },
    button: {
        margin: 5
    },
    div: {
        margin: 0
    },
    question: {
        width: 300,
        margin: 15,
    },
    phone: {
        margin: 5,
        width: 200
    },
    toolbar: {
        margin: 5,
        "textAlign": "right"
    }
}

export default AppointmentView;
