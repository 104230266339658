import React from 'react';
import {
    Drawer,
    IconButton,
    CircularProgress,
    TextField
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh'
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import ReportScheduleView from './ReportScheduleView';

import {
    getReportSchedule,
} from '@apricityhealth/web-common-lib/utils/Services';

import { v4 as uuidv4 } from 'uuid';
import Moment from 'moment';

const dateFormat = 'LLLL';

const freq = {
    "hour": "Hourly",
    "day": "Daily",
    "week": "Weekly",
    "month": "Monthly",
    "year": "Annually",
}

function filterContent(filter, reportSchedule) {
    if (reportSchedule.name && reportSchedule.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (reportSchedule.description && reportSchedule.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (reportSchedule.scheduleId && reportSchedule.scheduleId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    return false;
}
export class ReportSchedulesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            schedules: [],
            patients: [],
            limit: 250,
            filter: ""
        }
    }
    componentWillMount() {
        this.loadContent();
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        getReportSchedule(appContext, "*").then((schedules) => {
            self.setState({ schedules, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    contentSelected(s, t) {
        console.log("reportSchedule selected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const reportSchedule = t.state.data[s[0]]; //self.state.schedules[s[0]];

            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <ReportScheduleView
                    appContext={appContext}
                    reportSchedule={reportSchedule}
                    onClose={() => self.onCloseDialog()} />
            </Drawer>;
            self.setState({ progress: null, dialog: dialog });
        }
        else {
            self.setState({ progress: null, dialog: null });
        }
    }

    createReportSchedule() {
        console.log("create reportSchedule");
        const self = this;
        const { appContext } = this.props;
        const newSchedule = { name: '', description: '', scheduleId: uuidv4(), nextSend: new Date(), enabled: true };
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <ReportScheduleView appContext={appContext} reportSchedule={newSchedule} onClose={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        const { schedules, dialog, progress, error, filter } = this.state;

        const columnData = [
            { id: 'name', numeric: false, label: 'Name' },
            { id: 'description', label: 'Description' },
            { id: 'reportName', label: 'Report' },
            { id: 'enabled', label: 'Enabled' },
            {
                id: 'intervalType', numeric: false, disabledPadding: false, width: 200, label: 'Frequency', formatValue: (v) => {
                    return v ? freq[v] : "";
                }
            },
            {
                id: 'nextSend', label: 'Next Send', formatValue: (v) => {
                    return v ? Moment(v).format(dateFormat) : "";
                }
            },
            {
                id: 'lastSent', label: 'Last Sent', formatValue: (v) => {
                    return v ? Moment(v).format(dateFormat) : "";
                }
            },
            {
                id: 'error', label: 'Last Error', formatValue: (v) => {
                    return v ? v.slice(0, 20):""
                }
            },
        ];
        let filtered = [];
        for (let j = 0; j < schedules.length; j++) {
            let reportSchedule = schedules[j];
            if (!filterContent(filter, reportSchedule)) {
                continue;
            }
            filtered.push(reportSchedule);
        }

        return <table style={{ width: '100%' }}><tbody>
            <tr><td>
                <TextField style={{ margin: 5, width: 500 }}
                    value={filter} label="Filter" onChange={(event) => { self.setState({ filter: event.target.value }) }} />
            </td><td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                </td></tr>
            <tr><td colSpan={2}>
                <EnhancedTable disableMultiSelect={true}
                    getRowStyle={(row, index) => { return row.level >= 3 ? { 'backgroundColor': '#E57373' } : null }}
                    onTable={table => self._types = table}
                    onAdd={() => self.createReportSchedule()}
                    onSelected={(s, t) => self.contentSelected(s, t)}
                    orderBy='name'
                    order='asc'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={filtered}
                    title='Report Schedules' />
                {dialog}
            </td></tr>
        </tbody></table>;
    }
}

export default ReportSchedulesView;
