const styles = ({ palette, mixins }) => ({
    root: {
        // backgroundColor: palette?.background?.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginRight: '20px',
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
     
    },
    prompt:{
        minHeight: 'calc(90vh - 100px)',
    },
    formControl: {
        margin: 5,
        minWidth: 250,
    },
    links: {
        flexGrow: 1,
    }
})

export default styles
