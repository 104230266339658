import React, { Component } from 'react';
import { Drawer, CircularProgress, TextField } from '@material-ui/core/';
import Axios from 'axios';

import Config from '@apricityhealth/web-common-lib/Config';
import SubscriptionView from './SubscriptionView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';

function testFilter(filter, sub ) {
    if ( sub ) {
        filter = filter.toUpperCase();
        if ( sub.criteria && sub.criteria.toUpperCase().indexOf( filter ) >= 0)
            return true;
        if ( sub.resourceId && sub.resourceId.toUpperCase().indexOf( filter ) >= 0)
            return true;
        if ( sub.subId && sub.subId.toUpperCase().indexOf( filter ) >= 0)
            return true;
    }   
    return false;
}

export class SubscriptionsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            subs: []
        }
    }
    componentWillMount() {
        console.log("componentWillMount:", this);
        this.loadSubscriptions();
    }

    loadSubscriptions() {
        const self = this;
        const getSubscriptions = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/subscription`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getSubscriptions:", getSubscriptions);
        Axios(getSubscriptions).then( (response) => {
            console.log("getSubscriptions response:", response);
            self.setState({ subs: response.data.subscriptions, progress: null }, self.updateFilter.bind(self) );
        }).catch( (err) => {
            console.log("getSubscriptions error:", err);
            self.setState({ progress: err.message });
        });
    }
    updateFilter() {
        let { filter, subs } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < subs.length; ++i) {
                if (testFilter(filter, subs[i]))
                    filtered.push(subs[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadSubscriptions();
        if (this._types)
            this._types.setState({ selected: [] });      
    }

    selectSubscription(s,t) {
        console.log( "subSelected:", s, t );
        if (s.length > 0) {
            const sub = this.state.filtered ? this.state.filtered[s[0]] : this.state.subs[s[0]];
            var dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <SubscriptionView appContext={this.props.appContext} sub={sub} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    createSubscription() {
        console.log( "onAddSubscription" );
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <SubscriptionView appContext={this.props.appContext} sub={{ subId: '', resourceId: '', criteria: '' }} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        let { appContext } = this.props;
        let { filter, filtered, subs, dialog, progress } = this.state;

        const columnData = [
            { id: 'subId', numeric: false, disabledPadding: false, label: 'Subscription ID' },
            { id: 'resourceId', numeric: false, disabledPadding: false, label: 'Resource ID' },
            { id: 'status', numeric: false, disabledPadding: false, label: 'Status' },
            { id: 'criteria', numeric: false, disabledPadding: false, label: 'Criteria' }
        ];

        let importExport = <ImportExport name={'subscriptions'}
            exportStart={() => {
                return Promise.resolve(self.state.subs);
            }}
            exportRow={(row) => {
                delete row.__v;
                delete row._id;
                delete row.createDate;
                delete row.updatedAt;
                delete row._index;
            }}
            importStart={() => {
                self.setState({ progress: <CircularProgress /> });
                return new Promise( (resolve, reject) => {
                    let promises = [];
                    for(let i=0;i<subs.length;++i) {
                        const sub = subs[i];
                        const deleteSubscription = {
                            url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/subscription/${sub.subId}`,
                            method: 'DELETE',
                            headers: { "Authorization": appContext.state.idToken }
                        }
                        console.log("deleteSubscription:", deleteSubscription);
                        promises.push(Axios(deleteSubscription));
                    }
                    Promise.all(promises).then(resolve).catch(reject);
                });
            }}
            importRow={(row) => {
                const addSubscription = {
                    url: Config.baseUrl + `${Config.pathPrefix}apricity-cleanse/subscription`,
                    method: 'POST',
                    headers: { "Authorization": appContext.state.idToken },
                    data: row
                };

                console.log("addSubscription:", addSubscription);
                return Axios(addSubscription);
            }}
            importDone={(err) => {
                if (err) {
                    self.setState({ progress: err.message });
                }
                else {
                    self.setState({ progress: null });
                    self.loadSubscriptions();
                }
            }}
        />;

        return (
            <div style={styles.div}>
                <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                    onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                <EnhancedTable disableMultiSelect={true}
                    onTable={table => self._types = table}
                    onSelected={this.selectSubscription.bind(this)}
                    onAdd={this.createSubscription.bind(this)}
                    orderBy='subId'
                    rowsPerPage={25}
                    columnData={columnData}
                    data={filtered ? filtered : subs}
                    title='Subscriptions' />
                {importExport}
                {dialog}
                {progress}
        </div>
        );
    }
}

const styles = {
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        textAlign: 'left'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default SubscriptionsView;
